const CA_PHONE_NUMBER = "4159681625";
const TX_PHONE_NUMBER = "7372343930";

const CA_CALENDLY_BASE_URL = "https://my-adu.link/meet";
const TX_CALENDLY_BASE_URL =
  "https://calendly.com/habitat_adu/villa-homes-austin-adu-assessment";

const SERVICEABLE_CONFIRMATION_MESSAGE =
  "We are excited to chat with you soon. We will get back to you shortly with our property assessment.";
const NOT_SERVICEABLE_CONFIRMATION_MESSAGE =
  "We will be sure to reach out when we could be helpful. Know someone who could be interested?";

const WEBINAR_PAGE_SOURCE_FOR_REDIRECT = "webinar-on-demand-gated-ca";
const WEBINAR_REDIRECT_URL =
  "https://villahomes.com/webinar-on-demand-watch-gated-deposit-ca";

export {
  CA_CALENDLY_BASE_URL,
  CA_PHONE_NUMBER,
  TX_CALENDLY_BASE_URL,
  TX_PHONE_NUMBER,
  NOT_SERVICEABLE_CONFIRMATION_MESSAGE,
  SERVICEABLE_CONFIRMATION_MESSAGE,
  WEBINAR_PAGE_SOURCE_FOR_REDIRECT,
  WEBINAR_REDIRECT_URL,
};
