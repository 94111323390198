import React, {useEffect, useState} from "react";
import {NavBar} from "../components/nav-bar";
import {BottomBar} from "../components/bottom-bar";
import {useDispatch, useSelector} from "react-redux";
import "./styles.scss";
import {ConfigurationPage} from "./ConfigurationPage";
import {EditModifier} from "../components/EditModifier";
import {EditProductOptionKeys} from "../admin/components/ProductOptionKeyEditor";
import {EditProductOptionValues} from "../admin/components/ProductOptionValueEditor";
import {EditProduct} from "../../admin/products/views/ProductsTab/EditProduct";
import {
  chooseStep,
  editingOptionKeys,
  editingOptionValues,
} from "../../_shared/slices/CatalogSlice";
import {setFirstTouch} from "../slices/configurationSlice";
import {Helmet} from "react-helmet";
import {ConfirmationModal} from "../components/ConfirmationModal";
import {useIntercom} from "react-use-intercom";
import {isMobile} from "../../_shared/navigation";
import {EditConfigurationPage} from "../components/EditConfigurationPage";
import {FloorplanLayout} from "./floorplanLayout";
import {EditingConfirmationModal} from "../components/StartEditingConfirmationModal";
import {SaveChangesModal} from "../components/SaveChangesModal";
import {useFormik} from "formik";
import {useCurrentProject} from "../../_shared/hooks/useCurrentProject";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import useMountEffect from "../../_shared/hooks/useMountEffect";

const EditingMenu = () => {
  const dispatch = useDispatch();
  const editing = useSelector((state) => state.catalog.editing);

  if (editing !== true) {
    return null;
  }
  return (
    <div style={{position: "fixed", left: "10px", top: "30%", zIndex: 20}}>
      {/*<div*/}
      {/*	className="clickable"*/}
      {/*	onClick={() => {*/}
      {/*		dispatch(editingConfigurationPage(true));*/}
      {/*	}}*/}
      {/*>*/}
      {/*	Edit Configuration Page*/}
      {/*</div>*/}
      <div
        className="clickable"
        onClick={() => {
          dispatch(editingOptionKeys(true));
        }}
      >
        Edit Option Keys
      </div>
      <div
        className="clickable"
        onClick={() => {
          dispatch(editingOptionValues(true));
        }}
      >
        Edit Option Values
      </div>
    </div>
  );
};

export function DesignLayout() {
  const {product, productId} = useProductCatalog();
  const step = useSelector((state) => state.catalog.step);
  const editing = useSelector((state) => state.catalog.editing);
  const currentProduct = product;
  const isEditingSelections = useSelector(
    (state) => state.configuration.editingSelections
  );
  const hasFirstEdit = useSelector((state) => state.configuration.firstEdit);
  const [showPrompt, setShowPrompt] = useState(false);
  const dispatch = useDispatch();
  const {update} = useIntercom();
  const [lastPickedUnit, setLastPickedUnit] = useState(null);

  useEffect(() => {
    window.addEventListener("hashchange", function () {
      if (window.location.hash != null && window.location.hash.length > 0) {
        const hash = window.location.hash.substring(1);
        dispatch(chooseStep(hash));
        dispatch(setFirstTouch(false));
      }
    });
    update({hideDefaultLauncher: false});
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const {configuration} = useCurrentProject();

  useEffect(() => {
    if (configuration != null && configuration.modifierGroupNotes != null) {
      const notes = configuration.modifierGroupNotes;
      Object.keys(notes).forEach((key) => {
        const note = notes[key];
        if (note.value != null) {
          formik.setFieldValue("note-" + key, note.value);
        }
      });
    }
  }, [configuration]);

  useEffect(() => {
    if (isEditingSelections && hasFirstEdit) {
      window.onbeforeunload = () => {
        setTimeout(() => {
          setShowPrompt(true);
        }, 1000);
        return "You have unsaved changes.";
      };
    } else {
      window.onbeforeunload = undefined;
    }

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [isEditingSelections, hasFirstEdit]);

  useEffect(() => {
    if (currentProduct != null) {
      setLastPickedUnit(currentProduct);
    }
  }, [currentProduct]);

  let content = null;
  let productContent = null;

  let floorplanPicker = null;
  let navBar = <NavBar formik={formik} />;
  let bottomBar = null;

  const shouldLookMobile = useDynamicValue({
    forFour: 1,
    forTwelve: 0,
    forEight: 1,
  });
  const style = {};

  if (currentProduct != null) {
    if (shouldLookMobile) {
      style.height = "unset";
      style.minHeight = "calc(100vh - 120px)";
    }

    bottomBar = <BottomBar formik={formik} />;

    content = (
      <div
        className={`custom-container-fluid app-body-wrapper fade-in-animation`}
        style={style}
      >
        <ConfigurationPage
          currentProduct={currentProduct}
          step={step}
          formik={formik}
        />
      </div>
    );

    if (editing) {
      productContent = (
        <>
          <EditModifier currentProduct={currentProduct} />
          <EditConfigurationPage currentProduct={currentProduct} />
          <EditProductOptionKeys />
          <EditProductOptionValues />
          <EditProduct currentProduct={currentProduct} />
          <EditingMenu />
        </>
      );
    }
  } else if (productId == null) {
    floorplanPicker = <FloorplanLayout lastPickedUnit={lastPickedUnit} />;
    if (isMobile()) {
      navBar = null;
    }
  }
  const [loading, setLoading] = useState(true);
  useMountEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  return (
    <div className="app-wrapper">
      <LoadingPanel show={loading} />
      <Helmet>
        <title>Villa | Design Studio</title>
        <meta name="description" content="Design your dream ADU" />
      </Helmet>
      {productContent}
      {navBar}
      {floorplanPicker}
      {content}
      {bottomBar}
      <ConfirmationModal />
      <EditingConfirmationModal />
      <SaveChangesModal
        show={showPrompt}
        setShow={setShowPrompt}
        formik={formik}
      />
    </div>
  );
}
