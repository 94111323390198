import {createSlice} from "@reduxjs/toolkit";
import {
  getProductGroupFromURL,
  getUnitFromURL,
} from "../../design-tool/logic/ProductUtils";
import {FilterType} from "../catalog/filter";

const url = new URL(window.location.href);
const searchParams = new URLSearchParams(url.search);

const editingMode = () => {
  return searchParams.has("edit");
};

const renderMode = () => {
  return searchParams.has("render");
};

const getStepFromHash = () => {
  return window.location.hash != null && window.location.hash.length > 0
    ? window.location.hash.substring(1)
    : 0;
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState: {
    productGroupId: getProductGroupFromURL(),
    productGroups: {},
    productId: getUnitFromURL(),
    products: {},
    categories: {},
    modifierGroups: {},
    modifiers: {},
    productModifiers: {},
    productConfigurationPages: {},
    productModifierGroups: {},
    editing: editingMode(),
    editingRenderings: renderMode(),
    editingModifier: null,
    editingModifierGroup: null,
    editingModifierGroupOverrides: null,
    editingModifierOverrides: null,
    editingCategory: null,
    editingProduct: null,
    isEditingOptionKeys: false,
    isEditingOptionValues: false,
    optionKeys: {},
    optionValues: {},
    isEditingProduct: false,
    isEditingConfigurationPage: false,
    step: getStepFromHash(),
    filters: [],
  },
  reducers: {
    addFilter: (state, action) => {
      state.filters = [...state.filters, action.payload];
    },
    overwriteFilter: (state, action) => {
      const f = action.payload;
      // Return array that has all filters except the ones that match the type that you're overwriting
      let persistentFilters = state.filters.filter((filterOption) => {
        return !(filterOption.type === f.type);
      });
      state.filters = [...persistentFilters, f];
    },
    removeFilter: (state, action) => {
      const toRemove = action.payload;
      // Return array that has all elements except the one that you're removing
      state.filters = state.filters.filter((filterOption) => {
        return !(
          filterOption.type === toRemove.type &&
          (toRemove.label ? filterOption.label === toRemove.label : true)
        );
      });
    },
    hardResetFilters: (state) => {
      state.filters = [];
    },
    resetFilters: (state) => {
      state.filters = state.filters.filter((filterOption) => {
        return filterOption.type === FilterType.Address;
      });
    },
    setProductAndGroupId: (state, action) => {
      const {productId, productGroupId} = action.payload;
      state.productGroupId = productGroupId;
      state.productId = productId;
    },
    setProductGroupId: (state, action) => {
      state.productGroupId = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setProductGroups: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.productGroups[key] = action.payload[key];
      });
    },
    setProducts: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const product = action.payload[key];
        if (product == null) {
          delete state.products[key];
        } else {
          state.products[key] = product;
        }
      });

      const productId = state.productId;
      if (
        !isNaN(state.step) &&
        state.products[productId] != null &&
        state.products[productId].configuratorPages != null &&
        state.products[productId].configuratorPages.length >
          parseInt(state.step)
      ) {
        const index = parseInt(state.step);
        state.step = state.products[productId].configuratorPages[index];
      }
    },
    updateCategories: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        if (value == null) {
          delete state.categories[key];
        } else {
          state.categories[key] = value;
        }
      });
    },
    updateModifierGroups: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        if (value == null) {
          delete state.modifierGroups[key];
        } else {
          state.modifierGroups[key] = value;
        }
      });
    },
    updateModifiers: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        if (value == null) {
          delete state.modifiers[key];
        } else {
          state.modifiers[key] = value;
        }
      });
    },
    productModifiersLoaded: (state, action) => {
      const {productId, modifiers} = action.payload;
      const copyOfState = Object.assign({}, state.productModifiers[productId]);
      state.productModifiers[productId] = Object.assign(copyOfState, modifiers);
    },
    productModifierGroupsLoaded: (state, action) => {
      const {productId, modifierGroups} = action.payload;
      const copyOfState = Object.assign(
        {},
        state.productModifierGroups[productId]
      );
      state.productModifierGroups[productId] = Object.assign(
        copyOfState,
        modifierGroups
      );
    },
    productConfigurationPagesLoaded: (state, action) => {
      const {productId, configurationPages} = action.payload;
      const copyOfState = Object.assign(
        {},
        state.productConfigurationPages[productId]
      );
      state.productConfigurationPages[productId] = Object.assign(
        copyOfState,
        configurationPages
      );
    },
    baseModifierLoaded: (state, action) => {
      const modifier = action.payload;
      state.modifiers[modifier.id] = modifier;
    },
    baseModifierGroupLoaded: (state, action) => {
      const modifierGroup = action.payload;
      state.modifierGroups[modifierGroup.id] = modifierGroup;
    },
    clearEditing: (state) => {
      state.editingModifier = null;
      state.editingModifierGroup = null;
      state.editingModifierGroupOverrides = null;
      state.editingModifierOverrides = null;
      state.editingProduct = null;
      state.editingCategory = null;
    },
    editModifier: (state, action) => {
      state.editingModifier = action.payload;
    },
    editModifierGroup: (state, action) => {
      state.editingModifierGroup = action.payload;
    },
    editModifierGroupOverrides: (state, action) => {
      state.editingModifierGroupOverrides = action.payload;
    },
    editModifierOverrides: (state, action) => {
      state.editingModifierOverrides = action.payload;
    },
    editCategory: (state, action) => {
      state.editingCategory = action.payload;
    },
    editingOptionKeys: (state, action) => {
      state.isEditingOptionKeys = action.payload;
    },
    editProduct: (state, action) => {
      state.editingProduct = action.payload;
    },
    editingConfigurationPage: (state, action) => {
      state.isEditingConfigurationPage = action.payload; // TODO delete once switchover is done
    },
    editingOptionValues: (state, action) => {
      state.isEditingOptionValues = action.payload;
    },
    optionValuesLoaded: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.optionValues[key] = action.payload[key];
      });
    },
    optionKeysLoaded: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.optionKeys[key] = action.payload[key];
      });
    },
    chooseStep: (state, action) => {
      const index = parseInt(action.payload);
      const currentProduct = state.products[state.productId];
      if (isNaN(index)) {
        state.step = action.payload;
      } else if (
        currentProduct != null &&
        currentProduct.configuratorPages != null &&
        currentProduct.configuratorPages.length > index
      ) {
        state.step = currentProduct.configuratorPages[index];
      } else {
        state.step = action.payload;
      }
    },
  },
});

export const catalogReducer = catalogSlice.reducer;
export const {
  filters,
  addFilter,
  overwriteFilter,
  removeFilter,
  resetFilters,
  hardResetFilters,
  setProductAndGroupId,
  setProductId,
  setProductGroupId,
  setProductGroups,
  updateCategories,
  updateModifierGroups,
  updateModifiers,
  setProducts,
  productConfigurationPagesLoaded,
  productModifierGroupsLoaded,
  productModifiersLoaded,
  baseModifierGroupLoaded,
  baseModifierLoaded,
  clearEditing,
  editModifier,
  editModifierGroupOverrides,
  editModifierOverrides,
  editingConfigurationPage,
  editingOptionKeys,
  editingOptionValues,
  editProduct,
  editModifierGroup,
  optionKeysLoaded,
  optionValuesLoaded,
  chooseStep,
  editCategory,
} = catalogSlice.actions;
