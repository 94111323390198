import React from "react";
import "../styles.scss";
import {DesignButton, TextContainer} from "./styles";
import styled from "styled-components";

export const TextButton = (props) => {
  let priceText = props.price;
  if (priceText == null) {
    priceText = "Included";
  }
  return (
    <FullTextContainer onClick={props.onClick} active={props.isSelected}>
      <TextContainer>
        <p className="button-text">{props.buttonText}</p>
        <p className="price">{priceText}</p>
      </TextContainer>
    </FullTextContainer>
  );
};

const FullTextContainer = styled(DesignButton)`
  min-height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 18px;
  cursor: pointer;
  transition: 0.25s all;
`;
