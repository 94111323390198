import React from "react";
import {useSelector} from "react-redux";
import {ButtonForm} from "../components/buttonForm";

export const REASON_KEY = "reason";
export const PERSONAL_REASON = "for-myself-or-family-member";
export const INVESTMENT_REASON = "for-investment";
export const BOTH_PERSONAL_AND_INVESTMENT_REASON = "both";

export const StepTwo = (props) => {
	const selectedValue = useSelector((state) => state.intakeForm.questionTwo);
	const onClick = props.clickFormButton;

	const inputs = [
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "For myself or a family member",
			nextStep: "step-three",
			radioID: "first-option",
			radioValue: PERSONAL_REASON,
			radioName: REASON_KEY,
		},
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "For investment",
			nextStep: "step-two-substep-one",
			radioID: "second-option",
			radioValue: INVESTMENT_REASON,
			radioName: REASON_KEY,
		},
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "Both",
			nextStep: "step-three",
			radioID: "third-option",
			radioValue: BOTH_PERSONAL_AND_INVESTMENT_REASON,
			radioName: REASON_KEY,
		},
	];

	return (
		<ButtonForm
			inputs={inputs}
			question="How do you plan on using your ADU?"
			selectedValue={selectedValue}
		/>
	);
};
