import {isProdBackend} from "../../../database/firebase";

export const isStaging = window.location.host.includes("staging");
export const isProd = window.location.host.includes("app.villahomes.com");
export const isDev = !isStaging && !isProd;
export const BASE_URL = isDev
  ? "http://localhost:3000"
  : isStaging
  ? "https://natomas-staging.web.app"
  : "https://app.villahomes.com";

export const API_BASE_URL = () => {
  const LOCAL_API_BASE_URL = `http://localhost:5001/${
    isProdBackend() ? "natomas-app" : "natomas-staging"
  }/us-central1`;
  return (isDev ? LOCAL_API_BASE_URL : BASE_URL) + "/api";
};
