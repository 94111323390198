import {AddressDetails} from "@natomas/core/database";
import {Address, IAddressDetails} from "@natomas/core";

export const addressDetailsToNatomasDefaultAddress = (
  address: IAddressDetails
): AddressDetails => {
  let streetNumberAndStreet = address.street_number + " " + address.street;
  return {
    city: address.city,
    country: address.country,
    county: address.county,
    state: address.state,
    street_address: streetNumberAndStreet,
    zip: address.zip,
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

export const natomasAddressToAddressDetails = (
  address: AddressDetails
): IAddressDetails => {
  let streetNumberAndStreet = address?.street_address?.split(" ");
  let street_number = streetNumberAndStreet
    ? streetNumberAndStreet.shift()
    : "";
  let street = streetNumberAndStreet ? streetNumberAndStreet.join(" ") : ""; // Concat to single string.

  return Address.dataToAddress(
    "",
    street_number,
    street,
    address?.city,
    address?.state,
    address?.zip,
    address?.county,
    address?.country,
    address?.latitude,
    address?.longitude
  );
};
