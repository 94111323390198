import React from "react";
import {
  CatalogSectionTitle,
  ProductListContainer,
  CatalogSectionDescription,
} from "../../../views/styles";
import {ProductFlexList} from "./styles";
import {ProductCard} from "../ProductCard";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {sort} from "../../utilities";
import {updateProductId} from "../../../../_shared/catalog/ProductCatalogHelper";
import {usePage} from "../../../../_shared/hooks/usePage";
import {ImageCategory} from "@natomas/core";
import {getImageDetailsByCategory} from "../../utilities/images";
import {getUnitPriceInMicros} from "../../../../design-tool/logic/ProductUtils";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {PricingMode} from "../../../../_shared/slices/GlobalSlice";

interface ProductListProps {
  title: string;
  products: any;
}

export const ProductList = (props: ProductListProps) => {
  const {PRICING_MODE} = useGlobalConfiguration();
  const {columnCount} = usePage();
  const {title, products} = props;
  const productListItemColumnSize = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 4,
  });

  const getPricing = (product: any) => {
    if (PRICING_MODE === PricingMode.UNIT) {
      return getUnitPriceInMicros(product);
    } else return product.priceMicros;
  };

  return (
    <ProductListContainer>
      <CatalogSectionTitle>{title}</CatalogSectionTitle>
      <CatalogSectionDescription>
        {products?.length + " available models"}
      </CatalogSectionDescription>
      <ProductFlexList
        removeFrequency={Math.floor(columnCount / productListItemColumnSize)}
      >
        {sort(products).map((product: any) => {
          const floorPlanImages = getImageDetailsByCategory(
            product,
            ImageCategory.FLOOR_PLAN
          );
          return (
            <ProductCard
              key={"product-card-" + product.id}
              id={product.id}
              imageId={product?.imageId}
              hoverImageId={
                floorPlanImages.length > 0 ? floorPlanImages[0].imageId : null
              }
              title={product?.title}
              bedroomCount={product.productDetails?.bedrooms}
              bathroomCount={product.productDetails?.bathrooms}
              squareFootage={product.productDetails?.squareFeet}
              priceMicros={getPricing(product)}
              clickEvent={updateProductId}
              columnWidth={productListItemColumnSize}
              tagline={product.tagline}
            />
          );
        })}
      </ProductFlexList>
    </ProductListContainer>
  );
};
