import React from "react";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {NatDropdown} from "../../../../_shared/generics/dropdown/NatDropdown";
import {NatDropdownOption} from "../../../../_shared/generics/dropdown/NatDropdownOption";
import {Container} from "./styles";

export const ProductLineDropdown = (props: {handleChange: any}) => {
  const {handleChange} = props;
  const {productGroups, productGroup} = useProductCatalog();

  const getTitle = (pG: any) => {
    if (pG?.internal_title) {
      return pG.internal_title;
    } else if (pG?.title) {
      return pG.title;
    } else {
      return "Unnamed";
    }
  };

  const getOptions = (): NatDropdownOption[] => {
    let options: NatDropdownOption[] = [];
    Object.values(productGroups).forEach((pG: any) => {
      options.push({
        label: getTitle(pG),
        value: pG.id,
      });
    });
    return options;
  };

  const isDisabled = () => {
    return !Object.keys(productGroups).length;
  };

  return (
    <Container>
      <NatDropdown
        label={getTitle(productGroup)}
        options={getOptions()}
        trackingId={"admin-portal-product-group-dropdown"}
        callBack={handleChange}
        disabled={isDisabled()}
      />
    </Container>
  );
};
