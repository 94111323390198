import styled from "styled-components";
import {isMobile} from "../../../../_shared/navigation";

export const Title = styled.div`
  font-size: 20px;
  margin-top: 14px;
  font-family: Maison-Medium, sans-serif;
`;

export const Signature = styled.div`
  font-size: 16px;
  margin-top: 14px;
  font-family: Maison-Medium, sans-serif;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  padding-right: 40px;
`;

export const TeamContainerMobile = styled.div`
  display: ${isMobile() ? "" : "flex"};
  margin-top: 60px;
  margin-bottom: 40px;
  padding-right: ${isMobile() ? "0" : "40px"};
  text-align: center;
`;

export const TeamContainer = styled.div`
  display: ${isMobile() ? "" : "flex"};
  margin-top: 60px;
  margin-bottom: 40px;
  padding-right: 40px;
`;

export const TeamContactsContainer = styled.div`
  padding-right: 20px;
  border-right: 1px dotted rgba(0, 0, 0, 0.3);
  max-width: 35%;
  display: flex;
  justify-content: center;
`;

export const DefaultContactsContainer = styled.div<{fullWidth?: boolean}>`
  width: ${(props) => (props.fullWidth ? "100%" : "65%")};
  flex-grow: 2;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const MobileTeamContactsContainer = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
  width: 90%;
  display: inline-block;
  align-content: center;
  text-align: center;
`;

export const MobileDefaultContactsContainer = styled.div`
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  padding-top: 20px;
`;

export const ContactContainer = styled.div`
  width: ${isMobile() ? "124px" : "140px"};
  margin: 0 ${isMobile() ? "auto" : "15px"} 20px ${isMobile() ? "auto" : "15px"};
  text-align: center;
  position: relative;
`;

export const AvatarContainer = styled.div`
  width: ${isMobile() ? "90px" : "126px"};
  height: ${isMobile() ? "90px" : "126px"};
  background: #ffee7e;
  color: #2a3639;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 6px;
`;
export const ContactLineItem = styled.div`
  font-size: 12px;
`;
