import React, {useMemo, useState} from "react";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {fetchProjectsForCustomer} from "../../../../database/firebase/project";
import {GenericModal} from "../../../_shared/generics/GenericModal";
import {
  Address,
  CustomerProjectInfo,
  Customer,
  isBlankString,
  ICustomerInfo,
} from "@natomas/core";
import {natomasAddressToAddressDetails} from "../../../../database/firebase/adapters/address";
import styled from "styled-components";
import {useProduct} from "../../../_shared/hooks/useProductCatalog";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {SmartImageWithId} from "../../../design-tool/components/SmartImage";
import {setCurrentProject} from "../../../_shared/project";
import {HOME_PAGE_ID, toPage} from "../navigation/PageNavigation";

const ProjectsWrapper = styled.div`
  width: 100%;
  height: 280px;
  display: inline-flex;
  overflow-x: auto;
  position: relative;
  padding: 10px 0;
`;

const ProjectCardContainer = styled.div<{active: boolean}>`
  min-width: 240px;
  height: 100%;
  margin-right: 20px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.active ? "rgba(130, 194, 117, 0.1)" : "none"};
  box-shadow: ${(props) =>
    props.active
      ? `inset 0px 0px 0px 3px #82C275`
      : "inset 0px 0px 0px 2px black"};
  cursor: pointer;
  overflow: hidden;
`;

const ProjectCardImgContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
  overflow: hidden;
`;
const ProjectCardContentContainer = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-family: "Maison-Medium", sans-serif;
  font-size: 18px;
`;

const ProjectCard = (props: {
  project: CustomerProjectInfo;
  configuration: any;
  id: string;
  active: boolean;
  handleClick: () => void;
  customer: ICustomerInfo;
}) => {
  const {project, configuration, handleClick, active, customer} = props;
  const address = natomasAddressToAddressDetails(project?.project_address);
  const customerInfo = project?.customer_contact;
  const {product, productGroup} = useProduct(configuration?.product?.id);
  const addressText = Address.getStreetNumberAndStreet(address);
  const unit =
    product?.title ?? configuration?.product?.title ?? "No unit selected yet";
  const imageId =
    product?.imageId ??
    configuration?.product?.imageId ??
    productGroup?.imageId ??
    "";

  const customerName = customerInfo
    ? `${customerInfo?.first_name ?? ""} ${customerInfo?.last_name ?? ""}`
    : Customer.getFullName(customer);

  return (
    <ProjectCardContainer active={active} onClick={handleClick}>
      <ProjectCardImgContainer hidden={!imageId}>
        <SmartImageWithId imageId={imageId} size={"1000"} />
      </ProjectCardImgContainer>
      <ProjectCardContentContainer>
        <Title>{customerName}</Title>
        <div>
          {isBlankString(addressText) ? "No address specified" : addressText}
        </div>
        <div>{productGroup?.title}</div>
        <div>{unit}</div>
      </ProjectCardContentContainer>
    </ProjectCardContainer>
  );
};

export const ProjectSwitcherModal = (props: {
  show: boolean;
  handleClose: () => void;
}) => {
  const {show, handleClose} = props;
  const {customer} = useCurrentCustomer();
  const {currentConfigurationId} = useCurrentProject();
  const [customerProjects, setCustomerProjects]: any = useState([]);

  useMemo(async () => {
    if (customer && show) {
      const configurations = await fetchProjectsForCustomer(customer);
      setCustomerProjects(configurations);
    }
  }, [customer, show]);

  return (
    <GenericModal
      {...props}
      header={"Which project would you like to view?"}
      content={
        <ProjectsWrapper>
          {Array.isArray(customerProjects) &&
            customerProjects
              .sort((a) => (a.id === currentConfigurationId ? -1 : 1))
              .map((customerProject: any) => {
                return (
                  <ProjectCard
                    {...customerProject}
                    key={customerProject.id}
                    customer={customer}
                    handleClick={() => {
                      setCurrentProject(customerProject.id);
                      handleClose();
                      toPage(HOME_PAGE_ID);
                    }}
                    active={customerProject.id === currentConfigurationId}
                  />
                );
              })}
        </ProjectsWrapper>
      }
      footer={
        <button
          className="button-secondary"
          onClick={handleClose}
          style={{
            margin: "auto",
            minWidth: "150px",
          }}
        >
          {"Dismiss"}
        </button>
      }
    />
  );
};
