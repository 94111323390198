// @ts-ignore
import {functions} from "../index";
import {priceTextFromMicros} from "../../../components/design-tool/logic/ConfigurationPageHelper";
import {SITE_WORK_COSTS_DB_KEY} from "./sitework";
import {devLogger} from "../../../.developerSettings";

export const getCostsKey = () => {
	return SITE_WORK_COSTS_DB_KEY;
};

export const fetchSiteWorkCost = async (latitude: any, longitude: any) => {
	// @ts-ignore
	const fetchSiteWorkCostEstimate = functions.httpsCallable(
		"site_engine-fetchCostEstimate"
	);

	const payload = {
		latitude,
		longitude,
	};
	const estimates = await fetchSiteWorkCostEstimate(payload);

	devLogger("Nearby Avg Site work cost estimates:");

	estimates.data.forEach(
		(lineItem: {lineItemId: string; averagePriceMicros: any}) => {
			devLogger(
				lineItem.lineItemId +
					" " +
					priceTextFromMicros(lineItem.averagePriceMicros, "min")
			);
		}
	);

	return estimates;
};
