import {
  CustomerProjectInfo,
  ICustomerInfo,
  IEventInfo,
  INITIAL_DEPOSIT_PAYMENT_EVENT_ID,
  PHASE_1_PAYMENT_EVENT_ID,
  PSA_SIGNED_EVENT_ID,
} from "@natomas/core";

export const getDateObject = (
  project: CustomerProjectInfo | null,
  dateId: string
) => {
  let timestamp = null;
  project?.status_details?.dates.forEach((date) => {
    if (date?.id === dateId) {
      timestamp = date?.timestamp;
    }
  });
  return timestamp;
};

export const isPSASigned = (project: CustomerProjectInfo | null) => {
  return getEventObject(project, PSA_SIGNED_EVENT_ID) != null;
};

export const getEventObject = (
  project: CustomerProjectInfo | null,
  eventId: string
): IEventInfo | null => {
  let returnEvent = null;
  project?.events?.forEach((event) => {
    if (event?.event_id === eventId) {
      returnEvent = event;
    }
  });
  return returnEvent;
};

export const getEventObjectFromUser = (
  user: ICustomerInfo | null,
  eventId: string
) => {
  let returnEvent = null;
  user?.events?.forEach((event) => {
    if (event?.event_id === eventId) {
      returnEvent = event;
    }
  });
  return returnEvent;
};

export const isPhase1Complete = (project: CustomerProjectInfo | null) => {
  return getEventObject(project, PHASE_1_PAYMENT_EVENT_ID) != null;
};

export const isInitialDepositPaid = (user: ICustomerInfo | null) => {
  return getEventObjectFromUser(user, INITIAL_DEPOSIT_PAYMENT_EVENT_ID) != null;
};

export const getProposalCompletionDate = (
  project: CustomerProjectInfo | null
): Date | null => {
  const phase1Event = getEventObject(project, PHASE_1_PAYMENT_EVENT_ID);
  const psaEvent = getEventObject(project, PSA_SIGNED_EVENT_ID);

  if (psaEvent && phase1Event) {
    return new Date(Math.max(phase1Event.timestamp, psaEvent.timestamp));
  }

  return null;
};
