import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {useDispatch, useSelector} from "react-redux";
import {getSortedValuesWithKeyAsId} from "@natomas/core";
import {isBlankString, randomId} from "@natomas/core";
import {useWindowSize} from "../../../_shared/hooks/useWindowSize";
import {useFormik} from "formik";
import {SectionStructure} from "../../../design-tool/models/ConfigurationPageModel";
import {AdminInput} from "./AdminComponentFactory";
import {
  getImageButton,
  getTextButton,
} from "../../../design-tool/components/ModifierButtonFactory";
import {
  editModifierGroupOverrides,
  editModifierOverrides,
} from "../../../_shared/slices/CatalogSlice";
import {GrLinkDown, GrLinkUp} from "react-icons/gr";
import {FiMinusCircle} from "react-icons/fi";
import {BsGear, BsGearFill} from "react-icons/bs";
import {IStore} from "../../../_shared/slices/types/Store";
// @ts-ignore
import {Draggable, Droppable, DragDropContext} from "react-beautiful-dnd";

const RowContainer = styled.div<{marginBottom?: any}>`
  margin-bottom: ${(props) =>
    props.marginBottom != null ? props.marginBottom : "10px"};
  width: fit-content;
`;
const ModifierGroupHeader = styled.div`
  font-family: Maison-Medium, sans-serif;
  font-size: 18px;
  display: flex;
`;
const ModifierPreviewStyle = styled.div`
  padding-left: 30px;
`;
const DroppableContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Flex = styled.div`
  display: flex;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
const MainColumn = styled.div`
  flex-grow: 2;
  overflow-y: auto;
`;
const LibraryColumn = styled.div`
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.7);
  overflow-y: auto;
`;

const LibraryColumnWrapper = styled.div`
  padding: 10px 20px;
`;

const LibrarySearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  width: 80%;
  margin: 15px auto;
  position: sticky;
  top: 15px;
  z-index: 1000;

  background-color: #ffffff;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
`;

const LibrarySearchBar = styled.input`
  border: none;
  padding: 12px 12px;
  background-color: transparent;
  width: 90%;
`;

const ModifierContainer = styled.div`
  max-width: 100%;
  min-width: 300px;
  display: flex;
  align-items: center;
`;

const SectionActionButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 20px;
`;

const AddNewSectionContainer = styled.div`
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px dotted rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-family: Maison-Medium, sans-serif;
`;

function areEqualIds(prevProps: any, nextProps: any) {
  if (!arraysEqual(prevProps.savedIds, nextProps.savedIds)) {
    return false;
  }
  if (
    Object.keys(prevProps.sections).length !==
    Object.keys(nextProps.sections).length
  ) {
    return false;
  }

  const sortedPrevSections = getSortedValuesWithKeyAsId(prevProps.sections);
  const sortedNextSections = getSortedValuesWithKeyAsId(nextProps.sections);

  let sameSectionIds = true;
  sortedNextSections.forEach((section, index) => {
    const oldSection = sortedPrevSections[index];
    sameSectionIds = sameSectionIds && oldSection.id === section.id;
  });

  return sameSectionIds;
}

function arraysEqual(a: any, b: any) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const ModifierPreview = (props: any) => {
  const {contentId, index, modifierGroup, searchText, type, keySuffix} = props;
  const {currentGroupModifiers} = useProductCatalog();

  const modifier = currentGroupModifiers[contentId];
  const currentModifierGroup = useSelector(
    (state: IStore) => state.catalog.editingModifierGroup
  );
  const dispatch = useDispatch();

  if (modifier == null) {
    return (
      <RowContainer>
        <ModifierGroupHeader>
          {`${index + 1}) ${contentId}`}
        </ModifierGroupHeader>
      </RowContainer>
    );
  }

  const title = modifier.title;
  if (!isBlankString(searchText)) {
    if (!title.toLowerCase().includes(searchText.toLowerCase())) {
      return null;
    }
  }

  let modifierGroupForButton = currentModifierGroup;
  if (modifierGroup != null) {
    modifierGroupForButton = modifierGroup;
  }
  if (modifierGroupForButton != null && type === "modifiers") {
    const buttonModifier = Object.assign(Object.assign({}, modifier), {
      configurationButtonTitle: modifier.title,
      adjustedPriceMicros: modifier.defaultPriceMicros,
    });

    const commonProps = {
      buttonId: modifier.id,
      radioValue: modifier.id,
      key: modifier.id,
      isSelected: false,
    };
    let button;
    if (modifier.imageId != null) {
      button = getImageButton(commonProps, buttonModifier);
    } else {
      button = getTextButton(commonProps, buttonModifier);
    }

    let conditionalLogicDiv = null;
    if (keySuffix !== "library_") {
      const isOverridePopulated =
        modifierGroupForButton.modifierOverrides != null &&
        modifierGroupForButton.modifierOverrides[contentId] != null;

      conditionalLogicDiv = (
        <div
          style={{
            marginLeft: "20px",
            cursor: "pointer",
            fontSize: "16px",
            opacity: isOverridePopulated ? 1 : 0.3,
          }}
          onClick={() => {
            dispatch(editModifierOverrides(modifier));
          }}
        >
          {isOverridePopulated ? <BsGearFill /> : <BsGear />}
        </div>
      );
    }

    return (
      <ModifierContainer>
        {button} {conditionalLogicDiv}
      </ModifierContainer>
    );
  }

  return <div>{`- ${title}`}</div>;
};

const ModifierGroupPreview = (props: any) => {
  const {contentId, expanded, index, type, keySuffix, searchText} = props;
  const {currentGroupModifierGroups} = useProductCatalog();
  const currentCategory = useSelector(
    (state: IStore) => state.catalog.editingCategory
  );
  const dispatch = useDispatch();
  const modifierGroup = currentGroupModifierGroups[contentId];

  if (modifierGroup == null) {
    return (
      <RowContainer>
        <ModifierGroupHeader>
          {`${index + 1}) ${contentId}`}
        </ModifierGroupHeader>
      </RowContainer>
    );
  }

  const title = modifierGroup.title;
  if (!isBlankString(searchText)) {
    if (!title.toLowerCase().includes(searchText.toLowerCase())) {
      return null;
    }
  }
  let conditionalLogicDiv = null;
  if (type === "modifierGroup" && keySuffix !== "library_") {
    const isOverridePopulated =
      currentCategory?.modifierGroupOverrides != null &&
      currentCategory?.modifierGroupOverrides[contentId] != null;

    conditionalLogicDiv = (
      <div
        style={{
          marginLeft: "20px",
          cursor: "pointer",
          fontSize: "16px",
          opacity: isOverridePopulated ? 1 : 0.3,
        }}
        onClick={() => {
          dispatch(editModifierGroupOverrides(modifierGroup));
        }}
      >
        {isOverridePopulated ? <BsGearFill /> : <BsGear />}
      </div>
    );
  }

  return (
    <RowContainer>
      <ModifierGroupHeader>
        {`${index + 1}) ${title}`} {conditionalLogicDiv}
      </ModifierGroupHeader>

      {expanded === false ? null : (
        <ModifierPreviewStyle>
          {modifierGroup.modifiers.map((modifierId: any, index: any) => {
            return (
              <ModifierPreview
                {...props}
                modifierGroup={modifierGroup}
                key={type + keySuffix + modifierId + contentId}
                contentId={modifierId}
                searchText={null}
                index={index}
              />
            );
          })}
        </ModifierPreviewStyle>
      )}
    </RowContainer>
  );
};

const CategoryPreview = (props: any) => {
  const {contentId, keySuffix, index, type, searchText} = props;
  const {productGroupCategories} = useProductCatalog();
  const category = productGroupCategories[contentId];
  const expanded = false;
  if (category == null) {
    return <div>{contentId}</div>;
  }

  const title =
    category.internal_title != null ? category.internal_title : category.title;

  if (!isBlankString(searchText)) {
    if (!title.toLowerCase().includes(searchText.toLowerCase())) {
      return null;
    }
  }

  return (
    <RowContainer marginBottom={"40px"} className={"unselectable"}>
      <Flex>
        <ModifierGroupHeader>{`${index + 1}) ${title}`}</ModifierGroupHeader>
      </Flex>

      <ModifierPreviewStyle>
        {category.modifierGroups.map((modifierGroupId: any, index: any) => {
          return (
            <ModifierGroupPreview
              {...props}
              searchText={null}
              key={type + keySuffix + modifierGroupId + index}
              expanded={expanded}
              contentId={modifierGroupId}
              index={index}
            />
          );
        })}
      </ModifierPreviewStyle>
    </RowContainer>
  );
};

function array_move(arr: any, old_index: any, new_index: any) {
  if (new_index >= arr.length) {
    return arr;
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

const DNDRow = (props: any) => {
  const {contentId, index, keySuffix, type} = props;
  let content: any;
  if (type === "modifierGroup") {
    content = <ModifierGroupPreview expanded={true} {...props} />;
  } else if (type === "categories") {
    content = <CategoryPreview {...props} />;
  } else if (type === "modifiers") {
    content = <ModifierPreview {...props} />;
  }

  return (
    <Draggable
      key={keySuffix + contentId}
      draggableId={keySuffix + contentId}
      index={index}
    >
      {(provided: any) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{...provided.draggableProps.style, width: "fit-content"}}
          ref={provided.innerRef}
        >
          {content}
        </div>
      )}
    </Draggable>
  );
};

const getAllItems = (sections: any) => {
  return Object.values(sections)
    .map((section: any) => {
      return section.items;
    })
    .reduce(function (accumulator, currentValue) {
      return accumulator.concat(currentValue);
    }, []);
};

const reorderSections = (sections: any, currentIndex: any, nextIndex: any) => {
  const sectionsCopy = Object.assign({}, sections);
  const arrayOfIds = getSortedValuesWithKeyAsId(sections).map(
    (section) => section.id
  );
  const newOrder = array_move(arrayOfIds, currentIndex, nextIndex);
  const newSections = {};
  newOrder.forEach((sectionId: any, index: any) => {
    // @ts-ignore
    newSections[sectionId] = {
      ...sectionsCopy[sectionId],
      index: index,
    };
  });
  return newSections;
};

const MainSections = (props: any) => {
  const {sections, type, callback} = props;
  const formik = useFormik({
    initialValues: {
      sections: sections,
    },
    onSubmit: (values) => {},
  });

  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue("sections", sections);
  }, [sections]);

  const reorder = (sectionIndex: any, diff: any) => {
    const newSections = reorderSections(
      sections,
      sectionIndex,
      sectionIndex + diff
    );
    setSections(newSections);
  };

  const setSections = (newSections: any) => {
    formik.setFieldValue("sections", newSections);
    callback(getAllItems(newSections), newSections);
  };

  return (
    <div>
      {getSortedValuesWithKeyAsId(sections).map((sectionInfo, sectionIndex) => {
        const sectionId = sectionInfo.id;
        const value = formik.values.sections[sectionId];
        const inputId = "section_title_" + sectionId;
        const title =
          Object.keys(sections).length < 2 ? null : (
            <div style={{display: "flex"}}>
              <AdminInput
                id={inputId}
                value={value != null ? value["title"] : ""}
                fieldInfo={SectionStructure["title"]}
                handleChange={(e: any) => {
                  const sectionsCopy = Object.assign({}, sections);
                  const newSections = Object.assign(sectionsCopy, {
                    [sectionId]: {
                      ...sectionsCopy[sectionId],
                      title: e.target.value,
                    },
                  });
                  formik.setFieldValue("sections", newSections);
                  callback(getAllItems(newSections), newSections);
                }}
              />
              <SectionActionButton
                style={{marginLeft: "100px"}}
                onClick={() => {
                  reorder(sectionIndex, -1);
                }}
              >
                <GrLinkUp />
              </SectionActionButton>
              <SectionActionButton
                onClick={() => {
                  reorder(sectionIndex, 1);
                }}
              >
                <GrLinkDown />
              </SectionActionButton>
              <SectionActionButton
                style={{color: "red"}}
                hidden={sectionInfo.items.length !== 0}
                onClick={() => {
                  let counter = 0;
                  const newSections = {};
                  getSortedValuesWithKeyAsId(sections).forEach((section) => {
                    if (section.id !== sectionId) {
                      const copySection = Object.assign({}, section);
                      // @ts-ignore
                      newSections[section.id] = Object.assign(copySection, {
                        index: counter,
                      });
                      counter++;
                    }
                  });
                  setSections(newSections);
                }}
              >
                <FiMinusCircle />
              </SectionActionButton>
            </div>
          );

        let style = {
          position: "relative",
          minHeight: "200px",
        };
        return (
          <div style={{width: "100%"}} key={sectionId}>
            {title}
            <Droppable droppableId={sectionId} key={sectionId}>
              {(provided: any) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={style}
                >
                  {sectionInfo.items.map((savedId: any, index: any) => {
                    return (
                      <DNDRow
                        keySuffix={sectionId + "_"}
                        key={sectionId + "_" + savedId}
                        index={index}
                        contentId={savedId}
                        type={type}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}
      <AddNewSectionContainer
        hidden={type !== "modifierGroup"}
        onClick={() => {
          const count = Object.keys(sections).length;
          const sectionInfoId = randomId();
          const newSectionInfo = {
            index: count,
            items: [],
            title: "New Section",
            id: sectionInfoId,
          };

          const newSections = Object.assign(
            {[sectionInfoId]: newSectionInfo},
            sections
          );
          setSections(newSections);
        }}
      >
        + Add New Section
      </AddNewSectionContainer>
    </div>
  );
};

export interface DNDEditorProps {
  sections: any;
  fieldKey: any;
  formik: any;
  savedIds: any;
  libraryIds: any;
  type: any;
  callback: any;
}

export const DNDEditor = React.memo((props: DNDEditorProps) => {
  const {sections, libraryIds, type, callback} = props;
  const {height} = useWindowSize();
  const allValueIds = Object.values(sections)
    .map((section: any) => {
      return section.items;
    })
    .reduce(function (accumulator, currentValue) {
      return accumulator.concat(currentValue);
    }, []);

  const [searchText, setSearchText] = useState("");
  const filteredLibraryIds = libraryIds.filter(
    (id: any) => !allValueIds.includes(id)
  );

  return (
    <div>
      <DragDropContext
        onDragEnd={(data: any) => {
          const {destination, source, draggableId} = data;

          if (destination == null) {
            return;
          }

          const [sectionId, itemId] = draggableId.split("_");
          let newSections = Object.assign({}, sections);
          if (sections != null) {
            newSections = Object.assign({}, sections);
            if (source.droppableId !== "libraryList") {
              const sourceSection = Object.assign(
                {},
                newSections[source.droppableId]
              );
              const copyOfSource = Array.from(sourceSection.items);
              copyOfSource.splice(source.index, 1);
              newSections[source.droppableId] = Object.assign(sourceSection, {
                items: copyOfSource,
              });
            }
            if (destination.droppableId !== "libraryList") {
              const destSection = Object.assign(
                {},
                newSections[destination.droppableId]
              );
              const copyOfDest = Array.from(destSection.items);
              copyOfDest.splice(destination.index, 0, itemId);
              newSections[destination.droppableId] = Object.assign(
                destSection,
                {
                  items: copyOfDest,
                }
              );
            }
          }

          callback(getAllItems(newSections), newSections);
        }}
      >
        <DroppableContainer>
          <ColumnContainer>
            <MainColumn style={{height: `${height - 120}px`}}>
              <MainSections
                sections={sections}
                type={type}
                callback={callback}
              />
            </MainColumn>
            <LibraryColumn style={{height: `${height - 120}px`}}>
              <LibrarySearchBarContainer>
                <LibrarySearchBar
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  placeholder={"Search"}
                />
              </LibrarySearchBarContainer>

              <LibraryColumnWrapper>
                <Droppable droppableId={"libraryList"}>
                  {(provided: any) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{minHeight: "300px"}}
                    >
                      {filteredLibraryIds.map((libraryId: any, index: any) => {
                        return (
                          <DNDRow
                            searchText={searchText}
                            keySuffix={"library_"}
                            key={"library_" + libraryId}
                            index={index}
                            contentId={libraryId}
                            type={type}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </LibraryColumnWrapper>
            </LibraryColumn>
          </ColumnContainer>
        </DroppableContainer>
      </DragDropContext>
    </div>
  );
}, areEqualIds);
