import React, {useState} from "react";
import {useSelector} from "react-redux";
//Local
import {StyledFormLayout} from "./styles";
import {LoginPage} from "./login/views/LoginPage";
import {ForgotPasswordPage} from "./forgot-password/views/ForgotPasswordPage";
import {CheckYourEmailPage} from "./check-your-email/views/CheckYourEmailPage";
//Shared
import {IStore} from "../_shared/slices/types/Store";
import {useGlobalConfiguration} from "../_shared/hooks/useGlobalConfiguration";
import {enforceAuth} from "../_shared/navigation";
import {useUrl} from "../_shared/hooks/useUrl";
import useMountEffect from "../_shared/hooks/useMountEffect";
import {Utilities} from "../../database/firebase";
import {getParameter} from "../_shared/cookies";
import {ResetPasswordPage} from "./reset-password/views/ResetPasswordPage";

enum Pages {
  LOGIN = "login",
  FORGOT_PASSWORD = "forgot-password",
  CHECK_YOUR_EMAIL = "check-your-email",
  RESET_PASSWORD = "reset-password",
}

function getPasswordResetCode() {
  return getParameter("prc", true);
}

const LoginManager = () => {
  const userInitialized = useSelector(
    (state: IStore) => state.global.userInitialized
  );
  const {url} = useUrl();
  const {IS_SSD_LIVE} = useGlobalConfiguration();

  const [page, setPage] = useState<string>(Pages.LOGIN);
  const [email, setEmail] = useState<string>();
  const [prc, setPrc] = useState<any | null>(null);

  const toLogin = () => {
    setPage(Pages.LOGIN);
  };

  const toForgotPassword = (email: string) => {
    setEmail(email);
    setPage(Pages.FORGOT_PASSWORD);
  };

  const toCheckYourEmail = (email: string) => {
    setPage(Pages.CHECK_YOUR_EMAIL);
    setEmail(email);
  };

  const getPageComponent = (page?: string) => {
    if (page) {
      switch (page) {
        case Pages.FORGOT_PASSWORD:
          return (
            <ForgotPasswordPage
              toLogin={toLogin}
              toCheckYourEmail={toCheckYourEmail}
              email={email}
            />
          );
        case Pages.CHECK_YOUR_EMAIL:
          return email ? (
            <CheckYourEmailPage toLogin={toLogin} email={email} />
          ) : null;
        case Pages.RESET_PASSWORD:
          return (
            <ResetPasswordPage
              toLogin={toLogin}
              toCheckYourEmail={toCheckYourEmail}
              email={email}
              prc={prc}
            />
          );
        default:
          return <LoginPage toForgotPassword={toForgotPassword} />;
      }
    }
  };

  useMountEffect(async () => {
    const prc = getPasswordResetCode();
    if (prc) {
      const prcStatus = await Utilities.getDocData(
        Utilities.collection("password_reset_codes").doc(prc)
      );
      if (prcStatus != null) {
        setPrc(prcStatus);
        setPage(Pages.RESET_PASSWORD);
      }
    }
  });

  if (!IS_SSD_LIVE || !enforceAuth(url) || userInitialized !== false) {
    return null;
  }

  // We check if it is false because the default value is null
  return <StyledFormLayout>{getPageComponent(page)}</StyledFormLayout>;
};

export {LoginManager};
