import React, {useEffect, useState} from "react";

//Hooks
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";

//Views
import {MainView} from "../../../views/MainView";
import {ProductView} from "../../../views/ProductView";
import {ProductLineView} from "../../../views/ProductLineView";

//Core
import {isEmpty, Project} from "@natomas/core";

//Shared
import {Path} from "../Path";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {updateProductGroup} from "../../../../_shared/catalog/ProductCatalogHelper";
import {
  checkForFilter,
  deleteFilterByType,
  FilterType,
  getAddressFilter,
  getProductLineFilter,
  tryFilter,
  tryFilterByType,
} from "../../../../_shared/catalog/filter";

//Styles
import {CatalogContainer, NotServiceableBlurb} from "../../../views/styles";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {toPortal} from "../../../../_shared/navigation";

const Catalog = () => {
  /*
   * Catalog has currently two modes that are anticipated.
   * 1. Website Catalog (simply browsing)
   * 2. Portal Catalog (browsing and selection)
   * */
  enum CatalogMode {
    NONE,
    PRODUCT_LINES,
    PRODUCT_LINE,
    PRODUCT,
  }

  //Custom Hooks
  const {productGroupId, productId, product, availableProductGroups, filters} =
    useProductCatalog();
  const {projectSummary} = useCurrentProject();
  const fullWidth = useDynamicValue(fullColumnWidths);

  const determineMode = () => {
    if (productId) {
      return CatalogMode.PRODUCT;
    } else if (productGroupId) {
      return CatalogMode.PRODUCT_LINE;
    } else if (availableProductGroups && !isEmpty(availableProductGroups)) {
      if (availableProductGroups.length > 1) {
        return CatalogMode.PRODUCT_LINES;
      } else if (availableProductGroups.length === 1) {
        if (!productGroupId) updateProductGroup(availableProductGroups[0].id);
        return CatalogMode.PRODUCT_LINE;
      }
    }
    return CatalogMode.NONE;
  };

  const [mode, setMode] = useState<CatalogMode>(determineMode());

  const checkMode = () => {
    let m = determineMode();
    if (m !== mode) setMode(m);
  };

  useEffect(() => {
    checkMode();
    if (productGroupId) {
      tryFilter(getProductLineFilter(productGroupId, productGroupId), filters);
    } else {
      if (checkForFilter(FilterType.ProductLine, filters))
        deleteFilterByType(FilterType.ProductLine);
    }
  }, [productId, productGroupId, availableProductGroups]);

  useEffect(() => {
    if (!projectSummary) return;
    const projectAddress = Project.getAddress(projectSummary);
    tryFilterByType(
      getAddressFilter(projectAddress.county, projectAddress.state),
      filters
    );
  }, [filters, projectSummary]);

  const getContent = () => {
    switch (mode) {
      case CatalogMode.PRODUCT:
        return <ProductView product={product} />;
      case CatalogMode.PRODUCT_LINE:
        if (!productGroupId) return <></>;
        else
          return (
            <ProductLineView
              showPath={showPath()}
              productGroupId={productGroupId}
            />
          );
      case CatalogMode.PRODUCT_LINES:
        return <MainView availableGroups={availableProductGroups || []} />;
      default:
        return <></>;
    }
  };

  const showPath = () => {
    return availableProductGroups && availableProductGroups.length > 1;
  };

  const NonServiceableBlurb = () => {
    if (availableProductGroups === undefined) return <></>;
    else if (availableProductGroups.length === 0) {
      return (
        <PageContainer>
          <PageElement size={fullWidth} height={"16rem"}>
            <NotServiceableBlurb>
              Sorry, there are no available ADUs in your area yet. We will
              contact you once we can service your area.
              <NatButton
                label={"Back to Dashboard"}
                type={"button"}
                trackingId={"catalog-back-to-dashboard"}
                option={StyleOption.PRIMARY_COLOR}
                clickEvent={() => toPortal()}
              />
            </NotServiceableBlurb>
          </PageElement>
        </PageContainer>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Path showMainView={showPath()} />
      <NonServiceableBlurb />
      <CatalogContainer>{getContent()}</CatalogContainer>
    </>
  );
};

export {Catalog};
