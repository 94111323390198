import React, {useState} from "react";
import {WaitingForDesign} from "../../_shared/components/WaitingForDesign";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {DesignSummaryContent} from "./components/DesignSummaryContent";
import {DesignContainer} from "./styles";
import {DesignSummaryList} from "./components/DesignSummaryList";
import {isMobile} from "../../../_shared/navigation";
import {useProduct} from "../../../_shared/hooks/useProductCatalog";
import {usePage} from "../../../_shared/hooks/usePage";

export const DesignStudioView = () => {
  const {configurationSnapshot, configuration} = useCurrentProject();
  const {product} = useProduct(configuration?.product?.id);
  const [selectedConfigurationPage, setSelectedConfigurationPage] =
    useState(null);
  const {isNatMobile} = usePage();
  if (product == null || configurationSnapshot == null) {
    return (
      <DesignContainer isMobile={isNatMobile()}>
        <WaitingForDesign relative={isMobile()} />
      </DesignContainer>
    );
  }

  if (isNatMobile()) {
    return (
      <DesignContainer id={"design-view-container"} isMobile={isNatMobile()}>
        <DesignSummaryContent
          configuration={configuration}
          currentProduct={product}
          configurationPage={selectedConfigurationPage}
          configurationSnapshot={configurationSnapshot}
        />
        <DesignSummaryList
          configuration={configuration}
          configurationSnapshot={configurationSnapshot}
          currentProduct={product}
          selectedPage={selectedConfigurationPage}
          setSelectedPage={setSelectedConfigurationPage}
        />
      </DesignContainer>
    );
  }

  return (
    <DesignContainer isMobile={isNatMobile()}>
      <DesignSummaryList
        configuration={configuration}
        configurationSnapshot={configurationSnapshot}
        currentProduct={product}
        selectedPage={selectedConfigurationPage}
        setSelectedPage={setSelectedConfigurationPage}
      />
      <DesignSummaryContent
        configuration={configuration}
        currentProduct={product}
        configurationPage={selectedConfigurationPage}
        configurationSnapshot={configurationSnapshot}
      />
    </DesignContainer>
  );
};
