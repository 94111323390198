import {
  auth,
  logOut,
  signInWithGoogle,
  Utilities,
  Authentication,
} from "../../../database/firebase";
import logo from "../../../assets/logos/google-logo.png";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {signInValidationStructure} from "../models/AccountForm";
import React, {useEffect, useState} from "react";
import {Customer, isBlankString} from "@natomas/core";
import {setURLQuery} from "../navigation/_helpers";
import {useHistory} from "react-router-dom";
import useMountEffect from "../hooks/useMountEffect";
import {setShowLogin} from "../slices/AuthSlice";
import {Avatar} from "./Avatar";
import {getParameter} from "../cookies";
import {enforceAuth, toIntakeForm} from "../navigation";
import {useGlobalConfiguration} from "../hooks/useGlobalConfiguration";
import {useUrl} from "../hooks/useUrl";
import {resetConfiguration} from "../../design-tool/slices/configurationSlice";
import {resetUser} from "../slices/GlobalSlice";
import {store} from "../../../store";
import {useCurrentUser} from "../hooks/useCurrentUser";
import {hardResetFilters} from "../slices/CatalogSlice";
import {resetIntakeForm} from "../../intake-form/slices/IntakeFormSlice";

const SignInWithGoogleButton = styled.button`
  border-radius: 10px;
  height: 52px;
  margin-top: 42px;
  margin-bottom: 20px;
  width: 100%;
`;

const SignInInput = styled.input`
  height: ${(props) => (props.hideInput === true ? "1px" : "52px")};
  margin-top: ${(props) => (props.hideInput === true ? "0px" : "18px")};
  width: 100%;
  border-width: 2px;
  border-color: black;
  opacity: ${(props) => (props.hideInput === true ? 0 : 1)};
`;

const Container = styled.div`
  max-width: 350px;
  width: 100%;
`;

const UserInfoContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const AvatarContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

const DetailsContainer = styled.div`
  flex-grow: 2;
  text-align: left;
`;

const OrDiv = styled.div`
  background-color: white;
  position: absolute;
  top: -20px;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  line-height: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
`;

const SecondaryButton = styled.div`
  width: 45%;
  display: ${(props) => (props.hidden === true ? "none" : "block")};
  flex-grow: 1;
  transition: all 0.3s;
  margin-right: 20px;
`;

const PrimaryButton = styled.button`
  width: 45%;
  flex-grow: 1;
`;

const HideWhenTypingInPassword = styled.div`
  display: ${(props) => (props.typingPassword === true ? "none" : "block")}; ;
`;

const EMAIL_KEY = "email";
function getPasswordResetCode() {
  return getParameter("prc", true);
}

const ResetPasswordView = (props) => {
  const {formik, setShowResetView, prc} = props;
  const {values} = formik;
  const [status, setStatus] = useState("start");
  const [error, setError] = useState(null);
  const history = useHistory();

  useMountEffect(() => {
    if (prc != null) {
      const email = prc.email;
      formik.setFieldValue(EMAIL_KEY, email);
      setStatus("ready");
    }
  });

  return (
    <div className={"modal"}>
      <center
        className={"modal-content"}
        style={{maxWidth: "560px", width: "90%", alignItems: "center"}}
      >
        <Container>
          <div
            className={"bold large-text"}
            style={{
              marginTop: "21px",
              color: "black",
            }}
          >
            {"Set New Password"}
          </div>
          <form
            target="hiddenLogInFrame"
            onSubmit={() => {
              const {password} = values;
              const email = values[EMAIL_KEY];
              if (status === "resetting" || status === "start") {
                return;
              }

              if (
                !isBlankString(email) &&
                !isBlankString(password) &&
                status === "ready"
              ) {
                if (password.length >= 6) {
                  setStatus("resetting");
                  Authentication.resetPassword(
                    password,
                    getPasswordResetCode()
                  ).then((data) => {
                    if (data != null) {
                      Authentication.signInWithEmail(email, password).then(
                        () => {
                          setShowResetView(false);
                          window.location.reload();
                        }
                      );
                    }
                  });

                  setURLQuery(history, "prc", null);
                } else {
                  setError("Password needs to be at least 6 characters");
                  setTimeout(() => {
                    setError(null);
                  }, 5000);
                }
              }
            }}
          >
            <SignInInput
              id={"email-input"}
              className={"formik-input"}
              placeholder={"Email"}
              name={EMAIL_KEY}
              type={"email"}
              value={values[EMAIL_KEY]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => {
                formik.setFieldTouched(EMAIL_KEY, false);
              }}
              disabled={true}
              autoComplete={"username"}
              style={{
                borderColor: "rgba(0,0,0,0.1)",
              }}
            />

            <SignInInput
              id={"password-input"}
              className={"formik-input"}
              placeholder={"New Password"}
              name={"password"}
              value={values["password"]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={"password"}
              autoFocus={true}
              autoComplete="new-password"
            />
            <div style={{fontSize: 14, marginTop: "6px", color: "red"}}>
              {error}
            </div>

            <ButtonContainer>
              <PrimaryButton
                className={"button-primary bold"}
                id={"set-password"}
              >
                {"Set Password"}
              </PrimaryButton>
            </ButtonContainer>
            <div
              className={"fullDiv"}
              style={{
                backgroundColor: "rgba(255,255,255,0.3)",
                display: status !== "resetting" ? "none" : "block",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </form>
          <div
            style={{
              marginTop: "30px",
              marginBottom: "32px",
            }}
          >
            <span>Want to sign in instead? </span>
            <span
              className={"clickable text-link bold"}
              onClick={() => {
                setShowResetView(false);
              }}
            >
              Sign In
            </span>
          </div>
        </Container>
      </center>

      <iframe
        name="hiddenLogInFrame"
        width="0"
        height="0"
        border="0"
        style={{display: "none"}}
      />
    </div>
  );
};

export const AccountLogInModal = () => {
  const dispatch = useDispatch();
  const {IS_SSD_LIVE} = useGlobalConfiguration();
  const user = useSelector((state) => state.global.user);
  const userInfo = useCurrentUser().user;
  const userInitialized = useSelector((state) => state.global.userInitialized);
  const {url} = useUrl();
  const show = useSelector((state) => state.auth.showLogin);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [sentReset, setSentReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showResetView, setShowResetView] = useState(false);
  const [prc, setPrc] = useState(null);
  const formik = useFormik({
    initialValues: {
      [EMAIL_KEY]: "",
      password: "",
    },
    validationSchema: signInValidationStructure,
    onSubmit: (values) => {
      return Authentication.signInWithEmail(values[EMAIL_KEY], values.password);
    },
  });
  const {touched, values, errors} = formik;

  useEffect(() => {
    if (!isBlankString(values.password) && !showPasswordInput) {
      setShowPasswordInput(true);
    }
  }, [values, showPasswordInput]);

  useMountEffect(async () => {
    const prc = getPasswordResetCode();
    if (!isBlankString(prc)) {
      const prcStatus = await Utilities.getDocData(
        Utilities.collection("password_reset_codes").doc(prc)
      );
      if (prcStatus != null) {
        setPrc(prcStatus);
        setShowResetView(true);
      }
    }
  });

  if (userInitialized === null || !enforceAuth(url)) {
    return null;
  }

  if (user != null && show === true) {
    return (
      <div className={"modal"}>
        <div
          className={"fullDiv"}
          onClick={() => {
            dispatch(setShowLogin(false));
          }}
        />
        <center
          className={"modal-content"}
          style={{maxWidth: "560px", width: "90%", alignItems: "center"}}
        >
          <Container>
            <UserInfoContainer>
              <AvatarContainer>
                <Avatar customer={userInfo} />
              </AvatarContainer>
              <DetailsContainer>
                <div
                  className={"bold large-text"}
                  style={{whiteSpace: "nowrap"}}
                >
                  {user.displayName ?? Customer.getFullName(userInfo)}
                </div>
                <div style={{fontStyle: "italic", whiteSpace: "nowrap"}}>
                  {user.email}
                </div>
              </DetailsContainer>
            </UserInfoContainer>
            <button
              className={"button-primary bold"}
              style={{width: "100%", marginBottom: "20px"}}
              onClick={() => {
                logOut();
                store.dispatch(resetUser());
                store.dispatch(resetConfiguration());
                store.dispatch(hardResetFilters());
                store.dispatch(resetIntakeForm());
                setShowPasswordInput(false);
                formik.resetForm();
                store.dispatch(setShowLogin(false));
              }}
            >
              Log Out
            </button>
          </Container>
        </center>
      </div>
    );
  } else if (user != null && show !== true) {
    return null;
  }

  if (showResetView === true) {
    return null;
    return (
      <ResetPasswordView
        formik={formik}
        prc={prc}
        setShowResetView={setShowResetView}
      />
    );
  }
  if (!IS_SSD_LIVE) {
    return (
      <div className={"modal"}>
        <center
          className={"modal-content"}
          style={{maxWidth: "560px", width: "90%", alignItems: "center"}}
        >
          <Container>
            <div
              className={"bold large-text"}
              style={{
                marginTop: "21px",
                color: errorMessage != null ? "rgb(220,0,0)" : "black",
              }}
            >
              {errorMessage != null ? errorMessage : "Sign In"}
            </div>
            <HideWhenTypingInPassword typingPassword={showPasswordInput}>
              <SignInWithGoogleButton
                className="button-secondary"
                onClick={() => {
                  signInWithGoogle();
                }}
              >
                <img height="30px" src={logo} alt="google icon" />
                <span style={{marginLeft: "10px"}}> Continue with Google</span>
              </SignInWithGoogleButton>
              <div style={{position: "relative", marginBottom: "32px"}}>
                <hr style={{borderColor: "black"}} />
                <OrDiv>or</OrDiv>
              </div>
            </HideWhenTypingInPassword>
            <form
              target="hiddenLogInFrame"
              onSubmit={(e) => {
                if (showPasswordInput) {
                  Authentication.signInWithEmail(
                    values[EMAIL_KEY],
                    values.password
                  ).catch((error) => {
                    if (error.code === "auth/wrong-password") {
                      setErrorMessage("Incorrect Password");
                    } else if (error.code === "auth/user-not-found") {
                      setErrorMessage("Invalid Email");
                    }

                    setTimeout(() => {
                      setErrorMessage(null);
                    }, 5000);
                  });
                  return;
                }

                e.preventDefault();
                if (!errors[EMAIL_KEY]) {
                  setShowPasswordInput(true);
                  setSentReset(false);
                  setTimeout(() => {
                    const passwordInput =
                      document.getElementById("password-input");
                    if (passwordInput != null) {
                      passwordInput.focus();
                    }
                  }, 10);
                }
              }}
            >
              <SignInInput
                className={"formik-input"}
                placeholder={"Email"}
                name={EMAIL_KEY}
                type={"email"}
                value={values[EMAIL_KEY]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={() => {
                  formik.setFieldTouched(EMAIL_KEY, false);
                }}
                autoComplete={"username"}
                disabled={showPasswordInput}
                style={{
                  color:
                    errors[EMAIL_KEY] && touched[EMAIL_KEY] ? "red" : "black",
                  borderColor: showPasswordInput ? "rgba(0,0,0,0.1)" : "black",
                }}
              />

              <SignInInput
                id={"password-input"}
                className={"formik-input"}
                placeholder={"Password"}
                name={"password"}
                value={values["password"]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={"password"}
                hideInput={!showPasswordInput}
                autoComplete="current-password"
              />

              <ButtonContainer>
                <SecondaryButton
                  className={"button-secondary bold"}
                  onClick={() => {
                    formik.setFieldValue("password", "");
                    setShowPasswordInput(false);
                    setErrorMessage(null);
                  }}
                  hidden={!showPasswordInput}
                >
                  Back
                </SecondaryButton>
                <PrimaryButton className={"button-primary bold"}>
                  {showPasswordInput ? "Sign In" : "Next"}
                </PrimaryButton>
              </ButtonContainer>
            </form>
            <HideWhenTypingInPassword
              typingPassword={showPasswordInput}
              style={{
                marginTop: "30px",
                marginBottom: "32px",
              }}
            >
              <span>Don't have an account? </span>
              <span
                className={"clickable text-link bold"}
                onClick={toIntakeForm}
              >
                Sign Up
              </span>
            </HideWhenTypingInPassword>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "32px",
                display: showPasswordInput ? "block" : "none",
              }}
            >
              <span>Forgot password? </span>
              <span
                className={
                  "clickable text-link bold " + (sentReset ? "unclickable" : "")
                }
                onClick={() => {
                  if (!sentReset) {
                    auth
                      .sendPasswordResetEmail(values[EMAIL_KEY])
                      .then(function () {})
                      .catch(function (e) {
                        console.log(e);
                      });

                    setSentReset(true);
                  } else {
                  }
                }}
              >
                {sentReset
                  ? "Check your email :)"
                  : "Send password reset email"}
              </span>
            </div>
          </Container>
        </center>

        <iframe
          name="hiddenLogInFrame"
          width="0"
          height="0"
          border="0"
          style={{display: "none"}}
        />
      </div>
    );
  } else {
    return null;
  }
};
