//Global
import React, {useState} from "react";
//Shared
import {StyledForm, StyledFormik, StyledRow} from "../../../_shared/styles";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
import {getFormStructure} from "../../../../_shared/application";
//Local
import {ForgotPasswordFormModel} from "../../logic/models";
import {getForgotPasswordFormik} from "../../logic/constants";
import {IntakeFormInput} from "../../../../_shared/forms/FormComponentFactory";
import {isBlankString} from "@natomas/core";
import {EMAIL_KEY} from "../../../_shared/models";

interface IForgotPasswordForm {
  toLogin: any;
  toCheckYourEmail: any;
  email?: string;
}

export const ForgotPasswordForm = (props: IForgotPasswordForm) => {
  const {toLogin, toCheckYourEmail, email} = props;
  const formStructure = getFormStructure(ForgotPasswordFormModel);
  const [loading, setLoading] = useState(false);
  const form = getForgotPasswordFormik((email: string) => {
    if (email) {
      toCheckYourEmail(email);
    } else {
      setLoading(true);
    }
  }, email);
  return (
    <>
      <StyledFormik
        initialValues={form.initialValues}
        validationSchema={form.validationSchema}
        onSubmit={form.onSubmit}
      >
        {(props) => (
          <StyledForm onSubmit={props.handleSubmit}>
            {formStructure.map((field) => {
              return (
                <div key={field.key} style={{width: "100%", marginTop: "20px"}}>
                  <IntakeFormInput
                    formik={props}
                    inputType={field}
                    disableErrors={true}
                  />
                </div>
              );
            })}
            <StyledRow>
              <NatButton
                label={"Back to Login"}
                trackingId={"forgot-password-back-to-login"}
                option={StyleOption.SECONDARY}
                size={TextSize.NORMAL}
                type={"button"}
                clickEvent={toLogin}
                spinnerEnabled={false}
              />
              <NatButton
                disabled={
                  isBlankString(props.values[EMAIL_KEY]) ||
                  props.errors[EMAIL_KEY] != null
                }
                trackingId={"forgot-password-send-email"}
                label={"Send email"}
                option={StyleOption.PRIMARY}
                type={"submit"}
                size={TextSize.NORMAL}
                spinnerEnabled={true}
              />
            </StyledRow>
          </StyledForm>
        )}
      </StyledFormik>
      <div
        className={"fullDiv"}
        style={{
          backgroundColor: "rgba(255,255,255,0.3)",
        }}
        hidden={!loading}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    </>
  );
};
