import styled from "styled-components";

const PortalLayout = styled.div`
  height: 100%;
  display: flex;
`;

const MainContentWrapper = styled.div`
  z-index: 1;
  height: 100%;
  background: white;
`;

const Header = styled.div`
  margin-left: ${(props: {isMobile: any}) =>
    props?.isMobile() ? "90px" : "40px"};
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-family: Maison-Medium, sans-serif;
  margin-top: 40px;
`;

const GridHeader = styled.div<{addPadding: boolean; isMobile: any}>`
  text-align: ${(props) => (props?.isMobile() ? "center" : "left")};
  padding: 0 ${(props) => (props.addPadding ? "2.5rem" : 0)};
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: Maison-Medium, sans-serif;
  margin: 1.25rem 0;
`;

export {Header, GridHeader, PortalLayout, MainContentWrapper};
