import {useEffect, useState} from "react";
import {
  deleteRenderingFromCategory,
  saveRenderingToCategory,
} from "../backend/catalogApi";
import {ImageUploader} from "./ImageUploader";
import {Underline} from "./underline";
import {useFormik} from "formik";
import {isBlankString} from "@natomas/core";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {ModifierSelect} from "../../admin/products/components/AdminComponentFactory";
import styled from "styled-components";

const renderingProperties = {
  overlayGroupId: "",
  zIndex: "",
  orientation: "",
  objectFit: "",
};

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  margin-top: 20px;
`;

const findModifierIDsForFileName = (fileName, allModifiers) => {
  const fileNameParts = fileName.toLowerCase().split("_");
  const modifierMap = {};
  Object.keys(allModifiers)
    .filter((modifierId) => {
      const modifier = allModifiers[modifierId];
      let modifierTitle = modifier.title;
      if (modifierTitle == null) {
        return false;
      }
      modifierTitle = modifierTitle.toLowerCase();

      if (modifierTitle.length > 8) {
        modifierTitle = modifierTitle.substr(0, 8);
      }
      for (const fileNamePart of fileNameParts) {
        if (
          modifierTitle.startsWith(fileNamePart) ||
          fileNamePart.startsWith(modifierTitle)
        ) {
          return true;
        }
      }
      return false;
    })
    .forEach((modifierId) => {
      modifierMap[modifierId] = true;
    });
  return modifierMap;
};

export const EditRenderingForCategory = (props) => {
  const {
    rendering,
    renderingImageId,
    configurationPage,
    leftOverModifiers,
  } = props;
  const [imageId, setImageId] = useState(renderingImageId);
  const [showMore, setShowMore] = useState(false);
  const {currentGroupModifiers} = useProductCatalog();

  let initialModifiers = {};
  if (leftOverModifiers != null) {
    leftOverModifiers.forEach((modifierId) => {
      initialModifiers[modifierId] = true;
    });
  } else if (rendering.requiredModifiers != null) {
    initialModifiers = rendering.requiredModifiers;
  }
  const [newSelections, setNewSelections] = useState(initialModifiers);

  const formik = useFormik({
    initialValues: renderingProperties,
    onSubmit: (values) => {
      const valuesToSave = {};
      valuesToSave.zIndex = isBlankString(values.zIndex)
        ? null
        : parseInt(values.zIndex);
      valuesToSave.overlayGroupId = isBlankString(values.overlayGroupId)
        ? null
        : values.overlayGroupId;
      valuesToSave.orientation = isBlankString(values.orientation)
        ? null
        : values.orientation;
      valuesToSave.objectFit = isBlankString(values.objectFit)
        ? null
        : values.objectFit;
      return saveRendering(valuesToSave);
    },
  });

  useEffect(() => {
    setNewSelections(initialModifiers);
    formik.resetForm();
    for (const key in renderingProperties) {
      if (key === "zIndex" && rendering[key] != null) {
        formik.setFieldValue(key, "" + rendering[key]);
      } else if (rendering[key] != null) {
        formik.setFieldValue(key, rendering[key]);
      }
    }
  }, [rendering]);

  useEffect(() => {
    setImageId(renderingImageId);
  }, [renderingImageId]);

  const onSelectionChange = (value) => {
    setNewSelections(value);
  };

  const saveRendering = (valuesToSave) => {
    const updatedRendering = {
      ...valuesToSave,
      imageId: imageId,
      requiredModifiers: newSelections,
      id: rendering.id,
    };
    return saveRenderingToCategory(configurationPage, updatedRendering);
  };

  const deleteRendering = () => {
    return deleteRenderingFromCategory(configurationPage, rendering.id);
  };

  let moreDetailsContainer = null;
  if (showMore === true) {
    moreDetailsContainer = Object.keys(renderingProperties).map((key) => {
      return (
        <div key={key}>
          <label htmlFor={key} style={{marginRight: "20px"}}>
            {key}:
          </label>
          <input
            id={key}
            name={key}
            type="text"
            onChange={formik.handleChange}
            value={formik.values[key]}
          />
          <br />
        </div>
      );
    });
  }

  return (
    <div style={{minHeight: "400px"}}>
      <Underline className="option-underline" />
      <br></br>
      <div style={{maxWidth: "500px", position: "relative", zIndex: 11}}>
        <ModifierSelect value={newSelections} onChange={onSelectionChange} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        {moreDetailsContainer}
        <div
          className="clickable"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {showMore ? "Show Less" : "Show More"}
        </div>
        <button type="submit">Submit</button>
        <span
          style={{marginLeft: "20px"}}
          className="clickable"
          onClick={() => {
            return deleteRendering();
          }}
        >
          Delete
        </span>
      </form>
      <ImageContainer>
        <ImageUploader
          key={imageId}
          imageIdValue={imageId}
          onComplete={(value) => {
            setImageId(value);
          }}
          drop={true}
          fileNameCallback={(name) => {
            if (false) {
              setNewSelections(
                findModifierIDsForFileName(name, currentGroupModifiers)
              );
            }
          }}
        />
      </ImageContainer>
    </div>
  );
};
