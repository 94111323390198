import React, {useEffect, useMemo} from "react";
import {store} from "../../../store";
//Core
import {Customer, Event, EventStatus, IEventInfo} from "@natomas/core";
//Shared
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {Page} from "../../_shared/generics/page";
import {Catalog} from "../_shared/components/Catalog";
import {CatalogHeader} from "../_shared/components/Header";
import {updateAdditionLeftPadding} from "../../_shared/slices/PageSlice";
import {ProductDiscoveryView} from "./ProductDiscoveryView";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import {usePage} from "../../_shared/hooks/usePage";
import {customerInfoToNatomasCustomer} from "../../../database/firebase/adapters";
import {Users} from "../../../database/firebase";
import {applyReset} from "../../_shared/catalog/filter";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {useTracking} from "../../_shared/hooks/useTracking";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";

const CatalogContent = (props: any) => {
  const {customer, firstTimeUser, completeDiscovery} = props;
  if (customer && firstTimeUser) {
    return <ProductDiscoveryView completeDiscovery={completeDiscovery} />;
  } else {
    return <Catalog />;
  }
};

export const PortalCatalog = () => {
  //Custom Hooks
  const {loggedInUser} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {filteredProducts} = useProductCatalog();
  const {markPageView} = useTracking();
  const {leftPadding} = usePage();

  //Effects
  useEffect(() => {
    //Catalog view has no sidebar - make sure padding is reset.
    store.dispatch(updateAdditionLeftPadding(0));
    markPageView("Product Catalog");
    return () => {
      //Reset when we leave portal
      applyReset();
    };
  }, []);

  const firstTimeUser = useMemo(() => {
    //See if event exists
    let r = Customer.getEvents(customer).find(
      (event: IEventInfo) => event.event_id === "catalog_form"
    );
    //Check to see if completed
    return r?.details?.status !== EventStatus.COMPLETED;
  }, [customer]);

  const completeDiscovery = () => {
    if (!firstTimeUser) {
      applyReset();
      return;
    }

    const event = Event.createGenericEvent(
      "catalog_form",
      Date.now(),
      EventStatus.COMPLETED
    );
    const newInfo = Customer.addEvent(customer, event);
    const natUser = customerInfoToNatomasCustomer(newInfo);
    Users.setNatomasUser(natUser.user_id, natUser);
    applyReset();
  };

  const showLoading = () => {
    return leftPadding !== 0 && filteredProducts !== undefined;
  };

  return (
    <>
      <LoadingPanel show={showLoading()} />
      <CatalogHeader disableOptions={!loggedInUser} />
      <Page>
        <CatalogContent
          customer={customer}
          firstTimeUser={firstTimeUser}
          completeDiscovery={completeDiscovery}
        />
      </Page>
    </>
  );
};
