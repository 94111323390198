import React, {useEffect} from "react";
import {Route} from "react-router-dom";
import {DesignLayout} from "./components/design-tool/views/DesignLayout";
import {ThankYouLayout} from "./components/design-tool/views/thankyouLayout";
import {Account} from "./components/design-tool/views/account";
import {useSelector} from "react-redux";
import {DesignDashboard} from "./components/design-tool/views/dashboards";
import {IntakeFormLayout} from "./components/intake-form/views/IntakeFormLayout";
import {MappingLayout} from "./components/mapping/views/MappingLayout";
import {ProjectSummaryLayout} from "./components/portal/views";
import {AccountLogInModal} from "./components/_shared/generics/AccountLogInModal";
import {isMobile} from "./components/_shared/navigation";
import {DataManager} from "./components/_shared/generics/DataManager";
import {ProductAdminLayout} from "./components/admin/products/views/ProductAdminLayout";
import {IntakeError} from "./components/intake-form/views/error";
import {isDev} from "./components/_shared/application";
import styled from "styled-components";
import {isProdBackend} from "./database/firebase";
import {MultiFamilyIntakeForm} from "./components/intake-form/views/MultiFamilyIntakeFormLayout";
import {LoginManager} from "./components/forms/LoginManager";
import {PortalCatalog} from "./components/catalog/views/PortalCatalog";
import {useUrl} from "./components/_shared/hooks/useUrl";
import {DeveloperTools} from "./components/_shared/generics/developer-tools";
import {useCurrentCustomer} from "./components/_shared/hooks/useCurrentCustomer";
import {createInstance, MatomoProvider} from "@datapunt/matomo-tracker-react";
import MatomoTracker from "@datapunt/matomo-tracker-js";
import {IStore} from "./components/_shared/slices/types/Store";
import {useCurrentUser} from "./components/_shared/hooks/useCurrentUser";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import {IntercomController} from "./components/_shared/generics/IntercomController";

const FloatingEnvironmentDiv = styled.div<{isProdBackend: boolean}>`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${isProdBackend()
    ? "rgba(255, 0, 0, 0.3)"
    : "rgba(0, 0, 255, 0.3)"};
  color: black;
  border-radius: 8px;
  font-family: Maison-Bold, sans-serif;
  padding: 10px 20px;
  z-index: 100000000000;
`;

const MainRoot = styled.div`
  height: ${isMobile() ? "unset" : "100%"};
`;

let environmentDiv: JSX.Element | null = null;
if (isDev) {
  const title = isProdBackend() ? "PROD" : "STAGING";
  environmentDiv = (
    <FloatingEnvironmentDiv isProdBackend={isProdBackend()}>
      {title}
    </FloatingEnvironmentDiv>
  );
}

export const App = () => {
  const isFirebaseInitialized = useSelector(
    (state: IStore) => state.global.firebaseInitialized === true
  );
  const {} = useUrl();
  const {loggedInUser} = useCurrentUser();
  const {user_id} = useCurrentCustomer();

  const matomoProvider: MatomoTracker = createInstance({
    urlBase: "https://villahomes.matomo.cloud/",
    siteId: 1,
  });

  useEffect(() => {
    matomoProvider.pushInstruction("setUserId", user_id);
  }, [user_id, loggedInUser]);

  if (!isFirebaseInitialized) {
    return null;
  }

  const rootStyle: any = {};
  if (!isMobile()) {
    rootStyle.overflowX = "hidden";
  }

  return (
    <MatomoProvider value={matomoProvider}>
      <MainRoot className="App" style={rootStyle}>
        {environmentDiv}
        <DataManager />
        <LoginManager />
        <AccountLogInModal />
        <ErrorBoundary>
          <IntercomController />
        </ErrorBoundary>
        <Route path="/start">
          <IntakeFormLayout />
        </Route>
        <Route path="/multi-family">
          <MultiFamilyIntakeForm />
        </Route>
        <Route path="/intake-error">
          <IntakeError />
        </Route>
        <Route path="/demo">
          <IntakeFormLayout />
        </Route>
        <Route exact path="/account">
          <Account />
        </Route>
        <Route exact path="/dashboards/design">
          <DesignDashboard />
        </Route>
        <Route exact path="/design">
          <DesignLayout />
        </Route>
        <Route exact path="/admin/products">
          <ProductAdminLayout />
        </Route>
        <Route path="/start-design">
          <DesignLayout />
        </Route>
        <Route exact path="/go">
          <div style={{height: "100vh", width: "100vw"}}>
            <MappingLayout />
          </div>
        </Route>
        <Route exact path="/mapping">
          <div style={{height: "100vh", width: "100vw"}}>
            <MappingLayout />
          </div>
        </Route>
        <Route exact path="/">
          <ProjectSummaryLayout />
        </Route>
        <Route path="/summary">
          <ProjectSummaryLayout />
        </Route>
        <Route exact path="/products">
          <PortalCatalog />
        </Route>
        <Route path="/design-thank-you">
          <ThankYouLayout />
        </Route>
        {!isProdBackend() && <DeveloperTools />}
      </MainRoot>
    </MatomoProvider>
  );
};

export default App;
