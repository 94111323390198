export const ProductOptionKeyStructure = {
	name: "",
};

export const ProductOptionValueStructure = {
	name: "",
	optionKey: "",
	optionKeys: "",
	value: "",
};
