import React from "react";
import intake_address_page from "../../../assets/icons/pngs/intake_address_page.png";
import {Icon} from "../components/icon";
import {SearchLocationInput} from "../logic/searchLocationInput";

export const UNIT_PLACEMENT_KEY = "unitPlacement";
export const DETACHED_PLACEMENT = "detached";
export const ATTACHED_PLACEMENT = "attached";

export const AddressPage = (props) => {
  return (
    <div className="form-wrapper" style={{fontFamily: "Maison-Medium"}}>
      <Icon iconSrc={intake_address_page} iconAlt="address icon" />
      <div style={{width: "350px", maxWidth: "95%"}}>
        <div className="question-wrapper" style={{fontSize: "20px"}}>
          <h3 className="question" style={{fontSize: 48}}>
            Get Started
          </h3>
          <h3 className="question">Where are you looking to build?</h3>
        </div>

        <div className="input-wrapper" style={{fontSize: "16px"}}>
          <SearchLocationInput />
        </div>
      </div>
    </div>
  );
};
