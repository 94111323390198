import React, {useEffect, useRef} from "react";
import {ImgSliderArrows, ImgSliderContainer} from "./styled";
import {SliderArrowLeft, SliderArrowRight} from "../../../../../assets/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";

export const ImageSlider = ({product}) => {
	const [, updateState] = React.useState();
	let sliderRef = useRef();
	const slider = sliderRef && sliderRef.current;

	useEffect(() => {
		updateState({});
	}, []);

	const settings = {
		appendDots: (dots) => <ul>{dots}</ul>,
		customPaging: (i) => <div className="slick__dots--custom"></div>,
	};

	const renderArrows = () => {
		if (!slider) return null;
		return (
			<ImgSliderArrows>
				<span
					className="slider-arrow"
					onClick={(e) => {
						e.stopPropagation();
						slider.slickPrev();
					}}
				>
					<SliderArrowLeft />
				</span>
				<span
					className="slider-arrow"
					onClick={(e) => {
						e.stopPropagation();
						slider.slickNext();
					}}
				>
					<SliderArrowRight />
				</span>
			</ImgSliderArrows>
		);
	};

	return (
		<ImgSliderContainer>
			<SmartImageWithId
				imageId={product.imageId}
				height={"100%"}
				width={"100%"}
			/>
		</ImgSliderContainer>
	);
	return (
		<ImgSliderContainer>
			{renderArrows()}
			<Slider
				{...settings}
				ref={sliderRef}
				dots={true}
				arrows={true}
				infinite={true}
				speed={500}
				slidesToShow={1}
				slidesToScroll={1}
			>
				<SmartImageWithId imageId={product.imageId} />
			</Slider>
		</ImgSliderContainer>
	);
};
