import {Contact, Customer, Person, PhoneNumber} from "@natomas/core";
import {IAddressDetails} from "@natomas/core/application/entities/AddressDetails";
import {IContactInfo} from "@natomas/core/application/entities/ContactInfo";
import {IPersonInfo} from "@natomas/core/application/entities/PersonInfo";

export const intakeFormToCustomer = (
  uid: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  address: IAddressDetails
) => {
  let phoneNumber = PhoneNumber.dataToPhoneNumber("primary", phone);

  let contact: IContactInfo = Contact.dataToContactInfo(
    address,
    phoneNumber,
    email
  );

  let person: IPersonInfo = Person.dataToPerson(
    firstName,
    "",
    lastName,
    contact,
    ""
  );

  return Customer.dataToCustomer(uid, person);
};
