import React from "react";
import {Col, Row} from "antd";
import {ListHeaderContainer} from "./styled";

export const ListHeader = ({isListExpanded}) => {
	return (
		<ListHeaderContainer isListExpanded={isListExpanded}>
			<Row align="middle" justify="space-between">
				<Col flex="auto">
					<h3 style={{marginBottom: 0}}>Pick a floorplan</h3>
				</Col>
			</Row>
		</ListHeaderContainer>
	);
};
