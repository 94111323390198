/**
 * useEffect hook that only runs on component mount (aka once)
 */

import {useEffect, useState} from "react";

const useMountEffect = (effect) => {
	const [executed, setExecuted] = useState(false);
	useEffect(() => {
		if (!executed) {
			setExecuted(true);
			effect();
		}
	}, [effect, executed, setExecuted]);
};

 export default useMountEffect;
