import styled from "styled-components";
import {FONT_FAMILY_MEDIUM, colors, FONT_FAMILY_REGULAR} from "./globals";

export const Wrapper = styled.div<{type: string}>`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: ${(props) =>
    props.type === "watch-video" ? "row" : "column-reverse"};
  border-radius: 0.5rem;
  border: 1px solid ${colors.ctaCardBorder};
  background-color: ${colors.ctaCardBackground};
  margin-bottom: 1rem;
`;

export const ContentContainer = styled.div<{type: string}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: ${(props) => (props.type === "watch-video" ? "55%" : "100%")};
  height: ${(props) => (props.type === "watch-video" ? "100%" : "50%")};
  padding: 1rem 1.5rem;
  flex-direction: column;
`;

export const Status = styled.div<{status: string}>`
  display: inline-block;
  color: ${colors.status};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-weight: 500;
  font-size: 0.8rem;
  border-radius: 5px;
  text-align: center;
  padding: 8px 10px 6px;
  background-color: ${(props) =>
    props.status === "not-started"
      ? colors.watchVideoStatusNotStarted
      : colors.watchVideoStatusComplete};
  margin-bottom: ${(props) => (props.status !== null ? "10px" : "0")};

  &.not-started {
    background-color: ${colors.watchVideoStatusNotStarted};
  }
  &.started {
    background-color: ${colors.watchVideoStatusStarted};
  }
  &.complete {
    background-color: ${colors.watchVideoStatusComplete};
  }
  &.helpful-resource {
    display: none;
  }
`;

export const Title = styled.div`
  color: ${colors.ctaCardTitle};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-weight: 500;
  font-size: 1.1rem;
  &.helpful-resource {
    margin-top: 1.25rem;
  }
`;
export const Description = styled.div<{fontSize: string}>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.ctaCardDescription};
  font-family: ${FONT_FAMILY_REGULAR};
  font-weight: 500;
  font-size: ${(props) => props.fontSize ?? "0"};
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
`;

export const ImageContainer = styled.div<{type: string}>`
  overflow: hidden;
  height: ${(props) => (props.type === "watch-video" ? "100%" : "14rem")};
  width: ${(props) => (props.type === "watch-video" ? "45%" : "100%")};
  img {
    border-radius: ${(props) =>
      props.type === "watch-video" ? "0 0.5rem 0.5rem 0" : "0.5rem 0.5rem 0 0"};
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
