import React, {useEffect} from "react";
import {getParameter} from "../../_shared/cookies/getParameter";
import {Utilities} from "../../../database/firebase";
import firebase from "firebase";

export const IntakeError = () => {
  useEffect(() => {
    if (window.location.href.includes("errorMessage")) {
      const randomRef = Utilities.collection("intake_form_errors").doc();
      randomRef.set({
        error: getParameter("errorMessage", true),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }, []);

  return (
    <>
      <div className="form-wrapper">
        <div className="confirmation-title-wrapper">
          <h2 className="confirmation-title">
            Uh oh - looks like something's broken!
          </h2>
        </div>

        <>
          <p className="confirmation-text">
            Contact us directly by giving us a call, text, or email:
          </p>
          <div className="confirmation-buttons">
            <a href="tel:4159681625" className="yellow-btn link">
              Call / Text Us
            </a>
            <a href="mailto:hello@villahomes.com" className="white-btn link">
              Email Us
            </a>
          </div>
        </>
      </div>
    </>
  );
};
