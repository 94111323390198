import {SHOW_MULTI_SELECT_PROPERTY_KEY} from "../logic/Constants";
import {store} from "../../../store";
import {setSelectedModifiers} from "./configurationSlice";
import {validateAndResetSelections} from "../backend/ConfigurationAPI";

export const selectModifierAndValidate = (modifier, modifierGroup, reset) => {
  const state = store.getState();
  if (state.configuration.editingSelections === false && reset !== true) {
    return;
  }
  const modifierId = modifier.id;
  const isMultiSelect =
    modifierGroup.properties != null &&
    modifierGroup.properties[SHOW_MULTI_SELECT_PROPERTY_KEY] === true;

  const otherModifiersInGroup = modifierGroup.modifiers;
  const selectedModifiers = Object.assign(
    {},
    state.configuration.selectedModifiers
  );
  otherModifiersInGroup.forEach((modifierIdInGroup) => {
    if (modifierIdInGroup === modifierId) {
      if (isMultiSelect && selectedModifiers[modifierIdInGroup] === true) {
        selectedModifiers[modifierIdInGroup] = null;
      } else {
        selectedModifiers[modifierIdInGroup] = true;
      }
    } else if (!isMultiSelect) {
      selectedModifiers[modifierIdInGroup] = null;
    }
  });

  store.dispatch(
    setSelectedModifiers({modifiers: selectedModifiers, merge: true, reset})
  );
  validateAndResetSelections(selectedModifiers);
};
