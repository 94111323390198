import styled from "styled-components";

export const ImageManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: 1rem 0;
  width: 100%;
`;
export const NewImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: 1rem 0;
`;

export const ImageDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  & > * {
    margin: 0 1rem 1rem 0;
    padding: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
