import {useProductCatalog} from "./useProductCatalog";
import {getCategoriesForProduct} from "../../design-tool/logic/ProductCategoryHelper";
import {
	getCategoryInformationToDisplay,
	getOrderSummaryWithPages,
} from "../../design-tool/logic/ConfigurationPageHelper";
import {useSelector} from "react-redux";
import {fetchImage} from "../../design-tool/logic/data";

export const useDesignStudio = (props) => {
	const {product, productId, productGroupCategories} = useProductCatalog();
	const step = useSelector((state) => state.catalog.step);

	let currentCategory = null;
	const fullCategoriesForProduct = getCategoriesForProduct(
		product,
		productGroupCategories
	);

	if (fullCategoriesForProduct.length > 0) {
		const stepIndex = parseInt(step);
		if (step === "summary") {
			currentCategory =
				fullCategoriesForProduct[fullCategoriesForProduct.length - 1];
		} else if (typeof step === "string") {
			fullCategoriesForProduct.forEach((category) => {
				if (category.id.includes(step)) {
					currentCategory = category;
				}
			});
			if (currentCategory == null) {
				currentCategory = fullCategoriesForProduct[0];
			}
		} else {
			currentCategory = fullCategoriesForProduct[stepIndex];
		}
	}

	const orderSummary = useSelector((state) =>
		props?.summary === true
			? getOrderSummaryWithPages(state, fullCategoriesForProduct)
			: null
	);

	const currentCategoryToDisplay = useSelector((state) =>
		props?.categoryInfoToDisplay === true
			? getCategoryInformationToDisplay(state, productId, currentCategory)
			: null
	);

	if (currentCategory?.renderings != null) {
		Object.values(currentCategory.renderings).forEach((rendering) => {
			if (rendering.imageId != null) {
				fetchImage(rendering.imageId, false);
			}
		});
	}

	return {
		currentCategory,
		currentCategoryToDisplay,
		orderSummary,
		step,
		selectedModifiers: orderSummary?.selectedModifiers,
	};
};
