import {
  PaymentDetails,
  PaymentDetailsTable,
  PaymentDetailsTableData,
  PaymentDetailsTableRow,
  PaymentDetailsTableTitle,
  PaymentHorizontalRule,
  SectionInfoContainer,
  SectionInfoText,
  YourInfoFlex,
} from "../styles";
import React, {useMemo} from "react";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {PaymentCard} from "./PaymentCard";
import {
  Customer,
  formatPhoneNumber,
  PhoneNumberFormatType,
} from "@natomas/core";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {ADUBlurb} from "./ADUBlurb";
import {YourADU} from "./YourADU";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {
  PROFILE_PAGE_ID,
  toPage,
} from "../../../_shared/navigation/PageNavigation";
import {usePage} from "../../../../_shared/hooks/usePage";
import {toCatalog} from "../../../../_shared/navigation";
import {getUnitPriceInMicros} from "../../../../design-tool/logic/ProductUtils";

interface YourInfoProps {
  fullName: string;
  personalAddress1: string;
  personalAddress2: string;
  phone: string;
  propertyAddress1: string;
  propertyAddress2: string;
}

export const YourInfo = (props: YourInfoProps) => {
  const {configuration} = useCurrentProject();
  const {customer} = useCurrentCustomer();
  const {columnCount, isNatMobile} = usePage();
  const product = useMemo(() => {
    if (configuration?.product) return configuration.product;
    else return null;
  }, [configuration]);

  const {fullName, propertyAddress1, propertyAddress2} = props;

  const email = Customer.getContactEmail(customer);
  const phoneNumber = Customer.getPrimaryContactPhoneNumber(customer);

  const yourADUWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });
  const infoDetailsWidth = useDynamicValue({
    forFour: 4,
    forEight: 5,
    forTwelve: 8,
  });
  const tableWidth = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 5,
  });

  return (
    <>
      <PaymentDetails
        removeRightMargin={
          isNatMobile() ? Math.floor(columnCount / infoDetailsWidth) : undefined
        }
      >
        <PageElement size={infoDetailsWidth} height={"auto"}>
          <PaymentHorizontalRule />
          <SectionInfoContainer>
            <SectionInfoText>Your info</SectionInfoText>
          </SectionInfoContainer>
          <YourInfoFlex>
            <PageElement size={tableWidth} height={"auto"}>
              <PaymentDetailsTable>
                <tbody>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableTitle>
                      Contact info
                    </PaymentDetailsTableTitle>
                  </PaymentDetailsTableRow>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableData>
                      {fullName}
                    </PaymentDetailsTableData>
                  </PaymentDetailsTableRow>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableData>{email}</PaymentDetailsTableData>
                  </PaymentDetailsTableRow>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableData>
                      {formatPhoneNumber(
                        phoneNumber,
                        PhoneNumberFormatType.PARENTHESIS_AND_DASHES
                      )}
                    </PaymentDetailsTableData>
                  </PaymentDetailsTableRow>
                </tbody>
              </PaymentDetailsTable>
            </PageElement>

            <PageElement size={tableWidth} height={"auto"}>
              <PaymentDetailsTable>
                <tbody>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableTitle>
                      Property address
                    </PaymentDetailsTableTitle>
                  </PaymentDetailsTableRow>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableData>
                      {propertyAddress1}
                    </PaymentDetailsTableData>
                  </PaymentDetailsTableRow>
                  <PaymentDetailsTableRow>
                    <PaymentDetailsTableData>
                      {propertyAddress2}
                    </PaymentDetailsTableData>
                  </PaymentDetailsTableRow>
                </tbody>
              </PaymentDetailsTable>
            </PageElement>
          </YourInfoFlex>
          <NatButton
            style={{width: "10rem", marginBottom: "1.25rem"}}
            label={"Edit info"}
            trackingId={"payments-edit-info"}
            type={"button"}
            option={StyleOption.SECONDARY}
            size={TextSize.SMALL}
            clickEvent={() => {
              toPage(PROFILE_PAGE_ID);
            }}
          />
          {product && (
            <>
              <PaymentHorizontalRule />
              <SectionInfoContainer>
                <SectionInfoText>Your home</SectionInfoText>
              </SectionInfoContainer>
              <ADUBlurb
                imageId={product?.imageId}
                title={product?.title}
                description={product?.displayDescription}
                tagline={product?.tagline}
                size={yourADUWidth}
                showChangeButton={false}
              />
              <YourADU
                title={product?.title}
                frontLength={
                  product?.productDetails.dimensions.frontLength.feet
                }
                sideLength={product?.productDetails.dimensions.sideLength.feet}
                bedroomCount={product?.productDetails.bedrooms}
                bathroomCount={product?.productDetails.bathrooms}
                squareFootage={product?.productDetails.squareFeet}
                priceMicros={getUnitPriceInMicros(product)}
              />
              <NatButton
                style={{width: "10rem", marginBottom: "1.25rem"}}
                label={"Change home"}
                trackingId={"your-info-change-home"}
                type={"button"}
                option={StyleOption.SECONDARY}
                size={TextSize.SMALL}
                clickEvent={() => {
                  toCatalog();
                }}
              />
            </>
          )}
        </PageElement>

        <PaymentCard
          user={customer}
          clientReferenceIdProp={customer?.user_id}
          customerEmailProp={Customer.getContactEmail(customer)}
        />
      </PaymentDetails>
    </>
  );
};
