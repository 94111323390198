import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {ProductStructure} from "../../../../design-tool/models/ProductModel";
import {
  deleteProduct,
  saveProductInformation,
} from "../../../../design-tool/backend/catalogApi";
import {
  getIntegerValue,
  getPriceMicrosFromText,
  getTextFromPriceMicros,
} from "@natomas/core";
import {isBlankString} from "@natomas/core";
import {readDeepValue, setDeepValue} from "../../../../_shared/Utils";
import {DNDEditor} from "../../components/DNDEditor";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {
  AdminForm,
  MainContent,
  MainView,
  ViewOptions,
} from "../../styles/AdminStyles";
import {AdminInput} from "../../components/AdminComponentFactory";
import {editProduct} from "../../../../_shared/slices/CatalogSlice";
import {EditorHeader} from "../../components/EditorHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {IStore} from "../../../../_shared/slices/types/Store";

export const getBlankItemSection = (items: any) => {
  return {
    initial: {
      items: items,
      index: 0,
      title: "General",
    },
  };
};

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "structure", title: "Product Structure"},
];

const productStructureForForm = getFormStructure(ProductStructure);

const getInitialValues = (obj: any) => {
  const structure = ProductStructure;
  const initialValues = getFormInitialValues(structure);
  if (obj != null) {
    for (const key in structure) {
      // @ts-ignore
      const keyInfo = structure[key];
      let initialValue = obj[key];
      if (keyInfo.path != null) {
        initialValue = readDeepValue(obj, keyInfo.path);
      }

      if (initialValue != null) {
        if (keyInfo.subType === "price") {
          initialValue = getTextFromPriceMicros(initialValue);
        } else if (typeof initialValue === "number") {
          initialValue = "" + initialValue;
        }
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProduct = () => {
  const editingProduct = useSelector(
    (state: IStore) => state.catalog.editingProduct
  );
  const {productGroupCategories, currentProducts} = useProductCatalog();
  let currentProduct = editingProduct;
  currentProducts.forEach((product: any) => {
    if (product.id === editingProduct?.id) {
      currentProduct = product;
    }
  });

  const [tab, setTab] = useState(tabs[0].id);

  const dispatch = useDispatch();
  const close = () => {
    dispatch(editProduct(null));
  };

  const deleteAction = () => {
    close();
    return deleteProduct(currentProduct.id);
  };

  const initialValues = getInitialValues(currentProduct);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      let valueToSave = {};
      for (const key in values) {
        // @ts-ignore
        let value = values[key];
        // @ts-ignore
        const keyInfo = ProductStructure[key];
        if (keyInfo?.subType === "price") {
          value = getPriceMicrosFromText(value);
        } else if (keyInfo?.type === "number") {
          value = getIntegerValue(value);
        }

        if (keyInfo?.path != null) {
          setDeepValue(valueToSave, keyInfo.path, value);
        } else {
          // @ts-ignore
          valueToSave[key] = value;
        }
      }

      // @ts-ignore
      if (isBlankString(valueToSave?.imageId)) {
        // @ts-ignore
        valueToSave["imageId"] = null;
      }
      saveProductInformation(currentProduct.id, valueToSave);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [currentProduct]);

  if (currentProduct == null) {
    return null;
  }
  const libraryIds = Object.keys(productGroupCategories);

  let mainContent;
  if (tab === "details") {
    mainContent = (
      <AdminForm onSubmit={formik.handleSubmit}>
        {productStructureForForm.map((keyInfo) => {
          const {key} = keyInfo;
          // @ts-ignore
          const value = formik.values[key];
          if (key === "configuratorPages") {
            return null;
          }
          // @ts-ignore
          let initial = initialValues[key];
          return (
            <AdminInput
              key={key}
              value={value}
              fieldInfo={keyInfo}
              initialValue={initial}
              onChange={(value: any) => {
                formik.setFieldValue(key, value);
              }}
              handleChange={formik.handleChange}
            />
          );
        })}
      </AdminForm>
    );
  } else {
    const key = "configuratorPages";
    // @ts-ignore
    const value = formik.values[key];
    mainContent = (
      <div>
        <DNDEditor
          sections={getBlankItemSection(value)}
          fieldKey={key}
          formik={formik}
          savedIds={value}
          libraryIds={libraryIds}
          type={"categories"}
          callback={(newCategories: any) => {
            formik.setFieldValue(key, newCategories);
          }}
        />
      </div>
    );
  }
  const viewProductInCatalog = () => {
    window.open(
      window.location.origin + "/products?unit=" + currentProduct?.id
    );
  };

  const viewProductInDesignStudio = () => {
    window.open(window.location.origin + "/design?unit=" + currentProduct?.id);
  };

  return (
    <MainView>
      <EditorHeader
        tabs={tabs}
        setTab={setTab}
        isUpToDate={isDeepEqual(formik.values, initialValues)}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        viewAction={
          <ViewOptions>
            <NatButton
              clickEvent={viewProductInCatalog}
              hidden={currentProduct?.id == null}
              label={"Catalog View"}
              option={StyleOption.SECONDARY}
              size={TextSize.SMALL}
              trackingId={"admin-view-product-in-catalog"}
              type={"button"}
            />
            <NatButton
              clickEvent={viewProductInDesignStudio}
              hidden={currentProduct?.id == null}
              label={"Design Studio View"}
              option={StyleOption.SECONDARY}
              size={TextSize.SMALL}
              trackingId={"admin-view-product-in-design-studio"}
              type={"button"}
            />
          </ViewOptions>
        }
        deleteAction={currentProduct?.id != null ? deleteAction : null}
        saveAction={formik.handleSubmit}
      />
      <MainContent>{mainContent}</MainContent>
    </MainView>
  );
};
