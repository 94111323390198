import {
  HomeDetails,
  HomeDetailsTitle,
  HomeDetailsTable,
  HomeDetailsTableLeft,
  HomeDetailsTableRight,
  HomeDetailsTableRow,
  HomeDetailsFlex,
} from "../styles";
import React from "react";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  useDynamicValue,
  fullColumnWidths,
} from "../../../../_shared/hooks/useDynamicValue";
import {usePage} from "../../../../_shared/hooks/usePage";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";

interface YourADUProps {
  title: string;
  bedroomCount: number;
  bathroomCount: number;
  frontLength: number;
  sideLength: number;
  squareFootage: number;
  priceMicros: number;
}

export const YourADU = (props: YourADUProps) => {
  const {
    title,
    bedroomCount,
    bathroomCount,
    frontLength,
    sideLength,
    squareFootage,
    priceMicros,
  } = props;
  const {columnCount} = usePage();
  const size = useDynamicValue(fullColumnWidths);
  const tableWidth = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 5,
  });

  return (
    <PageElement size={size} height={"auto"}>
      <HomeDetails>
        <HomeDetailsTitle>Home Details</HomeDetailsTitle>
        <HomeDetailsFlex
          removeRightMargin={Math.floor(columnCount / tableWidth)}
        >
          <PageElement size={tableWidth} height={"auto"}>
            <HomeDetailsTable>
              <tbody>
                <HomeDetailsTableRow>
                  <HomeDetailsTableLeft>Model</HomeDetailsTableLeft>
                  <HomeDetailsTableRight>{title}</HomeDetailsTableRight>
                </HomeDetailsTableRow>
                <HomeDetailsTableRow>
                  <HomeDetailsTableLeft>Bedrooms</HomeDetailsTableLeft>
                  <HomeDetailsTableRight>{bedroomCount}</HomeDetailsTableRight>
                </HomeDetailsTableRow>
                <HomeDetailsTableRow>
                  <HomeDetailsTableLeft>Bathrooms</HomeDetailsTableLeft>
                  <HomeDetailsTableRight>{bathroomCount}</HomeDetailsTableRight>
                </HomeDetailsTableRow>
              </tbody>
            </HomeDetailsTable>
          </PageElement>
          <PageElement size={tableWidth} height={"auto"}>
            <HomeDetailsTable>
              <tbody>
                <HomeDetailsTableRow>
                  <HomeDetailsTableLeft>Dimensions</HomeDetailsTableLeft>
                  <HomeDetailsTableRight>
                    {frontLength}' x {sideLength}'
                  </HomeDetailsTableRight>
                </HomeDetailsTableRow>
                <HomeDetailsTableRow>
                  <HomeDetailsTableLeft>Size</HomeDetailsTableLeft>
                  <HomeDetailsTableRight>
                    {squareFootage} sq. ft
                  </HomeDetailsTableRight>
                </HomeDetailsTableRow>
                <HomeDetailsTableRow>
                  <HomeDetailsTableLeft>Unit Price</HomeDetailsTableLeft>
                  <HomeDetailsTableRight>
                    {priceTextFromMicros(priceMicros, "min")}
                  </HomeDetailsTableRight>
                </HomeDetailsTableRow>
              </tbody>
            </HomeDetailsTable>
          </PageElement>
        </HomeDetailsFlex>
      </HomeDetails>
    </PageElement>
  );
};
