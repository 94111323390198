import MaterialTable from "material-table";
import {useEffect, useState} from "react";
import {
  generateOutput,
  getDataFromEntries,
  ModifierSelection,
  PRICES_TYPE,
  VISIBILITY_HIDE_KEY,
  VISIBILITY_SHOW_KEY,
  VISIBILITY_TYPE,
} from "../logic/ModifierDependencyTableHelper";

const getFirstColumn = (type) => {
  switch (type) {
    case VISIBILITY_TYPE:
      return {
        title: "Visibility",
        field: "visibility",
        initialEditValue: VISIBILITY_SHOW_KEY,
        lookup: {
          [VISIBILITY_SHOW_KEY]: "Show if...",
          [VISIBILITY_HIDE_KEY]: "Hide if...",
        },
      };
    case PRICES_TYPE:
      return {
        title: "Price",
        field: "price",
        type: "currency",
      };
  }
};

export const ModifierDependencyTable = (props) => {
  const {value, handleChange, name, type} = props;

  const [data, setData] = useState(getDataFromEntries(value, type));
  const [show, setShow] = useState(true);

  const updateRow = (index, newData) => {
    const dataUpdate = [...data];
    dataUpdate[index] = newData;
    const newDataToSave = [...dataUpdate];
    setData(newDataToSave);
    handleChange(generateOutput(newDataToSave, type));
  };

  const [columns, setColumns] = useState([
    getFirstColumn(type),
    {
      title: "Selected Modifiers",
      field: "requiredModifiers",
      render: (rowData) => {
        const requiredModifiers = rowData.requiredModifiers;

        return (
          <ModifierSelection
            initialSelections={requiredModifiers}
            onSelectionChange={(value) => {
              const dict = {};
              value.forEach((modifier) => {
                dict[modifier.value] = true;
              });
              const row = rowData.tableData.id;
              const newData = Object.assign({}, rowData);
              delete newData.tableData;
              newData.requiredModifiers = dict;
              updateRow(row, newData);
            }}
          />
        );
      },
      editComponent: (props) => {
        const rowData = props.rowData;
        const requiredModifiers = rowData.requiredModifiers;

        return (
          <ModifierSelection
            initialSelections={requiredModifiers}
            onSelectionChange={(value) => {
              const dict = {};
              value.forEach((modifier) => {
                dict[modifier.value] = true;
              });
              props.onChange(dict);
            }}
          />
        );
      },
    },
    {
      title: "Condition",
      field: "condition",
      initialEditValue: "all",
      lookup: {
        all: "ALL selected",
        any: "ANY selected",
      },
    },
  ]);

  useEffect(() => {
    setData(getDataFromEntries(value, type));
  }, [value]);

  if (!show) {
    return (
      <div>
        <div
          className="clickable"
          onClick={() => {
            setShow(true);
          }}
        >
          {"Show " + name}
        </div>
      </div>
    );
  }

  return (
    <MaterialTable
      title={name}
      columns={columns}
      data={data}
      options={{
        search: false,
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            const newDataToSave = [...data, newData];
            setData(newDataToSave);
            handleChange(generateOutput(newDataToSave, type));
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const index = oldData.tableData.id;
            updateRow(index, newData);
            resolve();
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            const newDataToSave = [...dataDelete];
            setData(newDataToSave);
            handleChange(generateOutput(newDataToSave, type));
            resolve();
          }),
      }}
    />
  );
};
