import React from "react";
import {Subtitle, Title} from "../styles";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";

interface Props {
  isMapped: boolean;
}

export const MappingInfo = ({isMapped}: Props) => {
  const goToMapping = () => {
    window.alert("Cannot go to mapping yet.");
  };
  return (
    <>
      <Title>Map an ADU on your property</Title>
      <Subtitle>
        Use our mapping tool to find the ADU just right for you. Just select the
        unit and see how it will fit in your backyard!
      </Subtitle>
      <NatButton
        label={isMapped ? "Remap" : "Map your ADU"}
        trackingId={isMapped ? "mapping-remap" : "mapping-map-your-unit"}
        type={"button"}
        clickEvent={goToMapping}
        option={StyleOption.ColorWillDarken}
      />
    </>
  );
};
