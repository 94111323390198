import React from "react";
import {Button} from "./buttons/button";
import "./styles.scss";
import CountUp from "react-countup";
import {isBlankString} from "@natomas/core";
import {
  setURLHash,
  setURLQuery,
  setURLQueryKeyValue,
} from "../../_shared/navigation/_helpers";
import {
  getBasePriceForDesignStudio,
  getBedBathString,
  getLengthAndWidthString,
  getSqFtString,
} from "../logic/ProductUtils";
import usePrevious from "../../_shared/hooks/usePrevious";
import {saveConfiguration} from "../backend/ConfigurationAPI";
import {useDesignStudio} from "../../_shared/hooks/useDesignStudio";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {isMobile, toCatalog} from "../../_shared/navigation";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";

export function BottomBar(props) {
  const {formik} = props;
  const {product, productId} = useProductCatalog();
  const {orderSummary, step} = useDesignStudio({summary: true});
  const updatedPrice =
    (getBasePriceForDesignStudio(product) + orderSummary.upgradesInMicros) /
    100;
  //Splits up description of unit so we can have custom spacing and remove a piece on mobile due to space
  let squareFeetText = getSqFtString(product);
  let smallDescText = getBedBathString(product);
  let dollarFeetText = getLengthAndWidthString(product);
  let productTitle = "";

  if (product != null) {
    productTitle = product.title;
  }

  //Uses UsePrevious Hook to get the previous value of updated price so when it's animating, it won't jump down and back up and vice versa
  const prevPrice = usePrevious(updatedPrice);
  // const {STREAMLINED_JOURNEY} = useGlobalConfiguration();
  // const {isCustomerPreDeposit} = useCurrentCustomer();
  const backToCatalog = true;

  const backButtonToUnits =
    step !== "summary" &&
    (step == null ||
      product == null ||
      product.configuratorPages == null ||
      product.configuratorPages.indexOf(step) <= 0);

  const previousStep = (event) => {
    event.preventDefault();
    if (backButtonToUnits) {
      if (backToCatalog) {
        toCatalog(true);
      } else {
        setURLQueryKeyValue("unit", null);
      }
    } else if (step === "summary") {
      setURLHash(product.configuratorPages.length - 1);
    } else {
      const index = product.configuratorPages.indexOf(step);
      if (window.prevButtonAction != null) {
        window.prevButtonAction();
      } else if (index > 0) {
        setURLHash(index - 1);
      }
    }
  };

  const nextStep = (event) => {
    event.preventDefault();
    if (isBlankString(step)) {
      setURLHash(1);
    } else if (step === "summary") {
      saveConfiguration(formik);
    } else {
      const index = product.configuratorPages.indexOf(step);
      if (window.nextButtonAction != null) {
        window.nextButtonAction();
      } else if (index >= product.configuratorPages.length - 1) {
        setURLHash("summary");
      } else if (index >= 0) {
        setURLHash(index + 1);
      } else {
        setURLHash(1);
      }
    }
  };

  const shouldLookMobile = useDynamicValue({
    forFour: 1,
    forTwelve: 0,
    forEight: 1,
  });

  if (productId == null) {
    return null;
  }

  const backButtonTitle = backButtonToUnits
    ? backToCatalog
      ? "Catalog"
      : "Units"
    : "Back";

  const style = {position: shouldLookMobile ? "sticky" : "fixed"};
  if (shouldLookMobile) {
    style.padding = "8px 0px";
  }

  return (
    <div
      className={`custom-container-fluid ${
        step === "submit" ? "hidden" : "bottom-bar"
      }  ${step === "result" ? "bottom-bar-result" : ""} `}
      style={style}
    >
      <div className="row custom-row">
        <div className="bottom-wrapper">
          <div className="bottom-description">
            <div className="bottom-bar-description">
              {/* This is the desktop back button */}
              <Button
                componentClass={
                  !productId
                    ? "hidden"
                    : "back-button real-desktop button-secondary"
                }
                onClick={previousStep}
              >
                {backButtonTitle}
              </Button>

              {/* This is the desktop back button */}

              {/* This is mobile back button */}
              <Button
                componentClass={
                  !productId
                    ? "floorplan-back-button mobile-back-button real-mobile"
                    : "mobile-back-button real-mobile"
                }
                onClick={previousStep}
              >
                {backButtonTitle}
              </Button>

              {/* This is mobile back button */}
              <div className="unit-wrapper mobile-hidden">
                <div className="bottom-bar-bold">{productTitle}</div>
                <div className="unit-description">
                  <p className="small-desc">{smallDescText}</p>
                  <p className="square-feet">{squareFeetText}</p>
                  <p className="dollar-feet">{dollarFeetText}</p>
                </div>
              </div>

              <div className="bottom-bar-seperator mobile-hidden"></div>
              <div className="price-wrapper mobile-hidden">
                {product == null ? null : (
                  <>
                    <CountUp
                      start={prevPrice === 0 ? updatedPrice : prevPrice}
                      end={updatedPrice}
                      separator=","
                      prefix="$"
                      suffix="*"
                      duration={0.5}
                    />
                    {window.innerWidth > 1220 && (
                      <p>*Excl. construction site work costs</p>
                    )}
                    {/*  <h3>${priceTogether}</h3> */}
                  </>
                )}
              </div>

              {/* This is the mobile bottom bar button */}
              <Button
                componentClass="real-mobile mobile-red-button"
                id="next"
                onClick={nextStep}
              >
                {step === "summary" ? "Save Design" : "Next"}
              </Button>
            </div>
          </div>
        </div>
        {/* This is the desktop button */}
        <div
          className="next-button-wrapper real-desktop"
          style={{paddingTop: "4px"}}
        >
          <Button
            componentClass="next-button red-button-next"
            id="next"
            onClick={nextStep}
          >
            {step === "summary" ? "Save Your Design" : "Next"}
          </Button>
        </div>
      </div>
      {shouldLookMobile ? <div style={{height: "54px"}} /> : null}
    </div>
  );
}
