import {
  ToDoButton,
  ToDoImage,
  ToDoListCard,
  ToDoListCardContainer,
  ToDoListContainer,
  ToDoListTag,
  ToDoSubtitle,
  ToDoText,
  ToDoTitle,
} from "../styles";
import React from "react";
import {
  DESIGN_PAGE_ID,
  PROPOSAL_PAGE_ID,
  SITE_PAGE_ID,
  toPage,
} from "../../../_shared/navigation/PageNavigation";
import {openPopupWidget} from "react-calendly";
import assessment from "../../../../../assets/images/stock/assessment.jpg";
import {Customer} from "@natomas/core";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {isProd} from "../../../../_shared/application";

const CA_CALENDLY_BASE_URL = "https://my-adu.link/meet";
const TX_CALENDLY_BASE_URL =
  "https://calendly.com/habitat_adu/villa-homes-austin-adu-assessment";

const ToDoListCardDiv = (props: any) => {
  const {img, todo, title, subtitle, buttonTitle, disabled, onClick} = props;

  return (
    <ToDoListCard>
      <TagComponent todo={todo} hasImage={!!img} />
      <ToDoImage imageSrc={img} />
      <ToDoText imageSrc={img}>
        <ToDoTitle>{title}</ToDoTitle>
        <ToDoSubtitle>{subtitle}</ToDoSubtitle>
        <ToDoButton disabled={disabled} onClick={onClick}>
          {buttonTitle}
        </ToDoButton>
      </ToDoText>
    </ToDoListCard>
  );
};

const ToDoListCardComponent = (props: any) => {
  const {todo} = props;
  const {customer} = useCurrentCustomer();
  const {type, complete} = todo;

  switch (type) {
    case "design":
      return (
        <ToDoListCardDiv
          title={"Your ADU Design"}
          subtitle={
            complete
              ? "Review or change your design selection"
              : "Select the layout and upgrades for your ADU"
          }
          buttonTitle={complete ? "Review" : "Start Design"}
          disabled={false}
          todo={todo}
          onClick={() => {
            toPage(DESIGN_PAGE_ID);
          }}
        />
      );
    case "proposal":
      return (
        <ToDoListCardDiv
          title={"Project Proposal"}
          subtitle={
            complete
              ? "Review budget summary and next steps"
              : "Our team will walk you through your proposal over video call"
          }
          buttonTitle={"Review"}
          disabled={!complete}
          todo={todo}
          onClick={() => {
            toPage(PROPOSAL_PAGE_ID);
          }}
        />
      );
    case "site-plan":
      return (
        <ToDoListCardDiv
          title={"Site Plan"}
          subtitle={
            complete
              ? "We uploaded your site plan, take a look!"
              : "Our team will upload your site plan shortly - stay tuned!"
          }
          buttonTitle={"Review"}
          disabled={!complete}
          todo={todo}
          onClick={() => {
            toPage(SITE_PAGE_ID);
          }}
        />
      );
    case "assessment":
      const isComplete = Customer.isAssessmentScheduled(customer);
      return (
        <ToDoListCardDiv
          title={"Schedule an Assessment"}
          subtitle={
            isComplete
              ? "Assessment Scheduled"
              : "Get on a call with our service reps to kickstart your Villa homes journey!"
          }
          buttonTitle={"Schedule now"}
          img={assessment}
          disabled={isComplete}
          todo={todo}
          complete={isComplete} // Get if complete
          onClick={() => {
            let calendlyURL = "https://calendly.com/hmound"; // For testing
            if (isProd) {
              if (Customer.getContactState(customer) === "TX") {
                calendlyURL = TX_CALENDLY_BASE_URL;
              } else {
                calendlyURL = CA_CALENDLY_BASE_URL;
              }
            }

            const url = calendlyURL;
            const prefill = {
              email: Customer.getContactEmail(customer),
              firstName: Customer.getFirstName(customer),
              lastName: Customer.getLastName(customer),
              name: Customer.getFullName(customer),
            };
            //, prefill, pageSettings, utm
            openPopupWidget({url, prefill});
          }}
        />
      );
  }
  return null;
};

const TagComponent = (props: any) => {
  const {todo, previousTodoIsInGroup, hasImage} = props;
  let {stage} = todo;
  let backgroundColor = "white";
  let title = "";
  switch (stage) {
    case "todo":
      backgroundColor = "#FFD7CE";
      title = "To-do";
      break;
    case "review":
      backgroundColor = "rgba(110, 188, 233, 0.3)";
      if (hasImage) {
        backgroundColor = "rgba(110, 188, 233, 1)";
      }
      title = "Completed";
      break;
    case "waiting":
      backgroundColor = "#FFE2B7";
      title = "Pending";
      break;
  }

  let opacity = 1;
  if (previousTodoIsInGroup) {
    opacity = 0;
  }

  return <ToDoListTag style={{backgroundColor, opacity}}>{title}</ToDoListTag>;
};

export const ToDoList = (props: any) => {
  const {todoList, user} = props;
  return (
    <ToDoListContainer>
      {todoList.map((todo: any) => {
        return (
          <ToDoListCardContainer key={todo.type} shortenMargin={false}>
            <ToDoListCardComponent todo={todo} user={user} />
          </ToDoListCardContainer>
        );
      })}
    </ToDoListContainer>
  );
};
