import {createSlice} from "@reduxjs/toolkit";

export interface IPageSlice {
  additionalLeftPadding: number;
  pageWidth: number;
  containerWidth: number;
  containerPadding: number;
  columnCount: number;
  gutterWidthPercentage: number;
  gutterWidthPixels: number;
}

const getInitialState = (): IPageSlice => {
  return {
    additionalLeftPadding: 0,
    pageWidth: 0,
    containerWidth: 0,
    containerPadding: 0,
    columnCount: 0,
    gutterWidthPercentage: 0,
    gutterWidthPixels: 0,
  };
};

const pageSlice = createSlice({
  name: "page",
  initialState: getInitialState(),
  reducers: {
    updateAdditionLeftPadding: (state, action) => {
      if (action.payload < 0 || state.additionalLeftPadding === action.payload)
        return;
      state.additionalLeftPadding = action.payload;
    },
    updatePageWidth: (state, action) => {
      if (action.payload <= 0 || state.pageWidth === action.payload) return;
      state.pageWidth = action.payload;
    },
    updateContainerWidth: (state, action) => {
      if (action.payload <= 0 || state.containerWidth === action.payload)
        return;
      state.containerWidth = action.payload;
    },
    updateContainerPadding: (state, action) => {
      if (action.payload <= 0 || state.containerPadding === action.payload)
        return;
      state.containerPadding = action.payload;
    },
    updateColumnCount: (state, action) => {
      if (action.payload <= 0 || state.columnCount === action.payload) return;
      state.columnCount = action.payload;
    },
    updateGutterWidthPercentage: (state, action) => {
      if (action.payload <= 0 || state.gutterWidthPercentage === action.payload)
        return;
      state.gutterWidthPercentage = action.payload;
    },
    updateGutterWidthPixels: (state, action) => {
      if (action.payload <= 0 || state.gutterWidthPixels === action.payload)
        return;
      state.gutterWidthPixels = action.payload;
    },
  },
});

export const pageReducer = pageSlice.reducer;
export const {
  updateAdditionLeftPadding,
  updatePageWidth,
  updateContainerWidth,
  updateContainerPadding,
  updateColumnCount,
  updateGutterWidthPercentage,
  updateGutterWidthPixels,
} = pageSlice.actions;
