import styled from "styled-components";
import {BLACK, DEVELOPER_FILL_SECTION, WHITE} from "../../colors";
import {DEVELOPER_TOOLS_Z_INDEX} from "../../styles";

export const DeveloperToolsContainer = styled.div<{
  widthClosed: string;
  show: boolean;
}>`
  position: fixed;
  z-index: ${DEVELOPER_TOOLS_Z_INDEX};
  bottom: 0;
  right: 0;
  width: ${(props) => (!props.show ? props.widthClosed : "18rem")};
  color: ${WHITE};
  background-color: ${BLACK};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  height: auto;
  opacity: ${(props) => (props.show ? 1 : 0.5)};
  transition: all 350ms ease-in-out;
`;

export const DeveloperToolsSection = styled.div`
  background-color: ${DEVELOPER_FILL_SECTION};
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  padding: 1rem;
  border-radius: 1rem;
`;

export const CollapsableContainer = styled.div<{
  show: boolean;
  allowScroll?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: max-height 350ms ease-in-out;
  overflow-y: ${(props) => (props.allowScroll ? "auto" : "hidden")};
  max-height: ${(props) => (props.show ? "30rem" : "0")};
  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const IconContainer = styled.div`
  height: auto;
`;

export const CollapsableContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ToolsTitle = styled.div`
  font-size: 1rem;
  font-family: Maison-Bold, sans-serif;
  padding: 0;
`;

export const SectionTitle = styled.div`
  font-size: 1rem;
  font-family: Maison-Medium, sans-serif;
  padding: 0;
`;

export const SectionDescription = styled.div`
  font-size: 0.8rem;
  font-family: Maison-Book, sans-serif;
  padding: 0.8rem;
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ToolsDescription = styled.div`
  font-size: 0.8rem;
  padding: 0.5rem 0 1rem 0;
  font-family: Maison-Book, sans-serif;
`;
