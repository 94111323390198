import React from "react";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {useDispatch, useSelector} from "react-redux";
import {editModifier} from "../../../_shared/slices/CatalogSlice";
import {EditProductGroupModifier} from "./OverviewTab/EditProductGroupModifier";
import {ColumnContainer, MainColumn} from "../styles/AdminStyles";
import {isBlankString} from "@natomas/core";
import {TabColumn} from "../components/TabColumn";
import {IStore} from "../../../_shared/slices/types/Store";

export const ModifierTab = () => {
  const {currentGroupModifiers} = useProductCatalog();
  const currentModifier = useSelector(
    (state: IStore) => state.catalog.editingModifier
  );
  const dispatch = useDispatch();
  const title = "Modifiers";
  const newModifier = () => {
    dispatch(editModifier({title: "New Modifier"}));
  };
  const changeModifier = (modifier: any) => {
    dispatch(editModifier(modifier));
  };

  const checkIfActive = (key: string) => {
    return currentModifier?.id === key;
  };

  const getModifierRows = () => {
    return Object.values(currentGroupModifiers)
      .sort((a: any, b: any) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      })
      .map((modifier: any) => {
        const title = !isBlankString(modifier.internal_title)
          ? modifier.internal_title
          : modifier.title;
        return {
          title: title,
          key: modifier.id,
          data: modifier,
          action: changeModifier,
          checkIfActive: checkIfActive,
        };
      });
  };
  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={"11rem"}
        headerAddAction={newModifier}
        rows={getModifierRows()}
      />
      <MainColumn>
        <EditProductGroupModifier />
      </MainColumn>
    </ColumnContainer>
  );
};
