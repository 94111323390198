import React from "react";
import {
  deleteFilterByType,
  FilterOption,
  FilterType,
  getSquareFootageFilter,
  SquareFootageOption,
  tryFilter,
} from "../../../../_shared/catalog/filter";
import {NatDropdown} from "../../../../_shared/generics/dropdown/NatDropdown";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {NatDropdownOption} from "../../../../_shared/generics/dropdown/NatDropdownOption";
import {useDynamicOptions} from "./useDynamicOptions";

export const SquareFootageFilter = (props: {groups: any[]}) => {
  const {filters, checkLengthWithAdditionalFilter} = useProductCatalog();
  const options: SquareFootageOption[] = [
    {label: "Less than 500 sq.ft.", value: {low: -Infinity, high: 500}},
    {
      label: "Between 500 and 800 sq.ft.",
      value: {low: 500, high: 800},
    },
    {
      label: "Between 800 and 1,100 sq.ft.",
      value: {low: 800, high: 1100},
    },
    {label: "More than 1,100 sq.ft.", value: {low: 1100, high: Infinity}},
  ];
  const availableOptions = useDynamicOptions({
    options: options,
    optionType: FilterType.SquareFootage,
  });

  return (
    <NatDropdown
      label={"Sq. ft."}
      options={availableOptions}
      trackingId={"square-footage-filter-dropdown"}
      callBack={(option: NatDropdownOption) => {
        tryFilter(getSquareFootageFilter(option.label, option.value), filters);
      }}
      conditional={(option: NatDropdownOption) => {
        return Math.abs(
          checkLengthWithAdditionalFilter(
            getSquareFootageFilter(option.label, option.value),
            props.groups
          )
        );
      }}
      disabledCallback={() => deleteFilterByType("square_footage")}
      disabled={
        filters.filter((f: FilterOption) => f.type === "square_footage")
          .length === options.length
      }
    />
  );
};
