import {generateProjectPDF} from "../../../../design-tool/backend/ConfigurationAPI";
import {storage, Utilities} from "../../../../../database/firebase";
import {downloadImageToDesktop} from "../../SitePlanView/logic";
import {GenericModal} from "../../../../_shared/generics/GenericModal";
import React, {useState} from "react";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";

export const GeneratePDFModal = (props) => {
  const {showModal, setShowModal, currentConfigurationId} = props;
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const {sitePlanImages} = useCurrentProject();
  const [selectedSitePlan, setSelectedSitePlan] = useState(null);
  const close = () => {
    setShowModal(false);
    setSelectedSitePlan(null);
  };

  let mainContent = "Should take 10-15 seconds...";
  let sitePlanImageId = null;
  if (selectedSitePlan) {
    sitePlanImageId = selectedSitePlan;
  } else if (sitePlanImages.length === 1) {
    sitePlanImageId = sitePlanImages[0];
  }

  if (!generatingPDF) {
    if (sitePlanImages.length > 1 && !selectedSitePlan) {
      mainContent = (
        <div
          style={{
            width: "100%",
            height: "400px",
          }}
        >
          {"Select the site plan for the Proposal PDF"}
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              width: "100%",
              height: "100%",
            }}
          >
            {sitePlanImages.map((imageId) => {
              return (
                <div
                  style={{
                    minWidth: "80%",
                    height: "100%",
                    position: "relative",
                  }}
                  onClick={() => {
                    setSelectedSitePlan(imageId);
                  }}
                >
                  <SmartImageWithId
                    imageId={imageId}
                    style={{objectFit: "contain"}}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      mainContent = (
        <div
          style={{
            width: "100%",
            height: "400px",
          }}
        >
          {"Are you sure you want to generate another PDF?"}
          <div
            style={{
              minWidth: "80%",
              height: "100%",
              position: "relative",
            }}
          >
            <SmartImageWithId
              imageId={sitePlanImageId}
              style={{objectFit: "contain"}}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <GenericModal
      show={showModal}
      handleClose={close}
      header={generatingPDF ? "Generating PDF..." : "Confirm PDF Generation"}
      content={mainContent}
      footer={
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexFlow: "wrap-reverse",
          }}
          hidden={generatingPDF}
        >
          <button
            className="button-secondary"
            onClick={close}
            style={{
              margin: "5px",
              width: "45%",
              minWidth: "150px",
              flexGrow: 1,
              marginTop: "10px",
            }}
          >
            {"Cancel"}
          </button>
          <button
            className="red-button-next"
            onClick={async () => {
              setGeneratingPDF(true);
              await Utilities.setDocumentWithMerge(
                Utilities.collection("configurationSites"),
                currentConfigurationId,
                {
                  sitePlan: {
                    imageId: sitePlanImageId,
                    images: sitePlanImages,
                  },
                }
              );

              return generateProjectPDF(currentConfigurationId).then(
                (storageRef) => {
                  close();
                  setGeneratingPDF(false);
                  return storage
                    .ref(storageRef)
                    .getDownloadURL()
                    .then((url) => {
                      return downloadImageToDesktop(
                        url,
                        "Project_Proposal.pdf"
                      );
                    });
                }
              );
            }}
            style={{
              margin: "5px",
              width: "45%",
              minWidth: "150px",
              marginTop: "10px",
              flexGrow: 1,
            }}
            disabled={!sitePlanImageId}
          >
            {"Generate & Download PDF"}
          </button>
        </div>
      }
    />
  );
};
