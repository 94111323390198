import {
  getLengthAndWidthString,
  getSqFtString,
} from "../../../../design-tool/logic/ProductUtils";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import {Row, RowLeft, RowRight} from "../styles";
import React from "react";

export const getEntriesForUnit = (currentProduct) => {
  return [
    {
      title: "Model",
      value: currentProduct.title,
    },
    {
      title: "Bedrooms",
      value: currentProduct.productDetails.bedrooms,
    },
    {
      title: "Bathrooms",
      value: currentProduct.productDetails.bathrooms,
    },
    {
      title: "Dimensions",
      value: getLengthAndWidthString(currentProduct),
    },
    {
      title: "Size",
      value: getSqFtString(currentProduct),
    },
    {
      title: "Unit Price",
      value: priceTextFromMicros(
        currentProduct.productDetails.unitPriceMicros,
        "accurate"
      ),
    },
  ];
};

function getRows(rows) {
  return rows.map((entry, index) => {
    return (
      <Row className={"left-right"} key={"right-column-" + entry.title + index}>
        <RowLeft>{entry.title}</RowLeft>
        <RowRight>{entry.value}</RowRight>
      </Row>
    );
  });
}

export function getColumnsFromEntries(entries) {
  const half = Math.ceil(entries.length / 2);
  const firstHalf = entries.slice(0, half);
  const leftColumn = getRows(firstHalf);
  const rightColumn = entries.length < 2 ? null : getRows(entries.slice(half));
  return {leftColumn, rightColumn};
}

export function getEntriesFromModifiers(modifierGroups) {
  const entries = [];
  modifierGroups.forEach((modifierGroup) => {
    modifierGroup.selectedModifiers.forEach((modifier) => {
      entries.push({
        title: modifier.title,
        value: priceTextFromMicros(
          modifier.adjustedPriceMicros,
          "integer-sign"
        ),
      });
    });
  });
  return entries;
}
