import React from "react";
import {useSelector} from "react-redux";
import {Icon} from "../components/icon";
import intake_contact_page from "../../../assets/icons/pngs/intake_contact_page.png";
import intake_multi_family from "../../../assets/icons/pngs/intake_multi_family.png";
import intake_placement from "../../../assets/icons/pngs/intake_placement.png";
import intake_reason from "../../../assets/icons/pngs/intake_reason.png";
import intake_timeline from "../../../assets/icons/pngs/intake_timeline.png";

export const CurrentIcon = () => {
	const step = useSelector((state) => state.intakeForm.step);

	switch (step) {
		case "step-zero":
			return (
				<Icon
					iconSrc="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fstep-zero-icon.png?alt=media&token=1180e85f-51f4-4510-bd1e-fc5fcfa43015"
					iconAlt="check marks and x's icon"
				/>
			);
		case "step-one":
			return <Icon iconSrc={intake_placement} iconAlt="house icon" />;
		case "step-one-substep-one":
			return (
				<Icon
					iconSrc="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fstep-one-substep-one-icon.png?alt=media&token=a6a3e85c-2e67-4ab4-aa6d-a50d6b85e6c0"
					iconAlt="crane icon"
				/>
			);
		case "step-one-substep-two":
			return (
				<Icon
					iconSrc="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fstep-one-substep-two-icon.png?alt=media&token=a7fe06e4-070b-47e2-8ede-5b144b647ee4"
					iconAlt="house in thought bubble"
				/>
			);
		case "step-two":
			return <Icon iconSrc={intake_reason} iconAlt="light bulb icon" />;
		case "step-two-substep-one":
			return <Icon iconSrc={intake_multi_family} iconAlt="house icon" />;
		case "step-three":
			return <Icon iconSrc={intake_timeline} iconAlt="calender icon" />;
		case "step-contact":
			return <Icon iconSrc={intake_contact_page} iconAlt="pencil icon" />;
		case "step-confirmation":
			return (
				<Icon
					iconSrc="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fconfirmation-icon.png?alt=media&token=59945730-5cff-4369-b14c-4f1d5ac91846"
					iconAlt="check mark icon"
				/>
			);
		default:
			<Icon
				iconSrc="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fstep-three-icon.png?alt=media&token=127b5067-a54d-4798-8d47-51ace1dcace7"
				iconAlt="house icon"
			/>;
	}
};
