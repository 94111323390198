import styled, {css} from "styled-components";
import {BLACK as blk} from "../colors";
import {calculatePadding, INatStyle} from "../generics/_shared";
const StyledLink = styled.a`
   {
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    color: ${blk};
    text-decoration: none;
    &:hover {
      color: ${blk};
      font-weight: bold;
    }
  }
`;

export const NatStyle = css<INatStyle>`
  align-items: center;
  background-color: ${(props: INatStyle) => props.initialBackgroundColor};
  border-radius: 50%;
  color: ${(props: INatStyle) => props.initialTextColor};
  cursor: pointer;
  user-select: none;
  font-size: ${(props: INatStyle) => props.textSize};
  padding: ${(props: INatStyle) => calculatePadding(props.textSize)};
  text-decoration: ${(props: INatStyle) => props.initialTextDecoration};
  transition: background-color 150ms ease;
  &:hover {
    text-decoration: ${(props: INatStyle) => props.hoverTextDecoration};
    background-color: ${(props: INatStyle) => props.hoverBackgroundColor};
    color: ${(props: INatStyle) => props.hoverTextColor};
  }
`;

export const NatLinkStyle = css<INatStyle>`
  align-items: center;
  background-color: ${(props: INatStyle) => props.initialBackgroundColor};
  border-radius: 50%;
  color: ${(props: INatStyle) => props.initialTextColor};
  cursor: pointer;
  font-size: ${(props: INatStyle) => props.textSize};
  padding: 0.5rem;
  text-decoration: ${(props: INatStyle) => props.initialTextDecoration};
  transition: background-color 150ms ease;
  &:hover {
    text-decoration: ${(props: INatStyle) => props.hoverTextDecoration};
    background-color: ${(props: INatStyle) => props.hoverBackgroundColor};
    color: ${(props: INatStyle) => props.hoverTextColor};
  }
`;
// Calendly Z-Index -> main/src/components/portal/views/DashboardView/styles/calendlyStyles.css (10,000,000)
export const DEVELOPER_TOOLS_Z_INDEX = 9999999;
export const LOGIN_PANEL_Z_INDEX = 9000000;
export const LOADING_PANEL_Z_INDEX = 8000000;
export const MODAL_Z_INDEX = 7900000;
export const LIGHTBOX_Z_INDEX = 7000000;
export const MAPPING_Z_INDEX = 6900000;

export const SIDEBAR_NAV_Z_INDEX = 6500200;
export const SIDEBAR_CONTAINER_Z_INDEX = 6500150;
export const SIDEBAR_MOBILE_ICON_Z_INDEX = 6500100;

export const DROPDOWN_Z_INDEX = 6000100;
export const DROPDOWN_PANEL_Z_INDEX = 6000000;

export const HEADER_Z_INDEX = 5000000;
export const FOOTER_Z_INDEX = HEADER_Z_INDEX; // Share the index

export const BUTTON_Z_INDEX = 1000000;
export const IMAGE_Z_INDEX = 500000;

export {StyledLink};
