import * as turf from "@turf/turf";

function getStreetLines(
	parcelLines,
	neighborParcels,
	map,
	L,
	{shouldShowLines}
) {
	/**
	 * Checks to see if the midpoint of each parcel line overlaps a bufferred neighboring line
	 * if it does, we know that it is likely a neigbor-facing line
	 *
	 * @param parcelLines
	 * @param neighborParcels
	 * @param map - leaflet instance
	 * @param L - for creating leaflet items
	 * @param shouldShowLines - development only! set to true to see the colored lines in development mode
	 * @returns - Array of street facing lines
	 *
	 * Why?
	 * This allows us to give it a setback different to that of the street-facing line
	 *  */

	const parcelLinesNextToNeighbors = [];

	parcelLines.forEach((parcelLine) => {
		const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
		let hasOverlap = false;

		shouldShowLines &&
			L.geoJson(parcelLine, {
				weight: 6,
				color: randomColor,
			}).addTo(map);

		// Check if neighboring parcel overlaps the property lines
		for (let np of neighborParcels) {
			let npLines = turf.lineSegment(np).features;

			// Check each property line within neighboring parcel and compare it to the current property line
			for (let npLine of npLines) {
				const randomColor =
					"#" + Math.floor(Math.random() * 16777215).toString(16);
				const turfParcelLine = turf.lineString(parcelLine.geometry.coordinates);
				const bufferedNPLine = turf.buffer(npLine.geometry, 1, {
					units: "feet",
				});

				shouldShowLines &&
					L.geoJson(npLine, {
						weight: 2,
						color: randomColor,
					}).addTo(map);

				const explodedParcelLine = turf.explode(turfParcelLine);

				// check if the midpoint of each parcel line, buffered by 3 feet, overlaps any neighbor lines

				// get midpoint
				const midpoint = turf.midpoint(
					explodedParcelLine.features[0],
					explodedParcelLine.features[1]
				);

				// bufferize it
				const bufferedMidpoint = turf.buffer(midpoint, 10, {
					units: "feet",
				});

				shouldShowLines &&
					L.geoJson(bufferedMidpoint, {
						weight: 3,
						color: randomColor,
					}).addTo(map);

				if (turf.booleanOverlap(bufferedMidpoint, bufferedNPLine)) {
					hasOverlap = true;
					parcelLinesNextToNeighbors.push(parcelLine);
					break;
				} else {
					hasOverlap = false;
				}
			}
		}
		return hasOverlap;
	});

	shouldShowLines &&
		L.geoJson(parcelLinesNextToNeighbors, {
			weight: 10,
			color: "red",
		}).addTo(map);

	const streetSideLines = parcelLines.filter((l) => {
		return !parcelLinesNextToNeighbors.some((npLine) => npLine.id === l.id);
	});

	shouldShowLines &&
		L.geoJson(streetSideLines, {
			weight: 10,
			color: "green",
		}).addTo(map);

	return {streetSideLines, parcelLinesNextToNeighbors};
}

export function getStreetVsNeighboringLines(
  parcelBoundary,
  neighborParcels,
  map,
  L
) {
  let parcelLines = turf.lineSegment(parcelBoundary).features;

  /**
   * Checks to see if the midpoint of parcel each line overlaps a bufferred neighboring line
   * if it does, we know that it is likely a neigbor-facing line
   *  */
  const lotLines = getStreetLines(parcelLines, neighborParcels, map, L, {
    shouldShowLines: false,
  });

  return lotLines;
}
