import {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {
  CustomerProjectInfo,
  ICustomerInfo,
  INITIAL_DEPOSIT_PAYMENT_EVENT_ID,
} from "@natomas/core";
import {fetchUser} from "../../../database/firebase/user";
import {getParameter} from "../cookies";
import {useGlobalConfiguration} from "./useGlobalConfiguration";
import {IStore} from "../slices/types/Store";
import {
  CONFIGURATION_ID_URL_KEY,
  USER_ID_URL_KEY,
} from "../../../database/firebase/configuration/configuration";
import {useUrl} from "./useUrl";
import {
  getEventObjectFromUser,
  isInitialDepositPaid,
} from "../../portal/_shared/utilities";
import {useCurrentProject} from "./useCurrentProject";
import {saveProjectInfo} from "../../../database/firebase/project";
import {Project, Customer} from "@natomas/core";

export enum CUSTOMER_STATUS {
  COMPLETED = "completed",
  IN_PROGRESS = "in-progress",
  DISABLED = "disabled",
}

// This hook is for retrieving information about the portal customer/user
const getUID = (configuration: any, loggedInUser: any) => {
  const cidParam = getParameter(CONFIGURATION_ID_URL_KEY, true);
  const uidParam = getParameter(USER_ID_URL_KEY, true);
  if (uidParam) {
    return uidParam;
  } else if (cidParam || configuration) {
    return configuration?.uid;
  } else {
    return loggedInUser?.uid;
  }
};

const isCustomerPreDeposit = (
  userInfo: ICustomerInfo,
  projectSummary: CustomerProjectInfo | null
) => {
  const initialDepositFee =
    userInfo != null &&
    projectSummary != null &&
    getEventObjectFromUser(userInfo, INITIAL_DEPOSIT_PAYMENT_EVENT_ID) != null;
  const validUserLacksFee =
    userInfo != null && !userInfo.initial_fee_paid && !initialDepositFee;
  const projectIsMissingOpportunityId = projectSummary?.opportunity_id == null;

  return validUserLacksFee && projectIsMissingOpportunityId;
};

export interface ICustomerStatus {
  contactInfoStatus: CUSTOMER_STATUS;
  productSelectedStatus: CUSTOMER_STATUS;
  mappingStatus: CUSTOMER_STATUS;
  depositPaidStatus: CUSTOMER_STATUS;
}

export const useCurrentCustomer = (): {
  customer: ICustomerInfo;
  actions: any;
  isCustomerPreDeposit: boolean;
  user_id: string;
  customerStatus: ICustomerStatus;
} => {
  const loggedInUser = useSelector((state: IStore) => state.global.user);
  const users = useSelector((state: IStore) => state.global.users);
  const userActionsMap = useSelector((state: IStore) => state.user.userActions);
  const {url} = useUrl();
  const {IS_SSD_LIVE, IS_MAPPING_ENABLED, AUTO_VERIFY_PROJECT_INFO} =
    useGlobalConfiguration();
  const {
    projectSummary,
    mergedProjectInfos,
    currentConfigurationId,
    configuration,
  } = useCurrentProject();

  useEffect(() => {
    // TODO let's figure out an order of operations here...
    const uid = getUID(configuration, loggedInUser);

    if (uid) {
      fetchUser(uid, false);
    }
  }, [url, users, configuration, loggedInUser]);

  const uid = getUID(configuration, loggedInUser) ?? "";
  const userInfo = users[uid];
  const userActions = useMemo(() => {
    if (uid && userActionsMap) {
      return userActionsMap[uid];
    }
    return null;
  }, [userActionsMap, uid]);

  let contactInfoStatus =
    projectSummary?.address === undefined
      ? CUSTOMER_STATUS.IN_PROGRESS
      : CUSTOMER_STATUS.COMPLETED;
  let productSelectedStatus =
    configuration?.product && contactInfoStatus === CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.COMPLETED
      : contactInfoStatus !== CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.DISABLED
      : CUSTOMER_STATUS.IN_PROGRESS;
  let mappingStatus =
    configuration?.sitePlan &&
    productSelectedStatus === CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.COMPLETED
      : productSelectedStatus !== CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.DISABLED
      : CUSTOMER_STATUS.IN_PROGRESS;
  let depositPaidStatus =
    (IS_MAPPING_ENABLED &&
      mappingStatus === CUSTOMER_STATUS.COMPLETED &&
      isInitialDepositPaid(userInfo)) ||
    (!IS_MAPPING_ENABLED &&
      productSelectedStatus === CUSTOMER_STATUS.COMPLETED &&
      isInitialDepositPaid(userInfo))
      ? CUSTOMER_STATUS.COMPLETED
      : (IS_MAPPING_ENABLED && mappingStatus === CUSTOMER_STATUS.COMPLETED) ||
        (!IS_MAPPING_ENABLED &&
          productSelectedStatus === CUSTOMER_STATUS.COMPLETED)
      ? CUSTOMER_STATUS.IN_PROGRESS
      : CUSTOMER_STATUS.DISABLED;

  const isPreDeposit =
    IS_SSD_LIVE && isCustomerPreDeposit(userInfo, mergedProjectInfos);
  if (!isPreDeposit) {
    contactInfoStatus = CUSTOMER_STATUS.COMPLETED;
    productSelectedStatus = CUSTOMER_STATUS.COMPLETED;
    mappingStatus = CUSTOMER_STATUS.COMPLETED;
    depositPaidStatus = CUSTOMER_STATUS.COMPLETED;
  }

  useEffect(() => {
    if (
      currentConfigurationId &&
      AUTO_VERIFY_PROJECT_INFO &&
      userInfo &&
      projectSummary === null &&
      configuration?.id === currentConfigurationId &&
      configuration?.uid === userInfo.user_id
    ) {
      saveProjectInfo(
        currentConfigurationId,
        Project.dataToProject(
          currentConfigurationId,
          Customer.getFirstName(userInfo),
          Customer.getLastName(userInfo),
          {
            type: "primary",
            number: Customer.getPrimaryContactPhoneNumber(userInfo),
          },
          Customer.getContactAddress(userInfo)
        )
      );
    }
  }, [
    currentConfigurationId,
    userInfo,
    AUTO_VERIFY_PROJECT_INFO,
    projectSummary,
    configuration,
  ]);

  return {
    customer: userInfo,
    actions: userActions,
    isCustomerPreDeposit: isPreDeposit,
    user_id: uid,
    customerStatus: {
      contactInfoStatus,
      productSelectedStatus,
      mappingStatus,
      depositPaidStatus,
    },
  };
};
