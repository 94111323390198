import {Elements, useStripe} from "@stripe/react-stripe-js";
// TODO Failed import from Stripe?
import {loadStripe} from "@stripe/stripe-js";
import {getStripePublicKey} from "../stripe";
import {NatButton} from "./button";
import {StyleOption, TextSize} from "./_shared";
import {formNewURLQuery, formNewURLWithQueries} from "../navigation/_helpers";
import {HOME_PAGE_ID} from "../../portal/_shared/navigation/PageNavigation";
import {usePage} from "../hooks/usePage";
import {JourneyStepName, useTracking} from "../hooks/useTracking";

const stripePromise = loadStripe(getStripePublicKey());

export const StripeCheckoutButton = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeButton {...props} />
    </Elements>
  );
};

const StripeButton = (props) => {
  const stripe = useStripe();
  const {clientReferenceId, email, priceSKU} = props;
  const {markJourneyEvent, markPageView} = useTracking();
  const option = props.option ?? StyleOption.BlackWillColorLight;

  const successfulPayment = () => {
    return formNewURLWithQueries([
      {key: "page", value: HOME_PAGE_ID},
      {key: "proposalFeePaid", value: "true"},
    ]);
  };

  const canceledPayment = () => {
    return formNewURLQuery("proposalFeePaid", "false");
  };

  return (
    <NatButton
      option={option}
      type={"button"}
      spinnerEnabled={true}
      label={props.label}
      size={TextSize.NORMAL}
      clickEvent={() => {
        markPageView("Proposal Stripe Checkout");
        markJourneyEvent(JourneyStepName.PAYMENT_APPROACHED);
        return stripe.redirectToCheckout({
          lineItems: [
            {
              price: priceSKU,
              quantity: 1,
            },
          ],
          clientReferenceId: clientReferenceId,
          customerEmail: email,
          mode: "payment",
          successUrl: successfulPayment(),
          cancelUrl: canceledPayment(),
        });
      }}
    />
  );
};
