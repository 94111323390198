import React from "react";
import {Button, Space} from "antd";
import {ProjectDesignFooterContainer} from "./styled";
import {useWindowSize} from "../../../../_shared/hooks/useWindowSize";
import {BREAKPOINT_DESKTOP_SMALL} from "../../theme/constants";

const leafletImage = require("leaflet-image");

export const ProjectDesignFooter = ({
	handleBackBtnClick,
	product,
	handleProceed,
}) => {
	const isDesktop = useWindowSize().width < BREAKPOINT_DESKTOP_SMALL;

	return (
		<ProjectDesignFooterContainer>
			<Space size="small" direction="vertical">
				<button
					className={"button-primary full-width bold-white"}
					onClick={() => {
						handleProceed();
					}}
				>
					Save & Continue
				</button>
				{isDesktop && (
					<Button block onClick={() => handleBackBtnClick(null)}>
						Back
					</Button>
				)}
			</Space>
		</ProjectDesignFooterContainer>
	);
};
