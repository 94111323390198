import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button} from "../components/buttons/button";
import {MobilePrice} from "../components/mobilePrice";
import {ModifierGroup} from "../components/ModifierGroup";
import {SectionStepBar} from "../components/SectionStepBar";
import {CurrentImage} from "../logic/currentImage";
import {SummaryPage} from "./SummaryPage";
import {isMobile} from "../../_shared/navigation";
import {isBlankString} from "@natomas/core";
import {scrollToTop} from "../../_shared/navigation/_helpers";
import {useDesignStudio} from "../../_shared/hooks/useDesignStudio";
import {RiInformationLine} from "react-icons/ri";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import {
  TimelineToolTip,
  TimelineToolTipContent,
  TimelineToolTipTitle,
} from "../../portal/views/ProposalView/styles";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";

function getSortSections(sections) {
  return Object.keys(sections)
    .map((key) => {
      return {...sections[key], id: key};
    })
    .sort(function (a, b) {
      return a.index > b.index ? 1 : -1;
    });
}

const InformationIconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 24px;
  right: 50px;
  z-index: 100000;
`;

export const ConfigurationPage = (props) => {
  const {currentProduct, step, formik} = props;
  const {currentCategory, currentCategoryToDisplay} = useDesignStudio({
    categoryInfoToDisplay: true,
  });
  const baseConfigurationPage = currentCategory;
  const configurationPage = currentCategoryToDisplay;
  const [sectionId, setSectionId] = useState(null);
  const [sections, setSections] = useState(null);

  const mobileVersion = useDynamicValue({
    forFour: 1,
    forEight: 1,
    forTwelve: 0,
  });
  const imageContainerHeight = mobileVersion ? "240px" : "100%";

  useEffect(() => {
    if (
      baseConfigurationPage != null &&
      baseConfigurationPage.sections != null &&
      Object.keys(baseConfigurationPage.sections).length > 1
    ) {
      const sortedSections = getSortSections(baseConfigurationPage.sections);
      if (
        sectionId == null ||
        baseConfigurationPage.sections[sectionId] == null
      ) {
        setSections(sortedSections);
        setSectionId(sortedSections[0].id);
      } else {
        let index = null;
        sortedSections.forEach((section, i) => {
          if (section.id === sectionId) {
            index = i;
          }
        });
        if (index != null) {
          if (index > 0) {
            window.prevButtonAction = () => {
              setSectionId(sortedSections[index - 1].id);
            };
          } else {
            window.prevButtonAction = null;
          }
          if (index < sortedSections.length - 1) {
            window.nextButtonAction = () => {
              setSectionId(sortedSections[index + 1].id);
            };
          } else {
            window.nextButtonAction = null;
          }
        }
      }
    } else {
      window.nextButtonAction = null;
      window.prevButtonAction = null;
      setSections(null);
      setSectionId(null);
    }
    const selectionView = document.querySelector("#description-wrapper");
    if (selectionView != null) {
      selectionView.scrollTop = 0;
    }
    scrollToTop(false);
  }, [baseConfigurationPage, sectionId]);

  const isEditing = useSelector((state) => state.catalog.editing);
  const nodeRef = React.useRef(null);

  if (configurationPage == null) {
    return null;
  }

  let modifierGroups = configurationPage.fullModifierGroups.filter(
    (e) => e.id != null
  );
  if (sectionId != null && baseConfigurationPage.sections != null) {
    const section = baseConfigurationPage.sections[sectionId];
    if (section?.items != null) {
      modifierGroups = modifierGroups.filter((e) =>
        section.items.includes(e.id)
      );
    } else {
      if (isEditing) {
        const sectionIds = sections.map((section) => section.id);
        modifierGroups = modifierGroups.filter(
          (e) =>
            e.sectionId === sectionId ||
            sectionIds.indexOf(e.sectionId) < 0 ||
            isBlankString(e.sectionId)
        );
      } else {
        modifierGroups = modifierGroups.filter(
          (e) => e.sectionId === sectionId
        );
      }
    }
  }

  const modifierGroupDivs =
    modifierGroups == null
      ? null
      : modifierGroups.map((modifierGroup, index) => {
          return (
            <ModifierGroup
              formik={formik}
              product={currentProduct}
              isEditing={isEditing}
              modifierGroup={modifierGroup}
              modifierGroupId={modifierGroup.id}
              configurationPageId={configurationPage.id}
              key={"modifier_group" + modifierGroup.id}
              hideUnderline={index === modifierGroups.length - 1}
            />
          );
        });

  let imageContainerStyle = {
    position: "sticky",
    top: mobileVersion ? "60px" : 0,
    backgroundColor: "white",
    zIndex: 100,
  };
  imageContainerStyle.height = imageContainerHeight;

  let descriptionDiv = null;
  if (!isBlankString(configurationPage.displayDescription)) {
    descriptionDiv = (
      <>
        <br />
        <p style={{fontStyle: "italic", marginBottom: "0px"}}>
          {configurationPage.displayDescription}
        </p>
      </>
    );
  }

  return (
    <div className="row custom-row">
      <div
        className="current-image-wrapper"
        style={imageContainerStyle}
        ref={nodeRef}
      >
        <CurrentImage
          configurationPage={configurationPage}
          modifierGroups={modifierGroups}
          product={currentProduct}
        />

        <ReactTooltip
          className={"opaque tool-tip"}
          backgroundColor={"white"}
          id={"rendering_disclaimer"}
          effect="solid"
          getContent={() => {
            return (
              <TimelineToolTip>
                <TimelineToolTipTitle>Heads up!</TimelineToolTipTitle>
                <TimelineToolTipContent>
                  {"Please note that due to computer monitor differences, photographic\n" +
                    "lighting variances, and changes in dye casting during the manufacturing\n" +
                    "process, colors shown online may not match precisely on your finished home."}
                </TimelineToolTipContent>
              </TimelineToolTip>
            );
          }}
        />
        <InformationIconContainer
          hidden={isMobile()}
          data-tip
          data-for={"rendering_disclaimer"}
        >
          <RiInformationLine />
        </InformationIconContainer>
      </div>
      <div
        className="current-description-wrapper show-scroll"
        id="description-wrapper"
        style={{padding: "20px"}}
      >
        <div className="custom-container">
          <div id="top-anchor" />
          {step === "summary" ? (
            <SummaryPage currentProduct={currentProduct} />
          ) : (
            <div className="bath-form-wrapper" style={{paddingBottom: "0px"}}>
              <h2 className="step-title">{configurationPage.heading}</h2>
              {descriptionDiv}
              <form>
                <SectionStepBar
                  sectionId={sectionId}
                  sections={sections}
                  onClick={(sectionId) => {
                    setSectionId(sectionId);
                  }}
                />
                {modifierGroupDivs}
                <Button componentClass="hidden red-button" id="form-button">
                  Next
                </Button>
              </form>
              <MobilePrice />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
