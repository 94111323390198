export const convertSearchPayloadForDBFormat = (payload) => {
	const {buildingData, parcelData, ...others} = payload;
	const parcelDataForDB = Object.assign({}, parcelData);

	const buildingDataForDB = convertArrayOfBuildingCoordinatesForDB(
		buildingData
	);

	parcelDataForDB.coordinates = parcelDataForDB.coordinates.map((object) => {
		const points = object.map((point) => {
			return {
				coordinate: point,
			};
		});
		return {
			path: points,
		};
	});

	const dataForDB = {
		buildingData: buildingDataForDB,
		parcelData: parcelDataForDB,
		...others,
	};
	return dataForDB;
};

export const convertSearchPayloadFromDBFormat = (data) => {
	if (data == null || data.buildingData == null || data.parcelData == null) {
		return null;
	}
	const {buildingData, parcelData, neighborData, ...others} = data;
	const parcelDataForClient = Object.assign({}, parcelData);

	const buildingDataForClient = buildingData.map((buildingDataRow) => {
		const rowCopy = Object.assign({}, buildingDataRow);
		rowCopy.coordinates = rowCopy.coordinates.map((object) => {
			const points = object.path.map((coordinate) => {
				return coordinate.coordinate;
			});
			return points;
		});
		return rowCopy;
	});

	parcelDataForClient.coordinates = parcelDataForClient.coordinates.map(
		(object) => {
			const points = object.path.map((coordinate) => {
				return coordinate.coordinate;
			});
			return points;
		}
	);

	const dataForClient = {
		buildingData: buildingDataForClient,
		parcelData: parcelDataForClient,
		neighborData: convertArrayOfBuildingsFromDB(neighborData),
		...others,
	};
	return dataForClient;
};

export const convertPlacementForDBFormat = (payload) => {
	const {geometry, ...others} = payload;
	const geometryForDB = Object.assign({}, geometry);

	geometryForDB.coordinates = geometryForDB.coordinates.map((object) => {
		const points = object.map((point) => {
			return {
				coordinate: point,
			};
		});
		return {
			path: points,
		};
	});

	const dataForDB = {
		geometry: geometryForDB,
		...others,
	};
	return dataForDB;
};

export const convertPlacementFromDBFormat = (data) => {
	if (data == null || data.geometry == null) {
		return null;
	}
	const {geometry, ...others} = data;
	const geometryForClient = Object.assign({}, geometry);

	geometryForClient.coordinates = geometryForClient.coordinates.map(
		(object) => {
			const points = object.path.map((coordinate) => {
				return coordinate.coordinate;
			});
			return points;
		}
	);

	const dataForClient = {
		geometry: geometryForClient,
		...others,
	};
	return dataForClient;
};

export const convertArrayOfBuildingCoordinatesForDB = (arr) => {
	const buildingDataForDB = arr.map((buildingDataRow) => {
		const rowCopy = Object.assign({}, buildingDataRow);
		rowCopy.coordinates = rowCopy.coordinates.map((object) => {
			const points = object.map((point) => {
				return {
					coordinate: point,
				};
			});
			return {
				path: points,
			};
		});
		return rowCopy;
	});
	return buildingDataForDB;
};

export const convertArrayOfBuildingsFromDB = (arr) => {
	const buildingDataForClient = arr.map((buildingDataRow) => {
		const rowCopy = Object.assign({}, buildingDataRow);
		rowCopy.coordinates = rowCopy.coordinates.map((object) => {
			const points = object.path.map((coordinate) => {
				return coordinate.coordinate;
			});
			return points;
		});
		return rowCopy;
	});

	return buildingDataForClient;
};
