import styled from "styled-components";
import {isMobile} from "../../../_shared/navigation";
import {NatomasButton} from "../../../_shared/generics/button/styles";
import {BUTTON_Z_INDEX, MAPPING_Z_INDEX} from "../../../_shared/styles";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/DashboardView/styles/globals";

export const ProductDescriptionContainer = styled.div<{mobile: boolean}>`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  justify-content: ${(props) => (props.mobile ? "auto" : "space-between")};
  align-items: flex-start;
  margin-bottom: 5rem;
`;

export const ProductImageContainer = styled.div`
  margin-top: 1rem;
  height: 100%;
  position: relative;
  cursor: pointer;
  .fullDiv {
    border-radius: 1rem;
  }
  ${NatomasButton} {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: ${BUTTON_Z_INDEX};
  }
`;

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 0;
  border-bottom: 1px solid #e0e0e0;
`;

export const ProductInfoTitle = styled.div`
  font-size: ${isMobile() ? "1rem" : "1.25rem"};
  font-family: Maison-Bold, serif;
`;

export const ProductModel = styled.div`
  font-size: ${isMobile() ? "1.25rem" : "2rem"};
`;

export const PriceContainerSticky = styled.div`
  position: sticky;
  top: 100px;
  width: 320px;
  z-index: ${MAPPING_Z_INDEX};
`;

export const PriceModelContainer = styled.div`
  font-size: 1rem;
`;

export const PriceModel = styled.span`
  font-size: ${isMobile() ? "1.25rem" : "1.5rem"};
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const ProductInfo = styled.div`
  font-size: ${isMobile() ? "0.85rem" : "1rem"};
`;

export const ProductInfoImage = styled.div<{height: string}>`
  width: 100%;
  height: ${(props) => props.height};
  margin-bottom: 1rem;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductHighlight = styled.div`
  svg {
    font-size: ${isMobile() ? "1rem" : "2rem"};
  }
  font-size: ${isMobile() ? "1rem" : "2rem"};
  padding-bottom: ${isMobile() ? "0.75rem" : "1.5rem"};
  &:last-child {
    padding-bottom: 0;
  }
`;

export const ProductDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const ProductDetails = styled.div`
  svg {
    margin-right: 4px;
    margin-top: -2px;
  }
  font-size: 1rem;
  padding-bottom: ${isMobile() ? "0.75rem" : "0.75rem"};
  margin-right: 1rem;
`;

export const StickyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: 0 1px 6px #e0e0e0;
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  background-color: white;
`;

export const StickyMap = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
`;

export const StickyCardRows = styled.div`
  padding: 1rem 0;
`;

export const StickyCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.75rem;
  padding: 0.75rem 0;
  border-top: 1px solid #e0e0e0;
`;

export const IncludedTag = styled.div`
  svg {
    font-size: 1rem;
    color: #82c275;
  }
`;
