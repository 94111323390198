export const Checkmark = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M4 9.5L8.61538 14L16 5"
				stroke="#415053"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};
