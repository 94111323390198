import {useState} from "react";
import {useSelector} from "react-redux";
import {PopUp} from "./modal";
import {OneLine} from "./one-line";
import {SHOW_ONLY_UNIT_PRICING} from "../logic/ConfigurationSettings";
import {UnitDescription} from "./UnitDescription";
import {Customer, isBlankString} from "@natomas/core";
import {useCurrentProject} from "../../_shared/hooks/useCurrentProject";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";

export const SummaryHeader = (props) => {
  const {product, preview} = props;

  const [show, setShow] = useState(false);
  const [step, setStep] = useState("price");
  const handleShow = (event) => {
    setStep(event.target.dataset.modal);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const {customer} = useCurrentCustomer();

  let titleText = "Your Villa Selections";
  if (
    preview !== true &&
    customer != null &&
    !isBlankString(Customer.getFullName(customer))
  ) {
    titleText = Customer.getFirstName(customer) + "'s Selections";
  }

  if (SHOW_ONLY_UNIT_PRICING() || true) {
    return (
      <div>
        <h2
          className="step-title"
          style={{
            marginBottom: "40px",
          }}
        >
          {titleText}
        </h2>
        <UnitDescription hideDimensions={true} product={product} />
      </div>
    );
  }

  return (
    <div>
      <h2 className="step-title">{titleText}</h2>
      <UnitDescription product={product} />

      <div className="description-wrapper">
        <OneLine leftText="ADU Unit Price" rightText="Included" />
        <OneLine
          leftText="Show Details"
          rightText=""
          onClick={handleShow}
          className={"show-details"}
          data-modal={"price"}
        />
      </div>

      <div className="description-wrapper">
        <OneLine leftText="Base Construction Package" rightText="Included" />
        <OneLine
          leftText="Show Details"
          rightText=""
          onClick={handleShow}
          className={"show-details"}
          data-modal={"base"}
        />
      </div>
      <PopUp show={show} handleClose={handleClose} initialStep={step} />
    </div>
  );
};
