import { arcgisToGeoJSON, geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";
import * as turf from "@turf/turf";
import { getStreetVsNeighboringLines } from "./streets";
import { getNonAduStructures } from "./structures";

// Converts multiPolygons to polygons and multiLines to lines (needed for turf.difference)
export const convertMultipolygonToPolygon = (feature) =>
  arcgisToGeoJSON(geojsonToArcGIS(feature));

export function getSetbacks(mapLayers, parcelData, neighborData, map, L) {
  let feasibleZone;
  const primaryResidence = getNonAduStructures(mapLayers)[0];
  const {
    parcelLinesNextToNeighbors,
    streetSideLines,
  } = getStreetVsNeighboringLines(
    turf.feature(parcelData),
    neighborData,
    map,
    L
  );

  if (parcelLinesNextToNeighbors && streetSideLines) {
    feasibleZone = getFeasibleZone(
      parcelData,
      parcelLinesNextToNeighbors,
      streetSideLines
    );
  } else {
    /*
     * if we cannot calculate the property lines,
     * we just set the feasible zone as the parcel boundary
     * */
    feasibleZone = parcelData;
  }

  // TODO - check spreadsheet or DB for primary residence separation and handle it here
  try {
    feasibleZone = turf.difference(
      feasibleZone,
      turf.buffer(primaryResidence, 5, {
        units: "feet",
      })
    );
  } catch (e) {
    console.error("error computing setbacks from primary structure");
  }

  return feasibleZone;
}

function getFeasibleZoneWithFrontYardSetback(
  parcelBoundary,
  parcelLinesNextToNeighbors,
  streetSideLines
) {
  var parcel = convertMultipolygonToPolygon(parcelBoundary);
  const parcelSides = [];
  const lotLines = { parcelLinesNextToNeighbors, streetSideLines };

  for (let lineType in lotLines) {
    if (lineType === "parcelLinesNextToNeighbors") {
      for (let nbLine of lotLines["parcelLinesNextToNeighbors"]) {
        parcelSides.push(turf.buffer(nbLine, 4, { units: "feet" }));
      }
    }

    if (lineType === "streetSideLines") {
      for (let streetLine of lotLines["streetSideLines"]) {
        parcelSides.push(turf.buffer(streetLine, 20, { units: "feet" }));
      }
    }
  }

  for (let parcelSide of parcelSides) {
    parcel = turf.difference(parcel, parcelSide);
  }
  return parcel;
}

export function getFeasibleZone(
  parcelBoundary,
  parcelLinesNextToNeighbors,
  streetSideLines
) {
  try {
    return getFeasibleZoneWithFrontYardSetback(
      parcelBoundary,
      parcelLinesNextToNeighbors,
      streetSideLines
    );
  } catch (e) {
    console.error("getFeasibleZone error ", e);
    return getFeasibleAreaFallback(parcelBoundary);
  }
}

/** Computes setbacks on property boundary
 * @params parcel geometry
 * @returns buffered property boundary
 */
function getFeasibleAreaFallback(propertyGeometry) {
  let feasibleArea = convertMultipolygonToPolygon(propertyGeometry);
  const yards = [];
  const lotLine = turf.polygonToLine(propertyGeometry);
  const setback = 4;

  yards.push(turf.buffer(lotLine, setback, { units: "feet" }));
  for (let yard of yards) {
    feasibleArea = turf.difference(feasibleArea, yard);
  }
  return feasibleArea;
}
