import {
  formatPhoneNumber,
  PhoneNumberFormatType,
  TeamMemberContactInfo,
} from "@natomas/core";
import blank from "../../../../../assets/images/headshots/blank.png";
import {getFullNameWithFirstAndLast} from "../../../../_shared/user/UserUtils";
import React from "react";
import {AvatarContainer, ContactContainer, ContactLineItem} from "../styles";

export const TeamContact = (props: {contact: TeamMemberContactInfo}) => {
  const {contact} = props;
  let phoneNumber = "";
  if (contact.phone_numbers != null && contact.phone_numbers.length > 0) {
    phoneNumber =
      formatPhoneNumber(
        contact.phone_numbers[0].number,
        PhoneNumberFormatType.PARENTHESIS_AND_DASHES
      ) ?? "";
  }

  return (
    <ContactContainer>
      <AvatarContainer>
        <img
          alt={contact.first_name}
          src={contact.photo_url ?? blank}
          height={"100%"}
          width={"100%"}
          style={{objectFit: "cover"}}
          onError={(event: any) => {
            event.target.src = blank;
          }}
        />
      </AvatarContainer>
      <div className={"bold"}>
        {getFullNameWithFirstAndLast(contact.first_name, contact.last_name)}
      </div>
      <ContactLineItem>{contact.title}</ContactLineItem>
      <a href={`mailto:${contact.email}`}>
        <ContactLineItem>{contact.email ?? ""}</ContactLineItem>
      </a>
      <a href={`tel:${phoneNumber}`}>
        <ContactLineItem>{phoneNumber ?? ""}</ContactLineItem>
      </a>
    </ContactContainer>
  );
};
