import {
  ADDRESSES_KEY,
  COMPANY_KEY,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  PHONE_NUMBER_KEY,
} from "../../_shared/forms/FormModels";
import {
  formatPhoneNumber,
  isBlankString,
  PhoneNumberFormatType,
} from "@natomas/core";
import {useDispatch, useSelector} from "react-redux";
import {setStep} from "../slices/IntakeFormSlice";
import {Authentication, Users, Utilities} from "../../../database/firebase";
import firebase from "firebase";
import {useUTMInputs} from "../hooks/useUTMInputs";
import {MAX_CHARACTER_LENGTH} from "../../_shared/forms/FormConstants";

const isValid = (values, errors) => {
  const missingFields = isBlankString(values[EMAIL_KEY]);
  return !missingFields && Object.values(errors).length <= 0;
};

export const useMultiFamilyIntakeFormValues = (formik) => {
  const {values, errors} = formik;
  const company = values[COMPANY_KEY];
  let addresses = values[ADDRESSES_KEY].join("; ");
  addresses = addresses.substring(
    0,
    Math.min(MAX_CHARACTER_LENGTH - 1, addresses.length)
  );
  const utmInputs = useUTMInputs();
  const dispatch = useDispatch();

  const phoneNumber = formatPhoneNumber(
    values[PHONE_NUMBER_KEY],
    PhoneNumberFormatType.RAW
  );

  const information = [
    {
      inputType: "text",
      inputName: "first_name",
      inputValue: values[FIRST_NAME_KEY],
    },
    {
      inputType: "text",
      inputName: "last_name",
      inputValue: values[LAST_NAME_KEY],
    },
    {inputType: "email", inputName: "email", inputValue: values[EMAIL_KEY]},
    {
      inputType: "tel",
      inputName: "phone",
      inputValue: phoneNumber,
    },
    {
      inputType: "text",
      inputName: "company_name",
      inputValue: company,
    },
    {
      inputType: "text",
      inputName: "addresses",
      inputValue: addresses,
    },
    ...utmInputs,
  ];

  const isFormValid = isValid(values, errors);
  const finalSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    document.querySelector("#hidden-submit-button").click();
    formik.setFieldValue("confirmation", "call");
    dispatch(setStep("step-confirmation"));

    const submissionPayload = {};
    information.map((line) => {
      submissionPayload[line.inputName] =
        line.inputValue != null ? line.inputValue : "null";
    });
    submissionPayload.timestamp =
      firebase.firestore.FieldValue.serverTimestamp();
    return Utilities.collection("intake_form_submissions")
      .doc()
      .set(submissionPayload);
  };

  return {information, isFormValid, finalSubmit};
};
