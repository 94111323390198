import {priceTextFromMicros} from "../logic/ConfigurationPageHelper";
import {
	getBasePriceForDesignStudio,
	getBedBathString,
	getLengthAndWidthString,
	getSqFtString,
} from "../logic/ProductUtils";

export const UnitDescription = (props) => {
	const {product, hideDimensions} = props;
	return (
		<div>
			<h4 style={{marginBottom: "14px"}}>{product.title}</h4>
			<div style={{marginBottom: "14px"}}>{product.displayDescription}</div>

			<div className="one-line" style={{marginBottom: "4px"}}>
				<p>{getBedBathString(product)}</p>
				<p>
					{priceTextFromMicros(getBasePriceForDesignStudio(product), "min")}
				</p>
			</div>
			<div
				className="one-line"
				style={{
					marginBottom: "10px",
					display: hideDimensions === true ? "none" : "flex",
				}}
			>
				<p>{getLengthAndWidthString(product)}</p>
				<p>{getSqFtString(product)}</p>
			</div>
		</div>
	);
};
