import styled from "styled-components";
import {EditProductOptionKeys} from "../../../design-tool/admin/components/ProductOptionKeyEditor";
import {EditProductOptionValues} from "../../../design-tool/admin/components/ProductOptionValueEditor";
import React, {useEffect, useState} from "react";
import {ModifierGroupTab} from "./ModifierGroupTab";
import {ModifierTab} from "./ModifierTab";
import {ProductsTab} from "./ProductsTab/ProductsTab";
import {CategoryTab} from "./CategoryTab";
import {OverviewTab} from "./OverviewTab/OverviewTab";
import {MatrixTab} from "./MatrixTab/";
import {ProductAdminSideBar} from "../components/Sidebar/ProductAdminSideBar";
import {isBlankString} from "@natomas/core";
import {Helmet} from "react-helmet";
import {PRODUCT_ADMIN_TABS} from "../constants/Constants";
import {useUrl} from "../../../_shared/hooks/useUrl";
import {updateAdditionLeftPadding} from "../../../_shared/slices/PageSlice";
import {getStore} from "../../../../store";

const MainLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const MainContentWrapper = styled.div`
  width: 300px;
  flex-grow: 2;
  height: 100%;
  overflow: hidden;
  background: white;
`;

const getPageID = (page: string | null) => {
  if (page && !isBlankString(page)) {
    return page;
  }

  return PRODUCT_ADMIN_TABS[0].id;
};

export const ProductAdminLayout = () => {
  const [selectedTab, setSelectedTab] = useState("overview");
  const {url, getPageFromURL} = useUrl();

  useEffect(() => {
    setSelectedTab(getPageID(getPageFromURL()));
  }, [url]);

  let editingModals = (
    <>
      <EditProductOptionKeys />
      <EditProductOptionValues />
    </>
  );

  let mainContent;

  /*
   * Each main content needs to account for the primary side bar,
   * and potentially a tab specific side bar.
   * The primary sidebar is currently -> 10rem or 160px
   * The tab specific side bars can be customized.
   * Simply add those widths together and that is the
   * additional left padding you need to dispatch.
   * Yes, this is implemented poorly.
   * However, I don't think it will be changed much.
   * */

  switch (selectedTab) {
    case "overview":
      mainContent = <OverviewTab />;
      getStore().dispatch(updateAdditionLeftPadding(192));
      break;
    case "products":
      mainContent = <ProductsTab />;
      getStore().dispatch(updateAdditionLeftPadding(304));
      break;
    case "categories":
      mainContent = <CategoryTab />;
      getStore().dispatch(updateAdditionLeftPadding(368));
      break;
    case "modifierGroups":
      mainContent = <ModifierGroupTab />;
      getStore().dispatch(updateAdditionLeftPadding(368));
      break;
    case "modifiers":
      mainContent = <ModifierTab />;
      getStore().dispatch(updateAdditionLeftPadding(368));
      break;
    case "matrix":
      mainContent = <MatrixTab />;
      getStore().dispatch(updateAdditionLeftPadding(192));
      break;
    default:
      mainContent = <OverviewTab />;
      getStore().dispatch(updateAdditionLeftPadding(192));
      break;
  }

  return (
    <div>
      <Helmet>
        <title>Villa | Product Admin</title>
        <meta name="description" content="Your Villa Dashboard" />
      </Helmet>
      <MainLayout>
        {editingModals}
        <ProductAdminSideBar selectedTab={selectedTab} />
        <MainContentWrapper>{mainContent}</MainContentWrapper>
      </MainLayout>
    </div>
  );
};
