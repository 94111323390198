import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import * as Api from "./api";
import * as Authentication from "./authentication";
import * as Configuration from "./configuration";
import * as Email from "./email";
import * as Sitework from "./sitework";
import * as Types from "./types";
import * as Utilities from "./utilities";
import * as Users from "./user";
// TODO invert this dependency
import {
  currentUser,
  initializeFirebase,
} from "../../components/_shared/slices/GlobalSlice";
// TODO Invert this dependency
import {isDev, isProd} from "../../components/_shared/application";
// TODO address nav concerns
import {getStore} from "../../store";
import {DEV_BACKEND_ENVIRONMENT} from "./.developerSettings";
// TODO invert this dependency
import {getFirstAndLastFromFullName} from "../../components/_shared/user/UserUtils";
import {fetchUserActions} from "./user";
import {getFirebaseConfiguration} from "./firebaseConfig";

let database;
let firestore;
let storage;
let functions;
let auth;
let analytics;
let performance;

const initializeFirebaseApps = (config) => {
  if (config != null && !firebase.apps.length) {
    database = firebase.initializeApp(config);
  } else {
    database = firebase.app();
  }
  firestore = firebase.firestore();
  storage = firebase.storage();
  functions = firebase.functions();
  auth = firebase.auth();
  auth.onAuthStateChanged(onAuthChange);
  analytics = firebase.analytics();
  performance = firebase.performance();

  if (isDev) {
    functions.useEmulator("localhost", 5001);
  }

  // TODO Check if this is fine.
  getStore().dispatch(initializeFirebase(null));
};

export const backendConfig = getFirebaseConfiguration(DEV_BACKEND_ENVIRONMENT);

if (!firebase.apps.length) {
  if (isDev && backendConfig) {
    initializeFirebaseApps(backendConfig);
  } else {
    fetch("/__/firebase/init.json").then(async (response) => {
      initializeFirebaseApps(await response.json());
    });
  }
} else {
  initializeFirebaseApps(null);
}

export function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  firebase
    .auth()
    .signInWithRedirect(provider)
    .then()
    .catch((error) => {
      console.log(error);
    });
}

export function logOut() {
  return firebase.auth().signOut();
}

export function isProdBackend() {
  // devLogger(backendConfig);
  return backendConfig.projectId === "natomas-app" || isProd;
}

function onAuthChange(user) {
  if (user == null) {
    return getStore().dispatch(currentUser(null));
  }
  // TODO will remove soon, just want to keep it for now 12/09/2021
  // if (!IS_SSD_LIVE) {
  //   fetchUserInfo(user.uid).then((data) => {
  //     if (data === null)
  //       devLogger("User has not configured their account details, yet.");
  //     else getStore().dispatch(currentUser(data));
  //   });
  // }
  window.heap.identify?.(user.uid);

  const {firstName, lastName} = getFirstAndLastFromFullName(user.displayName);
  const stateUser = {
    firstName: firstName,
    lastName: lastName,
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    uid: user.uid,
  };

  fetchUserActions(user.uid);
  return getStore().dispatch(currentUser(stateUser));
}

export {
  auth,
  analytics,
  database,
  firestore,
  functions,
  performance,
  storage,
  Api,
  Authentication,
  Configuration,
  Email,
  Sitework,
  Types,
  Users,
  Utilities,
};
