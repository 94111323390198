import React, {Dispatch, SetStateAction, useMemo} from "react";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {priceFromText} from "../../../../design-tool/logic/ConfigurationPageHelper";
import {isBlankString} from "@natomas/core";
import ReactDataSheet from "react-datasheet";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import useMountEffect from "../../../../_shared/hooks/useMountEffect";
import {fetchProduct} from "../../../../design-tool/backend/catalogApi";
import {getPriceValue} from "./utilities";
import {getTable} from "./table";
import {MatrixTableHeaderRow, MatrixTableWrapper} from "./styles";
import {DependenciesDataProps} from "./DependenciesModal";
import {LoadingPanel} from "../../../../_shared/generics/loading-panel";

export interface MatrixTableProps {
  matrixEdits: any;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  matrixField: string;
  setData: Dispatch<SetStateAction<DependenciesDataProps | undefined>>;
  loading: boolean;
}

export const MatrixTable = (props: MatrixTableProps) => {
  const {matrixField, matrixEdits, setMatrixEdits, setData, loading} = props;
  const {currentGroupModifiers, currentGroupProductModifiers, currentProducts} =
    useProductCatalog();
  const transpose = true;

  useMountEffect(() => {
    if (currentProducts.length > 0) {
      currentProducts.forEach((product: any) => {
        fetchProduct(product.id);
      });
    }
  });

  const matrixData = useMemo(() => {
    return getTable(
      currentProducts,
      currentGroupProductModifiers,
      currentGroupModifiers,
      matrixEdits,
      setMatrixEdits,
      {matrixField, setData, transpose}
    );
  }, [currentGroupModifiers, matrixEdits]);

  return (
    <PageContainer overridePadding={true}>
      <MatrixTableWrapper>
        <LoadingPanel show={loading} />
        <ReactDataSheet
          data={matrixData}
          valueRenderer={(cell: any) => {
            const value = cell.value;
            if (matrixField === "prices") {
              const priceValue = getPriceValue(value);
              if (priceValue !== 0 && cell.readOnly !== true) {
                return priceFromText(value, "min");
              }
            }

            return value;
          }}
          rowRenderer={(props) => {
            if (props.row === 0) {
              return (
                <MatrixTableHeaderRow>{props.children}</MatrixTableHeaderRow>
              );
            } else {
              return <tr>{props.children}</tr>;
            }
          }}
          onCellsChanged={(changes) => {
            // TODO default descriptions dont delete/change?
            const newEdits: any = {};
            changes.forEach(
              (change: {
                cell: any;
                row: number;
                col: number;
                value: string | null;
              }) => {
                if (change.cell.value !== change.value) {
                  const editDictionary =
                    newEdits[change.cell.key] != null
                      ? newEdits[change.cell.key]
                      : {};
                  if (isBlankString(change.value)) {
                    editDictionary[matrixField] = "";
                  } else {
                    if (matrixField === "prices") {
                      editDictionary[matrixField] =
                        "" + getPriceValue(change.value);
                    } else {
                      editDictionary[matrixField] = change.value;
                    }
                  }
                  newEdits[change.cell.key] = editDictionary;
                }
              }
            );
            const copyOfEdits = Object.assign({}, matrixEdits);
            const newMatrixEdits = Object.assign(copyOfEdits, newEdits);
            setMatrixEdits(newMatrixEdits);
          }}
        />
      </MatrixTableWrapper>
    </PageContainer>
  );
};
