import React, {useState} from "react";
import {
  DropdownContainer,
  DropdownLabel,
  DropdownOption,
  DropdownOptionsContainer,
} from "./styles";
import {NatDropdownOption} from "./NatDropdownOption";
import {
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getHoverTextColor,
  getHoverTextDecoration,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextSize,
  StyleOption,
  TextSize,
} from "../_shared";
import {BiChevronDown} from "react-icons/bi";
import {IoIosClose} from "react-icons/io";
import {NatBackdropPanel} from "../_shared/backdrop-panel/NatBackdropPanel";

export const NatDropdown = (props: {
  label: string;
  options: NatDropdownOption[];
  trackingId: string;
  callBack: (value: any) => void;
  disabled: boolean;
  conditional?: (value: NatDropdownOption) => number;
  disabledCallback?: () => void;
}) => {
  const {
    label,
    options,
    callBack,
    conditional,
    disabled,
    disabledCallback,
    trackingId,
  } = props;
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const toggle = () => {
    if (!disabled) setShowOptions(!showOptions);
    else disabledCallback && disabledCallback();
  };

  const selectOption = (option: any) => {
    setShowOptions(false);
    callBack(option);
  };

  const getLabel = (o: NatDropdownOption, check: number | undefined) => {
    if (check) {
      return o.label + " (" + check + ")";
    } else {
      return o.label;
    }
  };

  const getDropdownOptions = () => {
    return options.map((o: NatDropdownOption) => {
      let conditionalCheck = conditional ? conditional(o) : undefined;
      return (
        <DropdownOption
          key={"nat-dropdown-" + o.label}
          onClick={() => selectOption(o)}
          disabled={conditionalCheck === 0}
        >
          {getLabel(o, conditionalCheck)}
        </DropdownOption>
      );
    });
  };

  const option = StyleOption.LIGHT_TOGGLE;

  return (
    <DropdownContainer>
      <DropdownLabel
        initialBackgroundColor={getInitialBackgroundColor(option)}
        initialTextColor={getInitialTextColor(option)}
        initialTextDecoration={getInitialTextDecoration(option)}
        initialBorderColor={getInitialBorderColor(option)}
        hoverBackgroundColor={getHoverBackgroundColor(option)}
        hoverTextColor={getHoverTextColor(option)}
        hoverTextDecoration={getHoverTextDecoration(option)}
        hoverBorderColor={getHoverBorderColor(option)}
        enabledBorderColor={getEnabledBorderColor(option)}
        enabledBackgroundColor={getEnabledBackgroundColor(option)}
        textSize={getTextSize(TextSize.SMALL)}
        onClick={() => toggle()}
        enabled={!disabled}
        name={trackingId}
      >
        {label}
        {disabled ? <IoIosClose /> : <BiChevronDown />}
      </DropdownLabel>
      <NatBackdropPanel
        show={showOptions}
        disabled={disabled}
        clickEvent={toggle}
      />
      <DropdownOptionsContainer show={showOptions && !disabled}>
        {getDropdownOptions()}
      </DropdownOptionsContainer>
    </DropdownContainer>
  );
};
