import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {TextButton} from "../components/buttons/textButton";
import {StepBar} from "../components/step-bar";
import "./styles.scss";
import {priceTextFromMicros} from "../logic/ConfigurationPageHelper";
import {Customer, makeNounPlural} from "@natomas/core";
import {sortByIndex} from "@natomas/core";
import {Underline} from "../components/underline";
import {Button} from "../components/buttons/button";
import {getBasePriceForDesignStudio} from "../logic/ProductUtils";
import {UnitDescription} from "../components/UnitDescription";
import {
  setProductGroupId,
  setProductId,
} from "../../_shared/slices/CatalogSlice";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {toUnitById} from "../../_shared/navigation";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";

const filterProducts = (products, floorplanStep) => {
  return Object.values(products)
    .filter((product) => {
      let productBedroomQuantity = 0;
      if (product.productDetails != null) {
        productBedroomQuantity = product.productDetails.bedrooms;
      }
      return productBedroomQuantity === floorplanStep;
    })
    .sort((a, b) => {
      if (a.priceMicros === b.priceMicros) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
      }
      return a.priceMicros - b.priceMicros;
    });
};

const getSelectedProduct = (filteredProducts, selectedProduct) => {
  if (selectedProduct != null) {
    for (const product of filteredProducts) {
      if (product.id === selectedProduct.id) {
        return product;
      }
    }
  }

  if (filteredProducts.length > 0) {
    return filteredProducts[0];
  }

  return null;
};

const getInitialStep = (selectedProduct, steps) => {
  let productBedroomQuantity = steps.length > 0 ? steps[0].value : 0;
  if (selectedProduct != null && selectedProduct.productDetails != null) {
    productBedroomQuantity = selectedProduct.productDetails.bedrooms;
  }
  return productBedroomQuantity;
};

export const FloorplanStep = (props) => {
  const dispatch = useDispatch();
  const {selectedProduct, setSelectedProduct, filteredGroups} = props;

  const {currentProducts, productGroup} = useProductCatalog();
  const {customer} = useCurrentCustomer();

  const nodeRef = React.useRef(null);

  const onClickProduct = (product) => {
    setSelectedProduct(product);
  };

  //Array of objects to create the step bar and fill it's values
  const stepsSet = new Set();
  currentProducts.forEach((product) => {
    let productBedroomQuantity = 1;
    if (product?.productDetails != null) {
      productBedroomQuantity = product.productDetails.bedrooms;
    }
    stepsSet.add(productBedroomQuantity);
  });

  let items = [];
  stepsSet.forEach((value) => {
    const text = `${value} Bedroom${makeNounPlural(value)}`;
    items.push({
      value: value,
      index: value,
      onClick: () => {
        setFloorplanStep(value);
      },
      text: text,
    });
  });
  items = sortByIndex(items);

  const [floorplanStep, setFloorplanStep] = useState(
    getInitialStep(selectedProduct, items)
  );

  const filteredProducts = filterProducts(currentProducts, floorplanStep);
  useEffect(() => {
    if (filteredProducts.length === 0 && currentProducts.length > 0) {
      const newFloorPLanStep = getInitialStep(selectedProduct, items);
      if (floorplanStep !== newFloorPLanStep) {
        setFloorplanStep(getInitialStep(selectedProduct, items));
      }
    } else {
      setSelectedProduct(getSelectedProduct(filteredProducts, selectedProduct));
    }
  }, [floorplanStep, currentProducts]);

  const onClickContinue = () => {
    if (selectedProduct) {
      dispatch(setProductId(selectedProduct.id));
      toUnitById(selectedProduct.id);
    }
  };

  const onClickBack = () => {
    dispatch(setProductGroupId(null));
  };

  let visibleProductDetails = null;

  if (selectedProduct != null) {
    visibleProductDetails = <UnitDescription product={selectedProduct} />;
  }

  let titleText = "Choose your Villa";
  if (filteredGroups.length > 1) {
    titleText = productGroup?.title;
  } else if (customer != null) {
    titleText = "Welcome " + Customer.getFirstName(customer) + "!";
  }

  return (
    <div className="bath-form-wrapper">
      <h2 className="step-title">{titleText}</h2>
      <StepBar step={floorplanStep} items={items} />

      <div
        className="floorplan-buttons button-layout"
        ref={nodeRef}
        style={{marginBottom: "50px"}}
      >
        {filteredProducts.map((product) => {
          return (
            <TextButton
              key={product.id}
              buttonId={product.id}
              onClick={() => {
                onClickProduct(product);
              }}
              buttonText={product.title}
              price={priceTextFromMicros(
                getBasePriceForDesignStudio(product),
                "min"
              )}
              isSelected={selectedProduct?.id === product.id}
            />
          );
        })}
      </div>

      <Underline className="option-underline" />
      <br />

      {visibleProductDetails}
      <div
        className="next-button-wrapper"
        style={{
          marginLeft: "0px",
          marginTop: "30px",
          marginBottom: "30px",
          display: "flex",
        }}
      >
        <button
          style={{width: "44%", marginRight: "10%"}}
          className={"button-secondary"}
          onClick={onClickBack}
          hidden={filteredGroups.length < 2}
        >
          Back
        </button>
        <Button
          componentClass="next-button red-button-next"
          id="next"
          onClick={onClickContinue}
          style={{width: "44%", flexGrow: 2}}
        >
          Start Designing
        </Button>
      </div>
    </div>
  );
};
