import {FilterType} from "./types";

export const verifyOption = (
  optionType: FilterType,
  filterType: FilterType,
  optionLabel: string,
  filterLabel: string
) => {
  return optionLabel === filterLabel && optionType === filterType;
};
