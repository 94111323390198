/* eslint-disable no-mixed-operators */
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Button} from "./buttons/button";
import "./styles.scss";
import {setURLHash} from "../../_shared/navigation/_helpers";
import logo from "../../../assets/logos/villa-logo.svg";
import mobileLogo from "../../../assets/logos/villa-logo-cropped.svg";
import {isMobile, toPortal} from "../../_shared/navigation";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {getCategoriesForProduct} from "../logic/ProductCategoryHelper";
import {saveConfiguration} from "../backend/ConfigurationAPI";
import {useDesignStudio} from "../../_shared/hooks/useDesignStudio";
import {LogoContainer} from "../../_shared/generics/header/styles";
import {NatButton} from "../../_shared/generics/button";
import {StyleOption, TextSize} from "../../_shared/generics/_shared";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";

function mobileNavBar(
  product,
  productGroupCategories,
  step,
  navigateStep,
  currentConfigurationPage,
  shouldShowSaveButton,
  saveChanges
) {
  let content = null;
  if (product != null && productGroupCategories != null) {
    const categories = getCategoriesForProduct(product, productGroupCategories);
    content = (
      <>
        {categories.map((category, index) => {
          return (
            <div className="nav-bar-item" key={category.id}>
              <Button
                componentClass="nav-link"
                id="nav-link-exterior"
                className={
                  currentConfigurationPage.id === category.id &&
                  step !== "summary"
                    ? "active"
                    : ""
                }
                data-step={index}
                onClick={navigateStep}
              >
                {index + 1}. {category.title}
              </Button>
            </div>
          );
        })}
        <div className="col-sm-2 custom-col-sm-2">
          <Button
            componentClass="nav-link"
            id="nav-link-exterior"
            className={step === "summary" ? "active" : ""}
            data-step="summary"
            onClick={navigateStep}
          >
            {categories.length + 1 + ". Summary"}
          </Button>
        </div>
      </>
    );
  }
  return (
    <div>
      <div className={`mobile-nav-bar`} style={{marginTop: "62px"}}>
        <div className="mobile-nav-bar-wrapper" id="nav-bar-wrapper">
          {content}

          <div className="small-space" />
        </div>
      </div>
      <div
        style={{
          padding: "16px 18px",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          zIndex: 5,
        }}
      >
        <LogoContainer src={mobileLogo} onClick={() => toPortal()} />
        <NatButton
          label={"Save Design"}
          type={"button"}
          size={TextSize.SMALL}
          disabled={!shouldShowSaveButton}
          option={
            shouldShowSaveButton
              ? StyleOption.ColorWillDarken
              : StyleOption.SECONDARY
          }
          clickEvent={() => {
            saveChanges();
          }}
          trackingId={"design-studio-save-design"}
        />
      </div>
    </div>
  );
}

export function NavBar(props) {
  const {formik} = props;
  const step = useSelector((state) => state.catalog.step);
  const productId = useSelector((state) => state.catalog.productId);
  const hasFirstEdit = useSelector((state) => state.configuration.firstEdit);
  const hasSavedBefore = useSelector(
    (state) =>
      state.configuration.fetchedConfiguration != null &&
      state.configuration.fetchedConfiguration.product != null
  );
  const isProductSelected = useSelector(
    (state) => state.catalog.productId != null
  );
  const isEditingSelections = useSelector(
    (state) => state.configuration.editingSelections
  );
  const {product, productGroupCategories} = useProductCatalog();
  const currentConfigurationPage = useDesignStudio().currentCategory;
  const productConfigurationPages = productGroupCategories;

  //Changes step to whatever linked was clicked
  const navigateStep = (event) => {
    event.preventDefault();
    const step = event.target.dataset.step;
    setURLHash(step);
  };

  const saveChanges = () => {
    saveConfiguration(formik);
  };

  useEffect(() => {
    if (product != null && product.configuratorPages != null) {
      let index = product.configuratorPages.indexOf(step);
      if (step === "summary") {
        index = product.configuratorPages.length; // summary page
      }
      const nav = document.querySelector("#nav-bar-wrapper");
      if (nav != null) {
        nav.scrollLeft = isMobile() ? index * 90 : index * 300;
      }
    }
  }, [step, product]);

  const isConfigurationPagesNotPopulated =
    productConfigurationPages == null || product?.configuratorPages == null;

  const shouldShowSaveButton =
    hasFirstEdit && isEditingSelections && hasSavedBefore && isProductSelected;
  const shouldShowSaveButtonMobile =
    hasFirstEdit && isEditingSelections && isProductSelected;

  // if (currentConfigurationPage == null) {
  // 	return null;
  // }

  const shouldLookMobile = useDynamicValue({
    forFour: 1,
    forEight: 1,
    forTwelve: 0,
  });

  if (shouldLookMobile) {
    return mobileNavBar(
      product,
      productGroupCategories,
      step,
      navigateStep,
      currentConfigurationPage,
      shouldShowSaveButtonMobile,
      saveChanges
    );
  }

  let navBarStepsContent = null;
  if (productId != null) {
    const categories = getCategoriesForProduct(product, productGroupCategories);
    navBarStepsContent = (
      <>
        {isConfigurationPagesNotPopulated
          ? null
          : categories.map((configurationPage, index) => {
              return (
                <div className="nav-bar-item" key={configurationPage.id}>
                  <Button
                    componentClass="nav-link"
                    className={
                      currentConfigurationPage?.id === configurationPage.id &&
                      step !== "summary"
                        ? "active"
                        : ""
                    }
                    data-step={index}
                    onClick={navigateStep}
                  >
                    {index + 1}. {configurationPage.title}
                  </Button>
                </div>
              );
            })}
        {isConfigurationPagesNotPopulated ? null : (
          <div className="nav-bar-item">
            <Button
              componentClass="nav-link"
              className={step === "summary" ? "active" : ""}
              data-step="summary"
              onClick={navigateStep}
            >
              {categories.length + 1 + ". Summary"}
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={` custom-container-fluid ${
        step === "submit" ? "hidden" : "nav-bar"
      }`}
    >
      <div className="nav-row row" style={{flexWrap: "nowrap"}}>
        <div
          className="custom-col-sm-2 col-sm-2 custom-offset-1"
          style={{
            position: "relative",
            marginLeft: "50px",
            width: "200px",
            height: "100%",
            flex: "none",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              cursor: "pointer",
              position: "absolute",
              left: 0,
              width: "100%",
              objectFit: "cover",
              maxWidth: "65%",
              maxHeight: "80%",
            }}
            className={"unselectable"}
            onClick={() => {
              toPortal();
            }}
          />
        </div>

        <div className="nav-bar-stretch">
          <div
            style={{
              flexWrap: "nowrap",
              display: "flex",
              height: "100%",
              maxWidth: "950px",
            }}
            id="nav-bar-wrapper"
          >
            {navBarStepsContent}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            height: "100%",
            marginRight: "67px",
            marginLeft: "30px",
            display: shouldShowSaveButton ? "flex" : "none",
            width: "150px",
            flexGrow: 0,
            alignItems: "center",
          }}
        >
          <NatButton
            label={"Save Design"}
            trackingId={"save-design-top-right"}
            clickEvent={() => {
              saveChanges();
            }}
            type={"button"}
          />
        </div>
      </div>
    </div>
  );
}
