import React from "react";
import {
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
} from "../../_shared/forms/FormModels";
import {CheckMark} from "../../_shared/generics/CheckMark";
import {useSelector} from "react-redux";
import {formatPhoneNumber, PhoneNumberFormatType} from "@natomas/core";
import {
  NOT_SERVICEABLE_CONFIRMATION_MESSAGE,
  SERVICEABLE_CONFIRMATION_MESSAGE,
  WEBINAR_PAGE_SOURCE_FOR_REDIRECT,
} from "../logic/constants";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {
  getCalendlyBaseURL,
  getSalesPhoneNumber,
} from "../../_shared/constants/sales";

const getConfirmationText = (confirmation, pageSource) => {
  if (confirmation === "call") {
    return SERVICEABLE_CONFIRMATION_MESSAGE;
  }

  return NOT_SERVICEABLE_CONFIRMATION_MESSAGE;
};

const ConfirmationButtons = (props) => {
  const state = useSelector((state) => state.intakeForm.state);
  const {values, pageSource} = props;
  const phoneNumber = getSalesPhoneNumber(state);
  const first_name = values[FIRST_NAME_KEY];
  const last_name = values[LAST_NAME_KEY];
  const email = values[EMAIL_KEY];
  const confirmation = values["confirmation"];
  const name = first_name + "%20" + last_name;
  const {IS_WATCH_WEBINAR_LIVE, WEBINAR_REDIRECT_URL} =
    useGlobalConfiguration();

  const calendlyLink =
    getCalendlyBaseURL(state) + `?name=${name}&email=${email}`;

  let buttonComponents = [];

  if (
    pageSource?.includes(WEBINAR_PAGE_SOURCE_FOR_REDIRECT) &&
    IS_WATCH_WEBINAR_LIVE
  ) {
    buttonComponents.push(
      <a href={WEBINAR_REDIRECT_URL} className="black-btn link">
        Watch webinar on demand
      </a>
    );
  }

  if (confirmation === "call") {
    buttonComponents.push(
      <a href={"tel:" + phoneNumber} className="yellow-btn link">
        {"Call us now at " +
          formatPhoneNumber(
            phoneNumber,
            PhoneNumberFormatType.PARENTHESIS_AND_DASHES
          )}
      </a>,
      <a href={calendlyLink} className="white-btn link">
        Schedule free property assessment
      </a>
    );
  } else {
    buttonComponents.push(
      <a href="https://www.villahomes.com/referrals" className="black-btn link">
        Refer a friend
      </a>
    );
  }

  return <div className="confirmation-buttons">{buttonComponents}</div>;
};

export const Confirmation = (props) => {
  const {formik} = props;
  const {values} = formik;
  const pageSource = useSelector((state) => state.utm.page_source);
  const confirmation = values["confirmation"];

  return (
    <>
      <div className="form-wrapper">
        <CheckMark size={50} strokeWidth={4} />
        <div className="confirmation-title-wrapper">
          <h2 className="confirmation-title">We received your information</h2>
        </div>
        <p className="confirmation-text">
          {getConfirmationText(confirmation, pageSource)}
        </p>
        <ConfirmationButtons values={values} pageSource={pageSource} />
      </div>
    </>
  );
};
