import React, {useEffect, useState} from "react";
import Carousel from "react-bootstrap/Carousel";
import {useSelector} from "react-redux";
import {CarouselImage} from "./CarouselImage";

export const StepCarousel = (props) => {
	const [index, setIndex] = useState(0);
	const [hideCaption, setHideCaption] = useState(false);

	const handleSelect = (selectedIndex, e) => {
		if (e != null) {
			e.preventDefault();
			e.stopPropagation();
		}
		setIndex(selectedIndex);
	};

	const step = useSelector((state) => state.catalog.step);
	const [lastStep, setLastStep] = useState(step);

	const {images, animate} = props;
	const [lastImages, setLastImages] = useState(images);

	useEffect(() => {
		if (
			step !== lastStep ||
			images == null ||
			images.length < 2 ||
			lastImages == null ||
			lastImages.length < 1
		) {
			setIndex(0);
		} else if (images != null) {
			for (let i = 0; i < images.length; i++) {
				const image = images[i];
				if (lastImages.length > i) {
					const prevImage = lastImages[i];

					if (
						image.imageId !== prevImage.imageId ||
						image.caption !== prevImage.caption
					) {
						setIndex(i);
						break;
					} else if (
						!isArrayEqualShallow(image.overlayImages, prevImage.overlayImages)
					) {
						setIndex(i);
						break;
					}
				} else {
					setIndex(i);
					break;
				}
			}
		}
		setLastStep(step);
		setLastImages(images);
	}, [images, step]);

	let leftArrow = (
		<span className="arrows left-arrow">
			<img
				src="https://firebasestorage.googleapis.com/v0/b/alans-playground-ffa5f.appspot.com/o/Icons%2Fleft-arrow.svg?alt=media&token=c0a40e1d-37e0-4c8b-8899-eb3662534d50"
				alt="left-arrow"
			></img>
		</span>
	);
	let rightArrow = (
		<span className="arrows right-arrow">
			<img
				src="https://firebasestorage.googleapis.com/v0/b/alans-playground-ffa5f.appspot.com/o/Icons%2Fright-arrow.svg?alt=media&token=b7dc0ae0-f3d3-43d9-a4a4-e37b1e544688"
				alt="right-arrow"
			></img>
		</span>
	);

	if (images == null || images.length === 0) {
		return null;
	}

	if (images.length === 1) {
		leftArrow = null;
		rightArrow = null;

		return (
			<div
				className="carousel"
				onClick={() => {
					setHideCaption(!hideCaption);
				}}
			>
				<CarouselImage
					index={0}
					currentIndex={index}
					imageId={images[0].imageId}
					caption={images[0].caption}
					orientation={images[0].orientation}
					objectFit={images[0].objectFit}
					overlayImages={images[0].overlayImages}
					hideCaption={hideCaption}
				></CarouselImage>
			</div>
		);
	}

	return (
		<div
			className="carousel"
			onClick={() => {
				setHideCaption(!hideCaption);
			}}
		>
			<Carousel
				prevIcon={leftArrow}
				nextIcon={rightArrow}
				interval={animate === true ? 8000 : null}
				activeIndex={index}
				onSelect={handleSelect}
			>
				{images.map((image, imageIndex) => {
					return (
						<Carousel.Item key={imageIndex}>
							<CarouselImage
								index={imageIndex}
								currentIndex={index}
								imageId={image.imageId}
								objectFit={image.objectFit}
								caption={image.caption}
								orientation={image.orientation}
								overlayImages={image.overlayImages}
								hideCaption={hideCaption}
							></CarouselImage>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</div>
	);
};

function isArrayEqualShallow(a, b) {
	if (a == null && b == null) {
		return true;
	}
	if (a != null && b == null) {
		return false;
	}
	if (a == null && b != null) {
		return false;
	}

	if (a.length !== b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i++) {
		if (!areEqualShallow(a[i], b[i])) {
			return false;
		}
	}
	return true;
}

function areEqualShallow(a, b) {
	if (a == null && b == null) {
		return true;
	}
	if (a != null && b == null) {
		return false;
	}
	if (a == null && b != null) {
		return false;
	}

	for (var key in a) {
		if (a[key] !== b[key]) {
			return false;
		}
	}
	return true;
}
