import {getPricingForUnit} from "../../../_shared/ConfigurationPricingHelper";
import {getBedBathString} from "../../../../design-tool/logic/ProductUtils";
import {
  DesignListContainer,
  DesignListHeader,
  DesignListItemContainer,
} from "../styles";
import {OneLine} from "../../../../design-tool/components/one-line";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import React from "react";
import {makeNounPlural} from "@natomas/core";
import {scrollToTop} from "../../../../_shared/navigation/_helpers";
import {usePage} from "../../../../_shared/hooks/usePage";
import {isMobile} from "../../../../_shared/navigation";

const ConfigurationPageRow = (props) => {
  const {configurationPage, index, selectedPage, setSelectedPage} = props;
  const modifierGroups = configurationPage.selectedModifierGroups;
  let totalPriceInMicros = 0;
  let upgradeCount = 0;
  modifierGroups.forEach((modifierGroup) => {
    const modifiers = modifierGroup.selectedModifiers;
    modifiers.forEach((modifier) => {
      totalPriceInMicros += modifier.adjustedPriceMicros;
      if (modifier.adjustedPriceMicros !== 0) {
        upgradeCount += 1;
      }
    });
  });

  const upgradeCountString = `${upgradeCount} upgrade${makeNounPlural(
    upgradeCount
  )}`;
  const isRowSelected =
    selectedPage != null && selectedPage.id === configurationPage.id;

  return (
    <DesignListRow
      isSelected={isRowSelected}
      onClick={() => {
        setSelectedPage(configurationPage);
        scrollToTop(true);
      }}
      title={`${index}. ${configurationPage.title}`}
      price={priceTextFromMicros(totalPriceInMicros, "integer-sign")}
      subtitle={upgradeCountString}
    />
  );
};

const DesignListRow = (props) => {
  const {isSelected, onClick, title, price, subtitle} = props;
  return (
    <DesignListItemContainer
      isMobile={isMobile()}
      selected={isSelected}
      onClick={onClick}
    >
      <div style={{fontFamily: "Maison-Bold", fontSize: 18}}>
        <OneLine leftText={title} rightText={price} />
      </div>
      <div style={{fontSize: 16}}>{subtitle}</div>
    </DesignListItemContainer>
  );
};

const DesignListRowHeader = (props) => {
  const {title, price} = props;
  return (
    <DesignListHeader isMobile={isMobile()}>
      <OneLine leftText={title} rightText={price} />
    </DesignListHeader>
  );
};

export const DesignSummaryList = (props) => {
  const {
    configurationSnapshot,
    configuration,
    currentProduct,
    selectedPage,
    setSelectedPage,
  } = props;
  const {isNatMobile} = usePage();
  const pricing = getPricingForUnit(configuration);
  const unitSubtitle = `${currentProduct.title}  |  ${getBedBathString(
    currentProduct,
    "line"
  )}`;

  return (
    <DesignListContainer isMobile={isNatMobile()}>
      <DesignListRowHeader
        title={"Your Home"}
        price={priceTextFromMicros(
          pricing.unitBasePriceAndUpgradesInMicros,
          "accurate"
        )}
      />
      <DesignListRow
        isSelected={selectedPage == null}
        onClick={() => {
          setSelectedPage(null);
          scrollToTop(true);
        }}
        title={"1. Home"}
        price={priceTextFromMicros(pricing.unitBasePriceInMicros, "accurate")}
        subtitle={unitSubtitle}
      />
      {configurationSnapshot.snapshot.map((page, index) => {
        return (
          <ConfigurationPageRow
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            configurationPage={page}
            index={index + 2}
            key={page.id}
          />
        );
      })}
    </DesignListContainer>
  );
};
