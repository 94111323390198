export const FONT_FAMILY_REGULAR = "Maison-Book, sans-serif";
export const FONT_FAMILY_MEDIUM = "Maison-Medium, sans-serif";
export const FONT_FAMILY_BOLD = "Maison-Bold, sans-serif";

const white = "#fff";
const black = "#000";
const black10 = "rgba(0, 0, 0, 0.1)";
const black60 = "rgba(0, 0, 0, 0.6)";
const black70 = "rgba(0, 0, 0, 0.7)";
const black80 = "rgba(0, 0, 0, 0.8)";
const apple = "#82C275"; // "#8B9FE5";
const apple10 = "rgba(130, 194, 117, 0.1)"; // #B575C2
const apple30 = "rgba(130, 194, 117, 0.3)";
//const purple = "rgb(181, 117, 194)";
//const purple10 = "rgb(181, 117, 194, 0.1)";
const sand20 = "rgb(224, 207, 181, 0.2)";
const greenHover = "#225722";
const whiteGrey = "#E6E6E6";
const redOrange = "#FFD7CE";
const purple = "rgb(139, 159, 229)"; // #8B9FE5

export const colors = {
  noImage: purple,
  status: black80,
  description: black80,
  ctaCardBackground: white,
  ctaCardBorder: black10,
  ctaCardDescription: black70,
  ctaCardTitle: black,
  buttonBackground: white,
  buttonText: black,
  buttonBackgroundHover: apple, // black,
  buttonTextHover: black, // white,
  buttonBorderHover: apple,
  border: black,
  modalButtonBackground: apple,
  modalButtonText: black,
  modalButtonHoverBackground: greenHover, // black,
  modalButtonHoverText: white, // apple,
  modalBorder: apple,
  videoAndResourcesContainerBackground: sand20,
  videoModalDescription: black60,
  videoModalTitle: black,
  journeyCompleteBackground: apple10,
  journeyDetailMessage: black60,
  journeyDetailLineSeparator: black,
  journeyStepBorder: black10,
  journeyStepSelectedBorder: black,
  journeyStepDisabledBorder: black10,
  journeyStepStatusCompleteBackground: apple,
  watchVideoStatusStarted: whiteGrey,
  watchVideoStatusNotStarted: redOrange,
  watchVideoStatusComplete: apple30,
};
export const spacing = {
  paddingLeft: 84,
};

export const effect = {
  boxShadow: "0px 4px 17px rgba(97, 97, 97, 0.17)",
};
