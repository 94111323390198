import styled from "styled-components";
import {MODAL_Z_INDEX} from "../../../../_shared/styles";

export const WebinarContainerDark = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  //z-index: 9999999;
`;

export const WebinarContainer = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${MODAL_Z_INDEX};
`;

export const WebinarModalContent = styled.div`
  position: fixed;
  max-height: 90%;
  border-radius: 10px;
  width: 100%;
  vertical-align: text-top;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  //z-index: 10000000;
`;

export const WebinarBottomContent = styled.div`
  flex-direction: column-reverse;
  vertical-align: text-bottom;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5%;
  padding-right: 5%;
`;
export const WebinarFooter = styled.div``;

export const FooterButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-flow: wrap-reverse;
`;

export const WebinarTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133%;
  color: black;
  margin-top: 18px;
  margin-bottom: 4px;
`;

export const WebinarSubtitle = styled.div`
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  line-height: 133%;
`;
