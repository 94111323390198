import {createSlice} from "@reduxjs/toolkit";
import {ICustomerInfo} from "@natomas/core/application/entities/CustomerInfo";
import {resetFetchesForUser} from "@natomas/core";
import {IGlobalSlice} from "./types/Store";
import {WEBINAR_REDIRECT_URL} from "../../intake-form/logic/constants";
import {getStripeAssessmentFeeProduct, getStripePhase1Product} from "../stripe";
import {isProd} from "../application";

export enum PricingMode {
  UNIT,
  BASE,
}

const CONFIG_CONSTANTS = {
  // Sign Up
  submit_button_title: "Start Project",
  submit_page_title: "One last step! What's your info?",
  submit_password_reveal_enabled: true,

  // Generic
  IS_REFERRAL_ENABLED: true,
  IS_PROJECT_SWITCHING_LIVE: true,
  IS_WATCH_WEBINAR_LIVE: true,
  show_phase1_payment_button: true,
  show_PSA_status: true,
  show_proposal_pdf_download: true,
  WEBINAR_REDIRECT_URL: WEBINAR_REDIRECT_URL,
  phase1PriceSKU: getStripePhase1Product(),
  assessmentPriceSKU: getStripeAssessmentFeeProduct(),
  SHOW_MAPPING_PREVIEW: false,

  // SSD
  WELCOME_VIDEO_URL: "https://vimeo.com/664004858",
  WELCOME_INTERCOM_CHAT_ENABLED: false,
  IS_SSD_LIVE: true, // used to be (!isProd && true), but for now let's set this to false and turn it on in production when ready
  IS_SSD_LIVE_ON_INTAKE: !isProd, // used to be (!isProd && true), but for now let's set this to false and turn it on in production when ready
  IS_INTERCOM_LIVE: false,
  MODEL_HOME_TOUR_ENABLED: true,
  AUTO_VERIFY_PROJECT_INFO: true,
  STREAMLINED_JOURNEY: true,
  PRICING_MODE: PricingMode.UNIT,
};

const getInitialState = (): IGlobalSlice => {
  return {
    firebaseInitialized: false,
    url: "",
    user: null,
    userInitialized: null,
    users: {},
    images: {},
    imagesFetched: {},
    config: CONFIG_CONSTANTS,
    windowWidth: 0,
    windowHeight: 0,
  };
};

const globalSlice = createSlice({
  name: "global",
  initialState: getInitialState(),
  reducers: {
    resetUser: (state) => {
      resetFetchesForUser(state.user?.uid);
      state.user = null;
      state.user = null;
    },
    initializeFirebase: (state) => {
      state.firebaseInitialized = true;
    },
    updateURL: (state, action) => {
      state.url = action.payload;
    },
    currentUser: (state, action) => {
      state.user = action.payload;
      state.userInitialized = !!action.payload;
    },
    userLoaded: (state, action) => {
      const customer_info: ICustomerInfo = action.payload;
      // TODO We don't assign full name here
      state.users[customer_info.user_id] = customer_info;
    },
    imageFetched: (state, action) => {
      const imageId = action.payload;
      // @ts-ignore
      state.imagesFetched[imageId] = true;
    },
    imageDataLoaded: (state, action) => {
      const image = action.payload;
      // @ts-ignore
      state.images[image.id] = image;
    },
    enrichConfig: (state, action) => {
      const remoteConfig = action.payload;
      Object.keys(remoteConfig).forEach((key) => {
        // @ts-ignore
        state.config[key] = remoteConfig[key];
      });

      const userProperties: any = {};
      Object.keys(state.config).forEach((key) => {
        let value = state.config[key];
        if (typeof value != "string" && typeof value != "number") {
          value = value ? 1 : 0;
        }
        userProperties[key] = value;
      });

      // @ts-ignore
      window.heap.addUserProperties?.(userProperties);
    },
    setWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    setHeight: (state, action) => {
      state.windowHeight = action.payload;
    },
  },
});

export const globalReducer = globalSlice.reducer;
export const {
  resetUser,
  currentUser,
  imageFetched,
  imageDataLoaded,
  initializeFirebase,
  updateURL,
  enrichConfig,
  userLoaded,
  setHeight,
  setWidth,
} = globalSlice.actions;
