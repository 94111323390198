import {useSelector} from "react-redux";
import {logOut, signInWithGoogle} from "../../../database/firebase";

export function Account() {
  let content = "Not Currently Logged In";
  const user = useSelector((state) => state.global.user);

  let button = null;
  if (user != null && user !== false) {
    content = "Welcome, " + user.displayName;
    button = <button onClick={logOut}>Log Out</button>;
  } else {
    button = <button onClick={signInWithGoogle}>Log In With Google</button>;
  }

  return (
    <div className="app-wrapper">
      <center>
        <h3>{content}</h3>
        {button}
      </center>
    </div>
  );
}
