import React, {useEffect, useRef, useState} from "react";
import useFetch from "use-http";
import {Layout, message} from "antd";
import Map from "./components/Map";
import Search from "./components/Search";
import "./App.css";
import Wkt from "wicket";
import {LoadingSpinner} from "./components/Common/LoadingSpinner";
import {VIEWS} from "./theme/constants";
import {isMobile} from "../../_shared/navigation";
import {setURLPathAndQuery} from "../../_shared/navigation/_helpers";
import {
  convertArrayOfBuildingCoordinatesForDB,
  convertSearchPayloadForDBFormat,
  convertSearchPayloadFromDBFormat,
} from "./data/configurationMapsUtils";
import {useCurrentProject} from "../../_shared/hooks/useCurrentProject";
import {useHistory} from "react-router-dom";
import design_graphic from "../../../assets/images/graphics/design_graphic.png";
import styled from "styled-components";
import {isDev} from "../../_shared/application";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {Project, Address} from "@natomas/core";
import {useConfigurationLocation} from "../../portal/_shared/hooks/useConfigurationLocation";
import {Utilities} from "../../../database/firebase";
import {CONFIGURATION_MAPS_DB_KEY} from "../../../database/firebase/configuration/configuration";
import {devLogger} from "../../../.developerSettings";
import {NatButton} from "../../_shared/generics/button";
import {StyleOption} from "../../_shared/generics/_shared";
import vi from "simple-react-lightbox";

const {Content} = Layout;

message.config({
  maxCount: 1,
});

const WaitingContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 360px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

let cachedCandyData = undefined;
let candyDataFetch = null;

const canReuseParcelSearch = (configurationMap, projectSummary) => {
  if (
    !configurationMap ||
    !projectSummary ||
    configurationMap?.parcelSearchData?.projectAddress == null
  ) {
    return false;
  }
  const projectAddress = Project.getAddress(projectSummary);
  const savedProjectAddress =
    configurationMap?.parcelSearchData?.projectAddress;
  return (
    Address.getFullAddress(savedProjectAddress) ===
    Address.getFullAddress(projectAddress)
  );
};

const ModalMapContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  //z-index: 100000000000000;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
`;

const ModalMap = styled.div`
  width: 90vw;
  height: 90vh;
  top: 5vh;
  left: 5vw;
  position: fixed;
`;

export const MappingLayout = React.memo(({readOnly, minimal}) => {
  const [expandMap, setExpandMap] = useState(false);
  const [candyData, setCandyData] = useState(cachedCandyData);
  const [placeName, setPlaceName] = useState(null);
  const [aduPlacement, setAduPlacement] = useState(null);
  const [propertyGeometry, setPropertyGeometry] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [viewPortCenter, setViewPortCenter] = useState(null);
  const [neighborData, setNeighborData] = useState(null);
  const [parcelSearchData, setParcelSearchData] = useState(null);
  const [view, setView] = useState(VIEWS.SEARCH_VIEW);
  const {
    configurationMap,
    configuration,
    projectSummary,
    currentConfigurationId,
  } = useCurrentProject();
  const {fetchGeoLocationToMap} = useConfigurationLocation();
  const {} = useProductCatalog(); // Need to initiate the product catalog for the next page
  const history = useHistory();
  const mapRef = useRef(null);
  const expandedMapRef = useRef(null);

  const API_URL =
    !isDev || true
      ? "https://mapping-tool-server.herokuapp.com"
      : process.env.REACT_APP_API_URL;

  const {get, post, response, loading, error} = useFetch(API_URL, []);

  useEffect(() => {
    if (candyDataFetch) {
      clearTimeout(candyDataFetch);
    }

    if (
      canReuseParcelSearch(configurationMap, projectSummary) ||
      cachedCandyData ||
      readOnly
    ) {
      setCandyData({});
      return;
    }

    async function initializeSpatialStreamAPI() {
      const resp = await get("/getSIK");

      if (error || !response.ok || !resp) {
        console.log("useFetch error: ", error);
        // message.error(
        // 	"Oops! We're experiencing some techincal difficulties - try again in a bit.",
        // 	8
        // );
        return;
      }

      if (resp) {
        const {Response} = resp;
        if (response.ok && Response.status === "success") {
          const candyData = Response.Results.Data.Row;
          cachedCandyData = candyData;
          setCandyData(candyData);
        }
      }
    }

    candyDataFetch = setTimeout(() => {
      devLogger("load API");
      initializeSpatialStreamAPI();
    }, 2000);
  }, [configurationMap, projectSummary]);

  useEffect(() => {
    if (parcelSearchData) {
      return;
    }

    if (canReuseParcelSearch(configurationMap, projectSummary)) {
      devLogger("reuse parcel");
      const {parcelSearchData} = configurationMap;
      const searchData = convertSearchPayloadFromDBFormat(parcelSearchData);
      if (searchData != null) {
        const {
          parcelData,
          buildingData,
          addressId,
          center,
          place_name,
          street_name,
          neighborData,
        } = searchData;

        setPropertyGeometry({parcelData, buildingData, addressId});
        setViewPortCenter(center);
        setPlaceName(place_name);
        setStreetName(street_name);
        setNeighborData(neighborData);
        setParcelSearchData(searchData);
      }
    } else if (projectSummary && candyData?.Candy) {
      const address = Project.getAddress(projectSummary);
      const coordinates = Address.getCoordinates(address);
      if (coordinates) {
        const domain = candyData.Domains;
        const candy = candyData.Candy;
        const projectAddress = Project.getAddress(projectSummary);

        const streetAddress = Address.getStreetNumberAndStreet(projectAddress);
        const city = Address.getCity(projectAddress);
        const state = Address.getState(projectAddress);
        const zip = Address.getZip(projectAddress);

        setViewPortCenter(coordinates);
        setPlaceName(Address.getFullAddress(projectAddress));
        setStreetName(Address.getFullAddress(projectAddress));
        const centerText = coordinates.join(" ");
        devLogger("fetch parcel");

        fetchParcelData({
          candy,
          centerText,
          domain,
          streetAddress,
          city,
          state,
          zip,
          fullStreetName: streetAddress,
          center: coordinates,
          projectAddress,
        });
      } else {
        devLogger("fetch coordinates");
        fetchGeoLocationToMap();
      }
    }
  }, [configurationMap, projectSummary, candyData, parcelSearchData]);

  useEffect(() => {
    if (
      !loading &&
      propertyGeometry &&
      propertyGeometry.parcelData &&
      streetName &&
      neighborData != null &&
      parcelSearchData
    ) {
      setView("MAP_VIEW");
    }
  }, [loading, propertyGeometry, streetName, neighborData, parcelSearchData]);

  const fetchNeighboringParcels = async (
    addressId,
    candy,
    domain,
    newParcelSearchData
  ) => {
    const res = await post("/neighboring-parcels", {
      addressId,
      candy,
      domain,
    });
    if (
      res.Response &&
      res.Response.status === "success" &&
      res.Response.Results &&
      res.Response.Results.Data &&
      res.Response.Results.Data &&
      res.Response.Results.Data.Row &&
      res.Response.Results.Data.Row.Link &&
      res.Response.Results.Data.Row.Link.Row &&
      res.Response.Results.Data.Row.Link.Row.length
    ) {
      let wkt = new Wkt.Wkt();
      let neighboringParcelArr =
        await res.Response.Results.Data.Row.Link.Row.map(async (parcel) => {
          wkt.read(parcel.GEOMETRY);
          parcel = await wkt.toJson();
          return parcel;
        });
      Promise.all(neighboringParcelArr).then((values) => {
        setNeighborData(values);

        const fullParcelSearchData = Object.assign(
          {neighborData: convertArrayOfBuildingCoordinatesForDB(values)},
          newParcelSearchData
        );

        Utilities.setAndMergeDocumentInDB(
          Utilities.collection(CONFIGURATION_MAPS_DB_KEY).doc(
            currentConfigurationId
          ),
          {parcelSearchData: fullParcelSearchData}
        );
        setParcelSearchData(fullParcelSearchData);
      });
    }
  };

  const fetchParcelData = async ({
    candy,
    center,
    domain,
    streetAddress,
    city,
    state,
    zip,
    fullStreetName,
    projectAddress,
  }) => {
    const res = await post("/parcel", {
      candy,
      center,
      domain,
      streetAddress,
      city,
      state,
      zip,
    });

    try {
      if (
        response.ok &&
        res.Response &&
        res.Response.status === "success" &&
        res.Response.Results &&
        res.Response.Results.Data &&
        res.Response.Results.Data.Row.Link &&
        res.Response.Results.Data.Row.Link.Row &&
        res.Response.Results.Data.Row.Link.Row.GEOMETRY
      ) {
        let wkt = new Wkt.Wkt();
        let parcelData = res.Response.Results.Data.Row.Link.Row.GEOMETRY;
        const buildingDataRoot =
          res.Response.Results.Data.Row.Link.Row.Link.Row;
        if (buildingDataRoot == null) {
          console.log("missing building data");
          console.log(res.Response.Results.Data.Row.Link.Row);
          return;
        }
        let buildingData;
        if (Array.isArray(buildingDataRoot)) {
          buildingData = [];
          for (let i = 0; i < buildingDataRoot.length; i++) {
            const rowData = buildingDataRoot[i];
            wkt.read(rowData.GEOMETRY);
            const json = await wkt.toJson();
            buildingData.push(json);
          }
        } else {
          wkt.read(buildingDataRoot.GEOMETRY);
          buildingData = [await wkt.toJson()];
        }

        let addressId = res.Response.Results.Data.Row.LOCID;
        wkt.read(parcelData);
        parcelData = await wkt.toJson();

        const dataToSave = {
          parcelData,
          buildingData,
          addressId,
          center,
          place_name: fullStreetName,
          street_name: fullStreetName,
          city,
          state,
          zip,
          address: streetAddress,
          projectAddress,
        };

        const dbObject = convertSearchPayloadForDBFormat(dataToSave);
        setParcelSearchData(dbObject);
        setPropertyGeometry({parcelData, buildingData, addressId});
        // This is used to query neighboring parcels
        fetchNeighboringParcels(addressId, candy, domain, dbObject);
      } else if (res.Response && res.Response.Error) {
        console.log(res.Response.Error);
        // message.error(
        // 	"Sorry, we couldn't find this parcel data! Please try a different address.",
        // 	10
        // );
      }
    } catch (e) {
      // message.error(
      // 	"Sorry, we couldn't find this parcel data! Please try a different address.",
      // 	10
      // );
      console.error("error fetching parcel data", e);
    } finally {
    }
  };

  const onGeocodeSubmit = async (data) => {
    const {result} = data;
    if (!result || !result.center) return;

    const domain = candyData.Domains;
    const candy = candyData.Candy;

    let {center, place_name, text} = result;
    let placeNameArr = place_name.split(",");
    let streetAddress = placeNameArr[0].toLowerCase();
    let city = placeNameArr[1].trim().toLowerCase();
    let state = placeNameArr[2].trim().split(" ")[0];
    let zip = placeNameArr[2].trim().split(" ")[1];

    setViewPortCenter(center);
    setPlaceName(place_name);
    setStreetName(text);
    center = center.join(" ");

    fetchParcelData({
      candy,
      center,
      domain,
      streetAddress,
      city,
      state,
      zip,
      fullStreetName: place_name,
    });
  };

  if (readOnly && view !== VIEWS.MAP_VIEW) {
    if (configurationMap == null || configurationMap.parcelSearchData == null) {
      return (
        <WaitingContent>
          <img
            src={design_graphic}
            alt={"Site Plan Image Missing"}
            width={"240px"}
          />
          <br />
          <div className={"large-text bold"} style={{marginBottom: "10px"}}>
            Place Your ADU
          </div>
          <div style={{marginBottom: "24px"}}>
            Designate a location for your ADU.
          </div>
          <button
            className={"button-primary bold"}
            style={{width: "50%"}}
            onClick={() => {
              setURLPathAndQuery(history, "mapping");
            }}
          >
            Place My ADU
          </button>
        </WaitingContent>
      );
    }

    return <LoadingSpinner isFullScreen={true} />;
  }

  if (view !== VIEWS.MAP_VIEW && minimal) {
    return null;
  }

  if (!candyData || loading) {
    return <LoadingSpinner isFullScreen={true} />;
  }

  const getContent = (fullScreen, expanded) => {
    if (view === VIEWS.MAP_VIEW) {
      return (
        <Map
          propertyGeometry={propertyGeometry}
          viewPortCenter={viewPortCenter}
          isMobile={isMobile}
          neighborData={neighborData}
          placeName={placeName}
          streetName={streetName}
          fetchedConfigurationMap={configurationMap}
          onGeocodeSubmit={onGeocodeSubmit}
          parcelSearchData={parcelSearchData}
          fetchedConfiguration={configuration}
          readOnly={readOnly}
          minimal={minimal}
          fullScreen={fullScreen}
          updatePlacement={(aduLatLngs) => {
            setAduPlacement(aduLatLngs);
            if (expandMap && expanded) {
              mapRef?.current?.updateUnitPlacement?.(aduLatLngs);
            }
          }}
          initialPlacement={aduPlacement}
          ref={expanded ? expandedMapRef : mapRef}
          aduPlacement={fullScreen && expandMap}
        />
      );
    } else {
      return <Search onGeocodeSubmit={onGeocodeSubmit} loading={loading} />;
    }
  };

  const mapStyle = {
    height: "100%",
    width: "100%",
  };

  if (minimal) {
    mapStyle.borderRadius = "20px";
    mapStyle.overflow = "hidden";
  }
  const height = minimal ? "calc(100% - 60px)" : "100%";

  return (
    <>
      <Layout style={{height: height, width: "100%"}}>
        <Content style={mapStyle}>{getContent(!minimal, false)}</Content>
      </Layout>
      {expandMap && (
        <ModalMapContainer>
          <div
            className={"fullDiv"}
            onClick={() => {
              setExpandMap(false);
            }}
          />
          <ModalMap>
            <Layout style={{height: "100%", width: "100%"}}>
              <Content style={mapStyle}>{getContent(true, true)}</Content>
            </Layout>
          </ModalMap>
        </ModalMapContainer>
      )}

      {minimal && (
        <div style={{textAlign: "center", marginTop: "10px"}}>
          <NatButton
            label={"Verify ADU Feasibility"}
            type={"button"}
            trackingId={"expand-mapping"}
            option={StyleOption.SECONDARY}
            clickEvent={() => {
              setExpandMap(true);
            }}
          />
        </div>
      )}
    </>
  );
});
