import styled from "styled-components";
import {DROPDOWN_PANEL_Z_INDEX} from "../../../styles";

export const NatomasBackdrop = styled.div<{show: boolean; showColor: boolean}>`
  display: ${(props) => (props.show ? "" : "none")};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: default;
  background-color: ${(props) => props.showColor && "rgba(0,0,0,0.15)"};
  z-index: ${DROPDOWN_PANEL_Z_INDEX};
`;
