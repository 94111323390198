import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import {StyleOption} from "./_shared";
import {NatButton} from "./button";

const FooterButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-flow: wrap-reverse;
`;

export const GenericModal = (props: {
  show: any;
  handleClose: any;
  header: any;
  content: any;
  footer?: any;
  footerButtons?: {
    label: string;
    trackingId: string;
    option?: StyleOption;
    action?: any;
    disabled?: boolean;
  }[];
}) => {
  //grabs the step that controls which tab to open on the modal pop-up
  const {show, handleClose, header, content, footer, footerButtons} = props;

  let footerContent = footer;
  if (footerButtons) {
    footerContent = (
      <FooterButtonsContainer>
        {footerButtons.map((footerButton: any, index: number) => {
          const option = footerButton.option ?? StyleOption.SECONDARY;
          const action = footerButton.action ?? handleClose;
          return (
            <NatButton
              style={{
                margin: "5px",
                width: "45%",
                minWidth: "150px",
                flexGrow: 1,
                marginTop: "10px",
              }}
              label={footerButton.label}
              type={"button"}
              trackingId={footerButton.trackingId}
              option={option}
              clickEvent={action}
              key={"modal_button_" + index}
              disabled={footerButton.disabled ?? false}
            />
          );
        })}
      </FooterButtonsContainer>
    );
  }

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton style={{fontFamily: "Maison-Bold"}}>
        {header}
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>{footerContent}</Modal.Footer>
    </Modal>
  );
};
