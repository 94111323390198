import React, {useState} from "react";
import {useFormik} from "formik";
import {NatButton} from "../../../../_shared/generics/button";
import {
  IconPosition,
  StyleOption,
  TextSize,
} from "../../../../_shared/generics/_shared";
import Modal from "react-bootstrap/Modal";
import {NatToggle} from "../../../../_shared/generics/toggle/NatToggle";
import {
  FaSwimmingPool,
  MdHouseSiding,
  GiHomeGarage,
  BsTreeFill,
} from "react-icons/all";
import styled from "styled-components";

const buttonWidth = "70%";
const buttonPadding = "10px";

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: ${buttonPadding};
`;
const ToggleColumn = styled.div`
  display: flex;
  row-gap: ${buttonPadding};
  flex-direction: column;
  width: 50%;
`;
const ButtonContainer = styled.div`
  text-align: center;
`;

const CraneQuestion = (props: {formik: any; setCurrentStep: any}) => {
  const {formik, setCurrentStep} = props;
  return (
    <div>
      <p>
        Is there a 15 feet (or more) wide pathway between the road and your
        desired ADU location? Don’t worry, we can use a crane to get your home
        into your backyard if your pathway is less than 15 feet wide!
      </p>
      <br />
      <ButtonContainer>
        <NatButton
          style={{width: buttonWidth, marginBottom: buttonPadding}}
          label={"Yes"}
          type={"button"}
          trackingId={"sitework-crane-no"}
          size={TextSize.LARGE}
          option={StyleOption.LIGHT_TOGGLE}
          clickEvent={() => {
            formik.setFieldValue("crane", "no");
            setCurrentStep((currentStep: number) => currentStep + 1);
          }}
        />
        <NatButton
          style={{width: buttonWidth, marginBottom: buttonPadding}}
          label={"No"}
          type={"button"}
          trackingId={"sitework-crane-yes"}
          size={TextSize.LARGE}
          option={StyleOption.LIGHT_TOGGLE}
          clickEvent={() => {
            formik.setFieldValue("crane", "yes");
            setCurrentStep((currentStep: number) => currentStep + 1);
          }}
        />
      </ButtonContainer>
    </div>
  );
};

const Sloped = (props: {formik: any; setCurrentStep: any}) => {
  const {formik, setCurrentStep} = props;
  return (
    <div>
      <p>
        If your lot is sloped, we need to budget some additional cost for the
        foundation. Don’t worry if you’re not sure, we’ll evaluate the exact
        slope during a detailed proposal.
      </p>
      <br />
      <ButtonContainer>
        <NatButton
          style={{width: buttonWidth, marginBottom: buttonPadding}}
          label={"Flat"}
          type={"button"}
          trackingId={"sitework-sloped-flat"}
          size={TextSize.LARGE}
          option={StyleOption.LIGHT_TOGGLE}
          clickEvent={() => {
            formik.setFieldValue("slope", "Flat");
            setCurrentStep((currentStep: number) => currentStep + 1);
          }}
        />
        <NatButton
          style={{width: buttonWidth, marginBottom: buttonPadding}}
          label={"Sloped"}
          type={"button"}
          trackingId={"sitework-sloped-mild"}
          size={TextSize.LARGE}
          option={StyleOption.LIGHT_TOGGLE}
          clickEvent={() => {
            formik.setFieldValue("slope", "Mild");
            setCurrentStep((currentStep: number) => currentStep + 1);
          }}
        />
        <NatButton
          style={{width: buttonWidth, marginBottom: buttonPadding}}
          label={"Not Sure"}
          type={"button"}
          trackingId={"sitework-sloped-not-sure"}
          size={TextSize.LARGE}
          option={StyleOption.LIGHT_TOGGLE}
          clickEvent={() => {
            formik.setFieldValue("slope", "Flat");
            setCurrentStep((currentStep: number) => currentStep + 1);
          }}
        />
      </ButtonContainer>
    </div>
  );
};

const Demolition = (props: {formik: any; setCurrentStep: any}) => {
  const {formik, setCurrentStep} = props;
  const [trees, setTrees] = useState(false);
  const [shed, setShed] = useState(false);
  const [garage, setGarage] = useState(false);
  const [pool, setPool] = useState(false);
  return (
    <div>
      <p>
        Do you want to build your ADU where something already exists? Let us
        know so we can estimate the cost to remove and prepare the land for your
        new ADU.
      </p>
      <br />
      <ButtonContainer>
        <ToggleContainer>
          <ToggleColumn>
            <NatToggle
              label={"Trees"}
              trackingId={"sitework-trees"}
              enabled={trees}
              setEnabled={setTrees}
              option={StyleOption.LIGHT_TOGGLE}
              icon={{
                icon: <BsTreeFill size={"150px"} />,
                iconPosition: IconPosition.TOP,
              }}
            />
            <NatToggle
              label={"Shed"}
              trackingId={"sitework-shed"}
              enabled={shed}
              setEnabled={setShed}
              option={StyleOption.LIGHT_TOGGLE}
              icon={{
                icon: <MdHouseSiding size={"150px"} />,
                iconPosition: IconPosition.TOP,
              }}
            />
          </ToggleColumn>
          <ToggleColumn>
            <NatToggle
              label={"Garage"}
              trackingId={"sitework-garage"}
              enabled={garage}
              setEnabled={setGarage}
              option={StyleOption.LIGHT_TOGGLE}
              icon={{
                icon: <GiHomeGarage size={"150px"} />,
                iconPosition: IconPosition.TOP,
              }}
            />
            <NatToggle
              label={"Pool"}
              trackingId={"sitework-pool"}
              enabled={pool}
              setEnabled={setPool}
              option={StyleOption.LIGHT_TOGGLE}
              icon={{
                icon: <FaSwimmingPool size={"150px"} />,
                iconPosition: IconPosition.TOP,
              }}
            />
          </ToggleColumn>
        </ToggleContainer>
        <NatButton
          style={{width: buttonWidth, marginTop: "20px"}}
          label={"Generate Quote"}
          type={"submit"}
          trackingId={"generate-site-work-submit"}
          option={StyleOption.ColorWillDarken}
          spinnerEnabled={true}
          size={TextSize.LARGE}
          clickEvent={() => {
            let structuresArray = [];
            if (trees) structuresArray.push("Trees");
            if (shed) structuresArray.push("Shed");
            if (garage) structuresArray.push("Garage");
            if (pool) structuresArray.push("Pool");
            formik.setFieldValue("structures", structuresArray);
            formik.submitForm();
          }}
          disabled={false}
        />
      </ButtonContainer>
    </div>
  );
};

export const GenerateSiteWorkModal = (props: {
  show: boolean;
  handleClose: () => void;
  onSubmit: (formData: any) => any;
}) => {
  const {handleClose, onSubmit, show} = props;
  const [currentStep, setCurrentStep] = useState(0);

  const formik = useFormik({
    initialValues: {
      crane: "",
      slope: "",
      structures: [],
    },
    onSubmit: (values: any) => {
      onSubmit(values).then(() => {
        handleClose();
        setTimeout(() => {
          formik.resetForm();
          setCurrentStep(0);
        }, 1000);
      });
      return;
    },
  });

  const steps = [
    <CraneQuestion formik={formik} setCurrentStep={setCurrentStep} />,
    <Sloped formik={formik} setCurrentStep={setCurrentStep} />,
    <Demolition formik={formik} setCurrentStep={setCurrentStep} />,
  ];

  const stepArray = [
    {question: "Is there a wide pathway for delivery?"},
    {question: "How sloped is your backyard?"},
    {question: "What’s In Your Backyard?"},
  ];

  const stepInfo = stepArray[currentStep];

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        formik.resetForm();
        setCurrentStep(0);
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton style={{fontFamily: "Maison-Bold"}}>
        {stepInfo.question}
      </Modal.Header>
      <Modal.Body>{steps[currentStep]}</Modal.Body>
    </Modal>
  );
};
