import {FieldType} from "../../_shared/generics/table/TableTypes";
import {SITE_WORK_LINE_ITEM_IDS} from "../../_shared/constants/sitework";

export const ProductStructure = {
  title: {
    name: "Title",
    type: "input",
    index: 0,
  },
  tagline: {
    name: "Tagline",
    index: 1,
    type: "input",
  },
  displayDescription: {
    name: "General Description",
    index: 1,
    type: "textarea",
    subType: "full-width",
  },
  interiorDescription: {
    name: "Interior Description",
    index: 1,
    type: "textarea",
  },
  exteriorDescription: {
    name: "Exterior Description",
    index: 1,
    type: "textarea",
  },
  bathrooms: {
    path: "productDetails.bathrooms",
    name: "Bathroom Quantity",
    defaultValue: 0,
    type: "number",
    index: 6,
  },
  bedrooms: {
    path: "productDetails.bedrooms",
    name: "Bedroom Quantity",
    defaultValue: 0,
    type: "number",
    index: 5,
  },
  constructionPriceMicros: {
    path: "productDetails.constructionPriceMicros",
    name: "Construction Price",
    type: "number",
    subType: "price",
    index: 4,
  },
  unitPriceMicros: {
    path: "productDetails.unitPriceMicros",
    name: "Unit Price",
    type: "number",
    subType: "price",
    index: 3,
  },
  priceMicros: {
    name: "Price",
    type: "number",
    subType: "price",
    index: 2,
  },
  squareFeet: {
    path: "productDetails.squareFeet",
    name: "SQFT",
    defaultValue: 0,
    type: "number",
    index: 9,
  },
  dimensions: {
    path: "productDetails.dimensions",
    name: "Dimensions",
    defaultValue: {
      frontLength: {
        feet: 10,
        inches: 0,
      },
      sideLength: {
        feet: 10,
        inches: 0,
      },
    },
    type: "dimensions",
    index: 10,
  },
  configuratorPages: {
    name: "Pages / Categories",
    defaultValue: [],
    index: 15,
  },
  optionValues: {
    type: "optionKeyValues",
    defaultValue: {},
    index: 20,
  },
  imageId: {
    name: "Default Image",
    type: "image",
    index: 12,
  },
  images: {
    name: "Images",
    type: "image-manager",
    defaultValue: [],
    index: 14,
  },
  default_site_work_costs: {
    name: "Site-work Costs",
    type: "table",
    tableStructure: {
      rowCount: Object.keys(SITE_WORK_LINE_ITEM_IDS).length,
      columns: [
        {
          dataField: "id",
          name: "Site Work Item ID",
          type: FieldType.STRING,
        },
        {
          dataField: "name",
          name: "Name",
          type: FieldType.STRING,
        },
        {
          dataField: "basePriceMicros",
          name: "Price",
          type: FieldType.PRICE,
        },
        {
          dataField: "note",
          name: "Notes / Description",
          type: FieldType.STRING,
        },
      ],
      rigidValues: Object.keys(SITE_WORK_LINE_ITEM_IDS).map((key) => [
        key,
        SITE_WORK_LINE_ITEM_IDS[key].title,
      ]),
    },
    index: 11,
  },
};
