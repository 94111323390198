import React from "react";
import {ListHeader} from "./ListHeader";
import {ListItems} from "./ListItems";
import {Button, Row} from "antd";
import {ArrowDown} from "../../../../../assets/icons";
import {ProjectDesign} from "./ProjectDesign";
import {ProjectDesignFooter} from "./ProjectDesignFooter";
import {ADUListColumn, ADUListContainer} from "./styled";
import {GeoCoder} from "../Search/GeoCoder";
import {useWindowSize} from "../../../../_shared/hooks/useWindowSize";
import {BREAKPOINT_DESKTOP_SMALL} from "../../theme/constants";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";

export const ADUList = ({
	currentDesign,
	placeAdu,
	handleExpandList,
	isListExpanded,
	isMobile,
	placeName,
	onAduCardClick,
	onGeocodeSubmit,
	handleBackBtnClick,
	map,
	handleProceed,
}) => {
	const {currentProducts} = useProductCatalog();
	const products = currentProducts;

	const isDesktop = useWindowSize().width > BREAKPOINT_DESKTOP_SMALL;
	return (
		<ADUListContainer isListExpanded={isListExpanded}>
			<ADUListColumn isListExpanded={isListExpanded}>
				{!isDesktop && (
					<Row align="middle" justify="end">
						<Button
							className="btn-transparent btn--expand-icon"
							onClick={handleExpandList}
						>
							<ArrowDown />
						</Button>
					</Row>
				)}
				<GeoCoder
					isOnMap={!isDesktop}
					onGeocodeSubmit={onGeocodeSubmit}
					placeName={placeName}
				/>
				{currentDesign ? (
					<>
						<ProjectDesign
							design={currentDesign}
							isListExpanded={isListExpanded}
						/>
						<ProjectDesignFooter
							handleBackBtnClick={handleBackBtnClick}
							product={currentDesign}
							map={map}
							handleProceed={handleProceed}
						/>
					</>
				) : (
					<>
						<ListHeader isListExpanded={isListExpanded} />

						<ListItems
							products={Object.values(products).sort(
								(a, b) => a.priceMicros - b.priceMicros
							)}
							isMobile={isMobile}
							placeAdu={placeAdu}
							onAduCardClick={onAduCardClick}
						/>
					</>
				)}
			</ADUListColumn>
		</ADUListContainer>
	);
};
