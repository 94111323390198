import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {editModifierGroup} from "../../../_shared/slices/CatalogSlice";
import {EditProductGroupModifierGroup} from "./OverviewTab/EditProductGroupModifierGroup";
import {ColumnContainer, MainColumn} from "../styles/AdminStyles";
import {isBlankString} from "@natomas/core";
import {TabColumn} from "../components/TabColumn";
import {IStore} from "../../../_shared/slices/types/Store";

export const ModifierGroupTab = () => {
  const {currentGroupModifierGroups} = useProductCatalog();
  const currentModifierGroup = useSelector(
    (state: IStore) => state.catalog.editingModifierGroup
  );
  const dispatch = useDispatch();
  const title = "Modifier Groups";

  const newModifierGroup = () => {
    dispatch(editModifierGroup({title: "New Modifier Group"}));
  };

  const changeModifierGroup = (modifierGroup: any) => {
    dispatch(editModifierGroup(modifierGroup));
  };

  const checkIfActive = (key: string) => {
    return currentModifierGroup?.id === key;
  };

  const getModifierGroupRows = () => {
    return Object.values(currentGroupModifierGroups)
      .sort((a: any, b: any) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      })
      .map((modifierGroup: any) => {
        const title = !isBlankString(modifierGroup.internal_title)
          ? modifierGroup.internal_title
          : modifierGroup.title;
        return {
          title: title,
          key: modifierGroup.id,
          data: modifierGroup,
          action: changeModifierGroup,
          checkIfActive: checkIfActive,
        };
      });
  };

  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={"11rem"}
        headerAddAction={newModifierGroup}
        rows={getModifierGroupRows()}
      />
      <MainColumn>
        <EditProductGroupModifierGroup />
      </MainColumn>
    </ColumnContainer>
  );
};
