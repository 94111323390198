import {EditRenderingForCategory} from "../../../design-tool/components/EditRenderingForCategory";
import React, {useMemo} from "react";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";

function cartesian(...args: any) {
  let r: any = [],
    max = args.length - 1;
  function helper(arr: any, i: any) {
    for (let j = 0, l = args[i].length; j < l; j++) {
      let a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i === max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

export const RenderingsTab = (props: any) => {
  const {category, renderings, modifierGroupIds} = props;
  const renderingToShow =
    category.renderings != null ? Object.values(category.renderings) : [];
  const {currentGroupModifierGroups} = useProductCatalog();

  const remainingRenderings = useMemo(() => {
    if (modifierGroupIds != null) {
      const listOfModifierGroups = modifierGroupIds.map(
        (modifierGroupId: any) => {
          return currentGroupModifierGroups[modifierGroupId];
        }
      );

      let listOfListsOfModifiers: any = [];
      listOfModifierGroups.forEach((modifierGroup: any) => {
        listOfListsOfModifiers = [
          ...listOfListsOfModifiers,
          modifierGroup.modifiers,
        ];
      });
      const allPermutationsOfModifiersForPage = cartesian(
        ...listOfListsOfModifiers
      );
      const remaining: any = [];
      const renderingToIterateThrough =
        renderings != null ? Object.values(renderings) : [];
      allPermutationsOfModifiersForPage.forEach((modifierList: any) => {
        const match = renderingToIterateThrough.some((rendering: any) => {
          return modifierList.every(
            (modifierId: any) => rendering.requiredModifiers[modifierId]
          );
        });
        if (!match) {
          remaining.push(modifierList);
        }
      });
      if (remaining.length > 10) {
        return remaining.slice(0, 10);
      }
      return remaining;
    }
    return [];
  }, [modifierGroupIds, renderings]);

  return (
    <div
      className={"left-right"}
      style={{maxHeight: "100%", overflow: "auto", alignItems: "baseline"}}
    >
      <div style={{width: "40%"}}>
        <div>Assigned Renderings</div>
        {renderingToShow.map((rendering: any, index: number) => {
          return (
            <EditRenderingForCategory
              key={rendering.imageId + index}
              renderingImageId={rendering.imageId}
              rendering={rendering}
              configurationPage={category}
            />
          );
        })}
      </div>
      <div style={{width: "40%"}}>
        <div>Unassigned Renderings</div>
        {remainingRenderings.map((rendering: any) => {
          return (
            <EditRenderingForCategory
              key={rendering.toString()}
              renderingImageId={null}
              leftOverModifiers={rendering}
              rendering={{}}
              configurationPage={category}
            />
          );
        })}
        <EditRenderingForCategory
          rendering={{}}
          renderingImageId={null}
          configurationPage={category}
        />
      </div>
    </div>
  );
};
