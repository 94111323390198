import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Modifier} from "./Modifier";
import {
  editModifier,
  editModifierGroup,
} from "../../_shared/slices/CatalogSlice";
import {Underline} from "./underline";
import {getFullModifierGroup} from "../logic/ConfigurationPageHelper";
import {
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
} from "../logic/Constants";
import {Checkmark} from "./Checkmark";
import {isMarketingPage} from "../../_shared/navigation";
import {isBlankString} from "@natomas/core";
import {getDescriptionForModifier} from "../logic/ProductUtils";
import {TextInputModal} from "./TextInputModal";
import {useState} from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

export const ShowMoreContainer = styled.div`
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  width: fit-content;
`;

function getSelectedModifiers(fullModifierGroup) {
  if (
    fullModifierGroup != null &&
    fullModifierGroup.selectedModifiers != null &&
    fullModifierGroup.selectedModifiers.length > 0
  ) {
    return fullModifierGroup.selectedModifiers;
  }
  return [];
}

function getSubtitleForSelectedModifier(selectedModifiers, fullModifierGroup) {
  if (selectedModifiers == null || selectedModifiers.length !== 1) {
    return null;
  }
  // TODO: allow for multiple subtitles when multiple selected modifiers?
  const selectedModifier = selectedModifiers[0];

  let title =
    selectedModifier.configurationButtonTitle != null
      ? selectedModifier.configurationButtonTitle
      : selectedModifier.title;
  if (title == null) {
    return null;
  }

  if (
    fullModifierGroup.properties != null &&
    fullModifierGroup.properties[SHOW_COLOR_SWATCHES_PROPERTY_KEY] === true &&
    fullModifierGroup.properties[SHOW_FULL_TEXT_PROPERTY_KEY] !== true
  ) {
    return <p className="capitilized">{title}</p>;
  }

  return null;
}

function breakDescriptionIntoParts(description) {
  const parts = [];

  const bullets = description.split("•");
  const firstText = (
    <pre
      style={{whiteSpace: "pre-line"}}
      key={"parts_0"}
      className="hidden-desc-text"
    >
      {bullets[0]}
    </pre>
  );

  parts.push(firstText);

  if (bullets.length > 1) {
    const subParts = [];
    for (let index = 1; index < bullets.length; index++) {
      const element = bullets[index];
      subParts.push(
        <div className="tooltip-item" key={"bullet_" + index}>
          <Checkmark></Checkmark>
          <p>{element}</p>
        </div>
      );
    }
    parts.push(
      <div className="tooltip-desc" key={"parts_1"}>
        {subParts}
      </div>
    );
  }

  return parts;
}

function getDescriptionForSelectedModifiers(
  selectedModifiers,
  fullModifierGroup,
  expand,
  setExpand
) {
  let description = null;
  if (
    fullModifierGroup != null &&
    !isBlankString(fullModifierGroup.prettyDescription)
  ) {
    description = fullModifierGroup.prettyDescription;
  }

  if (selectedModifiers != null && selectedModifiers.length > 0) {
    if (selectedModifiers.length === 1) {
      const modifierDescription = getDescriptionForModifier(
        selectedModifiers[0]
      );
      if (description == null) {
        description = modifierDescription;
      } else if (!isBlankString(modifierDescription)) {
        description += "\n\n" + modifierDescription;
      }
    } else {
      selectedModifiers.forEach((modifier) => {
        const modifierDescription = getDescriptionForModifier(modifier);
        if (!isBlankString(modifierDescription)) {
          if (description == null) {
            description = "";
          } else {
            description += "\n\n";
          }

          description += modifier.title + "\n" + modifierDescription;
        }
      });
    }
  }

  if (isBlankString(description)) {
    return null;
  }

  const re = /[.!?] \w/g;
  const numOfSentences = description.split(re);
  const count = numOfSentences.length - 1;

  let expandDiv = null;
  if (description.length > 150) {
    expandDiv = (
      <ShowMoreContainer
        className={"clickable"}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        {expand === true ? "Show Less" : "Show More"}
      </ShowMoreContainer>
    );

    if (expand !== true) {
      const nextSpace = description.substr(149).indexOf(" ");
      description =
        description.substr(0, 149 + (nextSpace >= 0 ? nextSpace : 0)) + "...";
    }
  } else if (count >= 2) {
    expandDiv = (
      <div
        className={"clickable"}
        style={{
          fontSize: 14,
          textAlign: "left",
          fontStyle: "italic",
          width: "fit-content",
        }}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        {expand === true ? "Show Less" : "Show More"}
      </div>
    );

    if (expand !== true) {
      let matches = [...description.matchAll(re)];
      description = description.substr(0, matches[1].index + 1);
    }
  }

  return (
    <div style={{width: "100%"}}>
      {breakDescriptionIntoParts(description)}
      {expandDiv}
    </div>
  );
}

export const ModifierGroup = (props) => {
  const {
    modifierGroupId,
    product,
    isEditing,
    hideUnderline,
    modifierGroup,
    formik,
  } = props;

  const fullModifierGroup = useSelector(
    (state) => getFullModifierGroup(state, product.id, modifierGroupId),
    shallowEqual
  );

  const [expand, setExpand] = useState(false);

  const editingSelections = useSelector(
    (state) => state.configuration.editingSelections
  );

  const dispatch = useDispatch();
  const [showNote, setShowNote] = useState(false);

  const editModifierGroupAction = (modifierGroup) => {
    dispatch(editModifierGroup(modifierGroup));
  };

  const modifierGroupTitle = modifierGroup.title;

  const editModifierAction = (modifier) => {
    dispatch(
      editModifier(Object.assign({modifierGroup: modifierGroup}, modifier))
    );
  };

  const modifierDivs =
    fullModifierGroup.fullModifiers == null
      ? null
      : fullModifierGroup.fullModifiers.map((modifier, index) => {
          return (
            <Modifier
              isEditing={isEditing}
              product={product}
              modifierGroup={modifierGroup}
              modifierId={modifier.id}
              key={modifierGroup.id + modifier.id + index}
              modifier={modifier}
              editingSelections={editingSelections}
            />
          );
        });

  let modifierIdText = isEditing ? modifierGroupId : null;
  const selectedModifiers = getSelectedModifiers(fullModifierGroup);
  const subtitleView = getSubtitleForSelectedModifier(
    selectedModifiers,
    fullModifierGroup
  );
  const descriptionView = getDescriptionForSelectedModifiers(
    selectedModifiers,
    fullModifierGroup,
    expand,
    setExpand
  );

  const doesNoteExist =
    formik != null &&
    formik.values != null &&
    isBlankString(formik.values["note-" + modifierGroup.id]);

  return (
    <div>
      <div className="one-line">
        <ReactTooltip id={"tooltip-" + modifierGroup.id} effect="solid">
          Add Note
        </ReactTooltip>
        <h4 htmlFor="roofStyle" className="option-title">
          {modifierGroupTitle}
        </h4>
        <h4
          htmlFor="roofStyle"
          className="option-title clickable"
          data-tip
          data-for={"tooltip-" + modifierGroup.id}
          onClick={() => {
            setShowNote(true);
          }}
          style={{
            opacity: doesNoteExist ? 0.3 : 1,
            fontSize: 14,
            display:
              !isMarketingPage() && (editingSelections || !doesNoteExist)
                ? "block"
                : "none",
          }}
        >
          {"📝"}
        </h4>
      </div>

      <div className="cabinetColor-buttons button-layout">
        {subtitleView}
        {modifierDivs}
        {descriptionView}
      </div>
      {!isEditing ? null : (
        <div>
          <div
            onClick={() => {
              editModifierGroupAction(modifierGroup);
            }}
          >
            Edit Modifier Group
          </div>

          <p>{modifierIdText}</p>
        </div>
      )}
      <TextInputModal
        modifierGroup={fullModifierGroup}
        show={showNote}
        setShow={setShowNote}
        formik={formik}
      />
      {hideUnderline ? null : <Underline className="option-underline" />}
    </div>
  );
};
