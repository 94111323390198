import React from "react";
import {ImageContainer} from "./styles";

interface Props {
  src: string;
}

export const MappingImage = ({src}: Props) => {
  return (
    <ImageContainer>
      <img src={src} alt={"Mapping picture"} />
    </ImageContainer>
  );
};
