import {Address, Contact, Customer, Person, PhoneNumber} from "@natomas/core";
import {IContactInfo} from "@natomas/core/application/entities/ContactInfo";
import {IAddressDetails} from "@natomas/core/application/entities/AddressDetails";
import {IPersonInfo} from "@natomas/core/application/entities/PersonInfo";
import {ICustomerInfo} from "@natomas/core/application/entities/CustomerInfo";
import {NatomasUserInfo, V0UserInfo} from "@natomas/core/database";
import {IEventInfo} from "@natomas/core/application/entities/EventInfo";
import {
  addressDetailsToNatomasDefaultAddress,
  natomasAddressToAddressDetails,
} from "./address";

export const mergeUserInfoAndUserLeadInfo = (
  userInfo?: NatomasUserInfo,
  userLeadInfo?: NatomasUserInfo
): NatomasUserInfo | null | undefined => {
  if (userLeadInfo == null) {
    return userInfo;
  } else if (userInfo == null) {
    return userLeadInfo;
  }

  // Since we have events from salesforce and natomas, we need to merge events
  // Let's start with SF events because they are the source of truth for now
  // Then any events not captured in salesforce will be filled in by the natomas events
  const eventsMap: {[key: string]: IEventInfo} = {};
  userLeadInfo.events?.forEach((event) => {
    if (!eventsMap[event.event_id]) {
      eventsMap[event.event_id] = event;
    }
  });

  userInfo.events?.forEach((event) => {
    if (!eventsMap[event.event_id]) {
      eventsMap[event.event_id] = event;
    }
  });

  const userLeadInfoCopy: NatomasUserInfo = Object.assign({}, userLeadInfo);
  return Object.assign(userLeadInfoCopy, {
    first_name: userInfo.first_name ?? userLeadInfo.first_name,
    last_name: userInfo.last_name ?? userLeadInfo.last_name,
    project_ids: userInfo.project_ids,
    phone: userInfo.phone ?? userLeadInfo.phone,
    default_address: userInfo.default_address ?? userLeadInfo.default_address,
    events: Object.values(eventsMap),
  });
};

const natomasCustomerToCustomerInfo = (
  natUser: NatomasUserInfo
): ICustomerInfo => {
  let phone = PhoneNumber.dataToPhoneNumber("primary", natUser.phone);

  let address = natomasAddressToAddressDetails(natUser.default_address);

  let contact: IContactInfo = Contact.dataToContactInfo(
    address,
    phone,
    natUser.email
  );

  let person: IPersonInfo = Person.dataToPerson(
    natUser.first_name,
    "",
    natUser.last_name,
    contact,
    ""
  );

  return Customer.dataToCustomer(
    natUser.user_id,
    person,
    natUser.lead_id,
    natUser.project_ids,
    natUser.initial_fee_paid,
    natUser.events
  );
};

const v0UserToCustomerInfo = (
  userInfo: V0UserInfo,
  userId: string
): ICustomerInfo => {
  let phone = PhoneNumber.dataToPhoneNumber("primary", userInfo.phoneNumber);

  let streetNumberAndStreet = userInfo?.address?.split(" ");
  let street_number = streetNumberAndStreet?.shift();
  let street = streetNumberAndStreet?.join(" "); // Concat to single string.
  let address: IAddressDetails = Address.dataToAddress(
    "",
    street_number,
    street,
    userInfo.city,
    userInfo.state,
    userInfo.zip,
    "",
    "US",
    0,
    0
  );

  let contact: IContactInfo = Contact.dataToContactInfo(
    address,
    phone,
    userInfo.email
  );

  let person: IPersonInfo = Person.dataToPerson(
    userInfo.firstName,
    "",
    userInfo.lastName,
    contact,
    ""
  );

  return Customer.dataToCustomer(userId, person, "", [], false);
};

const customerInfoToNatomasCustomer = (customer: ICustomerInfo) => {
  let natomasAddress = addressDetailsToNatomasDefaultAddress(
    Customer.getContactAddress(customer)
  );

  let natUser: NatomasUserInfo = {
    user_id: customer.user_id,
    first_name: Customer.getFirstName(customer),
    last_name: Customer.getLastName(customer),
    lead_id: customer.lead_id,
    // project_ids: customer.project_ids, TODO - this was causing a bug on intake/user creation, do not save projectIds
    phone: Customer.getPrimaryContactPhoneNumber(customer),
    email: Customer.getContactEmail(customer),
    initial_fee_paid: customer.initial_fee_paid,
    events: customer.events,
    default_address: natomasAddress,
    status: customer.status,
  };
  return natUser;
};

export {
  customerInfoToNatomasCustomer,
  natomasCustomerToCustomerInfo,
  v0UserToCustomerInfo,
};
