import React, {useState} from "react";
import styled from "styled-components";

const NavBarTabContainer = styled.div`
  height: 100%;
  overflow-x: auto;
  position: relative;
  display: flex;
  align-items: center;
`;

const Tab = styled.div`
  height: 100%;
  padding: 0 0.65rem;
  line-height: 4rem;
  font-size: 0.85rem;
  text-overflow: ellipsis;
  border-bottom: ${(props) => (props.active ? "3px solid #82c275" : "none")};
  font-family: ${(props) => (props.active ? "Maison-Bold" : "Maison-Book")};
  position: relative;
  cursor: pointer;
`;

const tabComponent = (selectedTab, setTab, tab) => {
  return (
    <Tab
      onClick={() => {
        setTab(tab.id);
      }}
      key={tab.id}
      active={tab.id === selectedTab}
    >
      {tab.title}
    </Tab>
  );
};

export const GenericNavBar = (props) => {
  const {tabs, onChange} = props;
  const [selectedTab, setTab] = useState(tabs[0].id);
  const select = (tabId) => {
    setTab(tabId);
    onChange(tabId);
  };
  return (
    <NavBarTabContainer>
      {tabs.map((tab) => {
        return tabComponent(selectedTab, select, tab);
      })}
    </NavBarTabContainer>
  );
};
