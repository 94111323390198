import {CheckCookie, GetCookie} from "./cookie";

export const getParameter = (parameter: any, disregardCookie: boolean) => {
	//Grab url and create URLSearchParams object instance
	const url = document.location.search.substring(1);
	const params = new URLSearchParams(url);

	//Checks to see if URL has that parameter, if not then return error string
	if (!params.has(parameter)) {
		//If no parameter is found, see if it exists as a cookie
		if (parameter === "cid" || disregardCookie === true) {
			return null;
		}

		const cookie = GetCookie(parameter);
		if (cookie !== null) {
			return cookie;
		}

		return `${parameter} was not found in URL or in cookie.`;
	}

	// Ensures that p is not null before storing.
	let p = params.get(parameter);
	if (p) {
		//See if parameter has been stored inside a cookie, if not it'll create it and if it already exists it'll leave it alone
		CheckCookie(parameter, p, 3);
	}

	//If it does find the parameter, then return it's value
	return params.get(parameter);
};
