export const getCategoriesForProduct = (product, categoryLibrary) => {
	if (product == null) {
		return [];
	}
	return product.configuratorPages
		.map((pageId) => {
			// TODO this is tech debt for transition to new product catalog structure
			let page = categoryLibrary[pageId];
			// if (product.productGroupId === "adu") {
			// 	page = categoryLibrary[product.id + pageId];
			// }

			return page;
		})
		.filter((x) => x != null);
};
