import React, {useState} from "react";
import {Divider, Modal, Tabs} from "antd";
import {ProjectDesignContainer} from "./styled";
import {ImageSlider} from "./ImageSlider";
import {UnitDescription} from "../../../../design-tool/components/UnitDescription";

const {TabPane} = Tabs;
const MODAL_VIEWS = {
	unitPrice: "unit-price",
	baseConstruction: "base-construction",
};

export const ProjectDesign = ({design, isListExpanded}) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [modalView, setModalView] = useState(MODAL_VIEWS.unitPrice);

	const handleOk = () => {
		setModalVisible(false);
	};

	const handleCancel = () => {
		setModalVisible(false);
	};

	const handleModalContent = (view) => {
		setModalView(view);
		setModalVisible(true);
	};

	return (
		<ProjectDesignContainer isListExpanded={isListExpanded}>
			<ImageSlider product={design} />
			<UnitDescription product={design} />
			<Divider />
			<Modal
				title={null}
				footer={null}
				visible={modalVisible}
				onOk={handleOk}
				destroyOnClose
				onCancel={handleCancel}
				className="pdp-modal"
			>
				<Tabs defaultActiveKey={modalView}>
					<TabPane tab="ADU Unit Price" key={MODAL_VIEWS.unitPrice}>
						Cost of all building materials and labor required to build your
						home.
					</TabPane>
					<TabPane
						tab="Base Construction Package"
						key={MODAL_VIEWS.baseConstruction}
					>
						Covers everything from permitting to installation for the average
						home on a flat property. Once we receive your deposit, we will
						conduct a site visit to provide a quote for any additional
						site-specific construction costs.
					</TabPane>
				</Tabs>
			</Modal>
		</ProjectDesignContainer>
	);
};
