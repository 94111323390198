import React, {useState} from "react";
//Button
import {StyleOption, TextSize} from "../_shared/";
import {NatButton} from "../button";
//Styles
import {
  CollapsableContainer,
  CollapsableContainerHeader,
  DeveloperToolsSection,
  SectionRow,
  SectionTitle,
  SectionDescription,
} from "./styles";
//Shared
import {SectionToggle} from "./_shared/SectionToggle";
//Hooks
import {useCurrentProject} from "../../hooks/useCurrentProject";
//Database
import {Utilities} from "../../../../database/firebase";
import {PROJECT_COLLECTION_KEY} from "../../../../database/firebase/project/constants";
import {updateUserProductSelection} from "../../../../database/firebase/api/user";
import {
  resetUserAction,
  setNatomasUserEvents,
} from "../../../../database/firebase/user";
import {useCurrentCustomer} from "../../hooks/useCurrentCustomer";
import {resetConfigurationSnapshot} from "../../../../database/firebase/configuration/configurationService";

export const PreDepositSection = () => {
  //Local
  const [show, setShow] = useState<boolean>(false);
  //Hooks
  const {currentConfigurationId, projectSummary, configuration} =
    useCurrentProject();
  const {user_id} = useCurrentCustomer();

  //Functions
  const resetContactVerification = () => {
    const projectDoc = Utilities.collection(PROJECT_COLLECTION_KEY).doc(
      currentConfigurationId
    );
    if (projectDoc) projectDoc.delete();
  };

  const resetUnitSelection = () => {
    updateUserProductSelection(currentConfigurationId, null).then(() => {});
  };

  const resetAll = () => {
    resetUnitSelection();
    resetContactVerification();
    setNatomasUserEvents(user_id, [], null);
    resetConfigurationSnapshot(currentConfigurationId);
    return resetUserAction(user_id);
  };

  return (
    <DeveloperToolsSection>
      <CollapsableContainerHeader>
        <SectionTitle>Pre-Deposit Portal</SectionTitle>
        <SectionToggle show={show} setShow={setShow} />
      </CollapsableContainerHeader>
      <CollapsableContainer show={show}>
        <SectionDescription>
          The Pre-Deposit Portal has <b>four</b> steps that the user must take
          sequentially. You can reset the each step <b>individually</b>, or{" "}
          <b>all at once</b>.
        </SectionDescription>
        <SectionRow>
          <NatButton
            label={"Reset Verification"}
            type={"button"}
            trackingId={"dev-tools-reset-contact-verification"}
            size={TextSize.SMALL}
            option={StyleOption.Developer}
            clickEvent={resetContactVerification}
            disabled={!projectSummary}
          />
        </SectionRow>
        <SectionRow>
          <NatButton
            label={"Reset Unit Selection"}
            type={"button"}
            trackingId={"dev-tools-reset-unit-selection"}
            size={TextSize.SMALL}
            option={StyleOption.Developer}
            clickEvent={resetUnitSelection}
            disabled={!configuration?.product}
          />
        </SectionRow>
        <SectionRow>
          <NatButton
            label={"Reset All Steps"}
            type={"button"}
            trackingId={"dev-tools-reset-all-pre-deposit-steps"}
            size={TextSize.SMALL}
            option={StyleOption.Developer}
            clickEvent={resetAll}
            disabled={!projectSummary && !configuration?.product}
          />
        </SectionRow>
      </CollapsableContainer>
    </DeveloperToolsSection>
  );
};
