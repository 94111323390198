import React from "react";
import {FilterList, FormattingContainer} from "../../../views/MainView/styles";
import {BedroomFilter} from "./BedroomFilter";
import {SquareFootageFilter} from "./SquareFootageFilter";
import {PriceRangeFilter} from "./PriceRangeFilter";
import {FilterToggles} from "./FilterToggles";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";

export const FilterContainer = (props: {offset: string}) => {
  const {listedProductGroups, activeProductGroups, productGroupId} =
    useProductCatalog();
  const selectProductGroups = () => {
    return productGroupId
      ? activeProductGroups ?? []
      : listedProductGroups ?? [];
  };
  return (
    <>
      <PageContainer>
        <FormattingContainer>
          <FilterList>
            <BedroomFilter groups={selectProductGroups()} />
            <SquareFootageFilter groups={selectProductGroups()} />
            <PriceRangeFilter groups={selectProductGroups()} />
          </FilterList>
        </FormattingContainer>
      </PageContainer>
      <FilterToggles offset={props.offset} />
    </>
  );
};
