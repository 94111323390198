import styled from "styled-components";

export const CornerContainer = styled.div`
   {
    position: absolute;
    z-index: 99999;
    left: 1rem;
    bottom: 1rem;
    font-size: 0.825rem;
  }
`;
