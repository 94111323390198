/* This is for the x button */
export const intakeFormBackAction = (step) => {
  const fallbackUrl = "https://villahomes.com";
  const prevPage = window.location.href;

  //if on the thank you page, go to the home page because back would be the form again
  if (step === "step-confirmation") {
    window.location.href = fallbackUrl;
    return;
  }

  //if not on the thank you page and a previous page exists, go to that
  window.history.back();

  //if not on the thank you page and no previous page exists, then go to home page
  setTimeout(function () {
    if (window.location.href === prevPage) {
      window.location.href = fallbackUrl;
    }
  }, 350);
};
