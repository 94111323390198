import React from "react";
// @ts-ignore
// There is no types package for react-awesome-lightbox
import Lightbox from "react-awesome-lightbox";
import {LightboxContainer} from "./styles";

interface ILightboxPhoto {
  url: string;
  title?: string;
  imageId: string;
}

interface LightboxProps {
  show: boolean;
  close: any;
  images: ILightboxPhoto[];
  selectedPhotoID: string | null;
}

export const PhotoCarousel = (props: LightboxProps) => {
  const {show, close, images, selectedPhotoID} = props;
  let startIndex = 0;
  images.forEach((image, index) => {
    if (image.imageId === selectedPhotoID) {
      startIndex = index;
    }
  });
  return show ? (
    <LightboxContainer>
      {images?.length > 1 && (
        <Lightbox
          startIndex={startIndex}
          images={images}
          onClose={() => close()}
          allowRotate={false}
        />
      )}
      {images?.length === 1 && (
        <Lightbox
          image={images[0].url}
          title={images[0].title}
          onClose={() => close()}
          allowRotate={false}
        />
      )}
    </LightboxContainer>
  ) : (
    <></>
  );
};
