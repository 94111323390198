import {useSelector} from "react-redux";
import styled from "styled-components";
import {Customer} from "@natomas/core";

const AvatarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffee7e;
  color: #2a3639;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  margin-right: 20px;
`;

const AvatarInitials = styled.div`
  text-align: center;
  position: relative;
  top: 55%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const Avatar = (props) => {
  const {customer} = props;
  const currentUser = useSelector((state) => state.global.user);
  let initials = "";
  let firstName = "";
  let lastName = "";

  if (customer) {
    firstName = Customer.getFirstName(customer);
    lastName = Customer.getLastName(customer);
  } else if (currentUser) {
    firstName = currentUser.firstName;
    lastName = currentUser.lastName;
  }

  if (firstName) {
    initials += firstName.substring(0, 1).toUpperCase();
  }
  if (lastName) {
    initials += lastName.substring(0, 1).toUpperCase();
  }

  return (
    <AvatarContainer className={"bold"}>
      <AvatarInitials>{initials}</AvatarInitials>
    </AvatarContainer>
  );
};
