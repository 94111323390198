import {useDispatch, useSelector} from "react-redux";
import {
  editCategory,
  editModifierGroupOverrides,
} from "../../_shared/slices/CatalogSlice";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {ModifierGroupStructureForOverrides} from "../models/ModifierModel";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../_shared/application";
import {AdminForm} from "../../admin/products/styles/AdminStyles";
import {AdminInput} from "../../admin/products/components/AdminComponentFactory";
import {saveCategory} from "../backend/catalogApi";

export const EditModifierGroupOverrides = (props) => {
  const {callback} = props;
  const modifierGroup = useSelector(
    (state) => state.catalog.editingModifierGroupOverrides
  );
  const currentCategory = useSelector((state) => state.catalog.editingCategory);

  const dispatch = useDispatch();
  const close = () => {
    dispatch(editModifierGroupOverrides(null));
  };

  const setOverride = (overrideMap) => {
    let overrides = {};
    if (currentCategory?.modifierGroupOverrides != null) {
      overrides = currentCategory.modifierGroupOverrides;
    }
    const copyOverrides = Object.assign({}, overrides);
    const editOverrides = Object.assign(copyOverrides, {
      [modifierGroup.id]: overrideMap,
    });
    const payload = {modifierGroupOverrides: editOverrides};
    saveCategory(currentCategory, payload);
    callback(payload);
    dispatch(editCategory(Object.assign({...currentCategory}, payload)));
  };

  const deleteModifierGroupAction = (e) => {
    e.preventDefault();
    setOverride(null);
    close();
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(ModifierGroupStructureForOverrides),
    onSubmit: (values) => {
      const modifierGroupToSave = Object.assign({}, values);
      const reqModifiers = values["requiredModifiers"];
      if (reqModifiers != null && Object.keys(reqModifiers).length > 0) {
        modifierGroupToSave.requiredModifiers = reqModifiers;
      } else {
        modifierGroupToSave.requiredModifiers = null;
      }

      setOverride(modifierGroupToSave);
      close();
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (modifierGroup != null && currentCategory != null) {
      let overrideValues = {};
      if (
        currentCategory.modifierGroupOverrides != null &&
        currentCategory.modifierGroupOverrides[modifierGroup.id] != null
      ) {
        overrideValues =
          currentCategory.modifierGroupOverrides[modifierGroup.id];
      }
      for (const key in ModifierGroupStructureForOverrides) {
        if (overrideValues[key] != null) {
          const value = overrideValues[key];
          formik.setFieldValue(key, value);
        }
      }
    }
  }, [modifierGroup, currentCategory]);

  if (modifierGroup == null || currentCategory == null) {
    return null;
  }

  const editingInputStructure = getFormStructure(
    ModifierGroupStructureForOverrides
  );
  return (
    <div id="myModal" className="modal" style={{zIndex: 1050}}>
      <div
        className="fullDiv"
        onClick={() => {
          close();
        }}
      />
      <div className="modal-content eighty-full">
        <AdminForm onSubmit={formik.handleSubmit}>
          {editingInputStructure.map((keyInfo) => {
            const {key} = keyInfo;
            const value = formik.values[key];

            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                onChange={(value) => {
                  formik.setFieldValue(key, value);
                }}
                handleChange={formik.handleChange}
              />
            );
          })}
        </AdminForm>

        <div>
          <button
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Save
          </button>
          <button onClick={deleteModifierGroupAction}>
            Remove all overrides
          </button>
        </div>
      </div>
    </div>
  );
};
