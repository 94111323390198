import styled from "styled-components";
// Call to action styles
export const PreProposalCallToActionImage = styled.div<{imageSrc: String}>`
  //width: 503px;
  height: 100%;
  background-size: cover;
  border-radius: 15px;
  ${(props) =>
    props.imageSrc
      ? "background-image: url(" + props.imageSrc + ");"
      : "background-image:none;"});
`;

export const PreProposalCallToActionContainer = styled.div<{
  isMobile: boolean;
  isNatMobile: boolean;
  isSinglePage: boolean;
}>`
  padding-top: ${(props) =>
    props.isMobile ? 0 : props.isSinglePage ? "2rem" : "3rem"};
  padding-bottom: ${(props) => (props.isMobile ? "0" : "2rem")};
  display: flex;
  flex-direction: ${(props) =>
    props.isSinglePage && props.isNatMobile
      ? "column"
      : props.isNatMobile
      ? "column-reverse"
      : "row"};
  ${(props) => (props.isSinglePage ? "align-items: flex-start" : "")};
`;

export const PreProposalCallToActionText = styled.div`
  padding-bottom: 2rem;
  padding-right: 2rem;
`;

export const PreProposalCallToActionTitle = styled.div<{
  isSinglePage: boolean;
}>`
  padding-top: 2rem;
  padding-bottom: 10px;
  font-family: Maison-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props.isSinglePage ? "1.5rem" : "22px")};
  line-height: 22px;
  color: #000000;
`;

export const PreProposalCallToActionSubtitle = styled.div<{
  isSinglePage: boolean;
}>`
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props.isSinglePage ? "1.15rem" : "16px")};
  line-height: 133%;
  color: rgba(0, 0, 0, 0.6);
  max-width: 500px;
`;

export const PreProposalCallToActionButton = styled.div`
  margin-top: 8px;
`;

// Other styles
export const PreProposalContainer = styled.div`
  padding-top: 0;
  padding-bottom: 1rem;
`;

export const ProposalCardsTitle = styled.div`
  font-family: Maison-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 133%;
  color: #000000;
`;

export const ProposalText = styled.div`
  padding-top: 10px;
`;

export const ProposalTitle = styled.div`
  font-family: Maison-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 133%;
  color: rgba(0, 0, 0, 0.9); // ALPHA
  margin-bottom: 6px;
`;

export const ProposalSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 133%;
  color: rgba(0, 0, 0, 0.7); // ALPHA
`;

export const ProposalCard = styled.div<{isMobile: boolean}>`
  width: ${(props) => (props.isMobile ? "100%" : "240px")};
  padding: 10px 10px 2rem 10px;
  min-height: 186px;
`;

export const ProposalImage = styled.div<{imageSrc: String; isMobile: boolean}>`
  height: ${(props) => (props.isMobile ? "211px" : "150px")};
  width: 100%;
  ${(props) =>
    props.imageSrc
      ? "background-image: url(" + props.imageSrc + ");"
      : "background-image:none;"};
  border: none;
  background-size: cover;
  border-radius: 15px;
`;

export const ProposalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;
