// @ts-ignore
import {auth, functions} from "../index";
import firebase from "firebase";
import {devLogger} from "../../../.developerSettings";

export const createNewUserWithEmailAndPassword = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => {
  // @ts-ignore
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((response: firebase.auth.UserCredential) => {
      devLogger("Successfully created user");
      devLogger(response);
      const user = response.user;
      user?.updateProfile({
        displayName: `${firstName} ${lastName}`,
      });
      return user;
    });
};

export const signInWithEmail = async (email: string, password: string) => {
  // @ts-ignore
  return auth.signInWithEmailAndPassword(email, password);
};

export const resetPassword = async (
  newPassword: any,
  passwordResetCode: any
) => {
  const request = {
    newPassword: newPassword,
    passwordResetCode: passwordResetCode,
  };
  // @ts-ignore
  return functions
    .httpsCallable("auth-resetPassword")(request)
    .then((success: {data: any}) => {
      return success.data;
    })
    .catch((error: any) => {
      console.log(error);
      return null;
    });
};

export const emailPasswordResetLink = (email: string) => {
  // @ts-ignore
  return auth
    .sendPasswordResetEmail(email)
    .then(() => {
      console.log("Sent email.");
    })
    .catch((error: any) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const triggerPasswordResetLink = (email: string) => {
  const request = {
    email: email,
  };
  // @ts-ignore
  return functions
    .httpsCallable("auth-createAndSendPasswordReset")(request)
    .then((success: any) => {
      return success.data;
    })
    .catch((error: any) => {
      console.log(error);
      return null;
    });
};

export const getPasswordReset = async (passwordResetCode: any) => {
  const request = {
    passwordResetCode: passwordResetCode,
  };
  // @ts-ignore
  return functions
    .httpsCallable("auth-fetchPasswordReset")(request)
    .then((success: any) => {
      return success.data;
    })
    .catch((error: any) => {
      console.log(error);
      return null;
    });
};
