import React, {useCallback, useEffect, useState} from "react";
import {firestore, storage} from "../../../database/firebase";
import Compressor from "compressorjs";
import {useSelector} from "react-redux";
import {isBlankString} from "@natomas/core";
import {useDropzone} from "react-dropzone";
import {SmartImageWithId} from "./SmartImage";
import {fetchImage} from "../logic/data";
import {NatButton} from "../../_shared/generics/button";

function MyDropzone(props) {
  const {imageId, upload, removeImage} = props;
  const onDrop = useCallback(
    (acceptedFiles) => {
      upload(acceptedFiles[0]);
    },
    [upload]
  );
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  let buttonText = "";
  if (isBlankString(imageId)) {
    buttonText = isDragActive
      ? "Drop the files here ..."
      : "Click here to upload image\nor drag & drop an image";
  }

  return (
    <div style={{width: "100%", height: "100%", position: "relative"}}>
      <div
        {...getRootProps()}
        style={{
          width: "100%",
          height: "calc(100% - 50px)",
          position: "relative",
        }}
      >
        {isBlankString(imageId) ? null : (
          <SmartImageWithId
            imageId={imageId}
            style={{width: "100%", objectFit: "contain", height: "100%"}}
          />
        )}
        <input {...getInputProps()} />
        <button
          className={"button-secondary"}
          style={{
            width: "100%",
            height: "100%",
            whiteSpace: "pre",
            borderStyle: "dashed",
            borderRadius: "10px",
            zIndex: 0,
            position: "relative",
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {buttonText}
        </button>
      </div>

      <button
        className={"button-secondary"}
        hidden={isBlankString(imageId)}
        onClick={(e) => {
          e.preventDefault();
          removeImage();
        }}
      >
        Remove Image
      </button>
    </div>
  );
}

export const ImageUploader = (props) => {
  const imageStorageBaseRef = storage.ref("images");
  const imageFirestoreBaseRef = firestore.collection("images");
  const {onComplete, imageIdValue, fileNameCallback, replaceImageReference} =
    props;
  const [imageId, setImageId] = useState(imageIdValue);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const imageIdIsBlank = isBlankString(imageId);
  const images = useSelector((state) => state.global.images);
  const allowImageReUpload = replaceImageReference === true;

  useEffect(() => {
    setImageId(imageIdValue);
  }, [imageIdValue]);

  const upload = (file) => {
    if (file) {
      if (fileNameCallback != null) {
        fileNameCallback(file.name);
      }
      let imageIdForUpload = imageId;
      if (isBlankString(imageIdForUpload) || !allowImageReUpload) {
        imageIdForUpload = imageFirestoreBaseRef.doc().id;
        setImageId(imageIdForUpload);
      }
      uploadFileForAllSizes(file, imageIdForUpload);
    }
  };

  const removeImage = (file) => {
    setImageId(null);
    onComplete(null);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    e.preventDefault();
    upload(file);
  };

  const uploadFileForAllSizes = (file, imageIdForUpload) => {
    uploadFile(file, imageIdForUpload, "original");
    uploadFile(file, imageIdForUpload, "100");
    uploadFile(file, imageIdForUpload, "300");
    uploadFile(file, imageIdForUpload, "500");
    uploadFile(file, imageIdForUpload, "750");
    uploadFile(file, imageIdForUpload, "1000");
    uploadFile(file, imageIdForUpload, "1500");
    uploadFile(file, imageIdForUpload, "2500");
  };

  const uploadFile = (file, imageIdForUpload, size) => {
    let maxWidth = null;

    if (size !== "original") {
      maxWidth = parseInt(size);
    }

    new Compressor(file, {
      maxWidth: maxWidth,
      success(result) {
        const uploadTask = imageStorageBaseRef
          .child(size)
          .child(imageIdForUpload)
          .put(result);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progress function ...
          },
          (error) => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              imageFirestoreBaseRef
                .doc(imageIdForUpload)
                .set(
                  {
                    [size]: {
                      url,
                    },
                  },
                  {merge: true}
                )
                .then((success) => {
                  if (size === "original") {
                    fetchImage(imageIdForUpload, true);
                    if (!allowImageReUpload) {
                      onComplete(imageIdForUpload);
                    }
                  }
                });
            });
          }
        );
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  if (props.drop === true) {
    return (
      <MyDropzone removeImage={removeImage} upload={upload} imageId={imageId} />
    );
  }
  const realImage = images[imageId];

  let imagePreview = null;
  if (realImage != null && realImage["300"] != null) {
    imagePreview = (
      <div>
        {imageId}
        <br />
        <img
          src={realImage["300"].url}
          alt="thumbnail"
          key={realImage["300"].url}
        />
        <br />
        <NatButton
          clickEvent={() => removeImage()}
          label={"Remove Image"}
          type={"button"}
        />
      </div>
    );
  }

  let imagePicker = (
    <div
      className="clickable"
      onClick={() => {
        setShowSuggestions(true);
      }}
    >
      Show Suggestions
    </div>
  );

  if (showSuggestions) {
    imagePicker = (
      <div className="gallery-grid">
        {Object.values(images).map((image) => {
          if (image["100"] == null) {
            return null;
          }
          return (
            <img
              onClick={() => {
                onComplete(image.id);
                setImageId(image.id);
              }}
              src={image["100"].url}
              alt="thumbnail"
              key={image["100"].url}
            />
          );
        })}
      </div>
    );
  }

  let imageIdInput = null;
  let textInput = React.createRef();
  if (imageIdIsBlank) {
    imageIdInput = (
      <div>
        <input ref={textInput} placeholder={"Image Id"} />
        <div
          className="general-button"
          onClick={(e) => {
            e.preventDefault();
            const imageId = textInput.current.value;
            setImageId(imageId);
            onComplete(imageId);
          }}
        >
          Set Image Id
        </div>
      </div>
    );
  }

  return (
    <div style={{maxHeight: "400px", overflow: "auto"}}>
      <br />
      <input type="file" onChange={handleChange} />
      {imageIdInput}
      {imagePreview}
      {imagePreview == null ? imagePicker : null}
    </div>
  );
};
