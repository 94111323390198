import React, {useEffect, useState} from "react";
import logo from "../../../../assets/logos/villa-logo-cropped.svg";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {
  LoadingPanelSpinner,
  LoadingPanelContainer,
  ImageContainer,
  SpinnerContainer,
} from "./styles";

export const LoadingPanel = (props: {show: boolean}) => {
  const {show} = props;
  const [hide, setHide] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (!show) {
      setOpacity(0);
      setTimeout(() => setHide(true), 250);
    } else if (show) {
      setOpacity(1);
      setHide(false);
    }
  }, [show]);

  return (
    <LoadingPanelContainer hide={hide} opacity={opacity}>
      <ImageContainer>
        <img src={logo} alt={"Villa"} />
      </ImageContainer>
      <SpinnerContainer>
        <LoadingPanelSpinner size={"2rem"}>
          <AiOutlineLoading3Quarters />
        </LoadingPanelSpinner>
      </SpinnerContainer>
    </LoadingPanelContainer>
  );
};
