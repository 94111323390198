import {ModifierGroupSummary} from "./ModifierGroupSummary";
import {Underline} from "./underline";

export const ConfigurationPageSummary = (props) => {
	const {configurationPage, index} = props;
	const modifierGroups = configurationPage.selectedModifierGroups;

	return (
		<div>
			<h4>
				{index}. {configurationPage.title}
			</h4>
			<div className="description-wrapper">
				{modifierGroups.map((modifierGroup) => {
					return (
						<div key={modifierGroup.id}>
							<ModifierGroupSummary
								modifierGroup={modifierGroup}
							></ModifierGroupSummary>
						</div>
					);
				})}
			</div>
			<Underline className="result-underline" />
		</div>
	);
};
