export async function triggerIntakeZapier(
  email,
  firstName,
  lastName,
  phone,
  date,
  encodedStreet,
  city,
  state,
  zip,
  page_source
) {
  try {
    const response = await fetch(
      `https://hooks.zapier.com/hooks/catch/6891355/ojxa2zq/?dateTime=${date}&street=${encodedStreet}&city=${city}&state=${state}&zip=${zip}&email=${email}&firstName=${firstName}&lastName=${lastName}&pageSource=${page_source}&phone=${phone}`
    );
    console.log(response.status);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}
