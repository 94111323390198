import {firestore} from "../../../database/firebase";
import "firebase/auth";
import {imageDataLoaded} from "../../_shared/slices/GlobalSlice";
import {store} from "../../../store";

const imagesFetched = {};
const imagesRef = () => {
  return firestore.collection("images");
};

export async function fetchImage(imageId, forceFetch) {
  const shouldPreventFetch = imagesFetched[imageId] === true;
  if (shouldPreventFetch && forceFetch !== true) {
    return;
  }
  imagesFetched[imageId] = true;
  const cachedImage = store.getState().global.images[imageId];
  if (cachedImage && !forceFetch) {
    return cachedImage;
  }

  if (imageId != null && imageId.length > 0) {
    if (store.getState().global.editingRenderings === true) {
      return imagesRef()
        .doc(imageId)
        .onSnapshot((snapshot) => {
          const image = snapshot.data();
          if (image != null) {
            image.id = snapshot.id;
            store.dispatch(imageDataLoaded(image));
          }
          return image;
        });
    } else {
      return imagesRef()
        .doc(imageId)
        .get()
        .then((snapshot) => {
          const image = snapshot.data();
          if (image != null) {
            image.id = snapshot.id;
            store.dispatch(imageDataLoaded(image));
          }
          return image;
        });
    }
  }
}
