import {isProd} from "../application";
import {
  STRIPE_INITIAL_DEPOSIT_PRICE_ID,
  STRIPE_INITIAL_DEPOSIT_PRICE_ID_TEST,
  STRIPE_PHASE_1_PRICE_ID,
  STRIPE_PHASE_1_PRICE_ID_TEST,
} from "@natomas/core";

export const getStripePublicKey = () => {
  return isProd
    ? "pk_live_hVbCK7Z1ZBFn71eEehTVLTkQ003bUGJHps"
    : "pk_test_n5E7E2iacteJYNKGuNIMuQHd00RXBfDLrW";
};

export const getStripePhase1Product = () => {
  return isProd ? STRIPE_PHASE_1_PRICE_ID : STRIPE_PHASE_1_PRICE_ID_TEST;
};

export const getStripeAssessmentFeeProduct = () => {
  return isProd
    ? STRIPE_INITIAL_DEPOSIT_PRICE_ID
    : STRIPE_INITIAL_DEPOSIT_PRICE_ID_TEST;
};
