import React from "react";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {useDispatch, useSelector} from "react-redux";
import {EditCategory} from "../components/EditCategory";
import {isBlankString} from "@natomas/core";
import {ColumnContainer, MainColumn} from "../styles/AdminStyles";
import {TabColumn} from "../components/TabColumn";
import {editCategory} from "../../../_shared/slices/CatalogSlice";
import {IStore} from "../../../_shared/slices/types/Store";

export const CategoryTab = () => {
  const {productGroupCategories} = useProductCatalog();
  const currentCategory = useSelector(
    (state: IStore) => state.catalog.editingCategory
  );
  const dispatch = useDispatch();
  const title = "Categories";

  const generateTabColumnRows = () => {
    return Object.values(productGroupCategories)
      .sort((a: any, b: any) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      })
      .map((category: any) => {
        const title = !isBlankString(category.internal_title)
          ? category.internal_title
          : category.title;
        return {
          title: title,
          key: category.id,
          data: category,
          action: changeCategory,
          checkIfActive: checkIfCategoryIsActive,
        };
      });
  };

  const newCategory = () => {
    dispatch(editCategory({title: "New Category"}));
  };

  const changeCategory = (category: any) => {
    dispatch(editCategory(category));
  };

  const checkIfCategoryIsActive = (key: string) => {
    return currentCategory?.id === key;
  };

  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={"11rem"}
        headerAddAction={newCategory}
        rows={generateTabColumnRows()}
      />
      <MainColumn>
        <EditCategory />
      </MainColumn>
    </ColumnContainer>
  );
};
