import React from "react";
import {
  PROJECT_SCHEDULE_1,
  PROJECT_SCHEDULE_2,
  PROJECT_SCHEDULE_3,
  PROJECT_SCHEDULE_4,
  PROJECT_SCHEDULE_5,
} from "../styles";
import {sumArray} from "@natomas/core";

export const NEXT_STEPS_POP_UP_TABS = [
  {
    title: "PSA",
    value: "PSA",
    content: (
      <div>
        The Villa “Project Services Agreement” – PSA for short – is your
        contract to build an ADU with Villa. Once signed, we will get to work on
        your project, starting with a final design review and permit package
        preparation. If you have additional questions regarding the PSA, your
        Home Consultant will have the answers.
      </div>
    ),
  },
  // {
  // 	title: "Phase 1",
  // 	value: "phase1",
  // 	content: (
  // 		<div>
  // 			<div className={"medium"} style={{marginBottom: "4px"}}>
  // 				Phase 1 includes the following Villa services:{" "}
  // 			</div>
  // 			<div>
  // 				1. Finalize design selections and floor plan, and send to our
  // 				factory for engineering review & final blacklines.
  // 			</div>
  // 			<div>2. Sign off on final blacklines.</div>
  // 			<div>3. Develop & finalize the permit package for your project.</div>
  // 			<div>
  // 				4. Perform any and all required work in order to provide a finalized
  // 				and locked-in price for the site-work line items.
  // 			</div>
  // 		</div>
  // 	),
  // },
  {
    title: "Refund Policy",
    value: "refund",
    content: (
      <div>
        Villa’s Phase 1 payment is fully refundable only if Permits are not
        approved. All third-party costs will remain non-refundable.
      </div>
    ),
  },
];

export const ALL_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const NEXT_STEPS_TIMELINE_TOTAL_MONTHS = 14;

export interface INextStepInfo {
  id: string;
  title: string;
  subtitle: string;
  details: string;
  color: string;
  startMonth: number;
  endMonth: number;
  phase?: any;
}

const GENERIC_TIMELINE_INFO = [
  {
    id: "proposal",
    title: "Project Proposal",
    subtitle: "Great news - your ADU design and budget are done!",
    details:
      "• Review Detailed Site Plan\n" +
      "• Select Floor Plan and Design\n" +
      "• Approve Estimated Budget Summary\n" +
      "• $9000 Payment to Enter Phase 1",
    color: PROJECT_SCHEDULE_1,
    startMonth: 0,
    endMonth: 1,
  },
  {
    id: "permitting",
    title: "Permitting",
    subtitle:
      "Your permit package is prepared and submitted by our preconstruction team.",
    details:
      "• Confirm PSA and Scope of Work\n" +
      "• Confirm Home Design\n" +
      "• Prep and Submit Permit Package* \n" +
      "• Prep Factory Home Order",
    color: PROJECT_SCHEDULE_2,
    startMonth: 1,
    endMonth: 4,
    phase: {
      title: "Phase 1",
      endMonth: 6,
      additionalSteps: 1,
      endColor: PROJECT_SCHEDULE_3,
    },
  },
  {
    id: "ATB",
    title: "Authorization to Build",
    subtitle:
      "Your team will present you with the preconstruction buyout results and a not to exceed quote for your project.",
    details:
      "• Permits Approved\n" +
      "• Preconstruction Buyout Finalized\n" +
      "• Authorization to Build Package Presented",
    color: PROJECT_SCHEDULE_3,
    startMonth: 4,
    endMonth: 6,
  },
  {
    id: "manufacturing",
    title: "Manufacturing",
    subtitle:
      "Our factory partner builds your unit; we prep your site for installation.",
    details:
      "• Fully Fund Escrow \n" +
      "• Permit Issuance \n" +
      "• Submit Factory Order \n" +
      "• Factory Home Production \n" +
      "• Complete Site Prep and Foundation Work",
    color: PROJECT_SCHEDULE_4,
    startMonth: 6,
    endMonth: 10,
    phase: {
      title: "Phase 2",
      endMonth: 13,
      additionalSteps: 1,
      endColor: PROJECT_SCHEDULE_5,
    },
  },
  {
    id: "installation",
    title: "Installation",
    subtitle: "Time to hand you the keys to your new home.",
    details:
      "• Home Delivery and Installation \n" +
      "• Utility Hook Up \n" +
      "• Complete Home Finishes \n" +
      "• Final Inspection/Walkthrough \n" +
      "• Issue Certificate of Occupancy \n" +
      "• Introduce Home Warranty Program",
    color: PROJECT_SCHEDULE_5,
    startMonth: 10,
    endMonth: 13,
  },
];

export const getProductionTimelineInfo = (productGroup: any) => {
  let monthBreakdown = [1, 3, 2, 4, 3];
  if (productGroup?.production_timeline?.length) {
    const timeline: any[] = productGroup.production_timeline;
    monthBreakdown = timeline.map((timelineInfo: any) => {
      return timelineInfo.months;
    });
  }
  return {
    monthBreakdown,
    totalMonths: sumArray(monthBreakdown),
  };
};

export const getNextSteps = (monthBreakdown: number[]): INextStepInfo[] => {
  let lastStepEndMonth = 0;
  return monthBreakdown.map((numberOfMonths: number, index: number) => {
    // Set up values
    const startMonth = lastStepEndMonth;
    const endMonth = startMonth + numberOfMonths;
    lastStepEndMonth = endMonth;

    // Copy from generic step
    const genericStepInfo = Object.assign({}, GENERIC_TIMELINE_INFO[index]);
    genericStepInfo.startMonth = startMonth;
    genericStepInfo.endMonth = endMonth;

    if (genericStepInfo.phase) {
      // Add the number of months for the additional phases
      let phaseEndMonth = startMonth;
      const additionalSteps = genericStepInfo.phase.additionalSteps;
      for (let i = index; i <= index + additionalSteps; i++) {
        phaseEndMonth += monthBreakdown[i];
      }
      genericStepInfo.phase.endMonth = phaseEndMonth;
    }

    return genericStepInfo;
  });
};
