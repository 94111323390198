import {TextSize} from "./options";

export const calculateBorder = (borderColor: string | null) => {
  if (borderColor) return "2px solid " + borderColor;
  else return "2px solid white";
};

export const calculatePadding = (size: TextSize): string => {
  switch (size) {
    case TextSize.XSMALL:
      return "0.15rem 0.45rem";
    case TextSize.SMALL:
      return "0.25rem 0.75rem";
    case TextSize.NORMAL:
      return "0.5rem 1rem";
    case TextSize.LARGE:
      return "0.75rem 1.25rem";
    default:
      return "0.75rem 1.25rem";
  }
};
