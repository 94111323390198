import React from "react";
import {Tabs} from "antd";
import {ListItem} from "./styled";
import {ImageSlider} from "./ImageSlider";
import {UnitDescription} from "../../../../design-tool/components/UnitDescription";

const {TabPane} = Tabs;

export const ListItems = ({placeAdu, onAduCardClick, products}) => {
	const renderUnits = (minSqFt, maxSqFt) => {
		return products
			.filter(
				(d) =>
					d.productDetails.squareFeet >= minSqFt &&
					d.productDetails.squareFeet < maxSqFt
			)
			.map((product) => {
				return (
					<ListItem
						key={product.title}
						className="design-container"
						onMouseOver={() => placeAdu(product)}
						onClick={() => onAduCardClick(product)}
					>
						<ImageSlider product={product} />
						<UnitDescription product={product} />
					</ListItem>
				);
			});
	};

	return (
		<Tabs
			destroyInactiveTabPane
			defaultActiveKey="1"
			tabBarStyle={{width: "100%"}}
		>
			<TabPane tab="Single module" key="1">
				{renderUnits(400, 700)}
			</TabPane>
			<TabPane tab="Double module" key="2">
				{renderUnits(700, 1500)}
			</TabPane>
		</Tabs>
	);
};
