import React from "react";
//Shared
import {
  StyledFormContent,
  StyledFormDetails,
  StyledParagraph,
  StyledFormContentHeader,
} from "../../../_shared/styles";
import {ResetPasswordForm} from "../../components/ResetPasswordForm";

interface IForgotPasswordPage {
  toLogin: any;
  toCheckYourEmail: any;
  email?: string;
  prc: any;
}

const ResetPasswordPage = (props: IForgotPasswordPage) => {
  return (
    <StyledFormContent>
      <StyledFormDetails>
        <StyledFormContentHeader>Create a new password</StyledFormContentHeader>
        <StyledParagraph>
          Please create a password that is at least 6 characters long.
        </StyledParagraph>
      </StyledFormDetails>
      <ResetPasswordForm {...props} />
    </StyledFormContent>
  );
};

export {ResetPasswordPage};
