import {useDispatch, useSelector} from "react-redux";
import {editingOptionValues} from "../../../_shared/slices/CatalogSlice";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {ProductOptionValueStructure} from "../../models/ProductOptionModel";
import Select from "react-select";
import {saveOptionValueToDB} from "../logic/ProductOptionHelper";

function convertOptionKeysToOptions(optionKeys) {
	if (optionKeys == null) {
		return [];
	}
	return Object.keys(optionKeys).map((key) => {
		const optionKey = optionKeys[key];
		return {
			label:
				optionKey.name != null && optionKey.name.length > 0
					? optionKey.name
					: key,
			value: key,
		};
	});
}

const convertOptionKeyToOptions = (optionKeyId, optionKeys) => {
	if (optionKeys == null || optionKeys[optionKeyId] == null) {
		return {
			label: optionKeyId,
			value: optionKeyId,
		};
	}
	const optionKey = optionKeys[optionKeyId];
	return [
		{
			label: optionKey.name,
			value: optionKey.id,
		},
	];
};

const convertDBOptionKeysToOptionsCurrentSelection = (
	optionKeyIdMap,
	optionKeys
) => {
	if (optionKeys == null || optionKeyIdMap == null) {
		return [];
	}

	return Object.keys(optionKeyIdMap).map((optionKeyId) => {
		const optionKey = optionKeys[optionKeyId];
		return {
			label: optionKey.name,
			value: optionKey.id,
		};
	});
};

const convertOptionsToOptionKey = (options) => {
	if (options == null || options.length < 1) {
		return null;
	}
	return options[0].value;
};

const convertKeyOptionsToDBKeys = (options) => {
	if (options == null || options.length < 1) {
		return null;
	}

	const optionKeyIdMap = {};
	options.forEach((option) => {
		optionKeyIdMap[option.value] = true;
	});
	return optionKeyIdMap;
};

export const EditProductOptionValues = () => {
	const isEditingOptionValues = useSelector(
		(state) => state.catalog.isEditingOptionValues
	);
	const optionKeys = useSelector((state) => state.catalog.optionKeys);
	const optionValues = useSelector((state) => state.catalog.optionValues);
	const dispatch = useDispatch();
	const close = () => {
		dispatch(editingOptionValues(false));
	};

	const [currentOptionValue, setCurrentOptionValue] = useState(null);

	const initialValues = {};
	Object.keys(ProductOptionValueStructure).forEach((key) => {
		initialValues[key] = "";
	});

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			if (currentOptionValue != null) {
				const valueToSave = Object.assign({id: currentOptionValue.id}, values);
				valueToSave.optionKey = convertOptionsToOptionKey(
					valueToSave.optionKey
				);
				valueToSave.optionKeys = convertKeyOptionsToDBKeys(
					valueToSave.optionKeys
				);
				saveOptionValueToDB(valueToSave);
				setCurrentOptionValue(null);
			}
		},
	});

	useEffect(() => {
		if (currentOptionValue == null) {
			formik.resetForm();
		} else {
			for (const key in ProductOptionValueStructure) {
				const value = currentOptionValue[key];
				if (value != null) {
					if (key === "optionKey") {
						formik.setFieldValue(
							key,
							convertOptionKeyToOptions(value, optionKeys)
						);
					} else if (key === "optionKeys") {
						formik.setFieldValue(
							key,
							convertDBOptionKeysToOptionsCurrentSelection(value, optionKeys)
						);
					} else {
						formik.setFieldValue(key, value);
					}
				} else {
					formik.setFieldValue(key, "");
				}
			}
		}
	}, [currentOptionValue, optionKeys]);

	if (isEditingOptionValues !== true) {
		return null;
	}

	const sortedOptionValues = Object.values(optionValues).sort((a, b) => {
		return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
	});

	return (
		<div id="myModal" className="modal">
			<div
				className="fullDiv"
				onClick={() => {
					close();
				}}
			></div>
			<div
				className="modal-content eighty-full"
				style={{maxHeight: "80vh", minHeight: "50vh", overflow: "scroll"}}
			>
				<div
					className="close"
					onClick={() => {
						close();
					}}
				>
					&times;
				</div>
				<div style={{verticalAlign: "top", height: "100%"}}>
					<div
						style={{
							width: "50%",
							display: "inline-block",
							height: "100%",
							overflow: "scroll",
						}}
					>
						<div
							className="clickable"
							onClick={() => {
								setCurrentOptionValue({});
							}}
						>
							New Option Value
						</div>
						{optionValues == null
							? null
							: sortedOptionValues.map((optionValue) => {
									return (
										<div
											className="clickable"
											onClick={() => {
												setCurrentOptionValue(optionValue);
											}}
											key={optionValue.id}
										>
											{optionValue.name}
										</div>
									);
							  })}
					</div>
					<div
						style={{
							width: "50%",
							display: currentOptionValue == null ? "none" : "inline-block",
							verticalAlign: "top",
						}}
					>
						<form onSubmit={formik.handleSubmit}>
							{Object.keys(ProductOptionValueStructure).map((key) => {
								if (key === "optionKey") {
									return (
										<Select
											value={formik.values[key]}
											onChange={(value) => {
												formik.setFieldValue(key, [value]);
											}}
											options={convertOptionKeysToOptions(optionKeys)}
										/>
									);
								} else if (key === "optionKeys") {
									return (
										<Select
											isMulti
											value={formik.values[key]}
											onChange={(value) => {
												formik.setFieldValue(key, value);
											}}
											options={convertOptionKeysToOptions(optionKeys)}
										/>
									);
								}
								return (
									<div key={key}>
										<label htmlFor={key} style={{marginRight: "20px"}}>
											{key}:
										</label>
										<input
											id={key}
											name={key}
											type="text"
											onChange={formik.handleChange}
											value={formik.values[key]}
										/>
										<br />
									</div>
								);
							})}

							<button type="submit">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
