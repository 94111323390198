import React from "react";
import {SmartImageWithId} from "../components/SmartImage";
import {isMobile} from "../../_shared/navigation";
import "./styles.scss";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";

export const FloorPlanImage = (props) => {
	const {selectedProduct, selectedProductGroup} = props;
	let imageId = null;
	const {productGroupId} = useProductCatalog();
	if (productGroupId == null) {
		imageId = selectedProductGroup?.imageId;
	} else {
		imageId = selectedProduct?.imageId;
	}

	const style = {flexGrow: 1, minWidth: "309px", position: "relative"};
	if (isMobile()) {
		style.height = "200px";
	}
	return (
		<div className="current-image-wrapper" style={style}>
			<div
				className="rendered-image-wrapper fullDiv"
				style={{padding: "inherit"}}
			>
				<SmartImageWithId
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMid slice"
					blur={false}
					alt="rendering"
					imageId={imageId}
				/>
			</div>
		</div>
	);
};
