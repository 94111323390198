import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {editingConfigurationPage} from "../../_shared/slices/CatalogSlice";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {
  copyConfigurationPageToProducts,
  saveToConfigurationPage,
} from "../backend/catalogApi";
import {
  getConfigurationPagesForProduct,
  getCurrentProductConfigurationPage,
} from "../logic/ConfigurationPageHelper";
import {ConfigurationPageStructure} from "../models/ConfigurationPageModel";
import Select from "react-select";
import {isBlankString} from "@natomas/core";

function convertOptionKeysToOptions(products) {
  if (products == null) {
    return [];
  }
  return Object.keys(products)
    .map((key) => {
      const product = products[key];
      return {
        label: !isBlankString(product.title) ? product.title : key,
        value: key,
        price: product.priceMicros,
      };
    })
    .sort((a, b) => {
      return a.price - b.price;
    });
}

export const EditConfigurationPage = (props) => {
  const {currentProduct} = props;

  const configurationPage = useSelector(
    (state) =>
      getCurrentProductConfigurationPage(
        getConfigurationPagesForProduct(state, currentProduct.id),
        state.catalog.step
      ),
    shallowEqual
  );
  const isEditingConfigurationPage = useSelector(
    (state) => state.catalog.isEditingConfigurationPage
  );

  const products = useSelector((state) => state.catalog.products);
  const [showCopy, setShowCopy] = useState(false);
  const [productCopyIds, setProductCopyIds] = useState({});

  const dispatch = useDispatch();
  const close = () => {
    dispatch(editingConfigurationPage(false));
  };

  const copyProduct = (shallow) => {
    copyConfigurationPageToProducts(
      currentProduct.id,
      configurationPage.id,
      productCopyIds,
      shallow
    );
  };

  const initialValues = {};
  Object.keys(ConfigurationPageStructure).forEach((key) => {
    const field = ConfigurationPageStructure[key];
    initialValues[key] = field.defaultValue != null ? field.defaultValue : "";
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const infoToSave = Object.assign({}, values);
      saveToConfigurationPage(currentProduct.id, configurationPage, infoToSave);
      close();
    },
  });

  useEffect(() => {
    if (isEditingConfigurationPage === false || configurationPage == null) {
      formik.resetForm();
    } else {
      for (const key in ConfigurationPageStructure) {
        if (configurationPage[key] != null) {
          const value = configurationPage[key];
          formik.setFieldValue(key, value);
        }
      }
    }
  }, [configurationPage, isEditingConfigurationPage]);

  if (configurationPage == null || isEditingConfigurationPage !== true) {
    return null;
  }

  const configurationPageStructureForForm = Object.keys(
    ConfigurationPageStructure
  )
    .map((key) => {
      return {
        key: key,
        ...ConfigurationPageStructure[key],
      };
    })
    .sort((a, b) => a.index - b.index);

  return (
    <div id="myModal" className="modal">
      <div
        className="fullDiv"
        onClick={() => {
          close();
        }}
      ></div>
      <div className="modal-content eighty-full">
        <div
          className="close"
          onClick={() => {
            close();
          }}
        >
          &times;
        </div>
        <div>
          <form onSubmit={formik.handleSubmit}>
            {configurationPageStructureForForm.map((inputType) => {
              const {key, name} = inputType;
              let content = null;
              if (key === "displayDescription") {
                content = (
                  <>
                    <label htmlFor={key} style={{marginRight: "20px"}}>
                      {name}:
                    </label>
                    <textarea
                      id={key}
                      name={key}
                      onChange={formik.handleChange}
                      value={formik.values[key]}
                      style={{width: "300px"}}
                    />
                    <br />
                  </>
                );
              } else {
                content = (
                  <>
                    <label htmlFor={key} style={{marginRight: "20px"}}>
                      {name}:
                    </label>
                    <input
                      id={key}
                      name={key}
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values[key]}
                    />
                  </>
                );
              }

              return (
                <div key={key}>
                  {content}
                  <br />
                </div>
              );
            })}
            <div style={{position: "absolute", top: "10px", right: "10px"}}>
              <button type="submit">Submit</button>
            </div>
            {showCopy === false ? (
              <div
                onClick={() => {
                  setShowCopy(true);
                }}
              >
                Copy Page...
              </div>
            ) : (
              <>
                <div
                  onClick={() => {
                    setShowCopy(false);
                  }}
                >
                  Hide Copy Page
                </div>
                <Select
                  isMulti
                  placeholder="Products to propagate to..."
                  onChange={(value) => {
                    const newProductsToCopyTo = {};
                    value.forEach((product) => {
                      newProductsToCopyTo[product.value] = true;
                    });
                    setProductCopyIds(newProductsToCopyTo);
                  }}
                  options={convertOptionKeysToOptions(products)}
                />
                <div
                  onClick={() => {
                    copyProduct(true);
                  }}
                >
                  Shallow Copy
                </div>
                <div
                  onClick={() => {
                    copyProduct(false);
                  }}
                >
                  Deep Copy
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
