import firebase from "firebase";

export const updateOrCreateDocumentInDB = (
  reference: firebase.firestore.DocumentReference,
  payload: any
) => {
  if (reference == null) {
    return null;
  }
  return reference
    .update(payload, {merge: true})
    .then((result: any) => {
      return result;
    })
    .catch((error: any) => {
      if (error != null && error.code === "not-found") {
        return setAndMergeDocumentInDB(reference, payload);
      }
      return error;
    });
};

export const setAndMergeDocumentInDB = (
  reference: firebase.firestore.DocumentReference,
  payload: any
) => {
  if (reference == null) {
    return null;
  }
  return reference.set(payload, {merge: true});
};

export const subscribeToCollectionWithCallback = async (
  reference: firebase.firestore.CollectionReference,
  callback: (any1: any | null) => void
) => {
  if (reference == null) {
    return null;
  }
  return reference.onSnapshot((snapshot) => {
    const docs = snapshot.docChanges();
    const events: any = {};
    if (docs) {
      docs.forEach((c) => {
        const event = c.doc.data();
        event.id = c.doc.id;
        events[c.doc.id] = event;
      });
    }

    callback(events);
  });
};

export const convertCollectionSnapshotToMap = (
  querySnapshot: firebase.firestore.QuerySnapshot
) => {
  const results: any = [];
  querySnapshot.forEach((doc) => {
    const result = doc.data();
    result.id = doc.id;
    results.push(result);
  });
  return results;
};

export const getDocData = async (
  reference: firebase.firestore.DocumentReference
) => {
  if (reference == null) {
    return null;
  }
  const doc = await reference.get();

  if (!doc.exists) {
    return null;
  }
  return docIntoData(doc);
};

export const subscribeWithCallback = async (
  reference: firebase.firestore.DocumentReference,
  callback: (any1: firebase.firestore.DocumentData | null) => void
) => {
  if (reference == null) {
    return null;
  }
  return reference.onSnapshot((snapshot) => {
    const data = snapshot.data();
    if (snapshot.exists && data) {
      callback(docIntoData(snapshot));
    } else {
      callback(null);
    }
  });
};

export const docIntoData = (doc: firebase.firestore.DocumentSnapshot) => {
  const data = doc.data();
  return Object.assign({id: doc.id}, data);
};

export const collection = (collectionId: string) => {
  return firebase.firestore().collection(collectionId);
};

export const batchUpdate = () => {
  return firebase.firestore().batch();
};

export const saveOrUpdateDocument = async (
  collection: firebase.firestore.CollectionReference,
  id: string | null,
  payload: any
) => {
  let actualDocumentId = id;
  if (actualDocumentId == null || actualDocumentId.length < 1) {
    actualDocumentId = collection.doc().id;
    await collection.doc(actualDocumentId).set(payload);
  } else {
    try {
      await collection.doc(actualDocumentId).update(payload);
    } catch (e) {
      await setDocumentWithMerge(collection, actualDocumentId, payload);
    }
  }
  return actualDocumentId;
};

export const setDocumentWithMerge = async (
  collection: firebase.firestore.CollectionReference,
  id: string,
  payload: any
) => {
  await collection.doc(id).set(payload, {merge: true});
  return id;
};

export const deleteCollection = (
  collectionRef: firebase.firestore.CollectionReference
) => {
  return collectionRef.get().then((snapshot) => {
    const batch = firebase.firestore().batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(collectionRef.doc(doc.id));
    });
    return batch.commit();
  });
};
