import React from "react";
import {FormButton} from "./formButton";
import {CurrentIcon} from "../logic/currentIcon";

export const ButtonForm = (props) => {
	//set the name of form for reference and setting function that will handle submit

	//Sets the props needed
	const {onSubmit, question, inputs, selectedValue} = props;

	//Form is used for all questions that have buttons as input. Will go through an array of objects to render out all the inputs
	return (
		<div className="form-wrapper" style={{fontFamily: "Maison-Medium"}}>
			<CurrentIcon />
			<form style={{width: "350px", maxWidth: "95%"}}>
				<div className="question-wrapper" style={{fontSize: "20px"}}>
					<h3 className="question">{question}</h3>
				</div>

				<div className="input-wrapper" style={{fontSize: "16px"}}>
					{inputs.map((item) => (
						<FormButton
							buttonClass={
								selectedValue === item.radioValue
									? `choosen ${item.buttonClass}`
									: `${item.buttonClass}`
							}
							buttonOnClick={item.onClick}
							buttonText={item.buttonText}
							nextStep={item.nextStep}
							radioID={item.radioID}
							radioValue={item.radioValue}
							radioName={item.radioName}
							key={item.radioID}
						/>
					))}
				</div>

				<input type="submit" id="button-form-submit" />
			</form>
		</div>
	);
};
