import styled from "styled-components";
import {isMobile} from "../../../../_shared/navigation";

export const SitePlanContainer = styled.div`
  height: ${isMobile() ? "unset" : "calc(100% - 85px)"};
  width: 100%;
  overflow: auto;
  padding-left: 40px;
  padding-right: 40px;
`;

export const BottomContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-wrap: wrap;
`;

export const FlexContainer = styled.div`
  position: relative;
  width: 50%;
  height: 600px;
  text-align: center;
  padding: 10px;
  min-width: 400px;
  flex-grow: 2;
`;

export const WaitingContent = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

export const SitePlanButtonContainer = styled.div`
  padding-top: 6px;
  display: ${(props) => (props.hideElement === true ? "none" : "flex")};
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-shrink: 2;
`;

export const SitePlanButton = styled.button`
  background: ${(props) => (props.primary ? "black" : "white")};
  color: ${(props) => (!props.primary ? "black" : "white")};
  font-family: ${(props) => (props.primary ? "Maison-Bold" : "Maison-Book")};
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SitePlanImagePreview = styled.div`
  position: relative;
  height: ${(props) => (props.isMobile === true ? "60vh" : "600px")};
  width: 100%;
  overflow: hidden;
`;

export const SitePlanComponentContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 25px;
`;

export const SiteAddressContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
`;

export const SiteAddressImage = styled.img`
  width: 300px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
`;

export const MappingPreviewContainer = styled.div`
  position: relative;
  height: 540px;
  width: 100%;
  overflow: hidden;
`;
