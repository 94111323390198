import {useIntercom} from "react-use-intercom";
import {useCurrentProject} from "../hooks/useCurrentProject";
import {useEffect} from "react";
import {isMobile} from "../navigation";
import {Customer, ICustomerInfo} from "@natomas/core";
import {BASE_URL} from "../application";
import {
  CONFIGURATION_ID_URL_KEY,
  USER_ID_URL_KEY,
} from "../../../database/firebase/configuration/configuration";
import {useGlobalConfiguration} from "../hooks/useGlobalConfiguration";
import {useUrl} from "../hooks/useUrl";
import {useCurrentUser} from "../hooks/useCurrentUser";

const INTERCOM_PATH_URLS = ["/design", "/summary", "/", "/products"];

function getIntercomUserDetails(
  userDetails: ICustomerInfo,
  configurationId: string,
  urlPath: string,
  shouldSendWelcomeChat: boolean,
  isIntercomLive: boolean
) {
  let alignment = "right";
  if (urlPath.indexOf("design") >= 0) {
    alignment = "left";
  }

  let uid = userDetails?.user_id;
  if (uid == null) {
    return null;
  }

  const allowListed = INTERCOM_PATH_URLS.includes(urlPath) && isIntercomLive;
  return {
    alignment: alignment,
    hideDefaultLauncher: isMobile() || !allowListed,
    userId: uid,
    name: Customer.getFullName(userDetails),
    email: Customer.getContactEmail(userDetails),
    phone: Customer.getPrimaryContactPhoneNumber(userDetails),
    customAttributes: {
      customerPortalURL:
        configurationId == null
          ? ""
          : `${BASE_URL}/summary?${USER_ID_URL_KEY}=${uid}`,
      projectURL:
        configurationId == null
          ? ""
          : `${BASE_URL}/summary?${CONFIGURATION_ID_URL_KEY}=${configurationId}`,
      shouldSendWelcomeChat,
    },
  };
}

export const IntercomController = () => {
  const {update, boot, hardShutdown} = useIntercom();
  const {currentConfigurationId} = useCurrentProject();
  const {IS_INTERCOM_LIVE, WELCOME_INTERCOM_CHAT_ENABLED} =
    useGlobalConfiguration();
  const {user, loggedInUser} = useCurrentUser();
  const {url, getURLPath} = useUrl();

  // Boot up intercom on first load
  useEffect(() => {
    if (loggedInUser) {
      const urlPath = getURLPath();
      const intercomDetails = getIntercomUserDetails(
        user,
        currentConfigurationId,
        urlPath,
        WELCOME_INTERCOM_CHAT_ENABLED,
        IS_INTERCOM_LIVE
      );
      if (intercomDetails) {
        boot(intercomDetails);
      } else {
        boot();
      }
    } else {
      hardShutdown();
    }
  }, [loggedInUser]);

  // Update intercom details
  useEffect(() => {
    const urlPath = getURLPath();
    const intercomDetails = getIntercomUserDetails(
      user,
      currentConfigurationId,
      urlPath,
      WELCOME_INTERCOM_CHAT_ENABLED,
      IS_INTERCOM_LIVE
    );
    if (intercomDetails) {
      update(intercomDetails);
    }
  }, [
    url,
    IS_INTERCOM_LIVE,
    user,
    currentConfigurationId,
    WELCOME_INTERCOM_CHAT_ENABLED,
  ]);

  return null;
};
