import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {globalReducer} from "./components/_shared/slices/GlobalSlice";
import {configurationReducer} from "./components/design-tool/slices/configurationSlice";
import {utmReducer} from "./components/_shared/slices/UTMSlice";
import {intakeFormReducer} from "./components/intake-form/slices/IntakeFormSlice";
import {authReducer} from "./components/_shared/slices/AuthSlice";
import {portalReducer} from "./components/portal/_shared/slices/ProjectSummarySlice";
import {catalogReducer} from "./components/_shared/slices/CatalogSlice";
import {userReducer} from "./components/_shared/slices/UserSlice";
import {pageReducer} from "./components/_shared/slices/PageSlice";

export const store = configureStore({
  reducer: {
    catalog: catalogReducer,
    global: globalReducer,
    configuration: configurationReducer,
    utm: utmReducer,
    intakeForm: intakeFormReducer,
    auth: authReducer,
    portal: portalReducer,
    page: pageReducer,
    user: userReducer,
  },
  middleware: [...getDefaultMiddleware({immutableCheck: false})],
});

export const getStore = () => {
  return store;
};

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}
