import styled from "styled-components";
import {BLACK, GRAY} from "../../_shared/colors";
import {isMobile} from "../../_shared/navigation";
import {NatomasButton} from "../../_shared/generics/button/styles";

const CatalogContainer = styled.div`
  height: inherit;
`;

const NotServiceableBlurb = styled.div`
  text-align: center;
  max-width: 18rem;
  margin: auto;
  font-size: 1rem;
  font-family: Maison-Book, sans-serif;
  ${NatomasButton} {
    margin-top: 1rem;
  }
`;

const ProductLinesContainer = styled.div``;

const ProductCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow-scrolling: auto;
`;

const ProductListContainer = styled.div`
  margin-bottom: 3rem;
`;

const CatalogTitle = styled.div`
  font-size: ${isMobile() ? "1.5" : "2"}rem;
  color: ${BLACK};
  font-family: Maison-Medium, sans-serif;
  margin-top: 1.5rem;
`;

const CatalogSectionTitle = styled.div`
  font-size: ${isMobile() ? "1.25" : "1.5"}rem;
  color: ${BLACK};
  font-family: Maison-Medium, sans-serif;
  margin-top: 1.5rem;
`;
const CatalogSectionDescription = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: ${GRAY};
  margin-bottom: 1rem;
`;

const ProductLineTitle = styled.div`
  font-size: 2rem;
  font-weight: bolder;
  color: ${BLACK};
  text-align: center;
`;

export {
  CatalogTitle,
  ProductListContainer,
  ProductCardsContainer,
  CatalogSectionTitle,
  CatalogSectionDescription,
  ProductLineTitle,
  ProductLinesContainer,
  CatalogContainer,
  NotServiceableBlurb,
};
