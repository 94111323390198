import React, {useMemo} from "react";
import {CardContainer, SectionContainer, Title} from "../styles/preDeposit";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {CallToActionCard} from "./CallToActionCard";
import {Customer} from "@natomas/core";
import {getResourceList} from "../util/config";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";

export const HelpfulResources = React.memo(
  (props: {launchHelpfulResource: any; fullWidth: number}) => {
    const {launchHelpfulResource, fullWidth} = props;
    const {customer} = useCurrentCustomer();
    const {
      SCHEDULE_ASSESSMENT_ENABLED,
      MODEL_HOME_TOUR_ENABLED,
      IS_INTERCOM_LIVE,
    } = useGlobalConfiguration();
    const helpfulResourceList = useMemo(() => {
      const customerAddressState = Customer.getContactState(customer);
      return getResourceList(
        customer,
        customerAddressState,
        SCHEDULE_ASSESSMENT_ENABLED,
        MODEL_HOME_TOUR_ENABLED,
        IS_INTERCOM_LIVE
      );
    }, [
      customer,
      MODEL_HOME_TOUR_ENABLED,
      IS_INTERCOM_LIVE,
      SCHEDULE_ASSESSMENT_ENABLED,
    ]);

    const resourceSize = useDynamicValue({
      forFour: 4,
      forEight: 4,
      forTwelve: 4,
    });

    return (
      <SectionContainer>
        <Title>Helpful Resources</Title>
        <CardContainer height={"auto"} rowCount={fullWidth / resourceSize}>
          {helpfulResourceList.map((item, index) => {
            return (
              <PageElement
                size={resourceSize}
                height={"auto"}
                key={item.target + index}
              >
                <CallToActionCard
                  key={`resource-${index}`}
                  type={item.target}
                  link={item.link}
                  statusMsg={item.statusMsg}
                  status={item.status}
                  title={item.title}
                  msg={item.msg}
                  image={item.image}
                  buttonText={item.buttonText}
                  clickHandler={() => launchHelpfulResource(item)}
                />
              </PageElement>
            );
          })}
        </CardContainer>
      </SectionContainer>
    );
  }
);
