// @ts-ignore
import {Configuration, firestore, Utilities} from "../index";
import {
  allowFetchFromUser,
  Customer,
  IEventInfo,
  isBlankString,
} from "@natomas/core";
import {getStore, store} from "../../../store";
import {getParameter} from "../../../components/_shared/cookies";
import {
  USER_ACTION_SUB_COLLECTION_KEY,
  USER_COLLECTION_KEY,
  USER_LEAD_INFO_COLLECTION_KEY,
} from "./constants";
import {
  collection,
  deleteCollection,
  subscribeToCollectionWithCallback,
  subscribeWithCallback,
} from "../utilities";
import {syncUserInfoWithSalesForce} from "../api/user";
import {storeUserActions} from "../../../components/_shared/slices/UserSlice";
import {userLoaded} from "../../../components/_shared/slices/GlobalSlice";
import {
  mergeUserInfoAndUserLeadInfo,
  natomasCustomerToCustomerInfo,
  v0UserToCustomerInfo,
} from "../adapters";
import {NatomasUserInfo, V0UserInfo} from "@natomas/core/database";
import {ICustomerInfo} from "@natomas/core/application/entities/CustomerInfo";
import {addressDetailsToNatomasDefaultAddress} from "../adapters/address";
import {setConfigurationId} from "../../../components/design-tool/slices/configurationSlice";
import {USER_ID_URL_KEY} from "../configuration/configuration";
import {devLogger} from "../../../.developerSettings";
import {
  isAdmin,
  isEmployeeEmail,
} from "../../../components/_shared/user/UserUtils";

const getNatomasUsersRef = (userId: string) => {
  return collection(USER_COLLECTION_KEY).doc(userId);
};

const getNatomasUserLeadInfoRef = (userId: string) => {
  return Utilities.collection(USER_LEAD_INFO_COLLECTION_KEY).doc(userId);
};

const getUserActionCollectionRef = (userId: string) => {
  return Utilities.collection(USER_COLLECTION_KEY)
    .doc(userId)
    .collection(USER_ACTION_SUB_COLLECTION_KEY);
};

const getUsersRef = (userId?: string) => {
  if (!isBlankString(userId)) {
    // @ts-ignore
    return firestore.collection("users").doc(userId);
  }
  // @ts-ignore
  return firestore.collection("users");
};

const mergeUserAndStore = (
  userInfo: NatomasUserInfo | undefined,
  userLeadInfo: NatomasUserInfo | undefined
) => {
  const user = mergeUserInfoAndUserLeadInfo(userInfo, userLeadInfo);
  if (user) {
    const natomasCustomer = natomasCustomerToCustomerInfo(user);
    const uidParam = getParameter(USER_ID_URL_KEY, true);

    // TODO we need a place for this logic, but this doesn't feel like the right location
    // Automatically select configuration from projects if only one
    if (
      getStore().getState().configuration.currentConfigurationId == null &&
      natomasCustomer.project_ids.length >= 1
    ) {
      if (uidParam == null || uidParam === natomasCustomer.user_id) {
        if (isEmployeeEmail(Customer.getContactEmail(natomasCustomer))) {
          devLogger("avoiding admin project fetch");
        } else {
          getStore().dispatch(
            setConfigurationId(natomasCustomer.project_ids[0])
          );
        }
      }
    }
    return storeUserInState(natomasCustomer);
  }
};

export const fetchAndStoreNatomasUser = async (uid: string) => {
  const initialUser = (await Utilities.getDocData(
    getUsersRef(uid)
  )) as unknown as V0UserInfo;

  if (initialUser) {
    const natomasCustomer = v0UserToCustomerInfo(initialUser, uid);
    storeUserInState(natomasCustomer);
  }

  let userLeadInfo: NatomasUserInfo;
  let userInfo: NatomasUserInfo;
  subscribeWithCallback(getNatomasUserLeadInfoRef(uid), (data) => {
    userLeadInfo = data as NatomasUserInfo;
    mergeUserAndStore(userInfo, userLeadInfo);
  });
  subscribeWithCallback(getNatomasUsersRef(uid), (data) => {
    userInfo = data as NatomasUserInfo;
    mergeUserAndStore(userInfo, userLeadInfo);
  });
};

export const getUserInfoFromCID = async (cid: string | null) => {
  if (isBlankString(cid)) {
    return null;
  }

  const configuration = await Configuration.Service.getConfiguration(cid);
  if (configuration != null && configuration.uid != null) {
    return fetchAndStoreNatomasUser(configuration.uid);
  }

  return null;
};

export const setNatomasUser = async (uid: string, natUser: NatomasUserInfo) => {
  return Utilities.updateOrCreateDocumentInDB(getNatomasUsersRef(uid), natUser);
};

export const updateNatomasUserEvents = (
  uid: string,
  customer: ICustomerInfo,
  callback: any
) => {
  return setNatomasUserEvents(uid, Customer.getEvents(customer), callback);
};

export const setNatomasUserEvents = (
  uid: string,
  events: IEventInfo[],
  callback: any
) => {
  // @ts-ignore
  const natomasUserDBObject: NatomasUserInfo = {
    events: events,
  };
  return Utilities.updateOrCreateDocumentInDB(
    getNatomasUsersRef(uid),
    natomasUserDBObject
  )?.then(callback);
};

export const updateNatomasUserContactInfo = (
  uid: string,
  customer: ICustomerInfo,
  callback: any
) => {
  // @ts-ignore
  const natomasUserDBObject: NatomasUserInfo = {
    first_name: Customer.getFirstName(customer),
    last_name: Customer.getLastName(customer),
    phone: Customer.getPrimaryContactPhoneNumber(customer),
    default_address: addressDetailsToNatomasDefaultAddress(
      Customer.getContactAddress(customer)
    ),
  };

  return Utilities.updateOrCreateDocumentInDB(
    getNatomasUsersRef(uid),
    natomasUserDBObject
  )?.then(callback);
};

export const markUserAction = (uid: string, actionId: string, payload: any) => {
  return Utilities.updateOrCreateDocumentInDB(
    getUserActionCollectionRef(uid).doc(actionId),
    payload
  );
};

export const resetUserAction = (uid: string) => {
  return deleteCollection(getUserActionCollectionRef(uid));
};

export const fetchUserActions = (userId: string) => {
  return subscribeToCollectionWithCallback(
    getUserActionCollectionRef(userId),
    (actions) => {
      storeUserActionInfoInState(actions, userId);
    }
  );
};

export const fetchUser = async (uid: string, forceFetch: boolean) => {
  if (uid != null) {
    const currentUser = store.getState().global.user;
    if (!allowFetchFromUser(currentUser?.uid, uid, forceFetch)) {
      return;
    }
    if (isAdmin(currentUser) && uid === currentUser.uid) {
      return;
    }
    devLogger("fetching user: " + uid);
    fetchAndStoreNatomasUser(uid);
    fetchUserActions(uid);
    return syncUserInfoWithSalesForce(uid);
  }

  return getUserInfoFromCID(getParameter("cid", true));
};

const storeUserInState = (data: ICustomerInfo) => {
  if (data != null) {
    store.dispatch(userLoaded(data));
  }
};

const storeUserActionInfoInState = (actions: any, userId: string) => {
  if (actions != null) {
    store.dispatch(storeUserActions({uid: userId, actions: actions}));
  }
};
