import React from "react";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {
  PreProposalContainer,
  ProposalCard,
  ProposalImage,
  ProposalText,
  ProposalTitle,
  ProposalSubtitle,
  ProposalContainer,
  ProposalCardsTitle,
  PreProposalCallToActionContainer,
  PreProposalCallToActionImage,
  PreProposalCallToActionText,
  PreProposalCallToActionTitle,
  PreProposalCallToActionSubtitle,
  PreProposalCallToActionButton,
} from "../styles/preProposalStyles";
import consultation from "../../../../../assets/images/stock/consultation.png";
import designSession from "../../../../../assets/images/stock/design-session.png";
import finalizedProposal from "../../../../../assets/images/stock/finalized-proposal.png";
import sitePlan from "../../../../../assets/images/stock/site-plan.png";
import projectProposal from "../../../../../assets/images/stock/projectProposal.png";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {
  toPage,
  SUBMIT_ASSESSMENT_PAGE_ID,
} from "../../../_shared/navigation/PageNavigation";
import {usePage} from "../../../../_shared/hooks/usePage";
import {PaymentCard} from "../components/PaymentCard";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {Customer} from "@natomas/core";
import {isMobile} from "../../../../_shared/navigation";
import {IncludedTag} from "../../../../catalog/views/ProductView/styles";
import {AiFillCheckCircle} from "react-icons/ai";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {SiteWorkInfoCard} from "../components/SiteWorkInfoCard";

const IncludedLineItem = (props: {children: any}) => {
  return (
    <IncludedTag>
      <div style={{position: "absolute"}}>
        <AiFillCheckCircle />
      </div>
      <div style={{marginLeft: "20px"}}>{props.children}</div>
    </IncludedTag>
  );
};

const PROPOSAL_TILES = [
  {
    image: consultation,
    title: "Kick-off Consultation",
    subtitle: (
      <>
        <IncludedLineItem>Virtual siting of your home</IncludedLineItem>
        <IncludedLineItem> Review initial design options</IncludedLineItem>
      </>
    ),
  },
  {
    image: designSession,
    title: "Design session",
    subtitle: (
      <>
        <IncludedLineItem>
          Confirm layout, options, & upgrades with the help of your personal
          home consultant
        </IncludedLineItem>
      </>
    ),
  },
  {
    image: sitePlan,
    title: "Custom site-plan",
    subtitle: (
      <>
        <IncludedLineItem>
          Cost estimates for any site-specific work (tree-removal, utility
          upgrades, etc.)
        </IncludedLineItem>
      </>
    ),
  },
  {
    image: finalizedProposal,
    title: "Finalized proposal",
    subtitle: (
      <>
        <IncludedLineItem>
          Detailed explanation of design & site-work budget
        </IncludedLineItem>
        <IncludedLineItem>Next steps & timeline</IncludedLineItem>
      </>
    ),
  },
];

const ProposalInfoCard = (props: any) => {
  const {isNatMobile} = usePage();
  const {img, title, subtitle, index} = props;
  return (
    <ProposalCard isMobile={isNatMobile()}>
      <ProposalImage isMobile={isNatMobile()} imageSrc={img} />
      <ProposalText>
        <ProposalTitle>{`${index + 1}) ${title}`}</ProposalTitle>
        <ProposalSubtitle>{subtitle}</ProposalSubtitle>
      </ProposalText>
    </ProposalCard>
  );
};

export const PreProposalTab = () => {
  const dynamicWidth = useDynamicValue(fullColumnWidths);
  const {customer} = useCurrentCustomer();
  const {isNatMobile} = usePage();
  const {
    IS_PRE_DEPOSIT_SINGLE_PAGE,
    IS_SITE_WORK_ENABLED,
  } = useGlobalConfiguration();

  return (
    <PageContainer>
      {IS_SITE_WORK_ENABLED && <SiteWorkInfoCard showDetails={false} />}

      <PreProposalCallToActionContainer
        isMobile={isMobile()}
        isNatMobile={isNatMobile()}
        isSinglePage={IS_PRE_DEPOSIT_SINGLE_PAGE}
      >
        <PageElement size={dynamicWidth} height={"auto"}>
          <PreProposalCallToActionText>
            <PreProposalCallToActionTitle
              isSinglePage={IS_PRE_DEPOSIT_SINGLE_PAGE}
            >
              Get a detailed proposal
            </PreProposalCallToActionTitle>
            <PreProposalCallToActionSubtitle
              isSinglePage={IS_PRE_DEPOSIT_SINGLE_PAGE}
            >
              Your personalized site assessment requires time, energy, and
              resources from our experts across permitting, design/architecture,
              and construction.
            </PreProposalCallToActionSubtitle>
            <PreProposalCallToActionSubtitle
              isSinglePage={IS_PRE_DEPOSIT_SINGLE_PAGE}
            >
              To get your proposal started, we require a proposal fee which is
              fully-refundable at any time, for any reason, no questions asked.
            </PreProposalCallToActionSubtitle>
          </PreProposalCallToActionText>
          {!IS_PRE_DEPOSIT_SINGLE_PAGE && (
            <PreProposalCallToActionButton>
              <NatButton
                type={"button"}
                trackingId={"pre-proposal-get-your-proposal-started"}
                label={"Get your proposal started"}
                option={StyleOption.ColorWillDarken}
                clickEvent={() => {
                  toPage(SUBMIT_ASSESSMENT_PAGE_ID);
                }}
                spinnerEnabled={false}
              />
            </PreProposalCallToActionButton>
          )}
        </PageElement>
        {!IS_PRE_DEPOSIT_SINGLE_PAGE && (
          <PageElement
            size={dynamicWidth}
            height={isNatMobile() ? "10rem" : "22rem"}
          >
            <PreProposalCallToActionImage imageSrc={projectProposal} />
          </PageElement>
        )}
        {IS_PRE_DEPOSIT_SINGLE_PAGE && (
          <PaymentCard
            user={customer}
            clientReferenceIdProp={customer?.user_id}
            customerEmailProp={Customer.getContactEmail(customer)}
          />
        )}
      </PreProposalCallToActionContainer>
      <PreProposalContainer>
        <PageElement size={dynamicWidth} height={"2rem"}>
          <ProposalCardsTitle>
            With a detailed proposal, you’ll get:
          </ProposalCardsTitle>
        </PageElement>
        <PageElement size={dynamicWidth} height={"auto"}>
          <ProposalContainer>
            {PROPOSAL_TILES.map((card, index) => {
              return (
                <ProposalInfoCard
                  key={card.title}
                  title={card.title}
                  subtitle={card.subtitle}
                  img={card.image}
                  index={index}
                />
              );
            })}
          </ProposalContainer>
        </PageElement>
      </PreProposalContainer>
    </PageContainer>
  );
};
