import styled from "styled-components";
import {calculateBorder, INatStyle} from "../_shared";
import {DROPDOWN_Z_INDEX, NatStyle} from "../../styles";
import {
  DROPDOWN_ACCENT_COLOR,
  SLIDER_RAIL,
  SLIDER_THUMB,
  SLIDER_TRACK,
  WHITE,
} from "../../colors";
import {isMobile} from "../../navigation";
import {Slider} from "@mui/material";

export const SliderContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
`;

export const SliderLabel = styled.button<INatStyle>`
  ${NatStyle};
  border: ${(props) => calculateBorder(props.initialBorderColor)};
  border-radius: 3rem;
  background-color: ${(props) => !props.enabled && DROPDOWN_ACCENT_COLOR};
  &:hover {
    border: ${(props) => calculateBorder(props.hoverBorderColor)};
  }
`;

export const SliderContentContainer = styled.div<{show: boolean}>`
  display: ${(props) => (props.show ? "flex" : "none")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: ${isMobile() ? "fixed" : "absolute"};
  left: ${isMobile() && "0.5rem"};
  right: ${isMobile() && "0.5rem"};
  top: ${(props) => props.show && !isMobile() && "125%"};
  bottom: ${(props) => props.show && isMobile() && "0.5rem"};
  background-color: ${WHITE};
  border: 2px solid ${DROPDOWN_ACCENT_COLOR};
  border-radius: 0.5rem;
  z-index: ${DROPDOWN_Z_INDEX};
  transition: all 2000ms ease-in-out;
`;

export const SliderContent = styled.div`
  text-align: ${isMobile() ? "center" : "left"};
  background-color: white;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 3rem 2rem 1rem 2rem;
  width: ${isMobile() ? "100%" : "20rem"};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  border-radius: 0.5rem;
  button {
    margin-top: 1rem;
  }
`;

export const SliderButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const PriceRangeSlider = styled(Slider)({
  "color": "#52af77",
  "height": 8,
  "& .MuiSlider-track": {
    backgroundColor: SLIDER_TRACK,
    border: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: SLIDER_RAIL,
    opacity: 1,
    border: "none",
  },
  "& .MuiSlider-thumb": {
    "height": 20,
    "width": 20,
    "backgroundColor": SLIDER_THUMB,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    "lineHeight": 1.2,
    "fontSize": 14,
    "fontFamily": "Maison-Bold, sans-serif",
    "background": "unset",
    "padding": 0,
    "width": 52,
    "height": 20,
    "borderRadius": 8,
    "backgroundColor": "white",
    "color": "black",
    "transformOrigin": "bottom left",
    "&:before": {display: "none"},
  },
});
