import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {saveProductGroupInformation} from "../../../../design-tool/backend/catalogApi";
import {
  getIntegerValue,
  getPriceMicrosFromText,
  getTextFromPriceMicros,
  isBlankString,
} from "@natomas/core";
import {readDeepValue, setDeepValue} from "../../../../_shared/Utils";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {
  AdminForm,
  MainContent,
  MainView,
  ViewOptions,
} from "../../styles/AdminStyles";
import {AdminInput} from "../../components/AdminComponentFactory";
import {EditorHeader} from "../../components/EditorHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {
  getAvailableCounties,
  ProductGroupAdvancedStructure,
  ProductGroupStructure,
} from "../../../../design-tool/models/ProductGroupModel";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";

export const getBlankItemSection = (items: any) => {
  return {
    initial: {
      items: items,
      index: 0,
      title: "General",
    },
  };
};

const productStructureForForm = getFormStructure(ProductGroupStructure);

const getInitialValues = (obj: any) => {
  const structure = ProductGroupStructure;
  const initialValues = getFormInitialValues(structure);
  if (obj != null) {
    for (const key in structure) {
      // @ts-ignore
      const keyInfo = structure[key];
      let initialValue = obj[key];
      if (keyInfo.path != null) {
        initialValue = readDeepValue(obj, keyInfo.path);
      }

      if (initialValue != null) {
        if (keyInfo.subType === "price") {
          initialValue = getTextFromPriceMicros(initialValue);
        } else if (typeof initialValue === "number") {
          initialValue = "" + initialValue;
        }
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProductGroup = () => {
  const {productGroup} = useProductCatalog();

  const tabs = [
    {id: "details", title: productGroup?.internal_title},
    {id: "advanced", title: "Advanced Details"},
  ];
  const [tab, setTab] = useState(tabs[0].id);

  const initialValues = getInitialValues(productGroup);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      let valueToSave = {};
      for (const key in values) {
        // @ts-ignore
        let value = values[key];
        // @ts-ignore
        const keyInfo = ProductGroupStructure[key];
        if (keyInfo.subType === "price") {
          value = getPriceMicrosFromText(value);
        } else if (keyInfo.type === "number") {
          value = getIntegerValue(value);
        }

        if (keyInfo.path != null) {
          setDeepValue(valueToSave, keyInfo.path, value);
        } else {
          // @ts-ignore
          valueToSave[key] = value;
        }
      }

      // @ts-ignore
      if (isBlankString(valueToSave?.imageId)) {
        // @ts-ignore
        valueToSave["imageId"] = null;
      }
      return saveProductGroupInformation(valueToSave);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [productGroup]);

  if (productGroup == null) {
    return null;
  }

  let formStructure = productStructureForForm.filter((keyInfo) => {
    // @ts-ignore
    return ProductGroupAdvancedStructure[keyInfo.key] == null;
  });

  if (tab === "advanced") {
    formStructure = productStructureForForm.filter((keyInfo) => {
      // @ts-ignore
      return ProductGroupAdvancedStructure[keyInfo.key] != null;
    });
  }

  const viewInCatalog = () => {
    window.open(window.location.origin + "/products?gid=" + productGroup?.id);
  };

  const viewInDesignStudio = () => {
    window.open(window.location.origin + "/design?gid=" + productGroup?.id);
  };

  const content = (
    <AdminForm onSubmit={formik.handleSubmit}>
      {formStructure.map((keyInfo) => {
        const {key} = keyInfo;
        // @ts-ignore
        const value = formik.values[key];
        if (key === "configuratorPages") {
          return null;
        }
        if (key === "counties") {
          // @ts-ignore
          if (formik.values["states"].length === 0) {
            // @ts-ignore
            formik.values["counties"] = [];
            return null;
          } else {
            // @ts-ignore
            let sts = formik.values["states"];
            keyInfo.options = getAvailableCounties(sts);
          }
        }

        // @ts-ignore
        let initial = initialValues[key];

        return (
          <AdminInput
            key={key}
            value={value}
            fieldInfo={keyInfo}
            initialValue={initial}
            onChange={(value: any) => {
              formik.setFieldValue(key, value);
            }}
            handleChange={formik.handleChange}
          />
        );
      })}
    </AdminForm>
  );
  return (
    <MainView>
      <EditorHeader
        tabs={tabs}
        setTab={setTab}
        isUpToDate={isDeepEqual(formik.values, initialValues)}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        viewAction={
          <ViewOptions>
            <NatButton
              clickEvent={viewInCatalog}
              option={StyleOption.SECONDARY}
              label={"Catalog View"}
              trackingId={"admin-view-group-in-catalog"}
              type={"button"}
              size={TextSize.SMALL}
            />
            <NatButton
              clickEvent={viewInDesignStudio}
              option={StyleOption.SECONDARY}
              label={"Design Studio View"}
              trackingId={"admin-view-group-in-design-studio"}
              type={"button"}
              size={TextSize.SMALL}
            />
          </ViewOptions>
        }
        deleteAction={null}
        saveAction={formik.handleSubmit}
      />
      <MainContent>{content}</MainContent>
    </MainView>
  );
};
