import React from "react";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";
import {
  ProductCardTitle,
  ProductCardImage,
  ProductCardInfoRow,
  ProductContents,
  HoverOverImage,
  ProductCardPriceRow,
} from "./styles";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {getKStringFromMicros} from "@natomas/core";

interface ProductCardProps {
  id: string;
  imageId: string;
  hoverImageId: string | null;
  title: string;
  bedroomCount: number;
  bathroomCount: number;
  squareFootage: number;
  priceMicros: number;
  clickEvent: (id: string) => void;
  columnWidth: number;
  tagline: string;
}

// Show main product specific information
const ProductCardComponent = (props: ProductCardProps) => {
  const {
    id,
    imageId,
    hoverImageId,
    title,
    bedroomCount,
    bathroomCount,
    squareFootage,
    priceMicros,
    clickEvent,
    columnWidth,
    tagline,
  } = props;

  const height = useDynamicValue({
    forFour: "12rem",
    forEight: "12rem",
    forTwelve: "12rem",
  });

  const getPriceString = (price: number) => {
    return "From " + getKStringFromMicros(price);
  };

  return (
    <PageElement size={columnWidth} height={"auto"}>
      <ProductContents onClick={() => clickEvent(id)}>
        <ProductCardImage height={height}>
          <SmartImageWithId imageId={imageId} />
          {hoverImageId && (
            <HoverOverImage>
              <SmartImageWithId
                blur={false}
                style={{objectFit: "contain"}}
                imageId={hoverImageId}
              />
            </HoverOverImage>
          )}
        </ProductCardImage>
        <ProductCardTitle>{title}</ProductCardTitle>
        <ProductCardInfoRow>
          {bedroomCount} bed | {bathroomCount} bath | {squareFootage} sq. ft.
        </ProductCardInfoRow>
        <ProductCardPriceRow>{getPriceString(priceMicros)}</ProductCardPriceRow>
        {/*{tagline && <ProductCardInfoRow>{tagline}</ProductCardInfoRow>}*/}
      </ProductContents>
    </PageElement>
  );
};

export const ProductCard = React.memo(ProductCardComponent);
