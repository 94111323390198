import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useCurrentProject} from "../../../../../_shared/hooks/useCurrentProject";
import {ConditionalRendering} from "../../../../_shared/components/ConditionalRendering";
import {useFormik} from "formik";
import {updateNatomasUserContactInfo} from "../../../../../../database/firebase/user";
import {getFormInitialValues} from "../../../../../_shared/application";
import {
  ADDRESS_KEY,
  CUSTOMER_DETAILS_FORM,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  PHONE_NUMBER_KEY,
} from "../../../../../_shared/forms/FormModels";
import {sendWelcomeEmail} from "../../../../../../database/firebase/email";
import {PersonalInfoRow} from "../PersonalInfoRow";
import profile from "../../../../../../assets/icons/pngs/profile_circle.png";
import mail from "../../../../../../assets/icons/pngs/mail_circle.png";
import phone from "../../../../../../assets/icons/pngs/phone_circle.png";
import pin from "../../../../../../assets/icons/pngs/pin_circle.png";
import {Utilities} from "../../../../../../database/firebase";
import {useGlobalConfiguration} from "../../../../../_shared/hooks/useGlobalConfiguration";
import {
  Address,
  Customer,
  formatPhoneNumber,
  IAddressDetails,
  ICustomerInfo,
  PhoneNumberFormatType,
} from "@natomas/core";
import {constructValidation} from "../../../../../_shared/forms/FormHelper";
import {useDeepEffect} from "../../../../../_shared/hooks/useDeepEffect";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
//Styles
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {ButtonContainer, SectionHeader} from "../../styles";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";

const lineItems = [
  {title: "Name", logo: profile, type: "name"},
  {title: "Email", logo: mail, type: "email"},
  {title: "Phone", logo: phone, type: "phone"},
  {title: "Address", logo: pin, type: "address"},
];

const getNewCustomerInfo = (
  customer: ICustomerInfo,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  address: IAddressDetails
) => {
  let newCustomer;
  newCustomer = Customer.setFirstName(customer, firstName);
  newCustomer = Customer.setLastName(newCustomer, lastName);
  newCustomer = Customer.setPrimaryContactPhoneNumber(
    newCustomer,
    formatPhoneNumber(phoneNumber, PhoneNumberFormatType.RAW) ?? ""
  );
  newCustomer = Customer.setContactAddress(newCustomer, address);
  return newCustomer;
};

const getValue = (customer: ICustomerInfo, title: string) => {
  if (!customer) return null;
  if (title === "Name") {
    return Customer.getFullName(customer);
  } else if (title === "Email") {
    return Customer.getContactEmail(customer);
  } else if (title === "Phone") {
    return formatPhoneNumber(
      Customer.getPrimaryContactPhoneNumber(customer),
      PhoneNumberFormatType.PARENTHESIS_AND_DASHES
    );
  } else if (title === "Address") {
    return Address.getFullAddress(Customer.getContactAddress(customer));
  }
};

export const AccountDetailsForm = () => {
  const {IS_SSD_LIVE} = useGlobalConfiguration();
  // @ts-ignore
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {currentConfigurationId} = useCurrentProject();
  const {customer} = useCurrentCustomer();
  const [editInfo, setEditInfo] = useState(false);
  const [welcomeEmailAlreadySent, setWelcomeEmailAlreadySent] = useState(false);
  const [addressObject, setAddressObject] = useState<IAddressDetails | null>(
    null
  );
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });

  const setFormikValues = () => {
    if (!customer) return;
    formik.setFieldValue(FIRST_NAME_KEY, Customer.getFirstName(customer));
    formik.setFieldValue(LAST_NAME_KEY, Customer.getLastName(customer));
    formik.setFieldValue(EMAIL_KEY, Customer.getContactEmail(customer));
    formik.setFieldValue(ADDRESS_KEY, Customer.getContactAddress(customer));
    formik.setFieldValue(
      PHONE_NUMBER_KEY,
      Customer.getPrimaryContactPhoneNumber(customer)
    );
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(CUSTOMER_DETAILS_FORM),
    validationSchema: constructValidation(CUSTOMER_DETAILS_FORM),
    onSubmit: (values) => {
      // @ts-ignore
      const {firstName, lastName, phoneNumber} = values;
      setEditInfo(false);
      const newCustomerInfo = getNewCustomerInfo(
        customer,
        firstName,
        lastName,
        phoneNumber,
        addressObject ?? Customer.getContactAddress(customer)
      );

      return updateNatomasUserContactInfo(
        customer.user_id,
        newCustomerInfo,
        null
      );
    },
  });
  useDeepEffect(() => {
    // Everytime user updates - update form
    setFormikValues();
    if (customer) setAddressObject(Customer.getContactAddress(customer));
  }, [IS_SSD_LIVE, customer]);

  useDeepEffect(() => {
    if (customer != null && editInfo) {
      setFormikValues();
    }

    if (customer != null && adminMode) {
      fetchWelcomeEmail();
    }
  }, [customer, editInfo, adminMode]);

  const fetchWelcomeEmail = () => {
    Utilities.getDocData(
      Utilities.collection("sentEmailTemplateIds").doc(customer.user_id)
    ).then((result) => {
      const alreadySentEmail =
        result != null &&
        result[
          currentConfigurationId + "_d-cb5f81b10a284125875b846d338117c7"
        ] === true;
      setWelcomeEmailAlreadySent(alreadySentEmail);
    });
  };

  return (
    <PageElement size={size} height={"auto"}>
      <SectionHeader>Account Information</SectionHeader>
      {lineItems.map((lineItem, index) => (
        <PersonalInfoRow
          {...lineItem}
          type={lineItem.type}
          key={"personal-info-" + index}
          value={getValue(customer, lineItem.title)}
          editInfo={editInfo}
          formik={formik}
          setAddressObject={setAddressObject}
        />
      ))}
      <ButtonContainer>
        <ConditionalRendering
          mode={"admin-activated"}
          approvedRender={
            <button
              className={
                welcomeEmailAlreadySent
                  ? "button-secondary"
                  : "button-primary bold"
              }
              onClick={() => {
                if (customer != null && currentConfigurationId != null) {
                  setWelcomeEmailAlreadySent(true);
                  return sendWelcomeEmail(
                    customer.user_id,
                    currentConfigurationId
                  ).then(() => {
                    return fetchWelcomeEmail();
                  });
                }
              }}
              style={{marginRight: "30px"}}
            >
              {welcomeEmailAlreadySent
                ? "Re-send Welcome Email"
                : "Send Welcome Email"}
            </button>
          }
          deniedRender={null}
        />
        <ConditionalRendering
          mode={"owner"}
          approvedRender={
            <NatButton
              type={"button"}
              trackingId={
                "account-details-" + editInfo ? "discard-changes" : "edit-info"
              }
              label={editInfo ? "Discard Changes" : "Edit Info"}
              clickEvent={() => {
                setEditInfo(!editInfo);
              }}
              option={StyleOption.SECONDARY}
            />
          }
          deniedRender={null}
        />
        <ConditionalRendering
          mode={"owner"}
          show={editInfo}
          approvedRender={
            <NatButton
              clickEvent={() => {
                return formik.submitForm();
              }}
              disabled={
                Object.keys(formik.errors).length !== 0 || !addressObject
              }
              trackingId={"account-details-save-changes"}
              label={"Save Changes"}
              type={"button"}
              option={StyleOption.PRIMARY}
              spinnerEnabled={true}
            />
          }
        />
      </ButtonContainer>
    </PageElement>
  );
};
