import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setShowConfirmation} from "../slices/configurationSlice";
import PuffLoader from "react-spinners/PuffLoader";
import {IntakeForm} from "../../_shared/generics/IntakeForm";
import {isMarketingPage, toPortal} from "../../_shared/navigation";
import HoverRating from "./FeedbackInput";
import {submitFeedback} from "../backend/ConfigurationAPI";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {
  OVERVIEW_ASSESSMENT_PAGE_ID,
  toPage,
} from "../../portal/_shared/navigation/PageNavigation";
import {IStore} from "../../_shared/slices/types/Store";
import {GenericModal} from "../../_shared/generics/GenericModal";
import {StyleOption} from "../../_shared/generics/_shared";

export const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(
    (state: IStore) => state.configuration.fetchedConfiguration
  );
  const {customer} = useCurrentCustomer();
  const saveInProgress = useSelector(
    (state: IStore) => state.configuration.saveInProgress
  );
  const show = useSelector(
    (state: IStore) => state.configuration.showConfirmation
  );
  const [submittingIntake, setSubmittingIntake] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const {STREAMLINED_JOURNEY} = useGlobalConfiguration();
  const {isCustomerPreDeposit} = useCurrentCustomer();
  const isStreamlined = STREAMLINED_JOURNEY && isCustomerPreDeposit;

  useEffect(() => {
    setSubmittingIntake(false);
  }, [show]);

  const handleClose = () => {
    dispatch(setShowConfirmation(false));
    if (feedback != null && configuration != null && configuration.id != null) {
      submitFeedback(configuration, feedback);
    }
  };

  const toDashboard = () => {
    handleClose();
    toPortal();
  };

  let content = null;
  let shareLink: any = "";
  if (configuration != null) {
    shareLink = (
      <>
        {
          "Your changes have been successfully saved. Please note that the design costs only include the unit costs, and our team is actively working on finalizing your total project cost based on the current selections."
        }
      </>
    );
  }

  let titleText = "Design Saved!";
  if (saveInProgress) {
    titleText = "Saving your design...";
    shareLink = <PuffLoader size="45px" />;
    content = null;
  }

  if (
    show &&
    (customer?.user_id == null || submittingIntake) &&
    isMarketingPage()
  ) {
    return (
      <IntakeForm
        submittingIntake={submittingIntake}
        setSubmittingIntake={setSubmittingIntake}
      />
    );
  }

  const modalContent = (
    <>
      <p style={{whiteSpace: "pre-wrap"}}>{shareLink}</p>
      <br />
      {content}
      <HoverRating
        callback={(feedback: any) => {
          setFeedback(feedback);
        }}
      />
    </>
  );

  const primaryButtonText = isCustomerPreDeposit
    ? "View instant quote"
    : "Continue to proposal";
  const isSaving =
    configuration == null || configuration.id == null || saveInProgress;

  const footerButtons = [
    {label: "Keep designing", trackingId: "keep-designing", disabled: isSaving},
    {
      label: primaryButtonText,
      action: () => {
        toDashboard();
        if (isStreamlined) {
          toPage(OVERVIEW_ASSESSMENT_PAGE_ID);
        }
      },
      option: StyleOption.PRIMARY,
      trackingId: "continue-to-proposal",
    },
  ];

  return (
    <GenericModal
      show={show}
      handleClose={handleClose}
      header={titleText}
      content={modalContent}
      footerButtons={footerButtons}
    />
  );
};
