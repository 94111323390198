import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {isAdmin} from "../../../../_shared/user/UserUtils";
import {ImageUploader} from "../../../../design-tool/components/ImageUploader";
import {Utilities} from "../../../../../database/firebase";
import {SitePlanImagePreview, WaitingContent} from "../styles";
import waiting_graphic from "../../../../../assets/images/graphics/waiting_graphic.png";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";
import React from "react";
import {isMobile} from "../../../../_shared/navigation";

export const SitePlanContent = (props) => {
  const {user, editing, sitePlanImageId} = props;
  const {sitePlanImages, currentConfigurationId} = useCurrentProject();

  // Show image uploader for admin
  if (isAdmin(user) && (editing || sitePlanImageId == null)) {
    return (
      <div>
        <div style={{width: "100%", height: "400px"}}>
          <ImageUploader
            drop={true}
            imageIdValue={sitePlanImageId}
            replaceImageReference={sitePlanImageId != null}
            onComplete={(imageId) => {
              let payload;
              if (imageId) {
                payload = {
                  sitePlan: {
                    images: [...sitePlanImages, imageId],
                    imageId: imageId,
                  },
                };
              } else {
                payload = {
                  sitePlan: {
                    images: sitePlanImages.filter(
                      (imageId) => imageId !== sitePlanImageId
                    ),
                  },
                };
              }
              return Utilities.saveOrUpdateDocument(
                Utilities.collection("configurationSites"),
                currentConfigurationId,
                payload
              );
            }}
          />
        </div>
      </div>
    );
  }

  // Show waiting graphic for customer
  if (sitePlanImageId == null) {
    return (
      <WaitingContent>
        <img
          src={waiting_graphic}
          alt={"Site Plan Image Missing"}
          width={"240px"}
        />
        <br />
        <div className={"large-text bold"} style={{marginBottom: "10px"}}>
          Waiting for Site Plan
        </div>
        <div style={{marginBottom: "24px"}}>
          Our sales team will upload your site plan once it's ready.
        </div>
      </WaitingContent>
    );
  }

  // Show full site plan image
  return (
    <SitePlanImagePreview isMobile={isMobile()}>
      <SmartImageWithId
        style={{
          width: "100%",
          objectFit: "contain",
          height: "100%",
        }}
        imageId={sitePlanImageId}
      />
    </SitePlanImagePreview>
  );
};
