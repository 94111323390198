import styled from "styled-components";
import {
  SIDEBAR_CONTAINER_Z_INDEX,
  SIDEBAR_NAV_Z_INDEX,
} from "../../../../_shared/styles/";
import {isMobile} from "../../../../_shared/navigation";
import {GRAY, WHITE} from "../../../../_shared/colors";

export const SideBarContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: ${(props) => (props.isExpanded ? "0" : "unset")};
  bottom: 0;
  z-index: ${SIDEBAR_CONTAINER_Z_INDEX};
  pointer-events: none;
  color: white;

  .link-text {
    display: block;
  }

  .unclickable {
    visibility: ${(props) => (props.isExpanded ? "" : "hidden")};
    opacity: ${(props) => (props.isExpanded ? 1 : 0)};
    transition: 400ms ease-in-out;
  }
`;

export const SideBarRow = styled.div`
  width: 100%;
  overflow: hidden;
  border-bottom: ${(props) =>
    props.hasBorder ? "rgba(255,255,255,0.2) solid 1px" : ""};
`;

export const SideBarNavigation = styled.div`
  pointer-events: all;
  width: ${isMobile()
    ? "100%"
    : (props) => (props.isExpanded ? "20rem" : "4rem")};
  transition: width 400ms ease-in-out;
  height: 100%;
  z-index: ${SIDEBAR_NAV_Z_INDEX};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #2a3639;
`;

export const SideBarLogo = styled.div`
  width: 100%;
  height: 64.5px;
  position: relative;
  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
  overflow: hidden;
  cursor: pointer;
`;

export const SideBarMenuItemContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "50px")};
  position: relative;
  overflow-x: hidden;
  padding-left: 1rem;
  cursor: ${(props) => (props.enabled === true ? "pointer" : "default")};
  background-color: ${(props) =>
    props.selected === true ? "rgba(255, 255, 255, 0.1)" : "#2a3639"};
  border-right: ${(props) =>
    props.selected === true ? "3px solid white" : "0"};
  font-family: ${(props) =>
    props.selected === true ? "Maison-Bold" : "Maison-Book"};
  opacity: ${(props) => (props.enabled === true ? 1 : 0.5)};
`;

export const BottomMenuArea = styled.div`
  margin-top: auto;
  position: relative;
`;

export const BottomMenuListContainer = styled.div`
  width: 100%;
  text-align: left;
  transition: opacity 400ms ease-in-out;
  padding: 0 1.3rem;
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  pointer-events: ${(props) => (!props.isExpanded ? "none" : "auto")};
  margin-bottom: 24px;
`;

export const BottomMenuListItem = styled.div`
  margin-top: 10px;
  cursor: pointer;
  text-align: left;
  font-family: "Maison-Bold", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
`;

export const BottomMenuContainer = styled.div`
  height: 4rem;
  transition: height 400ms ease-in-out;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
  position: absolute;
  right: 2px;
  bottom: 6px;
`;

export const BottomMenuItem = styled.div`
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${(props) =>
    props.willRotate && props.isExpanded ? "rotate(-180deg)" : ""};
  transition: 400ms ease-in-out;
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const MenuItemContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const MenuItemIcon = styled.div`
  width: 2rem;
  position: relative;
  font-size: 2rem;
`;

export const ExpandMenuIconFloating = styled.div`
  transform: ${(props) => (props.isExpanded ? "rotate(-180deg)" : "")};
  transition: 400ms ease-in-out;
  width: 24px;
  position: absolute;
  right: 20px;
  bottom: 8px;
  font-size: 26px;
  cursor: pointer;
`;

export const MenuItemTitle = styled.div`
  width: 20px;
  padding-left: 22px;
  flex-grow: 3;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: ${(props) => (props.enabled ? WHITE : GRAY)};
`;

export const SideBarLogoImage = styled.img`
  position: absolute;
  left: 55px;
  top: -6px;
  width: 80px;
`;

export const SideBarLogoImageNoText = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 65px;
`;

export const AvatarContainer = styled.div`
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
`;
