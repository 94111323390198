import React from "react";

export const Image = (props) => {
	const {imageSrc, preserveAspectRatio} = props;

	return (
		<img
			id="Background"
			width="100%"
			height="100%"
			style={{objectFit: "cover"}}
			src={imageSrc}
		/>
	);
};
