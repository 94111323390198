import React from "react";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import "react-awesome-lightbox/build/style.css";
import {BottomContainer, SitePlanContainer} from "./styles";
import {SitePlan} from "./components/SitePlan";
import {MappingToolPreview} from "./components/MappingToolPreview";
import {useSelector} from "react-redux";
import {isAdmin} from "../../../_shared/user/UserUtils";

export const SitePlanView = () => {
  const {configurationMap, sitePlanImages} = useCurrentProject();

  const adminMode = useSelector((state) => state.portal.adminMode);
  const user = useSelector((state) => state.global.user);
  // let {fullAddress, googleMapsURL} = useConfigurationLocation();

  let mappingToolPreview = null;
  if (configurationMap != null && false) {
    // TODO enhance when self serve is used.
    mappingToolPreview = (
      <BottomContainer>
        <MappingToolPreview />
      </BottomContainer>
    );
  }

  const sitePlanComponents = sitePlanImages.map((sitePlanImageId, index) => {
    return (
      <>
        <SitePlan
          key={sitePlanImageId}
          sitePlanImageId={sitePlanImageId}
          index={index + 1}
        />
      </>
    );
  });

  if ((adminMode && isAdmin(user)) || sitePlanImages.length < 1) {
    sitePlanComponents.push(
      <SitePlan
        key={"newSitePlanImage" + sitePlanComponents.length}
        sitePlanImageId={null}
        index={sitePlanComponents.length + 1}
      />
    );
  }

  return (
    <SitePlanContainer>
      {/*<SiteAddressContainer>*/}
      {/*	<SiteAddressImage src={googleMapsURL} />*/}
      {/*	<div style={{flexGrow: 1, padding: "10px"}}>*/}
      {/*		<div className={"large-text bold"}>Property Address</div>*/}
      {/*		<div style={{display: "flex", alignItems: "center"}}>*/}
      {/*			<FiMapPin />*/}
      {/*			<div style={{marginLeft: "6px"}}>{fullAddress}</div>*/}
      {/*		</div>*/}
      {/*	</div>*/}
      {/*</SiteAddressContainer>*/}
      {sitePlanComponents}
      {mappingToolPreview}
    </SitePlanContainer>
  );
};
