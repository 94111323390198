import {useEffect, useState} from "react";
import ColorPicker from "material-ui-color-picker";

export const ColorPickerComp = ({value, onChange, keyName}) => {
	const [color, setColor] = useState(value);
	const handleChange = (color) => {
		setColor(color);
		onChange(color);
	};

	useEffect(() => {
		setColor(value);
	}, [value]);

	return (
		<div
			key={keyName}
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-start",
				alignItems: "center",
				padding: 10,
			}}
		>
			<ColorPicker
				name={keyName}
				value={color != null ? color : ""}
				id={keyName}
				onChange={handleChange}
				InputProps={{value: color}}
			/>
		</div>
	);
};
