import {ICustomerInfo} from "@natomas/core/application/entities/CustomerInfo";
import {Customer} from "@natomas/core";

export function getGreeting(customer: ICustomerInfo) {
  const firstName = Customer.getFirstName(customer);
  if (firstName) {
    return `Dear ${firstName},`;
  }
  return `Welcome!`;
}
