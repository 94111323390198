import styled from "styled-components";

export const Card = styled.div`
  height: inherit;
  border-radius: 1rem;
  color: white;
`;

export const CardContainer = styled.div`
  //Positioning the card on top of the background
  position: relative;
  top: -100%;
  z-index: 10;

  //Scale card to match parent
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const CardDetailsContainer = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const CardTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
`;

export const CardDescription = styled.p`
  font-size: 1rem;
  text-shadow: rgba(0, 0, 0, 0.5) 0.08rem 0.08rem;
  //Remove auto margin from p
  margin: 0;
`;

export const CardPriceRange = styled.p`
  font-size: 1rem;
  text-shadow: rgba(0, 0, 0, 0.5) 0.08rem 0.08rem;
  //Auto-scale to put the button at the bottom
  margin-top: auto;
`;

export const CardButtonContainer = styled.div``;

export const CardBackground = styled.div`
  //Scale background image to fit card
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  position: relative;

  //White while loading...
  background: white;

  //inherit border radius from parent card
  border-radius: inherit;
  .fullDiv {
    border-radius: inherit;
  }
`;
