import {
  SitePlanButton,
  SitePlanButtonContainer,
  SitePlanComponentContainer,
} from "../styles";
import download from "../../../../../assets/icons/pngs/download-16.png";
import zoom from "../../../../../assets/icons/pngs/zoom-16.png";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useImage} from "../../../../_shared/hooks/useImage";
import {useConfigurationLocation} from "../../../_shared/hooks/useConfigurationLocation";
import {isMobile} from "../../../../_shared/navigation";
import {isBlankString} from "@natomas/core";
import {downloadImageToDesktop} from "../logic";
import Lightbox from "react-awesome-lightbox";
import {SitePlanContent} from "./SitePlanContent";
import {LightboxContainer} from "../../../../catalog/views/ProductView/components/styles";

export const SitePlan = (props) => {
  const {sitePlanImageId, index} = props;
  const adminMode = useSelector((state) => state.portal.adminMode);
  const user = useSelector((state) => state.global.user);
  const [fullScreenImage, setFullScreenImage] = useState(false);

  const sitePlanImage = useImage(sitePlanImageId);

  const {address} = useConfigurationLocation();
  const filename =
    (isBlankString(address) ? "" : address.split(" ").join("_") + "_") +
    "SitePlan.png";
  const url = sitePlanImage == null ? "" : sitePlanImage.original.url;

  return (
    <SitePlanComponentContainer>
      <div
        className={"left-right"}
        style={{
          marginBottom: "25px",
        }}
      >
        <div
          className={"medium"}
          style={{
            textAlign: "left",
            fontSize: "25px",
            flexGrow: 2,
            minWidth: "102px",
          }}
        >
          {index < 2 ? `Site Plan` : `Site Plan ${index}`}
        </div>
        <SitePlanButtonContainer hideElement={sitePlanImage == null}>
          <SitePlanButton
            onClick={() => {
              const url = sitePlanImage.original.url;
              downloadImageToDesktop(url, filename);
            }}
          >
            <img height={"16px"} src={download} />
            <div style={{marginLeft: "6px", marginTop: "2px"}}>
              {isMobile() ? "Save" : "Download"}
            </div>
          </SitePlanButton>
          <SitePlanButton
            onClick={() => {
              setFullScreenImage(true);
            }}
          >
            <img src={zoom} height={"16px"} />
            <div style={{marginLeft: "6px", marginTop: "2px"}}>
              {isMobile() ? "Zoom" : "Full Screen"}
            </div>
          </SitePlanButton>
        </SitePlanButtonContainer>
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => {
          if (sitePlanImage != null && !adminMode) {
            setFullScreenImage(true);
          }
        }}
      >
        <SitePlanContent
          sitePlanImageId={sitePlanImageId}
          user={user}
          editing={adminMode}
        />
      </div>
      <LightboxContainer hidden={fullScreenImage !== true}>
        <Lightbox
          image={url}
          onClose={() => {
            setFullScreenImage(false);
          }}
        />
      </LightboxContainer>
    </SitePlanComponentContainer>
  );
};
