import {isBlankString} from "@natomas/core";

export const getFirstAndLastFromFullName = (fullname) => {
  let firstName = null;
  let lastName = null;
  if (fullname != null) {
    const splits = fullname.split(" ");
    if (splits.length > 0) {
      firstName = splits[0];
    }
    if (splits.length > 1) {
      lastName = splits[1];
    }
  }

  return {firstName, lastName};
};

// TODO TeamMemberContactInfo is dependent on this but once it implements PersonInfo we should deprecate this.
export function getFullNameWithFirstAndLast(firstNameInput, lastNameInput) {
  let firstName = null;
  let lastName = null;
  let fullName = null;
  if (!isBlankString(firstNameInput)) {
    firstName = firstNameInput;
    fullName = firstName;
  }
  if (!isBlankString(lastNameInput)) {
    lastName = lastNameInput;
    if (fullName != null) {
      fullName = fullName + " " + lastName;
    } else {
      fullName = lastName;
    }
  }
  return fullName;
}

export function isAdmin(user) {
  return (
    user != null &&
    user.email != null &&
    isEmployeeEmail(user.email) &&
    user.emailVerified === true
  );
}

export function isEmployeeEmail(email) {
  if (!email) {
    return false;
  }
  return (
    email.search(/^[^@]+@habitatadu.com$/) >= 0 ||
    email.search(/^[^@]+@villahomes.com$/) >= 0
  );
}
