import React from "react";
import {isMobile} from "../../../navigation";
import {NatomasBackdrop} from "./styles";

export interface NatBackdropPanelProps {
  show: boolean;
  disabled: boolean;
  clickEvent?: () => void;
}

export const NatBackdropPanel = (props: NatBackdropPanelProps) => {
  const {show, disabled, clickEvent} = props;
  return (
    <NatomasBackdrop
      show={show && !disabled}
      showColor={isMobile()}
      onClick={() => clickEvent && clickEvent()}
    />
  );
};
