import React from "react";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
import {
  WebinarBottomContent,
  WebinarContainer,
  WebinarFooter,
  WebinarModalContent,
  WebinarTitle,
  FooterButtonsContainer,
  WebinarSubtitle,
  WebinarContainerDark,
} from "../styles/webinar";

export const WebinarModal = (props: any) => {
  //grabs the step that controls which tab to open on the modal pop-up
  const {
    description,
    handleClose,
    header,
    content,
    footer,
    footerButtons,
    modalIsOpen,
    videoWidth,
  } = props;
  let footerContent = footer;
  if (footerButtons) {
    footerContent = (
      <FooterButtonsContainer>
        {footerButtons.map((footerButton: any, index: number) => {
          const option = footerButton.option ?? StyleOption.SECONDARY;
          const action = footerButton.action ?? handleClose;

          return (
            <NatButton
              key={"webinar-footer-button-" + index}
              trackingId={footerButton.trackingId ?? "webinar-footer-button"}
              label={footerButton.label}
              type={"button"}
              option={option}
              clickEvent={action}
            />
          );
        })}
      </FooterButtonsContainer>
    );
  }
  return (
    <>
      {modalIsOpen && (
        <WebinarContainer>
          <WebinarContainerDark onClick={handleClose} />
          <WebinarModalContent
            style={{
              width: videoWidth + "vw",
            }}
          >
            {content}
            <WebinarBottomContent>
              <WebinarTitle>{header}</WebinarTitle>
              <WebinarSubtitle>{description}</WebinarSubtitle>
              <WebinarFooter>{footerContent}</WebinarFooter>
            </WebinarBottomContent>
          </WebinarModalContent>
        </WebinarContainer>
      )}
    </>
  );
};
