import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ProgressiveImage from "react-progressive-graceful-image";
import {isMobile} from "../../_shared/navigation";
import PuffLoader from "react-spinners/PuffLoader";
import {fetchImage} from "../logic/data";

const omit = (obj, omitKey) =>
	Object.keys(obj).reduce((result, key) => {
		if (key !== omitKey) {
			result[key] = obj[key];
		}
		return result;
	}, {});

const fetchedImages = {};

export const SmartImageWithId = (props) => {
	const {imageId, hideLoading, caption, hideCaption, style} = props;
	const image = useSelector((state) => state.global.images[imageId]);
	const [smallImageLoaded, setSmallImageLoaded] = useState(false);
	const shouldFade = useSelector(
		(state) => !state.configuration.firstTouchOnPage
	);
	const [missingImageId, setMissingImageId] = useState(null);

	useEffect(() => {
		setSmallImageLoaded(false);
		if (image == null) {
			setMissingImageId(imageId);
			if (fetchedImages[imageId] !== true) {
				fetchedImages[imageId] = true;
				fetchImage(imageId, false);
			}
		} else if (missingImageId != null && missingImageId !== imageId) {
			setMissingImageId(null);
		}
	}, [image, imageId]);

	const shouldFadeImage = missingImageId === imageId;

	if (image == null) {
		if (hideLoading === true) {
			return null;
		}
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-22px, -22px)",
					textAlign: "center",
				}}
			>
				<PuffLoader size="45px" />
				<div
					style={{marginTop: "55px", position: "absolute", marginLeft: "-10px"}}
				>
					Loading...
				</div>
			</div>
		);
	}

	let filteredProps = omit(props, "imageId");
	filteredProps = omit(filteredProps, "caption");
	filteredProps = omit(filteredProps, "image");
	filteredProps = omit(filteredProps, "size");
	filteredProps = omit(filteredProps, "blur");
	filteredProps = omit(filteredProps, "hideLoading");
	filteredProps = omit(filteredProps, "shouldNotFade");
	filteredProps = omit(filteredProps, "hideCaption");

	if (filteredProps.width == null) {
		filteredProps.width = "100%";
	}
	if (filteredProps.height == null) {
		filteredProps.height = "100%";
	}

	let smallImage = image["100"];
	let largeImage = image["2500"];
	if (props.size != null) {
		largeImage = image[props.size];
	}

	if (smallImage == null || largeImage == null) {
		return null;
	}

	const allowCaptions = !isMobile() && hideCaption !== true;

	if (props.blur === false) {
		return (
			<ProgressiveImage src={largeImage.url}>
				{(src, loading) => {
					const isStillLoading =
						loading &&
						(props.shouldNotFade !== true || shouldFade || shouldFadeImage);
					const largeImageView = (
						<img
							className={
								"fullDiv image-fade " +
								(isStillLoading ? "" : "image-fade-loaded")
							}
							{...filteredProps}
							src={largeImage.url}
							alt="an alternative text"
						/>
					);

					let captionDiv = null;

					if (
						!isStillLoading &&
						caption != null &&
						caption.length > 0 &&
						allowCaptions
					) {
						captionDiv = (
							<div className="rendering-image-caption">
								{/* <div style={{fontStyle: "italic"}}>{"Rendering Includes:"}</div> */}
								<div
									className="bold"
									style={{
										margin: "10px",
										marginLeft: "20px",
										marginRight: "20px",
									}}
								>
									{caption}
								</div>
							</div>
						);
					}

					return (
						<div className={"fullDiv"}>
							{largeImageView}
							{captionDiv}
						</div>
					);
				}}
			</ProgressiveImage>
		);
	}

	return (
		<ProgressiveImage src={largeImage.url} placeholder={smallImage.url}>
			{(src, loading) => {
				const largeImageView = (
					<img
						className={
							"fullDiv image-fade" + (loading ? "" : " image-fade-loaded")
						}
						{...filteredProps}
						src={largeImage.url}
						alt="an alternative text"
					/>
				);

				let captionDiv = null;

				if (
					!loading &&
					caption != null &&
					caption.length > 0 &&
					allowCaptions
				) {
					captionDiv = <div className="rendering-image-caption">{caption}</div>;
				}

				const placeholder = (
					<img
						className={
							"fullDiv blur-image" +
							(loading ? "" : " blur-image-hide") +
							(smallImageLoaded ? "" : " blur-image-transition")
						}
						{...filteredProps}
						src={smallImage.url}
						onLoad={() => {
							setSmallImageLoaded(true);
						}}
						alt="an alternative text"
					/>
				);

				return (
					<div className={"fullDiv"}>
						{largeImageView}
						{placeholder}
						{captionDiv}
					</div>
				);
			}}
		</ProgressiveImage>
	);
};
