import React from "react";
import Container from "react-bootstrap/Container";
import {Underline} from "./underline";

export const StepBar = (props) => {
	const {step, items} = props;

	return (
		<Container className="step-bar">
			{items.map((item) => (
				<p
					className={step === item.value ? "active clickable" : "clickable"}
					data-value={item.value}
					onClick={item.onClick}
					key={item.value}
				>
					{item.text}
				</p>
			))}

			<Underline className="base-underline" />
		</Container>
	);
};
