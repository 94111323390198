import React, {useMemo, useState} from "react";
import {
  getAllSelectedModifiersFromSnapshot,
  getRenderings,
} from "../../../../design-tool/logic/ConfigurationPageHelper";
import {useWindowSize} from "../../../../_shared/hooks/useWindowSize";
import {StepCarousel} from "../../../../design-tool/components/stepCarousel";
import {useHistory} from "react-router-dom";
import {
  Column,
  DesignButtonContainer,
  DesignContentContainer,
  DesignContentHeader,
  DesignContentHeaderContainer,
  PageDetailsBreakdown,
  PageDetailsBreakdownColumnContainer,
  PageDetailsBreakdownTitle,
  PageFooterContainer,
  PageFooterLeft,
  PageFooterRight,
  SummaryImageContainer,
} from "../styles";
import {
  getColumnsFromEntries,
  getEntriesForUnit,
  getEntriesFromModifiers,
} from "../logic";
import {getSortedValuesWithKeyAsId} from "@natomas/core";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {NatButton} from "../../../../_shared/generics/button";
import {toDesignStudio} from "../../../../_shared/navigation";
import {setURLHash} from "../../../../_shared/navigation/_helpers";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {ChangeModelModal} from "../../YourUnitView/components/ChangeModelModal";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";

const SINGLE_COLUMN_THRESHOLD = 655;

const SectionBreakdown = (props) => {
  const windowWidth = useWindowSize().width;
  const singleColumn = windowWidth < SINGLE_COLUMN_THRESHOLD;

  return (
    <div style={{width: "100%", position: "relative", marginBottom: "20px"}}>
      <PageDetailsBreakdownTitle>{props.title}</PageDetailsBreakdownTitle>
      <PageDetailsBreakdownColumnContainer>
        <Column singleColumn={singleColumn} margin={!singleColumn}>
          {props.leftColumn}
        </Column>
        <Column singleColumn={singleColumn}>{props.rightColumn}</Column>
      </PageDetailsBreakdownColumnContainer>
    </div>
  );
};

export const DesignSummaryContent = (props) => {
  const {
    configurationPage,
    currentProduct,
    configurationSnapshot,
    configuration,
  } = props;
  const windowWidth = useWindowSize().width;
  const singleColumnThreshold = 1500;
  const singleColumn = windowWidth < singleColumnThreshold;
  let title;
  let renderings;
  let breakdownContent;
  const history = useHistory();
  const {isNatMobile} = usePage();
  const {STREAMLINED_JOURNEY} = useGlobalConfiguration();
  const {isCustomerPreDeposit} = useCurrentCustomer();
  const [showChangeModelModal, setShowChangeModelModal] = useState(false);

  const allSelectedModifiers = useMemo(() => {
    const allSelectedModifiersArray = getAllSelectedModifiersFromSnapshot(
      configurationSnapshot.snapshot
    );
    const allSelectedModifiersObject = {};
    allSelectedModifiersArray.forEach((modifier) => {
      allSelectedModifiersObject[modifier.id] = modifier;
    });
    return allSelectedModifiersObject;
  }, [configurationSnapshot]);

  let selectedIndex = 0;
  if (configurationPage == null) {
    title = "Home";
    renderings = [
      {imageId: currentProduct.imageId, caption: currentProduct.title},
    ];
    const entries = getEntriesForUnit(currentProduct);
    const columns = getColumnsFromEntries(entries);

    const oneColumn = windowWidth < SINGLE_COLUMN_THRESHOLD;
    breakdownContent = (
      <>
        <PageDetailsBreakdownTitle>{"Home Details"}</PageDetailsBreakdownTitle>
        <PageDetailsBreakdownColumnContainer singleColumn={oneColumn}>
          <Column singleColumn={oneColumn} margin={!oneColumn}>
            {columns.leftColumn}
          </Column>
          <Column singleColumn={oneColumn}>{columns.rightColumn}</Column>
        </PageDetailsBreakdownColumnContainer>
      </>
    );
  } else {
    currentProduct.configuratorPages.forEach((pageId, index) => {
      if (configurationPage.id.includes(pageId)) {
        selectedIndex = index;
      }
    });

    title = configurationPage.title;
    renderings = getRenderings(
      null,
      configurationPage,
      allSelectedModifiers,
      configurationPage.selectedModifierGroups
    ).images;

    const modifierGroups = configurationPage.selectedModifierGroups;
    const sections = configurationPage.sections;
    if (sections != null && Object.keys(sections).length > 1) {
      const sortedSections = getSortedValuesWithKeyAsId(sections);
      breakdownContent = sortedSections.map((section) => {
        let entries = getEntriesFromModifiers(
          modifierGroups.filter((group) => group.sectionId === section.id)
        );
        if (section.items != null) {
          entries = getEntriesFromModifiers(
            modifierGroups.filter((group) => section.items.includes(group.id))
          );
        }

        const columns = getColumnsFromEntries(entries);
        return (
          <SectionBreakdown
            key={"ADU-Design-Section-" + section.title}
            title={section.title}
            leftColumn={columns.leftColumn}
            rightColumn={columns.rightColumn}
          />
        );
      });
    } else {
      const entries = getEntriesFromModifiers(modifierGroups);
      const columns = getColumnsFromEntries(entries);
      breakdownContent = (
        <SectionBreakdown
          title={configurationPage.title + " Selections"}
          leftColumn={columns.leftColumn}
          rightColumn={columns.rightColumn}
        />
      );
    }
  }

  return (
    <>
      <ChangeModelModal
        show={showChangeModelModal}
        setShow={setShowChangeModelModal}
      />
      <DesignContentContainer isMobile={isNatMobile()}>
        <DesignContentHeaderContainer
          justifyContentMode={isNatMobile() ? "flex-end" : "space-between"}
        >
          {!isNatMobile() && <DesignContentHeader>{title}</DesignContentHeader>}
          <DesignButtonContainer>
            {STREAMLINED_JOURNEY && isCustomerPreDeposit && (
              <NatButton
                label={"Change Model"}
                type={"button"}
                option={StyleOption.SECONDARY}
                size={TextSize.SMALL}
                clickEvent={setShowChangeModelModal}
                clickEventParam={!showChangeModelModal}
                trackingId={"your-unit-change-model"}
              />
            )}
            <NatButton
              label={"Change Design"}
              type={"button"}
              option={StyleOption.PRIMARY}
              clickEvent={() => {
                toDesignStudio();
                setURLHash("" + selectedIndex);
              }}
              hidden={configuration != null && configuration.locked === true}
              size={TextSize.SMALL}
              trackingId={"your-unit-change-design"}
            />
          </DesignButtonContainer>
        </DesignContentHeaderContainer>
        <SummaryImageContainer singleColumn={singleColumn}>
          <div className="rendered-image-wrapper">
            <StepCarousel images={renderings} animate={true} />
          </div>
        </SummaryImageContainer>
        <PageDetailsBreakdown>{breakdownContent}</PageDetailsBreakdown>
        <PageFooterContainer hidden={configurationPage != null}>
          <PageFooterLeft>Home Description</PageFooterLeft>
          <PageFooterRight>{currentProduct.displayDescription}</PageFooterRight>
        </PageFooterContainer>
      </DesignContentContainer>
    </>
  );
};
