import {useEffect, useState} from "react";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import Select from "react-select";
import {convertModifiersToSelectionOptions} from "../../logic/ConfigurationPageHelper";

export const VISIBILITY_TYPE = "visibility";
export const PRICES_TYPE = "prices";

export const VISIBILITY_SHOW_KEY = "show";
export const VISIBILITY_HIDE_KEY = "hide";

export function getDataFromEntries(data, type) {
  switch (type) {
    case PRICES_TYPE:
      return data.map((item) => {
        return {
          price: item?.priceMicros / 100,
          requiredModifiers: item?.requiredModifiers,
          condition: item?.condition,
        };
      });
    case VISIBILITY_TYPE:
      return data.map((item) => {
        return {
          visibility: item?.visibility,
          requiredModifiers: item?.requiredModifiers,
          condition: item?.condition,
        };
      });
  }

  throw "Incorrect Dependency Table type: " + type;
}

export function generateOutput(data, type) {
  switch (type) {
    case PRICES_TYPE:
      return data.map((price) => {
        return {
          priceMicros: Math.round(price.price * 100),
          requiredModifiers: price.requiredModifiers,
          condition: price.condition,
        };
      });
    case VISIBILITY_TYPE:
      return data;
  }
}

export function getOptionsFromSelections(initialSelections, allModifiers) {
  if (initialSelections == null) {
    return [];
  }
  return Object.keys(initialSelections).map((key) => {
    return {
      value: key,
      label: allModifiers[key] != null ? allModifiers[key].title : key,
    };
  });
}

export const ModifierSelection = (props) => {
  const {initialSelections, onSelectionChange} = props;

  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const {currentGroupModifiers} = useProductCatalog();

  useEffect(() => {
    const modifierOptions = getOptionsFromSelections(
      initialSelections,
      currentGroupModifiers
    );
    setSelectedModifiers(modifierOptions);
  }, [initialSelections]);

  return (
    <Select
      isMulti
      value={selectedModifiers}
      onChange={(value) => {
        onSelectionChange(value);
      }}
      placeholder={"Modifiers"}
      options={convertModifiersToSelectionOptions(currentGroupModifiers)}
    />
  );
};
