import {useSelector} from "react-redux";
import {EditRendering} from "../components/EditRendering";
import {StepCarousel} from "../components/stepCarousel";
import {getRenderings} from "./ConfigurationPageHelper";
import {useDesignStudio} from "../../_shared/hooks/useDesignStudio";

export const CurrentImage = (props) => {
	let {configurationPage, modifierGroups, product} = props;
	const editingRenderings = useSelector(
		(state) => state.catalog.editingRenderings
	);

	const {orderSummary, step} = useDesignStudio({summary: true});
	const allSelectedModifiers = orderSummary.selectedModifiers;

	const summarizeOrderInConfigurationPages =
		step === "summary" && orderSummary != null
			? orderSummary.configurationSnapshot
			: null;

	if (configurationPage == null) {
		return null;
	}

	let {
		renderings,
		selectedModifiers,
		leftOverModifiers,
		images,
	} = getRenderings(
		summarizeOrderInConfigurationPages,
		configurationPage,
		allSelectedModifiers,
		modifierGroups
	);

	if (editingRenderings) {
		return (
			<div style={{maxHeight: "80vh", overflow: "scroll"}}>
				<h4>Selected modifier IDs</h4>
				{Object.keys(selectedModifiers).map((modifierId) => {
					return (
						<div key={modifierId}>{selectedModifiers[modifierId].title}</div>
					);
				})}
				<br />

				{renderings.map((rendering, index) => {
					return (
						<EditRendering
							renderingImageId={rendering.imageId}
							rendering={rendering}
							productId={product.id}
							configurationPage={configurationPage}
						/>
					);
				})}
				<EditRendering
					rendering={{}}
					renderingImageId={null}
					leftOverModifiers={leftOverModifiers}
					productId={product.id}
					configurationPage={configurationPage}
				/>
			</div>
		);
	}

	let animate = false;
	if (summarizeOrderInConfigurationPages != null) {
		images.unshift({imageId: product.imageId, caption: product.title});
		animate = true;
	}

	return (
		<div className="rendered-image-wrapper">
			<StepCarousel images={images} animate={animate} />
		</div>
	);
};
