import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {
  BottomMenuArea,
  BottomMenuItem,
  MenuItemContentWrapper,
  MenuItemTitle,
  SideBarContainer,
  SideBarHeader,
  SideBarMenuItemContainer,
} from "../../styles/AdminStyles";
import {MobileMenu} from "../../../../portal/_shared/components/MobileMenu";
import {isMobile} from "../../../../_shared/navigation";
import {toPage} from "../../../../portal/_shared/navigation/PageNavigation";
import {setShowLogin} from "../../../../_shared/slices/AuthSlice";
import {PRODUCT_ADMIN_TABS} from "../../constants/Constants";
import {ProductLineDropdown} from "./ProductLineDropdown";
import {setURLQueryKeyValue} from "../../../../_shared/navigation/_helpers";
import {
  clearEditing,
  setProductGroupId,
} from "../../../../_shared/slices/CatalogSlice";

const SideBarMenuItem = (props: any) => {
  const {pageId, title, selectedTab, onClick, enabled} = props;
  return (
    <SideBarMenuItemContainer
      onClick={onClick}
      selected={pageId === selectedTab}
      className={"unselectable"}
      enabled={enabled}
    >
      <MenuItemContentWrapper>
        <MenuItemTitle>{title}</MenuItemTitle>
      </MenuItemContentWrapper>
    </SideBarMenuItemContainer>
  );
};

export const ProductAdminSideBar = (props: any) => {
  const {selectedTab} = props;
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleProductGroupChange = (option: any) => {
    dispatch(clearEditing());
    dispatch(setProductGroupId(option.value));
    setURLQueryKeyValue("gid", option.value);
  };

  const content = (
    <SideBarContainer>
      <SideBarHeader>
        <ProductLineDropdown handleChange={handleProductGroupChange} />
      </SideBarHeader>
      {PRODUCT_ADMIN_TABS.map((item) => {
        let enabled = true;
        return (
          <SideBarMenuItem
            key={item.id}
            {...item}
            pageId={item.id}
            selectedTab={selectedTab}
            enabled={enabled}
            title={item.title}
            onClick={() => {
              if (enabled) {
                toPage(item.id);
                setIsMenuOpen(false);
              }
            }}
          />
        );
      })}
      <BottomMenuArea>
        <BottomMenuItem
          onClick={() => {
            dispatch(setShowLogin(true));
            setIsMenuOpen(false);
          }}
        >
          Account
        </BottomMenuItem>
      </BottomMenuArea>
    </SideBarContainer>
  );

  if (isMobile()) {
    return (
      <MobileMenu
        items={content}
        isOpen={isMenuOpen}
        onOpen={() => {
          setIsMenuOpen(true);
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
      />
    );
  }

  return content;
};
