import styled from "styled-components";
import {HEADER_Z_INDEX} from "../../../../styles";
import {PageContainerStickyMode, PageContainerStickyOptions} from "./index";

export const PageContainerRoot = styled.div<{
  sticky?: PageContainerStickyOptions;
  marginTop?: string;
  top?: string;
}>`
  position: ${(props) =>
    props.sticky ? (props.sticky.mode ? "sticky" : "fixed") : "relative"};
  top: ${(props) => props.top};
  bottom: ${(props) =>
    props.sticky &&
    props.sticky.mode === PageContainerStickyMode.FOOTER &&
    props.sticky.height};
  z-index: ${(props) => (props.sticky ? HEADER_Z_INDEX : "")};
  margin-top: ${(props) => props.marginTop};
`;

export const PageContainerWrapper = styled.div<{
  padding: number;
  width: number;
  sticky?: PageContainerStickyOptions;
  backgroundColor?: string;
  border?: boolean;
  overrideAnimation?: boolean;
}>`
  position: ${(props) =>
    props.sticky ? (props.sticky.mode ? "sticky" : "fixed") : "relative"};
  z-index: ${(props) => props.sticky && HEADER_Z_INDEX};
  visibility: ${(props) => (props.width ? "visible" : "hidden")};
  width: ${(props) => props.width}px;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  background-color: ${(props) => props.backgroundColor ?? ""};
  transition: ${(props) =>
    !props.overrideAnimation &&
    props.width &&
    props.padding !== 0 &&
    "all 400ms ease-in-out"};
  border-bottom: ${(props) =>
    props.border || props.sticky?.mode === PageContainerStickyMode.HEADER
      ? "1px solid lightgray"
      : "none"};
  border-top: ${(props) =>
    props.border || props.sticky?.mode === PageContainerStickyMode.FOOTER
      ? "1px solid lightgray"
      : "none"};
`;

export const PageContainerBackgroundImage = styled.div<{
  image: string;
  width: number;
  height: string;
}>`
  z-index: -1;
  opacity: 0.1;
  background-image: url(${(props) => props.image ?? ""});
  height: ${(props) => props.height};
  width: ${(props) => props.width}px;
  position: absolute;
  background-size: cover;
  background-position: center;
  transition: ${(props) => (props.width !== 0 ? "all 400ms ease-in-out" : "")};
`;

export const PageContent = styled.div<{enabled: boolean; width?: string}>`
  opacity: 1;
  visibility: ${(props) => (props.enabled ? "visible" : "hidden")};
  width: ${(props) => props.width};
  transition: ${(props) => (props.enabled ? "all 400ms ease-in-out" : "")};
`;
