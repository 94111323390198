import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  ModifierGroupAdminStructure,
  modifierGroupStructureForEditing,
} from "../../../../design-tool/models/ModifierModel";
import {
  deleteModifierGroupFromProductGroup,
  saveModifierGroupToDB,
} from "../../../../design-tool/backend/catalogApi";
import React, {useEffect, useState} from "react";
import {editModifierGroup} from "../../../../_shared/slices/CatalogSlice";
import {useFormik} from "formik";
import {DNDEditor} from "../../components/DNDEditor";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {getBlankItemSection} from "../ProductsTab/EditProduct";
import {
  AdminForm,
  InputContainer,
  InputTitle,
  MainContent,
  MainView,
} from "../../styles/AdminStyles";
import {AdminInput} from "../../components/AdminComponentFactory";
import {SelectionOptionsInput} from "../../../../design-tool/components/SelectionOptionsInput";
import {
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
  SHOW_MULTI_SELECT_PROPERTY_KEY,
} from "../../../../design-tool/logic/Constants";
import styled from "styled-components";
import {EditModifierOverrides} from "../../../../design-tool/components/EditModifierOverrides";
import {EditorHeader} from "../../components/EditorHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {IStore} from "../../../../_shared/slices/types/Store";

const Break = styled.div`
  width: 100%;
`;

const convertModifierGroupPropertiesToOptions = (properties: any) => {
  return Object.keys(properties).map((property) => {
    return {
      value: property,
    };
  });
};
const convertOptionsToModifierGroupProperties = (options: any) => {
  if (options == null || options.length < 1) {
    return null;
  }
  let propertyObject = {};
  options.forEach((property: any) => {
    // @ts-ignore
    propertyObject[property.value] = true;
  });
  return propertyObject;
};

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "structure", title: "Modifier Group Structure"},
];

const options = [
  {value: SHOW_COLOR_SWATCHES_PROPERTY_KEY, label: "Show Color Swatches"},
  {value: SHOW_FULL_TEXT_PROPERTY_KEY, label: "Show Full Text"},
  {value: SHOW_MULTI_SELECT_PROPERTY_KEY, label: "Allow Multi Select"},
];

const getInitialValues = (obj: any) => {
  const structure = modifierGroupStructureForEditing;
  const initialValues = getFormInitialValues(structure);
  if (obj != null) {
    for (const key in structure) {
      let initialValue = obj[key];
      if (key === "properties" && initialValue != null) {
        initialValue = convertModifierGroupPropertiesToOptions(initialValue);
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProductGroupModifierGroup = () => {
  const editingModifierGroup = useSelector(
    (state: IStore) => state.catalog.editingModifierGroup,
    shallowEqual
  );
  const {currentGroupModifierGroups, currentGroupModifiers} =
    useProductCatalog();
  const modifierGroup =
    currentGroupModifierGroups != null &&
    currentGroupModifierGroups[editingModifierGroup?.id] != null
      ? currentGroupModifierGroups[editingModifierGroup?.id]
      : editingModifierGroup;

  const [tab, setTab] = useState(tabs[0].id);

  const [lastModifierGroupId, setLastModifierGroupId] = useState("");
  const dispatch = useDispatch();
  const close = () => {
    dispatch(editModifierGroup(null));
  };

  const deleteModifierGroupAction = (e: any) => {
    e.preventDefault();
    close();
    return deleteModifierGroupFromProductGroup(modifierGroup);
  };

  const initialValues = getInitialValues(modifierGroup);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      const modifierGroupToSave = Object.assign({}, values);
      modifierGroupToSave.id = modifierGroup.id;
      // modifierGroupToSave.parentModifiers = convertFromTagsToObjects(
      // 	values.parentModifiers
      // );
      modifierGroupToSave.properties = convertOptionsToModifierGroupProperties(
        values.properties
      );
      saveModifierGroupToDB(modifierGroupToSave);
    },
  });

  useEffect(() => {
    if (modifierGroup != null && lastModifierGroupId !== modifierGroup.id) {
      formik.resetForm();
      setLastModifierGroupId(modifierGroup.id);
      formik.setValues(initialValues);
    }
  }, [modifierGroup]);

  if (modifierGroup == null) {
    return null;
  }

  const formStructure = getFormStructure(ModifierGroupAdminStructure);

  let mainContent;
  if (tab === "details") {
    mainContent = (
      <AdminForm onSubmit={formik.handleSubmit}>
        {formStructure.map((keyInfo) => {
          const {key, name} = keyInfo;
          // @ts-ignore
          const value = formik.values[key];
          if (key === "modifiers") {
            return null;
          } else if (key === "properties") {
            return (
              <React.Fragment key={key}>
                <Break />
                <InputContainer key={key}>
                  <InputTitle>{name}</InputTitle>
                  <SelectionOptionsInput
                    key={key}
                    options={options}
                    handleChange={(value: any) => {
                      formik.setFieldValue(key, value);
                    }}
                    value={value}
                  />
                </InputContainer>
              </React.Fragment>
            );
          }
          // @ts-ignore
          let initial = initialValues[key];
          return (
            <AdminInput
              key={key}
              value={value}
              fieldInfo={keyInfo}
              onChange={(value: any) => {
                formik.setFieldValue(key, value);
              }}
              initialValue={initial}
              handleChange={formik.handleChange}
            />
          );
        })}
      </AdminForm>
    );
  } else {
    const key = "modifiers";
    // @ts-ignore
    const value = formik.values[key];
    const libraryIds = Object.keys(currentGroupModifiers);
    mainContent = (
      <div>
        <DNDEditor
          sections={getBlankItemSection(value)}
          fieldKey={key}
          formik={formik}
          savedIds={value}
          libraryIds={libraryIds}
          type={"modifiers"}
          callback={(newSavedIds: any) => {
            formik.setFieldValue(key, newSavedIds);
          }}
        />
      </div>
    );
  }

  return (
    <MainView>
      <EditorHeader
        tabs={tabs}
        setTab={setTab}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        isUpToDate={isDeepEqual(formik.values, initialValues)}
        deleteAction={
          modifierGroup?.id != null ? deleteModifierGroupAction : null
        }
        saveAction={formik.handleSubmit}
      />
      <MainContent>{mainContent}</MainContent>
      <EditModifierOverrides
        callback={(valuesChanged: any) => {
          Object.keys(valuesChanged).forEach((key) => {
            formik.setFieldValue(key, valuesChanged[key]);
          });
        }}
      />
    </MainView>
  );
};
