import {Address, counties, states as stateOptions} from "@natomas/core";
import {FieldType} from "../../_shared/generics/table/TableTypes";

export const getAvailableCounties = (states: string[]) => {
  let eligibleCounties = counties.filter((item) => states.includes(item.state));
  return eligibleCounties.map((countyInfo) => {
    return Address.getCountyStateString(
      countyInfo.countyName,
      countyInfo.state
    );
  });
};

export const ProductGroupAdvancedStructure = {
  states: {
    name: "Serviceable States",
    type: "dropdown-array",
    subtype: "multi",
    defaultValue: [],
    options: stateOptions,
    index: 4,
  },
  counties: {
    name: "Serviceable Counties",
    type: "dropdown-array",
    subtype: "multi",
    defaultValue: [],
    options: [],
    index: 5,
  },
  production_timeline: {
    name: "Production Timeline",
    type: "table",
    tableStructure: {
      rowCount: 5,
      columns: [
        {
          dataField: "stage",
          name: "Stage",
          type: FieldType.STRING,
        },
        {
          dataField: "months",
          name: "# of Months",
          type: FieldType.NUMBER,
        },
      ],
      rigidValues: [
        ["Proposal"],
        ["Permitting"],
        ["Authorization to Build"],
        ["Manufacturing"],
        ["Installation"],
      ],
    },
    index: 13,
  },
  optionValues: {
    type: "optionKeyValues",
    defaultValue: {},
    index: 20,
  },
};

export const ProductGroupStructure = {
  title: {
    name: "Display Title",
    type: "input",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    type: "input",
    index: 0,
  },
  displayDescription: {
    name: "Display Description",
    index: 2,
    type: "textarea",
  },
  status: {
    name: "Status",
    index: 3,
    type: "dropdown",
    options: {
      unlisted: {label: "Unlisted", value: "unlisted"},
      listed: {label: "Listed", value: "listed"},
      hidden: {label: "Hidden", value: "hidden"},
    },
  },
  imageId: {
    name: "Image",
    type: "image",
    index: 12,
  },
  ...ProductGroupAdvancedStructure,
};
