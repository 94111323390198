import React from "react";

export const FormButton = React.forwardRef((props, ref) => {
	//Setting all the props needed to see which button is clicked and to connect it with associated radio
	//nextStep is used to determine which step of the form is next up depending on which button clicked
	const {
		buttonClass,
		buttonText,
		buttonOnClick,
		nextStep,
		radioID,
		radioValue,
		radioName,
	} = props;

	return (
		<div className="form-button-wrapper">
			<button
				className={buttonClass}
				onClick={buttonOnClick}
				data-radio={radioID}
				data-value={radioValue}
				data-name={radioName}
				data-next-step={nextStep}
				variant="contained"
			>
				{buttonText}
			</button>
			<input type="radio" id={radioID}></input>
		</div>
	);
});
