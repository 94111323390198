import React from "react";
//Styles
import {
  LogoContainer,
  NavigationButtons,
} from "../../../../_shared/generics/header/styles";
//Assets
import logo from "../../../../../assets/logos/villa-logo-cropped.svg";
//Shared
import {Header} from "../../../../_shared/generics/header";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {toPortal} from "../../../../_shared/navigation";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";

export const CatalogHeader = (props: {disableOptions?: boolean}) => {
  const {disableOptions} = props;
  const width = useDynamicValue(fullColumnWidths);
  const {STREAMLINED_JOURNEY} = useGlobalConfiguration();

  return (
    <>
      <Header height={"4rem"} width={width}>
        <LogoContainer
          src={logo}
          onClick={() => !disableOptions && toPortal()}
        />
        <NavigationButtons>
          {!disableOptions && !STREAMLINED_JOURNEY && (
            <NatButton
              label={"Back to Portal"}
              type={"button"}
              trackingId={"catalog-back-to-portal"}
              size={TextSize.SMALL}
              option={StyleOption.WhiteWillFillBlack}
              clickEvent={toPortal}
              disabled={disableOptions}
              spinnerEnabled={false}
            />
          )}
        </NavigationButtons>
      </Header>
    </>
  );
};
