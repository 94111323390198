import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {
  IConfigurationSite,
  ILineItem,
} from "../../../../../../functions/core/application/entities/SiteWorkCosts";
import React, {useState} from "react";
import {currencyStringFromMicros} from "@natomas/core";
import styled from "styled-components";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import {getPricingForUnit} from "../../../_shared/ConfigurationPricingHelper";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {executeRequest} from "../../../../../database/firebase/api";
import {GenerateSiteWorkModal} from "./GenerateSiteWorkModal";
import {isMobile, toDesignStudio} from "../../../../_shared/navigation";
import {ChangeModelModal} from "../../YourUnitView/components/ChangeModelModal";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../DashboardView/styles/globals";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {devLogger} from "../../../../../.developerSettings";

export const SiteWorkInfoCard = (props: any) => {
  const {showDetails} = props;
  const {
    configurationSite,
    configuration,
    currentConfigurationId,
  } = useCurrentProject();
  const shouldLookMobile = useDynamicValue({
    forFour: 1,
    forEight: 0,
    forTwelve: 0,
  });
  const totalSiteWorkCostInMicros =
    configurationSite?.totalSiteWorkCostInMicros ?? 0;
  const pricing = getPricingForUnit(configuration);
  const [detailsText, setDetailsText] = useState("Show Details");
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showPriceQuote, setShowPriceQuote] = useState(false);
  const [siteWorkButtonEnabled, setSiteWorkButtonEnabled] = useState(true);
  const [showChangeModelModal, setShowChangeModelModal] = useState(false);

  if (!configuration?.product) {
    return <></>;
  }
  let preConstructionAndPermittingLineItem = {
    totalPriceInMicros: 0,
    title: "Pre-Construction & Permitting",
  };
  (configurationSite as IConfigurationSite)?.lineItems?.forEach(
    (item: ILineItem) => {
      if (item.lineItemId === "permit" || item.lineItemId === "phase1") {
        preConstructionAndPermittingLineItem.totalPriceInMicros! +=
          item.totalPriceInMicros ?? 0;
      }
    }
  );

  let utilitiesLineItem: ILineItem = {
    totalPriceInMicros: 0,
    title: "Utilities",
  };
  (configurationSite as IConfigurationSite)?.lineItems?.forEach(
    (item: ILineItem) => {
      if (
        item.lineItemId === "water" ||
        item.lineItemId === "sewer" ||
        item.lineItemId === "power"
      ) {
        utilitiesLineItem.totalPriceInMicros! += item.totalPriceInMicros ?? 0;
      }
    }
  );

  let deliveryLineItem: ILineItem = {
    totalPriceInMicros: 0,
    title: "Delivery",
  };
  (configurationSite as IConfigurationSite)?.lineItems?.forEach(
    (item: ILineItem) => {
      if (item.lineItemId === "delivery" || item.lineItemId === "crane") {
        deliveryLineItem.totalPriceInMicros! += item.totalPriceInMicros ?? 0;
      }
    }
  );

  let constructionLineItem: ILineItem = {
    totalPriceInMicros: 0,
    title: "Construction",
  };
  (configurationSite as IConfigurationSite)?.lineItems?.forEach(
    (item: ILineItem) => {
      if (
        item.lineItemId === "slope" ||
        item.lineItemId === "pool" ||
        item.lineItemId === "shed" ||
        item.lineItemId === "garage" ||
        item.lineItemId === "sitePrepAndFoundation" ||
        item.lineItemId === "installation" ||
        item.lineItemId === "trees"
      ) {
        constructionLineItem.totalPriceInMicros! +=
          item.totalPriceInMicros ?? 0;
      }
    }
  );

  const closeModal = () => {
    setShowGenerateModal(false);
    setSiteWorkButtonEnabled(false);
  };

  return (
    <SiteWorkInfoCardContainer>
      <div style={{color: "black"}}>
        <ChangeModelModal
          show={showChangeModelModal}
          setShow={setShowChangeModelModal}
        />
      </div>
      <SummaryCostContainer shouldLookMobile={shouldLookMobile}>
        <SiteWorkTotalCostContainer
          shouldLookMobile={shouldLookMobile}
          className={"bold"}
        >
          <div>Instant Estimate</div>
          <div style={{fontSize: 32}}>
            {getTotalProposalPrice(pricing, totalSiteWorkCostInMicros)}
          </div>
        </SiteWorkTotalCostContainer>
        <CostBreakdownContainer>
          <div className={"bold"} hidden={true}>
            Budget Breakdown
          </div>
          <UnitSiteBreakdownContainer>
            <ProposalBreakdownContainer>
              <CardBreakdownTitle className={"bold"}>Unit</CardBreakdownTitle>
              <CardBreakdownValue shouldLookMobile={shouldLookMobile}>
                {getUnitBasePrice(pricing)}
              </CardBreakdownValue>
              <NatButton
                size={TextSize.XSMALL}
                label={shouldLookMobile ? "Change" : "Change home"}
                type={"button"}
                trackingId={"proposal-change-unit"}
                option={StyleOption.WhiteWillFillBlack}
                disabled={false}
                clickEvent={setShowChangeModelModal}
                clickEventParam={!showChangeModelModal}
              />
            </ProposalBreakdownContainer>
            <ProposalBreakdownContainer>
              <CardBreakdownTitle className={"bold"}>
                Upgrades
              </CardBreakdownTitle>
              <CardBreakdownValue shouldLookMobile={shouldLookMobile}>
                {getUpgradesPrice(pricing)}
              </CardBreakdownValue>
              <NatButton
                size={TextSize.XSMALL}
                label={"Design home"}
                type={"button"}
                trackingId={"proposal-change-design"}
                clickEvent={() => {
                  toDesignStudio();
                }}
                option={StyleOption.WhiteWillFillBlack}
                spinnerEnabled={siteWorkButtonEnabled}
                disabled={false}
              />
            </ProposalBreakdownContainer>
            <ProposalBreakdownContainer>
              <CardBreakdownTitle className={"bold"}>
                Site Work
              </CardBreakdownTitle>
              <CardBreakdownValue shouldLookMobile={shouldLookMobile}>
                {getSiteWorkPrice(totalSiteWorkCostInMicros)}
              </CardBreakdownValue>
              <NatButton
                size={TextSize.XSMALL}
                label={"Quote site"}
                type={"button"}
                trackingId={"generate-site-work-estimate"}
                clickEvent={() => {
                  setShowGenerateModal(true);
                  setSiteWorkButtonEnabled(true);
                }}
                option={StyleOption.WhiteWillFillBlack}
                spinnerEnabled={siteWorkButtonEnabled}
                disabled={false}
              />
            </ProposalBreakdownContainer>
          </UnitSiteBreakdownContainer>
        </CostBreakdownContainer>
      </SummaryCostContainer>
      {configurationSite != null && (
        <ShowDetailsText
          shouldLookMobile={shouldLookMobile}
          onClick={() => {
            setShowPriceQuote(!showPriceQuote);
            setDetailsText(showPriceQuote ? "Show Details" : "Hide Details");
          }}
        >
          {detailsText}
        </ShowDetailsText>
      )}
      {showPriceQuote && (
        <DetailsTable shouldLookMobile={shouldLookMobile}>
          <DetailsTableSiteWork>
            <DetailsTableSiteWorkTitle shouldLookMobile={shouldLookMobile}>
              {"Unit"}
            </DetailsTableSiteWorkTitle>
            <p>
              Don't worry if you're not sure about your home & design
              selections. You can change your mind at anytime in the next phase.
            </p>
            <br hidden={shouldLookMobile} />
            <LineItemInfo
              lineItem={{
                title: configuration?.product?.title ?? "Base Price",
                totalPriceInMicros: pricing.unitBasePriceInMicros,
              }}
            />
            <LineItemInfo
              lineItem={{
                title: "Upgrades",
                totalPriceInMicros: pricing.upgradePriceInMicros,
              }}
            />
            <LineItemInfo
              lineItem={{
                title: "Total Unit Estimate",
                totalPriceInMicros: pricing.fullUnitTotalPriceInMicros,
              }}
              finalLine={true}
            />
          </DetailsTableSiteWork>
          <DetailsTableSiteWork>
            <DetailsTableSiteWorkTitle shouldLookMobile={shouldLookMobile}>
              Site Work
            </DetailsTableSiteWorkTitle>
            <p>
              This estimate includes everything necessary to get your new ADU
              installed and move-in ready. You'll received a detailed, refined
              quote after submitting a proposal fee.
            </p>
            <LineItemInfo lineItem={preConstructionAndPermittingLineItem} />
            <LineItemInfo lineItem={constructionLineItem} />
            <LineItemInfo lineItem={utilitiesLineItem} />
            <LineItemInfo lineItem={deliveryLineItem} />
            <LineItemInfo
              lineItem={{
                title: "Total Site Work Estimate",
                totalPriceInMicros: totalSiteWorkCostInMicros,
              }}
              finalLine={true}
            />
          </DetailsTableSiteWork>
        </DetailsTable>
      )}
      <GenerateSiteWorkModal
        show={showGenerateModal}
        handleClose={closeModal}
        onSubmit={(formData: any) => {
          devLogger("Product Id " + configuration.product.id);
          return executeRequest("/project/v1/generateSiteWorkQuote", {
            projectId: currentConfigurationId,
            productGroupId: configuration.product.productGroupId,
            productId: configuration.product.id,
            surveyAnswers: {
              crane: formData.crane,
              slope: formData.slope,
              structures: formData.structures,
            },
          }).then((data) => {
            if (data) {
              setShowPriceQuote(true);
            }
            devLogger("Form Submitted" + JSON.stringify(formData, null, 2));
            setDetailsText("Hide Details");
            return data;
          });
        }}
      />
    </SiteWorkInfoCardContainer>
  );
};

export const LineItemInfo = (item: any) => {
  const lineItem = item.lineItem;
  return (
    <SiteWorkLineItem>
      <SiteWorkLineItemTitle>{lineItem.title!}</SiteWorkLineItemTitle>
      <SiteWorkLineItemValue finalLine={item.finalLine}>
        {currencyStringFromMicros(lineItem.totalPriceInMicros!)}
      </SiteWorkLineItemValue>
    </SiteWorkLineItem>
  );
};

export const getTotalProposalPrice = (
  pricing: any,
  totalSiteWorkCostInMicros: any
) => {
  if (
    pricing.fullUnitTotalPriceInMicros === 0 ||
    totalSiteWorkCostInMicros == null
  ) {
    return "---,---";
  }

  return priceTextFromMicros(
    pricing.fullUnitTotalPriceInMicros + totalSiteWorkCostInMicros,
    "min"
  );
};

const getUnitBasePrice = (pricing: any) => {
  if (pricing.unitBasePriceInMicros === 0) {
    return "N/A";
  }

  return <div>{priceTextFromMicros(pricing.unitBasePriceInMicros, "min")}</div>;
};
const getUpgradesPrice = (pricing: any) => {
  if (pricing.upgradePriceInMicros === 0) {
    return "$0";
  }

  return <div>{priceTextFromMicros(pricing.upgradePriceInMicros, "min")}</div>;
};

const getSiteWorkPrice = (totalSiteWorkCostInMicros: any) => {
  if (totalSiteWorkCostInMicros === 0) {
    return "N/A";
  }
  return (
    <div>{priceTextFromMicros(totalSiteWorkCostInMicros, "accurate")}</div>
  );
};

export const SiteWorkInfoCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 30px;
  margin-top: 25px;
  max-width: 100%;
  //box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #3d4f54;
  color: white;
  margin-bottom: -10px;
`;
export const SummaryCostContainer = styled.div<{
  shouldLookMobile: boolean;
}>`
  position: relative;
  display: flex;
  color: white;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  flex-direction: ${(props) => (props.shouldLookMobile ? "column" : "row")};
  margin-bottom: 0px;
`;

export const SiteWorkLineItem = styled.div`
  font-size: 14px;
  margin-left: 0%;
  display: flex;
  color: white;
  justify-content: space-between;
`;

export const SiteWorkLineItemTitle = styled.div`
  font-weight: bold;
  text-align: left;
  color: white;
`;
export const SiteWorkLineItemValue = styled.div<{finalLine: boolean}>`
  text-align: right;
  color: white;
  font-family: ${(props) =>
    props.finalLine ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  border-top: ${(props) => (props.finalLine ? "1px solid white" : "")};
`;

export const SiteWorkTotalCostContainer = styled.div<{
  shouldLookMobile: boolean;
}>`
  position: relative;
  width: 100%;
  flex-grow: 0;
  line-height: 1.4;
  min-width: fit-content;
  color: white;
  text-align: ${(props) => (props.shouldLookMobile ? "center" : "left")};
  margin-bottom: 10px;
`;

export const CostBreakdownContainer = styled.div`
  position: relative;
  flex-grow: 2;
  width: 100%;
`;

export const ProposalBreakdownContainer = styled.div`
  flex-grow: 2;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  width: 30%;
`;

export const ShowDetailsText = styled.a<{
  shouldLookMobile: boolean;
}>`
  z-index: 100;
  width: ${(props) => (props.shouldLookMobile ? "100%" : "fit-content")};
  padding-bottom: 20px;
  margin-top: ${(props) => (props.shouldLookMobile ? "20px" : "0px")};
  cursor: pointer;
  color: white;
  :hover {
    color: white;
  }
  font-family: "Maison-Book", sans-serif;
  font-size: 14px;
  height: 20px;
  text-align: ${(props) => (props.shouldLookMobile ? "center" : "left")};
`;

export const DetailsTable = styled.div<{
  shouldLookMobile: boolean;
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  justify-content: space-between;
`;

export const DetailsTableSiteWork = styled.div`
  flex-grow: 2;
  min-width: 260px;
  max-width: 100%;
  flex-direction: column;
  margin-top: 20px;
  width: 40%;
  font-size: 14px;
`;

export const DetailsTableSiteWorkTitle = styled.div<{
  shouldLookMobile: boolean;
}>`
  border-bottom: ${(props) =>
    props.shouldLookMobile ? "white solid 1px;" : "white solid 1px;"};
  text-align: left;
  font-family: "Maison-Bold", sans-serif;
  font-size: 18px;
`;

export const CardBreakdownTitle = styled.div`
  font-size: 14px;
`;

export const CardBreakdownValue = styled.div<{
  shouldLookMobile: boolean;
}>`
  font-size: ${(props) => (props.shouldLookMobile ? "18px" : "22px")};
`;

const UnitSiteBreakdownContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
