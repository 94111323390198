import history from "./history";

export const scrollToTop = (smooth: boolean) => {
  if (smooth) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }
};

export const setMultipleURLQueryKeyValues = (
  keyValues: {key: string; value: string | null}[],
  mode: HistoryChangeType = HistoryChangeType.PUSH
) => {
  const initialSearch = window.location.search;
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(initialSearch);
  keyValues.forEach(({key, value}) => {
    updateSearchParam(searchParams, key, value);
  });
  setNewURLQuery(history, pathname, searchParams.toString(), mode);
};

export const setURLQueryKeyValue = (key: string, value: string | null) => {
  let pathname = window.location.pathname;
  const initialSearch = window.location.search;
  let searchParams = new URLSearchParams(initialSearch);
  updateSearchParam(searchParams, key, value);
  setNewURLQuery(history, pathname, searchParams.toString());
};

export const setURLQuery = (
  history: any,
  key: string,
  value: string | null
) => {
  setURLQueryKeyValue(key, value);
};

const updateSearchParam = (
  searchParams: URLSearchParams,
  key: string,
  value: string | null
) => {
  if (value != null) {
    if (searchParams.get(key) !== value) {
      searchParams.set(key, value);
    }
  } else {
    searchParams.delete(key);
  }
};

const setNewURLQuery = (
  history: any,
  pathname: string,
  searchParams: string,
  mode: HistoryChangeType = HistoryChangeType.PUSH
) => {
  const initialSearch = window.location.search;
  const oldSearchParams = new URLSearchParams(initialSearch);
  if (oldSearchParams.toString() !== searchParams) {
    if (mode === HistoryChangeType.PUSH)
      history.push({pathname: pathname, search: searchParams});
    else if (mode === HistoryChangeType.REPLACE)
      history.replace({pathname: pathname, search: searchParams});
  }
};

export const formNewURLPathAndQuery = (key: string, value: string) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  if (value != null) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }
  return pathname + "?" + searchParams.toString();
};

export const formNewURLPathWithQueries = (
  params: {key: string; value: string}[]
) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  params.forEach((param) => {
    if (param.value != null) {
      searchParams.set(param.key, param.value);
    } else {
      searchParams.delete(param.key);
    }
  });
  return pathname + "?" + searchParams.toString();
};

export const formNewURLWithQueries = (
  params: {key: string; value: string}[]
) => {
  return window.location.origin + formNewURLPathWithQueries(params);
};

export const formNewURLQuery = (key: string, value: string) => {
  return window.location.origin + formNewURLPathAndQuery(key, value);
};

export const setURLPathAndQuery = (
  historyHook: any,
  pathname: string,
  key?: string,
  value?: string
) => {
  let searchParams = new URLSearchParams(window.location.search);
  if (key != null) {
    if (value != null) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  }
  history.push({pathname: pathname, search: searchParams.toString()});
};

export enum HistoryChangeType {
  PUSH = "push",
  REPLACE = "replace",
}

export const setURLPathAndMultipleQueries = (
  pathname: string,
  keyValues: {key: string; value: string | null}[],
  mode: HistoryChangeType = HistoryChangeType.PUSH
) => {
  const initialSearch = window.location.search;
  let searchParams = new URLSearchParams(initialSearch);
  keyValues.forEach(({key, value}) => {
    updateSearchParam(searchParams, key, value);
  });
  if (mode === HistoryChangeType.PUSH)
    history.push({pathname: pathname, search: searchParams.toString()});
  else if (mode === HistoryChangeType.REPLACE)
    history.replace({pathname: pathname, search: searchParams.toString()});
};

export const setURLHash = (hashValue: string) => {
  window.location.hash = hashValue;
};
