import React from "react";
import {AdminInformationContainer} from "../../styles";

export const AdminBlurb = (props: {email: string}) => {
  const {email} = props;
  return (
    <AdminInformationContainer>
      <p>
        <b>Uh oh!</b> Configuration id was not found.
      </p>
      <p>
        You are currently logged in as an administrator, <b>{email}</b>, but
        have not specified a configuration id.
      </p>
    </AdminInformationContainer>
  );
};
