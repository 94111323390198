import React from "react";
//Local
import {LoginForm} from "../../components/LoginForm";
import {
  StyledFormContent,
  StyledFormContentHeader,
  StyledFormDetails,
} from "../../../_shared/styles";
import {CornerContainer} from "./styles";
import {signInWithGoogle} from "../../../../../database/firebase";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";

interface ILoginPage {
  toForgotPassword: any;
}

const LoginPage = (props: ILoginPage) => {
  const {toForgotPassword} = props;
  return (
    <>
      <StyledFormContent>
        <StyledFormDetails>
          <StyledFormContentHeader>Welcome to Villa</StyledFormContentHeader>
        </StyledFormDetails>
        <LoginForm toForgotPassword={toForgotPassword} />
      </StyledFormContent>
      <CornerContainer>
        <NatButton
          label={"Employee Login"}
          trackingId={"employee-login"}
          type={"button"}
          clickEvent={signInWithGoogle}
          option={StyleOption.PRIMARY}
          size={TextSize.SMALL}
        />
      </CornerContainer>
    </>
  );
};

export {LoginPage};
