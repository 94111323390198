import {Utilities} from "../../../database/firebase";
import {store} from "../../../store";
import {enrichConfig} from "../../_shared/slices/GlobalSlice";

export const fetchWebAppConfig = () => {
  const configCollection = Utilities.collection("config");

  return Utilities.getDocData(configCollection.doc("web_app")).then(
    (result) => {
      return Utilities.getDocData(configCollection.doc("web_app_v2")).then(
        (result2) => {
          const parseResults = parseV2Config(result2);
          const finalResults = Object.assign(result, parseResults);
          return store.dispatch(enrichConfig(finalResults));
        }
      );
    }
  );
};

const parseV2Config = (rawConfig: any) => {
  const results: any = {};
  Object.keys(rawConfig).forEach((key) => {
    const configItem = rawConfig[key];
    const {type} = configItem;
    let value;
    switch (type) {
      case "progressive":
        value = evaluateProgressiveConfigItem(key, configItem);
        break;
      default:
        break;
    }
    if (value) {
      results[key] = value;
    }
  });
  return results;
};

const evaluateProgressiveConfigItem = (key: string, configItem: any) => {
  try {
    const {value, value_fallback, percentage} = configItem;
    const storageKey = `config_${key}`;
    const onDeviceNumber = window.localStorage.getItem(storageKey);
    let coinFlip;
    if (onDeviceNumber) {
      coinFlip = parseInt(onDeviceNumber);
    } else {
      coinFlip = randomIntFromInterval(0, 100);
      window.localStorage.setItem(storageKey, `${coinFlip}`);
    }

    return coinFlip < percentage ? value : value_fallback;
  } catch (e) {
    return null;
  }
};

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
