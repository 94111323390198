export const DimensionsIcon = ({ isActive }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.22809 18V9.545H4.74609V7.93H6.26609V6.448C6.26609 5.59933 6.46876 5.004 6.87409 4.662C7.27943 4.30733 7.85576 4.13 8.60309 4.13H10.1611V5.802H8.96409C8.60943 5.802 8.36876 5.87167 8.24209 6.011C8.11543 6.15033 8.05209 6.429 8.05209 6.847V7.93H10.5601V9.545H8.09009V18H6.22809ZM13.6537 14.846V9.545H12.1717V7.93H13.6917V5.46H15.4587V7.93H17.9667V9.545H15.4777V14.656C15.4777 15.226 15.56 15.6313 15.7247 15.872C15.902 16.1 16.2503 16.214 16.7697 16.214H17.5297V18H16.6367C15.5473 18 14.7747 17.7403 14.3187 17.221C13.8753 16.7017 13.6537 15.91 13.6537 14.846Z"
      fill={isActive ? "white" : "black"}
    />
  </svg>
);
