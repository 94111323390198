import React, {useState} from "react";
import {FloorPlanImage} from "./floorplanImage";
import {FloorplanStep} from "./floorplan";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {ProductGroupPicker} from "./ProductGroupPicker";
import {sortByAlphabeticalKey} from "@natomas/core";

const filteredProductGroups = (productGroups) => {
  return sortByAlphabeticalKey(
    Object.values(productGroups).filter((group) => group.status === "listed"),
    "title"
  );
};

export function FloorplanLayout(props) {
  const nodeRef = React.useRef(null);

  const {productGroupId, productGroups} = useProductCatalog();
  const [selectedProduct, setSelectedProduct] = useState(props.lastPickedUnit);
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const filteredGroups = filteredProductGroups(productGroups);

  let content = null;
  if (productGroupId == null) {
    content = (
      <ProductGroupPicker
        selectedProductGroup={selectedProductGroup}
        setSelectedProductGroup={setSelectedProductGroup}
        filteredGroups={filteredGroups}
      />
    );
  } else {
    content = (
      <FloorplanStep
        selectedProductGroup={selectedProductGroup}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        filteredGroups={filteredGroups}
      />
    );
  }

  return (
    <div
      className="app-body-wrapper-full-height custom-container-fluid"
      ref={nodeRef}
    >
      <div className="row custom-row" style={{justifyContent: "center"}}>
        <FloorPlanImage
          selectedProduct={selectedProduct}
          selectedProductGroup={selectedProductGroup}
        />

        <div
          className="current-description-wrapper"
          style={{maxWidth: "460px", flexGrow: 1, padding: "20px"}}
        >
          <div className="custom-container">{content}</div>
        </div>
      </div>
    </div>
  );
}
