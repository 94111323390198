import {useWindowSize} from "./useWindowSize";
import {useEffect} from "react";
import {
  calculateContainerWidth,
  calculateGutterWidthPercentage,
  calculateGutterWidthPixels,
  calculateNumberOfColumns,
} from "../generics/page/constants";
import {devLogger} from "../../../.developerSettings";
import {useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {store} from "../../../store";
import {
  updateColumnCount,
  updateContainerPadding,
  updateContainerWidth,
  updateGutterWidthPercentage,
  updateGutterWidthPixels,
  updatePageWidth,
} from "../slices/PageSlice";
import {isMobile} from "../navigation";

const SHOULD_LOG = false;

export const usePage = () => {
  const leftPadding = useSelector(
    (state: IStore) => state.page.additionalLeftPadding
  );
  const containerPadding = useSelector(
    (state: IStore) => state.page.containerPadding
  );
  const containerWidth = useSelector(
    (state: IStore) => state.page.containerWidth
  );
  const pageWidth = useSelector((state: IStore) => state.page.pageWidth);
  const columnCount = useSelector((state: IStore) => state.page.columnCount);
  const gutterWidthPercentage = useSelector(
    (state: IStore) => state.page.gutterWidthPercentage
  );
  const gutterWidthPixels = useSelector(
    (state: IStore) => state.page.gutterWidthPixels
  );
  /*
   * Mobile width values (anything under 768px) are entirely responsive.
   *  Because of this we need to on demand calculate widths for elements
   *  when screen size is within 0 - 768px.
   * */

  const getColumnWidthPercentage = (): number => {
    return (100 - (columnCount - 1) * gutterWidthPercentage) / columnCount;
  };
  const getWidth = (size: number): number => {
    if (size < 1) {
      /* Size cannot be less than 1 column wide. */
      if (SHOULD_LOG) {
        devLogger("Size is less than 1");
      }
      return 0;
    } else if (size > columnCount) {
      /*
        We can not declare sizes for elements
        that are bigger than the number of total columns.
      */
      if (SHOULD_LOG) {
        devLogger(
          "Size is greater than column count, Size: " +
            size +
            ", Count: " +
            columnCount
        );
      }

      return 0;
    } else if (size === 1) {
      /* Standard one column wide element */
      return getColumnWidthPercentage();
    } else {
      /*
        Multi-column elements will 'bridge' the gutter,
        so we need to include it in the calculation
       */
      return (
        size * getColumnWidthPercentage() + (size - 1) * gutterWidthPercentage
      );
    }
  };

  const isNatMobile = () => {
    return isMobile() || columnCount === 4;
  };

  return {
    columnCount,
    gutterWidthPercentage,
    gutterWidthPixels,
    pageWidth,
    leftPadding,
    containerPadding,
    containerWidth,
    getColumnWidthPercentage,
    getWidth,
    isNatMobile,
  };
};

export const usePageManager = () => {
  const {width} = useWindowSize();
  const leftPadding = useSelector(
    (state: IStore) => state.page.additionalLeftPadding
  );
  const pageWidth = useSelector((state: IStore) => state.page.pageWidth);
  const columnCount = useSelector((state: IStore) => state.page.columnCount);

  useEffect(() => {
    if (width) {
      const w = width - leftPadding;
      const pW = calculateContainerWidth(w);
      if (pW !== pageWidth) store.dispatch(updatePageWidth(pW));
      store.dispatch(updateContainerWidth(w));
    }
  }, [width, leftPadding]);

  useEffect(() => {
    if (width && pageWidth) {
      const cP = (width - leftPadding - pageWidth) / 2;
      store.dispatch(updateContainerPadding(cP));
      const cC = calculateNumberOfColumns(pageWidth);
      store.dispatch(updateColumnCount(cC));
    }
  }, [width, pageWidth, leftPadding]);

  useEffect(() => {
    if (width && pageWidth && columnCount) {
      store.dispatch(
        updateGutterWidthPercentage(calculateGutterWidthPercentage(pageWidth))
      );
      store.dispatch(
        updateGutterWidthPixels(calculateGutterWidthPixels(pageWidth))
      );
    }
  }, [width, pageWidth, columnCount]);

  return {
    columnCount,
    pageWidth,
    leftPadding,
  };
};
