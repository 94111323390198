import React, {useState} from "react";
import {
  PriceRangeSlider,
  SliderButtons,
  SliderContainer,
  SliderContent,
  SliderContentContainer,
  SliderLabel,
} from "./styles";
import {NatDropdownOption} from "../dropdown/NatDropdownOption";
import {
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getHoverTextColor,
  getHoverTextDecoration,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextSize,
  StyleOption,
  TextSize,
} from "../_shared";
import {IoIosClose} from "react-icons/io";
import {BiChevronDown} from "react-icons/bi";
import {NatBackdropPanel} from "../_shared/backdrop-panel/NatBackdropPanel";
import {NatButton} from "../button";
import {NumberRangeFilter} from "../../catalog/filter";

export interface NatSliderProps {
  label: string;
  trackingId: string;
  bounds: [number, number];
  callBack: (value: any) => void;
  valueTextFormatter: (value: any) => string;
  disabled: boolean;
  conditional?: (value: NatDropdownOption) => number;
  disabledCallback?: () => void;
}

export interface NatSliderOption {
  label: string;
  value: NumberRangeFilter;
}

export const NatSlider = (props: NatSliderProps) => {
  const {
    label,
    trackingId,
    callBack,
    bounds,
    disabled,
    disabledCallback,
    valueTextFormatter,
  } = props;
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [value, setValue] = useState([0, 100]);

  const minDistance = 22;

  const handleChange = (e: any, newValue: any, activeThumb: any) => {
    e.preventDefault();
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const toggle = () => {
    if (!disabled) setShowOptions(!showOptions);
    else disabledCallback && disabledCallback();
  };

  const calculateValue = (value: number): number => {
    const step = Math.round((bounds[1] - bounds[0]) / 10000) * 100;
    if (value === 0) return bounds[0];
    if (value === 100) return bounds[1];
    return Math.round(bounds[0] + step * value);
  };

  const formatter = (value: number) => {
    return valueTextFormatter(calculateValue(value));
  };

  const confirmOption = () => {
    let low = calculateValue(value[0]);
    let high = calculateValue(value[1]);
    callBack({
      label: valueTextFormatter(low) + " - " + valueTextFormatter(high),
      value: {
        low: low,
        high: high,
      },
    });
    setShowOptions(false);
  };

  const option = StyleOption.LIGHT_TOGGLE;

  return (
    <SliderContainer>
      <SliderLabel
        initialBackgroundColor={getInitialBackgroundColor(option)}
        initialTextColor={getInitialTextColor(option)}
        initialTextDecoration={getInitialTextDecoration(option)}
        initialBorderColor={getInitialBorderColor(option)}
        hoverBackgroundColor={getHoverBackgroundColor(option)}
        hoverTextColor={getHoverTextColor(option)}
        hoverTextDecoration={getHoverTextDecoration(option)}
        hoverBorderColor={getHoverBorderColor(option)}
        enabledBorderColor={getEnabledBorderColor(option)}
        enabledBackgroundColor={getEnabledBackgroundColor(option)}
        textSize={getTextSize(TextSize.SMALL)}
        onClick={() => toggle()}
        enabled={!disabled}
        name={trackingId}
      >
        {label}
        {disabled ? <IoIosClose /> : <BiChevronDown />}
      </SliderLabel>
      <NatBackdropPanel
        show={showOptions}
        disabled={disabled}
        clickEvent={toggle}
      />
      <SliderContentContainer show={showOptions && !disabled}>
        <SliderContent>
          <PriceRangeSlider
            getAriaLabel={() => label}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="on"
            valueLabelFormat={formatter}
            getAriaValueText={formatter}
          />
          <SliderButtons className={"flex"}>
            <NatButton
              label={"Dismiss"}
              clickEvent={() => {
                setShowOptions(false);
              }}
              option={StyleOption.SECONDARY}
              type={"button"}
              size={TextSize.SMALL}
              trackingId={"nat-slider-dismiss"}
            />
            <NatButton
              label={"Confirm"}
              clickEvent={confirmOption}
              option={StyleOption.PRIMARY}
              type={"button"}
              size={TextSize.SMALL}
              trackingId={"nat-slider-confirm"}
            />
          </SliderButtons>
        </SliderContent>
      </SliderContentContainer>
    </SliderContainer>
  );
};
