import React from "react";
import "../styles.scss";

export const RadioButton = React.forwardRef((props, ref) => {
	const {
		buttonId,
		buttonClassname,
		onClick,
		radioId,
		children,
		radioName,
		radioValue,
	} = props;
	return (
		<div className="pill-button-wrapper">
			<button
				id={buttonId}
				className={buttonClassname}
				onClick={onClick}
				data-radio={radioId}
			>
				{children}
			</button>
			<input
				type="radio"
				id={radioId}
				name={radioName}
				value={radioValue}
				ref={ref}
			/>
		</div>
	);
});
