export const getFormStructure = (formValues: any) => {
  return Object.keys(formValues)
    .map((key) => {
      return {
        key: key,
        ...formValues[key],
      };
    })
    .sort((a, b) => a.index - b.index);
};

export const getFormStructureFromMap = (formValues: Map<string, any>): any => {
  const formStructure: any = [];
  formValues.forEach((value, key) => {
    formStructure.push({
      key: key,
      ...value,
    });
  });
  return formStructure;
};

export const getFormInitialValues = (formValues: any) => {
  const initialValues = {};
  Object.keys(formValues).forEach((key) => {
    const field = formValues[key];
    // @ts-ignore TODO initialValues is a key/value pair system - ensure that it can receive 'any'
    initialValues[key] = field.defaultValue != null ? field.defaultValue : "";
  });

  return initialValues;
};

export const getFormInitialValuesFromMap = (formValues: Map<string, any>) => {
  const initialValues: any = {};
  formValues.forEach((value, key) => {
    initialValues[key] = value?.defaultValue ?? "";
  });
  return initialValues;
};
