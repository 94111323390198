import {createSlice} from "@reduxjs/toolkit";
import {getParameter} from "../cookies/getParameter";

const utmSlice = createSlice({
	name: "utm",
	initialState: {
		page_source: getParameter("pgsrc"),
		utm_adgroup: getParameter("utm_adgroup"),
		utm_campaign: getParameter("utm_campaign"),
		utm_content: getParameter("utm_content"),
		utm_medium: getParameter("utm_medium"),
		utm_placement: getParameter("utm_placement"),
		utm_source: getParameter("utm_source"),
		utm_term: getParameter("utm_term"),
		key: getParameter("key"),
	},
});

export const utmReducer = utmSlice.reducer;
