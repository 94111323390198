export const modifierStructureForProduct = {
  title: {
    name: "Title",
    index: 0,
  },
  priceMicros: {
    name: "Price",
    index: 2,
  },
  prices: {
    name: "Price Dependencies",
    defaultValue: [],
    index: 2,
    type: "priceTable",
  },
  priceAdjustments: {
    name: "Price Adjustments",
    defaultValue: [],
    index: 2,
    type: "priceTable",
  },
  description: {
    name: "Description",
    index: 3,
  },
  displayDescription: {
    name: "Display Description",
    index: 4,
  },
  configurationButtonTitle: {
    name: "Button Title",
    index: 1,
  },
  blockingModifiers: {
    name: "Blocking Modifiers",
    defaultValue: [],
    index: 11,
  },
};

export const modifierStructureForBase = {
  title: {
    name: "Title",
    index: 0,
  },
  descriptionDefault: {
    name: "Description",
    index: 15,
  },
  displayDescriptionDefault: {
    name: "Display Description",
    index: 16,
  },
  color: {
    name: "Color",
    index: 10,
  },
  secondaryColor: {
    name: "Secondary Color",
    index: 11,
  },
  imageId: {
    name: "Image",
    index: 9,
  },
  optionValues: {
    name: "Modified Options",
    index: 6,
  },
  configurationButtonTitle: {
    name: "Button Title",
    index: 1,
  },
  defaultPriceMicros: {
    name: "Global Price",
    index: 13,
  },
  defaultPrices: {
    name: "Global Prices Dependencies",
    defaultValue: [],
    index: 14,
    type: "priceTable",
  },
  defaultPriceAdjustments: {
    name: "Global Price Adjustments",
    defaultValue: [],
    index: 15,
    type: "priceTable",
  },
};

export const modifierStructureForEditing = {
  ...modifierStructureForBase,
  ...modifierStructureForProduct,
};

export const ProductLevelModifierStructureForEditing = {
  priceMicros: {
    name: "Price",
    index: 2,
    type: "number",
    subType: "price",
  },
  prices: {
    name: "Price Dependencies",
    defaultValue: [],
    index: 2,
    type: "priceTable",
  },
  priceAdjustments: {
    name: "Price Adjustments",
    defaultValue: [],
    index: 2,
    type: "priceTable",
  },
  defaultPriceMicros: {
    name: "Global Price",
    index: 13,
    type: "number",
    subType: "price",
  },
  defaultPrices: {
    name: "Global Prices Dependencies",
    defaultValue: [],
    index: 14,
    type: "priceTable",
  },
  defaultPriceAdjustments: {
    name: "Global Price Adjustments",
    defaultValue: [],
    index: 15,
    type: "priceTable",
  },
};

export const ModifierAdminStructure = {
  title: {
    name: "Title",
    index: 0,
  },
  configurationButtonTitle: {
    name: "Button Title",
    index: 1,
  },
  defaultPriceMicros: {
    name: "Global Price",
    type: "number",
    subType: "price",
    index: 2,
  },
  // descriptionDefault: {
  // 	name: "Description",
  // 	type: "textarea",
  // 	index: 3,
  // },
  displayDescriptionDefault: {
    name: "Display Description",
    type: "textarea",
    index: 3,
  },
  color: {
    name: "Primary Color",
    type: "color",
    index: 10,
  },
  secondaryColor: {
    name: "Secondary Color",
    type: "color",
    index: 11,
  },
  imageId: {
    name: "Image",
    type: "image",
    index: 20,
  },
  optionValues: {
    type: "optionKeyValues",
    name: "Modified Options",
    index: 21,
  },
};

export const ModifierAdvancedAdminStructure = {
  color: {
    name: "Color",
    type: "color",
    index: 10,
  },
  secondaryColor: {
    name: "Secondary Color",
    type: "color",
    index: 11,
  },
  imageId: {
    name: "Image",
    type: "image",
    index: 20,
  },
  optionValues: {
    type: "optionKeyValues",
    name: "Modified Options",
    index: 19,
  },
};

export const modifierGroupStructureForProduct = {
  title: {
    name: "Title",
    defaultValue: "",
    index: 0,
  },
  sectionId: {
    name: "Section ID",
    defaultValue: "",
    index: 7,
  },
  parentModifiers: {
    name: "Parent Modifiers",
    defaultValue: [],
    index: 8,
  },
};

export const modifierGroupStructureForBase = {
  title: {
    name: "Title",
    defaultValue: "",
    index: 0,
  },
  properties: {
    name: "Properties",
    defaultValue: [],
    index: 5,
  },
  modifiers: {
    name: "Modifiers",
    defaultValue: [],
    index: 10,
  },
  prettyDescription: {
    name: "Description",
    defaultValue: "",
    index: 1,
  },
  modifierOverrides: {
    name: "Modifier Overrides",
    defaultValue: {},
    index: 11,
  },
};

export const modifierGroupStructureForEditing = {
  ...modifierGroupStructureForBase,
  ...modifierGroupStructureForProduct,
};

export const ModifierGroupAdminStructure = {
  title: {
    name: "Title",
    defaultValue: "",
    index: 0,
  },
  prettyDescription: {
    name: "Description",
    defaultValue: "",
    type: "textarea",
    index: 1,
  },
  properties: {
    name: "Properties",
    defaultValue: [],
    index: 5,
  },
  modifiers: {
    name: "Modifiers",
    defaultValue: [],
    index: 10,
  },
};

export const ModifierGroupStructureForOverrides = {
  // requiredModifiers: {
  //   name: "Required Modifiers",
  //   type: "modifier-select",
  //   defaultValue: {},
  //   index: 8,
  // },
  dependencies: {
    name: "Visibility Dependencies",
    type: "modifier-dependency",
    subType: "visibility",
    defaultValue: [],
    index: 11,
  },
};

export const ModifierStructureForOverrides = {
  // blockingModifiers: {
  //   name: "Blocking Modifiers",
  //   type: "modifier-select",
  //   defaultValue: {},
  //   index: 8,
  // },
  dependencies: {
    name: "Visibility Dependencies",
    type: "modifier-dependency",
    subType: "visibility",
    defaultValue: [],
    index: 11,
  },
};
