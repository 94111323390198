export const sortProducts = (products) => {
	return products.sort((a, b) => {
		if (a.priceMicros === b.priceMicros) {
			if (a.title < b.title) {
				return -1;
			}
			if (a.title > b.title) {
				return 1;
			}
		}
		return a.priceMicros - b.priceMicros;
	});
};
