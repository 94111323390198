const BLACK = "#000000";
const WHITE = "#FFFFFF";
const GRAY = "#808080";
const red = "#dd0000";
const dark_red = "#660000";
const light_gray_1 = "#E1E1E1";
const light_gray_2 = "#afafaf";
const light_gray_3 = "#C5CBCC";
const gray_2 = "#3E5054";
const dark_gray = "#252525";
const green_3 = "#225722";
const green_2 = "#6A9E60";
const green_1 = "#82C275";
const green_4 = "#d7eecc";

export {BLACK, WHITE, GRAY};

export const DIVIDER_COLOR = light_gray_1;
export const LINK_COLOR = green_3;
export const LINK_HOVER_COLOR = green_2;
export const BUTTON_FILL_COLOR_LIGHT = green_1;
export const BUTTON_FILL_COLOR = green_2;
export const BUTTON_FILL_COLOR_DARK = green_3;
export const DROPDOWN_ACCENT_COLOR = light_gray_3;
export const TOGGLE_ENABLED_FILL_COLOR = green_4;
export const TOGGLE_BORDER_COLOR = light_gray_3;
export const TOGGLE_BORDER_ENABLED_COLOR = gray_2;
export const ERROR_COLOR = red;
export const VALID_COLOR = green_1;
export const BUTTON_FILL_COLOR_GRAY_1 = light_gray_1;
export const BUTTON_FILL_COLOR_GRAY_2 = light_gray_2;
export const DEVELOPER_FILL = dark_red;
export const DEVELOPER_FILL_HOVER = red;
export const DEVELOPER_FILL_SECTION = dark_gray;
export const ARCHIVE_FILL = red;
export const ARCHIVE_FILL_HOVER = dark_red;
export const SLIDER_THUMB = BLACK;
export const SLIDER_TRACK = BLACK;
export const SLIDER_RAIL = light_gray_1;
