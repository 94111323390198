import {useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {JourneyStepName, useTracking} from "./useTracking";
import {setURLQueryKeyValue} from "../navigation/_helpers";
import {useEffect} from "react";

export const useUrl = () => {
  const url = useSelector((state: IStore) => state.global.url);
  const {markJourneyEvent} = useTracking();

  const processUrl = () => {
    let params = getParams();
    if (params.has("proposalFeePaid")) {
      if (params.get("proposalFeePaid") === "true") {
        markJourneyEvent(JourneyStepName.PAYMENT_SUBMITTED);
        setURLQueryKeyValue("proposalFeePaid", null);
      } else {
        markJourneyEvent(JourneyStepName.PAYMENT_ABANDON);
        setURLQueryKeyValue("proposalFeePaid", null);
      }
    }
  };

  useEffect(() => {
    processUrl();
  }, [url]);

  const getPageFromURL = () => {
    const currentSearchParams = getParams();
    if (currentSearchParams.has("page")) {
      return currentSearchParams.get("page");
    } else {
      return null;
    }
  };

  const getURLPath = () => {
    return window.location.pathname;
  };

  const getURL = () => {
    return window.location.href;
  };

  const getParams = () => {
    const formattedUrl = new URL(getURL());
    return new URLSearchParams(formattedUrl.search);
  };

  return {
    url,
    pageId: getPageFromURL(),
    getPageFromURL,
    getURLPath,
    getURL,
    getParams,
  };
};
