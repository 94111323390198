import {
  getModifierGroupType,
  priceTextFromMicros,
} from "../logic/ConfigurationPageHelper";
import {
  SHOW_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_TEXT_DISPLAY_KEY,
  SHOW_MULTI_SECENT_DISPLAY_KEY,
} from "../logic/Constants";
import {ColorButton} from "./buttons/colorButton";
import {ImageTextButton} from "./buttons/imageTextButton";
import {MultiSelectTextButton} from "./buttons/multiSelectTextButton";
import {RadioButton} from "./buttons/radioButton";
import {TextButton} from "./buttons/textButton";
import {FullColorTextButton} from "./buttons/fullColorTextButton";

export const getModifierButton = (
  modifier,
  modifierGroup,
  onClick,
  isSelected,
  editingSelections
) => {
  const modifierGroupType = getModifierGroupType(modifierGroup);
  let className = "";
  if (isSelected === true) {
    className = "active";
  } else if (
    editingSelections !== true &&
    editingSelections != null &&
    isSelected !== true
  ) {
    className = "disabled";
  }

  const commonProps = {
    buttonId: modifier.id,
    onClick: onClick,
    radioValue: modifier.id,
    key: modifier.id,
    buttonClassname: className,
    isSelected: isSelected,
  };

  switch (modifierGroupType) {
    case SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY:
      return getFullColor(commonProps, modifier);
    case SHOW_MULTI_SECENT_DISPLAY_KEY:
      return getMultiSelectButton(commonProps, modifier);
    case SHOW_COLOR_SWATCHES_DISPLAY_KEY:
      return getColorButton(commonProps, modifier);
    case SHOW_FULL_TEXT_DISPLAY_KEY:
      return getTextButton(commonProps, modifier);
    default:
      return getButton(commonProps, modifier);
  }
};

function getButton(commonProps, modifier) {
  if (modifier.imageId != null) {
    return getImageButton(commonProps, modifier);
  } else if (microsToText(modifier.adjustedPriceMicros)) {
    return getTextButton(commonProps, modifier);
  }

  return (
    <RadioButton {...commonProps}>{getModifierTitle(modifier)}</RadioButton>
  );
}

function getMultiSelectButton(commonProps, modifier) {
  return (
    <MultiSelectTextButton
      {...commonProps}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(modifier.adjustedPriceMicros, "min-sign")}
    />
  );
}

export function getTextButton(commonProps, modifier) {
  return (
    <TextButton
      {...commonProps}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(modifier.adjustedPriceMicros, "min-sign")}
    />
  );
}

function getColorButton(commonProps, modifier) {
  return (
    <ColorButton
      {...commonProps}
      bgColor="#FFF"
      firstColor={modifier.color}
      secondColor={modifier.secondaryColor}
      imageId={modifier.imageId}
    />
  );
}

function getFullColor(commonProps, modifier) {
  return (
    <FullColorTextButton
      {...commonProps}
      firstColor={modifier.color}
      secondColor={modifier.secondaryColor}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(modifier.adjustedPriceMicros, "min-sign")}
    />
  );
}

export function getImageButton(commonProps, modifier) {
  return (
    <ImageTextButton
      {...commonProps}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(modifier.adjustedPriceMicros, "min-sign")}
      imageId={modifier.imageId}
    />
  );
}

function microsToText(micros) {
  if (micros == null || micros === 0) {
    return null;
  }
  let prefix = "+$";
  if (micros < 0) {
    prefix = "-$";
  }

  return prefix + Math.abs(micros / 100);
}

function isBlank(string) {
  return string == null || string.length < 1;
}

function getModifierTitle(modifier) {
  if (!isBlank(modifier.configurationButtonTitle)) {
    return modifier.configurationButtonTitle;
  }
  return modifier.title;
}
