import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import styled from "styled-components";
import {
  getSelectedModifiersFromSnapshot,
  priceFromText,
  priceTextFromMicros,
} from "../../../../design-tool/logic/ConfigurationPageHelper";
import {getDescriptionForModifier} from "../../../../design-tool/logic/ProductUtils";
import React, {useState} from "react";
import {WaitingForDesign} from "../../../_shared/components/WaitingForDesign";
import {getDollarPriceValueFromText} from "@natomas/core";
import {isBlankString} from "@natomas/core";
import {getPricingForUnit} from "../../../_shared/ConfigurationPricingHelper";
import {UnitLineItemsTable} from "./UnitLineItemsTable";
import {
  FirstColumn,
  HeaderRowContainer,
  Opaque,
  SecondColumn,
  TableContainer,
  ThirdColumn,
} from "../styles/SiteWorkTableStyles";

const RowContainer = styled.div`
  min-height: 70px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: ${(props) => (props.hide === true ? "none" : "flex")};
  font-size: 16px;
`;

const FinalRowContainer = styled.div`
  min-height: 70px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 16px;
  background-color: rgba(130, 194, 117, 0.4);
`;

const ColumnText = styled.div`
  margin: 20px;
  position: relative;
  margin-left: 30px;
`;

const UpgradesText = styled.div`
  position: relative;
  margin: 10px;
  margin-left: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  &:hover {
    background: rgba(130, 194, 117, 0.2);
  }
`;

export const UnitBudgetSummary = (props) => {
  const {showExtras} = props;
  const {configurationSnapshot, configuration} = useCurrentProject();
  const [expandUpgrades, setExpandUpgrades] = useState(false);
  const [expandLineItems, setExpandLineItems] = useState(false);

  if (configuration?.product == null || configurationSnapshot == null) {
    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <WaitingForDesign relative={true} />
      </div>
    );
  }
  const {product} = configuration;
  let upgradesLineItems = null;
  if (expandUpgrades) {
    const modifiers = getSelectedModifiersFromSnapshot(
      configurationSnapshot.snapshot
    );
    const modifiersList = Object.values(modifiers).filter(
      (modifier) => modifier.snapshotPrice !== 0
    );
    upgradesLineItems = modifiersList.map((modifier) => {
      return (
        <RowContainer
          key={modifier.id}
          style={{backgroundColor: "rgba(0,0,0,0.1"}}
        >
          <FirstColumn>
            <ColumnText>{modifier.title}</ColumnText>
          </FirstColumn>
          <SecondColumn>
            <ColumnText>
              {priceTextFromMicros(modifier.snapshotPrice, "min")}
            </ColumnText>
          </SecondColumn>
          <ThirdColumn>
            <ColumnText>{getDescriptionForModifier(modifier)}</ColumnText>
          </ThirdColumn>
        </RowContainer>
      );
    });
  }

  const unitAdditionalLineItems =
    configuration.additionalLineItems != null
      ? configuration.additionalLineItems
          .map((lineItem, index) => {
            const intValue = getDollarPriceValueFromText(lineItem.totalPrice);
            const priceText =
              intValue === 0 ? "-" : priceFromText("" + intValue, "min");
            if (isBlankString(lineItem.title)) {
              return null;
            }

            return (
              <RowContainer
                key={"additionalLineItem-" + index}
                style={{backgroundColor: "rgba(0,0,0,0.1"}}
              >
                <FirstColumn>
                  <ColumnText>{lineItem.title}</ColumnText>
                </FirstColumn>
                <SecondColumn>
                  <ColumnText>{priceText}</ColumnText>
                </SecondColumn>
                <ThirdColumn>
                  <ColumnText>{lineItem.note}</ColumnText>
                </ThirdColumn>
              </RowContainer>
            );
          })
          .filter((e) => e != null)
      : [];

  const upgradesText = "Upgrades " + (expandUpgrades ? "▼" : "▲");
  const lineItemsText =
    "Additional Line Items " + (expandLineItems ? "▼" : "▲");
  const pricing = getPricingForUnit(configuration);
  const finalRowContent = (
    <>
      <FirstColumn>
        <ColumnText>UNIT COST SUBTOTAL</ColumnText>
      </FirstColumn>
      <SecondColumn>
        <ColumnText>
          {priceTextFromMicros(pricing.fullUnitTotalPriceInMicros, "min")}
        </ColumnText>
      </SecondColumn>
      <ThirdColumn />
    </>
  );

  return (
    <TableContainer>
      <div>
        <HeaderRowContainer className={"bold"}>
          <Opaque />
          <FirstColumn>
            <ColumnText>Unit Item</ColumnText>
          </FirstColumn>
          <SecondColumn>
            <ColumnText>Price</ColumnText>
          </SecondColumn>
          <ThirdColumn>
            <ColumnText>Description</ColumnText>
          </ThirdColumn>
        </HeaderRowContainer>
        <RowContainer>
          <FirstColumn className={"bold"}>
            <ColumnText>{product.title}</ColumnText>
          </FirstColumn>
          <SecondColumn className={"bold"}>
            <ColumnText>
              {priceTextFromMicros(pricing.unitBasePriceInMicros, "min")}
            </ColumnText>
          </SecondColumn>
          <ThirdColumn>
            <ColumnText>{product.displayDescription}</ColumnText>
          </ThirdColumn>
        </RowContainer>
        <RowContainer>
          <FirstColumn className={"bold"}>
            <UpgradesText
              onClick={() => {
                setExpandUpgrades(!expandUpgrades);
              }}
            >
              {upgradesText}
            </UpgradesText>
          </FirstColumn>
          <SecondColumn className={"bold"}>
            <ColumnText>
              {priceTextFromMicros(pricing.upgradePriceInMicros, "accurate")}
            </ColumnText>
          </SecondColumn>
          <ThirdColumn />
        </RowContainer>
        {upgradesLineItems}
        <RowContainer
          hide={
            configuration.additionalLineItems == null ||
            unitAdditionalLineItems.length <= 0
          }
        >
          <FirstColumn className={"bold"}>
            <UpgradesText
              onClick={() => {
                setExpandLineItems(!expandLineItems);
              }}
            >
              {lineItemsText}
            </UpgradesText>
          </FirstColumn>
          <SecondColumn className={"bold"}>
            <ColumnText>
              {priceTextFromMicros(pricing.lineItemsPriceInMicros, "accurate")}
            </ColumnText>
          </SecondColumn>
          <ThirdColumn></ThirdColumn>
        </RowContainer>
        {expandLineItems ? unitAdditionalLineItems : null}

        {showExtras ? (
          <FinalRowContainer className={"bold"}>
            {finalRowContent}
          </FinalRowContainer>
        ) : (
          <RowContainer className={"bold"}>{finalRowContent}</RowContainer>
        )}
      </div>

      <UnitLineItemsTable showExtras={showExtras} />
    </TableContainer>
  );
};
