import React, {Dispatch, SetStateAction} from "react";
import {ModifierDependencyTable} from "../../../../../design-tool/admin/components/ModifierDependencyTable";
import {
  NatModal,
  NatModalSize,
} from "../../../../../_shared/generics/modal/NatModal";

export interface DependenciesDataProps {
  modifierTitle: string;
  productTitle: string;
  setEdit: any;
  id: string;
  type: string;
  field: string;
  info: any[];
}

export interface DependenciesProps {
  show: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  data: DependenciesDataProps | undefined;
}

export const DependenciesModal = (props: DependenciesProps) => {
  const {show, handleClose, data} = props;
  const handleChange = (d: any) => {
    if (data) {
      const newEdit = {
        [data.id]: {[data.field]: d},
      };
      data.setEdit(newEdit);
    }
  };

  const close = () => {
    handleClose(false);
  };

  const getTitle = () => {
    if (data?.type === "prices") {
      return (
        "Price Dependencies: " + data.productTitle + " ~ " + data.modifierTitle
      );
    }
  };

  // Require that data is populated
  return (
    <>
      {data && (
        <NatModal
          show={show}
          handleClose={close}
          header={getTitle()}
          size={NatModalSize.LARGE}
          content={
            <>
              <ModifierDependencyTable
                value={data?.info}
                handleChange={handleChange}
                name={""}
                type={data?.type}
              />
            </>
          }
        />
      )}
    </>
  );
};
