import {useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {loadConfiguration} from "../../design-tool/backend/ConfigurationAPI";
import {mergeProjectInfoAndProjectOpportunityInfo} from "../../../database/firebase/adapters/project";
import {natomasAddressToAddressDetails} from "../../../database/firebase/adapters/address";
import {PHASE_1_PAYMENT_EVENT_ID, PhoneNumber, Project} from "@natomas/core";
import {getEventObject} from "../../portal/_shared/utilities";
import {CUSTOMER_STATUS} from "./useCurrentCustomer";

const getProjectCompletionStatuses = (
  designReady,
  sitePlanUploaded,
  proposalReady,
  projectSummary
) => {
  const designStudio = !designReady
    ? CUSTOMER_STATUS.IN_PROGRESS
    : CUSTOMER_STATUS.COMPLETED;
  const sitePlan =
    designReady && sitePlanUploaded
      ? CUSTOMER_STATUS.COMPLETED
      : designReady
      ? CUSTOMER_STATUS.IN_PROGRESS
      : CUSTOMER_STATUS.DISABLED;
  const phase1PaymentEvent = getEventObject(
    projectSummary,
    PHASE_1_PAYMENT_EVENT_ID
  );

  let proposal;
  if (phase1PaymentEvent != null && proposalReady) {
    proposal = CUSTOMER_STATUS.COMPLETED;
  } else if (proposalReady) {
    proposal = CUSTOMER_STATUS.IN_PROGRESS;
  } else {
    proposal = CUSTOMER_STATUS.DISABLED;
  }

  return {
    designStudio,
    sitePlan,
    proposal,
  };
};

export const useCurrentProject = () => {
  const currentConfigurationId = useSelector(
    (state) => state.configuration.currentConfigurationId
  );
  const configuration = useSelector(
    (state) => state.configuration.fetchedConfiguration
  );
  const configurationSnapshot = useSelector(
    (state) => state.configuration.fetchedConfigurationSnapshot
  );
  const configurationMap = useSelector(
    (state) => state.configuration.fetchedConfigurationMap
  );
  const configurationSite = useSelector(
    (state) => state.configuration.fetchedConfigurationSite
  );
  const configurationOrder = useSelector(
    (state) => state.configuration.fetchedConfigurationOrder
  );
  const projectInfo = useSelector(
    (state) => state.configuration.fetchedProjectSummary
  );
  const projectOpportunityInfo = useSelector(
    (state) => state.configuration.fetchedProjectOpportunity
  );

  const mergedProjectInfos = useMemo(() => {
    return mergeProjectInfoAndProjectOpportunityInfo(
      projectInfo,
      projectOpportunityInfo
    );
  }, [projectInfo, projectOpportunityInfo]);

  useEffect(() => {
    if (configuration == null || configuration.id !== currentConfigurationId) {
      loadConfiguration();
    }
  }, [currentConfigurationId, configuration]);

  const designReady = configurationSnapshot != null;

  let sitePlanImages = [];
  if (configurationSite?.sitePlan?.images != null) {
    sitePlanImages = [...configurationSite?.sitePlan?.images];
  } else if (configurationSite?.sitePlan?.imageId != null) {
    sitePlanImages = [configurationSite?.sitePlan?.imageId];
  }

  const sitePlanReady = sitePlanImages.length > 0;
  const proposalReady =
    configurationSite != null &&
    configurationSite.totalSiteWorkCostInMicros != null &&
    configurationSite.visible === true &&
    designReady &&
    sitePlanReady;

  const projectSummary = useMemo(() => {
    // We have fetched both dependencies
    if (projectOpportunityInfo !== undefined && projectInfo !== undefined) {
      const summary = mergeProjectInfoAndProjectOpportunityInfo(
        projectInfo,
        projectOpportunityInfo
      );
      // Both dependencies are null
      if (!summary) {
        return null;
      }
      // Otherwise retrieve info
      let projectAddress = natomasAddressToAddressDetails(
        summary.project_address
      );
      let projectPhone = PhoneNumber.dataToPhoneNumber(
        "project",
        summary.customer_contact?.phone
      );

      return Project.dataToProject(
        currentConfigurationId,
        summary.customer_contact?.first_name,
        summary.customer_contact?.last_name,
        projectPhone,
        projectAddress,
        summary.events
      );
    } else return undefined;
  }, [projectInfo, projectOpportunityInfo]);

  return {
    product: configuration?.product,
    configuration,
    configurationSnapshot,
    configurationMap,
    configurationSite,
    projectInfo,
    projectSummary,
    currentConfigurationId,
    configurationOrder,
    sitePlanImages,
    mergedProjectInfos,
    status: {
      designReady,
      sitePlanReady,
      proposalReady,
    },
    projectCompletion: getProjectCompletionStatuses(
      designReady,
      sitePlanReady,
      proposalReady,
      mergedProjectInfos
    ),
  };
};
