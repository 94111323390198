import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import clipboard from "../../../../../assets/icons/pngs/clipboardIcon16.png";
import {isPhase1Complete, isPSASigned} from "../../../_shared/utilities";
import dollar from "../../../../../assets/icons/pngs/dollarIcon16.png";
import {StripeCheckoutButton} from "../../../../_shared/generics/StripeCheckoutButton";
import {
  BoxContainer,
  BoxContentContainer,
  BoxLeftContent,
  BoxRightContent,
  BoxTitle,
  CallToAction,
  MobileStepContainer,
  MobileStepSubtitle,
} from "../styles";
import React from "react";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {Customer} from "@natomas/core";
import {usePage} from "../../../../_shared/hooks/usePage";

export const StepBox = (props: any) => {
  const {step, handleShow} = props;
  const {isNatMobile} = usePage();
  let icon = null;
  let title = "";
  let subtitle = "";
  let navItem = "";
  let action = null;
  const {currentConfigurationId, mergedProjectInfos} = useCurrentProject();
  const {customer} = useCurrentCustomer();
  const config = useGlobalConfiguration();
  if (customer == null) {
    return null;
  }

  switch (step) {
    case 1:
      icon = clipboard;
      title = "Review Project Services Agreement (PSA)";
      subtitle = "What is the PSA?";
      navItem = "PSA";
      if (config.show_PSA_status) {
        action = (
          <div
            style={{
              marginTop: isNatMobile() ? "5px" : "30px",
              textAlign: "center",
            }}
          >
            {isPSASigned(mergedProjectInfos) ? (
              <div
                style={{
                  marginTop: isNatMobile() ? "10px" : "30px",
                  textAlign: isNatMobile() ? "left" : "center",
                  cursor: "default",
                }}
              >
                {"PSA Signed ✅"}
              </div>
            ) : (
              <div
                style={{
                  marginTop: isNatMobile() ? "10px" : "30px",
                  textAlign: isNatMobile() ? "left" : "center",
                  cursor: "default",
                }}
              >
                Check Email 📬
              </div>
            )}
          </div>
        );
      }
      break;
    case 2:
      icon = dollar;
      title = "Complete the $9,000 Phase 1 Fee";
      subtitle = "What is the refund policy?";
      navItem = "refund";

      if (config.show_phase1_payment_button) {
        if (isPhase1Complete(mergedProjectInfos)) {
          action = (
            <div
              style={{
                marginTop: isNatMobile() ? "10px" : "30px",
                textAlign: isNatMobile() ? "left" : "center",
                cursor: "default",
              }}
            >
              Completed Payment ✅
            </div>
          );
        } else {
          action = (
            <div
              style={{
                marginTop: isNatMobile() ? "10px" : "30px",
                textAlign: isNatMobile() ? "left" : "center",
              }}
            >
              <StripeCheckoutButton
                email={Customer.getContactEmail(customer)}
                clientReferenceId={currentConfigurationId}
                priceSKU={config.phase1PriceSKU}
                label={"Complete Payment"}
              />
            </div>
          );
        }
      }
      break;
    default:
      break;
  }
  if (isNatMobile()) {
    return (
      <MobileStepContainer>
        <BoxTitle>
          {"Step " + step}
          {isPhase1Complete(mergedProjectInfos) && step === 1 ? " ✓" : ""}
          {isPSASigned(mergedProjectInfos) && step === 2 ? " ✓" : ""}
        </BoxTitle>
        <MobileStepSubtitle>{title}</MobileStepSubtitle>
        <CallToAction
          onClick={() => {
            handleShow(navItem);
          }}
        >
          {subtitle}
        </CallToAction>
        {action}
        <hr />
      </MobileStepContainer>
    );
  }

  return (
    <BoxContainer>
      <BoxTitle>{"Step " + step}</BoxTitle>
      <BoxContentContainer>
        <BoxLeftContent>
          <img src={icon ?? ""} alt={"Step " + step} width={20} />
        </BoxLeftContent>
        <BoxRightContent>
          <div>{title}</div>
          <CallToAction
            onClick={() => {
              handleShow(navItem);
            }}
          >
            {subtitle}
          </CallToAction>
        </BoxRightContent>
      </BoxContentContainer>
      {action}
    </BoxContainer>
  );
};
