import React from "react";
//Shared
import {
  StyledFormContent,
  StyledFormContentHeader,
  StyledFormDetails,
  StyledParagraph,
} from "../../../_shared/styles";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
import {isMobile} from "../../../../_shared/navigation";

interface ICheckYourEmailPage {
  toLogin: any;
  email: string;
}

const CheckYourEmailPage = (props: ICheckYourEmailPage) => {
  const {toLogin, email} = props;
  return (
    <StyledFormContent>
      <StyledFormDetails>
        <StyledFormContentHeader>Check your email</StyledFormContentHeader>
        <StyledParagraph>
          We just sent you an email with password reset instructions to the
          following email address: <b>{email}</b>
        </StyledParagraph>
        <div
          style={{
            marginTop: "1.8rem",
            textAlign: isMobile() ? "center" : "left",
          }}
        >
          <NatButton
            label={"Back to Login"}
            trackingId={"check-your-email-back-to-login"}
            option={StyleOption.PRIMARY}
            size={TextSize.NORMAL}
            type={"button"}
            clickEvent={toLogin}
            spinnerEnabled={false}
          />
        </div>
      </StyledFormDetails>
    </StyledFormContent>
  );
};

export {CheckYourEmailPage};
