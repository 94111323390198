import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import React from "react";
import {
  ColumnText,
  FinalRowContainer,
  FirstColumn,
  HeaderRowContainer,
  Opaque,
  RowContainer,
  SecondColumn,
  ThirdColumn,
} from "../styles/SiteWorkTableStyles";
import {TableRow} from "./SiteWorkTableRow";
import {Phase1BudgetSummary} from "./Phase1BudgetSummary";
import {SiteBudgetSummary} from "./SiteBudgetSummary";
import {UnitBudgetSummary} from "./UnitBudgetSummary";
import {getPricingForUnit} from "../../../_shared/ConfigurationPricingHelper";

export const OverallBudgetSummary = () => {
  const {configuration, configurationSite} = useCurrentProject();
  const totalSiteWorkCostInMicros =
    configurationSite?.totalSiteWorkCostInMicros ?? 0;
  const pricing = getPricingForUnit(configuration);
  return (
    <div>
      {/*<HeaderRowContainer className={"bold"}>*/}
      {/*  <Opaque />*/}
      {/*  <FirstColumn>*/}
      {/*    <ColumnText>Item Name</ColumnText>*/}
      {/*  </FirstColumn>*/}
      {/*  <SecondColumn>*/}
      {/*    <ColumnText>Price</ColumnText>*/}
      {/*  </SecondColumn>*/}
      {/*  <ThirdColumn>*/}
      {/*    <ColumnText>Description</ColumnText>*/}
      {/*  </ThirdColumn>*/}
      {/*</HeaderRowContainer>*/}
      <Phase1BudgetSummary showExtras={false} />
      <SiteBudgetSummary showExtras={false} />
      <UnitBudgetSummary showExtras={false} />
      <FinalRowContainer style={{marginTop: "-24px"}}>
        <TableRow
          items={[
            "TOTAL",
            priceTextFromMicros(
              pricing.fullUnitTotalPriceInMicros +
                totalSiteWorkCostInMicros +
                (configurationSite?.additionalLineItemCostInMicros ?? 0) +
                (configurationSite?.totalPhase1CostInMicros ?? 0),
              "min"
            ),
            "",
          ]}
        />
      </FinalRowContainer>
    </div>
  );
};
