import React, {useEffect, useMemo, useState} from "react";
import {DepositSubmissionJourneyStep} from "./DepositSubmissionJourneyStep";
import {
  DepositSubmissionJourneyDetailHeader,
  DepositSubmissionJourneyDetailImage,
  DepositSubmissionJourneyDetailInfo,
  DepositSubmissionJourneyDetailMessage,
  DepositSubmissionJourneyDetails,
  DepositSubmissionJourneySteps,
  PageStepper,
} from "../styles/submissionJourneyStyles";
import {
  ButtonContainer,
  DepositSubmissionJourneyContainer,
} from "../styles/preDeposit";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {
  CUSTOMER_STATUS,
  useCurrentCustomer,
} from "../../../../_shared/hooks/useCurrentCustomer";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {getJourneyList} from "../util/configJourneyList";
import {AiOutlineDoubleLeft, AiOutlineDoubleRight} from "react-icons/ai";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useIntercom} from "react-use-intercom";

const SCROLL_DIV_ID = "journey-steps-scroll";

export const DepositSubmissionJourney = React.memo(() => {
  const leftSize = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 5,
  });
  const rightSize = useDynamicValue({
    forFour: 4,
    forEight: 5,
    forTwelve: 7,
  });

  const fontSize = useDynamicValue({
    forFour: "1rem",
    forEight: "1rem",
    forTwelve: "1rem",
  });
  const journeyStepContainer = useDynamicValue({
    forFour: 0,
    forEight: 8,
    forTwelve: 12,
  });

  const {IS_MAPPING_ENABLED} = useGlobalConfiguration();
  const {customerStatus, isCustomerPreDeposit} = useCurrentCustomer();
  const {projectCompletion} = useCurrentProject();
  const {designStudio, sitePlan, proposal} = projectCompletion;
  const {
    contactInfoStatus,
    depositPaidStatus,
    productSelectedStatus,
    mappingStatus,
  } = customerStatus;
  const [journeyStep, setJourneyStep] = useState(0);
  const setJourneyStepChange = (step: number) => {
    setJourneyStep(step);
  };
  const [pagination, setPagination] = useState(0);
  const {update} = useIntercom();

  useEffect(() => {
    setPagination(isCustomerPreDeposit ? 0 : 1);
  }, [isCustomerPreDeposit]);

  const journeyList = useMemo(
    () =>
      getJourneyList(
        contactInfoStatus,
        productSelectedStatus,
        mappingStatus,
        depositPaidStatus,
        designStudio,
        sitePlan,
        proposal,
        IS_MAPPING_ENABLED,
        isCustomerPreDeposit,
        pagination
      ),
    [
      contactInfoStatus,
      depositPaidStatus,
      productSelectedStatus,
      mappingStatus,
      IS_MAPPING_ENABLED,
      pagination,
      designStudio,
      sitePlan,
      proposal,
      isCustomerPreDeposit,
    ]
  );

  useEffect(() => {
    let step = 0;
    for (let item of journeyList) {
      if (item.status === CUSTOMER_STATUS.COMPLETED) {
        step += 1;
      } else if (item.status === CUSTOMER_STATUS.IN_PROGRESS) {
        break;
      } else if (item.status === CUSTOMER_STATUS.DISABLED) {
        step -= 1;
        break;
      }
    }

    step = Math.min(Math.max(0, step), journeyList.length - 1);
    setJourneyStep(step);
    const currentStep = journeyList[step];
    update({
      customAttributes: {
        current_step: currentStep.type,
      },
    });

    const journeyScroll = document.getElementById(SCROLL_DIV_ID);
    if (journeyScroll != null) {
      // 160 is the step min-width
      journeyScroll.scrollLeft = 160 * step;
    }
  }, [journeyList]);

  const selectedStep = journeyList?.[journeyStep];
  const {isNatMobile} = usePage();
  return (
    <>
      {journeyList && (
        <DepositSubmissionJourneyContainer>
          <DepositSubmissionJourneySteps
            id={SCROLL_DIV_ID}
            shouldOverflow={journeyStepContainer === 0}
          >
            <PageStepper
              right={false}
              hidden={pagination === 0}
              onClick={() => {
                setPagination(0);
              }}
            >
              <AiOutlineDoubleLeft />
            </PageStepper>
            {journeyList.map((item, index) => {
              return (
                <DepositSubmissionJourneyStep
                  journeyStep={item}
                  selected={journeyStep === index}
                  step={index}
                  setJourneyStep={setJourneyStepChange}
                  key={item.type + index}
                />
              );
            })}

            <PageStepper
              right={true}
              hidden={pagination === 1}
              onClick={() => {
                setPagination(1);
              }}
            >
              <AiOutlineDoubleRight />
            </PageStepper>
          </DepositSubmissionJourneySteps>
          <DepositSubmissionJourneyDetails isMobile={isNatMobile()}>
            <PageElement size={leftSize} height={"auto"}>
              <DepositSubmissionJourneyDetailInfo>
                <DepositSubmissionJourneyDetailHeader>
                  {selectedStep?.detailHeader}
                </DepositSubmissionJourneyDetailHeader>
                <DepositSubmissionJourneyDetailMessage fontSize={fontSize}>
                  {selectedStep?.detailSubheader}
                </DepositSubmissionJourneyDetailMessage>
                <ButtonContainer hidden={selectedStep?.actionEvent == null}>
                  <NatButton
                    clickEvent={() => {
                      selectedStep?.actionEvent();
                    }}
                    trackingId={
                      "deposit-journey-step" + selectedStep
                        ? selectedStep?.type
                        : ""
                    }
                    label={selectedStep?.buttonText}
                    type={"button"}
                    option={
                      selectedStep?.status === CUSTOMER_STATUS.COMPLETED
                        ? StyleOption.WhiteWillFillBlack
                        : StyleOption.ColorWillDarken
                    }
                    spinnerEnabled={false}
                    id={selectedStep?.type}
                  />
                </ButtonContainer>
              </DepositSubmissionJourneyDetailInfo>
            </PageElement>
            <PageElement size={rightSize} height={"auto"}>
              <DepositSubmissionJourneyDetailImage
                src={selectedStep?.imageSrc}
              />
            </PageElement>
          </DepositSubmissionJourneyDetails>
        </DepositSubmissionJourneyContainer>
      )}
    </>
  );
});
