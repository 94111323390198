import React from "react";
import styled from "styled-components";
import {FaCheck} from "react-icons/fa";
import {colors, effect, FONT_FAMILY_MEDIUM} from "../styles/globals";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  LineSeparator,
  PreviousLineSeparator,
} from "../styles/submissionJourneyStyles";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {isMobile} from "../../../../_shared/navigation";
import {IJourneyStep, JourneyStepType} from "../util/configJourneyList";
import {CUSTOMER_STATUS} from "../../../../_shared/hooks/useCurrentCustomer";

interface Props {
  step: number;
  selected: boolean;
  setJourneyStep: any;
  journeyStep: IJourneyStep;
}

export const JourneyStep = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  display: inline-block;
  position: relative;
  border: 1px solid ${colors.journeyStepBorder};
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 133%;
  cursor: pointer;
  min-height: 4rem;
  &.complete {
    background: ${colors.journeyCompleteBackground};
  }
  &.selected {
    border: 1px solid ${colors.journeyStepSelectedBorder};
    box-shadow: ${effect.boxShadow};
  }
  &.locked {
    border: 1px solid ${colors.journeyStepBorder};
    opacity: 0.7;
    cursor: default;
  }
  &.overflow {
    flex-grow: 1;
    min-width: 160px;
    margin: auto;
    margin-right: 16px;
  }
`;

export const StatusContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: white;
  border: 1px solid #aaa;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  color: white;
  &.complete {
    background-color: ${colors.journeyStepStatusCompleteBackground};
    border: none;
  }
`;

export const Check = styled(FaCheck)`
  font-size: 0.5rem;
`;

export const CheckContainer = styled.div`
  height: 100%;
  width: 100%;
  line-height: 100%;
  text-align: center;
`;

export const StepHeader = styled.div`
  margin: 1rem 2rem 1rem 1rem;
  font-size: ${isMobile() ? "0.85rem" : "1rem"};
  color: black;
`;

const IconContainer = styled.div`
  color: black;
  font-size: 1rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
`;

export const DepositSubmissionJourneyStep = ({
  step,
  setJourneyStep,
  selected,
  journeyStep,
}: Props) => {
  const {icon, status, menuHeader} = journeyStep;
  const complete = status === CUSTOMER_STATUS.COMPLETED;
  const disabled = status === CUSTOMER_STATUS.DISABLED;
  const {IS_MAPPING_ENABLED} = useGlobalConfiguration();
  const sizeMultiplier = !IS_MAPPING_ENABLED ? 2 : 1;
  const {gutterWidthPixels} = usePage();
  const size = useDynamicValue({
    forFour: 0,
    forEight: 2 * sizeMultiplier,
    forTwelve: 3 * sizeMultiplier,
  });
  const classList = [];
  if (complete) classList.push("complete");
  if (selected) classList.push("selected");
  if (disabled) classList.push("locked");
  if (size === 0) {
    classList.push("overflow");
    return (
      <JourneyStep
        key={step.toString()}
        className={classList.join(" ")}
        onClick={() => {
          if (!disabled) {
            setJourneyStep(step);
          }
        }}
      >
        {journeyStep.type !== JourneyStepType.SUBMIT_CONTACT && (
          <PreviousLineSeparator width={16} />
        )}
        <StepHeader>{menuHeader}</StepHeader>
        <IconContainer>{icon}</IconContainer>
        <StatusContainer className={complete ? "complete" : ""}>
          <CheckContainer>
            <Check />
          </CheckContainer>
        </StatusContainer>
        {journeyStep.type === JourneyStepType.INITIAL_PROJECT_SUBMISSION && (
          <LineSeparator width={16} />
        )}
      </JourneyStep>
    );
  }

  return (
    <>
      <PageElement size={size} height={"auto"}>
        <JourneyStep
          key={step.toString()}
          className={classList.join(" ")}
          onClick={() => {
            if (!disabled) {
              setJourneyStep(step);
            }
          }}
        >
          {journeyStep.type !== JourneyStepType.SUBMIT_CONTACT && (
            <PreviousLineSeparator width={gutterWidthPixels} />
          )}
          <StepHeader>{menuHeader}</StepHeader>
          <IconContainer>{icon}</IconContainer>
          <StatusContainer className={complete ? "complete" : ""}>
            <CheckContainer>
              <Check />
            </CheckContainer>
          </StatusContainer>
          {journeyStep.type === JourneyStepType.INITIAL_PROJECT_SUBMISSION && (
            <LineSeparator width={gutterWidthPixels} />
          )}
        </JourneyStep>
      </PageElement>
    </>
  );
};
