import {usePage} from "./usePage";
import {useEffect, useState} from "react";

interface IDynamicValue {
  forFour: number | string;
  forEight: number | string;
  forTwelve: number | string;
}

export const fullColumnWidths: IDynamicValue = {
  forFour: 4,
  forEight: 8,
  forTwelve: 12,
};

export const useDynamicValue = (props: IDynamicValue) => {
  const {columnCount} = usePage();
  const [currentValue, setCurrentValue] = useState<any>(0);

  const determineValue = () => {
    switch (columnCount) {
      case 4:
        setCurrentValue(props.forFour);
        break;
      case 8:
        setCurrentValue(props.forEight);
        break;
      case 12:
        setCurrentValue(props.forTwelve);
        break;
    }
  };

  useEffect(() => {
    determineValue();
  }, [columnCount]);

  return currentValue;
};
