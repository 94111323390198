import React from "react";

export const Button = (props) => {
	const {componentClass, children, ...otherProps} = props;

	return (
		<button className={componentClass} {...otherProps}>
			{children}
		</button>
	);
};
