import React from "react";
import {Breadcrumb, BreadcrumbItem} from "./styles";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {
  updateProductGroup,
  updateProductId,
} from "../../../../_shared/catalog/ProductCatalogHelper";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../../_shared/generics/page/components/PageContainer";
import {usePage} from "../../../../_shared/hooks/usePage";
import {WHITE} from "../../../../_shared/colors";

export const Path = (props: {
  showMainView?: boolean;
  recommendedProducts?: boolean;
  allADUCallback?: () => void;
}) => {
  const {showMainView, recommendedProducts, allADUCallback} = props;
  const separator = "/";
  const {productGroupId, productId, productGroups, products} =
    useProductCatalog();
  const {isNatMobile} = usePage();

  const seeAllADUs = () => {
    updateProductGroup(null);
    updateProductId(null);
  };

  const seeProductGroup = () => {
    updateProductId(null);
  };

  const getProductGroupsBreadcrumb = () => {
    if (showMainView || recommendedProducts) {
      return (
        <BreadcrumbItem
          clickable={true}
          onClick={() => {
            seeAllADUs();
            if (allADUCallback) allADUCallback();
          }}
        >
          All ADUs
        </BreadcrumbItem>
      );
    } else {
      return <></>;
    }
  };

  const getProductGroupBreadcrumb = () => {
    if (productId) {
      return (
        <BreadcrumbItem clickable={true} onClick={() => seeProductGroup()}>
          {productGroups[productGroupId]?.title}
        </BreadcrumbItem>
      );
    } else {
      return (
        <BreadcrumbItem>{productGroups[productGroupId]?.title}</BreadcrumbItem>
      );
    }
  };

  const getProductBreadcrumb = () => {
    if (productId)
      return <BreadcrumbItem>{products[productId]?.title}</BreadcrumbItem>;
    else return <></>;
  };

  const getBreadcrumbPath = () => {
    if (recommendedProducts) {
      return (
        <PageContainer border={true}>
          <Breadcrumb isMobile={isNatMobile()} separator={separator}>
            {getProductGroupsBreadcrumb()}
            <BreadcrumbItem>Recommended ADUs</BreadcrumbItem>
          </Breadcrumb>
        </PageContainer>
      );
    } else if (
      (productGroupId && showMainView) ||
      (productId && productGroupId)
    ) {
      return (
        <PageContainer
          stickyOptions={{
            mode: PageContainerStickyMode.MODAL,
            offset: "64px",
          }}
          border={true}
          backgroundColor={WHITE}
        >
          <Breadcrumb isMobile={isNatMobile()} separator={separator}>
            {getProductGroupsBreadcrumb()}
            {getProductGroupBreadcrumb()}
            {getProductBreadcrumb()}
          </Breadcrumb>
        </PageContainer>
      );
    } else return <></>;
  };

  return <>{getBreadcrumbPath()}</>;
};
