import React, {useMemo} from "react";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {ProductList} from "../../_shared/components/ProductList";
import {Banner} from "../../_shared/components/Banner";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {HelpText} from "../MainView/styles";
import {FilterContainer} from "../../_shared/components/Filters/FilterContainer";
import {usePage} from "../../../_shared/hooks/usePage";
import {CatalogSectionDescription, CatalogTitle} from "../styles";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";

// Show product group info & list out products
export const ProductLineView = (props: any) => {
  const {productGroupId, showPath} = props;
  const {STREAMLINED_JOURNEY} = useGlobalConfiguration();
  const {isCustomerPreDeposit} = useCurrentCustomer();
  const {isNatMobile} = usePage();
  const {filteredProducts, productGroups} = useProductCatalog();
  const mainDescriptionWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 8,
  });

  const filterProductsByBedroom = (products: any[], bedroomCount: number) => {
    return products.filter((product: any) => {
      return product.productDetails?.bedrooms === bedroomCount;
    });
  };

  const oneBedroomProducts: any = useMemo(() => {
    if (filteredProducts && productGroups[productGroupId])
      return filterProductsByBedroom(filteredProducts, 1);
    else return [];
  }, [filteredProducts, productGroupId]);

  const twoBedroomProducts: any = useMemo(() => {
    if (filteredProducts && productGroups[productGroupId])
      return filterProductsByBedroom(filteredProducts, 2);
    else return [];
  }, [filteredProducts, productGroupId]);

  const threeBedroomProducts: any = useMemo(() => {
    if (filteredProducts && productGroups[productGroupId])
      return filterProductsByBedroom(filteredProducts, 3);
    else return [];
  }, [filteredProducts, productGroupId]);

  const getOffset = () => {
    let total = 0;
    if (showPath) {
      total += 42;
      if (isNatMobile()) total -= 4;
    }

    return total + 64 + "px";
  };

  const getTitleSection = () => {
    if (STREAMLINED_JOURNEY && isCustomerPreDeposit && !showPath) {
      return (
        <PageContainer>
          <PageElement size={mainDescriptionWidth} height={"auto"}>
            <CatalogTitle>Browse our homes</CatalogTitle>
            <CatalogSectionDescription>
              {
                "Find a model that you are interested in, and then design it in our Design Studio!"
              }
            </CatalogSectionDescription>
          </PageElement>
        </PageContainer>
      );
    } else {
      return (
        <Banner
          imageId={productGroups[productGroupId]?.imageId}
          title={productGroups[productGroupId]?.title}
          description={productGroups[productGroupId]?.displayDescription}
        />
      );
    }
  };

  return (
    <>
      {getTitleSection()}
      <FilterContainer offset={getOffset()} />
      <PageContainer>
        {oneBedroomProducts.length > 0 && (
          <ProductList
            title={"Single bedroom ADUs"}
            products={oneBedroomProducts}
          />
        )}
        {twoBedroomProducts.length > 0 && (
          <ProductList
            title={"Two bedroom ADUs"}
            products={twoBedroomProducts}
          />
        )}
        {threeBedroomProducts.length > 0 && (
          <ProductList
            title={"Three bedroom ADUs"}
            products={threeBedroomProducts}
          />
        )}
        {threeBedroomProducts.length === 0 &&
          twoBedroomProducts.length === 0 &&
          oneBedroomProducts.length === 0 && (
            <HelpText>No ADUs match your filters.</HelpText>
          )}
      </PageContainer>
    </>
  );
};
