import React from "react";
import {
  FirstColumn,
  FirstColumnContent,
  FirstColumnRow,
  HeaderAddButton,
  HeaderRow,
  HeaderTitle,
} from "../../styles/AdminStyles";
import {AiOutlinePlus} from "react-icons/all";

export interface TabColumnProps {
  title: string;
  width: string;
  headerAddAction: () => void;
  rows: TabColumnRow[];
}

export interface TabColumnRow {
  title: string;
  key: string;
  data: any;
  action: (data: any) => void;
  checkIfActive: (key: string) => boolean;
}

export const TabColumn = (props: TabColumnProps) => {
  const {title, headerAddAction, rows, width} = props;

  const getTabColumnRows = () => {
    return rows?.map((r: TabColumnRow) => {
      return (
        <FirstColumnRow
          key={r.key}
          onClick={() => r?.action(r?.data)}
          active={r.checkIfActive(r.key)}
        >
          {r.title}
        </FirstColumnRow>
      );
    });
  };

  return (
    <FirstColumn width={width}>
      <HeaderRow>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderAddButton onClick={() => headerAddAction()}>
          <AiOutlinePlus />
        </HeaderAddButton>
      </HeaderRow>
      <FirstColumnContent>{getTabColumnRows()}</FirstColumnContent>
    </FirstColumn>
  );
};
