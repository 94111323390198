import styled from "styled-components";
import {BREAKPOINT_DESKTOP_SMALL} from "../../theme/constants";
import {VALID_COLOR} from "../../../../_shared/colors";
import {colors} from "../../theme/constants";

export const MapViewContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse;
  }
  #map__back-btn {
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.textGrey};
    height: 40px;
    padding: 20px;
    background: #ffffff;
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 4999;
    svg {
      margin-right: 8px;
    }
  }
  .leaflet-right .leaflet-control {
    margin-right: 24px;
  }
  .leaflet-control-zoom.leaflet-control {
    margin-bottom: 117px;
  }
  @media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
    .leaflet-right .leaflet-control {
      margin-right: 24px;
    }
    .leaflet-control-zoom.leaflet-control {
      margin-bottom: 22px;
    }
  }
`;

export const MapContainerWrapper = styled.div`
  flex: ${(props) => (props.isListExpanded ? "0" : "1")};
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  transition: flex 0.4s;
  .leaflet-container {
    z-index: 0;
  }
  @media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
    height: 100%;
    max-height: 100vh;
  }
`;

export const UserFeedbackContainer = styled.div`
  max-width: 320px;
  position: absolute;
  top: ${(props) => (props.minimal ? "10px" : "30px")};
  left: ${(props) => (props.minimal ? "10px" : "30px")};
  z-index: 4999;
  background: ${(props) =>
    props.type === "success"
      ? VALID_COLOR
      : props.type === "neutral"
      ? colors.textGrey
      : colors.aduRed};
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: ${(props) => (props.type === "success" ? "#fff" : "#fff")};
  padding: 8px 16px;
`;

export const DisclaimerContainer = styled.div`
  max-width: 320px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 4999;
  background: white;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #000;
  padding: 8px 16px;
`;

export const MapToolbarContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 0;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    width: 40px;
    padding: 0;
    height: 40px;
    background: #ffffff;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  @media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
    bottom: 40px;
    right: 172px;
  }
`;
