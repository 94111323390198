import React, {useMemo} from "react";
//Hooks
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
//Assets
import image from "../../../../assets/images/photos/exteriorFeatures2.jpg";
import adu from "../../../../assets/images/trimmed/450_HUD_GABLED_LAPPED.png";
//Shared
import {
  HelperText,
  NoProductContainer,
  WaitingGraphic,
  DesignBlurb,
} from "./styles";
import {NatButton} from "../../../_shared/generics/button";
import {StyleOption} from "../../../_shared/generics/_shared";
import {toCatalog, toDesignStudio} from "../../../_shared/navigation";
import waiting_graphic from "../../../../assets/images/graphics/waiting_graphic.png";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {YourADU} from "../PaymentsView/components/YourADU";
import {ADUBlurb} from "../PaymentsView/components/ADUBlurb";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {
  OVERVIEW_ASSESSMENT_PAGE_ID,
  toPage,
} from "../../_shared/navigation/PageNavigation";
import {GiPadlock} from "react-icons/gi";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {DesignStudioView} from "../DesignStudioView";
import {getUnitPriceInMicros} from "../../../design-tool/logic/ProductUtils";

export const YourUnitView = () => {
  const {configuration, configurationSnapshot} = useCurrentProject();
  const {STREAMLINED_JOURNEY} = useGlobalConfiguration();
  const product = useMemo(() => {
    if (configuration?.product) return configuration.product;
    else return null;
  }, [configuration]);

  const dynamicWidth = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 4,
  });

  const fullYourADUContentSize = useDynamicValue({
    forFour: 4,
    forEight: 8,
    forTwelve: 12,
  });

  const blurbComponentSize = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });

  if (STREAMLINED_JOURNEY && configurationSnapshot) {
    return <DesignStudioView />;
  } else {
    return (
      <>
        <PageContainer>
          {product ? (
            <>
              <ADUBlurb
                imageId={product?.imageId}
                title={product?.title}
                description={product?.displayDescription}
                tagline={product?.tagline}
                size={blurbComponentSize}
                showChangeButton={true}
              />
              <PageElement size={fullYourADUContentSize} height={"auto"}>
                <YourADU
                  title={product?.title}
                  frontLength={
                    product?.productDetails.dimensions.frontLength.feet
                  }
                  sideLength={
                    product?.productDetails.dimensions.sideLength.feet
                  }
                  bedroomCount={product?.productDetails.bedrooms}
                  bathroomCount={product?.productDetails.bathrooms}
                  squareFootage={product?.productDetails.squareFeet}
                  priceMicros={getUnitPriceInMicros(product)}
                />
              </PageElement>
            </>
          ) : (
            <NoProductContainer>
              <WaitingGraphic
                src={waiting_graphic}
                alt={"Site Plan Image Missing"}
              />
              <NatButton
                trackingId={"your-unit-to-catalog"}
                label={"Let's look at some ADUs!"}
                type={"button"}
                option={StyleOption.ColorWillDarken}
                clickEvent={toCatalog}
                spinnerEnabled={false}
              />
            </NoProductContainer>
          )}
        </PageContainer>
        <PageContainer
          marginTop={"auto"}
          backgroundImage={{image: image, height: "40rem"}}
        >
          <DesignBlurb>
            <PageElement size={dynamicWidth} height={"auto"}>
              <img src={adu} alt={""} />
              {STREAMLINED_JOURNEY ? (
                <>
                  <HelperText size={"1.25rem"} bold={true} align={"center"}>
                    Design Studio
                  </HelperText>
                  <HelperText size={"1.05rem"} align={"center"}>
                    Configure and upgrade your dream home
                  </HelperText>
                  <NatButton
                    label={"Design your home"}
                    trackingId={"your-unit-to-design-studio"}
                    type={"button"}
                    option={StyleOption.ColorWillDarken}
                    clickEvent={() => toDesignStudio()}
                    spinnerEnabled={false}
                  />
                </>
              ) : (
                <>
                  <HelperText size={"1.25rem"} bold={true} align={"center"}>
                    <GiPadlock style={{color: "gray", marginRight: "0.5rem"}} />
                    Design Studio
                  </HelperText>
                  <HelperText size={"1rem"} align={"center"}>
                    Submit your project to unlock the full features of the
                    Design Studio and customize your ADU to your liking.
                  </HelperText>
                  <NatButton
                    label={"Get a proposal"}
                    trackingId={"your-unit-get-a-proposal"}
                    type={"button"}
                    option={StyleOption.ColorWillDarken}
                    clickEvent={() => toPage(OVERVIEW_ASSESSMENT_PAGE_ID)}
                    spinnerEnabled={false}
                  />
                </>
              )}
            </PageElement>
          </DesignBlurb>
        </PageContainer>
      </>
    );
  }
};
