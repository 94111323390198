import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
  setAddress,
  setCity,
  setCountry,
  setCounty,
  setLatitude,
  setLongitude,
  setState,
  setStreet,
  setStreetNumber,
  setZip,
} from "../slices/IntakeFormSlice";
import {CheckMark} from "../../_shared/generics/CheckMark";
import {Button} from "../components/button";

let autoComplete;
let selectedStreet;

//dynamically load the javascript file in the html with the callback when finished
const loadScript = (url, callback) => {
  let script = document.createElement("script"); //create script tag
  script.type = "text/javascript"; //set the type

  //when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; //load by url
  document.getElementsByTagName("head")[0].appendChild(script); //append to head
};

//handle when the script is loaded we will assign autoCompleteRef with google maps autocomplete
//were passing in setQuery as updateQuery, the ref of the input and passing in dispatch function, also passing setAddressError so if incomplete address is sent, we can display error message
//passing in nextStep to handle the next step when somone chooses a verified address
function handleScriptLoad(
  updateQuery,
  autoCompleteRef,
  dispatch,
  setAddressError,
  nextStep,
  setVerifiedAddress
) {
  //assign autoComplete with google maps place one time
  autoComplete = new window.google.maps.places.Autocomplete(
    //set the type of data we want and the fields associated with that type
    autoCompleteRef.current,
    {
      types: ["address"],
      componentRestrictions: {country: "us"},
    }
  );

  //Everytime user add/remove a character, call handlePlaceSelect to update predictions
  autoComplete.addListener("place_changed", () => {
    //when looking for address, set to false
    /*  setVerifiedAddress(false) */

    //continues to pass on setQuery function and dispatch function and setAddressError to finally use them in the next function
    handlePlaceSelect(
      updateQuery,
      dispatch,
      setAddressError,
      nextStep,
      setVerifiedAddress
    );
  });
}

async function handlePlaceSelect(
  updateQuery,
  dispatch,
  setAddressError,
  nextStep,
  setVerifiedAddress
) {
  //when user selects a prediction, grab the data from that selection and save it
  const addressObject = autoComplete.getPlace();

  //save the full address using the formatted_address field in object
  const full_address = addressObject.formatted_address;

  //if full_address comes back null/undefined, which means an incomplete address was sent, catch it and unhide error message by setting setAddressError(true)
  //and returning to prevent continuation of function
  if (!full_address) {
    setAddressError(true);
    setVerifiedAddress(false);
    return;
  } else if (
    !addressObject.types ||
    (!addressObject.types.includes("street_address") &&
      !addressObject.types.includes("premise"))
  ) {
    setAddressError(true);
    setVerifiedAddress(false);
    return;
  }

  let route;
  let street_number;
  let zipcode;
  let city;
  let state;
  let county;
  let country;

  //grabs address_components array and filters through to grab pieces of address information
  addressObject.address_components.forEach((item) => {
    if (item.types.includes("street_number")) {
      street_number = item.long_name;
    } else if (item.types.includes("route")) {
      route = item.long_name;
    } else if (item.types.includes("locality")) {
      city = item.long_name;
    } else if (item.types.includes("administrative_area_level_2")) {
      county = item.long_name;
    } else if (item.types.includes("administrative_area_level_1")) {
      state = item.short_name;
    } else if (item.types.includes("postal_code")) {
      zipcode = item.long_name;
    } else if (item.types.includes("country")) {
      country = item.short_name;
    }
  });

  //using the setQuery function passed on as updateQuery to update local state
  updateQuery(full_address);

  //update state redux with options grabbed here from dispatch function that passed through
  dispatch(setAddress(full_address));
  dispatch(setStreetNumber(street_number));
  dispatch(setCity(city));
  dispatch(setZip(zipcode));
  dispatch(setCounty(county));
  dispatch(setState(state));
  dispatch(setCountry(country));
  dispatch(setLatitude(addressObject.geometry.location.lat()));
  dispatch(setLongitude(addressObject.geometry.location.lng()));
  selectedStreet = route;

  //set setAddressError to false in case it has been triggered before
  setAddressError(false);

  //set verified address to true
  setVerifiedAddress(true);
}

export const SearchLocationInput = (props) => {
  const [query, setQuery] = useState("");
  const [addressError, setAddressError] = useState(false);
  const {nextStep} = props;
  const [verifiedAddress, setVerifiedAddress] = useState(false);
  const autoCompleteRef = useRef(null);
  const dispatch = useDispatch();

  //Checks to see if verifiedAddress is true and that value inside input is not 0
  const onClick = () => {
    if (verifiedAddress === true && autoCompleteRef.current.value.length) {
      dispatch(setStreet(selectedStreet));
      return;
    }
    setVerifiedAddress(false);
    setAddressError(true);
  };

  const verifiedIcon = (
    <div id={"verified-icon"}>
      <CheckMark size={18} strokeWidth={2} />
    </div>
  );
  const nextArrow = (
    <img
      onClick={onClick}
      src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Farrow-right.svg?alt=media&token=9a519294-124a-4aee-93d3-f6db44bdc68e"
      id="next-arrow"
      alt="next step arrow"
    />
  );

  useEffect(() => {
    handleScriptLoad(
      setQuery,
      autoCompleteRef,
      dispatch,
      setAddressError,
      nextStep,
      setVerifiedAddress
    );
  }, [dispatch, nextStep]);

  return (
    <div className="search-location-input">
      <div className="intake-input-icons-wrapper">
        <input
          ref={autoCompleteRef}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Enter street address"
          defaultValue={query}
          id="address-input"
          autoFocus={true}
          className={addressError === true ? "error-input" : ""}
        />
        {verifiedAddress ? verifiedIcon : ""}
        {nextArrow}
      </div>
      <div className={addressError === true ? "error-message" : "hidden"}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FAlert.svg?alt=media&token=0ccb15d9-7f9b-4d8d-9791-44e17df3af2f"
          alt="error icon"
        />
        <p>Please submit a complete address.</p>
      </div>

      <Button
        onClick={onClick}
        style={{marginTop: "20px"}}
        buttonClasses={
          "yellow-btn half-width " +
          (!verifiedAddress || addressError ? "disabled" : "")
        }
      >
        Continue
      </Button>
    </div>
  );
};
