import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/esm/Container";
import {Underline} from "../../design-tool/components/underline";

export const PopUpModal = (props) => {
	//grabs the step that controls which tab to open on the modal pop-up
	const {initialStep, navItems} = props;
	const [step, setStep] = useState(initialStep);

	useEffect(() => {
		setStep(initialStep);
	}, [initialStep]);

	let content = navItems[0].content;
	for (const item of navItems) {
		if (item.value === step) {
			content = item.content;
		}
	}

	return (
		<div className="modal-wrapper">
			<Modal show={props.show} onHide={props.handleClose} centered size="md">
				<Modal.Header closeButton>
					<Container className="step-bar" style={{marginTop: "0px"}}>
						{navItems.map((item) => (
							<p
								className={step === item.value ? "active" : ""}
								data-value={item.value}
								onClick={() => {
									setStep(item.value);
								}}
								key={item.value}
							>
								{item.title}
							</p>
						))}

						<Underline className="base-underline" />
					</Container>
				</Modal.Header>

				<Modal.Body>
					<div className={"modal-text"}>{content}</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
