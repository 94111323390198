export enum SortModes {
  DEFAULT,
}

/*
 * Default sorting of the products is by price, and then title as a tie-settler.
 * */
const defaultSort = (products: any[]) => {
  return products.sort(function (a: any, b: any) {
    if (a.priceMicros === b.priceMicros) return a.title.localeCompare(b.title);
    else return a.priceMicros - b.priceMicros;
  });
};

export const sort = (
  products: any[] = [],
  mode: SortModes = SortModes.DEFAULT
) => {
  if (mode == SortModes.DEFAULT) return defaultSort(products);
  else return products;
};
