import styled from "styled-components";
import {FormLayout} from "../_shared/generics/form/FormLayout";

const StyledFormLayout = styled(FormLayout)`
   {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export {StyledFormLayout};
