import React from "react";
import {SmartImageWithId} from "../SmartImage";
import "../styles.scss";
import styled from "styled-components";
import {DesignButton, ImageContainer, TextContainer} from "./styles";

export const ImageTextButton = (props) => {
  return (
    <FullWidthImageContainer
      onClick={props.onClick}
      active={props.isSelected === true}
    >
      <ImageContainer>
        <SmartImageWithId imageId={props.imageId} size={"300"} alt="stuff" />
      </ImageContainer>
      <TextContainer>
        <p>{props.buttonText}</p>
        <p>{props.price}</p>
      </TextContainer>
    </FullWidthImageContainer>
  );
};

const FullWidthImageContainer = styled(DesignButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 18px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.25s all;
  min-height: 54px;
`;
