import React, {useEffect, useRef} from "react";
import CountUp from "react-countup";
import {
  getBasePriceForDesignStudio,
  getBedBathString,
  getSqFtString,
} from "../logic/ProductUtils";
import {useProductCatalog} from "../../_shared/hooks/useProductCatalog";
import {useDesignStudio} from "../../_shared/hooks/useDesignStudio";

export const MobilePrice = () => {
  const {product} = useProductCatalog();
  const {orderSummary} = useDesignStudio({summary: true});
  const updatedPrice =
    (getBasePriceForDesignStudio(product) + orderSummary.upgradesInMicros) /
    100;

  //Splits up description of unit so we can have custom spacing and remove a piece on mobile due to space
  let squareFeetText = getSqFtString(product);
  let smallDescText = getBedBathString(product);
  let productTitle = "";

  if (product != null) {
    productTitle = product.title;
  }
  const previousPrice = usePrevious(updatedPrice);

  return (
    <div className="mobile-description-wrapper mobile-appear">
      <div className="unit-wrapper">
        <div className="bottom-bar-bold" style={{textAlign: "left"}}>
          {productTitle}
        </div>
        <div className="unit-description">
          <p className="small-desc">{smallDescText}</p>
        </div>
      </div>

      {/*<div className="bottom-bar-seperator" />*/}

      <div
        className="price-wrapper"
        style={{textAlign: "right", width: "120px"}}
      >
        <CountUp
          start={previousPrice}
          end={updatedPrice}
          separator=","
          prefix="$"
          suffix="*"
          duration={0.5}
        />
        <p>*excl. constr. site work costs</p>
      </div>
    </div>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
