import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {
  getPriceMicrosFromTextToSave,
  getTextFromPriceMicros,
} from "@natomas/core";
import {isBlankString} from "@natomas/core";
import {
  ModifierAdminStructure,
  ModifierAdvancedAdminStructure,
} from "../../../../design-tool/models/ModifierModel";
import {
  deleteModifierFromProductGroup,
  saveModifierToDB,
} from "../../../../design-tool/backend/catalogApi";
import {editModifier} from "../../../../_shared/slices/CatalogSlice";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {AdminForm, MainContent, MainView} from "../../styles/AdminStyles";
import {AdminInput} from "../../components/AdminComponentFactory";
import {EditorHeader} from "../../components/EditorHeader";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {IStore} from "../../../../_shared/slices/types/Store";

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "other", title: "Advanced Details"},
];

const getInitialValues = (obj: any) => {
  const initialValues = getFormInitialValues(ModifierAdminStructure);
  if (obj != null) {
    for (const key in ModifierAdminStructure) {
      // @ts-ignore
      const keyInfo = ModifierAdminStructure[key];
      const value = obj[key];
      let initialValue = value;
      if (keyInfo.subType === "price") {
        initialValue = getTextFromPriceMicros(value);
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProductGroupModifier = () => {
  const editingModifier = useSelector(
    (state: IStore) => state.catalog.editingModifier
  );
  const {currentGroupModifiers} = useProductCatalog();
  const modifier =
    currentGroupModifiers != null &&
    currentGroupModifiers[editingModifier?.id] != null
      ? currentGroupModifiers[editingModifier?.id]
      : editingModifier;

  const dispatch = useDispatch();
  const [tab, setTab] = useState(tabs[0].id);
  const close = () => {
    dispatch(editModifier(null));
  };
  const deleteModifierFromGroupAction = (e: any) => {
    e.preventDefault();
    deleteModifierFromProductGroup(modifier);
    close();
  };

  const initialValues = getInitialValues(modifier);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      const modifierToSave = Object.assign({}, values);
      modifierToSave.id = modifier.id;
      if (isBlankString(modifierToSave.imageId)) {
        modifierToSave.imageId = null;
      }

      modifierToSave.defaultPriceMicros = getPriceMicrosFromTextToSave(
        modifierToSave.defaultPriceMicros
      );

      return saveModifierToDB(modifierToSave);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [modifier]);

  if (modifier == null) {
    return null;
  }

  const modifierStructureForForm = getFormStructure(ModifierAdminStructure);

  let mainContent;
  if (tab === "details") {
    mainContent = (
      <AdminForm onSubmit={formik.handleSubmit}>
        {modifierStructureForForm.map((keyInfo) => {
          const {key} = keyInfo;
          // @ts-ignore
          const value = formik.values[key];
          // @ts-ignore
          if (ModifierAdvancedAdminStructure[key] != null) {
            return null;
          }
          // @ts-ignore
          let initial = initialValues[key];
          return (
            <AdminInput
              key={key}
              value={value}
              initialValue={initial}
              fieldInfo={keyInfo}
              onChange={(value: any) => {
                formik.setFieldValue(key, value);
              }}
              handleChange={formik.handleChange}
            />
          );
        })}
      </AdminForm>
    );
  } else {
    mainContent = (
      <AdminForm onSubmit={formik.handleSubmit}>
        {modifierStructureForForm.map((keyInfo) => {
          const {key} = keyInfo;
          // @ts-ignore
          const value = formik.values[key];
          // @ts-ignore
          if (ModifierAdvancedAdminStructure[key] == null) {
            return null;
          }

          return (
            <AdminInput
              key={key}
              value={value}
              fieldInfo={keyInfo}
              onChange={(value: any) => {
                formik.setFieldValue(key, value);
              }}
              handleChange={formik.handleChange}
            />
          );
        })}
      </AdminForm>
    );
  }

  return (
    <MainView>
      <EditorHeader
        tabs={tabs}
        setTab={setTab}
        isUpToDate={isDeepEqual(formik.values, initialValues)}
        deleteAction={
          modifier?.id != null ? deleteModifierFromGroupAction : null
        }
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        saveAction={() => {
          return formik.submitForm();
        }}
      />
      <MainContent>{mainContent}</MainContent>
    </MainView>
  );
};
