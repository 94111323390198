import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/DashboardView/styles/globals";

const ProductCardTitle = styled.div`
   {
    font-size: 1.25rem;
    font-style: normal;
    align-items: flex-start;
    font-weight: bolder;
    padding-top: 0.5rem;
  }
`;

const ProductCardInfoRow = styled.div`
   {
    font-size: 1rem;
    font-family: ${FONT_FAMILY_BOLD};
    font-style: normal;
    align-content: center;
    font-weight: normal;
    color: #555555;
    margin-bottom: 0.25rem;
  }
`;

const ProductCardPriceRow = styled.div`
   {
    font-size: 1rem;
    font-style: normal;
    align-content: center;
    font-weight: normal;
    color: #555555;
    margin-bottom: 0.25rem;
  }
`;

const ProductCardImage = styled.div<{height: string}>`
  height: ${(props) => props.height};
  position: relative;
  .fullDiv {
    border-radius: 1rem;
  }
`;

const HoverOverImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 15;
  .fullDiv {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  &:hover {
    background-color: white;
  }
  &:hover .fullDiv {
    opacity: 1;
  }
`;

const ProductContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export {
  ProductCardTitle,
  ProductCardImage,
  ProductCardInfoRow,
  ProductContents,
  HoverOverImage,
  ProductCardPriceRow,
};
