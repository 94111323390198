import React from "react";
import "../styles.scss";
import {isBlankString} from "@natomas/core";
import styled from "styled-components";
import {DesignSquareButton} from "./styles";
import {SmartImageWithId} from "../SmartImage";

export const ColorButton = (props) => {
  let style = {};
  if (!isBlankString(props.firstColor) && !isBlankString(props.secondColor)) {
    style.background = `linear-gradient(135deg, ${props.firstColor} 50%, ${props.secondColor} calc(50% + 1px))`;
  } else {
    style.backgroundColor = props.firstColor ?? props.secondColor;
  }

  if (isBlankString(props.firstColor) && props.imageId) {
    style.width = "100px";
    style.height = "100px";
    return (
      <ColorTextButtonWrapper
        active={props.isSelected}
        onClick={props.onClick}
        style={style}
      >
        <SmartImageWithId imageId={props.imageId} />
      </ColorTextButtonWrapper>
    );
  }

  return (
    <ColorTextButtonWrapper
      active={props.isSelected}
      onClick={props.onClick}
      style={style}
    />
  );
};
const ColorTextButtonWrapper = styled(DesignSquareButton)`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  outline: none;
  transition: all 0.35s;
  font-weight: 500;
  border-image-outset: 2px;
  margin-top: 15px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
`;
