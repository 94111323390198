import WebinarImage from "../assets/watch-video-webinar-webinar.png";
import PortalImage from "../assets/watch-video-webinar-portal.png";
import ScheduleImage from "../assets/helpful-resource-schedule.png";
import ShowingImage from "../assets/helpful-resource-showing.png";
import ChatImage from "../assets/helpful-resource-chat.jpg";
import {
  CalendlyEventTypes,
  Customer,
  formatPhoneNumber,
  ICustomerInfo,
  PhoneNumberFormatType,
} from "@natomas/core";
import {isProd} from "../../../../_shared/application";
import {getSalesPhoneNumber} from "../../../../_shared/constants/sales";

const NOT_APPLICABLE = "N/A";
const statusList: {[key: string]: string} = {
  "not-started": "Not Started",
  "started": "Started",
  "complete": "Completed",
};
const DEV_CALENDLY = "https://calendly.com/hmound/15min";

export const getStatusList = () => statusList;
export const getVideoList = (
  region: string,
  actions: any,
  config: any
): WatchVideoProps[] => {
  const cleanedActions = actions ?? {};
  const list = [];
  list.push(getWatchTutorial(cleanedActions, config));
  list.push(getWatchWebinar(region, cleanedActions));
  return list;
};
export const getResourceList = (
  customer: ICustomerInfo,
  state: string,
  assessmentEnabled: boolean,
  modelHomeTourEnabled: boolean,
  intercomEnabled: boolean
) => {
  const list = [];
  if (assessmentEnabled) {
    list.push(
      helpfulResourceScheduleAssessment(
        Customer.isAssessmentScheduled(customer),
        state
      )
    );
  } else {
    list.push(helpfulResourceCall(state));
  }

  if (modelHomeTourEnabled) {
    list.push(
      helpfulResourceScheduleModelHomeTour(
        Customer.isShowingScheduled(customer),
        state
      )
    );
  }
  if (intercomEnabled) {
    list.push(helpfulResourceChat);
  } else {
    list.push(helpfulResourceEmail());
  }
  return list;
};

/**
 * Define Call To Action Cards
 *
 *  currently there are two card types:
 *    1. watch-video
 *    2. helpful-resource
 *
 **/
/** Watch Video definition List **/
export interface WatchVideoProps {
  type: string;
  target: string;
  title: string;
  msg: string;
  buttonText: string;
  image: string;
  videoUrl: string;
  actionId: string;
  region: string;
  status?: string;
  statusMsg?: string;
}

const getWatchWebinar = (region: string, actions: any): WatchVideoProps => {
  const webinarWatchedStatus: string =
    actions["introductory_webinar_watched"] &&
    actions["introductory_webinar_watched"]["status"]
      ? actions["introductory_webinar_watched"]["status"]
      : "not-started";

  let videoURL;
  switch (region) {
    case "TX":
      videoURL = "https://vimeo.com/645773122?";
      break;
    default:
      videoURL = "https://vimeo.com/665722953";
      break;
  }

  // TODO for now just show this
  videoURL = "https://vimeo.com/665722953";

  return {
    type: "watch-video",
    target: "webinar",
    title: `${region} Webinar`,
    msg: "Learn about our products and process from experts!",
    buttonText: "Watch",
    image: WebinarImage,
    videoUrl: videoURL,
    actionId: "introductory_webinar_watched",
    region: region,
    status: webinarWatchedStatus,
    statusMsg: statusList[webinarWatchedStatus],
  };
};

const getWatchTutorial = (actions: any, config: any): WatchVideoProps => {
  const portal_tutorial_status: string =
    actions["portal_tutorial_watched"] &&
    actions["portal_tutorial_watched"]["status"]
      ? actions["portal_tutorial_watched"]["status"]
      : "not-started";

  const videoURL =
    config["WELCOME_VIDEO_URL"] ??
    "https://www.youtube.com/watch?v=EEnOY4OSUvU";

  return {
    type: "watch-video",
    target: "portal",
    title: "Welcome to Villa",
    msg: "Here's an introduction into your new dashboard.",
    buttonText: "Watch",
    image: PortalImage,
    videoUrl: videoURL,
    actionId: "portal_tutorial_watched",
    region: NOT_APPLICABLE,
    status: portal_tutorial_status,
    statusMsg: statusList[portal_tutorial_status],
  };
};

/** Helpful Resource List definition List **/
export interface HelpfulResourcesProps {
  target: string;
  title: string;
  msg: string;
  buttonText: string;
  isComplete: boolean;
  status: string;
  statusMsg: string;
  image: string;
  link: string;
}

const helpfulResourceScheduleModelHomeTour = (
  complete: boolean,
  region: string
): HelpfulResourcesProps => {
  let link;
  switch (region) {
    default:
      link =
        "https://calendly.com/habitat_adu/model-home-tour-mp?month=2021-12";
      break;
  }
  if (!isProd) {
    link = DEV_CALENDLY;
  }

  return {
    target: CalendlyEventTypes.SHOWING,
    title: "Book a model home tour",
    msg: complete
      ? "Model Home Tour Scheduled"
      : "Visit our model ADU homes and knock on the walls, jump on the floors, and touch the tile.",
    buttonText: "Book a tour",
    isComplete: complete,
    status: complete ? "complete" : "",
    statusMsg: complete ? "complete" : "",
    image: ShowingImage,
    link,
  };
};

const helpfulResourceChat: HelpfulResourcesProps = {
  target: "chat",
  title: "Chat with an expert",
  msg: "Chat with us if you have any questions about choosing your ADU.",
  buttonText: "Chat now",
  isComplete: false,
  status: "",
  statusMsg: "",
  image: ChatImage,
  link: "",
};

const helpfulResourceEmail = (): HelpfulResourcesProps => {
  return {
    target: "link",
    title: "Message our team",
    msg: "Email us if you have any questions about choosing your ADU.",
    buttonText: "Start message",
    isComplete: false,
    status: "",
    statusMsg: "",
    image: ChatImage,
    link: "mailto:hello@villahomes.com",
  };
};

const helpfulResourceScheduleAssessment = (
  complete: boolean,
  region: string
): HelpfulResourcesProps => {
  let link;
  switch (region) {
    case "TX":
      link =
        "https://calendly.com/habitat_adu/villa-homes-austin-adu-assessment";
      break;
    default:
      link = "https://my-adu.link/meet";
      break;
  }
  if (!isProd) {
    link = DEV_CALENDLY;
  }

  return {
    target: CalendlyEventTypes.ASSESSMENT,
    title: "Schedule a call",
    msg: complete
      ? "Assessment Scheduled!"
      : "Get on a call with one of our experts and get your questions answered.",
    buttonText: "Schedule now",
    isComplete: complete,
    status: complete ? "complete" : "",
    statusMsg: complete ? "complete" : "",
    image: ScheduleImage,
    link: link,
  };
};

const helpfulResourceCall = (state: string): HelpfulResourcesProps => {
  const phoneNumber = getSalesPhoneNumber(state);
  const buttonText = `Call ${
    formatPhoneNumber(
      phoneNumber,
      PhoneNumberFormatType.PARENTHESIS_AND_DASHES
    ) ?? ""
  }`;
  return {
    target: "link",
    title: "Have any questions?",
    msg: "Get on a call with one of our experts and get your questions answered.",
    buttonText: buttonText,
    isComplete: false,
    status: "",
    statusMsg: "",
    image: ScheduleImage,
    link: "tel:" + phoneNumber,
  };
};
