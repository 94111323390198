import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CurrentStep} from "../logic/currentStep";
import {CurrentImage} from "../logic/currentImage";
import {Col, Container, Row} from "react-bootstrap";
import {NavStepper} from "../components/navStepper";
import {BackButton} from "../components/backButton";
import {Button} from "../components/button";
import "./styles.scss";
import {useFormik} from "formik";
import {getFormInitialValuesFromMap, isDev} from "../../_shared/application";
import {
  FIRST_NAME_KEY,
  GENERAL_INTAKE_FORM,
  GENERAL_INTAKE_FORM_WITH_PASSWORD,
  LAST_NAME_KEY,
  PHONE_NUMBER_KEY,
  EMAIL_KEY,
} from "../../_shared/forms/FormModels";
import {setStep, setStreet} from "../slices/IntakeFormSlice";
import {getParameter} from "../../_shared/cookies";
import {HiddenInput} from "../components/hiddenInput";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {constructValidation} from "../../_shared/forms/FormHelper";
import {useIntakeFormValues} from "../logic/GeneralIntakeForm";
import {intakeFormBackAction} from "../logic/navigation";
import {randomId} from "@natomas/core";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {toPortal} from "../../_shared/navigation";

const getInitialStepsTaken = () => {
  if (getParameter("street", true) == null) {
    return ["address"];
  }

  return [];
};

function getIntakeFormValues(withPassword) {
  if (withPassword) {
    return GENERAL_INTAKE_FORM_WITH_PASSWORD;
  }
  return GENERAL_INTAKE_FORM;
}

export const IntakeFormLayout = () => {
  const {customer} = useCurrentCustomer();
  const step = useSelector((state) => state.intakeForm.step);
  const {IS_SSD_LIVE, IS_SSD_LIVE_ON_INTAKE} = useGlobalConfiguration();
  const shouldUseSSD = IS_SSD_LIVE && IS_SSD_LIVE_ON_INTAKE;
  const nodeRef = React.useRef(null);
  const otherRef = React.useRef(null);
  const [stepsTaken, setStepsTaken] = useState(getInitialStepsTaken());

  useEffect(() => {
    if (customer) toPortal();
  }, [customer]);

  const form = getIntakeFormValues(shouldUseSSD);
  const initialValues = getFormInitialValuesFromMap(form);
  if (isDev) {
    initialValues[FIRST_NAME_KEY] = "Test";
    initialValues[LAST_NAME_KEY] = "Test";
    initialValues[PHONE_NUMBER_KEY] = "15555555555";
    initialValues[EMAIL_KEY] = `test-${randomId(10)}@test.com`;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: constructValidation(form),
    onSubmit: (values) => {},
  });

  const dispatch = useDispatch();
  const clickFormButton = (event) => {
    setStepsTaken([...stepsTaken, step]);
    const {value, name, nextStep} = event.target.dataset;
    formik.setFieldValue(name, value);
    event.preventDefault();
    dispatch(setStep(nextStep));
  };

  const clickBackAction = () => {
    if (stepsTaken.length === 1 && stepsTaken[0] === "address") {
      dispatch(setStreet(null));
      return;
    }
    const newSteps = [...stepsTaken];
    const lastStep = newSteps.pop();
    setStepsTaken(newSteps);
    dispatch(setStep(lastStep));
  };

  //Array of objects with the name, type and value
  const {information, finalSubmit, isFormValid} = useIntakeFormValues(
    formik,
    shouldUseSSD
  );
  return (
    <Container fluid>
      <Row
        className="full-height"
        style={{
          flexWrap: "nowrap",
          flexDirection: "row",
        }}
      >
        <Col
          lg={6}
          className={`current-image`}
          style={{
            paddingRight: 0,
            height: "100%",
          }}
        >
          <div ref={otherRef} className="image-wrapper">
            <div className="top-bar mobile-only">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FVilla_Logo_Marks_CMYK_Secondary%20Mark.svg?alt=media&token=4b6666f3-6f9c-44bd-a51d-db1dc12e5f22"
                width="85px"
                alt="villa"
              />

              <Button
                buttonClasses="close-button"
                onClick={() => {
                  intakeFormBackAction(step);
                }}
              />
            </div>
            <CurrentImage />
          </div>
        </Col>

        <Col lg={6} className={`current-step ${step}`}>
          <div className="top-bar">
            <BackButton
              stepsTaken={stepsTaken}
              clickBackAction={clickBackAction}
            />

            <img
              src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FVilla_Logo_Marks_CMYK_Secondary%20Mark.svg?alt=media&token=4b6666f3-6f9c-44bd-a51d-db1dc12e5f22"
              width="85px"
              alt="villa"
            />
            <Button
              buttonClasses="close-button"
              onClick={() => {
                intakeFormBackAction(step);
              }}
            />
          </div>

          {step === "step-zero" ||
          step === "step-confirmation" ||
          step === "step-no-contact" ? (
            ""
          ) : (
            <NavStepper />
          )}
          <div
            className={
              step === "step-zero" || step === "step-confirmation"
                ? "step-wrapper-full-height"
                : "step-wrapper"
            }
            ref={nodeRef}
          >
            <CurrentStep
              formik={formik}
              clickFormButton={clickFormButton}
              finalSubmit={finalSubmit}
              form={form}
              isFormValid={isFormValid}
              shouldUseSSD={shouldUseSSD}
            />
          </div>

          <form
            className="hidden"
            action="https://go.villahomes.com/l/909732/2021-02-02/2j8"
            method="POST"
            target={"hiddenFrame"}
          >
            {information.map((item) => (
              <HiddenInput
                inputName={item.inputName}
                inputValue={item.inputValue != null ? item.inputValue : "null"}
                inputType={item.inputType}
                key={item.inputName}
              />
            ))}

            <button
              className="hidden"
              type="submit"
              id="hidden-submit-button"
            />
          </form>
          <iframe
            name="hiddenFrame"
            width="0"
            height="0"
            border="0"
            style={{display: "none"}}
          />
        </Col>
      </Row>
    </Container>
  );
};
