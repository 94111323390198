import {useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchImage} from "../../design-tool/logic/data";

export const useImage = (imageId) => {
  const image = useSelector((state) => state.global.images[imageId]);

  useEffect(() => {
    if (image == null) {
      fetchImage(imageId, false);
    }
  }, [image]);

  return image;
};
