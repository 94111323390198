import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {
  priceFromText,
  priceTextFromMicros,
} from "../../../../design-tool/logic/ConfigurationPageHelper";
import React, {useEffect, useState} from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {isAdmin} from "../../../../_shared/user/UserUtils";
import {useSelector} from "react-redux";
import {
  Configuration,
  firestore,
  Sitework,
  Utilities,
} from "../../../../../database/firebase";
import waiting_graphic from "../../../../../assets/images/graphics/waiting_graphic.png";
import laptop from "../../../../../assets/images/graphics/laptop.png";
import {useConfigurationLocation} from "../../../_shared/hooks/useConfigurationLocation";
import {FiEye, FiEyeOff} from "react-icons/fi";
import {
  ErrorDiv,
  FinalRowContainer,
  HeaderRowContainer,
  MainContainer,
  Opaque,
  RowContainer,
  TableContainer,
} from "../styles/SiteWorkTableStyles";
import {TableRow} from "./SiteWorkTableRow";
import {
  cleanCurrencyString,
  currencyStringFromMicros,
  isBlankString,
} from "@natomas/core";
import {dollarsToMicros} from "@natomas/core";
import {CONFIGURATION_SITE_DB_KEY} from "../../../../../database/firebase/configuration/configuration";
const PHASE_1_LINE_ITEM_ID = "phase1";

const getIntValue = (entry) => {
  if (isBlankString(entry)) {
    return 0;
  }
  const strippedText = entry.replace(/\$|,/g, "");
  const parsedFloat = parseFloat(strippedText);
  if (isNaN(parsedFloat)) {
    return 0;
  }
  return Math.round(parsedFloat * 100) / 100;
};

const getTotalBreakdown = (data) => {
  let totalPhase1CostInMicros = 0;
  let errors = [];
  data.forEach((row, index) => {
    const [description, total, notes] = row;
    if (description.readOnly !== true) {
      let totalValue = getIntValue(total.value);
      totalPhase1CostInMicros += dollarsToMicros(totalValue);
    }
  });
  return {
    totalPhase1CostInMicros,
    errors,
  };
};

const shouldShowRow = (row) => {
  if (isBlankString(row[0].value) && isBlankString(row[1].value)) {
    return false;
  }
  return row[0].readOnly !== true;
};

const getTable = (rowCount, columnCount, data) => {
  const table = [];
  table.push([
    {value: "Item", readOnly: true},
    {value: "Your Total", readOnly: true},
    {value: "Notes & Comments", readOnly: true},
  ]);
  if (data != null) {
    data = data.filter((row) => {
      return row.lineItemId === PHASE_1_LINE_ITEM_ID;
    });
  }
  for (let i = 0; i < 7; i++) {
    const row = [];
    if (data != null && data.length > i) {
      const dataRow = data[i];
      if (dataRow.lineItemId === PHASE_1_LINE_ITEM_ID) {
        row.push({
          value: dataRow.title,
          readOnly: false,
          key: dataRow.lineItemId,
        });
        row.push({
          value:
            dataRow.totalPrice ??
            priceTextFromMicros(dataRow.totalPriceInMicros, "accurate"),
        });
        row.push({value: dataRow.note ?? ""});
      }
    } else {
      for (let col = 0; col < columnCount; col++) {
        row.push({value: ""});
      }
    }
    table.push(row);
  }

  const {totalPhase1CostInMicros} = getTotalBreakdown(table);
  table.push([
    {value: "Site work cost total", readOnly: true},
    {
      value: priceTextFromMicros("" + totalPhase1CostInMicros, "min"),
      readOnly: true,
    },
    {value: "", readOnly: true},
  ]);
  return table;
};

const initialData = getTable(8, 3, null);

const savePhase1PricingToConfiguration = (
  data,
  configuration,
  configurationSite
) => {
  let totalPhase1CostInMicros = 0;
  const rows = data
    .filter((row) => {
      return row[1].readOnly !== true;
    })
    .map((row) => {
      const [title, totalPrice, note] = row;
      const totalPriceInt = getIntValue(totalPrice.value);
      return {
        lineItemId: "phase1",
        title: title.value,
        totalPrice: totalPrice.value,
        totalPriceInMicros: totalPriceInt * 100,
        note: note.value,
      };
    });

  let lineItems = [];
  if (configurationSite != null && configurationSite.lineItems != null) {
    lineItems = configurationSite.lineItems.filter((row) => {
      return row.lineItemId !== PHASE_1_LINE_ITEM_ID;
    });
  }
  const rowsToAdd = rows.filter((row) => {
    // Remove extra rows
    totalPhase1CostInMicros += row.totalPriceInMicros;
    return row.title !== "";
  });
  lineItems = lineItems.concat(rowsToAdd);

  const payload = {
    lineItems: lineItems,
    totalPhase1CostInMicros,
    autoGeneratedQuote: false,
  };

  const updateSiteWorkCosts = Utilities.setDocumentWithMerge(
    firestore.collection(Sitework.getCostsKey()),
    configuration.id,
    payload
  );

  const updateConfigurationCosts = Utilities.setDocumentWithMerge(
    firestore.collection(Configuration.Constants.CONFIGURATION_SITE_DB_KEY),
    configuration.id,
    payload
  );

  return Promise.all([updateConfigurationCosts, updateSiteWorkCosts]);
};

export const Phase1BudgetSummary = (props) => {
  const {viewOnly, showExtras} = props;
  const {
    configuration,
    configurationSite,
    currentConfigurationId,
    configurationSnapshot,
  } = useCurrentProject();
  const currentUser = useSelector((state) => state.global.user);
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {saveGeoLocation} = useConfigurationLocation();
  const [data, setData] = useState(initialData);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (configurationSite != null && configurationSite.lineItems != null) {
      const rows = configurationSite.lineItems.filter((row) => {
        return row.lineItemId === PHASE_1_LINE_ITEM_ID;
      });
      if (rows.length !== 0) {
        console.log(Object.keys(rows[0]).length, rows);
        setData(getTable(rows.length, 3, rows));
      }
    }
  }, [configurationSite]);
  if (configuration == null) {
    return null;
  }
  const {totalPhase1CostInMicros, errors} = getTotalBreakdown(data);
  if (isAdmin(currentUser) && editing === true) {
    return (
      <MainContainer>
        <div>
          <RowContainer>
            <ReactDataSheet
              style={{width: "100%"}}
              data={data}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={(changes) => {
                const grid = data.map((row) => [...row]);
                changes.forEach(({cell, row, col, value}) => {
                  grid[row][col] = {...grid[row][col], value};
                });
                const {totalPhase1CostInMicros} = getTotalBreakdown(grid);
                const lastRow = grid[grid.length - 1];
                lastRow[1].value = priceTextFromMicros(
                  "" + totalPhase1CostInMicros,
                  "min"
                );
                setData(grid);
              }}
            />
          </RowContainer>
          {errors.length === 0
            ? null
            : errors.map((errorString, index) => {
                return <ErrorDiv key={index}>{errorString}</ErrorDiv>;
              })}
        </div>

        <br />
        <button
          className={"button-secondary"}
          onClick={() => {
            setEditing(false);
            if (
              configurationSite != null &&
              configurationSite.lineItems != null
            ) {
              const rows = configurationSite.lineItems.filter((row) => {
                return row.lineItemId === PHASE_1_LINE_ITEM_ID;
              });
              if (rows.length > 0) {
                setData(getTable(rows.length, 3, rows));
              }
            } else {
              setData(initialData);
            }
          }}
          style={{marginRight: "20px"}}
        >
          Discard Edits
        </button>
        <button
          className={
            "button-primary bold " + (errors.length > 0 ? "unclickable" : "")
          }
          disabled={errors.length > 0}
          onClick={() => {
            setEditing(false);
            return savePhase1PricingToConfiguration(
              data,
              configuration,
              configurationSite
            ).then(() => {
              saveGeoLocation();
            });
          }}
        >
          Save Phase 1 Breakdown
        </button>
      </MainContainer>
    );
  }

  if (
    (configurationSite == null ||
      configurationSite.lineItems == null ||
      configurationSite.visible !== true) &&
    !isAdmin(currentUser)
  ) {
    let table = null;
    let waitingGraphic = null;
    if (configurationSnapshot != null) {
      const rows = data.filter(shouldShowRow).map((row, index) => {
        const items = row.map((entry, index) => {
          if (index > 0) {
            if (index === 2) {
              return entry.note;
            }
            return "";
          }
          return entry.value;
        });

        return (
          <RowContainer key={"row-" + index}>
            <TableRow items={items} />
          </RowContainer>
        );
      });
      table = (
        <div style={{opacity: 0.5}}>
          <HeaderRowContainer className={"bold"}>
            <TableRow items={["Phase 1", "Price", "Notes & Comments"]} />
          </HeaderRowContainer>
          {rows}
          <FinalRowContainer className={"bold"}>
            <TableRow
              items={[
                "Phase 1 cost total",
                priceTextFromMicros("" + totalPhase1CostInMicros, "min"),
                "",
              ]}
            />
          </FinalRowContainer>
        </div>
      );
      waitingGraphic = (
        <MainContainer
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "30px",
              }}
            >
              <img
                src={laptop}
                alt={"Site Plan Image Missing"}
                width={"240px"}
              />
            </div>
            <div style={{textAlign: "left", height: "fit-content"}}>
              <div
                className={"large-text bold"}
                style={{marginBottom: "10px", fontSize: "25px"}}
              >
                Breakdown will be published during our call
              </div>
              <div>
                Our team will sit down with you to discuss your project in great
                detail. Afterwards, you will be able to view your site work
                budget summary here anytime.
              </div>
            </div>
          </div>
        </MainContainer>
      );
    } else {
      waitingGraphic = (
        <MainContainer style={{display: "flex", justifyContent: "center"}}>
          <div style={{textAlign: "center", maxWidth: "428px"}}>
            <img
              src={waiting_graphic}
              alt={"Site Plan Image Missing"}
              width={"240px"}
            />
            <br />
            <div className={"large-text bold"} style={{marginBottom: "10px"}}>
              Almost there
            </div>
            <div style={{marginBottom: "24px"}}>
              Our team is working on your budget summary and project timeline.
              We will let you know the details shortly!
            </div>
          </div>
        </MainContainer>
      );
    }
    return (
      <div>
        {waitingGraphic}
        {table}
      </div>
    );
  }

  let publishHideButton = null;
  if (configurationSite != null && configurationSite.visible === true) {
    publishHideButton = (
      <button
        className={"button-secondary"}
        onClick={() => {
          Utilities.setAndMergeDocumentInDB(
            Utilities.collection(CONFIGURATION_SITE_DB_KEY).doc(
              currentConfigurationId
            ),
            {
              visible: false,
            }
          );
        }}
        style={{marginLeft: "30px"}}
      >
        <FiEyeOff />
        {" Hide Costs from Customer"}
      </button>
    );
  } else {
    publishHideButton = (
      <button
        className={"button-primary bold"}
        onClick={() => {
          Utilities.setAndMergeDocumentInDB(
            Utilities.collection(CONFIGURATION_SITE_DB_KEY).doc(
              currentConfigurationId
            ),
            {
              visible: true,
            }
          );
        }}
        style={{marginLeft: "30px"}}
      >
        <FiEye />
        {" Reveal Costs to Customer"}
      </button>
    );
  }
  const rows = data.filter(shouldShowRow).map((row, index) => {
    const items = row.map((entry, index) => {
      if (index >= 1 && index <= 3) {
        const intValue = getIntValue(entry.value);
        if (index === 1) {
          if (intValue === 0) {
            return "-";
          }
          return cleanCurrencyString("" + intValue, index === 2 ? "sign" : "");
        }
      }
      return entry.value;
    });

    return (
      <RowContainer key={"row-" + index}>
        <TableRow items={items} />
      </RowContainer>
    );
  });

  const finalRowContent = (
    <TableRow
      items={[
        "PHASE 1 SUBTOTAL",
        priceTextFromMicros("" + totalPhase1CostInMicros, "min"),
        "",
      ]}
    />
  );
  let finalRowContainer;
  if (showExtras) {
    finalRowContainer = (
      <FinalRowContainer className={"bold"}>
        {finalRowContent}
      </FinalRowContainer>
    );
  } else if (viewOnly) {
    finalRowContainer = (
      <RowContainer className={"bold"}>{finalRowContent}</RowContainer>
    );
  } else {
    finalRowContainer = (
      <RowContainer className={"bold"}>{finalRowContent}</RowContainer>
    );
  }

  return (
    <MainContainer>
      <TableContainer>
        <HeaderRowContainer className={"bold"}>
          <Opaque />
          <TableRow items={["Phase 1", "Price", "Notes & Comments"]} />
        </HeaderRowContainer>

        {rows}
        {finalRowContainer}
      </TableContainer>

      {isAdmin(currentUser) && adminMode && showExtras && !viewOnly ? (
        <div style={{display: "flex", marginTop: "20px"}}>
          <button
            className={"button-secondary"}
            onClick={() => {
              setEditing(true);
            }}
          >
            Edit Phase 1 Line Items
          </button>
          {publishHideButton}
        </div>
      ) : null}
    </MainContainer>
  );
};
