import {useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {isEmpty, ProductGroup} from "@natomas/core";
import {
  fetchProduct,
  fetchProductGroupAndProducts,
  fetchProductGroups,
} from "../../design-tool/backend/catalogApi";
import {updateProductGroupAndProductId} from "../catalog/ProductCatalogHelper";
import {
  fetchOptionKeys,
  fetchOptionValues,
} from "../../design-tool/admin/logic/ProductOptionHelper";
import {devLogger} from "../../../.developerSettings";
import {FilterOption, filterProducts, FilterType} from "../catalog/filter";
import {useDeepEffect} from "./useDeepEffect";
import {IStore} from "../slices/types/Store";

let initializedProductCatalog = false;

export const useProductCatalog = () => {
  if (!initializedProductCatalog) {
    initializedProductCatalog = true;
    fetchOptionKeys();
    fetchOptionValues();
    fetchProductGroups();
    devLogger("Fetching Catalog");
  }

  const productGroups = useSelector(
    (state: IStore) => state.catalog.productGroups
  );
  const productGroupId = useSelector(
    (state: IStore) => state.catalog.productGroupId
  );
  const productGroup = productGroups[productGroupId];

  const productId = useSelector((state: IStore) => state.catalog.productId);
  const products = useSelector((state: IStore) => state.catalog.products);

  //-------------Catalog Filter Management----------
  const activeProductGroups = useMemo(() => {
    const formattedProductGroups = Object.values(productGroups).map((pG) => {
      return ProductGroup.getProductGroupDetails(pG);
    });
    return formattedProductGroups.filter((pG) => {
      return !ProductGroup.isHidden(pG);
    });
  }, [productGroups]);

  const listedProductGroups = useMemo(() => {
    const formattedProductGroups = Object.values(productGroups).map((pG) => {
      return ProductGroup.getProductGroupDetails(pG);
    });
    return formattedProductGroups.filter((pG) => {
      return ProductGroup.isListed(pG);
    });
  }, [productGroups]);

  const filters = useSelector((state: IStore) => state.catalog.filters);
  const [filteredProducts, setFilteredProducts] = useState<any[] | undefined>(
    undefined
  );
  const [filteredListedProducts, setFilteredListedProducts] = useState<
    any[] | undefined
  >(undefined);

  const availableProductGroups = useMemo(() => {
    let addressFilter = filters?.find(
      (f: any) => f.type === FilterType.Address
    );
    if (addressFilter) {
      return listedProductGroups.filter((pG: any) => {
        return ProductGroup.isAvailable(
          addressFilter.value?.county,
          addressFilter.value?.state,
          pG
        );
      });
    } else {
      if (!isEmpty(listedProductGroups)) {
        return listedProductGroups;
      } else {
        return undefined;
      }
    }
  }, [filters, listedProductGroups]);

  const checkLengthWithAdditionalFilter = (
    filter: FilterOption,
    groups: any[]
  ) => {
    const currentlyFiltered = filteredProducts || [];
    const newlyFiltered = filterProducts(Object.values(products), groups, [
      ...filters,
      filter,
    ]);
    // All the elements that will be removed with this filter
    const removed = newlyFiltered.filter((n) =>
      currentlyFiltered.find((c) => n.id === c.id)
    );
    // All the elements that will be added with this filter
    const added = newlyFiltered.filter((n) =>
      currentlyFiltered.find((c) => n.id !== c.id)
    );

    let count = 0;
    if (added.length > 0) count += added.length;
    if (removed.length > 0) count -= removed.length;
    if (added.length === removed.length) return added.length;
    return count;
  };

  const getProductsWithOnlyFilterTypes = (
    types: FilterType[],
    groups: any[],
    products: any[] | undefined
  ) => {
    const filtersWithOnlyRequestedTypes = filters.filter((f: FilterOption) =>
      types.includes(f.type)
    );
    if (!products) return undefined;
    return filterProducts(
      Object.values(products),
      groups,
      filtersWithOnlyRequestedTypes
    );
  };

  // Object changes are picked up by useDeepEffect
  useDeepEffect(() => {
    setFilteredProducts(
      filterProducts(Object.values(products), activeProductGroups, filters)
    );
  }, [products]);

  // Array changes are picked up by useEffect
  useEffect(() => {
    setFilteredProducts(
      filterProducts(Object.values(products), activeProductGroups, filters)
    );
  }, [activeProductGroups, filters]);

  // Object changes are picked up by useDeepEffect
  useDeepEffect(() => {
    setFilteredListedProducts(
      filterProducts(Object.values(products), listedProductGroups, filters)
    );
  }, [products]);

  // Array changes are picked up by useEffect
  useEffect(() => {
    setFilteredListedProducts(
      filterProducts(Object.values(products), listedProductGroups, filters)
    );
  }, [listedProductGroups, filters]);

  //--------------------------------------------------

  const productGroupModifierGroups = useSelector(
    (state: IStore) => state.catalog.modifierGroups
  );
  const productGroupModifiers = useSelector(
    (state: IStore) => state.catalog.modifiers
  );

  const allProductModifierGroups = useSelector(
    (state: IStore) => state.catalog.productModifierGroups
  );
  const allProductModifiers = useSelector(
    (state: IStore) => state.catalog.productModifiers
  );

  const currentProductModifierGroups = allProductModifierGroups[productId];
  const currentProductModifiers = allProductModifiers[productId];

  const categories = useSelector((state: IStore) => state.catalog.categories);
  let productGroupCategories: any = {};
  Object.values(categories)
    .filter((category: any) => category.productGroupId === productGroupId)
    .forEach((category: any) => {
      productGroupCategories[category.id] = category;
    });

  const modifierGroups = useSelector(
    (state: IStore) => state.catalog.modifierGroups
  );
  const modifiers = useSelector((state: IStore) => state.catalog.modifiers);

  const product = productId != null ? products[productId] : null;

  // Logic for retrieving product groups and specific product details
  useEffect(() => {
    if (!productId && !productGroupId) {
      // dispatch(setProductGroupId("aduV2"));
    } else if (productId && !productGroupId) {
      updateProductGroupAndProductId(productId);
    } else if (currentProductModifierGroups == null && productId) {
      fetchProduct(productId);
    }

    if (productGroupId) {
      fetchProductGroupAndProducts(productGroupId);
      if (productId) {
        fetchProduct(productId);
      }
    }
  }, [productId, productGroupId, currentProductModifierGroups]);

  let currentProducts: any = {};
  Object.values(products)
    .filter((product: any) => product.productGroupId === productGroupId)
    .forEach((product: any) => {
      currentProducts[product.id] = product;
    });

  let currentGroupModifiers: any = {};
  Object.values(modifiers)
    .filter((modifier: any) => modifier.productGroupId === productGroupId)
    .forEach((modifier: any) => {
      currentGroupModifiers[modifier.id] = modifier;
    });

  let currentGroupModifierGroups: any = {};
  Object.values(modifierGroups)
    .filter(
      (modifierGroup: any) => modifierGroup.productGroupId === productGroupId
    )
    .forEach((modifierGroup: any) => {
      currentGroupModifierGroups[modifierGroup.id] = modifierGroup;
    });

  let currentGroupProductModifiers: any = {};
  Object.keys(allProductModifiers)
    .filter((productId) => currentProducts[productId] != null)
    .forEach((productId) => {
      currentGroupProductModifiers[productId] = allProductModifiers[productId];
    });

  return {
    productGroup,
    productGroupId,
    product,
    products,
    productId,
    productGroups,
    currentProducts: Object.values(currentProducts),
    currentGroupModifierGroups,
    currentGroupModifiers,
    currentGroupProductModifiers,
    productGroupModifiers,
    productGroupModifierGroups,
    productGroupCategories,
    currentProductModifierGroups,
    currentProductModifiers,
    allProductModifierGroups,
    allProductModifiers,
    checkLengthWithAdditionalFilter,
    getProductsWithOnlyFilterTypes,
    filters,
    filteredProducts,
    filteredListedProducts,
    availableProductGroups,
    listedProductGroups,
    activeProductGroups,
  };
};

export const useProduct = (productId: string) => {
  const {productGroups, products} = useProductCatalog();
  let product = null;
  let productGroup = null;

  if (productId) {
    product = products[productId];
  }
  if (product?.productGroupId) {
    productGroup = productGroups[product?.productGroupId];
  }

  return {
    productGroup,
    product,
  };
};
