import {useEffect, useState} from "react";
import Select from "react-select";
import "./styles.scss";

function convertOptionsToMap(options) {
  if (options == null || options.length < 1) {
    return null;
  }

  let propertyObject = {};
  options.forEach((option) => {
    propertyObject[option.value] = option.label;
  });

  return propertyObject;
}

function cleanUpImportedValue(value, options) {
  const optionMap = convertOptionsToMap(options);

  return value.map((entry) => {
    if (entry.label == null) {
      return {
        value: entry.value,
        label: optionMap[entry.value],
      };
    }
    return entry;
  });
}

export const SelectionOptionsInput = ({value, handleChange, options}) => {
  const cleanedUpValues = cleanUpImportedValue(value, options);
  const [values, setValues] = useState(cleanedUpValues);

  useEffect(() => {
    setValues(cleanedUpValues);
  }, [values]);

  return (
    <Select
      value={cleanedUpValues}
      isMulti
      onChange={handleChange}
      options={options}
    />
  );
};

function convertOptionArrayForSelect(options) {
  if (options == null || options.length < 1) {
    return [];
  }

  return options.map((option) => {
    return {
      value: option,
      label: option,
    };
  });
}

export const SelectionOptionsInputWithArrays = ({
  value,
  handleChange,
  options,
}) => {
  const cleanedOptions = convertOptionArrayForSelect(options);
  const cleanedValue = convertOptionArrayForSelect(value);

  return (
    <Select
      value={cleanedValue}
      isMulti
      onChange={(selections) => {
        handleChange(selections.map((selection) => selection.value));
      }}
      options={cleanedOptions}
    />
  );
};
