import styled from "styled-components";
import {ERROR_COLOR, VALID_COLOR} from "../../colors";

interface StyledAddressInputProps {
  error: boolean;
}

export const StyledAddressInput = styled.input<StyledAddressInputProps>`
   {
    height: 100%;
    width: 100%;
    border: ${(props: StyledAddressInputProps) =>
      props.error ? "2px solid" : "1px solid"};
    border-radius: 2px;
    border-color: ${(props: StyledAddressInputProps) =>
      props.error ? ERROR_COLOR : "default"};
  }
`;

interface DescriptionProps {
  show: boolean;
}

export const Description = styled.div<DescriptionProps>`
   {
    position: relative;
    display: ${(props: DescriptionProps) => (props.show ? "flex" : "none")};
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.25rem;
  }
`;

export const ResolutionDescription = styled.div<{status: string}>`
  display: flex;
  flex-direction: row;
  p {
    margin: 0 0 0 0.5rem;
    color: ${(props) => (props.status === "error" ? ERROR_COLOR : VALID_COLOR)};
    font-size: 1rem;
  }
`;
