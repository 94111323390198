import {store} from "../../../store";
import {setConfigurationId} from "../../design-tool/slices/configurationSlice";
import {setURLQueryKeyValue} from "../navigation/_helpers";
import {CONFIGURATION_ID_URL_KEY} from "../../../database/firebase/configuration/configuration";
import {loadConfiguration} from "../../design-tool/backend/ConfigurationAPI";

export const setCurrentProject = (projectId: string) => {
  store.dispatch(setConfigurationId(projectId));
  setURLQueryKeyValue(CONFIGURATION_ID_URL_KEY, projectId);
  return loadConfiguration(true, projectId);
};
