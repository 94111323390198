import {
  CA_CALENDLY_BASE_URL,
  CA_PHONE_NUMBER,
  TX_CALENDLY_BASE_URL,
  TX_PHONE_NUMBER,
} from "../../../intake-form/logic/constants";

export const getSalesPhoneNumber = (state: String) => {
  if (state == null) {
    return CA_PHONE_NUMBER;
  }
  switch (state.toUpperCase()) {
    case "CA":
      return CA_PHONE_NUMBER;
    case "TX":
      return TX_PHONE_NUMBER;
    default:
      return CA_PHONE_NUMBER;
  }
};

export const getCalendlyBaseURL = (state: string) => {
  if (state == null) {
    return CA_CALENDLY_BASE_URL;
  }
  switch (state.toUpperCase()) {
    case "CA":
      return CA_CALENDLY_BASE_URL;
    case "TX":
      return TX_CALENDLY_BASE_URL;
    default:
      return CA_CALENDLY_BASE_URL;
  }
};
