import React from "react";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {NatButton, NatLink} from "../../../../_shared/generics/button";
import {
  Description,
  Title,
  ContentContainer,
  Wrapper,
  ImageContainer,
  Status,
  ButtonContainer,
} from "../styles/callToActionStyles";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";

export const CallToActionCard = ({
  type,
  statusMsg,
  status,
  title,
  msg,
  image,
  buttonText,
  clickHandler,
  link,
}) => {
  const descriptionFontSize = useDynamicValue({
    forFour: "1rem",
    forEight: "1rem",
    forTwelve: "1rem",
  });

  let button;
  if (type === "link") {
    button = (
      <NatLink
        label={buttonText}
        option={StyleOption.WhiteWillFillBlack}
        textSize={TextSize.SMALL}
        link={link}
      />
    );
  } else {
    button = (
      <NatButton
        label={buttonText}
        type={"button"}
        clickEvent={clickHandler}
        option={StyleOption.WhiteWillFillBlack}
        size={TextSize.SMALL}
      />
    );
  }

  return (
    <Wrapper type={type}>
      <ContentContainer type={type}>
        {type === "watch-video" && <Status status={status}>{statusMsg}</Status>}
        <Title>{title}</Title>
        <Description fontSize={descriptionFontSize}>{msg}</Description>
        <ButtonContainer>{button}</ButtonContainer>
      </ContentContainer>
      <ImageContainer type={type}>
        <img src={image} alt={type + " picture"} />
      </ImageContainer>
    </Wrapper>
  );
};
