import React from "react";
import {
  NatomasModal,
  NatomasModalBackdrop,
  NatomasModalClose,
  NatomasModalContainer,
  NatomasModalContentContainer,
  NatomasModalFooterButtonContainer,
  NatomasModalHeader,
} from "./styles";
import {IButton, NatButton} from "../button";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {usePage} from "../../hooks/usePage";
import {TextSize} from "../_shared";

export enum NatModalSize {
  X_SMALL = 1,
  SMALL = 2,
  NORMAL = 3,
  LARGE = 4,
  FULLSCREEN = 5,
}

export interface NatModalCustomSize {
  width: string;
  height: string;
}

interface NatModalProps {
  show: boolean;
  handleClose: () => void;
  content: JSX.Element;
  header?: string;
  footer?: JSX.Element;
  footerButtons?: IButton[];
  hideCloseIcon?: boolean;
  size?: NatModalSize;
  centeredContent?: boolean;
  customSize?: NatModalCustomSize;
  customPadding?: string;
}

const getWidth = (
  isMobile: boolean,
  size?: NatModalSize,
  customSize?: NatModalCustomSize
) => {
  if (isMobile) return "98vw";
  if (customSize) return customSize.width + " + 4rem";
  switch (size) {
    case undefined:
      return "50vw";
    case NatModalSize.X_SMALL:
      return "30vw";
    case NatModalSize.SMALL:
      return "40vw";
    case NatModalSize.NORMAL:
      return "50vw";
    case NatModalSize.LARGE:
      return "75vw";
    case NatModalSize.FULLSCREEN:
      return "98vw";
  }
};

const getHeight = (
  isMobile: boolean,
  size?: NatModalSize,
  customSize?: NatModalCustomSize
) => {
  if (isMobile) return "auto";
  if (customSize) return customSize.height + " + 4rem";
  switch (size) {
    case undefined:
      return "50vh";
    case NatModalSize.X_SMALL:
      return "30vh";
    case NatModalSize.SMALL:
      return "40vh";
    case NatModalSize.NORMAL:
      return "50vh";
    case NatModalSize.LARGE:
      return "75vh";
    case NatModalSize.FULLSCREEN:
      return "98vh";
  }
};

export const NatModal = (props: NatModalProps) => {
  const {
    show,
    handleClose,
    hideCloseIcon,
    content,
    header,
    footer,
    footerButtons,
    size,
    centeredContent,
    customSize,
    customPadding,
  } = props;
  const {isNatMobile} = usePage();
  const h = getHeight(isNatMobile(), size, customSize);
  const w = getWidth(isNatMobile(), size, customSize);
  const opacity = show ? 1 : 0;

  const getFooterButtons = () => {
    return (
      <NatomasModalFooterButtonContainer>
        {footerButtons?.map((button: IButton) => {
          return <NatButton {...button} />;
        })}
      </NatomasModalFooterButtonContainer>
    );
  };

  return (
    <>
      {show && (
        <>
          <NatomasModalBackdrop
            opacity={opacity}
            onClick={() => handleClose()}
          />
          <NatomasModal opacity={opacity} height={h} width={w}>
            <NatomasModalContainer customPadding={customPadding}>
              {header && (
                <NatomasModalHeader isMobile={isNatMobile()}>
                  {header}
                </NatomasModalHeader>
              )}
              <NatomasModalContentContainer centered={centeredContent ?? false}>
                {content}
              </NatomasModalContentContainer>
              {footer && <div>{footer}</div>}
              {footerButtons && getFooterButtons()}
            </NatomasModalContainer>
            {!hideCloseIcon && (
              <NatomasModalClose onClick={() => handleClose()}>
                <AiOutlineCloseCircle />
              </NatomasModalClose>
            )}
          </NatomasModal>
        </>
      )}
    </>
  );
};
