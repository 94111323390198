import React from "react";

export const Button = (props) => {
	const {buttonClasses, children, ...otherProps} = props;

	return (
		<button className={buttonClasses} {...otherProps}>
			{children}
		</button>
	);
};
