import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  SubmitDepositContainer,
  SubmitDepositSubtitle,
  SubmitDepositTitle,
} from "../styles";
import {YourInfo} from "../components/YourInfo";
import {Customer} from "@natomas/core";
import {formatPhoneNumber, PhoneNumberFormatType} from "@natomas/core";
import React from "react";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";

export const PaymentTab = (props: any) => {
  const dynamicWidth = useDynamicValue({
    forFour: 4,
    forEight: 8,
    forTwelve: 8,
  });
  const {customer} = useCurrentCustomer();
  const {projectSummary} = useCurrentProject();

  return (
    <>
      <PageContainer>
        <PageElement size={dynamicWidth} height={"auto"}>
          <SubmitDepositContainer>
            <SubmitDepositTitle>Get your proposal</SubmitDepositTitle>
            <SubmitDepositSubtitle>
              Receive a consultation, custom site-plan, personalized design
              session, and finalized quote. Our proposal fee is
              fully-refundable, no questions asked.
            </SubmitDepositSubtitle>
          </SubmitDepositContainer>
        </PageElement>
        <YourInfo
          fullName={Customer.getFullName(customer)}
          personalAddress1={
            Customer.getContactAddress(customer)?.street_number +
            " " +
            Customer.getContactAddress(customer)?.street
          }
          personalAddress2={
            Customer.getContactAddress(customer)?.city +
            ", " +
            Customer.getContactAddress(customer)?.state +
            " " +
            Customer.getContactAddress(customer)?.zip
          }
          phone={
            formatPhoneNumber(
              Customer.getPrimaryContactPhoneNumber(customer),
              PhoneNumberFormatType.PARENTHESIS_AND_DASHES
            ) ??
            Customer.getPrimaryContactPhoneNumber(customer) ??
            ""
          }
          propertyAddress1={
            projectSummary?.address
              ? projectSummary.address?.street_number +
                " " +
                projectSummary.address?.street
              : ""
          }
          propertyAddress2={
            projectSummary?.address
              ? projectSummary.address?.city +
                ", " +
                projectSummary.address?.state +
                " " +
                projectSummary.address?.zip
              : ""
          }
        />
      </PageContainer>
    </>
  );
};
