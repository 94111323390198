import {
  Configuration,
  firestore,
  Utilities,
} from "../../../../../database/firebase";
import {dollarsToMicros, getDollarPriceValueFromText} from "@natomas/core";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import ReactDataSheet from "react-datasheet";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isAdmin} from "../../../../_shared/user/UserUtils";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import styled from "styled-components";
import {HiLockClosed, HiLockOpen} from "react-icons/hi";
import ReactTooltip from "react-tooltip";

const MainContainer = styled.div`
  margin-bottom: 0;
`;

const HeaderRowContainer = styled.div`
  height: 50px;
  width: 100%;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 14px;
`;

const RowContainer = styled.div`
  min-height: 70px;
  width: 100%;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 16px;
`;

const FinalRowContainer = styled.div`
  min-height: 70px;
  width: 100%;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 16px;
  background-color: rgba(130, 194, 117, 0.4);
`;

const getTotalBreakdown = (data) => {
  let totalBaseCostInMicros = 0;
  let totalSiteSpecificCostInMicros = 0;
  let totalSiteWorkCostInMicros = 0;
  let errors = [];
  data.forEach((row, index) => {
    const [description, total, notes] = row;
    if (total.readOnly !== true) {
      let totalValue = getDollarPriceValueFromText(total.value);
      totalSiteWorkCostInMicros += dollarsToMicros(totalValue);
    }
  });
  if (
    totalBaseCostInMicros + totalSiteSpecificCostInMicros !==
    totalSiteWorkCostInMicros
  ) {
    errors.push("Total price does not add up correctly.");
  }

  return {
    totalBaseCostInMicros,
    totalSiteSpecificCostInMicros,
    totalSiteWorkCostInMicros,
    errors,
  };
};

const getTable = (rowCount, columnCount, data) => {
  const table = [];
  table.push([
    {value: "Item", readOnly: true},
    {value: "Total", readOnly: true},
    {value: "Notes & Comments", readOnly: true},
  ]);
  for (let i = 0; i < rowCount; i++) {
    const row = [];
    if (data != null && data.length > i) {
      const dataRow = data[i];
      row.push({value: dataRow.title});
      row.push({value: dataRow.totalPrice});
      row.push({value: dataRow.note});
    } else {
      for (let col = 0; col < columnCount; col++) {
        row.push({value: ""});
      }
    }

    table.push(row);
  }

  const {totalSiteWorkCostInMicros} = getTotalBreakdown(table);
  table.push([
    {value: "Line items cost total", readOnly: true},
    {
      value: priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
      readOnly: true,
    },
    {value: "", readOnly: true},
  ]);
  return table;
};

const initialData = getTable(8, 3, null);

const saveSitePricingToConfiguration = (data, configuration) => {
  const {totalSiteWorkCostInMicros} = getTotalBreakdown(data);

  const rows = data
    .filter((row) => {
      return row[0].readOnly !== true;
    })
    .map((row) => {
      const [title, totalPrice, note] = row;
      return {
        title: title.value,
        totalPrice: totalPrice.value,
        note: note.value,
      };
    });

  Utilities.saveOrUpdateDocument(
    firestore.collection(Configuration.Constants.CONFIGURATION_DESIGN_DB_KEY),
    configuration.id,
    {
      additionalLineItems: rows,
      additionalLineItemsTotalInMicros: totalSiteWorkCostInMicros,
    }
  );
};

export const UnitLineItemsTable = (props) => {
  const {showExtras} = props;
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState(initialData);
  const currentUser = useSelector((state) => state.global.user);
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {
    configurationSnapshot,
    configuration,
    currentConfigurationId,
  } = useCurrentProject();

  useEffect(() => {
    if (configuration != null && configuration.additionalLineItems != null) {
      const rows = configuration.additionalLineItems;
      setData(getTable(rows.length, Object.keys(rows[0]).length, rows));
    }
  }, [configuration]);

  const {
    totalBaseCostInMicros,
    totalSiteSpecificCostInMicros,
    totalSiteWorkCostInMicros,
    errors,
  } = getTotalBreakdown(data);

  if (isAdmin(currentUser) && editing === true) {
    return (
      <MainContainer>
        <div>
          <RowContainer>
            <ReactDataSheet
              style={{width: "100%"}}
              data={data}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={(changes) => {
                const grid = data.map((row) => [...row]);
                changes.forEach(({cell, row, col, value}) => {
                  grid[row][col] = {...grid[row][col], value};
                });
                const {
                  totalBaseCostInMicros,
                  totalSiteSpecificCostInMicros,
                  totalSiteWorkCostInMicros,
                } = getTotalBreakdown(grid);
                const lastRow = grid[grid.length - 1];
                lastRow[1].value = priceTextFromMicros(
                  "" + totalSiteWorkCostInMicros,
                  "min"
                );
                setData(grid);
              }}
            />
          </RowContainer>
        </div>

        <br />

        <button
          className={"button-secondary"}
          onClick={() => {
            setEditing(false);
            if (
              configuration != null &&
              configuration.additionalLineItems != null
            ) {
              const rows = configuration.additionalLineItems;
              setData(getTable(rows.length, Object.keys(rows[0]).length, rows));
            } else {
              setData(initialData);
            }
          }}
          style={{marginRight: "20px"}}
        >
          Discard Edits
        </button>
        <button
          className={"button-primary bold"}
          onClick={() => {
            setEditing(false);
            saveSitePricingToConfiguration(data, configuration);
          }}
        >
          Save Unit Line Items
        </button>
      </MainContainer>
    );
  }

  let editLineItemsButton = null;
  let lockDesignButton = null;
  if (configuration != null && configuration.locked === true) {
    lockDesignButton = (
      <button
        data-tip
        data-for={"lock-button"}
        className={"button-secondary"}
        onClick={() => {
          Utilities.setAndMergeDocumentInDB(
            firestore.collection("configurations").doc(currentConfigurationId),
            {
              locked: false,
            }
          );
        }}
      >
        <HiLockOpen />
        {" Unlock Design"}
      </button>
    );
  } else if (showExtras) {
    lockDesignButton = (
      <button
        data-tip
        data-for={"lock-button"}
        className={"button-primary bold"}
        onClick={() => {
          Utilities.setAndMergeDocumentInDB(
            firestore.collection("configurations").doc(currentConfigurationId),
            {
              locked: true,
            }
          );
        }}
      >
        <HiLockClosed />
        {" Lock Design"}
      </button>
    );
    editLineItemsButton = (
      <button
        className={"button-secondary"}
        onClick={() => {
          setEditing(true);
        }}
        style={{marginRight: "30px"}}
      >
        Edit Unit Line Items
      </button>
    );
  }

  return (
    <div>
      <br />
      {isAdmin(currentUser) && adminMode ? (
        <div>
          {editLineItemsButton}
          {lockDesignButton}
          <ReactTooltip place={"bottom"} id={"lock-button"} effect="solid">
            {configuration != null && configuration.locked === true
              ? "Allow customer to make design revisions"
              : "Solidify design selections and prevent future edits"}
          </ReactTooltip>
        </div>
      ) : null}
    </div>
  );
};
