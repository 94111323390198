import {useMemo} from "react";
import {
  BedroomOption,
  FilterOption,
  FilterType,
  PriceRangeOption,
  SquareFootageOption,
  verifyOption,
} from "../../../../_shared/catalog/filter";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";

export interface DynamicOptionsProps {
  options: (SquareFootageOption | BedroomOption | PriceRangeOption)[];
  optionType: FilterType;
}

export const useDynamicOptions = (props: DynamicOptionsProps) => {
  const {options, optionType} = props;
  const {filters} = useProductCatalog();

  return useMemo(() => {
    //Get options that aren't already enabled
    return [
      ...options.filter((o: any) => {
        return !filters.find((f: FilterOption) =>
          verifyOption(optionType, f.type, o.label, f.label)
        );
      }),
    ];
  }, [filters.length]);
};
