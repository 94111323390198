import {createAsyncThunk} from "@reduxjs/toolkit";
import firebase from "firebase/app";
import {fetchImage} from "../logic/data";
import {Utilities} from "../../../database/firebase";
import {
  modifierGroupStructureForBase,
  modifierGroupStructureForProduct,
  modifierStructureForBase,
  modifierStructureForProduct,
} from "../models/ModifierModel";
import "firebase/auth";
import {
  editCategory,
  editModifier,
  editModifierGroup,
  editProduct,
  productModifierGroupsLoaded,
  productModifiersLoaded,
  setProductGroups,
  setProducts,
  updateCategories,
  updateModifierGroups,
  updateModifiers,
} from "../../_shared/slices/CatalogSlice";
import {v4 as uuidv4} from "uuid";
import {store} from "../../../store";
import {resetSelections} from "./ConfigurationAPI";

// TODO Reassess how to do this.
//const loadedGroups = {};
const productsFetched = {};
const preFetchImages = true;

const getCurrentProductGroupId = () => {
  return store.getState().catalog.productGroupId;
};

export const getProductGroupRef = (id) => {
  if (id) return Utilities.collection("productCatalog").doc(id);
  else
    return Utilities.collection("productCatalog").doc(
      getCurrentProductGroupId()
    );
};

const productGroupCategoriesRef = (id) => {
  return getProductGroupRef(id).collection("categories");
};

const productsCollectionRef = (id) => {
  return getProductGroupRef(id).collection("products");
};

const productGroupProductDoc = (productId) => {
  if (productId != null) {
    return getProductGroupRef().collection("products").doc(productId);
  }
  return getProductGroupRef().collection("products").doc();
};

const modifiersRef = (id) => {
  return getProductGroupRef(id).collection("modifiers");
};

const categoryRef = (categoryId) => {
  if (categoryId != null) {
    return getProductGroupRef().collection("categories").doc(categoryId);
  }
  return getProductGroupRef().collection("categories").doc();
};

function getProductConfigurationPageRef(productId, configurationPageId) {
  return productsCollectionRef()
    .doc(productId)
    .collection("configurationPages")
    .doc(configurationPageId);
}

function getGlobalModifierRef(modifierId) {
  if (modifierId != null) {
    return modifiersRef().doc(modifierId);
  }
  return modifiersRef();
}

function getGlobalModifierGroupRef(modifierGroupId) {
  if (modifierGroupId != null) {
    return getProductGroupRef()
      .collection("modifierGroups")
      .doc(modifierGroupId);
  }
  return getProductGroupRef().collection("modifierGroups");
}

function getProductModifierRef(productId, modifierId) {
  return productsCollectionRef()
    .doc(productId)
    .collection("productModifiers")
    .doc(modifierId);
}

const modifierGroupsRef = (id) => {
  return getProductGroupRef(id).collection("modifierGroups");
};

export function fetchProductGroups() {
  Utilities.collection("productCatalog").onSnapshot((snapshot) => {
    const docs = snapshot.docChanges();
    const productGroups = {};
    if (docs) {
      docs.forEach((c) => {
        if (c.doc.id !== "adu") {
          const product = c.doc.data();
          product.id = c.doc.id;
          productGroups[c.doc.id] = product;
          fetchProductsInProductGroup(c.doc.id);
        }
      });
    }
    store.dispatch(setProductGroups(productGroups));
  });
}

export function fetchProductsInProductGroup(productGroupId) {
  return productsCollectionRef(productGroupId).onSnapshot((snapshot) => {
    const docs = snapshot.docChanges();
    const products = {};
    if (docs) {
      docs.forEach((c) => {
        const product = c.doc.data();
        product.id = c.doc.id;
        product.productGroupId = productGroupId;
        if (product.visible == null || product.visible !== false) {
          products[c.doc.id] = product;
        } else {
          products[c.doc.id] = null;
        }

        if (product.imageId != null && preFetchImages) {
          fetchImage(product.imageId, false);
        }
      });
    }

    store.dispatch(setProducts(products));
  });
}

let lastLoadedGroup = null;
export function fetchProductGroupAndProducts(id) {
  const productGroupId = id;
  if (productGroupId === lastLoadedGroup) {
    return;
  }
  lastLoadedGroup = productGroupId;
  fetchProductsInProductGroup(productGroupId);

  modifiersRef(productGroupId).onSnapshot((snapshot) => {
    const docs = snapshot.docChanges();
    const modifiers = {};
    if (docs) {
      docs.forEach((c) => {
        const modifier = c.doc.data();
        modifiers[c.doc.id] = null;
        if (modifier != null) {
          modifier.productGroupId = productGroupId;
          modifier.id = c.doc.id;
          if (modifier.imageId != null) {
            fetchImage(modifier.imageId, false);
          }

          if (modifier.visible == null || modifier.visible !== false) {
            modifiers[c.doc.id] = modifier;
          }
        }
      });
    }

    if (lastLoadedGroup === productGroupId) {
      store.dispatch(updateModifiers(modifiers));
      resetSelections();
    }
  });

  modifierGroupsRef(productGroupId).onSnapshot((snapshot) => {
    const docs = snapshot.docChanges();
    const modifierGroups = {};
    if (docs) {
      docs.forEach((c) => {
        const modifierGroup = c.doc.data();
        modifierGroups[c.doc.id] = null;
        if (modifierGroup != null) {
          modifierGroup.productGroupId = productGroupId;
          modifierGroup.id = c.doc.id;
          if (
            modifierGroup.modifiers == null ||
            modifierGroup.modifiers.length === 0
          ) {
            modifierGroup.modifiers = [];
          }

          if (c.type === "removed") {
            modifierGroups[c.doc.id] = null;
          } else if (
            modifierGroup.visible == null ||
            modifierGroup.visible !== false
          ) {
            modifierGroups[c.doc.id] = modifierGroup;
          }
        }
      });
    }
    if (lastLoadedGroup === productGroupId) {
      store.dispatch(updateModifierGroups(modifierGroups));
      resetSelections();
    }
  });

  productGroupCategoriesRef(productGroupId).onSnapshot((snapshot) => {
    const docs = snapshot.docChanges();
    const categories = {};
    if (docs) {
      docs.forEach((c) => {
        const category = c.doc.data();
        if (category != null) {
          category.productGroupId = productGroupId;
          category.id = c.doc.id;
        }
        if (c.type === "removed") {
          categories[c.doc.id] = null;
        } else if (category.visible == null || category.visible !== false) {
          categories[c.doc.id] = category;
        } else {
          categories[c.doc.id] = null;
        }

        if (category.renderings != null) {
          Object.values(category.renderings).forEach((rendering) => {
            if (rendering.imageId != null && preFetchImages) {
              // fetchImage(rendering.imageId, false);
            }
          });
        }
      });
    }

    if (lastLoadedGroup === productGroupId) {
      store.dispatch(updateCategories(categories));
      resetSelections();
    }
  });
}

export function fetchProduct(specificProductId) {
  const productId = specificProductId;
  if (
    productId == null ||
    productsFetched[productId] === true ||
    getCurrentProductGroupId() == null
  ) {
    return;
  }
  productsFetched[productId] = true;

  const modifierGroups = productsCollectionRef()
    .doc(productId)
    .collection("productModifierGroups")
    .onSnapshot((snapshot) => {
      const docs = snapshot.docChanges();
      if (docs) {
        let modifierGroupMap = {};
        docs.forEach((c) => {
          const modifierGroup = c.doc.data();
          modifierGroup.id = c.doc.id;
          modifierGroup.productGroupId = getCurrentProductGroupId();
          modifierGroupMap[c.doc.id] = modifierGroup;
        });
        store.dispatch(
          productModifierGroupsLoaded({
            productId: productId,
            modifierGroups: modifierGroupMap,
          })
        );

        resetSelections();
        // fetchGlobalModifierGroups(modifierGroupMap);
      }
    });

  const modifiers = productsCollectionRef()
    .doc(productId)
    .collection("productModifiers")
    .onSnapshot((snapshot) => {
      const docs = snapshot.docChanges();
      if (docs) {
        let modifierObjects = {};
        docs.forEach((c) => {
          const modifier = c.doc.data();
          modifier.id = c.doc.id;
          modifier.productGroupId = getCurrentProductGroupId();
          modifierObjects[c.doc.id] = modifier;
        });

        store.dispatch(
          productModifiersLoaded({
            productId: productId,
            modifiers: modifierObjects,
          })
        );
        resetSelections();
        // fetchModifiers(modifierObjects);
      }
    });
  // TODO Can this be removed?
  // const configurationPages = productsCollectionRef()
  // 	.doc(productId)
  // 	.collection("configurationPages")
  // 	.onSnapshot((snapshot) => {
  // 		const docs = snapshot.docChanges();
  // 		if (docs) {
  // 			let configurationPages = {};
  // 			docs.forEach((c) => {
  // 				const configurationPage = c.doc.data();
  // 				configurationPage.id = c.doc.id;
  // 				configurationPages[c.doc.id] = configurationPage;
  // 				configurationPage.productId = productId;
  // 				if (configurationPage.renderings != null) {
  // 					Object.values(configurationPage.renderings).forEach((rendering) => {
  // 						if (rendering.imageId != null && preFetchImages) {
  // 							fetchImage(rendering.imageId, false);
  // 						}
  // 					});
  // 				}
  // 			});
  //
  // 			store.dispatch(
  // 				productConfigurationPagesLoaded({
  // 					productId: productId,
  // 					configurationPages: configurationPages,
  // 				})
  // 			);
  // 		}
  // 	});
}

// export function fetchModifiers(modifiersMap) {
// 	const modifierMapLoaded = {};
// 	Object.keys(modifiersMap).forEach((modifierId) => {
// 		const shouldPreventFetch = loadedModifiers[modifierId] === true;
// 		if (shouldPreventFetch) {
// 			modifierMapLoaded[modifierId] = true;
// 		} else {
// 			loadedModifiers[modifierId] = true;
//
// 			modifiersRef()
// 				.doc(modifierId)
// 				.onSnapshot((snapshot) => {
// 					const modifier = snapshot.data();
// 					modifierMapLoaded[snapshot.id] = true;
// 					if (modifier != null) {
// 						modifier.productGroupId = getCurrentProductGroupId();
// 						modifier.id = snapshot.id;
// 						store.dispatch(baseModifierLoaded(modifier));
// 						if (modifier.imageId != null && preFetchImages) {
// 							fetchImage(modifier.imageId, false);
// 						}
// 					}
// 					if (
// 						Object.keys(modifiersMap).length ===
// 						Object.keys(modifierMapLoaded).length
// 					) {
// 						resetSelections();
// 					}
// 				});
// 		}
// 	});
// }

// export function fetchGlobalModifierGroups(modifierGroupsMap) {
// 	const modifierGroupMapLoaded = {};
// 	Object.keys(modifierGroupsMap).forEach((modifierGroupId) => {
// 		const shouldPreventFetch = loadedModifierGroups[modifierGroupId] === true;
// 		if (shouldPreventFetch) {
// 			modifierGroupMapLoaded[modifierGroupId] = true;
// 		} else {
// 			loadedModifierGroups[modifierGroupId] = true;
//
// 			modifierGroupsRef()
// 				.doc(modifierGroupId)
// 				.onSnapshot((snapshot) => {
// 					const modifierGroup = snapshot.data();
// 					modifierGroupMapLoaded[snapshot.id] = true;
// 					if (modifierGroup != null) {
// 						modifierGroup.productGroupId = getCurrentProductGroupId();
// 						modifierGroup.id = snapshot.id;
// 						if (
// 							modifierGroup.modifiers != null &&
// 							modifierGroup.modifiers.length > 0
// 						) {
// 							const modifierMap = {};
// 							modifierGroup.modifiers.forEach((modifierId) => {
// 								modifierMap[modifierId] = true;
// 							});
// 							fetchModifiers(modifierMap);
// 						} else {
// 							modifierGroup.modifiers = [];
// 						}
// 						store.dispatch(baseModifierGroupLoaded(modifierGroup));
// 					}
// 					if (
// 						Object.keys(modifierGroupsMap).length ===
// 						Object.keys(modifierGroupMapLoaded).length
// 					) {
// 						resetSelections();
// 					}
// 				});
// 		}
// 	});
// }

export const searchModifierGroups = createAsyncThunk(
  "data/searchModifierGroupsStatus",
  async (payload, thunkAPI) => {
    const response = await searchModifierGroupsInDB(payload, thunkAPI);
    return response;
  }
);

export function searchModifierGroupsInDB(title) {
  return getGlobalModifierGroupRef()
    .where("title", ">=", title)
    .where("title", "<=", title + "\uf8ff")
    .get()
    .then((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const modifierGroup = doc.data();
        modifierGroup.id = doc.id;
        results.push(modifierGroup);
      });
      return results;
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
}

export function searchModifiersInDB(title) {
  return getGlobalModifierRef()
    .where("title", ">=", title)
    .where("title", "<=", title + "\uf8ff")
    .get()
    .then((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const modifier = doc.data();
        modifier.id = doc.id;
        results.push(modifier);
      });
      return results;
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
}

export async function bulkUpdateProductGroupModifiers(modifierUpdateBreakdown) {
  const {productGroup, ...productBreakdown} = modifierUpdateBreakdown;

  let batch = Utilities.batchUpdate();
  let counter = 1;
  if (productGroup != null && Object.keys(productGroup).length > 0) {
    Object.keys(productGroup).forEach((modifierId) => {
      const updatePayload = productGroup[modifierId];
      batch.set(modifiersRef().doc(modifierId), updatePayload, {merge: true});
      counter++;
      if (counter >= 500) {
        counter = 1;
        batch.commit();
        batch = Utilities.batchUpdate();
      }
    });
  }

  if (productBreakdown != null && Object.keys(productBreakdown).length > 0) {
    Object.keys(productBreakdown).forEach((productId) => {
      const productModifiers = productBreakdown[productId];
      Object.keys(productModifiers).forEach((modifierId) => {
        const updatePayload = productModifiers[modifierId];
        batch.set(getProductModifierRef(productId, modifierId), updatePayload, {
          merge: true,
        });
        counter++;
        if (counter >= 500) {
          counter = 1;
          batch.commit();
          batch = Utilities.batchUpdate();
        }
      });
    });
  }

  if (counter > 1) {
    return batch.commit();
  }
  return null;
}

export async function saveModifierToDB(modifier, productId) {
  let modifierId = modifier.id;
  if (modifierId == null) {
    modifierId = modifiersRef().doc().id;
    modifier.id = modifierId;
    store.dispatch(editModifier(modifier));
  }

  const baseDocument = filterModifierForDestination(modifier, "base");
  baseDocument.prettyDescription = null;
  const promises = [];
  promises.push(
    Utilities.updateOrCreateDocumentInDB(
      modifiersRef().doc(modifierId),
      baseDocument
    )
  );

  if (productId != null) {
    const productDocument = filterModifierForDestination(modifier, "product");
    promises.push(
      Utilities.updateOrCreateDocumentInDB(
        getProductModifierRef(productId, modifierId),
        productDocument
      )
    );
  }

  return Promise.all(promises)
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export const deleteModifierFromProductGroup = (modifier) => {
  const modifierId = modifier.id;
  const newModifier = modifierId == null;

  if (!newModifier) {
    return getGlobalModifierRef(modifierId).update({visible: false});

    // const promises = [];
    // const state = store.getState();
    // const modifierGroups = getProductGroupModifierGroups(state);
    // console.log("Deleting modifier: ", modifier);
    // Object.values(modifierGroups).forEach((modifierGroup) => {
    // 	if (modifierGroups.modifiers?.indexOf(modifierId) >= 0) {
    // 		promises.push(
    // 			getGlobalModifierGroupRef(modifierGroup.id).update({
    // 				modifiers: firebase.firestore.FieldValue.arrayRemove(modifierId),
    // 			})
    // 		);
    // 	}
    // });
    // promises.push(getGlobalModifierRef(modifierId).delete());
    //
    // return Promise.all(promises)
    // 	.then((data) => {
    // 		console.log("Properly deleted modifier");
    // 	})
    // 	.catch((error) => {
    // 		console.log(error);
    // 	});
  }
};

export async function deleteModifierGroupFromProductGroup(modifierGroup) {
  const modifierGroupId = modifierGroup.id;
  const isNewModifierGroup = modifierGroupId == null;

  if (!isNewModifierGroup) {
    console.log("Deleting modifier group: ", modifierGroup);
    return getGlobalModifierGroupRef(modifierGroupId).update({visible: false});
    // return getGlobalModifierGroupRef(modifierGroupId).delete();
  }
}

function filterModifierForDestination(modifier, destination) {
  let filteringObject = {};
  switch (destination) {
    case "base":
      filteringObject = modifierStructureForBase;
      break;
    case "product":
      filteringObject = modifierStructureForProduct;
      break;
    default:
      break;
  }
  const filteredObject = Object.keys(modifier)
    .filter((key) => filteringObject.hasOwnProperty(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: modifier[key],
      };
    }, {});
  return filteredObject;
}

export async function saveModifierGroupToDB(modifierGroup) {
  let modifierGroupId = modifierGroup.id;
  const isNewModifierGroup = modifierGroupId == null;
  if (isNewModifierGroup) {
    modifierGroupId = getGlobalModifierGroupRef().doc().id;
    modifierGroup.id = modifierGroupId;
    console.log("Creating id for new modifier: ", modifierGroupId);
    store.dispatch(editModifierGroup(modifierGroup));
  }

  const baseDocument = filterModifierGroupForDestination(modifierGroup, "base");

  const promises = [
    Utilities.updateOrCreateDocumentInDB(
      getGlobalModifierGroupRef(modifierGroupId),
      baseDocument
    ),
  ];
  //
  // if (productId != null) {
  // const productDocument = filterModifierGroupForDestination(
  //   modifierGroup,
  //   "product"
  // );
  // 	promises.push(
  // 		Utilities.updateOrCreateDocumentInDB(
  // 			getProductModifierGroupRef(productId, modifierGroupId),
  // 			productDocument
  // 		)
  // 	);
  // }

  return Promise.all(promises)
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function saveRenderingToCategory(configurationPage, rendering) {
  let renderingId = rendering.id;
  if (renderingId == null) {
    renderingId = uuidv4();
    rendering.id = renderingId;
  }
  const renderObjectToBeSaved = filterByKeys(rendering, {
    requiredModifiers: true,
    imageId: true,
    id: true,
    overlayGroupId: true,
    zIndex: true,
    orientation: true,
    objectFit: true,
  });

  const renderingKey = "renderings." + renderingId;
  return categoryRef(configurationPage.id).update({
    [renderingKey]: renderObjectToBeSaved,
  });
}

export async function deleteRenderingFromCategory(
  configurationPage,
  renderingId
) {
  if (renderingId != null) {
    const renderingKey = "renderings." + renderingId;
    return categoryRef(configurationPage.id).update({
      [renderingKey]: firebase.firestore.FieldValue.delete(),
    });
  }
}

export async function saveRenderingToConfigurationPage(
  productId,
  configurationPage,
  rendering
) {
  let renderingId = rendering.id;
  if (renderingId == null) {
    renderingId = uuidv4();
    rendering.id = renderingId;
  }

  const renderObjectToBeSaved = filterByKeys(rendering, {
    requiredModifiers: true,
    imageId: true,
    id: true,
    overlayGroupId: true,
    zIndex: true,
    orientation: true,
    objectFit: true,
  });

  const renderingKey = "renderings." + renderingId;
  getProductConfigurationPageRef(productId, configurationPage.id).update({
    [renderingKey]: renderObjectToBeSaved,
  });
}

export async function deleteRenderingFromConfigurationPage(
  productId,
  configurationPage,
  renderingId
) {
  if (renderingId != null) {
    const renderingKey = "renderings." + renderingId;
    getProductConfigurationPageRef(productId, configurationPage.id).update({
      [renderingKey]: firebase.firestore.FieldValue.delete(),
    });
  }
}

export async function copyConfigurationPageToProducts(
  sourceProductId,
  configurationPageId,
  destinationProductIds,
  shallow
) {
  Object.keys(destinationProductIds).forEach((productIdDestination) => {
    productsCollectionRef()
      .doc(productIdDestination)
      .collection("duplicateConfigurationPages")
      .doc(configurationPageId)
      .delete()
      .then(() => {
        if (shallow === false) {
          productsCollectionRef()
            .doc(productIdDestination)
            .collection("duplicateConfigurationPages")
            .doc(configurationPageId)
            .set({
              copyProductId: sourceProductId,
            });
        } else {
          productsCollectionRef()
            .doc(productIdDestination)
            .collection("duplicateConfigurationPages")
            .doc(configurationPageId)
            .set({
              shallowCopyProductId: sourceProductId,
            })
            .then((data) => {
              console.log(data);
            });
        }
      });
  });
}

export async function saveToConfigurationPage(
  productId,
  configurationPage,
  payload
) {
  return getProductConfigurationPageRef(productId, configurationPage.id).update(
    payload,
    {
      merge: true,
    }
  );
}

export async function deleteCategory(configurationPage) {
  return categoryRef(configurationPage.id).update({visible: false});
  // return categoryRef(configurationPage.id).delete();
}

export async function saveCategory(category, payload) {
  const ref = categoryRef(category.id);
  if (category.id == null) {
    store.dispatch(editCategory(Object.assign({id: ref.id}, payload)));
  }
  return Utilities.updateOrCreateDocumentInDB(ref, payload);
}

export async function saveProductInformation(productId, payload) {
  const productGroupId = store.getState().catalog.productGroupId;
  const ref = productGroupProductDoc(productId);
  const productInfo = Object.assign({id: ref.id, productGroupId}, payload);
  if (productId == null) {
    store.dispatch(editProduct(productInfo));
  }
  return Utilities.updateOrCreateDocumentInDB(ref, productInfo);
}

export async function deleteProduct(productId) {
  return saveProductInformation(productId, {visible: false});
}

export async function saveProductGroupInformation(payload) {
  return Utilities.updateOrCreateDocumentInDB(getProductGroupRef(), payload);
}

function filterModifierGroupForDestination(modifierGroup, destination) {
  let filteringObject = {};
  switch (destination) {
    case "product":
      filteringObject = modifierGroupStructureForProduct;
      break;
    case "base":
      filteringObject = modifierGroupStructureForBase;
      break;
    default:
  }
  const filteredObject = Object.keys(modifierGroup)
    .filter((key) => filteringObject.hasOwnProperty(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: modifierGroup[key],
      };
    }, {});
  return filteredObject;
}

function filterByKeys(object, filteringObject) {
  const filteredObject = Object.keys(object)
    .filter((key) => filteringObject.hasOwnProperty(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key],
      };
    }, {});
  return filteredObject;
}
