import {priceTextFromMicros} from "../logic/ConfigurationPageHelper";
import {OneLine} from "./one-line";

export const ModifierGroupSummary = (props) => {
	const {modifierGroup} = props;
	const modifiers = modifierGroup.selectedModifiers;

	return (
		<div key={modifierGroup.id}>
			{modifiers.map((modifier) => {
				return (
					<div key={modifier.id}>
						<OneLine
							leftText={modifier.title}
							rightText={priceTextFromMicros(
								modifier.adjustedPriceMicros,
								"min-sign"
							)}
						/>
					</div>
				);
			})}
		</div>
	);
};
