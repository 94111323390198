import React from "react";
import {useSelector} from "react-redux";
import {ButtonForm} from "../components/buttonForm";

export const MULTI_FAMILY_KEY = "multiFamily";
export const MULTI_FAMILY_YES = "yes";
export const MULTI_FAMILY_NO = "no";

export const StepTwoSubstepOne = (props) => {
	const selectedValue = useSelector(
		(state) => state.intakeForm.questionTwoSubOne
	);
	const onClick = props.clickFormButton;
	const inputs = [
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "Yes",
			nextStep: "step-contact",
			radioID: "first-option",
			radioValue: MULTI_FAMILY_YES,
			radioName: MULTI_FAMILY_KEY,
		},
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "No",
			nextStep: "step-contact",
			radioID: "second-option",
			radioValue: MULTI_FAMILY_NO,
			radioName: MULTI_FAMILY_KEY,
		},
	];

	return (
		<ButtonForm
			inputs={inputs}
			question="Do you invest in multi-family properties?"
			selectedValue={selectedValue}
		/>
	);
};
