import React from "react";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {sortProducts} from "../../../../design-tool/logic/ProductGroupUtils";
import {editProduct} from "../../../../_shared/slices/CatalogSlice";
import {useDispatch, useSelector} from "react-redux";
import {EditProduct} from "./EditProduct";
import {ColumnContainer, MainColumn} from "../../styles/AdminStyles";
import {TabColumn} from "../../components/TabColumn";
import {IStore} from "../../../../_shared/slices/types/Store";

export const ProductsTab = () => {
  const {currentProducts} = useProductCatalog();
  const currentProduct = useSelector(
    (state: IStore) => state.catalog.editingProduct
  );
  const dispatch = useDispatch();
  const title = "Products";
  const newProduct = () => {
    dispatch(editProduct({title: "New Product"}));
  };
  const changeProduct = (product: any) => {
    dispatch(editProduct(product));
  };
  const checkIfActive = (key: string) => {
    return currentProduct?.id === key;
  };
  const getProductRows = () => {
    return sortProducts(currentProducts).map((product: any) => {
      return {
        title: product.title,
        key: product.id,
        data: product,
        action: changeProduct,
        checkIfActive: checkIfActive,
      };
    });
  };
  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={"7rem"}
        headerAddAction={newProduct}
        rows={getProductRows()}
      />
      <MainColumn>
        <EditProduct />
      </MainColumn>
    </ColumnContainer>
  );
};
