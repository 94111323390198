import styled from "styled-components";
import {calculateBorder, INatStyle, ITextStyle} from "../_shared/";
import {NatLinkStyle, NatStyle} from "../../styles";
import {IconPosition} from "../_shared/";

const NatomasButton = styled.button<INatStyle>`
  ${NatStyle};
  font-family: ${(props) => props.fontFamily ?? "Maison-Bold, sans-serif"};
  border: ${(props) => calculateBorder(props.initialBorderColor)};
  border-radius: 3rem;
  max-height: calc(${(props) => props.textSize} * 2 + 8px);
  &:hover {
    border: ${(props) => calculateBorder(props.hoverBorderColor)};
  }
`;

const NatomasLink = styled.a<INatStyle>`
  ${NatLinkStyle};
  font-family: ${(props) => props.fontFamily ?? "Maison-Bold, sans-serif"};
  border: ${(props) => calculateBorder(props.initialBorderColor)};
  border-radius: 3rem;
  &:hover {
    border: ${(props) => calculateBorder(props.hoverBorderColor)};
  }
`;

const TextContainer = styled.div<ITextStyle>`
  height: 100%;
  width: 100%;
  font-size: ${(props: ITextStyle) => props.fontSize};
  line-height: 1.15rem;
  svg {
    margin-right: ${(props: ITextStyle) =>
      props.hasLabel && props.iconPosition === IconPosition.LEFT && "0.375rem"};
    margin-left: ${(props: ITextStyle) =>
      props.hasLabel &&
      props.iconPosition === IconPosition.RIGHT &&
      "0.375rem"};
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export {NatomasButton, NatomasLink, TextContainer};
