import {GenericNavBar} from "../../../_shared/generics/GenericNavBar";
import React from "react";
import {Header} from "../../../_shared/generics/header";
import {NavigationButtons} from "../../../_shared/generics/header/styles";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {NatButton} from "../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../_shared/generics/_shared";

export const EditorHeader = (props: any) => {
  const {
    tabs,
    setTab,
    deleteAction,
    discardAction,
    saveAction,
    isUpToDate,
    viewAction,
  } = props;

  const width = useDynamicValue(fullColumnWidths);

  return (
    <Header width={width} height={"4rem"} noPadding={true}>
      <GenericNavBar tabs={tabs} onChange={setTab} />
      <NavigationButtons>
        <NatButton
          type={"button"}
          size={TextSize.SMALL}
          option={StyleOption.Archive}
          hidden={deleteAction == null}
          clickEvent={deleteAction}
          label={"Archive"}
          trackingId={"admin-archive"}
        />
        <NatButton
          type={"button"}
          size={TextSize.SMALL}
          option={StyleOption.SECONDARY}
          disabled={isUpToDate}
          clickEvent={discardAction}
          label={"Discard Changes"}
          trackingId={"admin-discard-changes"}
        />
        <NatButton
          type={"button"}
          size={TextSize.SMALL}
          option={StyleOption.PRIMARY}
          disabled={isUpToDate}
          clickEvent={saveAction}
          label={"Save"}
          trackingId={"admin-save"}
        />
        {viewAction}
      </NavigationButtons>
    </Header>
  );
};
