import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";

const labels = {
  0.5: "Useless",
  1: "Useless",
  1.5: "Poor",
  2: "Poor",
  2.5: "Ok",
  3: "Ok",
  3.5: "Good",
  4: "Good",
  4.5: "Excellent",
  5: "Excellent",
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    alignItems: "center",
  },
});

export default function HoverRating(props) {
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const [hover, setHover] = React.useState(-1);
  const classes = useStyles();
  const {callback} = props;

  if (localStorage.getItem("designFeedback") != null || true) {
    return null;
  }

  const handleChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment);
    callback({comment: newComment, score: value});
  };

  let input = null;
  if (value > 0) {
    let placeholder = "What could we improve?";
    if (value >= 4) {
      placeholder = "Glad you liked it! Any specific feedback?";
    }
    input = (
      <div style={{padding: "20px"}}>
        <textarea
          className={"clean-text-area"}
          style={{width: "100%"}}
          autoFocus={true}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    );
  }

  return (
    <center className={classes.root}>
      <Rating
        name="hover-feedback"
        value={value}
        precision={1}
        onChange={(event, newValue) => {
          setValue(newValue);
          callback({score: newValue, comment: comment});
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      <div style={{display: value > 0 ? "none" : "block"}}>
        {value !== 0 || hover !== -1
          ? labels[hover !== -1 ? hover : value]
          : "How was your experience?"}
      </div>
      {input}
    </center>
  );
}
