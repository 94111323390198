import React from "react";
import {IconContainer} from "../styles";
import {IoChevronUpCircleSharp, IoCloseCircleSharp} from "react-icons/io5";

interface SectionToggleProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SectionToggle = (props: SectionToggleProps): JSX.Element => {
  const {show, setShow} = props;
  return (
    <IconContainer onClick={() => setShow(!show)}>
      {show ? <IoCloseCircleSharp /> : <IoChevronUpCircleSharp />}
    </IconContainer>
  );
};
