import styled from "styled-components";
import {FONT_FAMILY_MEDIUM, colors} from "./globals";
import {isMobile} from "../../../../_shared/navigation";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";

export const DepositSubmissionJourneySteps = styled.div<{
  shouldOverflow: boolean;
}>`
  font-family: ${FONT_FAMILY_MEDIUM};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  height: fit-content;
  overflow-x: ${(props) => (props.shouldOverflow ? "auto" : "visible")};
  position: relative;
`;

export const PageStepper = styled.div<{right: boolean}>`
  font-family: ${FONT_FAMILY_MEDIUM};
  margin: auto 0 0 ${(props) => (props.right ? "-16px" : "0px")};
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const DepositSubmissionJourneyDetails = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.isMobile ? "2rem" : "4rem")} 0;
  ${StandardElement} {
    margin-bottom: ${(props) => (props.isMobile ? "0" : "1rem")};
    :last-child {
      margin-bottom: ${(props) => (props.isMobile ? "1rem" : "0")};
    }
    margin-right: ${(props) => (props.isMobile ? "0" : "")};
  }
`;
export const DepositSubmissionJourneyDetailInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const DepositSubmissionJourneyDetailImage = styled.img`
  width: 100%;
  height: ${isMobile() ? "8rem" : "16rem"};
  border-radius: 0.5rem;
  object-fit: cover;
  margin: auto;
`;

export const DepositSubmissionJourneyDetailHeader = styled.div`
  font-size: 1.5rem;
  margin: 0.5rem 0;
`;

export const DepositSubmissionJourneyDetailMessage = styled.div<{
  fontSize: string;
}>`
  font-size: ${(props) => props.fontSize};
  margin: 0.5rem 0;
  height: 4.5rem;
  color: ${colors.journeyDetailMessage};
`;

export const LineSeparator = styled.div<{width: number}>`
  border: 1px solid black;
  position: absolute;
  left: 100%;
  top: 50%;
  // The reason we add 1 is because of the borders
  width: ${(props) => props.width + 1}px;
`;

export const PreviousLineSeparator = styled.div<{width: number}>`
  border: 1px solid black;
  position: absolute;
  right: 100%;
  top: 50%;
  // The reason we add 1 is because of the borders
  width: ${(props) => props.width + 1}px;
`;
