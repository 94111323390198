//Packages
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
//Core
import {isBlankString} from "@natomas/core";
//Database
//import {updateNatomasUserEvents} from "../../../database/firebase/user";
//Styles
import {GridHeader, Header, PortalLayout} from "./styles";
//Shared
import {
  getHeaderChildrenForPageId,
  getMenuItems,
  getPageComponent,
  getTitleForPageId,
  isNatPage,
} from "../_shared/navigation/PageNavigation";
import {IntercomController} from "../../_shared/generics/IntercomController";
import {SideBar} from "../_shared/components/SideBar";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {useUrl} from "../../_shared/hooks/useUrl";

import {Page} from "../../_shared/generics/page";
import {getStore} from "../../../store";
import {updateAdditionLeftPadding} from "../../_shared/slices/PageSlice";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import {isMobile} from "../../_shared/navigation";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../_shared/generics/page/components/PageContainer";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {ReferralRock} from "../_shared/components/ReferralRock";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const ProjectSummaryLayout = () => {
  const {url, getPageFromURL} = useUrl();
  const {isCustomerPreDeposit} = useCurrentCustomer();
  const {uid} = useCurrentUser();
  const {IS_MAPPING_ENABLED} = useGlobalConfiguration();
  const [tabIds, setTabIds] = useState<string[] | undefined>();
  const [showReferralRock, setShowReferralRock] = useState(false);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(true);
  const [isPortalReady, setIsPortalReady] = useState<boolean>(false);
  const {IS_SSD_LIVE} = useGlobalConfiguration();
  const {trackPageView} = useMatomo();

  const getPageID = (tabs: string[] | null, page: string | null) => {
    if (!isBlankString(page) && !isBlankString(getTitleForPageId(page))) {
      return page;
    } else if (tabs) {
      //Default to the first tab in the tab list.
      return tabs[0];
    } else return null;
  };

  const [selectedTab, setSelectedTab] = useState(
    getPageID(null, getPageFromURL())
  );

  useEffect(() => {
    trackPageView({documentTitle: getTitleForPageId(selectedTab)});
  }, [selectedTab]);

  useEffect(() => {
    getStore().dispatch(
      isMobile()
        ? updateAdditionLeftPadding(0)
        : updateAdditionLeftPadding(isSideBarExpanded ? 320 : 65)
    );
    return () => {
      getStore().dispatch(updateAdditionLeftPadding(0));
    };
  }, [isSideBarExpanded]);

  useEffect(() => {
    setTabIds(getMenuItems(isCustomerPreDeposit, IS_MAPPING_ENABLED));
  }, [isCustomerPreDeposit]);

  useEffect(() => {
    if (tabIds) {
      setSelectedTab(getPageID(tabIds, getPageFromURL()));
    }
  }, [tabIds, url]);

  useEffect(() => {
    if (uid || !IS_SSD_LIVE) {
      setTimeout(() => setIsPortalReady(true), 750);
    } else {
      setIsPortalReady(false);
    }
  }, [uid, IS_SSD_LIVE]);

  const getSelectedTab = (tab: string | null): JSX.Element => {
    return getPageComponent(tab);
  };

  const toggleReferralRock = () => {
    setShowReferralRock(!showReferralRock);
  };

  const getHeader = () => {
    if (IS_SSD_LIVE) {
      return (
        <PageContainer
          stickyOptions={{height: "4rem", mode: PageContainerStickyMode.HEADER}}
          border={true}
          backgroundColor={"white"}
          overridePadding={!isNatPage(selectedTab)}
          overrideAnimation={isNatPage(selectedTab)}
        >
          <GridHeader addPadding={!isNatPage(selectedTab)} isMobile={isMobile}>
            {getTitleForPageId(selectedTab)}
          </GridHeader>
          {getHeaderChildrenForPageId(selectedTab)}
        </PageContainer>
      );
    } else {
      return (
        <Header hidden={isCustomerPreDeposit} isMobile={isMobile}>
          {getTitleForPageId(selectedTab)}
        </Header>
      );
    }
  };

  return (
    <>
      <LoadingPanel show={!isPortalReady} />
      <ReferralRock show={showReferralRock} setShow={setShowReferralRock} />
      <PortalLayout>
        <Helmet>
          <title>Villa | Project Summary</title>
          <meta name="description" content="Your Villa Dashboard" />
        </Helmet>
        <SideBar
          toggleReferralRock={toggleReferralRock}
          selectedTab={selectedTab}
          availableTabs={tabIds}
          isSidebarExpanded={isSideBarExpanded}
          setIsSidebarExpanded={setIsSideBarExpanded}
        />
        <ErrorBoundary>
          {/*id={"main-content-wrapper"} is needed for mobile menu*/}
          <Page id={"main-content-wrapper"}>
            {getHeader()}
            {getSelectedTab(selectedTab)}
          </Page>
        </ErrorBoundary>
      </PortalLayout>
    </>
  );
};

export {ProjectSummaryLayout};
