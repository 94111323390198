import {
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  PASSWORD_KEY,
  PHONE_NUMBER_KEY,
} from "../../_shared/forms/FormModels";
import {
  Address,
  formatPhoneNumber,
  isBlankString,
  PhoneNumberFormatType,
} from "@natomas/core";
import {useDispatch, useSelector} from "react-redux";
import {REASON_KEY} from "../views/stepTwo";
import {MULTI_FAMILY_KEY} from "../views/stepTwoSubstepOne";
import {TIMELINE_KEY} from "../views/stepThree";
import {UNIT_TYPE_KEY} from "../views/stepOne";
import {setStep} from "../slices/IntakeFormSlice";
import {Authentication, Users, Utilities} from "../../../database/firebase";
import {intakeFormToCustomer} from "./intakeFormToCustomer";
import {customerInfoToNatomasCustomer} from "../../../database/firebase/adapters";
import {openURLInNewTab, toCatalog, toPortal} from "../../_shared/navigation";
import firebase from "firebase";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {triggerIntakeZapier} from "./GenericIntakeHelper";
import {useUTMInputs} from "../hooks/useUTMInputs";
import {WEBINAR_PAGE_SOURCE_FOR_REDIRECT} from "./constants";
import {devLogger} from "../../../.developerSettings";
import {getParameter} from "../../_shared/cookies";
import {JourneyStepName, useTracking} from "../../_shared/hooks/useTracking";
import {getAddressFilter, tryFilterByType} from "../../_shared/catalog/filter";
import {isProd} from "../../_shared/application";

const counties = [
  "Alameda County",
  "Santa Clara County",
  "San Mateo County",
  "Contra Costa County",
  "Solano County",
  "San Francisco County",
  "Sonoma County",
  "Napa County",
  "Marin County",
  "Los Angeles County",
  "Orange County",
  "Santa Cruz County",
];

const isValid = (values, errors, shouldUseSSD) => {
  const missingFields =
    isBlankString(values[PHONE_NUMBER_KEY]) ||
    isBlankString(values[FIRST_NAME_KEY]) ||
    isBlankString(values[LAST_NAME_KEY]) ||
    isBlankString(values[EMAIL_KEY]) ||
    (shouldUseSSD && isBlankString(values[PASSWORD_KEY]));

  return !missingFields && Object.values(errors).length <= 0;
};

export const useIntakeFormValues = (formik, shouldUseSSD) => {
  const {values, errors, setFieldError} = formik;
  const intakeFormState = useSelector((state) => state.intakeForm);
  const {
    address,
    street,
    street_number,
    city,
    state,
    zip,
    country,
    county,
    latitude,
    longitude,
  } = intakeFormState;
  const reason = values[REASON_KEY];
  const multiFamily = values[MULTI_FAMILY_KEY];
  const timeline = values[TIMELINE_KEY];
  const unit_type = values[UNIT_TYPE_KEY];
  const utmInputs = useUTMInputs();
  const dispatch = useDispatch();
  const page_source = useSelector((state) => state.utm.page_source);
  const utm_key = useSelector((state) => state.utm.key);

  const phoneNumber = formatPhoneNumber(
    values[PHONE_NUMBER_KEY],
    PhoneNumberFormatType.RAW
  );

  const {markJourneyEvent} = useTracking();

  const information = [
    {
      inputType: "text",
      inputName: "first_name",
      inputValue: values[FIRST_NAME_KEY],
    },
    {
      inputType: "text",
      inputName: "last_name",
      inputValue: values[LAST_NAME_KEY],
    },
    {inputType: "email", inputName: "email", inputValue: values[EMAIL_KEY]},
    {
      inputType: "tel",
      inputName: "phone",
      inputValue: phoneNumber,
    },
    {
      inputType: "text",
      inputName: "street",
      inputValue: street_number + " " + street,
    },
    {inputType: "text", inputName: "city", inputValue: city},
    {inputType: "text", inputName: "state", inputValue: state},
    {inputType: "text", inputName: "zip", inputValue: zip},
    {inputType: "text", inputName: "county", inputValue: county},
    {inputType: "text", inputName: "reason", inputValue: reason},
    {inputType: "text", inputName: "timeline", inputValue: timeline},
    {inputType: "text", inputName: "multiFamily", inputValue: multiFamily},
    {
      inputType: "text",
      inputName: "unit_type",
      inputValue: unit_type,
    },
    {inputType: "number", inputName: "latitude", inputValue: latitude},
    {inputType: "number", inputName: "longitude", inputValue: longitude},
    ...utmInputs,
  ];

  const {IS_WATCH_WEBINAR_LIVE, WEBINAR_REDIRECT_URL, STREAMLINED_JOURNEY} =
    useGlobalConfiguration();
  const isFormValid = isValid(values, errors, shouldUseSSD);

  const intakePayloadAndRedirect = () => {
    const submissionPayload = {};
    information.map((line) => {
      submissionPayload[line.inputName] =
        line.inputValue != null ? line.inputValue : "null";
    });
    submissionPayload.timestamp =
      firebase.firestore.FieldValue.serverTimestamp();
    Utilities.collection("intake_form_submissions")
      .doc()
      .set(submissionPayload)
      .finally(() => {
        dispatch(setStep("step-confirmation"));

        if (
          IS_WATCH_WEBINAR_LIVE &&
          (utm_key === "view_webinar" ||
            getParameter("pgsrc", true)?.includes(
              WEBINAR_PAGE_SOURCE_FOR_REDIRECT
            ))
        ) {
          openURLInNewTab(WEBINAR_REDIRECT_URL);
        }
      });
  };

  const finalSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    const firstName = values[FIRST_NAME_KEY];
    const lastName = values[LAST_NAME_KEY];
    const email = values[EMAIL_KEY];
    const phone = values[PHONE_NUMBER_KEY];
    const password = values[PASSWORD_KEY];

    let confirmation = "refer";
    if (counties.includes(county) || state.toUpperCase() === "TX") {
      confirmation = "call";
    }

    formik.setFieldValue("confirmation", confirmation);

    const encodedStreet = street_number + "%20" + street;
    const date = new Date();

    if (isProd || !email.toLowerCase().includes("test")) {
      document.querySelector("#hidden-submit-button").click();
      triggerIntakeZapier(
        email,
        firstName,
        lastName,
        phoneNumber,
        date,
        encodedStreet,
        city,
        state,
        zip,
        page_source
      ).then((r) => devLogger(r));
    }

    if (shouldUseSSD) {
      Authentication.createNewUserWithEmailAndPassword(
        firstName,
        lastName,
        email,
        password
      )
        .then((user) => {
          if (user) {
            let address = Address.dataToAddress(
              address,
              street_number,
              street,
              city,
              state,
              zip,
              county,
              country,
              latitude,
              longitude
            );
            let customer = intakeFormToCustomer(
              user.uid,
              firstName,
              lastName,
              email,
              phone,
              address
            );

            //convert to natomas
            let natUser = customerInfoToNatomasCustomer(customer);

            Users.setNatomasUser(natUser.user_id, natUser).then(() => {
              Authentication.signInWithEmail(email, password).then(() => {
                intakePayloadAndRedirect();
                markJourneyEvent(JourneyStepName.STARTED);
                if (STREAMLINED_JOURNEY) {
                  tryFilterByType(
                    getAddressFilter(
                      Address.getCounty(address),
                      Address.getState(address)
                    ),
                    []
                  );
                  toCatalog();
                } else {
                  toPortal();
                }
              });
            });
          }
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            const errorDiv = (
              <div>
                {error.message} <a href={"/"}>Want to sign in instead?</a>
              </div>
            );
            formik.setFieldError(EMAIL_KEY, errorDiv);
            formik.setFieldTouched(EMAIL_KEY, true, false);
          }
        });
    } else {
      intakePayloadAndRedirect();
    }
  };

  return {information, isFormValid, finalSubmit};
};
