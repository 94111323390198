export const colors = {
  feasibleZoneBlue: "#d8dff7",
  feasibleZoneGrey: "#dfe1e3",
  primaryResidenceBlue: "#92a6ed",
  primaryResidenceGrey: "#505050",
  propertyLineBlue: "#4E6FE5",
  propertyLineGrey: "#415053",
  aduRed: "#F3887C",
  textGrey: "#3E5054",
  textBlue: "#4E6FE5",
};

export const VIEWS = {
  SEARCH_VIEW: "SEARCH_VIEW",
  MAP_VIEW: "MAP_VIEW",
};

export const BREAKPOINT_MOBILE_WIDTH = 576;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_DESKTOP_SMALL = 992;
export const BREAKPOINT_DESKTOP_LARGE = 1240;
export const BREAKPOINT_DESKTOP_X_LARGE = 1600;
