import React from "react";
//Shared
import {
  StyledInput,
  StyledErrorMessage,
  StyledWarning,
} from "../../../forms/_shared/styles";

interface IFormInput {
  label: string;
  showLabel?: boolean | true;
  id: string;
  name: string;
  type: string;
  onChange?: any;
  onBlur?: any;
  value?: string;
  touched?: any;
  errors?: any;
}

const checkInput = (touched: any, errors: any, name: string) => {
  return touched[name] && errors[name];
};

const FormInput = (props: IFormInput) => {
  const {
    name,
    type,
    label,
    showLabel,
    value,
    onChange,
    onBlur,
    touched,
    errors,
  } = props;
  return (
    <>
      {showLabel && <label htmlFor={type}>{label}</label>}
      <StyledInput
        showingError={checkInput(errors, touched, name)}
        type={type}
        placeholder={label}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
      />
      {checkInput(errors, touched, name) && (
        <StyledWarning>
          <StyledErrorMessage name={name} />
        </StyledWarning>
      )}
    </>
  );
};

export {FormInput};
