import React from "react";
import {WHITE} from "../../colors";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../page/components/PageContainer";
import {PageElement} from "../page/components/PageElement";
import {HeaderContent, HeaderTitle} from "./styles";

export const Header = (props: {
  children: any;
  height: string;
  width: number;
  title?: string;
  noPadding?: boolean;
}) => {
  const {children, width, title, noPadding, height} = props;
  return (
    <>
      <PageContainer
        backgroundColor={WHITE}
        overridePadding={noPadding}
        stickyOptions={{height: height, mode: PageContainerStickyMode.HEADER}}
      >
        <PageElement size={width} height={height}>
          <HeaderContent>
            {title && <HeaderTitle lineHeight={height}>{title}</HeaderTitle>}
            {children}
          </HeaderContent>
        </PageElement>
      </PageContainer>
    </>
  );
};
