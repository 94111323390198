import {useDispatch, useSelector} from "react-redux";
import {
	editModifierGroup,
	editModifierOverrides,
} from "../../_shared/slices/CatalogSlice";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {ModifierStructureForOverrides} from "../models/ModifierModel";
import {
	getFormInitialValues,
	getFormStructure,
} from "../../_shared/application";
import {AdminForm} from "../../admin/products/styles/AdminStyles";
import {AdminInput} from "../../admin/products/components/AdminComponentFactory";
import {saveModifierGroupToDB} from "../backend/catalogApi";

export const EditModifierOverrides = (props) => {
	const {callback} = props;
	const modifier = useSelector(
		(state) => state.catalog.editingModifierOverrides
	);
	const currentModifierGroup = useSelector(
		(state) => state.catalog.editingModifierGroup
	);

	const dispatch = useDispatch();
	const close = () => {
		dispatch(editModifierOverrides(null));
	};

	const setOverride = (overrideMap) => {
		let overrides = {};
		if (currentModifierGroup?.modifierOverrides != null) {
			overrides = currentModifierGroup.modifierGroupOverrides;
		}
		const copyOverrides = Object.assign({}, overrides);
		const editOverrides = Object.assign(copyOverrides, {
			[modifier.id]: overrideMap,
		});
		const payload = {
			modifierOverrides: editOverrides,
		};
		saveModifierGroupToDB({
			id: currentModifierGroup.id,
			...payload,
		});
		callback(payload);
		dispatch(
			editModifierGroup(Object.assign({...currentModifierGroup}, payload))
		);
	};

	const deleteModifierOverrideAction = (e) => {
		e.preventDefault();
		setOverride(null);
		close();
	};

	const formik = useFormik({
		initialValues: getFormInitialValues(ModifierStructureForOverrides),
		onSubmit: (values) => {
			const modifierGroupToSave = Object.assign({}, values);
			const reqModifiers = values["blockingModifiers"];
			if (reqModifiers != null && Object.keys(reqModifiers).length > 0) {
				modifierGroupToSave.blockingModifiers = reqModifiers;
			} else {
				modifierGroupToSave.blockingModifiers = null;
			}

			setOverride(modifierGroupToSave);
			close();
		},
	});

	useEffect(() => {
		formik.resetForm();
		if (currentModifierGroup != null && modifier != null) {
			let overrideValues = {};
			if (
				currentModifierGroup.modifierOverrides != null &&
				currentModifierGroup.modifierOverrides[modifier.id] != null
			) {
				overrideValues = currentModifierGroup.modifierOverrides[modifier.id];
			}
			for (const key in ModifierStructureForOverrides) {
				if (overrideValues[key] != null) {
					const value = overrideValues[key];
					formik.setFieldValue(key, value);
				}
			}
		}
	}, [currentModifierGroup, modifier]);

	if (currentModifierGroup == null || modifier == null) {
		return null;
	}

	const editingInputStructure = getFormStructure(ModifierStructureForOverrides);
	return (
		<div id="myModal" className="modal" style={{zIndex: 1050}}>
			<div
				className="fullDiv"
				onClick={() => {
					close();
				}}
			/>
			<div className="modal-content eighty-full">
				<AdminForm onSubmit={formik.handleSubmit}>
					{editingInputStructure.map((keyInfo) => {
						const {key} = keyInfo;
						const value = formik.values[key];

						return (
							<AdminInput
								key={key}
								value={value}
								fieldInfo={keyInfo}
								onChange={(value) => {
									formik.setFieldValue(key, value);
								}}
								handleChange={formik.handleChange}
							/>
						);
					})}
				</AdminForm>

				<div>
					<button
						onClick={() => {
							formik.handleSubmit();
						}}
					>
						Save
					</button>
					<button onClick={deleteModifierOverrideAction}>
						Remove all overrides
					</button>
				</div>
			</div>
		</div>
	);
};
