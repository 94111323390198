const EMAIL_KEY = "email";
const PASSWORD_KEY = "password";

const emailModel = {
  [EMAIL_KEY]: {
    placeholder: "Email",
    autoComplete: "username",
    type: "email",
    index: 0,
  },
};

const currentPasswordModel = {
  [PASSWORD_KEY]: {
    placeholder: "Password",
    autoComplete: "current-password",
    type: "password",
    index: 1,
  },
};

const newPasswordModel = {
  [PASSWORD_KEY]: {
    placeholder: "Password",
    autoComplete: "new-password",
    type: "password",
    index: 1,
  },
};

export {
  EMAIL_KEY,
  PASSWORD_KEY,
  emailModel,
  currentPasswordModel,
  newPasswordModel,
};
