import React from "react";
import {
  changeFilter,
  deleteFilterByType,
  FilterOption,
  FilterType,
  getPriceRangeFilter,
} from "../../../../_shared/catalog/filter";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {getThousandsNumberFromMicros} from "@natomas/core";
import {PricingMode} from "../../../../_shared/slices/GlobalSlice";
import {
  NatSlider,
  NatSliderOption,
} from "../../../../_shared/generics/slider/NatSlider";

export const PriceRangeFilter = (props: {groups: any[]}) => {
  const {filters, getProductsWithOnlyFilterTypes, products} =
    useProductCatalog();
  const {PRICING_MODE} = useGlobalConfiguration();

  const isUnitPricing = () => {
    return PRICING_MODE === PricingMode.UNIT;
  };

  const getPriceRangeKey = () => {
    return isUnitPricing() ? "unit_price_range" : "price_range";
  };

  const getPricingBounds = (): [number, number] => {
    let min = Infinity;
    let max = -Infinity;
    let totalRangeProducts = getProductsWithOnlyFilterTypes(
      [FilterType.Address, FilterType.ProductLine],
      props.groups,
      Object.values(products)
    );
    if (!totalRangeProducts) return [0, 1];
    else {
      totalRangeProducts.forEach((p: any) => {
        let price = isUnitPricing()
          ? p?.productDetails?.unitPriceMicros
          : p?.priceMicros;
        if (min > price) min = price;
        if (max < price) max = price;
      });
      // Reason we add one is because NumberRanges are exclusive of the upper bound. See filter/options.ts
      return [min, max + 1];
    }
  };

  const valueToText = (value: number): string => {
    return `$` + getThousandsNumberFromMicros(value) + `k`;
  };

  return (
    <NatSlider
      label={isUnitPricing() ? "Unit Price" : "Price"}
      trackingId={"price-range-filter-dropdown"}
      bounds={getPricingBounds()}
      callBack={(option: NatSliderOption) => {
        changeFilter(
          getPriceRangeFilter(
            isUnitPricing() ? FilterType.UnitPriceRange : FilterType.PriceRange,
            option.label,
            option.value
          )
        );
      }}
      valueTextFormatter={valueToText}
      disabledCallback={() => deleteFilterByType(getPriceRangeKey())}
      disabled={filters.find(
        (f: FilterOption) => f.type === getPriceRangeKey()
      )}
    />
  );
};
