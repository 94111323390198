import {createSlice} from "@reduxjs/toolkit";
import {getParameter} from "../../_shared/cookies";

const getInitialState = () => {
  return {
    step: "step-contact",
    address: getParameter("address", true),
    street_number: getParameter("street_number", true),
    street: getParameter("street", true),
    city: getParameter("city", true),
    state: getParameter("state", true),
    zip: getParameter("postal_code", true),
    county: getParameter("county", true),
    country: getParameter("country", true),
    latitude: getParameter("lat"),
    longitude: getParameter("lng"),
  };
};

const intakeFormSlice = createSlice({
  name: "intake",
  initialState: getInitialState(),
  reducers: {
    resetIntakeForm: (state, action) => {
      const initialState = getInitialState();
      Object.keys(initialState).forEach((k) => {
        state[k] = initialState[k];
      });
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setStreetNumber: (state, action) => {
      state.street_number = action.payload;
    },
    setStreet: (state, action) => {
      state.street = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setZip: (state, action) => {
      state.zip = action.payload;
    },
    setCounty: (state, action) => {
      state.county = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    setLongitude: (state, action) => {
      state.longitude = action.payload;
    },
  },
});

export const intakeFormReducer = intakeFormSlice.reducer;
export const {
  setStep,
  setAddress,
  setStreet,
  setCity,
  setState,
  setZip,
  setCounty,
  setCountry,
  setStreetNumber,
  setLatitude,
  setLongitude,
  resetIntakeForm,
} = intakeFormSlice.actions;
