import {useFormik} from "formik";
import {getFormInitialValues, getFormStructure} from "../application";
import {GENERAL_INTAKE_FORM} from "../forms/FormModels";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setShowConfirmation} from "../../design-tool/slices/configurationSlice";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import {isBlankString} from "@natomas/core";
import {saveConfigurationAPIV2} from "../../design-tool/backend/ConfigurationAPI";
import {PuffLoader} from "react-spinners";
import {useHistory} from "react-router-dom";
import {constructValidation} from "../forms/FormHelper";

function addressComponent(place, type) {
  var component = place.address_components.find(
    (v) => v.types.indexOf(type) >= 0
  );
  if (component) {
    return component.short_name;
  } else {
    return undefined;
  }
}

const HiddenInput = (props) => {
  //Add values needed for input in the associated form
  const {inputType, inputName, inputValue} = props;

  return (
    <input
      type={inputType}
      name={inputName}
      defaultValue={inputValue}
      className="hidden"
    />
  );
};

export const IntakeForm = (props) => {
  const dispatch = useDispatch();
  const {submittingIntake, setSubmittingIntake} = props;
  const [address, setAddress] = useState("");
  const [addressFields, setAddressFields] = useState({});
  const formStructure = getFormStructure(GENERAL_INTAKE_FORM);
  const configuration = useSelector(
    (state) => state.configuration.fetchedConfiguration
  );
  const configurationId = useSelector(
    (state) => state.configuration.currentConfigurationId
  );

  const utms = useSelector((state) => state.utm);
  const handleClose = () => {
    dispatch(setShowConfirmation(false));
  };

  const history = useHistory();

  const renderFunc = ({
    getInputProps,
    getSuggestionItemProps,
    loading,
    suggestions,
  }) => {
    return (
      <div className="autocomplete-root">
        <input
          {...getInputProps()}
          placeholder={"Enter street address"}
          autoFocus={true}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion) => (
            <div
              {...getSuggestionItemProps(suggestion)}
              className={"address-widget"}
            >
              <span>{suggestion.description}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
    setAddressFields({address: address});
    geocodeByAddress(address).then((results) => {
      const place = results[0];
      const params = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
        street_number: addressComponent(place, "street_number"),
        street:
          addressComponent(place, "street_number") +
          " " +
          addressComponent(place, "route"),
        city: addressComponent(place, "locality"),
        state: addressComponent(place, "administrative_area_level_1"),
        zip: addressComponent(place, "postal_code"),
        country: addressComponent(place, "country"),
        county: addressComponent(place, "administrative_area_level_2"),
      };
      setAddressFields(params);
    });
  };

  const formik = useFormik({
    initialValues: formStructure,
    validationSchema: constructValidation(GENERAL_INTAKE_FORM),
    onSubmit: (values) => {
      setSubmittingIntake(true);
      document.querySelector("#hidden-submit-button").click();
      saveConfigurationAPIV2({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        address: addressFields.address,
        configurationId: configurationId,
      }).then((result) => {
        handleClose();
        history.push("design-thank-you");
      });
    },
  });

  useEffect(() => {
    if (configurationId != null) {
      formik.setFieldValue(
        "configurationURL",
        "https://app.villahomes.com/design?cid=" + configurationId
      );
    }
    if (configuration != null) {
      formik.setFieldValue(
        "configurationSpreadsheetURL",
        "https://docs.google.com/spreadsheets/d/" + configuration.spreadsheetId
      );
    }
  }, [configurationId, configuration]);

  const {touched, values, errors} = formik;

  const information = [
    {
      inputType: "text",
      inputName: "configurationURL",
      inputValue: values.configurationURL,
    },
    {
      inputType: "text",
      inputName: "configurationSpreadsheetURL",
      inputValue: values.configurationSpreadsheetURL,
    },
    {inputType: "text", inputName: "first_name", inputValue: values.firstName},
    {inputType: "text", inputName: "last_name", inputValue: values.lastName},
    {inputType: "email", inputName: "email", inputValue: values.email},
    {inputType: "tel", inputName: "phone", inputValue: values.phoneNumber},
    {inputType: "text", inputName: "street", inputValue: addressFields.street},
    {inputType: "text", inputName: "city", inputValue: addressFields.city},
    {inputType: "text", inputName: "state", inputValue: addressFields.state},
    {inputType: "text", inputName: "zip", inputValue: addressFields["zip"]},
    {inputType: "text", inputName: "county", inputValue: addressFields.county},
    {
      inputType: "text",
      inputName: "latitude",
      inputValue: addressFields["lat"],
    },
    {
      inputType: "text",
      inputName: "longitude",
      inputValue: addressFields["lng"],
    },
    {inputType: "text", inputName: "page_source", inputValue: utms.page_source},
    {inputType: "text", inputName: "utm_adgroup", inputValue: utms.utm_adgroup},
    {
      inputType: "text",
      inputName: "utm_campaign",
      inputValue: utms.utm_campaign,
    },
    {inputType: "text", inputName: "utm_content", inputValue: utms.utm_content},
    {inputType: "text", inputName: "utm_medium", inputValue: utms.utm_medium},
    {
      inputType: "text",
      inputName: "utm_placement",
      inputValue: utms.utm_placement,
    },
    {inputType: "text", inputName: "utm_source", inputValue: utms.utm_source},
    {inputType: "text", inputName: "utm_term", inputValue: utms.utm_term},
    {inputType: "text", inputName: "key", inputValue: utms.key},
  ];

  let isDisabled = false;
  let allFieldsEntered = true;
  for (const input of information) {
    if (
      isBlankString(input.inputValue) &&
      !input.inputName.startsWith("utm") &&
      input.inputName !== "page_source" &&
      input.inputName !== "key"
    ) {
      allFieldsEntered = false;
      isDisabled = true;
      break;
    }
  }

  isDisabled = isDisabled || configuration == null || configuration.id == null;

  return (
    <div className="modal-wrapper" id="confirmation-modal">
      <Modal show={true} onHide={handleClose} centered size="md">
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton style={{position: "relative"}}>
            <h4>Let's get to know you!</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                className={
                  "fullDiv " + (submittingIntake === true ? "" : "hidden")
                }
                style={{zIndex: 50, backgroundColor: "rgba(255,255,255,0.8)"}}
              >
                <div
                  style={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <span style={{width: "30px", marginTop: "-24px"}}>
                    <PuffLoader size={22} />
                  </span>
                  <span>Submitting...</span>
                </div>
              </div>
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                highlightFirstSuggestion={true}
                searchOptions={{
                  componentRestrictions: {country: "us"},
                  types: ["geocode"],
                }}
              >
                {renderFunc}
              </PlacesAutocomplete>
              {Object.keys(addressFields).length === 0 ? null : (
                <div>
                  <div
                    style={{
                      display:
                        Object.keys(addressFields).length === 0
                          ? "none"
                          : "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{width: "45%"}}>
                      <input
                        id={"firstName"}
                        name={"firstName"}
                        type={"text"}
                        placeholder={"First Name"}
                        value={values["firstName"]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={"formik-input"}
                        autoFocus={true}
                      />
                    </div>
                    <div style={{width: "45%"}}>
                      <input
                        id={"lastName"}
                        name={"lastName"}
                        type={"text"}
                        placeholder={"Last Name"}
                        value={values["lastName"]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={"formik-input"}
                      />
                    </div>
                  </div>
                  {Object.keys(addressFields).length === 0
                    ? null
                    : formStructure.map((inputType) => {
                        const {key, name, type, placeholder} = inputType;
                        const style = {};
                        if (errors[key] && touched[key]) {
                          style.border = "1px solid red";
                        }
                        let content = (
                          <div style={{marginBottom: "10px"}}>
                            <input
                              id={key}
                              name={key}
                              type={type}
                              placeholder={placeholder}
                              value={values[key]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={"formik-input"}
                              style={style}
                            />
                            {errors[key] && touched[key] ? (
                              <div style={{fontSize: 14}}>{errors[key]}</div>
                            ) : null}
                          </div>
                        );
                        if (key.indexOf("Name") > 0) {
                          return null;
                        }

                        return <div key={key}>{content}</div>;
                      })}
                </div>
              )}
              {(configuration == null || configuration.id == null) &&
              allFieldsEntered ? (
                <div
                  style={{
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span style={{width: "25px"}}>
                    <PuffLoader size={20} />
                  </span>
                  <span>saving design...</span>
                </div>
              ) : null}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <button
                className="button-secondary"
                type="none"
                style={{width: "45%"}}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                className="button-primary"
                type="submit"
                style={{width: "45%"}}
                disabled={isDisabled}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <form
        className="hidden"
        action="https://go.villahomes.com/l/909732/2021-05-05/386q"
        method="POST"
        target="hiddenFrame"
      >
        {information.map((item) => (
          <HiddenInput
            inputName={item.inputName}
            inputValue={item.inputValue}
            inputType={item.inputType}
            key={item.inputName}
          />
        ))}

        <button className="hidden" type="submit" id="hidden-submit-button" />
      </form>
      <iframe
        name="hiddenFrame"
        width="0"
        height="0"
        border="0"
        style={{display: "none"}}
      ></iframe>
    </div>
  );
};
