import {SHOW_ONLY_UNIT_PRICING} from "./ConfigurationSettings";
import {isBlankString} from "@natomas/core";
import {getParameter} from "../../_shared/cookies/getParameter";

export const getUnitFromURL = () => {
  return getParameter("unit", true);
};

export const getProductGroupFromURL = () => {
  return getParameter("gid", true);
};

export const getBedBathString = (product, styling) => {
  if (product != null) {
    const {bedrooms, bathrooms} = product.productDetails;
    const bedString = bedrooms > 1 ? "beds" : "bed";
    const bathString = bathrooms > 1 ? "baths" : "bath";
    let seperator = ", ";
    if (styling === "line") {
      seperator = "  |  ";
    }
    return `${bedrooms} ${bedString}${seperator}${bathrooms} ${bathString}`;
  }
  return "";
};

export const getSqFtString = (product) => {
  if (product != null) {
    const squareFeet = product.productDetails.squareFeet;
    return squareFeet + " sq. ft.";
  }
  return "";
};

export const getPricePerSqFtString = (product) => {
  if (product != null) {
    const squareFeet = product.productDetails.squareFeet;

    return `$${Math.round(
      getBasePriceForDesignStudio(product) / 100 / squareFeet
    )}/sq. ft.`;
  }
  return "";
};

export const getLengthAndWidthString = (product) => {
  if (
    product != null &&
    product.productDetails != null &&
    product.productDetails.dimensions != null
  ) {
    const dimensions = product.productDetails.dimensions;
    return (
      getLengthFromDimension(dimensions.frontLength) +
      " x " +
      getLengthFromDimension(dimensions.sideLength)
    );
  }
  return "";
};

export const getLengthFromDimension = (dimension) => {
  if (dimension == null) {
    return "";
  }

  let feetString = "" + dimension.feet + "'";
  let inchesString = "";
  if (dimension.inches > 0) {
    inchesString = " " + dimension.inches + '"';
  }
  return feetString + inchesString;
};

export const getDescriptionForModifier = (modifier) => {
  if (!isBlankString(modifier.displayDescription)) {
    return modifier.displayDescription;
  } else if (!isBlankString(modifier.displayDescriptionDefault)) {
    return modifier.displayDescriptionDefault;
  } else if (!isBlankString(modifier.prettyDescription)) {
    return modifier.prettyDescription;
  }
  return "";
};

export const getBasePriceForDesignStudio = (product) => {
  if (product == null) {
    return 0;
  }
  if (SHOW_ONLY_UNIT_PRICING()) {
    return getUnitPriceInMicros(product);
  }

  return product.priceMicros;
};

export const getUnitPriceInMicros = (product) => {
  return product.productDetails.unitPriceMicros;
};

export const getConstructionPriceMicros = (product) => {
  return product.priceMicros - product.productDetails.unitPriceMicros;
  return product.productDetails.constructionPriceMicros;
};

export const getLengthAndDepthInInches = (product) => {
  const {productDetails} = product;
  if (productDetails != null && productDetails.dimensions != null) {
    const {frontLength, sideLength} = productDetails.dimensions;
    return [
      getInchesFromDimension(frontLength),
      getInchesFromDimension(sideLength),
    ];
  }
  return [0, 0];
};

const getInchesFromDimension = (dimension) => {
  return dimension.feet * 12 + dimension.inches;
};
