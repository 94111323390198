import React from "react";
import {useSelector} from "react-redux";
import {Image} from "../components/image";

export const CurrentImage = () => {
	const step = useSelector((state) => state.intakeForm.step);

	switch (step) {
		case "step-zero":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fstep-zero-min.png?alt=media&token=942a08cb-36fc-4021-bb23-c767de81089b`}
					preserveAspectRatio={true}
				/>
			);
		case "step-one":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fquestion-one.jpg?alt=media&token=d8e1725e-e314-47cc-a8e4-1a6037bf5dbf`}
					preserveAspectRatio={true}
				/>
			);
		case "step-one-substep-one":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fquestion-one-sub-one.jpg?alt=media&token=bc914797-5039-459c-97c9-f301d00e0c9b`}
					preserveAspectRatio={true}
				/>
			);
		case "step-one-substep-two":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fstep-one-substep-two-min.png?alt=media&token=8e0f3389-a616-49e1-8538-1d01ced8473b`}
					preserveAspectRatio={true}
				/>
			);
		case "step-two":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fstep-two-min.png?alt=media&token=f2190a8e-6e93-46bc-a472-2f6082388e02`}
					preserveAspectRatio={true}
				/>
			);
		case "step-two-substep-one":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fstep-two-substep-one-min.png?alt=media&token=9560b22a-8418-4a38-836c-66ae6859751a`}
					preserveAspectRatio={true}
				/>
			);
		case "step-three":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fstep-three-min.png?alt=media&token=08c8d1e7-615c-4c70-8997-2b16974813cd`}
					preserveAspectRatio={true}
				/>
			);
		case "step-contact":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fcontact-min.png?alt=media&token=b52c7a4f-1692-4294-b02a-1a060b26f06c`}
					preserveAspectRatio={true}
				/>
			);
		case "step-no-contact":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fcontact-min.png?alt=media&token=b52c7a4f-1692-4294-b02a-1a060b26f06c`}
					preserveAspectRatio={true}
				/>
			);
		case "step-confirmation":
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fcontact-min.png?alt=media&token=b52c7a4f-1692-4294-b02a-1a060b26f06c`}
					preserveAspectRatio={true}
				/>
			);
		default:
			return (
				<Image
					imageSrc={`https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fstep-zero-min.png?alt=media&token=942a08cb-36fc-4021-bb23-c767de81089b`}
					preserveAspectRatio={true}
				/>
			);
	}
};
