import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from "react";
import {MapContainer, ZoomControl} from "react-leaflet";
import {
  DisclaimerContainer,
  MapContainerWrapper,
  MapViewContainer,
} from "./styled";
import "leaflet.pattern";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "leaflet.path.drag";
import "leaflet-path-transform";
import "leaflet-imageoverlay-rotated";
// Shows dimensions on lines/polygons
import "leaflet-measure-path";
import "leaflet-measure-path/leaflet-measure-path.css";
import {getAduPolygon, getNonAduStructures} from "../../helpers/structures";
import {
  convertMultipolygonToPolygon,
  getSetbacks,
} from "../../helpers/geometry";
import {ArrowLeft} from "../../../../../assets/icons";
import {ADUList} from "../ADUList";
import {MapToolbar} from "./MapToolbar";
import {UserFeedback} from "./UserFeedback";
// Turf.js methods
import turfDifference from "@turf/difference";
import turfBbox from "@turf/bbox";
import turfBboxPolygon from "@turf/bbox-polygon";
import turfBooleanWithin from "@turf/boolean-within";
import turfBooleanOverlap from "@turf/boolean-overlap";
import turfBuffer from "@turf/buffer";
// Constants
import {colors} from "../../theme/constants";
import {message} from "antd";
import {ImageCategory, isBlankString} from "@natomas/core";
// TODO Address navigations
import {isMobile} from "../../../../_shared/navigation";
import {
  auth,
  Authentication,
  Configuration,
  firestore,
  Utilities,
} from "../../../../../database/firebase";
import {
  scrollToTop,
  setURLPathAndQuery,
  setURLQueryKeyValue,
} from "../../../../_shared/navigation/_helpers";
import {getLengthAndDepthInInches} from "../../../../design-tool/logic/ProductUtils";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getParameter} from "../../../../_shared/cookies/getParameter";
import {setProductId} from "../../../../_shared/slices/CatalogSlice";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import useMountEffect from "../../../../_shared/hooks/useMountEffect";
import {saveConfigurationAPIV2} from "../../../../design-tool/backend/ConfigurationAPI";
import {setConfigurationId} from "../../../../design-tool/slices/configurationSlice";
import {getImageDetailsByCategory} from "../../../../catalog/_shared/utilities/images";
import {useImage} from "../../../../_shared/hooks/useImage";
import {fetchImage} from "../../../../design-tool/logic/data";
import {Disclaimer} from "./Disclaimer";
const leafletImage = require("leaflet-image");

var L = window.L;

const MAP_BOX_TOKEN =
  "pk.eyJ1IjoiamFja2Nvbm5vbGx5NDE1IiwiYSI6ImNrbnVzZnFybjBkb3cycXJxcjJ2OXVkYTQifQ.HrxEp44-s_3xcQiztYeeBA";
const MAP_BOX_STUDIO_TOKEN =
  "pk.eyJ1IjoiamFja2Nvbm5vbGx5NDE1IiwiYSI6ImNrbnVzYzlwbjBkbDEyd3BqNzhhbmY0OW0ifQ.bEma15QYKYcFZCI7NZZNaw";

var mapboxUrl = `https://api.mapbox.com/styles/v1/jackconnolly415/cko22mh1s1gr717oblo8aisus/tiles/256/{z}/{x}/{y}?access_token=${MAP_BOX_STUDIO_TOKEN}`;
var mapboxSatelliteUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=${MAP_BOX_TOKEN}`;

const initialState = {mapLayers: {}};

function reducer(state, action) {
  switch (action.type) {
    case "ADD_OR_UPDATE_LAYER":
      return {
        mapLayers: {...state.mapLayers, ...action.payload},
      };
    default:
      throw new Error();
  }
}

const getCornersFromLatLngs = (latLngs) => {
  return [latLngs[0][0], latLngs[0][1], latLngs[0][3]];
};

const getConfigurationMapRef = () => {
  const cid = getParameter(
    Configuration.Constants.CONFIGURATION_ID_URL_KEY,
    true
  );
  if (!isBlankString(cid)) {
    return firestore
      .collection(Configuration.Constants.CONFIGURATION_MAPS_DB_KEY)
      .doc(cid);
  }
  const newCID = firestore
    .collection(Configuration.Constants.CONFIGURATION_DESIGN_DB_KEY)
    .doc().id;
  return firestore
    .collection(Configuration.Constants.CONFIGURATION_MAPS_DB_KEY)
    .doc(newCID);
};

const DEMO_ACCOUNT_EMAIL = "villatester@gmail.com";

const Map = (
  {
    propertyGeometry,
    viewPortCenter,
    onGeocodeSubmit,
    placeName,
    neighborData,
    fetchedConfigurationMap,
    parcelSearchData,
    readOnly,
    fetchedConfiguration,
    minimal,
    fullScreen,
    updatePlacement,
    initialPlacement,
  },
  ref
) => {
  const matchImageToUnit = (layer) => {
    const unitLayer = layer ?? getAduFromBuildingsLayer();
    const corners = getCornersFromLatLngs(unitLayer.getLatLngs());
    const unitImageLayer = getAduImageFromBuildingsLayer();
    if (unitImageLayer) {
      unitImageLayer.reposition(corners[0], corners[1], corners[2]);
    }
  };

  useImperativeHandle(ref, () => ({
    updateUnitPlacement: (latLngs) => {
      const unit = getAduFromBuildingsLayer();
      unit.setLatLngs(latLngs);
      matchImageToUnit(unit);
    },
  }));

  const isReadOnly = readOnly === true;
  const isMobileDevice = isMobile();
  const isDesktop = !isMobileDevice;
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatch2 = useDispatch();
  const [map, setMap] = useState(null);
  const [userFeedback, setUserFeedback] = useState({
    message: fullScreen
      ? "Drag and rotate this ADU to fit onto your property"
      : "",
    type: "neutral",
    icon: fullScreen,
  });
  const [isSatelliteActive, setIsSatelliteActive] = useState(false);
  const [isMeasurementActive, setIsMeasurementActive] = useState(false);
  const [isDimensionActive, setIsDimensionActive] = useState(false);
  const [feasibleZone, setFeasibleZone] = useState(null);
  const [isRotatingADU, setIsRotatingADU] = useState(false);
  const [hasRotated, setHasRotated] = useState(false);

  const {currentProducts, product} = useProductCatalog();
  const products = currentProducts;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [foundUnit, setFoundUnit] = useState(false);
  const [hoveredDesign, setHoveredDesign] = useState(null);
  const [isStatic] = useState(isReadOnly);
  const [lastCoordinates, setLastCoordinates] = useState(null);

  const {mapLayers} = state;
  const history = useHistory();
  const handleProceed = () => {
    const sitePayload = {
      unitPlacement: lastCoordinates,
      unit: selectedProduct,
    };
    const payload = {};
    if (parcelSearchData != null) {
      sitePayload.parcelSearchData = parcelSearchData;
      const {city, zip, state, address} = parcelSearchData;
      payload.city = city;
      payload.zip = zip;
      payload.state = state;
      payload.address = address;
    }

    const ref = getConfigurationMapRef();
    const configurationId = ref.id;
    Utilities.setAndMergeDocumentInDB(ref, sitePayload);
    const currentUser = auth.currentUser;
    if (currentUser != null) {
      payload.email = currentUser.email;
      payload.configurationId = configurationId;

      payload.firstName = "";
      payload.lastName = "";
      if (currentUser.email === DEMO_ACCOUNT_EMAIL) {
        payload.firstName = "Marisa";
        payload.lastName = "Robertson";
      }

      saveConfigurationAPIV2(payload).then(() => {
        setURLQueryKeyValue(
          Configuration.Constants.CONFIGURATION_ID_URL_KEY,
          configurationId
        );
        dispatch2(setConfigurationId(configurationId));
      });

      dispatch2(setProductId(selectedProduct.id));
      setURLPathAndQuery(history, "/design", "unit", selectedProduct.id);
    } else {
      setTimeout(() => {
        dispatch2(setProductId(selectedProduct.id));
        setURLPathAndQuery(history, "/design", "unit", selectedProduct.id);
      }, 500);
    }
  };

  const handleImageDownload = () => {
    if (isReadOnly) {
      leafletImage(map, function (err, canvas) {
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "ADU_site_map.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  const [viewport] = useState({
    latitude: viewPortCenter[1],
    longitude: viewPortCenter[0],
  });

  // useMountEffect(() => {
  //   if (
  //     auth.currentUser == null &&
  //     window.location.pathname.startsWith("/go")
  //   ) {
  //     Authentication.signInWithEmail(DEMO_ACCOUNT_EMAIL, "thisisatest");
  //   }
  // });

  // Initializes map
  useEffect(() => {
    function initializeMap() {
      scrollToTop(false);
      initializeMapTileLayer();
      initializeADULayer();
      initializeBuildingsLayer();
      initializeParcelLayer();
    }

    function initializeMapTileLayer() {
      const streets = L.tileLayer(mapboxUrl, {
        id: "streets-map",
        tileSize: 512,
        zoomOffset: -1,
        detectRetina: true,
        maxZoom: 22,
        minZoom: 0,
        attribution:
          ' © <a target="_blank" rel="noreferrer" href="https://www.mapbox.com/">Mapbox</a>',
      }).addTo(map);
      dispatch({type: "ADD_OR_UPDATE_LAYER", payload: {streets: streets}});
    }

    function initializeADULayer() {
      const aduLayer = new L.featureGroup().addTo(map);
      dispatch({type: "ADD_OR_UPDATE_LAYER", payload: {unit: aduLayer}});
    }

    function initializeBuildingsLayer() {
      const buildingsLayer = L.featureGroup().addTo(map);
      dispatch({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {buildings: buildingsLayer},
      });
      setPopOverHandlers(buildingsLayer);
    }

    function initializeParcelLayer() {
      const parcelMapLayer = L.geoJson(propertyGeometry.parcelData, {
        style: {
          color: colors.propertyLineGrey,
          fillOpacity: 1,
          weight: 1.5,
          className: "parcel",
        },
      }).addTo(map);
      dispatch({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {parcel: parcelMapLayer},
      });
      setPopOverHandlers(parcelMapLayer);
    }
    map && initializeMap();
  }, [map, propertyGeometry.parcelData]);

  // Handles satellite view toggle
  useEffect(() => {
    if (isSatelliteActive) {
      activateSatellite();
    } else {
      deActivateSatellite();
    }

    function activateSatellite() {
      const stripesPattern = getStripesPattern();
      if (mapLayers.feasibleZone) {
        mapLayers.feasibleZone.setStyle({
          fillOpacity: 1,
          fillPattern: stripesPattern,
          fillColor: "transparent",
        });
        mapLayers.parcel.setStyle({color: "white"});
      } else {
        mapLayers.parcel.setStyle({fillOpacity: 0, color: "white"});
      }

      const satelliteTile = L.tileLayer(mapboxSatelliteUrl, {
        id: "satellite-map",
        tileSize: 512,
        detectRetina: true,
        zoomOffset: -1,
        maxZoom: 22,
        minZoom: 0,
        attribution:
          ' © <a target="_blank" rel="noreferrer" href="https://www.mapbox.com/">Mapbox</a>',
      });
      dispatch({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {satellite: satelliteTile},
      });
      map.addLayer(satelliteTile);
      map.removeLayer(mapLayers.streets);
    }

    function deActivateSatellite() {
      mapLayers.feasibleZone &&
        mapLayers.feasibleZone.setStyle({
          fillOpacity: 1,
          fillColor: "#dfe1e3",
          fillPattern: null,
        });
      if (mapLayers.streets) {
        map.addLayer(mapLayers.streets);
      }
    }
  }, [isSatelliteActive]);

  // Reset handlers in order to set "active" adu in sidebar
  useEffect(() => {
    if (hoveredDesign) {
      const aduOnUnitLayer = mapLayers.unit.getLayers()[0];
      setAduDragRotateHandlers(aduOnUnitLayer, {isOnUnitLayer: true});
    }
  }, [hoveredDesign]);

  // Handles measurement
  useEffect(() => {
    if (!mapLayers.buildings) return;

    mapLayers.buildings.getLayers().map((l) => updateDimensions(l));
    var editableLayers = new L.FeatureGroup();
    var options = {
      position: "topright",
      buildings: {
        polyline: {
          metric: false,
          feet: true,
          showLength: false,
          guidelineDistance: 20,
          // zIndexOffset: 20000000,
          shapeOptions: {
            color: colors.propertyLineGrey,
            fillOpacity: 1,
            lineCap: "round",
            weight: 5,
            opactiy: 1,
          },
        },
        polygon: false,
        circle: false, // Turns off this drawing tool
        rectangle: false,
        marker: false,
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        remove: false,
      },
    };

    var drawControl = new L.Control.Draw(options);
    var drawInstance = new L.Draw.Polyline(
      map,
      drawControl.options.draw.polyline
    );
    if (isMeasurementActive) {
      setUserFeedback({
        message: "Click on the map to start measurement",
        icon: null,
      });

      drawInstance.enable();

      map.on(L.Draw.Event.CREATED, function (e) {
        const layer = e.layer;
        editableLayers.addLayer(layer);
        editableLayers.addTo(map);

        setUserFeedback({
          message: "Click on measurement line to delete it",
          icon: null,
        });
        setIsMeasurementActive(false);

        // Remove drawn lines on click
        editableLayers.on("click", function (e) {
          editableLayers.removeLayer(e.propagatedFrom);
          const hasMeasurements = Object.keys(map._layers).some(
            (l) => map._layers[l]._measurement
          );
          if (!hasMeasurements) {
            setUserFeedback(null);
          }
        });

        editableLayers.bringToFront();

        // Show measurements on all the layers
        for (let l in editableLayers._layers) {
          editableLayers._layers[l].showMeasurements({
            formatArea: () => null,
            imperial: true,
            showTotalDistance: false,
          });
        }
      });
    } else {
      // Handle toggle off measurement tool

      setUserFeedback({
        message: null,
        icon: null,
      });

      drawInstance.disable();

      map.removeControl(drawControl);
      editableLayers.clearLayers();
      map.removeLayer(drawInstance);
      map.removeLayer(editableLayers);
      map.removeLayer(drawControl);

      new L.Draw.Polyline(map, editableLayers).disable();
    }
  }, [isMeasurementActive]);

  // Calculates the setbacks
  useEffect(() => {
    if (!mapLayers.buildings) return;

    addBuildings(propertyGeometry.buildingData);
    mapLayers.buildings.addTo(map);

    if (mapLayers.buildings.getLayers().length) {
      setSetbacks();
    }

    const buildingsOnMap = [];
    map.eachLayer((layer) => {
      if (layer.options.className === "non-adu") {
        buildingsOnMap.push(layer);
      }
    });

    // Handle warning message when user clicks ADU on mobile
    mapLayers.buildings.on("click", (e) => {
      if (isMobileDevice && e.layer.options.className === "unit") {
        // message.info(
        //   "Please use a desktop instead of a mobile device to position ADU!",
        //   6
        // );
      }
    });
  }, [isMobileDevice, mapLayers.buildings, propertyGeometry.buildingData]);

  // Handle Dimensions display / UX
  useEffect(() => {
    if (mapLayers.buildings && mapLayers.unit) {
      mapLayers.buildings.getLayers().map((l) => updateDimensions(l));
      mapLayers.unit.getLayers().map((l) => updateDimensions(l));
      // reset the transform handlers so that we can show dims on drag
      // This prevents stale state vars from closure of when the event handler was initially set
      const aduOnUnitLayer = mapLayers.unit.getLayers()[0];
      const aduLayerOnBuildingsLayer = getAduFromBuildingsLayer();
      if (aduOnUnitLayer) {
        setAduDragRotateHandlers(aduOnUnitLayer, {isOnUnitLayer: true});
      }
      if (aduLayerOnBuildingsLayer) {
        setAduDragRotateHandlers(aduLayerOnBuildingsLayer, {
          isOnUnitLayer: false,
        });
      }
    }
  }, [isDimensionActive]);

  /**
   * We use this in order to swap the adu from one layer to the other
   * when a design is selected by clicking the design, or on draggend event
   *  */

  useEffect(() => {
    if (selectedProduct) {
      const aduLayer = getAduFromBuildingsLayer();

      if (aduLayer && aduLayer.transform) {
        setRotateHandlers(aduLayer);
      }
      convertAduToBuildingsLayer(selectedProduct);
    }
  }, [selectedProduct]);

  // This orients the layers to that the are in correct z-index
  useEffect(() => {
    if (
      mapLayers.feasibleZone &&
      mapLayers.restrictedZone &&
      mapLayers.parcel
    ) {
      // Orient the different layers
      mapLayers.feasibleZone.bringToBack();
      mapLayers.restrictedZone.bringToBack();
      mapLayers.parcel.bringToBack();
      mapLayers.parcel.setStyle({fillOpacity: 0});
    }

    if (mapLayers.parcel && !isRotatingADU && !hasRotated) {
      const parcelbounds = mapLayers.parcel.getBounds().pad(0);

      map.fitBounds(parcelbounds);
      map.setMaxBounds(parcelbounds.pad(1));
      map.setMinZoom(map.getZoom() - 1);
    }
  }, [
    map,
    hasRotated,
    mapLayers.feasibleZone,
    mapLayers.restrictedZone,
    mapLayers.parcel,
    isRotatingADU,
  ]);

  function setPopOverHandlers(layer) {
    layer.on("mousemove", (e) => {
      if (isRotatingADU) {
        map.closePopup();
        return;
      }
      let text = "";
      const className = e.propagatedFrom.options.className;
      if (className === "unit") return;
      if (className === "non-adu") {
        text = "Residence on Property";
        e.propagatedFrom.setStyle({fillColor: colors.primaryResidenceBlue});
      } else if (className === "feasibleZone") {
        text = "Buildable area";
        e.propagatedFrom.setStyle({fillColor: colors.feasibleZoneBlue});
      } else if (className === "parcel") {
        text = "Your property line";
        e.propagatedFrom.setStyle({color: colors.propertyLineBlue});
      }
      var popup = L.popup({
        className: "map-popup",
        keepInView: true,
        autoPan: false,
        closeButton: false,
      })
        .setLatLng(e.latlng)
        .setContent(`<p>${text}</p>`)
        .openOn(map);
      layer.openPopup(popup);
    });

    layer.on("mouseout", (e) => {
      let color = "";
      const className = e.propagatedFrom.options.className;
      if (className === "unit") return;
      if (className === "non-adu") {
        color = colors.primaryResidenceGrey;
      } else if (className === "feasibleZone") {
        color = colors.feasibleZoneGrey;
      } else if (className === "parcel") {
        color = colors.propertyLineGrey;
        e.propagatedFrom.setStyle({color: color});
      }
      e.propagatedFrom.setStyle({fillColor: color});
      map.closePopup();
    });
  }

  function toggleSatelliteView(e) {
    e.stopPropagation();
    setIsSatelliteActive(!isSatelliteActive);
  }

  function placeAdu(design) {
    const dimensionsInInches = getLengthAndDepthInInches(design);
    const aduSidesDimensions = [
      dimensionsInInches[0],
      dimensionsInInches[1],
      dimensionsInInches[0],
      dimensionsInInches[1],
    ];
    setHoveredDesign(design);
    let aduPolygon = null;

    if (!aduPolygon) {
      try {
        aduPolygon = getAduPolygon(
          aduSidesDimensions,
          feasibleZone,
          fetchedConfigurationMap,
          parcelSearchData,
          design
        );
      } catch (e) {
        console.error("getAduPolygon", e);
        // message.error(
        //   "Oops! We are having trouble placing this ADU, please try again later",
        //   10
        // );
        return;
      }
    }

    // Need this to not duplicate ADUs when hovering over different design cards
    mapLayers.unit && mapLayers.unit.clearLayers();

    // if user clicks the placed ADU polygon on map, we drill down into the detail view
    mapLayers.unit &&
      mapLayers.unit.on("click", function () {
        onAduCardClick(design);
      });

    let aduLatLng = L.geoJson(aduPolygon).getLayers()[0].getLatLngs();
    const updatedAduLayer = L.polygon(aduLatLng, {
      draggable: !isReadOnly,
    });

    // This adds the "ADU" text to the center of the ADU
    updatedAduLayer
      .bindTooltip(design.title, {
        interactive: false,
        className: "adu-tooltip",
        permanent: true,
        direction: "center",
      })
      .openTooltip()
      .setStyle({
        weight: 0,
        fillColor: "#000",
        fillOpacity: 1,
      })
      .addTo(mapLayers.unit);

    updateDimensions(updatedAduLayer);
    setAduDragRotateHandlers(updatedAduLayer, {isOnUnitLayer: true});
  }

  function addBuildings(buildingData) {
    buildingData.forEach((building) => {
      const buildingLayer = L.polygon(
        L.geoJson(building).getLayers()[0].getLatLngs(),
        {
          transform: false,
          editing: false,
          draggable: false,
        }
      );
      buildingLayer.setStyle({
        color: colors.primaryResidenceGrey,
        weight: 0,
        opacity: 0,
        fillColor: colors.primaryResidenceGrey,
        fillOpacity: 1,
        className: "non-adu",
      });
      buildingLayer.addTo(mapLayers.buildings);
      updateDimensions(buildingLayer);
    });
  }

  function getStripesPattern() {
    var stripePattern = new L.StripePattern({
      color: "#FFFFFF",
      spaceColor: "transparent",
      opacity: 0.55,
      spaceOpacity: 0.1,
      weight: 3,
      spaceWeight: 3,
      height: 6,
      angle: 135,
    }).addTo(map);

    mapLayers.feasibleZone &&
      mapLayers.feasibleZone.setStyle({
        fillOpacity: 0.2,
        fillPattern: stripePattern,
        fillColor: "transparent",
      });
    return stripePattern;
  }

  function setSetbacks() {
    const {parcelData} = propertyGeometry;
    // TODO - await neigbor data
    if (!neighborData) return;
    let feasibleZone = getSetbacks(mapLayers, parcelData, neighborData, map, L);
    const stripesPattern = getStripesPattern();

    const feasibleZoneMapLayer = L.geoJson(feasibleZone, {
      weight: 0,
      fillOpacity: 1,
      fillPattern: isSatelliteActive ? stripesPattern : "",
      fillColor: isSatelliteActive ? "transparent" : colors.feasibleZoneGrey,
      className: "feasibleZone",
    }).addTo(map);
    setPopOverHandlers(feasibleZoneMapLayer);

    const restrictedZoneLayer = L.geoJson(
      turfDifference(parcelData, feasibleZone),
      {
        fillColor: "#000",
        fillOpacity: 0,
        weight: 0,
      }
    ).addTo(map);

    setFeasibleZone(feasibleZone);
    dispatch({
      type: "ADD_OR_UPDATE_LAYER",
      payload: {feasibleZone: feasibleZoneMapLayer},
    });
    dispatch({
      type: "ADD_OR_UPDATE_LAYER",
      payload: {restrictedZone: restrictedZoneLayer},
    });
  }

  function getAduFromBuildingsLayer() {
    try {
      return mapLayers.buildings
        .getLayers()
        .filter((i) => i.options.className === "unit")[0];
    } catch (e) {
      // message.error("Oops! Error loading ADU - please try again later.", 10);
      return null;
    }
  }

  function getAduImageFromBuildingsLayer() {
    try {
      return mapLayers.buildings
        .getLayers()
        .filter((i) => i.options.className === "unit-image")[0];
    } catch (e) {
      // message.error("Oops! Error loading ADU - please try again later.", 10);
      return null;
    }
  }

  function verifyAduPlacement(aduLayer, isSatelliteActive) {
    if (!mapLayers.feasibleZone) return;

    const latLngs = aduLayer.getLatLngs();
    setLastCoordinates(
      latLngs[0].map((latLng) => {
        return {
          lat: latLng.lat,
          lng: latLng.lng,
        };
      })
    );

    updatePlacement(latLngs);
    matchImageToUnit(aduLayer);

    let aduColor = "#000";
    let feasibleZoneColor = colors.feasibleZoneGrey;
    const poly = L.polygon(latLngs, {transform: !isReadOnly});
    const feasibleZone = mapLayers.feasibleZone.getLayers()[0];
    const aduPolygon = convertMultipolygonToPolygon(poly.toGeoJSON(13));
    const isOverlappingSetback = !turfBooleanWithin(
      aduPolygon,
      feasibleZone.feature
    );
    let primaryRes = getNonAduStructures(mapLayers)[0];
    const bufferredprimaryRes = turfBuffer(primaryRes, 4, {
      units: "feet",
    });
    const isOverlappingPrimaryResidence = turfBooleanOverlap(
      aduPolygon,
      bufferredprimaryRes
    );

    if (isOverlappingPrimaryResidence) {
      setUserFeedback({
        message: "ADU must not touch other buildings.",
        type: "warning",
        icon: true,
      });
      aduColor = colors.aduRed;
      feasibleZoneColor = isSatelliteActive ? null : colors.feasibleZoneBlue;
    } else if (isOverlappingSetback) {
      setUserFeedback({
        message:
          "ADU must be set back at least 4 ft from side and rear property lines.",
        type: "warning",
        icon: true,
      });

      // showPopupOnFeasibleZone(feasibleZone);
      aduColor = colors.aduRed;
      feasibleZoneColor = isSatelliteActive ? null : colors.feasibleZoneBlue;
    } else {
      setUserFeedback({
        message: "ADU placement is feasible",
        type: "success",
        icon: true,
      });
      aduColor = "#000";
      feasibleZoneColor = isSatelliteActive ? null : colors.feasibleZoneGrey;
    }

    aduLayer.setStyle({fillColor: aduColor});
    feasibleZone.setStyle({fillColor: feasibleZoneColor});
  }

  /**
   * We have 2 Leaflet layers on which the ADU unit appears.
   * The first is the 'unit' layer (this is populated when user hovers over the design in the ADUList sidebar)
   * The second is the 'buildings' layer (when a user is done dragging, or clicks the ADU, we remove it from the 'unit' layer
   * and convert it to the 'buildings' layer so that we can rotate it and compare it primary residence)
   */

  // TODO - consider simplifying this so that we keep that ADU on the 'unit' layer
  function convertAduToBuildingsLayer(product) {
    resetBuildingsLayer();
    const polygon = mapLayers.unit.toGeoJSON(13).features[0];
    addAduToBuildingsLayer(polygon, product);
    const aduLayer = getAduFromBuildingsLayer();
    if (!aduLayer) {
      // message.error("Oops! Error loading ADU - please try again later.", 10);
    } else {
      // aduLayer.transform.enable({rotation: !isReadOnly, scaling: false});
    }
    // TODO - refactor to promise
    // removing this introduces an on draggend bug
    setTimeout(() => {
      resetAduLayer();
    }, 100);
  }

  function resetAduLayer() {
    if (map.hasLayer(mapLayers.unit)) {
      // Need to clear out the map of adu layer
      mapLayers.unit.clearLayers();
      map.removeLayer(mapLayers.unit);
      const aduLayer = new L.featureGroup().addTo(map);
      dispatch({type: "ADD_OR_UPDATE_LAYER", payload: {unit: aduLayer}});
    }
  }

  function resetBuildingsLayer() {
    if (map.hasLayer(mapLayers.buildings)) {
      const aduLayer = getAduFromBuildingsLayer();
      aduLayer && aduLayer.transform && aduLayer.transform.disable();
      mapLayers.buildings.removeLayer(aduLayer);

      setPopOverHandlers(mapLayers.buildings);
      addBuildings(propertyGeometry.buildingData);
    }
  }

  function addAduToBuildingsLayer(aduPolygon, product) {
    if (!aduPolygon) return;

    const bounds =
      initialPlacement ?? L.geoJson(aduPolygon).getLayers()[0].getLatLngs();
    const corners = getCornersFromLatLngs(bounds);

    const floorPlanImages = getImageDetailsByCategory(
      product,
      ImageCategory.FLOOR_PLAN
    );
    let opacity = 1;
    if (floorPlanImages && floorPlanImages.length > 0) {
      opacity = 0.35;
      const imageId = floorPlanImages[0].imageId;
      fetchImage(imageId, true).then((image) => {
        if (image) {
          map.createPane("imagePane");
          map.getPane("imagePane").style.zIndex = 401;
          var imageLayer = L.imageOverlay.rotated(
            image["500"].url,
            corners[0],
            corners[1],
            corners[2],
            {interactive: false, className: "unit-image", pane: "imagePane"}
          );

          imageLayer.addTo(mapLayers.buildings);
        }
      });
    }

    const aduLayer = L.polygon(bounds, {
      draggable: !isReadOnly,
      transform: {
        rotation: !isReadOnly,
        scaling: false,
      },
    });
    aduLayer.setStyle({
      fillColor: "000",
      fillOpacity: opacity,
      weight: 0,
      className: "unit",
    });
    aduLayer.addTo(mapLayers.buildings);

    // TODO - move to use effect -  needed to keep handlers
    setAduDragRotateHandlers(aduLayer, {isOnUnitLayer: false});
    updateDimensions(aduLayer);
  }

  function setAduDragRotateHandlers(unitLayer, {isOnUnitLayer}) {
    if (unitLayer == null) {
      return;
    }
    // only allow rotate once the ADU has been selected
    if (!isOnUnitLayer) {
      setRotateHandlers(unitLayer);
    }

    if (!isOnUnitLayer && unitLayer.transform && !isReadOnly) {
      unitLayer.transform.setOptions({
        rotation: !isReadOnly,
        scaling: false,
        boundsOptions: {
          dashArray: "3, 3",
          color: colors.propertyLineBlue,
          weight: 1.5,
        },
        rotateHandleOptions: {
          color: colors.propertyLineBlue,
          fill: true,
          fillColor: colors.propertyLineBlue,
          weight: 1,
        },
      });
    }

    unitLayer.on("drag", function () {
      verifyAduPlacement(unitLayer, isSatelliteActive);
      if (mapLayers.unit.getLayers()[0]) {
        mapLayers.unit.getLayers()[0].closeTooltip();
      }

      try {
        if (this.transform && this.transform.enabled()) {
          // This updates the rotation handler
          this.transform._updateHandlers();
          const bbox = turfBbox(L.polygon(this.getLatLngs()).toGeoJSON(13));
          const polygon = turfBboxPolygon(bbox);
          const geoJson = L.geoJson(polygon);
          const layers = geoJson.getLayers()[0];
          const latLngs = layers.getLatLngs();
          this.transform._rect.setLatLngs(latLngs);
        }
      } catch (e) {
        console.error("Error on drag: ", e);
      }
    });

    unitLayer.on("dragend", function () {
      if (isOnUnitLayer) {
        onAduCardClick(hoveredDesign);
      }
      if (this.transform) {
        this.transform.enable({rotation: !isReadOnly, scaling: false});
        updateDimensions(this);
        verifyAduPlacement(unitLayer, isSatelliteActive);
      }
    });
  }

  function setRotateHandlers(layer) {
    layer.on("rotate", (e) => {
      setIsRotatingADU(true);
      setHasRotated(true);
      verifyAduPlacement(e.layer, isSatelliteActive);
    });
    layer.on("rotateend", (e) => {
      setIsRotatingADU(false);
      verifyAduPlacement(e.layer, isSatelliteActive);
      updateDimensions(e.layer);
    });
  }

  function updateDimensions(layer) {
    if (!isDimensionActive && layer.hideMeasurements) {
      layer.hideMeasurements();
    } else if (layer.showMeasurements) {
      layer.showMeasurements({
        formatArea: () => null,
        imperial: true,
      });
      layer.updateMeasurements();
    }
  }

  const onAduCardClick = (design) => {
    setSelectedProduct(design);
  };

  const handleBackBtnClick = () => {
    resetAduLayer();
    resetBuildingsLayer();
    setSelectedProduct(null);
    setUserFeedback(null);
  };

  useEffect(() => {
    if (selectedProduct != null || foundUnit || feasibleZone == null) {
      return;
    }
    if (product != null) {
      placeAdu(product);
      onAduCardClick(product);

      setFoundUnit(true);
    } else if (
      fetchedConfigurationMap != null &&
      fetchedConfigurationMap.unit != null
    ) {
      let newProduct = products[fetchedConfigurationMap.unit.id];
      if (newProduct == null) {
        newProduct = fetchedConfigurationMap.unit;
      }
      placeAdu(newProduct);
      onAduCardClick(newProduct);

      setFoundUnit(true);
    } else if (
      fetchedConfiguration != null &&
      fetchedConfiguration.product != null
    ) {
      let newProduct = products[fetchedConfiguration.product.id];
      if (newProduct != null) {
        placeAdu(newProduct);
        onAduCardClick(newProduct);

        setFoundUnit(true);
      }
    }
  }, [
    product,
    feasibleZone,
    fetchedConfigurationMap,
    selectedProduct,
    foundUnit,
  ]);

  let aduList = null;
  if (!isReadOnly && !minimal) {
    aduList = (
      <ADUList
        isMobile={isMobileDevice}
        placeName={placeName}
        placeAdu={placeAdu}
        onGeocodeSubmit={onGeocodeSubmit}
        handleBackBtnClick={handleBackBtnClick}
        currentDesign={selectedProduct}
        onAduCardClick={onAduCardClick}
        map={map}
        handleProceed={handleProceed}
      />
    );
  }

  return (
    <div style={{width: "100%", height: "100%"}}>
      <MapViewContainer
        isListExpanded={false}
        isMobile={isMobileDevice}
        id={"real-map-container"}
      >
        <MapContainerWrapper isListExpanded={false}>
          <MapContainer
            style={{
              height: isMobileDevice && !fullScreen ? "280px" : "100%",
              width: "100%",
            }}
            center={[viewport.latitude, viewport.longitude]}
            zoom={20}
            zoomControl={false}
            scrollWheelZoom={!isStatic && fullScreen}
            id={"map-container"}
            whenCreated={(newMap) => {
              setMap(newMap);
            }}
            preferCanvas={isStatic}
          >
            {<ZoomControl position="bottomright" />}
          </MapContainer>
          {map && !isReadOnly && fullScreen && (
            <MapToolbar
              isMobile={isMobileDevice}
              isSatelliteActive={isSatelliteActive}
              isDimensionActive={isDimensionActive}
              isMeasurementActive={isMeasurementActive}
              handleTrashBtnClick={null}
              toggleSatelliteView={toggleSatelliteView}
              setIsMeasurementActive={() =>
                setIsMeasurementActive(!isMeasurementActive)
              }
              setIsDimensionActive={() =>
                setIsDimensionActive(!isDimensionActive)
              }
            />
          )}
          {selectedProduct && isDesktop && !isReadOnly && !minimal && (
            <button
              id="map__back-btn"
              className={"button-secondary"}
              onClick={handleBackBtnClick}
            >
              <ArrowLeft />
              Back
            </button>
          )}
          {userFeedback && !isMobileDevice && (
            <UserFeedback userFeedback={userFeedback} minimal={!fullScreen} />
          )}
          {fullScreen && <Disclaimer />}

          {isReadOnly && !minimal && (
            <button
              id="map__back-btn"
              className={"button-secondary"}
              onClick={handleImageDownload}
            >
              Download PNG
            </button>
          )}
        </MapContainerWrapper>
        {aduList}
      </MapViewContainer>
    </div>
  );
};

export default forwardRef(Map);
