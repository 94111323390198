import React from "react";
import Modal from "react-bootstrap/Modal";
import {saveConfiguration} from "../backend/ConfigurationAPI";

export const SaveChangesModal = (props) => {
	const {show, setShow, formik} = props;
	const handleClose = () => {
		setShow(false);
	};

	const saveChanges = () => {
		saveConfiguration(formik);
		setShow(false);
	};

	return (
		<div>
			<Modal show={show} onHide={handleClose} centered size="md">
				<Modal.Header closeButton style={{position: "relative"}}>
					<h4>Want to save your design?</h4>
				</Modal.Header>

				<Modal.Body>
					You have changes that have not been saved. Would you like to save them
					now?
				</Modal.Body>
				<Modal.Footer>
					<div
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "space-between",
						}}
					>
						<button
							className="button-secondary"
							onClick={() => {
								handleClose();
							}}
							style={{width: "45%"}}
						>
							Close
						</button>
						<button
							className="red-button-next"
							onClick={() => {
								saveChanges();
							}}
							style={{width: "45%"}}
						>
							Save Design
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
