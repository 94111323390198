import React, {useState} from "react";
import {MatrixHeader} from "./MatrixHeader";
import {MatrixTable} from "./MatrixTable";
import {DependenciesDataProps, DependenciesModal} from "./DependenciesModal";

export const MatrixTab = () => {
  const [matrixEdits, setMatrixEdits] = useState(null);
  const [matrixField, setMatrixField] = useState("description");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DependenciesDataProps | undefined>(
    undefined
  );
  const closePriceDependencies = () => {
    setData(undefined);
  };
  return (
    <>
      <MatrixHeader
        matrixEdits={matrixEdits}
        setMatrixEdits={setMatrixEdits}
        matrixField={matrixField}
        setMatrixField={setMatrixField}
        setLoading={setLoading}
      />
      <MatrixTable
        matrixEdits={matrixEdits}
        setMatrixEdits={setMatrixEdits}
        matrixField={matrixField}
        setData={setData}
        loading={loading}
      />
      <DependenciesModal
        data={data}
        show={data !== undefined}
        handleClose={closePriceDependencies}
      />
    </>
  );
};
