import {useDispatch, useSelector} from "react-redux";
import {editModifier} from "../../_shared/slices/CatalogSlice";
import {getModifierButton} from "./ModifierButtonFactory";
import {setShowEditingConfirmation} from "../slices/configurationSlice";
import {selectModifierAndValidate} from "../slices/ConfigurationSliceHelper";

export const Modifier = (props) => {
  const {modifierId, modifierGroup, modifier, isEditing, editingSelections} =
    props;

  const isSelected = useSelector(
    (state) => state.configuration.selectedModifiers[modifierId]
  );

  const dispatch = useDispatch();

  if (modifier == null) {
    return null;
  }

  let onClick = (e) => {
    e.preventDefault();
    if (editingSelections !== true) {
      dispatch(setShowEditingConfirmation(true));
    } else {
      selectModifierAndValidate(modifier, modifierGroup, false);
    }
  };

  if (isEditing) {
    onClick = (e) => {
      e.preventDefault();
      dispatch(
        editModifier(Object.assign({modifierGroup: modifierGroup}, modifier))
      );
      selectModifierAndValidate(modifier, modifierGroup, false);
    };
  }

  return getModifierButton(
    modifier,
    modifierGroup,
    onClick,
    isSelected,
    editingSelections
  );
};
