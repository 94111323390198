import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {TableContainer} from "../../../portal/views/ProposalView/styles/SiteWorkTableStyles";
import {FieldType, ITableColumnInfo} from "./TableTypes";
import {dollarsToMicros, getIntegerValue} from "@natomas/core";
import {priceTextFromMicros} from "../../../design-tool/logic/ConfigurationPageHelper";

const MainContainer = styled.div`
  margin-bottom: 0;
`;

const RowContainer = styled.div`
  height: 50px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 14px;
`;

const HeaderRowContainer = styled(RowContainer)`
  background-color: #82c275;
  font-family: "Maison-Bold", sans-serif;
`;

const ContentRowContainer = styled(RowContainer)`
  min-height: 70px;
  font-size: 16px;
  height: fit-content;
`;

const ColumnText = styled.div<{bold?: boolean}>`
  position: relative;
  margin: 20px;
  margin-left: 30px;
  font-family: ${(props) => (props.bold ? "Maison-Bold" : undefined)};
`;

const Column = styled.div`
  min-height: 100%;
  min-width: 160px;
  position: relative;
  display: flex;
  align-items: center;
`;

const GenericColumn = styled(Column)<{index: number; total: number}>`
  width: ${(props) => 100 / props.total + "%"};
  height: fit-content;
  border-left: ${(props) =>
    props.index > 0 ? "solid rgba(0, 0, 0, 0.1) 1px" : null};
`;

const TableRow = (props: {items: any[]}) => {
  const {items} = props;

  return (
    <>
      {items.map((item, index) => (
        <GenericColumn index={index} total={items.length}>
          <ColumnText bold={index === 0}>{item}</ColumnText>
        </GenericColumn>
      ))}
    </>
  );
};

// Take each row after the header and save the values to the header fields
const saveTable = (rows: any[], columns: ITableColumnInfo[], onChange: any) => {
  const dataToSave = rows.slice(1).map((row: any) => {
    const rowInfo: any = {};
    columns.forEach((columnInfo: ITableColumnInfo, index: number) => {
      let value = row[index].value;
      if (columnInfo.type === FieldType.NUMBER) {
        value = getIntegerValue(value);
      } else if (columnInfo.type === FieldType.PRICE) {
        value = getIntegerValue(value);
        value = dollarsToMicros(value);
      }
      rowInfo[columnInfo.dataField] = value;
    });
    return rowInfo;
  });

  onChange(dataToSave);
};

const isValidIndexOfArray = (arr: any[], index: number) => {
  return arr != null && arr.length > index;
};

const getTable = (
  rowCount: number,
  columns: ITableColumnInfo[],
  rigidValues: any[],
  data: any
) => {
  const table = [];
  table.push(
    columns.map((col: ITableColumnInfo) => {
      return {value: col.name, readOnly: true};
    })
  );
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const row: {value: string; readOnly?: boolean}[] = [];
    columns.forEach((col: ITableColumnInfo, columnIndex: number) => {
      if (
        isValidIndexOfArray(rigidValues, rowIndex) &&
        isValidIndexOfArray(rigidValues[rowIndex], columnIndex)
      ) {
        const rigidValue = rigidValues[rowIndex][columnIndex];
        row.push({value: rigidValue, readOnly: true});
      } else if (isValidIndexOfArray(data, rowIndex)) {
        const dataRow = data[rowIndex];
        let value = dataRow[col.dataField];
        if (col.type === FieldType.NUMBER) {
          value = `${value}`;
        } else if (col.type === FieldType.PRICE) {
          value = priceTextFromMicros(value, "accurate");
        }
        row.push({
          value: value,
        });
      } else {
        row.push({value: ""});
      }
    });

    table.push(row);
  }

  return table;
};

export const GenericTable = (props: any) => {
  const {currentValue, tableStructure, onChange} = props;
  const initialData = useMemo(() => {
    const {rowCount, columns, rigidValues} = tableStructure;
    return getTable(rowCount, columns, rigidValues, currentValue);
  }, [tableStructure, currentValue]);

  const {rowCount, columns, rigidValues} = tableStructure;
  const [data, setData] = useState(initialData);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  if (editing) {
    return (
      <MainContainer>
        <ReactDataSheet
          // @ts-ignore
          style={{width: "100%"}}
          data={data}
          valueRenderer={(cell: any) => cell.value}
          onCellsChanged={(changes) => {
            const grid: any = data.map((row) => [...row]);
            changes.forEach(({cell, row, col, value}) => {
              const previousValues = grid[row][col];
              grid[row][col] = {...previousValues, value};
            });
            setData(grid);
          }}
        />
        <br />
        <button
          className={"button-secondary"}
          onClick={() => {
            setEditing(false);
            if (currentValue) {
              setData(getTable(rowCount, columns, rigidValues, currentValue));
            } else {
              setData(initialData);
            }
          }}
          style={{marginRight: "20px"}}
        >
          Discard Edits
        </button>
        <button
          className={"button-primary bold "}
          onClick={() => {
            setEditing(false);
            saveTable(data, columns, onChange);
          }}
        >
          Confirm Values
        </button>
      </MainContainer>
    );
  }

  const rows = data.map((row: any, index) => {
    if (index === 0) {
      return (
        <HeaderRowContainer>
          <TableRow items={columns.map((columnData: any) => columnData.name)} />
        </HeaderRowContainer>
      );
    }

    const items = row.map((entry: any, index: number) => {
      return entry.value;
    });

    return (
      <ContentRowContainer key={"row-" + index}>
        <TableRow items={items} />
      </ContentRowContainer>
    );
  });

  return (
    <MainContainer>
      <TableContainer>{rows}</TableContainer>
      <br />
      <div style={{display: "flex"}}>
        <button
          className={"button-secondary"}
          onClick={() => {
            setEditing(true);
          }}
        >
          Edit Table
        </button>
      </div>
    </MainContainer>
  );
};
