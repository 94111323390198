import React, {useMemo, useState} from "react";
import {Title, SectionsWrapper} from "./styles/preDeposit";
import {useIntercom} from "react-use-intercom";
import {WatchVideoProps, HelpfulResourcesProps} from "./util/config";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {CalendlyEventTypes, Customer, Event, EventStatus} from "@natomas/core";
import {saveVideoEvent} from "./util/actions";
import "./assets/style.css";
import {getGreeting} from "./logic";
import {DepositSubmissionJourney} from "./components/DepositSubmissionJourney";
import {CalendlyEventListener, openPopupWidget} from "react-calendly";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {updateNatomasUserEvents} from "../../../../database/firebase/user";
import {StyleOption} from "../../../_shared/generics/_shared";
import ReactPlayer from "react-player";
import {WebinarModal} from "./components/WebinarModal";
import {HelpfulVideos} from "./components/HelpfulVideos";
import {HelpfulResources} from "./components/HelpfulResources";

export const PreDepositHomeView = () => {
  const fullWidth = useDynamicValue(fullColumnWidths);
  const {customer} = useCurrentCustomer();

  const [showVideoContent, setShowVideoContent] =
    useState<WatchVideoProps | null>(null);
  const [calendlySelected, setCalendlySelected] = useState("");
  const {show} = useIntercom();

  const launchCalendly = (url: string) => {
    const prefill = {
      email: Customer.getContactEmail(customer),
      firstName: Customer.getFirstName(customer),
      lastName: Customer.getLastName(customer),
      name: Customer.getFullName(customer),
    };

    openPopupWidget({url, prefill});
  };

  const scheduleAssessmentClickHandler = (link: string) => {
    setCalendlySelected(CalendlyEventTypes.ASSESSMENT);
    launchCalendly(link);
  };

  const bookShowingClickHandler = (link: string) => {
    setCalendlySelected(CalendlyEventTypes.SHOWING);
    launchCalendly(link);
  };
  const chatClickHandler = () => {
    show();
  };

  const onVideoComplete = () => {
    if (showVideoContent) {
      saveVideoEvent(
        customer.user_id,
        "complete",
        showVideoContent.videoUrl,
        showVideoContent.actionId,
        showVideoContent.region
      );
    }
  };

  const launchHelpfulResource = (item: HelpfulResourcesProps) => {
    const target = item.target;
    if (target === CalendlyEventTypes.ASSESSMENT) {
      scheduleAssessmentClickHandler(item.link);
    }
    if (target === CalendlyEventTypes.SHOWING) {
      bookShowingClickHandler(item.link);
    }
    if (target === "chat") {
      chatClickHandler();
    }
  };
  const launchVideo = (item: WatchVideoProps) => {
    setShowVideoContent(item);
  };

  const closeVideoModal = () => {
    setShowVideoContent(null);
  };

  const greeting = useMemo(() => {
    return getGreeting(customer);
  }, [customer]);

  const videoWidth = useDynamicValue({
    forFour: 90,
    forEight: 70,
    forTwelve: 50,
  });
  return (
    <>
      <PageContainer>
        <PageElement size={fullWidth} height={"auto"}>
          <Title>{greeting}</Title>
        </PageElement>
        <PageElement size={fullWidth} height={"auto"}>
          <DepositSubmissionJourney />
        </PageElement>
      </PageContainer>
      <PageContainer backgroundColor={"#E0CFB533"}>
        <SectionsWrapper>
          <HelpfulVideos launchVideo={launchVideo} fullWidth={fullWidth} />
          <HelpfulResources
            launchHelpfulResource={launchHelpfulResource}
            fullWidth={fullWidth}
          />
        </SectionsWrapper>
      </PageContainer>
      <WebinarModal
        show={showVideoContent}
        handleClose={closeVideoModal}
        header={showVideoContent?.title}
        description={showVideoContent?.msg}
        modalIsOpen={showVideoContent}
        videoWidth={videoWidth}
        content={
          <ReactPlayer
            url={showVideoContent?.videoUrl}
            controls={true}
            config={{
              youtube: {
                playerVars: {modestbranding: 1},
              },
            }}
            width={videoWidth + "vw"}
            height={videoWidth * 0.563 + "vw"}
          />
        }
        footerButtons={[
          {label: "Close", trackingId: "close-webinar"},
          {
            label: "Complete",
            trackingId: "complete-webinar",
            option: StyleOption.ColorWillDarken,
            action: () => {
              onVideoComplete();
              closeVideoModal();
            },
          },
        ]}
      />
      <CalendlyEventListener
        onEventScheduled={(e) => {
          const newEvent = Event.createCalendlyEvent(
            calendlySelected,
            -1, // TODO get the actual event date, this NEEDS to change
            e.data.payload.event.uri,
            "",
            EventStatus.SCHEDULED
          );
          const newCustomer = Customer.addEvent(customer, newEvent);
          return updateNatomasUserEvents(customer.user_id, newCustomer, null);
        }}
      />
    </>
  );
};
