import React, {Dispatch, SetStateAction} from "react";
import {DependenciesDataProps} from "./DependenciesModal";
import {NatButton} from "../../../../_shared/generics/button";
import {
  StyleOption,
  TextSize,
  IconPosition,
} from "../../../../_shared/generics/_shared";
import {AiOutlinePlus} from "react-icons/all";
import {sortProducts} from "../../../../design-tool/logic/ProductGroupUtils";
import {
  initialAndEditedStatesMatch,
  priceMicrosToMatrix,
  productGroupLevelKey,
  transposeTable,
} from "./utilities";

const getDependencyButton = (
  buttonLabel: string,
  modifierId: string,
  cellKey: string,
  value: any,
  modifier: any,
  groupModifiers: any,
  setData: any,
  setMatrixEdits: any,
  showIcon: boolean,
  hasDependencies: boolean,
  productTitle: string | null
): JSX.Element => {
  return (
    <NatButton
      label={buttonLabel}
      trackingId={"admin-matrix-changing-modifier-price-dependencies"}
      type={"button"}
      option={
        hasDependencies ? StyleOption.PRIMARY_COLOR : StyleOption.SECONDARY
      }
      size={TextSize.SMALL}
      icon={
        showIcon
          ? {icon: <AiOutlinePlus />, iconPosition: IconPosition.LEFT}
          : undefined
      }
      clickEvent={setData}
      clickEventParam={{
        productTitle: productTitle ?? "Default",
        modifierTitle: modifier?.title ?? groupModifiers[modifierId].title,
        setEdit: setMatrixEdits,
        type: "prices",
        field: "price-dependencies",
        id: cellKey,
        info: value ?? [],
      }}
    />
  );
};

const hasTableRequirements = (
  products: any,
  productModifiers: any,
  groupModifiers: any
) => {
  return !(
    products.length === 0 ||
    productModifiers == null ||
    groupModifiers == null ||
    Object.keys(productModifiers).length === 0 ||
    Object.keys(groupModifiers).length === 0
  );
};

const getModifierIds = (groupModifiers: any) => {
  return Object.values(groupModifiers)
    .sort((a: any, b: any) => {
      return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
    })
    .map((e: any) => e.id);
};

const getMatrixEditValue = (
  edits: any,
  cellKey: string,
  matrixField: string
) => {
  return edits[cellKey] != null ? edits[cellKey][matrixField] : null;
};

const getCellValue = (
  matrixField: string,
  matrixEditValue: any,
  modifier: any,
  modifierId: string,
  cellKey: string,
  groupModifiers: any,
  setData: any,
  setMatrixEdits: any,
  productTitle: string | null
) => {
  if (matrixField === "prices") {
    return matrixEditValue != null
      ? matrixEditValue
      : priceMicrosToMatrix(
          productTitle == null
            ? modifier?.defaultPriceMicros
            : modifier?.priceMicros
        );
  }
  if (matrixField === "description" && matrixEditValue == null) {
    return productTitle == null
      ? modifier?.displayDescriptionDefault
      : modifier?.displayDescription;
  }
  if (matrixField === "price-dependencies") {
    const valueKey = productTitle == null ? "defaultPrices" : "prices";
    let value = [];
    let buttonLabel = "";
    let showIcon = true;
    let hasDependencies = modifier?.[valueKey]?.length > 0;
    let editDependencies = matrixEditValue;
    if (hasDependencies || editDependencies) {
      if (hasDependencies && !editDependencies) {
        value = modifier?.[valueKey];
      }
      if (editDependencies) {
        value = matrixEditValue;
      }
      if (value?.length > 0) {
        buttonLabel = "Yes (" + value?.length + ")";
        showIcon = false;
      }
    }
    return getDependencyButton(
      buttonLabel,
      modifierId,
      cellKey,
      value,
      modifier,
      groupModifiers,
      setData,
      setMatrixEdits,
      showIcon,
      value?.length > 0,
      productTitle
    );
  }
  return matrixEditValue;
};

const getCellClass = (
  matrixField: string,
  matrixEditValue: any,
  modifier: any,
  defaultValue: boolean
) => {
  let className = "";
  if (matrixField === "prices") {
    className += " centered-cell ";
    if (matrixEditValue == null) {
      let value = defaultValue ? "defaultPriceMicros" : "priceMicros";
      if (modifier?.[value]) {
        if (modifier?.[value] > 0) {
          className += "positive-cell";
        } else {
          className += "negative-cell";
        }
      }
    } else return className + "edited-cell";
  } else if (matrixField === "description") {
    if (matrixEditValue != null) {
      return className + "edited-cell width-200";
    }
  } else if (matrixField === "price-dependencies") {
    if (defaultValue) className += " centered-cell ";
    let value = defaultValue ? "defaultPrices" : "prices";
    if (
      matrixEditValue != null &&
      !initialAndEditedStatesMatch(modifier?.[value], matrixEditValue)
    ) {
      return className + "edited-cell ";
    }
  }
  return className;
};

export const getTable = (
  products: any,
  productModifiers: any,
  groupModifiers: any,
  matrixEdits: any,
  setMatrixEdits: any,
  options: {
    matrixField: string;
    transpose: boolean;
    setData: Dispatch<SetStateAction<DependenciesDataProps | undefined>>;
  }
) => {
  const {matrixField, setData, transpose} = options;
  let edits = matrixEdits ?? {};
  let table: any = [];
  if (!hasTableRequirements(products, productModifiers, groupModifiers))
    return table;

  const modifierIds = getModifierIds(groupModifiers);

  const sizeClassName =
    matrixField === "description"
      ? " width-200 centered-cell"
      : " centered-cell";
  const additionalStyling =
    matrixField === "description" ? " top-left-align" : "";

  const globalModifierTitles = [
    {value: "", readOnly: true, className: "horizontal-sticky" + sizeClassName},
  ];
  const globalModifierValues = [
    {
      value: "Default",
      className: sizeClassName,
      readOnly: true,
      key: "Default Key",
    },
  ];
  modifierIds.forEach((modifierId) => {
    const cellKey = productGroupLevelKey + "_" + modifierId;
    const modifier = groupModifiers[modifierId];
    const matrixEditValue = getMatrixEditValue(edits, cellKey, matrixField);
    let value: any;
    let className: string;
    let defaultValue = true;
    value = getCellValue(
      matrixField,
      matrixEditValue,
      modifier,
      modifierId,
      cellKey,
      groupModifiers,
      setData,
      setMatrixEdits,
      null
    );
    className = getCellClass(
      matrixField,
      matrixEditValue,
      modifier,
      defaultValue
    );

    globalModifierTitles.push({
      value: modifier.title,
      readOnly: true,
      className: "horizontal-sticky centered-cell",
    });
    globalModifierValues.push({
      key: cellKey,
      value: value,
      className: className + additionalStyling,
      readOnly: false,
    });
  });
  table.push(globalModifierTitles);
  table.push(globalModifierValues);

  const sortedProducts = sortProducts(products);
  const productRows = sortedProducts.map((product: any) => {
    const specificProductModifiers = productModifiers[product.id];
    const productRow = [
      {value: product.title, readOnly: true, className: sizeClassName},
    ];

    const productModifiersRow = modifierIds.map((modifierId) => {
      const cellKey = product.id + "_" + modifierId;
      let value;
      let className;
      let defaultValue = false;
      const modifier =
        specificProductModifiers != null
          ? specificProductModifiers[modifierId]
          : null;

      const matrixEditValue =
        edits[cellKey] != null ? edits[cellKey][matrixField] : null;

      value = getCellValue(
        matrixField,
        matrixEditValue,
        modifier,
        modifierId,
        cellKey,
        groupModifiers,
        setData,
        setMatrixEdits,
        product.title
      );
      className = getCellClass(
        matrixField,
        matrixEditValue,
        modifier,
        defaultValue
      );

      return {
        value: value,
        key: cellKey,
        className: className + sizeClassName + additionalStyling,
      };
    });

    return [...productRow, ...productModifiersRow];
  });

  table = [...table, ...productRows];

  if (transpose) {
    table = transposeTable(table);
  }
  return table;
};
