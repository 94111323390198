import {push as Menu} from "react-burger-menu";
import {isMobile} from "../../../_shared/navigation";
import {
  SIDEBAR_CONTAINER_Z_INDEX,
  SIDEBAR_MOBILE_ICON_Z_INDEX,
  SIDEBAR_NAV_Z_INDEX,
} from "../../../_shared/styles";

var styles = {
  bmBurgerButton: {
    zIndex: SIDEBAR_MOBILE_ICON_Z_INDEX,
    position: "fixed",
    width: "2rem",
    height: "2rem",
    left: "0.5rem",
    top: "0.5rem",
    margin: "0.5rem",
    backgroundColor: "white",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    zIndex: SIDEBAR_NAV_Z_INDEX,
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItem: {
    display: "flex",
  },
  bmOverlay: {
    zIndex: SIDEBAR_CONTAINER_Z_INDEX,
    background: "rgba(0, 0, 0, 0.6)",
  },
};

export const MobileMenu = (props) => {
  const {items, isOpen, onOpen, onClose} = props;

  if (isMobile() === false) {
    return null;
  }

  return (
    <Menu
      pageWrapId={"main-content-wrapper"}
      styles={styles}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      {items}
    </Menu>
  );
};
