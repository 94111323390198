import styled from "styled-components";
import {NatomasButton} from "../button/styles";

export const HeaderContent = styled.div`
   {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HeaderTitle = styled.h1<{lineHeight: string}>`
  margin: 0 1rem;
  font-size: 1.25rem;
  line-height: ${(props) => props.lineHeight};
  text-align: center;
  font-family: Maison-Medium, sans-serif;
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  ${NatomasButton} {
    margin-left: 0.5rem;
  }
`;

export const LogoContainer = styled.img`
   {
    cursor: pointer;
    height: 1.5rem;
  }
`;
