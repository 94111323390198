import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../../_shared/slices/types/Store";
import {IImageDetails, ImageCategory, ImageStatus} from "@natomas/core";
//Shared
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {NatButton} from "../../../_shared/generics/button";
import {IconPosition} from "../../../_shared/generics/_shared";
import {StyleOption, TextSize} from "../../../_shared/generics/_shared";
import {SmartImageWithId} from "../../../design-tool/components/SmartImage";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {fetchImage} from "../../../design-tool/logic/data";
import {PhotoCarousel} from "./components/PhotoCarousel";
import {MobileStickyBottom} from "../../../portal/_shared/components/MobileStickyBottom";
//Hooks
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {usePage} from "../../../_shared/hooks/usePage";
import {updateUserProductSelection} from "../../../../database/firebase/api/user";
import {isMobile, toDesignStudio, toPortal} from "../../../_shared/navigation";
//Styles
import {
  IncludedTag,
  PriceContainerSticky,
  PriceModel,
  PriceModelContainer,
  ProductDescriptionContainer,
  ProductDetails,
  ProductDetailsWrapper,
  ProductHighlight,
  ProductImageContainer,
  ProductInfo,
  ProductInfoContainer,
  ProductInfoImage,
  ProductInfoTitle,
  ProductModel,
  StickyCard,
  StickyCardRow,
  StickyCardRows,
  StickyMap,
} from "./styles";
//Icons
import {AiFillCheckCircle, AiOutlineStar as Star} from "react-icons/ai";
import {BiCarousel} from "react-icons/bi";
import {FaBath, FaRulerCombined} from "react-icons/fa";
import {IoBedSharp} from "react-icons/io5";
import {IoMdResize} from "react-icons/io";
//Dev
import {devLogger} from "../../../../.developerSettings";
import {
  getLengthAndWidthString,
  getSqFtString,
  getUnitPriceInMicros,
} from "../../../design-tool/logic/ProductUtils";
import {getImageDetailsByCategory} from "../../_shared/utilities/images";
import {JourneyStepName, useTracking} from "../../../_shared/hooks/useTracking";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {PricingMode} from "../../../_shared/slices/GlobalSlice";
import {MappingLayout} from "../../../mapping/views/MappingLayout";
import {MAPPING_Z_INDEX} from "../../../_shared/styles";
import {
  HOME_PAGE_ID,
  OVERVIEW_ASSESSMENT_PAGE_ID,
  toPage,
} from "../../../portal/_shared/navigation/PageNavigation";

export const ProductView = (props: {product: any}) => {
  const {product} = props;
  const {customer} = useCurrentCustomer();
  const images = useSelector((state: IStore) => state.global.images);
  const {currentConfigurationId} = useCurrentProject();
  const {columnCount} = usePage();
  const {isCustomerPreDeposit} = useCurrentCustomer();
  const {PRICING_MODE, SHOW_MAPPING_PREVIEW} = useGlobalConfiguration();
  const {markJourneyEvent} = useTracking();
  const fullSize = useDynamicValue(fullColumnWidths);
  const right4 = useDynamicValue({
    forFour: 0,
    forEight: 2,
    forTwelve: 4,
  });
  const left8 = useDynamicValue({
    forFour: 4,
    forEight: 6,
    forTwelve: 8,
  });
  const leftSideSize = useDynamicValue({
    forFour: 4,
    forEight: 5,
    forTwelve: 8,
  });
  const imageHeight = useDynamicValue({
    forFour: "18rem",
    forEight: "18rem",
    forTwelve: "24rem",
  });

  const productImages = useMemo(() => {
    return product?.images?.filter((imageDetails: IImageDetails) => {
      // Only get listed images
      if (imageDetails.status !== ImageStatus.UNLISTED) {
        // Make sure image is fetched
        fetchImage(imageDetails.imageId, false).then((r) => {
          if (r !== undefined) devLogger("fetched new image");
        });
        return imageDetails;
      }
    });
  }, [product, images]);

  const carouselImages = useMemo(() => {
    let carouselProductImages =
      productImages
        ?.filter((imageDetails: IImageDetails) => images[imageDetails.imageId])
        .map((imageDetails: IImageDetails) => {
          return {
            imageId: imageDetails.imageId,
            url: images[imageDetails.imageId].original.url,
            title: imageDetails.description,
          };
        }) ?? [];

    // Add default image to carousel
    if (images[product?.imageId] && product?.title) {
      return [
        {
          url: images[product?.imageId].original.url,
          title: product.title,
          imageId: product?.imageId,
        },
        ...carouselProductImages,
      ];
    } else {
      return carouselProductImages;
    }
  }, [productImages]);

  const [showPhotos, setShowPhotos] = useState(false);
  const [selectedPhotoID, setSelectedPhotoID] = useState<string | null>(null);

  const showPhotoCarousel = (selectedPhotoURL: string | null) => {
    setSelectedPhotoID(selectedPhotoURL);
    setShowPhotos(true);
  };

  const hidePhotoCarousel = () => {
    setSelectedPhotoID(null);
    setShowPhotos(false);
  };

  const getPriceString = (price: number) => {
    const dollars = price / 100;
    return "$" + dollars.toLocaleString("en-US");
  };

  const isIncludedElement = () => {
    return (
      <IncludedTag>
        <AiFillCheckCircle /> Included
      </IncludedTag>
    );
  };

  const saveProductToProject = () => {
    if (currentConfigurationId) {
      toDesignStudio();
      updateUserProductSelection(currentConfigurationId, product).then(() => {
        markJourneyEvent(JourneyStepName.UNIT_SELECTION_COMPLETED);
      });
    }
  };
  const saveProductToProjectNoDesign = () => {
    if (currentConfigurationId) {
      toPortal();
      if (isCustomerPreDeposit) {
        toPage(OVERVIEW_ASSESSMENT_PAGE_ID);
      } else {
        toPage(HOME_PAGE_ID);
      }

      updateUserProductSelection(currentConfigurationId, product).then(() => {
        markJourneyEvent(JourneyStepName.UNIT_SELECTION_COMPLETED);
      });
    }
  };

  const selectButton = () => {
    const label = "Select this home";
    return (
      <NatButton
        label={label}
        type={"button"}
        trackingId={"save-product-to-project"}
        clickEvent={saveProductToProjectNoDesign}
        option={StyleOption.WhiteWillFillBlack}
        spinnerEnabled={true}
        disabled={!customer}
        style={{marginTop: "10px"}}
      />
    );
  };

  const saveButton = () => {
    return (
      <NatButton
        label={"Design this home"}
        type={"button"}
        trackingId={"save-product-to-project"}
        clickEvent={saveProductToProject}
        option={StyleOption.ColorWillDarken}
        spinnerEnabled={true}
        disabled={!customer}
      />
    );
  };

  const productImageHeight = useDynamicValue({
    forFour: "30vh",
    forEight: "24rem",
    forTwelve: "24rem",
  });

  const getImageContainer = (imageId: string, category: ImageCategory) => {
    return (
      <ProductInfoImage height={imageHeight} key={imageId}>
        <ProductImageContainer
          onClick={() => {
            showPhotoCarousel(imageId);
          }}
        >
          <SmartImageWithId
            style={{
              objectFit:
                category === ImageCategory.FLOOR_PLAN ? "contain" : "cover",
            }}
            imageId={imageId}
          />
        </ProductImageContainer>
      </ProductInfoImage>
    );
  };

  const getImagesByCategory = (category: ImageCategory) => {
    return getImageDetailsByCategory(product, category).map(
      (imageDetails: IImageDetails) => {
        return getImageContainer(imageDetails.imageId, category);
      }
    );
  };

  const hasImagesInCategory = (category: ImageCategory) => {
    return getImageDetailsByCategory(product, category).length > 0;
  };

  const floorPlanImages = getImagesByCategory(ImageCategory.FLOOR_PLAN);
  const heroShots = getImageDetailsByCategory(product, ImageCategory.HERO);
  const shouldShowRightGrid = right4 > 0 && heroShots?.length >= 2;

  if (product == null) {
    return null;
  }

  const seeAllPhotosButton = (
    <NatButton
      label={"See all photos"}
      type={"button"}
      trackingId={"see-all-photos"}
      clickEvent={() => {
        showPhotoCarousel(null);
      }}
      option={StyleOption.WhiteWillFillBlack}
      size={TextSize.SMALL}
      icon={{
        icon: <BiCarousel />,
        iconPosition: IconPosition.LEFT,
      }}
    />
  );

  const getPricing = (product: any) => {
    if (PRICING_MODE === PricingMode.UNIT)
      return getPriceString(getUnitPriceInMicros(product));
    else return getPriceString(product?.priceMicros);
  };

  const whatsIncludedSection = () => {
    if (PRICING_MODE !== PricingMode.UNIT) {
      return (
        <>
          <StickyCardRows>
            <StickyCardRow>Permits & plans {isIncludedElement()}</StickyCardRow>
            <StickyCardRow>
              Site prep & foundation {isIncludedElement()}
            </StickyCardRow>
            <StickyCardRow>Delivery {isIncludedElement()}</StickyCardRow>
            <StickyCardRow>
              Interior & exterior finishing {isIncludedElement()}
            </StickyCardRow>
            <StickyCardRow>Utility hookups {isIncludedElement()}</StickyCardRow>
          </StickyCardRows>
        </>
      );
    } else
      return (
        <StickyCardRows>
          <StickyCardRow>
            Appliances included {isIncludedElement()}
          </StickyCardRow>
          <StickyCardRow>
            Energy efficient construction {isIncludedElement()}
          </StickyCardRow>
          <StickyCardRow>Extended warranty {isIncludedElement()}</StickyCardRow>
          <StickyCardRow>Solar-ready roof {isIncludedElement()}</StickyCardRow>
        </StickyCardRows>
      );
  };

  const mainHeroShotWidth = !shouldShowRightGrid ? fullSize : left8;

  return (
    <>
      <PageContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <PageElement size={mainHeroShotWidth} height={productImageHeight}>
            <ProductImageContainer
              onClick={() => {
                showPhotoCarousel(product?.imageId);
              }}
            >
              <SmartImageWithId imageId={product?.imageId} />
              {!shouldShowRightGrid && seeAllPhotosButton}
            </ProductImageContainer>
          </PageElement>
          {shouldShowRightGrid && (
            <PageElement
              size={right4}
              height={"24rem"}
              styles={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ProductImageContainer
                onClick={() => {
                  showPhotoCarousel(heroShots[0].imageId);
                }}
              >
                <SmartImageWithId imageId={heroShots[0].imageId} />
              </ProductImageContainer>
              <ProductImageContainer
                onClick={() => {
                  showPhotoCarousel(heroShots[1].imageId);
                }}
              >
                <SmartImageWithId imageId={heroShots[1].imageId} />
                {seeAllPhotosButton}
              </ProductImageContainer>
            </PageElement>
          )}
        </div>

        <ProductDescriptionContainer mobile={columnCount === 4}>
          <PageElement size={leftSideSize} height={"auto"}>
            <ProductInfoContainer>
              <ProductModel>{product?.title}</ProductModel>
              <ProductDetailsWrapper>
                <ProductDetails>
                  <IoBedSharp /> {product?.productDetails?.bedrooms} bed
                </ProductDetails>
                <ProductDetails>
                  <FaBath /> {product?.productDetails?.bathrooms} bath
                </ProductDetails>
                <ProductDetails>
                  <IoMdResize /> {getSqFtString(product)}
                </ProductDetails>
                <ProductDetails>
                  <FaRulerCombined /> {getLengthAndWidthString(product)}
                </ProductDetails>
              </ProductDetailsWrapper>
            </ProductInfoContainer>
            {product?.highlights && (
              <ProductInfoContainer>
                <ProductInfo>
                  {product?.highlights.map((highlight: string) => {
                    return (
                      <ProductHighlight>
                        <Star /> {" " + highlight}
                      </ProductHighlight>
                    );
                  })}
                </ProductInfo>
              </ProductInfoContainer>
            )}
            <ProductInfoContainer>
              <ProductInfoTitle>
                {product?.tagline ?? product?.title}
              </ProductInfoTitle>
              <ProductInfo>
                <div>{product?.displayDescription}</div>
              </ProductInfo>
              {floorPlanImages.length > 0 && floorPlanImages[0]}
              {SHOW_MAPPING_PREVIEW && columnCount === 4 && !isMobile() && (
                <div style={{zIndex: MAPPING_Z_INDEX, height: "380px"}}>
                  {/*// @ts-ignore*/}
                  <MappingLayout minimal={true} />
                </div>
              )}
            </ProductInfoContainer>
            {(product?.exteriorDescription ||
              hasImagesInCategory(ImageCategory.EXTERIOR)) && (
              <ProductInfoContainer>
                <ProductInfoTitle>Exterior Features</ProductInfoTitle>
                <ProductInfo>{product?.exteriorDescription}</ProductInfo>
                {getImagesByCategory(ImageCategory.EXTERIOR)}
              </ProductInfoContainer>
            )}
            {(product?.interiorDescription ||
              hasImagesInCategory(ImageCategory.INTERIOR)) && (
              <ProductInfoContainer>
                <ProductInfoTitle>Interior Features</ProductInfoTitle>
                <ProductInfo>{product?.interiorDescription}</ProductInfo>
                {getImagesByCategory(ImageCategory.INTERIOR)}
              </ProductInfoContainer>
            )}
            {hasImagesInCategory(ImageCategory.FLOOR_PLAN) && (
              <ProductInfoContainer>
                <ProductInfoTitle>Floor Plan</ProductInfoTitle>
                {floorPlanImages}
              </ProductInfoContainer>
            )}
          </PageElement>
          {columnCount !== 4 && (
            <PriceContainerSticky>
              <StickyCard>
                <PriceModelContainer>
                  {"From "}
                  <PriceModel>{getPricing(product)}</PriceModel>
                </PriceModelContainer>
                {whatsIncludedSection()}
                {saveButton()}
                {selectButton()}
              </StickyCard>
              {SHOW_MAPPING_PREVIEW && (
                <StickyMap>
                  {/*// @ts-ignore*/}
                  <MappingLayout minimal={true} />
                </StickyMap>
              )}
            </PriceContainerSticky>
          )}
        </ProductDescriptionContainer>
      </PageContainer>
      {columnCount === 4 && (
        <MobileStickyBottom
          callToActionButton={saveButton()}
          descriptionText={getPricing(product)}
          descriptionPrefixText={"From"}
        />
      )}
      <PhotoCarousel
        selectedPhotoID={selectedPhotoID}
        show={showPhotos}
        close={hidePhotoCarousel}
        images={carouselImages}
      />
    </>
  );
};
