export const ConfigurationPageStructure = {
	title: {
		name: "Title",
		index: 0,
		type: "input",
	},
	internal_title: {
		name: "Internal Title",
		index: 2,
		type: "input",
	},
	heading: {
		name: "Heading",
		index: 3,
		type: "input",
	},
	displayDescription: {
		name: "Display Description",
		index: 4,
		type: "textarea",
	},
	modifierGroups: {
		name: "Modifier Groups",
		defaultValue: [],
		hiddenInput: true,
		index: 5,
	},
	modifierGroupOverrides: {
		name: "Modifier Groups",
		defaultValue: {},
		hiddenInput: true,
		index: 7,
	},
	sections: {
		name: "Sections",
		defaultValue: {},
		hiddenInput: true,
		index: 6,
	},
};

export const SectionStructure = {
	title: {
		name: "Title",
		index: 0,
		type: "input",
	},
	index: {
		name: "Internal Title",
		index: 1,
		defaultValue: "General",
		type: "number",
	},
	items: {
		name: "Items",
		index: 2,
		defaultValue: [],
	},
};
