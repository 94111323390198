import styled from "styled-components";

export const Title = styled.h1`
  font-size: 1.5rem;
  font-family: Maison-Bold, sans-serif;
  padding: 2rem 0;
  border-bottom: #e0e0e0 solid 1px;
`;

export const Description = styled.h1`
  font-size: 1rem;
  padding: 2rem 0;
  border-bottom: #e0e0e0 solid 1px;
`;

export const TwoColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

export const AduImage = styled.div`
   {
    height: 32rem;
    width: 100%;
    position: relative;
    cursor: pointer;
    .fullDiv {
      border-radius: 1rem;
    }
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4rem;
  width: 24rem;
`;

export const HelperText = styled.div<{
  size: string;
  bold?: boolean;
  align?: string;
}>`
  font-family: ${(props) =>
    props.bold ? "Maison-Bold, sans-serif" : "Maison-Book, sans-serif"};
  text-align: ${(props) => props.align};
  font-size: ${(props) => props.size};
  color: gray;
  margin-bottom: 0.85rem;
`;

export const DesignBlurb = styled.div`
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: 100%;
  }
`;

export const ChangeModelButton = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
`;

export const NoProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 34rem;
`;

export const WaitingGraphic = styled.img`
  height: 36rem;
`;
