import styled from "styled-components";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";

export const ProductFlexList = styled.div<{removeFrequency: number}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  ${StandardElement} {
    margin-bottom: 1rem;
    &:nth-child(${(props) => props.removeFrequency}n) {
      margin-right: 0;
    }
  }
`;
