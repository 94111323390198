import React, {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
//Shared
import {LoadingPanelSpinner} from "../loading-panel/styles";
//Local
import {TextContainer, NatomasButton} from "./styles";
import {
  StyleOption,
  TextSize,
  getHoverBackgroundColor,
  getHoverTextColor,
  getHoverTextDecoration,
  getHoverBorderColor,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextSize,
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getTextContent,
  IconProps,
} from "../_shared";
// Icons
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {isBlankString} from "@natomas/core";

export interface IButton {
  label: string;
  type: "button" | "submit" | "reset" | undefined;
  trackingId: string;
  icon?: IconProps | undefined;
  size?: TextSize;
  fontFamily?: string;
  clickEvent?: any;
  clickEventParam?: any;
  disabled?: boolean;
  option?: StyleOption;
  style?: any;
  spinnerEnabled?: boolean;
  hidden?: any;
  id?: string;
  tooltip?: any;
}

const NatButton = (props: IButton): JSX.Element => {
  const {
    option,
    clickEvent,
    clickEventParam,
    label,
    type,
    size,
    disabled,
    style,
    icon,
    fontFamily,
    spinnerEnabled,
    hidden,
    trackingId,
    id,
    tooltip,
  } = props;

  const [spin, setSpin] = useState<boolean>(false);

  useEffect(() => {
    if (spin) setTimeout(() => runClickEvent(), 250);
  }, [spin]);

  useEffect(() => {
    if (!spinnerEnabled) {
      setSpin(false);
    }
  }, [spinnerEnabled]);

  const runClickEvent = () => {
    if (clickEventParam) clickEvent(clickEventParam);
    else if (clickEvent) clickEvent();
  };

  const attemptClickEvent = () => {
    if (spinnerEnabled) {
      setSpin(true);
    } else {
      runClickEvent();
    }
  };

  return (
    <NatomasButton
      fontFamily={fontFamily}
      initialBackgroundColor={getInitialBackgroundColor(option)}
      initialTextColor={getInitialTextColor(option)}
      initialTextDecoration={getInitialTextDecoration(option)}
      initialBorderColor={getInitialBorderColor(option)}
      hoverBackgroundColor={getHoverBackgroundColor(option)}
      hoverTextColor={getHoverTextColor(option)}
      hoverTextDecoration={getHoverTextDecoration(option)}
      hoverBorderColor={getHoverBorderColor(option)}
      enabledBackgroundColor={getEnabledBackgroundColor(option)}
      enabledBorderColor={getEnabledBorderColor(option)}
      textSize={getTextSize(size)}
      onClick={attemptClickEvent}
      type={type}
      disabled={disabled || (spinnerEnabled && spin && type !== "submit")}
      style={style}
      id={id}
      hidden={hidden}
      name={trackingId}
      data-tip
      data-for={"tooltip-" + id}
    >
      <TextContainer
        fontSize={getTextSize(size)}
        iconPosition={icon?.iconPosition}
        id={id}
        hasLabel={!isBlankString(label)}
      >
        {spinnerEnabled && spin && (
          <LoadingPanelSpinner size={getTextSize(size)}>
            <AiOutlineLoading3Quarters />
          </LoadingPanelSpinner>
        )}
        {!spin && getTextContent(icon, label)}
      </TextContainer>
      {tooltip ? (
        <ReactTooltip
          id={"tooltip-" + id}
          effect="solid"
          place={"bottom"}
          multiline={true}
        >
          {tooltip}
        </ReactTooltip>
      ) : null}
    </NatomasButton>
  );
};

export {NatButton};
