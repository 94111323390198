import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import React, {useEffect, useState} from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {isAdmin} from "../../../../_shared/user/UserUtils";
import {useSelector} from "react-redux";
import {
  Configuration,
  firestore,
  Sitework,
  Utilities,
} from "../../../../../database/firebase";
import waiting_graphic from "../../../../../assets/images/graphics/waiting_graphic.png";
import laptop from "../../../../../assets/images/graphics/laptop.png";
import {useConfigurationLocation} from "../../../_shared/hooks/useConfigurationLocation";
import {FiEye, FiEyeOff} from "react-icons/fi";
import {
  ErrorDiv,
  FinalRowContainer,
  HeaderRowContainer,
  MainContainer,
  Opaque,
  RowContainer,
  TableContainer,
} from "../styles/SiteWorkTableStyles";
import {TableRow} from "./SiteWorkTableRow";
import {
  cleanCurrencyString,
  currencyStringFromMicros,
  isBlankString,
} from "@natomas/core";
import {dollarsToMicros} from "@natomas/core";
import {getSortedValuesWithKeyAsId} from "@natomas/core";
import {SITE_WORK_LINE_ITEM_IDS} from "../../../../_shared/constants/sitework";
import {CONFIGURATION_SITE_DB_KEY} from "../../../../../database/firebase/configuration/configuration";
const PHASE_1_LINE_ITEM_ID = "phase1";
const getIntValue = (entry) => {
  if (isBlankString(entry)) {
    return 0;
  }
  const strippedText = entry.replace(/\$|,/g, "");
  const parsedFloat = parseFloat(strippedText);
  if (isNaN(parsedFloat)) {
    return 0;
  }
  return Math.round(parsedFloat * 100) / 100;
};

const getTotalBreakdown = (data) => {
  let totalSiteWorkCostInMicros = 0;
  let errors = [];
  data.forEach((row, index) => {
    const [description, total, notes] = row;
    if (total.readOnly !== true) {
      let totalValue = getIntValue(total.value);
      // if (baseValue + siteSpecificValue !== totalValue) {
      //   let title = isBlankString(description.value)
      //     ? "" + index
      //     : `${index}: '${description.value}'`;
      //   errors.push("Inconsistent/Incorrect pricing for row " + title);
      // }
      totalSiteWorkCostInMicros += dollarsToMicros(totalValue);
    }
  });
  return {
    totalSiteWorkCostInMicros,
    errors,
  };
};

const getTable = (rowCount, columnCount, data) => {
  const lineItems = getSortedValuesWithKeyAsId(SITE_WORK_LINE_ITEM_IDS).filter(
    (row) => {
      return row.id !== PHASE_1_LINE_ITEM_ID;
    }
  );
  const table = [];
  table.push([
    {value: "Item", readOnly: true},
    {value: "Your Total", readOnly: true},
    {value: "Notes & Comments", readOnly: true},
  ]);

  for (let i = 0; i < lineItems.length; i++) {
    const row = [];
    if (data != null && data.length > i) {
      const dataRow = data[i];
      if (dataRow.lineItemId == null) {
        const lineItem = lineItems[i];
        row.push({value: lineItem.title, readOnly: true, key: lineItem.id});
      } else {
        row.push({
          value: dataRow.title,
          readOnly: true,
          key: dataRow.lineItemId,
        });
      }
      // row.push({value: dataRow.basePrice ?? ""});
      // row.push({value: dataRow.siteSpecificPrice ?? ""});
      row.push({
        value:
          dataRow.totalPrice ??
          priceTextFromMicros(dataRow.totalPriceInMicros, "accurate"),
      });
      row.push({value: dataRow.note ?? ""});
    } else {
      for (let col = 0; col < columnCount; col++) {
        if (col === 0) {
          const lineItemId = lineItems[i];
          row.push({
            value: lineItemId.title,
            readOnly: true,
            key: lineItemId.id,
          });
        } else {
          row.push({value: ""});
        }
      }
    }

    table.push(row);
  }

  const {totalSiteWorkCostInMicros} = getTotalBreakdown(table);
  table.push([
    {value: "Site work cost total", readOnly: true},
    {
      value: priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
      readOnly: true,
    },
    {value: "", readOnly: true},
  ]);
  return table;
};

const initialData = getTable(8, 3, null);

const saveSitePricingToConfiguration = (
  data,
  configuration,
  configurationSite
) => {
  const {totalSiteWorkCostInMicros, errors} = getTotalBreakdown(data);
  if (errors.length > 0) {
    return;
  }

  const rows = data
    .filter((row) => {
      return row[1].readOnly !== true;
    })
    .map((row) => {
      const [title, totalPrice, note] = row;
      const totalPriceInt = getIntValue(totalPrice.value);
      return {
        lineItemId: title.key,
        title: title.value,
        totalPrice: totalPrice.value,
        totalPriceInMicros: totalPriceInt * 100,
        note: note.value,
      };
    });

  let lineItems = [];
  if (configurationSite != null && configurationSite.lineItems != null) {
    lineItems = configurationSite.lineItems.filter((row) => {
      return row.lineItemId === PHASE_1_LINE_ITEM_ID;
    });
  }
  lineItems = lineItems.concat(rows);

  const payload = {
    lineItems: lineItems,
    totalSiteWorkCostInMicros,
    autoGeneratedQuote: false,
  };

  const updateSiteWorkCosts = Utilities.saveOrUpdateDocument(
    firestore.collection(Sitework.getCostsKey()),
    configuration.id,
    payload
  );

  const updateConfigurationCosts = Utilities.saveOrUpdateDocument(
    firestore.collection(Configuration.Constants.CONFIGURATION_SITE_DB_KEY),
    configuration.id,
    payload
  );

  return Promise.all([updateConfigurationCosts, updateSiteWorkCosts]);
};

export const SiteBudgetSummary = (props) => {
  const {viewOnly, showExtras} = props; // Show bottom row add
  const {
    configuration,
    configurationSite,
    currentConfigurationId,
    configurationSnapshot,
  } = useCurrentProject();
  const currentUser = useSelector((state) => state.global.user);
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {saveGeoLocation} = useConfigurationLocation();
  const [data, setData] = useState(initialData);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (configurationSite != null && configurationSite.lineItems != null) {
      const rows = configurationSite.lineItems.filter((row) => {
        return row.lineItemId !== PHASE_1_LINE_ITEM_ID;
      });
      if (rows !== null && rows.length > 0) {
        setData(getTable(rows.length, Object.keys(rows[0]).length, rows));
      }
    }
  }, [configurationSite]);
  if (configuration == null) {
    return null;
  }
  const {totalSiteWorkCostInMicros, errors} = getTotalBreakdown(data);

  if (isAdmin(currentUser) && editing === true && showExtras) {
    return (
      <MainContainer>
        <div>
          <RowContainer>
            <ReactDataSheet
              style={{width: "100%"}}
              data={data}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={(changes) => {
                const grid = data.map((row) => [...row]);
                changes.forEach(({cell, row, col, value}) => {
                  grid[row][col] = {...grid[row][col], value};
                });
                const {totalSiteWorkCostInMicros} = getTotalBreakdown(grid);
                const lastRow = grid[grid.length - 1];

                lastRow[1].value = priceTextFromMicros(
                  "" + totalSiteWorkCostInMicros,
                  "min"
                );
                setData(grid);
              }}
            />
          </RowContainer>
        </div>

        <br />

        <button
          className={"button-secondary"}
          onClick={() => {
            setEditing(false);
            if (
              configurationSite != null &&
              configurationSite.lineItems != null
            ) {
              const rows = configurationSite.lineItems.filter((row) => {
                return row.lineItemId !== PHASE_1_LINE_ITEM_ID;
              });
              if (rows.length > 0) {
                setData(
                  getTable(rows.length, Object.keys(rows[0]).length, rows)
                );
              }
            } else {
              setData(initialData);
            }
          }}
          style={{marginRight: "20px"}}
        >
          Discard Edits
        </button>
        <button
          className={
            "button-primary bold " + (errors.length > 0 ? "unclickable" : "")
          }
          disabled={errors.length > 0}
          onClick={() => {
            setEditing(false);
            return saveSitePricingToConfiguration(
              data,
              configuration,
              configurationSite
            ).then(() => {
              saveGeoLocation();
            });
          }}
        >
          Save Site Work Breakdown
        </button>
      </MainContainer>
    );
  }

  if (
    (configurationSite == null ||
      configurationSite.lineItems == null ||
      configurationSite.visible !== true) &&
    !isAdmin(currentUser)
  ) {
    let table = null;
    let waitingGraphic = null;
    if (configurationSnapshot != null) {
      const rows = data
        .filter((row) => {
          return row[1].readOnly !== true;
        })
        .map((row, index) => {
          const items = row.map((entry, index) => {
            if (index > 0) {
              if (index === 2) {
                return "-";
              }
              return "";
            }
            return entry.value;
          });

          return (
            <RowContainer key={"row-" + index}>
              <TableRow items={items} />
            </RowContainer>
          );
        });
      table = (
        <div style={{opacity: 0.5}}>
          <HeaderRowContainer className={"bold"}>
            <TableRow items={["Site Work", "Price", "Notes & Comments"]} />
          </HeaderRowContainer>
          {rows}
          <FinalRowContainer className={"bold"}>
            <TableRow
              items={[
                "Site work cost total",
                priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
                "",
              ]}
            />
          </FinalRowContainer>
        </div>
      );
      waitingGraphic = (
        <MainContainer
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "30px",
              }}
            >
              <img
                src={laptop}
                alt={"Site Plan Image Missing"}
                width={"240px"}
              />
            </div>
            <div style={{textAlign: "left", height: "fit-content"}}>
              <div
                className={"large-text bold"}
                style={{marginBottom: "10px", fontSize: "25px"}}
              >
                Breakdown will be published during our call
              </div>
              <div>
                Our team will sit down with you to discuss your project in great
                detail. Afterwards, you will be able to view your site work
                budget summary here anytime.
              </div>
            </div>
          </div>
        </MainContainer>
      );
    } else {
      waitingGraphic = (
        <MainContainer style={{display: "flex", justifyContent: "center"}}>
          <div style={{textAlign: "center", maxWidth: "428px"}}>
            <img
              src={waiting_graphic}
              alt={"Site Plan Image Missing"}
              width={"240px"}
            />
            <br />
            <div className={"large-text bold"} style={{marginBottom: "10px"}}>
              Almost there
            </div>
            <div style={{marginBottom: "24px"}}>
              Our team is working on your budget summary and project timeline.
              We will let you know the details shortly!
            </div>
          </div>
        </MainContainer>
      );
    }
    return (
      <div>
        {waitingGraphic}
        {table}
      </div>
    );
  }

  let publishHideButton = null;
  if (configurationSite != null && configurationSite.visible === true) {
    publishHideButton = (
      <button
        className={"button-secondary"}
        onClick={() => {
          Utilities.setAndMergeDocumentInDB(
            Utilities.collection(CONFIGURATION_SITE_DB_KEY).doc(
              currentConfigurationId
            ),
            {
              visible: false,
            }
          );
        }}
        style={{marginLeft: "30px"}}
      >
        <FiEyeOff />
        {" Hide Costs from Customer"}
      </button>
    );
  } else {
    publishHideButton = (
      <button
        className={"button-primary bold"}
        onClick={() => {
          Utilities.setAndMergeDocumentInDB(
            Utilities.collection(CONFIGURATION_SITE_DB_KEY).doc(
              currentConfigurationId
            ),
            {
              visible: true,
            }
          );
        }}
        style={{marginLeft: "30px"}}
      >
        <FiEye />
        {" Reveal Costs to Customer"}
      </button>
    );
  }

  const rows = data
    .filter((row) => {
      return row[1].readOnly !== true;
    })
    .map((row, index) => {
      const items = row.map((entry, index) => {
        if (index >= 1 && index <= 3) {
          const intValue = getIntValue(entry.value);
          if (index === 2) {
            if (intValue === 0) {
              return "-";
            }
          }
          return cleanCurrencyString("" + intValue, index === 2 ? "sign" : "");
        }
        return entry.value;
      });

      return (
        <RowContainer key={"row-" + index}>
          <TableRow items={items} />
        </RowContainer>
      );
    });

  return (
    <MainContainer>
      <TableContainer>
        <HeaderRowContainer className={"bold"}>
          <Opaque />
          <TableRow items={["Site Work", "Price", "Notes & Comments"]} />
        </HeaderRowContainer>
        {rows}
        {viewOnly || !showExtras ? (
          <RowContainer className={"bold"}>
            <TableRow
              items={[
                "SITE WORK SUBTOTAL",
                priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
                "",
              ]}
            />
          </RowContainer>
        ) : (
          <FinalRowContainer className={"bold"}>
            <TableRow
              items={[
                "SITE WORK SUBTOTAL",
                priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
                "",
              ]}
            />
          </FinalRowContainer>
        )}
      </TableContainer>
      {isAdmin(currentUser) && adminMode && !viewOnly && showExtras ? (
        <div style={{display: "flex", marginTop: "20px"}}>
          <button
            className={"button-secondary"}
            onClick={() => {
              setEditing(true);
            }}
          >
            Edit Site Work Breakdown
          </button>
          {publishHideButton}
        </div>
      ) : null}
    </MainContainer>
  );
};
