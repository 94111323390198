import {firestore} from "../../../../database/firebase";
import "firebase/auth";
import {
	optionKeysLoaded,
	optionValuesLoaded,
} from "../../../_shared/slices/CatalogSlice";
import {store} from "../../../../store";

const optionKeysRef = () => {
	return firestore.collection("product_option_keys");
};
const optionValuesRef = () => {
	return firestore.collection("product_option_values");
};

export const fetchOptionKeys = () => {
	optionKeysRef().onSnapshot((snapshot) => {
		const docs = snapshot.docChanges();
		const optionKeys = {};
		docs.forEach((doc) => {
			const optionKey = doc.doc.data();
			optionKey.id = doc.doc.id;
			optionKeys[optionKey.id] = optionKey;
		});
		store.dispatch(optionKeysLoaded(optionKeys));
	});
};

export const fetchOptionValues = () => {
	optionValuesRef().onSnapshot((snapshot) => {
		const docs = snapshot.docChanges();
		const optionValues = {};
		docs.forEach((doc) => {
			const optionValue = doc.doc.data();
			optionValue.id = doc.doc.id;
			optionValues[optionValue.id] = optionValue;
		});

		store.dispatch(optionValuesLoaded(optionValues));
	});
};

export function searchOptionKeysInDB(title) {
	return optionKeysRef()
		.where("name", ">=", title)
		.where("name", "<=", title + "\uf8ff")
		.get()
		.then((querySnapshot) => {
			const results = [];
			querySnapshot.forEach((doc) => {
				const optionKey = doc.data();
				optionKey.id = doc.id;
				results.push(optionKey);
			});
			return results;
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});
}

export function searchOptionValuesInDB(title) {
	return optionValuesRef()
		.where("name", ">=", title)
		.where("name", "<=", title + "\uf8ff")
		.get()
		.then((querySnapshot) => {
			const results = [];
			querySnapshot.forEach((doc) => {
				const optionValue = doc.data();
				optionValue.id = doc.id;
				results.push(optionValue);
			});
			return results;
		})
		.catch((error) => {
			console.log("Error getting documents: ", error);
		});
}

export async function saveOptionKeyToDB(optionKey, thunkAPI) {
	if (optionKey.id == null) {
		optionKey.id = optionKeysRef().doc().id;
	}

	optionKeysRef().doc(optionKey.id).set(optionKey);
}

export async function saveOptionValueToDB(optionValue, thunkAPI) {
	let optionValueToSave = Object.assign({}, optionValue);
	let newValue = false;
	if (optionValueToSave.id == null) {
		newValue = true;
		optionValueToSave.id = optionValuesRef().doc().id;
	} else {
		delete optionValueToSave.optionKey;
	}

	if (newValue) {
		optionValuesRef().doc(optionValueToSave.id).set(optionValueToSave, {
			merge: true,
		});
	} else {
		optionValuesRef().doc(optionValueToSave.id).update(optionValueToSave, {
			merge: true,
		});
	}
}

function filterByKeys(object, filteringObject) {
	const filteredObject = Object.keys(object)
		.filter((key) => filteringObject.hasOwnProperty(key))
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: object[key],
			};
		}, {});
	return filteredObject;
}

export const convertGlobalOptionValuesToSelectionOptions = (optionValues) => {
	if (optionValues == null) {
		return [];
	}
	return Object.keys(optionValues).map((key) => {
		const optionValue = optionValues[key];
		return {
			label: optionValue.name,
			value: key,
		};
	});
};

export const convertGlobalOptionValuesToSelectionOptionsWithKey = (
	optionValues,
	optionKey
) => {
	if (optionValues == null) {
		return [];
	} else if (optionKey == null || optionKey.length < 1) {
		convertGlobalOptionValuesToSelectionOptions(optionValues);
	}

	const filteredOptionValues = {};
	Object.keys(optionValues).forEach((key) => {
		const optionValue = optionValues[key];
		if (
			optionValue.optionKey === optionKey ||
			(optionValue.optionKeys != null &&
				optionValue.optionKeys[optionKey] === true)
		) {
			filteredOptionValues[key] = optionValue;
		}
	});

	return convertGlobalOptionValuesToSelectionOptions(filteredOptionValues);
};

export const convertFromModifierOptionValuesToSelect = (
	optionKeyToOptionValueIDs,
	optionValues
) => {
	return Object.values(optionKeyToOptionValueIDs).map((optionValueId) => {
		if (optionValues == null || optionValues[optionValueId] == null) {
			return {
				label: optionValueId,
				value: optionValueId,
			};
		}
		const optionValue = optionValues[optionValueId];

		return {
			value: optionValue.id,
			label: optionValue.name,
		};
	});
};

export const convertSelectionToOptionKeyValue = (options, optionValues) => {
	if (options == null || options.length < 1) {
		return null;
	}
	let propertyObject = {};
	options.forEach((property) => {
		const option = optionValues[property.value];
		propertyObject[option.optionKey] = option.id;
	});
	return propertyObject;
};

export const convertSelectionsToMap = (selections) => {
	if (selections == null || selections.length < 1) {
		return {};
	}
	let propertyObject = {};
	selections.forEach((selection) => {
		propertyObject[selection.value] = true;
	});
	return propertyObject;
};

export const convertMapIntoSelections = (map, labelLookUp) => {
	if (map == null) {
		return [];
	}
	return Object.keys(map).map((selectionId) => {
		return {
			value: selectionId,
			label: labelLookUp[selectionId].name,
		};
	});
};
