import {useDispatch, useSelector} from "react-redux";
import {editModifier} from "../../_shared/slices/CatalogSlice";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {ProductLevelModifierStructureForEditing} from "../models/ModifierModel";
import {saveModifierToDB} from "../backend/catalogApi";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../_shared/application";
import {AdminForm} from "../../admin/products/styles/AdminStyles";
import {AdminInput} from "../../admin/products/components/AdminComponentFactory";
import {
  getPriceMicrosFromTextToSave,
  getTextFromPriceMicros,
} from "@natomas/core";

export const EditModifier = (props) => {
  const {currentProduct} = props;
  const modifier = useSelector((state) => state.catalog.editingModifier);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(editModifier(null));
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(
      ProductLevelModifierStructureForEditing
    ),
    onSubmit: (values) => {
      const modifierToSave = Object.assign({}, values);
      modifierToSave.id = modifier.id;
      modifierToSave.priceMicros = getPriceMicrosFromTextToSave(
        modifierToSave.priceMicros
      );

      modifierToSave.defaultPriceMicros = getPriceMicrosFromTextToSave(
        modifierToSave.defaultPriceMicros
      );

      saveModifierToDB(modifierToSave, currentProduct.id);
      close();
    },
  });

  useEffect(() => {
    if (modifier == null) {
      formik.resetForm();
    } else {
      for (const key in ProductLevelModifierStructureForEditing) {
        const value = modifier[key];
        const keyInfo = ProductLevelModifierStructureForEditing[key];
        if (value != null) {
          if (keyInfo.subType === "price") {
            formik.setFieldValue(key, getTextFromPriceMicros(value));
          } else {
            formik.setFieldValue(key, value);
          }
        }
      }
    }
  }, [modifier]);

  if (modifier == null) {
    return null;
  }

  const modifierStructureForForm = getFormStructure(
    ProductLevelModifierStructureForEditing
  );
  return (
    <div id="myModal" className="modal">
      <div
        className="fullDiv"
        onClick={() => {
          close();
        }}
      />
      <div className="modal-content eighty-full">
        <div
          className="close"
          onClick={() => {
            close();
          }}
        >
          &times;
        </div>
        <div>
          <p>{modifier.id}</p>
          <AdminForm onSubmit={formik.handleSubmit}>
            {modifierStructureForForm.map((keyInfo) => {
              const {key} = keyInfo;
              const value = formik.values[key];

              return (
                <AdminInput
                  value={value}
                  fieldInfo={keyInfo}
                  onChange={(value) => {
                    formik.setFieldValue(key, value);
                  }}
                  handleChange={formik.handleChange}
                />
              );
            })}

            <button
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </button>
          </AdminForm>
        </div>
      </div>
    </div>
  );
};
