import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLeadInfo: {},
    userActions: {},
  },
  reducers: {
    storeUserLeadInfo: (state, action) => {
      const {userInfo, uid} = action.payload;
      state.userLeadInfo[uid] = userInfo;
    },
    storeUserActions: (state, action) => {
      const {actions, uid} = action.payload;
      if (state.userActions[uid] == null) {
        state.userActions[uid] = {};
      }

      Object.keys(actions).forEach((eventId) => {
        state.userActions[uid][eventId] = actions[eventId];
      });
    },
  },
});

export const userReducer = userSlice.reducer;
export const {storeUserLeadInfo, storeUserActions} = userSlice.actions;
