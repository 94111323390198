import {getUnitPriceInMicros} from "../../design-tool/logic/ProductUtils";

export const getPricingForUnit = (configuration) => {
	if (configuration == null) {
		return {
			unitAndLineItemsPriceInMicros: 0,
			unitBasePriceInMicros: 0,
			lineItemsPriceInMicros: 0,
			upgradePriceInMicros: 0,
			fullUnitTotalPriceInMicros: 0,
		};
	}

	const {
		product,
		upgradesInMicros,
		additionalLineItemsTotalInMicros,
	} = configuration;
	const unitPriceInMicros = product != null ? getUnitPriceInMicros(product) : 0;
	const upgradePriceInMicros = upgradesInMicros ?? 0;
	const lineItemPriceInMircos = additionalLineItemsTotalInMicros ?? 0;

	return {
		unitAndLineItemsPriceInMicros: unitPriceInMicros + lineItemPriceInMircos,
		unitBasePriceInMicros: unitPriceInMicros,
		unitBasePriceAndUpgradesInMicros: unitPriceInMicros + upgradePriceInMicros,
		upgradePriceInMicros: upgradePriceInMicros,
		lineItemsPriceInMicros: lineItemPriceInMircos,
		fullUnitTotalPriceInMicros:
			lineItemPriceInMircos + unitPriceInMicros + upgradePriceInMicros,
	};
};

export const getPricingForSite = (configurationSite) => {
	let siteWorkBasePrice = 0;
	let siteSpecificPrice = 0;
	let totalSiteWorkCosts = 0;

	if (configurationSite) {
		const {
			totalBaseCostInMicros,
			totalSiteSpecificCostInMicros,
			totalSiteWorkCostInMicros,
		} = configurationSite;
		siteWorkBasePrice = totalBaseCostInMicros ?? 0;
		siteSpecificPrice = totalSiteSpecificCostInMicros ?? 0;
		totalSiteWorkCosts = totalSiteWorkCostInMicros ?? 0;
	}

	return {
		siteWorkBasePrice,
		siteSpecificPrice,
		totalSiteWorkCosts,
	};
};
