import {useMatomo} from "@datapunt/matomo-tracker-react";

export enum TrackingCategory {
  BUTTON = "Button",
  INTERPRETED = "Interpreted",
}

export enum ButtonAction {
  SUBMISSION = "Submission",
  CANCEL = "Cancel",
  NAVIGATION = "Navigation",
  SELECT = "Select",
  DESELECT = "Deselect",
  DOWNLOAD = "Download",
}

export enum InterpretedAction {
  Journey = "Journey",
}

export enum JourneyStepName {
  STARTED = "PDP Started",
  INFO_SUBMITTED = "PDP Info Submitted",
  UNIT_SELECTION_COMPLETED = "PDP Unit Selected",
  PAYMENT_APPROACHED = "PDP Proposal Fee Approached",
  PAYMENT_SUBMITTED = "PDP Proposal Fee Submitted",
  PAYMENT_ABANDON = "PDP Proposal Fee Abandoned",
}

const TRACKING_RETRY_COUNT = 3;

const trackHeapEvent = (
  eventName: string,
  payload?: any,
  retryCount: number = 0
) => {
  // @ts-ignore
  const heap: any = window.heap;

  if (heap.identity && heap.track) {
    heap.track?.(eventName, payload);
  } else if (!retryCount || retryCount < TRACKING_RETRY_COUNT) {
    setTimeout(() => {
      trackHeapEvent(eventName, payload, retryCount + 1);
    }, 1000);
  }
};

export const useTracking = () => {
  const {trackPageView, trackEvent} = useMatomo();

  const markEvent = (
    category: TrackingCategory,
    action: ButtonAction | InterpretedAction,
    name?: string,
    value?: number
  ) => {
    trackEvent({
      category: category,
      action: action,
      name: name,
      value: value,
    });
  };

  const markJourneyEvent = (name: JourneyStepName) => {
    trackHeapEvent(name);
    markEvent(
      TrackingCategory.INTERPRETED,
      InterpretedAction.Journey,
      name,
      undefined
    );
  };

  const markButtonEvent = (
    action: ButtonAction,
    name?: string,
    value?: number
  ) => {
    markEvent(TrackingCategory.BUTTON, action, name, value);
  };

  const markPageView = (title: string) => {
    trackPageView({
      documentTitle: title,
    });
  };
  return {
    markJourneyEvent,
    markButtonEvent,
    markPageView,
  };
};
