import styled from "styled-components";
import {isMobile} from "../../../../_shared/navigation";

export const PROJECT_SCHEDULE_1 = "#D1EBCC";
export const PROJECT_SCHEDULE_2 = "#FFDF8D";
export const PROJECT_SCHEDULE_3 = "#F08080";
export const PROJECT_SCHEDULE_4 = "#B7CDFF";
export const PROJECT_SCHEDULE_5 = "#E6C7FF";
const MOBILE_TIMELINE_ROW_HEIGHT = 40;
const MOBILE_TIMELINE_CONTENT_OFFSET = 35; // Set when other content is added/removed from mobile page

export const PhaseTitle = styled.div<{
  color: string;
}>`
  font-size: 12px;
  margin-left: 10px;
  font-family: Maison-Bold, serif;
  margin-top: -19px;
  width: fit-content;
  text-align: center;
  border-radius: 4px;
  padding: 0 8px;
  background-color: ${(props) => props.color + "33"};
`;
export const PhaseBox = styled.div<{
  show: boolean;
  startLineColor: string;
  endLineColor: string;
  width: number;
  start: number;
}>`
  position: absolute;
  left: ${(props) => props.start + "%"};
  width: ${(props) => props.width + "%"};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  background-image: linear-gradient(
    to right,
    ${(props) => props.startLineColor + "1A"},
    ${(props) => props.endLineColor + "1A"}
      // Addition of 33 makes hex transparent
  );
  border-left: 2px dotted ${(props) => props.startLineColor};
  border-right: 2px dotted ${(props) => props.endLineColor};
  height: 200px;
  opacity: 1;
  pointer-events: none;
  z-index: -1;
`;

export const MainContainer = styled.div<{active: boolean}>`
  height: 100%;
  overflow: auto;
  padding-right: 40px;
  display: ${(props) => (props.active ? "block" : "none")};
`;

export const ProjectCostContainer = styled.div`
  height: 160px;
  width: 790px;
  margin-top: 50px;
  max-width: 100%;
  overflow-x: auto;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const ProjectTotalCostContainer = styled.div`
  position: relative;
  width: 33%;
  padding-left: ${isMobile() ? "20px" : "60px"};
  margin-right: ${isMobile() ? "20px" : "0px"};
  line-height: 1.9;
  min-width: fit-content;
`;

export const ProjectCostBreakdownContainer = styled.div`
  position: relative;
  flex-grow: 2;
  padding-left: ${isMobile() ? "20px" : "50px"};
  margin-right: ${isMobile() ? "20px" : "0px"};
  border-left: rgba(0, 0, 0, 0.3) solid 1px;
`;

export const UnitSiteBreakdownContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 8px;
`;

export const BreakdownContainer = styled.div`
  flex-grow: 2;
  margin-right: 10px;
`;

export const BreakdownTitle = styled.div`
  font-size: 14px;
  width: max-content;
`;

export const BreakdownValue = styled.div`
  font-size: 22px;
`;
export const BreakdownValueFlex = styled.div`
  font-size: 22px;
  display: flex;
  align-items: center;
`;
export const BreakdownValueFlag = styled.div<{hiddenElement: boolean}>`
  font-size: 14px;
  margin-left: 12px;
  height: fit-content;
  font-family: Maison-Book, sans-serif;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: #ffd1e7;
  display: ${(props) => (props.hiddenElement === true ? "none" : "block")};
`;
export const TabTitle = styled.div`
  font-size: 20px;
`;

export const ToggleContainer = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  width: fit-content;
  border-radius: 100px;
`;

export const CostTabToggleContainer = styled.div`
  height: 50px;
  display: flex;
  margin-top: 60px;
  margin-bottom: 40px;
  border-radius: 100px;
  position: relative;
  min-width: fit-content;
  overflow-x: auto;
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
`;

export const CostTabToggle = styled.div<{active: boolean}>`
  line-height: 50px;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  min-width: fit-content;
  background-color: ${(props) =>
    props.active ? "rgba(130, 194, 117, 0.1)" : "none"};
  box-shadow: ${(props) =>
    props.active ? `inset 0px 0px 0px 3px #82C275` : "none"};
  font-family: ${(props) => (props.active ? `Maison-Bold` : "Maison-Book")};
  cursor: pointer;
`;

export const ToDoListTag = styled.div`
  background: rgba(110, 188, 233, 0.3);
  border-radius: 5px;
  padding: 4px 10px;
  width: fit-content;
  color: rgba(0, 0, 0, 0.8);
  font-family: Maison-Medium, sans-serif;
  font-size: 14px;
`;

export const BreakdownPageContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
`;

export const TabRow = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 40px;
  padding-right: 20px;
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
  position: relative;
`;
export const TabContainer = styled.div`
  margin-top: 20px;
  height: 40px;
  position: relative;
  display: flex;
`;

export const Tab = styled.div<{active: boolean}>`
  height: 100%;
  margin-right: 40px;
  min-width: fit-content;
  flex-shrink: 0;
  border-bottom: ${(props) => (props.active ? "3px solid #82c275" : "none")};
  font-family: ${(props) => (props.active ? "Maison-Bold" : "Maison-Book")};
  position: relative;
  cursor: pointer;
`;

export const ProposalContainer = styled.div`
  width: 100%;
  //height: calc(100% - 65px);
  //overflow: hidden;
`;

export const Container = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  padding-left: 40px;
  overflow: auto;
  position: relative;
`;

export const Title = styled.div`
  font-family: Maison-Bold, sans-serif;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 6px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
`;

export const ParentBoxContainer = styled.div`
  display: flex;
  margin-top: 21px;
  margin-bottom: 30px;
  overflow-x: auto;
  width: 100%;
`;

export const BoxContainer = styled.div`
  width: 300px;
  height: fit-content;
  min-width: 300px;
  min-height: 160px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-right: 60px;
  padding: 16px 26px;
  margin-bottom: 12px;
`;

export const BoxTitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin-bottom: 10px;
`;
export const BoxContentContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 16px;
`;
export const BoxLeftContent = styled.div`
  width: 20px;
  margin-right: 10px;
`;
export const BoxRightContent = styled.div`
  flex-grow: 1;
  font-size: 16px;
`;
export const CallToAction = styled.div`
  margin-top: 4px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
`;
export const TimeLineParent = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;
export const TimeLineHeader = styled.div`
  margin-top: 46px;
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;
`;
export const TimeLineHeaderTitle = styled.div`
  font-family: Maison-Bold, sans-serif;
  font-size: 20px;
`;
export const TimeLineHeaderETA = styled.div`
  display: ${isMobile() ? "none" : "flex"};
  font-size: 14px;
  align-items: center;
`;

export const MobileTimeLineHeaderETAComponent = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  div {
    margin-right: 10px;
  }
  white-space: nowrap;
  overflow: hidden;
`;

export const TimeLineHeaderETAComponent = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  div {
    margin-right: 10px;
  }
`;
export const TimeLineFooter = styled.div`
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
`;

export const TimelineContainer = styled.div`
  display: flex;
`;
export const TimelineLeftColumn = styled.div`
  width: 180px;
  flex-shrink: 0;
`;
export const TimelineRightColumn = styled.div`
  flex-grow: 2;
  overflow-x: auto;
`;
export const TimelineTable = styled.div`
  min-width: 900px;
  flex-grow: 2;
`;
export const TimelineRowTop = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
`;
export const TimelineRow = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: none;
  }
`;

export const TimelineToolTip = styled.div`
  background-color: white;
  color: black;
  padding: 4px;
`;

export const TimelineToolTipTitle = styled.div`
  font-size: 18px;
`;

export const TimelineToolTipSubTitle = styled.div`
  font-size: 15px;
  margin-top: 20px;
`;

export const TimelineToolTipContent = styled.div`
  margin-top: 8px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-wrap;
`;

export const TimelineRowInfoContainer = styled.div`
  padding: 6px 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
export const InfoTitle = styled.div`
  font-size: 16px;
  font-family: Maison-Bold, sans-serif;
`;
export const InfoSubtitle = styled.div`
  font-size: 12px;
`;
export const RowCell = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
`;
export const MainRowCell = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  &:first-child {
    border-left: none;
  }
`;

export const MobileTimelineContainer = styled.div`
  padding-bottom: 40px;
`;

export const MobileTimelineLeftColumn = styled.div`
  padding-top: 10px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`;

export const MobileTimelineRightColumn = styled.div`
  flex-grow: 2;
  overflow-x: auto;
`;

export const MobileTimelineTable = styled.div`
  min-width: 100%;
  flex-grow: 2;
  flex-direction: row;
`;

export const MobileTimelineRowTop = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
`;

export const MobileTimelineRow = styled.td`
  margin-top: ${MOBILE_TIMELINE_ROW_HEIGHT / 2 + "px"};
  width: 100%;
  height: ${MOBILE_TIMELINE_ROW_HEIGHT / 2 + "px"};
  display: flex;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: none;
  }
`;

export const MobileTimelineRowInfoContainer = styled.div`
  padding: 6px 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const MobileInfoSubtitle = styled.div`
  font-size: 12px;
`;

export const MobileRowCell = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
`;

export const MobileMainRowCell = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  &:first-child {
    border-left: none;
  }
`;

export const MobileMonthLeftColumn = styled.div`
  width: 30px;
  height: 100%;
  flex-shrink: 0;
`;

export const MobileMonthNameRow = styled.td`
  height: 15px;
  width: 40px;
`;

export const MobileStageTitleDiv = styled.div`
  font-family: Maison-Medium, sans-serif;
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 117%;
  width: 90%;
`;

export const MobileStageMonthCountDiv = styled.div`
  font-family: Maison-Book, sans-serif;
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
`;

export const MobileStageMonthTooltipDiv = styled.div`
  height: 20px;
  width: 20px;
  margin-top: -70px;
  margin-left: 85%;
`;

export const MobileStagePhaseDescriptionDiv = styled.div`
  font-family: Maison-Book, sans-serif;
  word-break: break-all;
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-wrap: normal;
`;

export const RowBlock = styled.div<{
  width: number;
  bgColor: string;
  start: number;
}>`
  width: ${(props) => props.width + "%"};
  background-color: ${(props) => props.bgColor};
  position: absolute;
  left: ${(props) => props.start + "%"};
  top: 20%;
  bottom: 20%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 12px;
  font-family: Maison-Bold, sans-serif;
  padding-left: 10px;
  line-height: 70px;
`;

export const MobileRowBlock = styled.div<{
  monthHeight: number;
  bgColor: string;
  start: number;
}>`
  width: calc(100% - 80px - 20%);
  justify-content: flex-start;
  border-left: 10px solid ${(props) => props.bgColor};
  height: ${(props) => props.monthHeight * MOBILE_TIMELINE_ROW_HEIGHT + "px"};
  margin-left: 60px;
  background-color: ${(props) =>
    props.bgColor + "40"}; // Addition to hex represents opacity
  opacity: 1;
  top: ${MOBILE_TIMELINE_CONTENT_OFFSET + "rem"};
  position: absolute;
  margin-top: ${(props) => props.start * MOBILE_TIMELINE_ROW_HEIGHT + "px"};
  bottom: 20%;
  border-radius: 5px;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-size: 12px;
  font-family: Maison-Bold, sans-serif;
  padding-left: 10px;
  line-height: 70px;
`;

export const MobilePhaseTitle = styled.div`
  font-size: 14px;
  margin-left: -22px;
  margin-top: 15px;
  font-family: Maison-Book, serif;
  width: max-content;
  text-align: center;
  border-radius: 4px;
  padding: 0 8px;
  transform: rotate(90deg);
`;

export const MobilePhaseBox = styled.div<{
  show: boolean;
  startLineColor: string;
  endLineColor: string;
  monthHeight: number;
  start: number;
}>`
  top: ${MOBILE_TIMELINE_CONTENT_OFFSET + "rem"};
  position: absolute;
  margin-top: ${(props) => props.start * MOBILE_TIMELINE_ROW_HEIGHT + "px"};
  height: ${(props) => props.monthHeight * MOBILE_TIMELINE_ROW_HEIGHT + "px"};
  left: calc(100% - 20%);
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  background-image: linear-gradient(
    to bottom,
    ${(props) => props.startLineColor},
    ${(props) => props.endLineColor}
  );
  width: 4px;
  opacity: 1;
  pointer-events: none;
  z-index: 100;
  padding-bottom: 10px;
`;

export const MobileStepContainer = styled.div`
  width: 100%;
`;

export const MobileStepSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export const MobileParentBoxContainer = styled.div`
  padding-top: 20px;
  margin-bottom: 0;
  overflow-x: auto;
  width: 100%;
`;

export const MobileTitle = styled.div`
  font-family: Maison-Bold, sans-serif;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
`;
