import {auth} from "../index";
import axios from "axios";
import {API_BASE_URL} from "../../../components/_shared/application";

export const executeRequest = async (endpoint, payload) => {
	const headers = {};
	let authToken = "";
	if (auth.currentUser) {
		authToken = await auth.currentUser.getIdToken();
		headers["Authorization"] = "Bearer " + authToken;
	}
	const uri = API_BASE_URL() + endpoint;

	if (payload) {
		return axios
			.post(uri, payload, {
				headers,
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log("Error executing API: ", error);
				return null;
			});
	}

	return axios
		.get(uri, {
			headers,
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			console.log("Error executing API: ", error);
			return null;
		});
};
