import * as Yup from "yup";
import {EMAIL_KEY, PASSWORD_KEY} from "./models";

const emailValidation = {
  [EMAIL_KEY]: Yup.string().email("Invalid email").required("Required"),
};

const passwordValidation = {
  [PASSWORD_KEY]: Yup.string().min(6, "Too Short!").required("Required"),
};

export {emailValidation, passwordValidation};
