import React from "react";

export const CheckMark = (props) => {
	let checkboxClass = "bigCircleCheck visited";

	if (props.incomplete === true) {
		checkboxClass = "bigCircleCheck";
	}

	const {size, strokeWidth} = props;
	const width = strokeWidth != null ? strokeWidth : 5;

	return (
		<div
			className={checkboxClass}
			style={{height: size + "px", width: size + "px"}}
		>
			<svg
				viewBox={`0 0 ${size} ${size}`}
				style={{
					height: size + "px",
					width: size + "px",
					strokeWidth: width + "px",
				}}
				preserveAspectRatio="xMidYMin"
			>
				<polyline
					points={`${size * 0.25} ${size * 0.5} ${size * 0.45} ${size * 0.75} ${
						size * 0.8
					} ${size * 0.3}`}
				/>
			</svg>
		</div>
	);
};
