import React, {useMemo} from "react";
//Core
import {IProductGroupDetails} from "@natomas/core";
//Styles
import {HelpText, ProductLists, ProductLineList} from "./styles";
//Shared
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {ProductList} from "../../_shared/components/ProductList";
import {ProductLineCard} from "../../_shared/components/ProductLineCard";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {
  CatalogSectionTitle,
  CatalogSectionDescription,
  CatalogTitle,
} from "../styles";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {usePage} from "../../../_shared/hooks/usePage";
//Filters
import {FilterContainer} from "../../_shared/components/Filters/FilterContainer";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {getUnitPriceInMicros} from "../../../design-tool/logic/ProductUtils";
import {PricingMode} from "../../../_shared/slices/GlobalSlice";

export const MainView = (props: {availableGroups: any[]}) => {
  const {PRICING_MODE} = useGlobalConfiguration();
  const {availableGroups} = props;
  const {columnCount} = usePage();
  const {filteredListedProducts, products} = useProductCatalog();
  const productLineCardColumnWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });

  const mainDescriptionWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });

  const calculatePriceRange = (groupId: string) => {
    let min = Infinity;
    let max = -Infinity;
    Object.values(products).forEach((p: any) => {
      const price =
        PRICING_MODE === PricingMode.UNIT
          ? getUnitPriceInMicros(p)
          : p.priceMicros;
      if (p.productGroupId === groupId) {
        if (min > price) {
          min = price;
        }
        if (max < price) {
          max = price;
        }
      }
    });
    if (min !== Infinity && max !== -Infinity) {
      const minString = "$" + Math.floor(min / 100000) + "k";
      const maxString = "$" + Math.floor(max / 100000) + "k";
      return "From " + minString + " to " + maxString;
    }
    return "";
  };

  const getProductLineCards = (itemColumnWidth: number) => {
    return availableGroups.map((group: IProductGroupDetails) => {
      return (
        <ProductLineCard
          key={group.id + "product-line-card"}
          id={group.id}
          title={group.title}
          description={group.displayDescription}
          priceRange={calculatePriceRange(group.id)}
          imageId={group.imageId}
          columnWidth={itemColumnWidth}
        />
      );
    });
  };

  const oneBedroomProducts: any = useMemo(() => {
    return filteredListedProducts
      ? Object.values(filteredListedProducts).filter((product: any) => {
          return product.productDetails?.bedrooms === 1;
        })
      : [];
  }, [filteredListedProducts]);

  const twoBedroomProducts: any = useMemo(() => {
    return filteredListedProducts
      ? Object.values(filteredListedProducts).filter((product: any) => {
          return product.productDetails?.bedrooms === 2;
        })
      : [];
  }, [filteredListedProducts]);

  const threeBedroomProducts: any = useMemo(() => {
    return filteredListedProducts
      ? Object.values(filteredListedProducts).filter((product: any) => {
          return product.productDetails?.bedrooms === 3;
        })
      : [];
  }, [filteredListedProducts]);

  return (
    <>
      <PageContainer>
        <PageElement size={mainDescriptionWidth} height={"auto"}>
          <CatalogTitle>Our products</CatalogTitle>
          <CatalogSectionDescription>
            {
              "Dive into one of our product lines or use the filters below to find the home that's right for you."
            }
          </CatalogSectionDescription>
        </PageElement>
        <CatalogSectionTitle>Collections</CatalogSectionTitle>
        <ProductLineList
          removeRightMargin={Math.floor(
            columnCount / productLineCardColumnWidth
          )}
        >
          {getProductLineCards(productLineCardColumnWidth)}
        </ProductLineList>
      </PageContainer>
      <FilterContainer offset={"64px"} />
      <PageContainer>
        <ProductLists>
          {oneBedroomProducts.length > 0 && (
            <ProductList
              title={"Single bedroom ADUs"}
              products={oneBedroomProducts}
            />
          )}
          {twoBedroomProducts.length > 0 && (
            <ProductList
              title={"Two bedroom ADUs"}
              products={twoBedroomProducts}
            />
          )}
          {threeBedroomProducts.length > 0 && (
            <ProductList
              title={"Three bedroom ADUs"}
              products={threeBedroomProducts}
            />
          )}
          {threeBedroomProducts.length === 0 &&
            twoBedroomProducts.length === 0 &&
            oneBedroomProducts.length === 0 && (
              <HelpText>No ADUs match your filters.</HelpText>
            )}
        </ProductLists>
      </PageContainer>
    </>
  );
};
