import React from "react";
import {Icon} from "../components/icon";

export const NoContact = () => {
	return (
		<div className="form-wrapper">
			<Icon
				iconSrc="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fconfirmation-icon.png?alt=media&token=59945730-5cff-4369-b14c-4f1d5ac91846"
				iconAlt="Check mark"
			/>
			<div className="confirmation-title-wrapper">
				<h2 className="confirmation-title">Thanks anyway for reaching out</h2>
			</div>

			<p className="confirmation-text">
				We will be sure to reach out when we could be helpful. Know someone who
				could be interested?
			</p>
			<div className="confirmation-buttons">
				<a
					href="https://www.villahomes.com/referrals"
					className="black-btn link"
				>
					Refer a friend
				</a>
			</div>
		</div>
	);
};
