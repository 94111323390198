import React from "react";
import {priceTextFromMicros} from "../logic/ConfigurationPageHelper";
import {ConfigurationPageSummary} from "../components/ConfigurationPageSummary";
import {OneLine} from "../components/one-line";
import {Underline} from "../components/underline";
import {SummaryHeader} from "../components/SummaryHeader";
import {
  getBasePriceForDesignStudio,
  getConstructionPriceMicros,
  getUnitPriceInMicros,
} from "../logic/ProductUtils";
import {isMarketingPage} from "../../_shared/navigation";
import {setURLPathAndQuery} from "../../_shared/navigation/_helpers";
import {useHistory} from "react-router-dom";
import {useDesignStudio} from "../../_shared/hooks/useDesignStudio";

function getSummaryFooter(currentProduct, upgradeCost, orderSummary) {
  return (
    <>
      <h4>Summary</h4>
      <div style={{fontStyle: "italic"}}>
        {!isMarketingPage() ? (
          <OneLine
            leftText={"Base Package"}
            rightText={priceTextFromMicros(
              getBasePriceForDesignStudio(currentProduct),
              "min"
            )}
          />
        ) : (
          <div>
            <OneLine
              leftText={"Base Unit"}
              rightText={priceTextFromMicros(
                getUnitPriceInMicros(currentProduct),
                "min"
              )}
            />
            <OneLine
              leftText="Base Site Work*"
              rightText={priceTextFromMicros(
                getConstructionPriceMicros(currentProduct),
                "min"
              )}
            />
          </div>
        )}
        <OneLine leftText="Upgrades" rightText={upgradeCost} />
      </div>

      <div className="description-wrapper base-price">
        <OneLine
          leftText="Price Estimate"
          rightText={priceTextFromMicros(
            getBasePriceForDesignStudio(currentProduct) +
              orderSummary.upgradesInMicros,
            "min"
          )}
        />
        {!isMarketingPage() ? null : (
          <div
            className={"no-transform"}
            style={{fontSize: "12px", textAlign: "right"}}
          >
            * additional site work costs dependant on lot
          </div>
        )}
      </div>

      <Underline className="result-underline" />

      <div className="description-wrapper no-transform greyed-out terms-policy">
        <p>
          By submitting this form, you accept our Terms of Use and Privacy
          Policy.
        </p>
      </div>
    </>
  );
}

export const SummaryPage = (props) => {
  const {currentProduct, preview} = props;
  const history = useHistory();
  const {orderSummary} = useDesignStudio({
    summary: true,
  });

  let summarizeOrderInConfigurationPages =
    orderSummary != null ? orderSummary.configurationSnapshot : null;

  if (summarizeOrderInConfigurationPages == null) {
    return null;
  }

  let upgradeCost = priceTextFromMicros(
    orderSummary.upgradesInMicros,
    "min-sign"
  );
  if (
    orderSummary.upgradesInMicros == null ||
    orderSummary.upgradesInMicros === 0
  ) {
    upgradeCost = "+$0";
  }

  return (
    <div className="result-wrapper" style={{paddingBottom: "0px"}}>
      <SummaryHeader product={currentProduct} preview={preview} />

      <Underline className="result-underline" />
      {summarizeOrderInConfigurationPages.map((page, index) => {
        return (
          <div key={page.id}>
            <ConfigurationPageSummary
              configurationPage={page}
              index={index + 1}
            />
          </div>
        );
      })}
      {preview !== true ? (
        getSummaryFooter(currentProduct, upgradeCost, orderSummary)
      ) : (
        <div>
          <button
            className={"button-primary bold"}
            style={{width: "100%"}}
            onClick={() => {
              setURLPathAndQuery(history, "/design", "unit", currentProduct.id);
            }}
          >
            Edit Selections
          </button>
        </div>
      )}
    </div>
  );
};
