import React, {useState} from "react";
import {
  DeveloperToolsContainer,
  CollapsableContainerHeader,
  ToolsDescription,
  ToolsTitle,
  CollapsableContainer,
} from "./styles";
import {SectionToggle} from "./_shared/SectionToggle";
import {PreDepositSection} from "./PreDepositSection";
import {CatalogSection} from "./CatalogSection";
import {usePage} from "../../hooks/usePage";

export const DeveloperTools = () => {
  //Local State
  const [show, setShow] = useState<boolean>(false);
  const {isNatMobile} = usePage();

  const widthClosed = isNatMobile() ? "3rem" : "15rem";

  const getTitle = () => {
    if (isNatMobile()) {
      if (!show) return <></>;
    }
    return <ToolsTitle>Developer Tools</ToolsTitle>;
  };

  return (
    <DeveloperToolsContainer widthClosed={widthClosed} show={show}>
      <CollapsableContainerHeader>
        {getTitle()}
        <SectionToggle setShow={setShow} show={show} />
      </CollapsableContainerHeader>
      <CollapsableContainer show={show} allowScroll={true}>
        <ToolsDescription>
          This modal window is <b>strictly</b> for the development environment,
          and should only be used with accounts that have been configured for{" "}
          <b>testing</b> purposes.
        </ToolsDescription>
        <PreDepositSection />
        <CatalogSection />
      </CollapsableContainer>
    </DeveloperToolsContainer>
  );
};
