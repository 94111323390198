import {firestore} from "../../../database/firebase";
import {store} from "../../../store";
import {
  setProductAndGroupId,
  setProductGroupId,
  setProductId,
} from "../slices/CatalogSlice";
import {setMultipleURLQueryKeyValues} from "../navigation/_helpers";

const PRODUCT_GROUP_URL_KEY = "gid";
const PRODUCT_URL_KEY = "unit";

const fetchProductFromId = (productId, callback) => {
  const products = firestore
    .collectionGroup("products")
    .where("id", "==", productId);
  return products.get().then((querySnapshot) => {
    if (querySnapshot.empty) {
      callback(null);
    } else {
      querySnapshot.forEach((doc) => {
        callback(doc);
      });
    }
  });
};

export const updateProductGroup = (productGroupId) => {
  store.dispatch(setProductGroupId(productGroupId));
};

export const updateProductGroupAndProductId = (productId) => {
  return fetchProductFromId(productId, (doc) => {
    if (doc) {
      const productGroupId = doc.ref.parent.parent.id;
      if (productGroupId !== "adu") {
        updateProductURLAndState(productGroupId, productId);
      }
    } else {
      store.dispatch(setProductId(null));
    }
  });
};

export const updateProductId = (productId) => {
  store.dispatch(setProductId(productId));
};

export const updateProductGroupAndProduct = (
  productGroupId,
  productId,
  mode
) => {
  if (productGroupId == null && productId != null) {
    return updateProductGroupAndProductId(productId);
  }
  updateProductURLAndState(productGroupId, productId, mode);
};

export const updateProductAndGroupState = (productGroupId, productId) => {
  store.dispatch(setProductAndGroupId({productId, productGroupId}));
};

export const updateProductURLAndState = (productGroupId, productId, mode) => {
  setMultipleURLQueryKeyValues(
    [
      {key: PRODUCT_GROUP_URL_KEY, value: productGroupId},
      {key: PRODUCT_URL_KEY, value: productId},
    ],
    mode
  );
  updateProductAndGroupState(productGroupId, productId);
};

export const getProductGroupModifierGroups = (state) => {
  const productGroupId = state.catalog.productGroupId;
  const modifierGroups = state.catalog.modifierGroups;
  const productGroupModifierGroups = {};
  Object.values(modifierGroups)
    .filter((modifierGroup) => modifierGroup.productGroupId === productGroupId)
    .forEach((modifierGroup) => {
      productGroupModifierGroups[modifierGroup.id] = modifierGroup;
    });
  return productGroupModifierGroups;
};
