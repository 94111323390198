import React, {useState} from "react";
import {NatToggle} from "../../../../_shared/generics/toggle/NatToggle";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {
  ProductDiscoveryStepContent,
  Question,
  QuestionHelper,
  QuestionIcon,
} from "./styles";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {GiHouse} from "react-icons/all";
import {NatButton} from "../../../../_shared/generics/button";
import {getBedroomFilter, tryFilter} from "../../../../_shared/catalog/filter";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {ButtonAction, useTracking} from "../../../../_shared/hooks/useTracking";

export const ProductDiscoveryStep = (props: {
  completeDiscovery: () => void;
  seeRecommendations: () => void;
}) => {
  const {completeDiscovery, seeRecommendations} = props;
  const {filters} = useProductCatalog();
  const {markButtonEvent} = useTracking();
  const [seeOneBedroom, setSeeOneBedroom] = useState<boolean>(false);
  const [seeTwoBedroom, setSeeTwoBedroom] = useState<boolean>(false);
  const [seeThreeBedroom, setSeeThreeBedroom] = useState<boolean>(false);

  const submitFilters = () => {
    let count = 0;
    seeOneBedroom && count++;
    seeTwoBedroom && count++;
    seeThreeBedroom && count++;
    if (count > 1) {
      // If more than one selected show main portal
      completeDiscovery();
    } else {
      // See recommendations
      seeRecommendations();
    }
    // Always add appropriate filters
    seeOneBedroom &&
      tryFilter(getBedroomFilter(1, "1 bedroom"), filters) &&
      markButtonEvent(ButtonAction.SELECT, "Catalog 1 Bedroom");
    seeTwoBedroom &&
      tryFilter(getBedroomFilter(2, "2 bedrooms"), filters) &&
      markButtonEvent(ButtonAction.SELECT, "Catalog 2 Bedroom");
    seeThreeBedroom &&
      tryFilter(getBedroomFilter(3, "3 bedrooms"), filters) &&
      markButtonEvent(ButtonAction.SELECT, "Catalog 3 Bedroom");
  };

  return (
    <ProductDiscoveryStepContent>
      <PageElement size={4} height={"auto"}>
        <QuestionIcon>
          <GiHouse />
        </QuestionIcon>
        <Question>What type of units are you interested in?</Question>
        <QuestionHelper>Choose all that apply.</QuestionHelper>
      </PageElement>
      <PageElement size={4} height={"auto"}>
        <NatToggle
          label={"1 bedroom"}
          trackingId={"catalog-form-1-bedroom"}
          enabled={seeOneBedroom}
          setEnabled={setSeeOneBedroom}
          option={StyleOption.LIGHT_TOGGLE}
        />
      </PageElement>
      <PageElement size={4} height={"auto"}>
        <NatToggle
          label={"2 bedroom"}
          trackingId={"catalog-form-2-bedroom"}
          enabled={seeTwoBedroom}
          setEnabled={setSeeTwoBedroom}
          option={StyleOption.LIGHT_TOGGLE}
        />
      </PageElement>
      <PageElement size={4} height={"auto"}>
        <NatToggle
          label={"3 bedroom"}
          trackingId={"catalog-form-3-bedroom"}
          enabled={seeThreeBedroom}
          setEnabled={setSeeThreeBedroom}
          option={StyleOption.LIGHT_TOGGLE}
        />
      </PageElement>
      <PageElement size={4} height={"auto"}>
        <NatButton
          label={"See recommendations"}
          type={"button"}
          trackingId={"catalog-form-see-recommendations"}
          clickEvent={submitFilters}
          disabled={!seeThreeBedroom && !seeTwoBedroom && !seeOneBedroom}
          spinnerEnabled={true}
        />
      </PageElement>
    </ProductDiscoveryStepContent>
  );
};
