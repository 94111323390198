import React from "react";
import {isMobile} from "../../_shared/navigation";

export function ThankYouLayout() {
	const style = {
		height: "100%",
		width: "60%",
	};
	let paddingTop = "100px";

	if (isMobile()) {
		style.height = "50%";
		style.width = "100%";
		paddingTop = "20px";
	}

	let svgClass = "bigCircleCheck";
	let checkboxClass = "bigCircleCheck visited";

	let checkBoxCircle = (
		<div className={checkboxClass}>
			<svg
				viewBox="0 0 100 100"
				className={svgClass}
				preserveAspectRatio="xMidYMin"
			>
				<polyline points="25 50.75 45 75 80 30"></polyline>
			</svg>
		</div>
	);

	return (
		<div className="app-wrapper">
			<div className="custom-row" style={{height: "100vh"}}>
				<div style={style}>
					<img
						src={
							"https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Fstep-images%2Fcontact-min.png?alt=media&token=b52c7a4f-1692-4294-b02a-1a060b26f06c"
						}
						style={{
							objectFit: "cover",
							objectPosition: "top",
							height: "100%",
							width: "100%",
						}}
					/>
				</div>

				<center
					style={{
						flexGrow: 2,
						width: "10%",
						padding: "20px",
						paddingTop: paddingTop,
					}}
				>
					{checkBoxCircle}
					<br />
					<h2>We received your information</h2>
					<br />
					<p className="confirmation-text" style={{maxWidth: "500px"}}>
						We are excited to chat with you soon. One of our team members will
						get back to you shortly with ADU feasibility.
					</p>

					<p style={{maxWidth: "500px"}}>
						Know someone who could be interested?
					</p>

					<br />
					<a
						href={"https://www.villahomes.com/referrals"}
						className={"black-btn link"}
					>
						Refer a friend
					</a>
					<br />
					<br />
					<div>
						<input
							type="button"
							value="Back to design"
							className={"button-secondary"}
							onClick={() => {
								window.history.back();
							}}
						/>
					</div>
				</center>
			</div>
		</div>
	);
}
