import {isProd} from "./components/_shared/application";

export const DEV_LOGGER = !isProd;

const label = "Logger: ";

export const devLogger = (data: any) => {
	if (DEV_LOGGER) {
		console.log(label, data);
	}
};
