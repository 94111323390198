import React, {Dispatch, SetStateAction, useEffect} from "react";
import {bulkUpdateProductGroupModifiers} from "../../../../design-tool/backend/catalogApi";
import {NavigationButtons} from "../../../../_shared/generics/header/styles";
import {NatButton} from "../../../../_shared/generics/button";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {getProductModifierUpdates} from "./utilities";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {Header} from "../../../../_shared/generics/header";
import {NatDropdown} from "../../../../_shared/generics/dropdown/NatDropdown";
import {NatDropdownOption} from "../../../../_shared/generics/dropdown/NatDropdownOption";

export interface MatrixHeaderProps {
  matrixEdits: any;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  matrixField: string;
  setMatrixField: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const MatrixHeader = (props: MatrixHeaderProps) => {
  const {matrixEdits, setMatrixEdits, matrixField, setMatrixField, setLoading} =
    props;
  const width = useDynamicValue(fullColumnWidths);
  useEffect(() => {
    if (matrixEdits == null) setLoading(false);
  }, [matrixEdits]);

  const resetEdits = () => {
    setMatrixEdits(null);
  };

  const saveChanges = () => {
    setLoading(true);
    const dbUpdates = getProductModifierUpdates(matrixEdits);
    bulkUpdateProductGroupModifiers(dbUpdates).then(() => {
      resetEdits();
    });
  };

  const getTitle = (matrixField: string) => {
    switch (matrixField) {
      case "prices":
        return "Prices";
      case "description":
        return "Descriptions";
      case "price-dependencies":
        return "Price Dependencies";
      default:
        return "Unknown";
    }
  };

  const discardChanges = () => {
    resetEdits();
  };

  return (
    <>
      <Header
        width={width}
        height={"4rem"}
        title={"Modifier × Product"}
        noPadding={true}
      >
        <div style={{marginRight: "auto"}}>
          <NatDropdown
            label={getTitle(matrixField)}
            options={[
              {label: "Prices", value: "prices"},
              {label: "Descriptions", value: "description"},
              {label: "Price Dependencies", value: "price-dependencies"},
            ]}
            trackingId={"admin-matrix-table-views"}
            callBack={(option: NatDropdownOption) => {
              setMatrixField(option.value);
            }}
            disabled={false}
          />
        </div>
        <NavigationButtons>
          <NatButton
            disabled={matrixEdits == null}
            type={"button"}
            clickEvent={discardChanges}
            option={StyleOption.SECONDARY}
            label={"Discard Changes"}
            trackingId={"admin-matrix-discard-changes"}
            size={TextSize.SMALL}
          />
          <NatButton
            disabled={matrixEdits == null}
            type={"button"}
            clickEvent={saveChanges}
            label={"Save Changes"}
            trackingId={"admin-matrix-save-changes"}
            size={TextSize.SMALL}
          />
        </NavigationButtons>
      </Header>
    </>
  );
};
