import React from "react";
import "../styles.scss";
import {isBlankString} from "@natomas/core";
import {DesignButton, TextContainer, ImageContainer} from "./styles";
import styled from "styled-components";

export const FullColorTextButton = (props) => {
  return (
    <ColorTextButtonWrapper onClick={props.onClick} active={props.isSelected}>
      <ImageContainer
        width={"54px"}
        style={{
          background: isBlankString(props.secondColor)
            ? props.firstColor
            : `linear-gradient(135deg, ${props.firstColor} 50%, ${props.secondColor} calc(50% + 1px))`,
          minHeight: "54px",
        }}
      />
      <TextContainer>
        <p>{props.buttonText}</p>
        <p>{props.price}</p>
      </TextContainer>
    </ColorTextButtonWrapper>
  );
};

const ColorTextButtonWrapper = styled(DesignButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 18px;
  transition: 0.25s all;
`;
