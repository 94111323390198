export const SITE_PREP_LINE_ITEM_ID = "sitePrepAndFoundation";
export const INSTALLATION_LINE_ITEM_ID = "installation";
export const DELIVERY_LINE_ITEM_ID = "delivery";

export const SITE_WORK_LINE_ITEM_IDS = {
  phase1: {
    title: "Phase 1 Payment(s)",
    index: 0,
  },
  permit: {
    title: "Permit Approval",
    index: 1,
  },
  [SITE_PREP_LINE_ITEM_ID]: {
    title: "Site Prep & Foundation",
    index: 2,
  },
  [INSTALLATION_LINE_ITEM_ID]: {
    title: "Installation",
    index: 3,
  },
  power: {
    title: "Power",
    index: 4,
  },
  sewer: {
    title: "Sewer",
    index: 5,
  },
  water: {
    title: "Water",
    index: 6,
  },
  [DELIVERY_LINE_ITEM_ID]: {
    title: "Delivery",
    index: 7,
  },
};
