import {TeamMemberContactInfo} from "@natomas/core";
import {isMobile} from "../../../../_shared/navigation";
import React from "react";
import {teamContact} from "../../../_shared/models/ProjectModels";
import alana_headshot from "../../../../../assets/images/headshots/alana_headshot.jpeg";
import sean_headshot from "../../../../../assets/images/headshots/sean_headshot.jpg";
import jeremy_headshot from "../../../../../assets/images/headshots/jeremy_headshot.jpeg";
import ben_headshot from "../../../../../assets/images/headshots/ben_headshot.jpg";
import stephanie_headshot from "../../../../../assets/images/headshots/stephanie_headshot.png";
import james_headshot from "../../../../../assets/images/headshots/james.jpeg";
import zephan_headshot from "../../../../../assets/images/headshots/zephan_headshot.jpg";
import nish_headshot from "../../../../../assets/images/headshots/nish_headshot.jpg";
import {TeamContact} from "./TeamContact";
import {
  DefaultContactsContainer,
  MobileDefaultContactsContainer,
  MobileTeamContactsContainer,
  TeamContactsContainer,
  TeamContainer,
  TeamContainerMobile,
} from "../styles";
import blank from "../../../../../assets/images/headshots/blank.png";

const LOCKED_CONTACT = teamContact(
  "Unlock",
  "Specialist",
  "Home Consultant",
  blank
);

const DEFAULT_TEAM_CONTACTS = [
  teamContact("Alana", "Davis", "Sales Manager", alana_headshot),
  teamContact("Sean", "Blasy", "Project Coordinator Manager", sean_headshot),
  teamContact("Nish", "Budhraja", "Head of Product", nish_headshot),
  teamContact(
    "Jeremy",
    "Pearman",
    "Director of Site Operations",
    jeremy_headshot
  ),
  teamContact("Ben", "Nelson", "Head of Project Operations", ben_headshot),
  teamContact(
    "Stephanie",
    "Lee",
    "Director of Preconstruction",
    stephanie_headshot
  ),
  teamContact("James", "Connolly", "Co-Founder & CEO", james_headshot),
  teamContact("Zephan", "McMinn", "Co-Founder & COO", zephan_headshot),
];

export const TeamContacts = (props: {
  teamContacts?: TeamMemberContactInfo[];
}) => {
  const {teamContacts} = props;
  const genericTeamContacts = (
    <>
      {DEFAULT_TEAM_CONTACTS.map((contact, index) => (
        <TeamContact key={`default_${index}`} contact={contact} />
      ))}
    </>
  );

  let customerTeamContactsContent = null;
  if (teamContacts) {
    customerTeamContactsContent = (
      <>
        {teamContacts.map((contact, index) => (
          <TeamContact
            key={`team_${index}`}
            contact={Object.assign({title: "Home Consultant"}, contact)}
          />
        ))}
      </>
    );
  }

  if (isMobile()) {
    return (
      <TeamContainerMobile>
        <MobileTeamContactsContainer
          hidden={teamContacts == null || teamContacts.length < 1}
        >
          {customerTeamContactsContent}
        </MobileTeamContactsContainer>
        <MobileDefaultContactsContainer>
          {genericTeamContacts}
        </MobileDefaultContactsContainer>
      </TeamContainerMobile>
    );
  }

  return (
    <TeamContainer>
      <TeamContactsContainer
        hidden={teamContacts == null || teamContacts.length < 1}
      >
        {customerTeamContactsContent}
      </TeamContactsContainer>
      <DefaultContactsContainer
        fullWidth={teamContacts && teamContacts.length < 1}
      >
        {genericTeamContacts}
      </DefaultContactsContainer>
    </TeamContainer>
  );
};
