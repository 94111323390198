import waiting_graphic from "../../../../../assets/images/graphics/waiting_graphic.png";
import React from "react";
import laptop from "../../../../../assets/images/graphics/laptop.png";
import styled from "styled-components";

const MainContainer = styled.div`
	margin-bottom: 0px;
`;

export const SiteWorkWaitingGraphic = (props) => {
	const {status} = props;

	switch (status) {
		case "new":
			return (
				<MainContainer style={{display: "flex", justifyContent: "center"}}>
					<div style={{textAlign: "center", maxWidth: "428px"}}>
						<img
							src={waiting_graphic}
							alt={"Site Plan Image Missing"}
							width={"240px"}
						/>
						<br />
						<div className={"large-text bold"} style={{marginBottom: "10px"}}>
							Almost there
						</div>
						<div style={{marginBottom: "24px"}}>
							Our team is working on your budget summary and project timeline.
							We will let you know the details shortly!
						</div>
					</div>
				</MainContainer>
			);
		case "pending":
			return (
				<MainContainer
					style={{
						display: "flex",
						justifyContent: "center",
						marginBottom: "50px",
					}}
				>
					<div
						style={{
							maxWidth: "800px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<div
							style={{
								marginRight: "30px",
							}}
						>
							<img
								src={laptop}
								alt={"Site Plan Image Missing"}
								width={"240px"}
							/>
						</div>
						<div style={{textAlign: "left", height: "fit-content"}}>
							<div
								className={"large-text bold"}
								style={{marginBottom: "10px", fontSize: "25px"}}
							>
								Breakdown will be published during our call
							</div>
							<div>
								Our team will sit down with you to discuss your project in great
								detail. Afterwards, you will be able to view your site work
								budget summary here anytime.
							</div>
						</div>
					</div>
				</MainContainer>
			);
	}

	return null;
};
