import firebase from "firebase";
import "firebase/auth";
import {Configuration, Utilities} from "../../../database/firebase/";
import {
  getAllCurrentModifierGroups,
  getModifierGroup,
  getOrderSummary,
  getRenderingsFromSummary,
  isModifierCurrentlyValid,
} from "../logic/ConfigurationPageHelper";
import {SHOW_MULTI_SELECT_PROPERTY_KEY} from "../logic/Constants";
import {batch} from "react-redux";
import {isBlankString} from "@natomas/core";
import {
  loadedConfiguration,
  loadedConfigurationMap,
  loadedConfigurationOrder,
  loadedConfigurationSite,
  loadedConfigurationSnapshot,
  loadedProjectOpportunity,
  loadedProjectSummary,
  selectModifier,
  setConfigurationId,
  setEditingSelections,
  setSaveInProgress,
  setSelectionsFromConfiguration,
} from "../slices/configurationSlice";
import {store} from "../../../store";
import history from "../../_shared/navigation/history";
import {executeRequest} from "../../../database/firebase/api";
import {auth} from "../../../database/firebase";
import {allowFetchFromUser} from "@natomas/core";
import {devLogger} from "../../../.developerSettings";
import {subscribeWithCallback} from "../../../database/firebase/utilities";
import {
  PROJECT_COLLECTION_KEY,
  PROJECT_OPPORTUNITY_COLLECTION_KEY,
} from "../../../database/firebase/project/constants";
import {CONFIGURATION_SNAPSHOTS_DB_KEY} from "../../../database/firebase/configuration/configuration";

function getConfigurationAPI(endpoint) {
  return firebase.functions().httpsCallable("configuration-" + endpoint);
}

export const saveConfigurationAPI = (configuration) => {
  const api = getConfigurationAPI("saveConfiguration");
  return api(configuration)
    .then((success) => {
      return success.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export const saveConfigurationAPIV2 = (configuration) => {
  const api = getConfigurationAPI("saveConfigurationV2");
  return api(configuration)
    .then((success) => {
      return success.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export const submitFeedback = (configuration, feedback) => {
  const configurationId = configuration.id;
  Utilities.collection("designStudioFeedback")
    .doc(configurationId)
    .set({
      rating: feedback.score,
      comment: feedback.comment,
      opportunityId:
        configuration.opportunityId != null
          ? configuration.opportunityId
          : null,
    })
    .then(() => {
      localStorage.setItem("designFeedback", feedback.score);
    });
};

export const getFeedback = () => {
  return Utilities.collection("designStudioFeedback")
    .get()
    .then((results) => {
      return Utilities.convertCollectionSnapshotToMap(results);
    });
};

const configurationsLoaded = {};
export function validateAndResetSelections(modifiers) {
  const state = store.getState();
  const selectedModifiers =
    modifiers != null ? modifiers : state.configuration.selectedModifiers;
  const modifierGroups = getAllCurrentModifierGroups(state);
  const productId = state.catalog.productId;
  const batchedUpdates = [];
  Object.values(modifierGroups).forEach((modifierGroup) => {
    const modifiers = modifierGroup?.modifiers;
    if (modifiers != null && modifiers.length > 0) {
      // Reset if no modifiers are selected
      const noSelectedModifiers = modifiers.every((modifierId) => {
        return selectedModifiers[modifierId] !== true;
      });

      let needsToReset = noSelectedModifiers;

      if (!needsToReset) {
        // Reset if any of the selected modifiers are invalid
        needsToReset = modifiers.some((modifierId) => {
          return (
            selectedModifiers[modifierId] === true &&
            !isModifierCurrentlyValid(
              state,
              state.catalog.productId,
              modifierId
            )
          );
        });
      }

      if (modifierGroup.properties?.[SHOW_MULTI_SELECT_PROPERTY_KEY] === true) {
        needsToReset = false;
      }

      if (needsToReset) {
        let reset = false;
        for (const modifierId of modifiers) {
          if (isModifierCurrentlyValid(state, productId, modifierId)) {
            batchedUpdates.push(
              selectModifier({
                modifier: {id: modifierId},
                modifierGroup: modifierGroup,
                reset: true,
              })
            );
            reset = true;
            break;
          }
        }

        // In the case that all modifiers are invalid, just select the first one.
        if (!reset && modifiers.length > 0 && noSelectedModifiers) {
          batchedUpdates.push(
            selectModifier({
              modifier: {id: modifiers[0]},
              modifierGroup: modifierGroup,
              reset: true,
            })
          );
        }
      }
    }
  });

  batch(() => {
    batchedUpdates.forEach((action) => {
      store.dispatch(action);
    });
  });
}

export const resetSelections = async (fromConfiguration) => {
  const state = store.getState();

  let selectedModifiers = state.configuration.selectedModifiers;
  let configuration =
    fromConfiguration != null
      ? fromConfiguration
      : state.configuration.fetchedConfiguration;

  if (configuration != null) {
    const configurationSelections = configuration.selectedModifiers;
    if (configurationSelections != null) {
      const newSelectedModifiers = {};
      Object.keys(configurationSelections).forEach((modifierId) => {
        newSelectedModifiers[modifierId] = true;
      });
      selectedModifiers = newSelectedModifiers;
    }
    store.dispatch(setSelectionsFromConfiguration(configuration));
  }
  validateAndResetSelections(selectedModifiers);
};

export const saveConfiguration = async (formik) => {
  store.dispatch(setSaveInProgress("saving"));
  const state = store.getState();
  const orderSummary = getOrderSummary(state);
  const currentConfigurationId = state.configuration.currentConfigurationId;
  if (currentConfigurationId != null) {
    orderSummary.configurationId = currentConfigurationId;
  }

  const modifierGroupNotes = {};
  Object.keys(formik.values).forEach((key) => {
    const value = formik.values[key];
    if (key.startsWith("note-") && !isBlankString(value)) {
      const modifierGroupId = key.substring(5);
      const modifierGroup = getModifierGroup(
        state,
        orderSummary.product.id,
        modifierGroupId
      );

      modifierGroupNotes[modifierGroupId] = {
        value: formik.values[key],
        name: modifierGroup.title,
      };
    }
  });
  orderSummary.modifierGroupNotes = modifierGroupNotes;
  const {images} = getRenderingsFromSummary(orderSummary);
  images.unshift({
    imageId: orderSummary.product.imageId,
    configurationPageId: "product",
    caption: orderSummary.product.title,
  });
  orderSummary.images = images.map((imageObj) => {
    const image = state.global.images[imageObj.imageId];
    if (image != null) {
      imageObj.url = image["2500"].url;
    }
    if (imageObj.overlayImages != null) {
      imageObj.overlayImages.forEach((overlayImg) => {
        const overlayImage = state.global.images[overlayImg.imageId];
        if (overlayImage != null) {
          overlayImg.url = overlayImage["2500"].url;
        }
      });
    }
    return imageObj;
  });

  const configurationId = await saveConfigurationAPI(orderSummary);
  if (configurationId == null) {
    return null;
  }

  store.dispatch(setConfigurationId(configurationId));
  if (currentConfigurationId == null) {
    setConfigurationIdInHistory(configurationId, history);
    await fetchConfiguration(configurationId);
  }
  store.dispatch(setEditingSelections(false));
  store.dispatch(setSaveInProgress("saved"));

  return configurationId;
};

const setConfigurationIdInHistory = (configurationId, history) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set("cid", configurationId);
  history.push({pathname: pathname, search: searchParams.toString()});
};

export const loadConfiguration = async (forceFetch, projectId) => {
  const currentConfigurationId =
    projectId ?? store.getState().configuration.currentConfigurationId;
  if (currentConfigurationId != null) {
    const currentUser = store.getState().global.user;
    if (
      allowFetchFromUser(currentUser?.uid, currentConfigurationId, forceFetch)
    ) {
      return fetchConfiguration(currentConfigurationId);
    }
  }
};

export async function fetchConfiguration(configurationId) {
  const configurationsRef = Utilities.collection("configurations");
  configurationsRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configuration = snapshot.data();
      if (configuration != null) {
        configuration.id = snapshot.id;
        resetSelections(configuration);
        if (
          configurationsLoaded[snapshot.id] !== true &&
          configuration.product != null
        ) {
          configurationsLoaded[snapshot.id] = true;
        }

        if (configuration.configurationSnapshot != null) {
          store.dispatch(
            loadedConfigurationSnapshot({
              snapshot: configuration.configurationSnapshot,
              id: snapshot.id,
            })
          );
        }
      }
      store.dispatch(loadedConfiguration(configuration));
    },
    (error) => {
      console.log(error);
    }
  );

  const configurationSnapshotsRef = Utilities.collection(
    CONFIGURATION_SNAPSHOTS_DB_KEY
  );
  configurationSnapshotsRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configuration = snapshot.data();
      if (configuration != null) {
        configuration.id = snapshot.id;
        store.dispatch(setEditingSelections(false));
        store.dispatch(loadedConfigurationSnapshot(configuration));
      } else {
        store.dispatch(setEditingSelections(true));
        store.dispatch(loadedConfigurationSnapshot(null));
      }
    },
    (error) => {
      console.log(error);
    }
  );

  subscribeWithCallback(
    Utilities.collection(PROJECT_OPPORTUNITY_COLLECTION_KEY).doc(
      configurationId
    ),
    (data) => {
      store.dispatch(loadedProjectOpportunity(data));
    }
  );

  subscribeWithCallback(
    Utilities.collection(PROJECT_COLLECTION_KEY).doc(configurationId),
    (data) => {
      store.dispatch(loadedProjectSummary(data));
    }
  );

  const configurationMapRef = Utilities.collection(
    Configuration.Constants.CONFIGURATION_MAPS_DB_KEY
  );
  configurationMapRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configurationMap = snapshot.data();
      if (configurationMap != null) {
        configurationMap.id = snapshot.id;
      }
      store.dispatch(loadedConfigurationMap(configurationMap));
    },
    (error) => {
      console.log(error);
    }
  );

  const configurationSiteRef = Utilities.collection(
    Configuration.Constants.CONFIGURATION_SITE_DB_KEY
  );
  configurationSiteRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configurationSite = snapshot.data();
      if (configurationSite != null) {
        configurationSite.id = snapshot.id;
      }
      store.dispatch(loadedConfigurationSite(configurationSite));
    },
    (error) => {
      console.log(error);
    }
  );

  const configurationOrderRef = Utilities.collection(
    Configuration.Constants.CONFIGURATION_ORDER_DB_KEY
  );
  configurationOrderRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configurationOrder = snapshot.data();
      if (configurationOrder != null) {
        configurationOrder.id = snapshot.id;
      }
      store.dispatch(loadedConfigurationOrder(configurationOrder));
    },
    (error) => {
      console.log(error);
    }
  );

  if (auth.currentUser) {
    return executeRequest("/project/v1/synchronize", {
      projectId: configurationId,
    }).then((data) => {
      devLogger(data);
    });
  }
}

export const generateProjectPDF = (configurationId) => {
  return executeRequest("/project/v1/generatePDF", {
    projectId: configurationId,
  }).then((data) => {
    return data?.pdfStoragePath;
  });
};
