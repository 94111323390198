import React from "react";
import {useSelector} from "react-redux";
import {ButtonForm} from "../components/buttonForm";

export const UNIT_TYPE_KEY = "unit_type";
export const DETACHED_PLACEMENT = "detached";
export const ATTACHED_PLACEMENT = "attached";

export const StepOne = (props) => {
	//save the useDispatch as a variable to then push updates to state
	const selectedValue = useSelector((state) => state.intakeForm.questionOne);
	//simple variable used to save the value of data-attribute nextStep from button that was clicked
	//No need to create a state for this since it's value wouldnt be saved to the store

	//call ButtonSelectRadio to handle the selection of hidden radio input for the question and grab nextStep data-attribute from button
	//Also will find the submit button and click it
	const onClick = props.clickFormButton;

	//This array holds all the information for the buttons that are needed to be created for the question. Onclick is passed as a function as well
	const inputs = [
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "Build a stand alone unit",
			nextStep: "step-two",
			radioID: "first-option",
			radioValue: DETACHED_PLACEMENT,
			radioName: UNIT_TYPE_KEY,
		},
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "Convert an existing garage/space",
			nextStep: "step-two",
			radioID: "second-option",
			radioValue: ATTACHED_PLACEMENT,
			radioName: UNIT_TYPE_KEY,
		},
	];

	return (
		<ButtonForm
			inputs={inputs}
			question="What type of unit are you interested in?"
			selectedValue={selectedValue}
		/>
	);
};
