import {isBlankString} from "@natomas/core";
import {SmartImageWithId} from "./SmartImage";

export const CarouselImage = (props) => {
  const {
    imageId,
    caption,
    overlayImages,
    orientation,
    objectFit,
    index,
    hideCaption,
  } = props;

  if (overlayImages != null) {
    return (
      <>
        {overlayImages.map((image, index) => {
          const {imageId} = image;
          const style = {
            objectFit: objectFit,
          };
          if (orientation === "xFlipped") {
            style.transform = "scaleX(-1)";
          }
          if (index === 0) {
            const firstStyle = Object.assign({zIndex: 0}, style);
            return (
              <SmartImageWithId
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
                imageId={imageId}
                caption={caption}
                hideCaption={hideCaption}
                style={firstStyle}
                alt="rendering"
                blur={false}
                shouldNotFade={true}
                key={"rendering" + index + objectFit}
              />
            );
          }
          const shouldFade =
            caption != null && caption.indexOf("Orientation") >= 0;
          let key = "overlay" + index + objectFit;
          if (shouldFade) {
            key = "overlay" + imageId;
          }
          return (
            <div
              className="fullDiv"
              style={{position: "absolute", zIndex: index}}
              key={key}
            >
              <SmartImageWithId
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
                imageId={imageId}
                style={style}
                alt="rendering"
                blur={false}
                shouldNotFade={!shouldFade}
                hideLoading={true}
              />
            </div>
          );
        })}
      </>
    );
  }

  const objectFitValue = isBlankString(objectFit) ? "cover" : objectFit;
  const style = {
    objectFit: objectFitValue,
  };

  return (
    <SmartImageWithId
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      imageId={imageId}
      caption={caption}
      hideCaption={hideCaption}
      shouldNotFade={true}
      blur={false}
      style={style}
      key={"rendering" + index + objectFitValue}
      alt="rendering"
    />
  );
};
