import {getPriceMicrosFromTextToSave, isBlankString} from "@natomas/core";
import React from "react";

export const productGroupLevelKey = "productGroup";

export const getProductModifierUpdates = (edits: any) => {
  const productBreakdown = {};
  Object.keys(edits).forEach((key) => {
    let editMap = edits[key];
    const [destination, modifierId] = key.split("_");
    // @ts-ignore
    if (productBreakdown[destination] == null) {
      // @ts-ignore
      productBreakdown[destination] = {};
    }

    Object.keys(editMap).forEach((editKey) => {
      const editValue = editMap[editKey];
      let modifierValue = editValue;
      let modifierKey = editKey;
      if (editKey === "prices") {
        if (!isBlankString(editValue)) {
          modifierValue = getPriceMicrosFromTextToSave(editValue);
        } else {
          modifierValue = null;
        }
        modifierKey =
          destination === productGroupLevelKey
            ? "defaultPriceMicros"
            : "priceMicros";
      } else if (editKey === "description") {
        modifierKey =
          destination === productGroupLevelKey
            ? "displayDescriptionDefault"
            : "displayDescription";
      } else if (editKey === "price-dependencies") {
        modifierKey =
          destination === productGroupLevelKey ? "defaultPrices" : "prices";
      }

      // @ts-ignore
      productBreakdown[destination][modifierId] = {
        [modifierKey]: modifierValue,
      };
    });
  });

  return productBreakdown;
};

export const transposeTable = (m: any) =>
  m[0].map((x: any, i: number) => m.map((x: any) => x[i]));

export const priceMicrosToMatrix = (priceMicros: number) => {
  if (priceMicros == null) {
    return "";
  }
  return "" + priceMicros / 100;
};

export const getPriceValue = (entry: string | null) => {
  if (!entry || isBlankString(entry)) {
    return 0;
  }
  const strippedText = entry.replace(/\$|,/g, "");
  const parsedFloat = parseFloat(strippedText);
  if (isNaN(parsedFloat)) {
    return 0;
  }
  return Math.round(parsedFloat * 100) / 100;
};

export const initialAndEditedStatesMatch = (initial: any[], edited: any[]) => {
  return JSON.stringify(initial) === JSON.stringify(edited);
};
