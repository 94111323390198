export const MeasureIcon = ({ isActive }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={isActive ? "#000" : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.02539 15.9758L8.89021 15.111L15.8088 8.19246"
      stroke={isActive ? "#fff" : "#000"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      r="2.91876"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 17.8722 6.12671)"
      fill={isActive ? "#000" : "none"}
      stroke={isActive ? "#fff" : "#000"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      r="2.91876"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 6.12776 17.8721)"
      fill={isActive ? "#000" : "none"}
      stroke={isActive ? "#fff" : "#000"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
