import React from "react";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";
import {
  ADUImage,
  ADUBlurbContainer,
  ADUBlurbTitle,
  ADUBlurbText,
  ADUBlurbDescription,
} from "../styles";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {usePage} from "../../../../_shared/hooks/usePage";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {toCatalog} from "../../../../_shared/navigation";
import ReactTooltip from "react-tooltip";

interface YourADUProps {
  title: string;
  imageId: string;
  tagline: string;
  description: string;
  size: number;
  showChangeButton: boolean;
}

export const ADUBlurb = (props: YourADUProps) => {
  const {columnCount} = usePage();
  const {title, imageId, tagline, description, size, showChangeButton} = props;
  return (
    <ADUBlurbContainer removeRightMargins={columnCount === size}>
      <PageElement size={size} height={"auto"}>
        <ADUImage>
          <SmartImageWithId imageId={imageId} />
        </ADUImage>
      </PageElement>
      <PageElement size={size} height={"auto"}>
        <ADUBlurbTitle>{title}</ADUBlurbTitle>
        <ADUBlurbText>{tagline}</ADUBlurbText>
        <ADUBlurbDescription>{description}</ADUBlurbDescription>
        {showChangeButton && (
          <>
            <div
              data-tip
              data-for={"tooltip-change-adu"}
              style={{width: "100%"}}
            >
              <NatButton
                style={{
                  alignSelf: "center",
                  width: "100%", //changeADUButtonWidthPercent + "%",
                }}
                trackingId={"payments-change-home"}
                type={"button"}
                label={"Change home"}
                option={StyleOption.WhiteWillFillBlack}
                clickEvent={toCatalog}
                spinnerEnabled={false}
              />
            </div>
            <ReactTooltip
              place={"bottom"}
              id={"tooltip-change-adu"}
              effect="solid"
            >
              Choose your favorite, you can change your mind even after deposit
            </ReactTooltip>
          </>
        )}
      </PageElement>
    </ADUBlurbContainer>
  );
};
