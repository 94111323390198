import ExploreHomesImage from "../assets/journey-explore-homes.png";
import MapAduImage from "../assets/MappingStepGraphic.png";
import SubmitContactImage from "../assets/ContactInfoStepGraphic.png";
import SubmitDepositImage from "../assets/PaymentGraphic.png";
import {
  DESIGN_PAGE_ID,
  OVERVIEW_ASSESSMENT_PAGE_ID,
  PROFILE_PAGE_ID,
  PROPOSAL_PAGE_ID,
  SITE_PAGE_ID,
  toPage,
  UNIT_PAGE_ID,
} from "../../../_shared/navigation/PageNavigation";
import React from "react";
import {BsPencil} from "react-icons/bs";
import {FiClipboard} from "react-icons/fi";
import {CUSTOMER_STATUS} from "../../../../_shared/hooks/useCurrentCustomer";
import {
  HiOutlineClipboardCheck,
  HiOutlinePresentationChartLine,
} from "react-icons/hi";
import {toCatalog, toDesignStudio} from "../../../../_shared/navigation";
import {GiCrane} from "react-icons/gi";
import {RiBuilding3Line} from "react-icons/ri";

const SUBMIT_TITLE = "Get estimate";
const COMPLETED_PROJECT_SUBMISSION_TITLE = "Proposal unlocked";
const PROJECT_PROPOSAL_TITLE = "Project proposal";

export enum JourneyStepType {
  SUBMIT_CONTACT = "SUBMIT_CONTACT",
  SELECT_PRODUCT = "SELECT_PRODUCT",
  COMPLETE_MAPPING = "COMPLETE_MAPPING",
  INITIAL_PROJECT_SUBMISSION = "INITIAL_PROJECT_SUBMISSION",
  COMPLETE_DESIGN_STUDIO = "COMPLETE_DESIGN_STUDIO",
  SITE_PLAN_UPLOADED = "SITE_PLAN_UPLOADED",
  COMPLETE_PROJECT_SUBMISSION = "COMPLETE_PROJECT_SUBMISSION",
}

export const getJourneyList = (
  contactInfoStatus: CUSTOMER_STATUS,
  productSelectedStatus: CUSTOMER_STATUS,
  mappingStatus: CUSTOMER_STATUS,
  depositPaidStatus: CUSTOMER_STATUS,
  designStudio: CUSTOMER_STATUS,
  sitePlan: CUSTOMER_STATUS,
  proposal: CUSTOMER_STATUS,
  isMappingEnabled: boolean,
  isCustomerPreDeposit: boolean,
  pagination: number
) => {
  if (pagination > 0) {
    return [
      depositStep(depositPaidStatus),
      completeDesignStudio(
        isCustomerPreDeposit ? CUSTOMER_STATUS.DISABLED : designStudio,
        productSelectedStatus
      ),
      sitePlanUploaded(
        isCustomerPreDeposit ? CUSTOMER_STATUS.DISABLED : sitePlan
      ),
      completeProjectSubmission(
        isCustomerPreDeposit ? CUSTOMER_STATUS.DISABLED : proposal
      ),
    ];
  }

  const arrayToReturn = [
    submitContactInfoStep(contactInfoStatus),
    productSelectionStep(productSelectedStatus, isCustomerPreDeposit),
  ];
  if (isMappingEnabled) {
    arrayToReturn.push(mappingStep(mappingStatus));
  }
  arrayToReturn.push(depositStep(depositPaidStatus)); // is there a payment event

  return arrayToReturn;
};

export interface IJourneyStep {
  type: JourneyStepType;
  icon: JSX.Element;
  status: CUSTOMER_STATUS;
  menuHeader: string;
  menuSubheader: string;
  actionEvent: any | null;
  detailHeader: string;
  detailSubheader: string;
  detailClass: string;
  buttonText: string;
  imageSrc: any;
}

const submitContactInfoStep = (status: CUSTOMER_STATUS): IJourneyStep => {
  const complete = status === CUSTOMER_STATUS.COMPLETED;
  return {
    type: JourneyStepType.SUBMIT_CONTACT,
    icon: <BsPencil />,
    status: status,
    menuHeader: "Submit contact info",
    menuSubheader: "contact info",
    actionEvent: () => {
      toPage(PROFILE_PAGE_ID);
    },
    detailHeader: "Submit contact info",
    detailSubheader: complete
      ? "You can update your project and personal information anytime."
      : "Please confirm your first and last name and project address are correct using the button below.",
    detailClass: "ToDo?",
    buttonText: complete ? "Edit your info" : "Verify your info",
    imageSrc: SubmitContactImage,
  };
};

const productSelectionStep = (
  status: CUSTOMER_STATUS,
  isCustomerPreDeposit: boolean
): IJourneyStep => {
  const complete = status === CUSTOMER_STATUS.COMPLETED;
  return {
    type: JourneyStepType.SELECT_PRODUCT,
    icon: <RiBuilding3Line />,
    status: status,
    menuHeader: "Browse our homes",
    menuSubheader: "homes",
    actionEvent: () => {
      if (!isCustomerPreDeposit) {
        toPage(DESIGN_PAGE_ID);
      } else if (complete) {
        toPage(UNIT_PAGE_ID);
      } else {
        toCatalog();
      }
    },
    detailHeader: "Browse our homes",
    detailSubheader: complete
      ? "Great choice! You can change your mind even after you submit for your proposal."
      : "Browse our 1, 2, and 3 bedroom homes and find the right ADU for you.",
    detailClass: "ToDo?",
    buttonText: complete ? "Review your home" : "Browse our homes",
    imageSrc: ExploreHomesImage,
  };
};

const mappingStep = (mappingStatus: CUSTOMER_STATUS): IJourneyStep => {
  return {
    type: JourneyStepType.COMPLETE_MAPPING,
    icon: <FiClipboard />,
    status: mappingStatus,
    menuHeader: "Map home",
    menuSubheader: "property",
    actionEvent: null,
    detailHeader: "Map ADU on your property",
    detailSubheader:
      "E Pluribus Unim. In aliquet, purus quis imperdiet volutpat, augue orci viverra lacus, varius sollicitudin nisl ante in purus.",
    detailClass: "ToDo?",
    buttonText: "Map your home",
    imageSrc: MapAduImage,
  };
};

const depositStep = (status: CUSTOMER_STATUS): IJourneyStep => {
  const complete = status === CUSTOMER_STATUS.COMPLETED;
  const title = complete ? COMPLETED_PROJECT_SUBMISSION_TITLE : SUBMIT_TITLE;
  return {
    type: JourneyStepType.INITIAL_PROJECT_SUBMISSION,
    icon: <HiOutlineClipboardCheck />,
    status: status,
    menuHeader: title,
    menuSubheader: "deposit",
    actionEvent: complete
      ? null
      : () => {
          toPage(OVERVIEW_ASSESSMENT_PAGE_ID);
        },
    detailHeader: title,
    detailSubheader: complete
      ? "Successfully submitted your key information for your project."
      : "Get a consultation, custom site-plan, personalized design session, and a specific quote.",
    detailClass: "ToDo?",
    buttonText: SUBMIT_TITLE,
    imageSrc: SubmitDepositImage,
  };
};

const completeDesignStudio = (
  status: CUSTOMER_STATUS,
  productSelectedStatus: CUSTOMER_STATUS
): IJourneyStep => {
  const complete = status === CUSTOMER_STATUS.COMPLETED;
  return {
    type: JourneyStepType.COMPLETE_DESIGN_STUDIO,
    icon: <RiBuilding3Line />,
    status: status,
    menuHeader: "Design your home",
    menuSubheader: "homes",
    actionEvent: () => {
      if (complete) {
        toPage(DESIGN_PAGE_ID);
      } else if (productSelectedStatus !== CUSTOMER_STATUS.COMPLETED) {
        toCatalog();
      } else {
        toDesignStudio();
      }
    },
    detailHeader: "Design your home",
    detailSubheader: complete
      ? "Review or change your design selection"
      : "Confirm floor plan layout and upgrades to your unit",
    detailClass: "ToDo?",
    buttonText: complete ? "Review your selections" : "Start designing",
    imageSrc: ExploreHomesImage,
  };
};

const sitePlanUploaded = (status: CUSTOMER_STATUS): IJourneyStep => {
  return {
    type: JourneyStepType.SITE_PLAN_UPLOADED,
    icon: <GiCrane />,
    status: status,
    menuHeader: "Confirm site plan",
    menuSubheader: "property",
    actionEvent: () => {
      toPage(SITE_PAGE_ID);
    },
    detailHeader: "Confirm site plan",
    detailSubheader:
      status === CUSTOMER_STATUS.COMPLETED
        ? "Check out your uploaded site plan!"
        : "Hold tight, we're generating your site plan for your project.",
    detailClass: "ToDo?",
    buttonText: "View site plan",
    imageSrc: MapAduImage,
  };
};

const completeProjectSubmission = (status: CUSTOMER_STATUS): IJourneyStep => {
  return {
    type: JourneyStepType.COMPLETE_PROJECT_SUBMISSION,
    icon: <HiOutlinePresentationChartLine />,
    status: status,
    menuHeader: PROJECT_PROPOSAL_TITLE,
    menuSubheader: "deposit",
    actionEvent: () => {
      toPage(PROPOSAL_PAGE_ID);
    },
    detailHeader: PROJECT_PROPOSAL_TITLE,
    detailSubheader:
      "Congratulations, your Villa project proposal is ready for review!",
    detailClass: "ToDo?",
    buttonText: "View Proposal",
    imageSrc: SubmitDepositImage,
  };
};
