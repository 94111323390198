import React from "react";
import {
  deleteFilterByType,
  FilterType,
  getBedroomFilter,
  tryFilter,
} from "../../../../_shared/catalog/filter";
import {NatDropdown} from "../../../../_shared/generics/dropdown/NatDropdown";
import {FilterOption} from "../../../../_shared/catalog/filter";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {NatDropdownOption} from "../../../../_shared/generics/dropdown/NatDropdownOption";
import {useDynamicOptions} from "./useDynamicOptions";

export const BedroomFilter = (props: {groups: any[]}) => {
  const {filters, checkLengthWithAdditionalFilter} = useProductCatalog();
  const options = [
    {label: "1 bedroom", value: 1},
    {
      label: "2 bedrooms",
      value: 2,
    },
    {
      label: "3 bedrooms",
      value: 3,
    },
  ];
  const availableOptions = useDynamicOptions({
    options: options,
    optionType: FilterType.Bedroom,
  });
  return (
    <NatDropdown
      label={"Bedrooms"}
      options={availableOptions}
      trackingId={"bedroom-filter-dropdown"}
      conditional={(option: NatDropdownOption) => {
        return Math.abs(
          checkLengthWithAdditionalFilter(
            getBedroomFilter(option.value, option.label),
            props.groups
          )
        );
      }}
      callBack={(option: NatDropdownOption) => {
        tryFilter(getBedroomFilter(option.value, option.label), filters);
      }}
      disabledCallback={() => deleteFilterByType("bedroom")}
      disabled={
        filters.filter((f: FilterOption) => f.type === "bedroom").length ===
        options.length
      }
    />
  );
};
