import {
  SHOW_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_TEXT_DISPLAY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
  SHOW_MULTI_SECENT_DISPLAY_KEY,
  SHOW_MULTI_SELECT_PROPERTY_KEY,
} from "./Constants";
import {isBlankString} from "@natomas/core";
import {getBasePriceForDesignStudio} from "./ProductUtils";
import {getCategoriesForProduct} from "./ProductCategoryHelper";

export const getFullModifier = (state, productId, modifierId) => {
  const finalModifier = getProductModifier(state, productId, modifierId);
  if (Object.keys(finalModifier).length === 0) {
    return finalModifier;
  }
  if (finalModifier.imageId != null) {
    finalModifier.image = state.global.images[finalModifier.imageId];
  }
  finalModifier.adjustedPriceMicros = getModifierPriceMicros(
    finalModifier,
    state.configuration.selectedModifiers,
    state
  );
  return finalModifier;
};

export const getProductModifier = (state, productId, modifierId) => {
  const allProductModifiers = state.catalog.productModifiers[productId];
  if (allProductModifiers == null) {
    return {};
  }
  const productModifier =
    allProductModifiers[modifierId] == null
      ? {}
      : Object.assign({}, allProductModifiers[modifierId]);
  const generalModifier = state.catalog.modifiers[modifierId];

  let finalModifier = Object.assign({}, productModifier);
  Object.assign(finalModifier, generalModifier);

  return finalModifier;
};

export const getModifierGroup = (state, productId, modifierGroupId) => {
  const allProductModifierGroups =
    state.catalog.productModifierGroups[productId];
  if (allProductModifierGroups == null) {
    return {};
  }

  const productModifierGroup = allProductModifierGroups[modifierGroupId];
  const generalModifierGroup = state.catalog.modifierGroups[modifierGroupId];

  let finalModifierGroup = Object.assign({}, generalModifierGroup);
  if (productModifierGroup != null) {
    Object.assign(finalModifierGroup, productModifierGroup);
  }
  return finalModifierGroup;
};

export const getFullModifierGroup = (state, productId, modifierGroupId) => {
  const selectedModifiers = state.configuration.selectedModifiers;
  const modifierGroup = getModifierGroup(state, productId, modifierGroupId);

  let selectedModifierObjects = [];
  let fullModifiers = [];
  if (modifierGroup != null && modifierGroup.modifiers != null) {
    fullModifiers = modifierGroup.modifiers
      .map((modifierId) => {
        return getFullModifier(state, productId, modifierId);
      })
      .filter((modifier) => {
        let shouldCheckValidity = false;
        if (
          modifierGroup.modifierOverrides != null &&
          modifierGroup.modifierOverrides[modifier.id] != null
        ) {
          shouldCheckValidity = true;
        } else if (modifier.blockingModifiers != null) {
          shouldCheckValidity = true;
        }

        return shouldCheckValidity
          ? isModifierCurrentlyValid(state, productId, modifier.id)
          : true;
      });

    selectedModifierObjects = fullModifiers.map((modifier) => {
      if (selectedModifiers[modifier.id] === true) {
        return modifier;
      } else {
        return null;
      }
    });
  }

  return Object.assign(
    {
      selectedModifiers: selectedModifierObjects.filter((e) => e != null),
      fullModifiers: fullModifiers,
    },
    modifierGroup
  );
};

export const getCurrentProductConfigurationPage = (
  configurationPages,
  step
) => {
  if (configurationPages == null) {
    return null;
  }

  // Return the configuration page for the step if it exists
  if (configurationPages[step] != null) {
    return configurationPages[step];
  }

  return getSortedConfigurationPages(configurationPages)[0];
};

export const getSortedConfigurationPages = (configurationPages) => {
  return Object.values(configurationPages).sort(function (a, b) {
    return a.index - b.index;
  });
};

export const getSortedConfigurationPagesWithProduct = (
  configurationPages,
  product
) => {
  if (product == null) {
    return [];
  }
  return product.configuratorPages.map((pageId) => {
    // if (product.productGroupId === "adu") {
    // 	return configurationPages[product.id + pageId];
    // }
    return configurationPages[pageId];
  });
};

export const findAllModifierGroupsWithModifier = (
  state,
  productId,
  modifierId
) => {
  const configurationPagesObject = getConfigurationPagesForProduct(
    state,
    productId
  );

  const modifierGroupsToReturn = [];
  if (configurationPagesObject != null) {
    Object.values(configurationPagesObject).forEach((configurationPage) => {
      const modifierGroups = configurationPage.modifierGroups;
      if (modifierGroups != null) {
        modifierGroups.forEach((modifierGroupId) => {
          const modifierGroup = getModifierGroup(
            state,
            productId,
            modifierGroupId
          );
          if (
            modifierGroup.modifiers != null &&
            modifierGroup.modifiers.indexOf(modifierId) >= 0
          ) {
            modifierGroupsToReturn.push(modifierGroup);
          }
        });
      }
    });
  }

  return modifierGroupsToReturn;
};

export const findAllModifierGroupOverridesFromCategories = (
  state,
  productId,
  modifierGroupId
) => {
  const categories = getConfigurationPagesForProduct(state, productId);
  let overrides = {};
  if (categories != null) {
    Object.values(categories).forEach((category) => {
      const modifierGroupOverrides = category.modifierGroupOverrides;
      if (
        modifierGroupOverrides != null &&
        modifierGroupOverrides[modifierGroupId] != null
      ) {
        const override = modifierGroupOverrides[modifierGroupId];
        Object.assign(overrides, override);
      }
    });
  }

  return overrides;
};
export const findAllModifierOverrides = (parentModifierGroups, modifierId) => {
  let overrides = {};
  if (parentModifierGroups != null) {
    parentModifierGroups.forEach((modifierGroup) => {
      const modifierOverrides = modifierGroup.modifierOverrides;
      if (modifierOverrides != null && modifierOverrides[modifierId] != null) {
        const override = modifierOverrides[modifierId];
        Object.assign(overrides, override);
      }
    });
  }

  return overrides;
};

export const isModifierCurrentlyValid = (state, productId, modifierId) => {
  const parentModifierGroups = findAllModifierGroupsWithModifier(
    state,
    productId,
    modifierId
  );
  let isValidModifier = false;
  for (const modifierGroup of parentModifierGroups) {
    if (isModifierGroupCurrentlyValid(state, productId, modifierGroup.id)) {
      isValidModifier = true;
      break;
    }
  }

  if (isValidModifier) {
    const modifier = getProductModifier(state, productId, modifierId);
    const modifierOverrides = findAllModifierOverrides(
      parentModifierGroups,
      modifierId
    );
    let blockingModifiers = modifier.blockingModifiers;
    if (modifierOverrides.blockingModifiers != null) {
      blockingModifiers = modifierOverrides.blockingModifiers;
    }
    if (modifierOverrides.dependencies?.length > 0) {
      return validateModifierDependencies(
        modifierOverrides.dependencies,
        state
      );
    } else if (
      blockingModifiers != null &&
      Object.keys(blockingModifiers).length > 0
    ) {
      const selectedModifiers = state.configuration.selectedModifiers;
      for (const blockingModifierId of Object.keys(blockingModifiers)) {
        if (
          selectedModifiers[blockingModifierId] === true &&
          isModifierCurrentlyValid(state, productId, blockingModifierId)
        ) {
          return false;
        }
      }
    }
  }

  return isValidModifier;
};

const validateModifierDependencies = (dependencies, state) => {
  const showConditions = dependencies
    .filter((dependency) => dependency.visibility === "show")
    .some((dependency) => validateModifierDependency(dependency, state));
  const hideConditions = dependencies
    .filter((dependency) => dependency.visibility === "hide")
    .some((dependency) => validateModifierDependency(dependency, state));
  return showConditions && !hideConditions;
};

const validateModifierDependency = (dependency, state) => {
  const selectedModifiers = state.configuration.selectedModifiers;
  let isValidPrice = true;
  if (dependency.condition === "any") {
    isValidPrice = Object.keys(dependency.requiredModifiers).some(
      (modifierId) => {
        return checkSelectedAndValid(selectedModifiers, modifierId, state);
      }
    );
  } else {
    isValidPrice = Object.keys(dependency.requiredModifiers).every(
      (modifierId) => {
        return checkSelectedAndValid(selectedModifiers, modifierId, state);
      }
    );
  }

  return isValidPrice;
};

export const isModifierGroupCurrentlyValid = (
  state,
  productId,
  modifierGroupId
) => {
  // If the modifier group doesn't have parent modifiers, it's automatically valid.
  const modifierGroup = getModifierGroup(state, productId, modifierGroupId);
  const overrides = findAllModifierGroupOverridesFromCategories(
    state,
    productId,
    modifierGroupId
  );

  let reqModifiers = {};
  if (overrides.dependencies?.length > 0) {
    return validateModifierDependencies(overrides.dependencies, state);
  } else if (
    overrides.requiredModifiers != null &&
    Object.keys(overrides.requiredModifiers).length > 0
  ) {
    reqModifiers = overrides.requiredModifiers;
  } else if (
    modifierGroup?.parentModifiers != null &&
    Object.keys(modifierGroup.parentModifiers).length > 0
  ) {
    reqModifiers = modifierGroup.parentModifiers;
  }

  // Return false if any of the parent modifiers are not selected or invalid
  const selectedModifiers = state.configuration.selectedModifiers;
  for (const modifierId of Object.keys(reqModifiers)) {
    if (
      selectedModifiers[modifierId] !== true ||
      !isModifierCurrentlyValid(state, productId, modifierId)
    ) {
      return false;
    }
  }

  return true;
};

export const getValidModifierGroupsForPage = (
  state,
  productId,
  configurationPage
) => {
  if (configurationPage == null || configurationPage.modifierGroups == null) {
    return [];
  }

  const modifierGroupIds = configurationPage.modifierGroups;
  return modifierGroupIds
    .map((modifierGroupId) => {
      if (
        shouldHideModifierGroupDueToSelections(
          state,
          productId,
          modifierGroupId
        )
      ) {
        return null;
      } else {
        return getFullModifierGroup(state, productId, modifierGroupId);
      }
    })
    .filter((e) => e != null);
};

export const getConfigurationPagesForProduct = (state, productId) => {
  const product = state.catalog.products[productId];
  if (product != null) {
    return getCategoriesForProduct(product, state.catalog.categories);
  }
  return [];
};

export const getCategoryInformationToDisplay = (state, productId, category) => {
  return Object.assign(
    {
      fullModifierGroups: getValidModifierGroupsForPage(
        state,
        productId,
        category
      ),
    },
    category
  );
};

export const getAllCurrentModifierGroups = (state) => {
  const productId = state.catalog.productId;
  const product = state.catalog.products[productId];
  const categories = state.catalog.categories;
  const modifierGroups = state.catalog.modifierGroups;

  const sortedConfigurationPages = getSortedConfigurationPagesWithProduct(
    categories,
    product
  );

  const modifierGroupMap = {};
  sortedConfigurationPages.forEach((page) => {
    if (page != null) {
      page.modifierGroups.forEach((modifierGroupId) => {
        modifierGroupMap[modifierGroupId] = modifierGroups[modifierGroupId];
      });
    }
  });
  return modifierGroupMap;
};

export const getOrderSummaryWithPages = (state, configurationPages) => {
  const productId = state.catalog.productId;
  const product = state.catalog.products[productId];
  if (product == null || configurationPages == null) {
    return {};
  }

  const snapshot = configurationPages.map((configurationPage) => {
    const validModifierGroups = getValidModifierGroupsForPage(
      state,
      productId,
      configurationPage
    );
    return Object.assign(
      {selectedModifierGroups: validModifierGroups},
      configurationPage
    );
  });

  const allSelectedModifiersArray =
    getAllSelectedModifiersFromSnapshot(snapshot);

  // Collect the modifiers into an object for being the source of truth for selected modifiers
  const allSelectedModifiersObject = {};

  let upgradesInMicros = 0;
  allSelectedModifiersArray.forEach((modifier) => {
    upgradesInMicros += modifier.adjustedPriceMicros;
    allSelectedModifiersObject[modifier.id] = Object.assign(
      {snapshotPrice: modifier.adjustedPriceMicros},
      modifier
    );
  });

  const orderSummary = {
    product: product,
    configurationSnapshot: snapshot,
    selectedModifiers: allSelectedModifiersObject,
    subTotalInMicros: upgradesInMicros + product.priceMicros,
    upgradesInMicros: upgradesInMicros,
  };

  return orderSummary;
};

export const getOrderSummary = (state) => {
  const productId = state.catalog.productId;
  const product = state.catalog.products[productId];

  const categories = state.catalog.categories;

  const sortedConfigurationPages = getSortedConfigurationPagesWithProduct(
    categories,
    product
  );
  return getOrderSummaryWithPages(state, sortedConfigurationPages);
};

export const getAllSelectedModifiersFromSnapshot = (snapshot) => {
  const allSelectedModifierGroupsArray = snapshot
    .map((configPage) => {
      return configPage.selectedModifierGroups;
    })
    .reduce(function (accumulator, currentValue) {
      return accumulator.concat(currentValue);
    }, []);

  return allSelectedModifierGroupsArray
    .map((selectedModifierGroup) => {
      return selectedModifierGroup.selectedModifiers;
    })
    .reduce(function (accumulator, currentValue) {
      return accumulator.concat(currentValue);
    }, []);
};

export const getSelectedModifiersFromSnapshot = (snapshot) => {
  const allSelectedModifiersArray =
    getAllSelectedModifiersFromSnapshot(snapshot);

  const allSelectedModifiersObject = {};
  const allSelectedModifiers = {};
  allSelectedModifiersArray.forEach((modifier) => {
    allSelectedModifiers[modifier.id] = true;
  });
  allSelectedModifiersArray.forEach((modifier) => {
    if (modifier.snapshotPrice != null) {
      allSelectedModifiersObject[modifier.id] = modifier;
    } else {
      const modifierPrice = getModifierPriceMicros(
        modifier,
        allSelectedModifiers
      );
      allSelectedModifiersObject[modifier.id] = Object.assign(
        {snapshotPrice: modifierPrice},
        modifier
      );
    }
  });
  return allSelectedModifiersObject;
};

export const getModifierPriceMicros = (modifier, selectedModifiers, state) => {
  const priceBeforeAdjustments = getModifierPriceBeforeAdjustments(
    modifier,
    selectedModifiers,
    state
  );
  let adjustmentAmount = 0;
  if (
    modifier.priceAdjustments != null &&
    modifier.priceAdjustments.length > 0
  ) {
    adjustmentAmount = determineAdjustmentAmount(
      modifier.priceAdjustments,
      modifier,
      selectedModifiers,
      state
    );
  } else if (
    modifier.defaultPriceAdjustments != null &&
    modifier.defaultPriceAdjustments.length > 0
  ) {
    adjustmentAmount = determineAdjustmentAmount(
      modifier.defaultPriceAdjustments,
      modifier,
      selectedModifiers,
      state
    );
  }

  return priceBeforeAdjustments + adjustmentAmount;
};

const determineAdjustmentAmount = (
  adjustments,
  modifier,
  selectedModifiers,
  state
) => {
  let adjustmentAmount = 0;
  for (const price of modifier.priceAdjustments) {
    if (price.condition === "any") {
      Object.keys(price.requiredModifiers).forEach((modifierId) => {
        const selected = checkSelectedAndValid(
          selectedModifiers,
          modifierId,
          state
        );
        if (selected) {
          adjustmentAmount += price.priceMicros;
        }
      });
    } else {
      let isValidPrice = true;
      Object.keys(price.requiredModifiers).forEach((modifierId) => {
        isValidPrice =
          isValidPrice &&
          checkSelectedAndValid(selectedModifiers, modifierId, state);
      });
      if (isValidPrice) {
        adjustmentAmount += price.priceMicros;
      }
    }
  }
  return adjustmentAmount;
};

const getModifierPriceBeforeAdjustments = (
  modifier,
  selectedModifiers,
  state
) => {
  if (modifier.prices != null) {
    for (const price of modifier.prices) {
      let isValidPrice = true;
      if (price.condition === "any") {
        isValidPrice = Object.keys(price.requiredModifiers).some(
          (modifierId) => {
            return checkSelectedAndValid(selectedModifiers, modifierId, state);
          }
        );
      } else {
        isValidPrice = Object.keys(price.requiredModifiers).every(
          (modifierId) => {
            return checkSelectedAndValid(selectedModifiers, modifierId, state);
          }
        );
      }

      if (isValidPrice) {
        return getPriceMicros(price.priceMicros);
      }
    }
  }

  if (shouldPriceBeUsed(modifier.priceMicros)) {
    return getPriceMicros(modifier.priceMicros);
  }

  if (modifier.defaultPrices != null) {
    for (const price of modifier.defaultPrices) {
      let isValidPrice = true;
      Object.keys(price.requiredModifiers).forEach((modifierId) => {
        isValidPrice =
          isValidPrice &&
          checkSelectedAndValid(selectedModifiers, modifierId, state);
      });
      if (isValidPrice) {
        return getPriceMicros(price.priceMicros);
      }
    }
  }

  return getPriceMicros(modifier.defaultPriceMicros);
};

export const checkSelectedAndValid = (selectedModifiers, modifierId, state) => {
  const isValidModifier =
    state == null ||
    isModifierCurrentlyValid(state, state.catalog.productId, modifierId);
  return isValidModifier && selectedModifiers[modifierId] === true;
};

const getPriceMicros = (value) => {
  return value == null ? 0 : value;
};

const shouldPriceBeUsed = (value) => {
  return value != null && (value >= 1 || value <= -1);
};

export const convertModifiersToSelectionOptions = (modifiers) => {
  if (modifiers == null) {
    return [];
  }
  return Object.keys(modifiers).map((key) => {
    const modifier = modifiers[key];
    return {
      label: modifier.title,
      value: key,
    };
  });
};

export const getAllModifierGroupsForProduct = (state, productId) => {
  const configurationPages = state.catalog.productConfigurationPages[productId];
  if (configurationPages == null) {
    return [];
  }

  const allModifiersForProduct = Object.values(configurationPages)
    .map((configurationPage) => {
      return configurationPage.modifierGroups
        .map((modifierGroupId) => {
          return getFullModifierGroup(state, productId, modifierGroupId)
            .fullModifiers;
        })
        .reduce(function (accumulator, currentValue) {
          return accumulator.concat(currentValue);
        }, []);
    })
    .reduce(function (accumulator, currentValue) {
      return accumulator.concat(currentValue);
    }, []);
  const modifierMap = {};
  allModifiersForProduct.forEach((modifier) => {
    modifierMap[modifier.id] = modifier;
  });
  return modifierMap;
};

export const shouldHideModifierGroupDueToSelections = (
  state,
  productId,
  modifierGroupId
) => {
  return !isModifierGroupCurrentlyValid(state, productId, modifierGroupId);
};

export const getModifierGroupType = (modifierGroup) => {
  const properties = modifierGroup.properties;
  if (properties == null) {
    return "default";
  } else if (properties[SHOW_MULTI_SELECT_PROPERTY_KEY]) {
    return SHOW_MULTI_SECENT_DISPLAY_KEY;
  } else if (properties[SHOW_COLOR_SWATCHES_PROPERTY_KEY]) {
    if (properties[SHOW_FULL_TEXT_PROPERTY_KEY]) {
      return SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY;
    }
    return SHOW_COLOR_SWATCHES_DISPLAY_KEY;
  } else if (properties[SHOW_FULL_TEXT_PROPERTY_KEY]) {
    return SHOW_FULL_TEXT_DISPLAY_KEY;
  }

  return "default";
};

export const priceFromText = (text, option) => {
  if (text == null || text === "") {
    return "Included";
  }
  let sign = "";
  let int = parseInt(text);
  if (int < 0) {
    sign = "-";
    int = Math.abs(int);
  }

  if (option === "minimal") {
    return sign + "$" + numberWithCommas(int);
  }
  if (option === "min") {
    return sign + "$" + numberWithCommasWithoutDecimal(int);
  }
  if (isBlankString(sign)) {
    sign = "+";
  }
  return sign + "$" + numberWithCommas(int) + ".00";
};

export const priceTextFromMicros = (micros, option) => {
  if (micros == null || micros === 0) {
    if (option === "integer-sign") {
      return "+$0";
    }
    if (option === "accurate") {
      return "$0";
    }
    return "Included";
  }
  let sign = "";
  if (option !== "minimal") {
    sign = "+";
  }
  if (micros < 0) {
    sign = "-";
  }

  if (option === "min" || option === "accurate") {
    sign = micros < 0 ? "-" : "";
    return (
      sign +
      "$" +
      numberWithCommasWithoutDecimal(Math.round(microsToReadablePrice(micros)))
    );
  }

  if (option === "min-sign" || option === "integer-sign") {
    return (
      sign +
      "$" +
      numberWithCommasWithoutDecimal(Math.round(microsToReadablePrice(micros)))
    );
  }

  return sign + "$" + numberWithCommas(microsToReadablePrice(micros));
};

function microsToReadablePrice(micros, currency) {
  return Math.abs(micros / 100);
}

function numberWithCommas(x) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithCommasWithoutDecimal(x) {
  return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getRenderingsFromSummary(orderSummary) {
  const allSelectedModifiers = orderSummary.selectedModifiers;
  const summarizeOrderInConfigurationPages = orderSummary.configurationSnapshot;
  return getRenderingsForWholeProduct(
    summarizeOrderInConfigurationPages,
    allSelectedModifiers
  );
}

export function getRenderingsForWholeProduct(
  summarizeOrderInConfigurationPages,
  allSelectedModifiers
) {
  let renderings,
    selectedModifiers,
    leftOverModifiers = null;
  renderings = [];
  leftOverModifiers = [];

  for (const configurationPageObj of summarizeOrderInConfigurationPages) {
    const {selectedModifierGroups} = configurationPageObj;
    selectedModifiers = getSelectedModifierIds(selectedModifierGroups);

    const response = getMatchingRenderings(
      configurationPageObj,
      selectedModifiers,
      allSelectedModifiers
    );
    renderings = renderings.concat(response.renderings);
    leftOverModifiers = leftOverModifiers.concat(response.leftOverModifiers);
  }
  const images = getImagesFromRenderings(renderings);
  return {renderings, leftOverModifiers, selectedModifiers, images};
}

export function getRenderings(
  summarizeOrderInConfigurationPages,
  configurationPage,
  allSelectedModifiers,
  modifierGroups
) {
  if (summarizeOrderInConfigurationPages != null) {
    return getRenderingsForWholeProduct(
      summarizeOrderInConfigurationPages,
      allSelectedModifiers
    );
  }

  let renderings,
    selectedModifiers,
    leftOverModifiers = null;

  if (modifierGroups != null) {
    selectedModifiers = getSelectedModifierIds(modifierGroups);

    const response = getMatchingRenderings(
      configurationPage,
      selectedModifiers,
      allSelectedModifiers
    );
    renderings = response.renderings;
    leftOverModifiers = response.leftOverModifiers;
  }

  const images = getImagesFromRenderings(renderings);
  return {renderings, leftOverModifiers, selectedModifiers, images};
}

function getSelectedModifierIds(modifierGroups) {
  const selectedModifiers = {};
  if (modifierGroups != null) {
    let index = 0;
    modifierGroups.forEach((modifierGroup) => {
      modifierGroup.selectedModifiers.forEach((modifier) => {
        selectedModifiers[modifier.id] = {title: modifier.title, index: index};
        index += 1;
      });
    });
  }

  return selectedModifiers;
}

function getMatchingRenderings(
  configurationPage,
  selectedModifiers,
  allSelectedModifiers
) {
  const mutableSelectedModifiers = Object.assign({}, selectedModifiers);
  const {renderings} = configurationPage;
  const renderingsToDisplay = [];

  if (renderings != null) {
    const renderingArrays = Object.values(renderings).sort(
      (a, b) =>
        Object.keys(a.requiredModifiers).length -
        Object.keys(b.requiredModifiers).length
    );

    for (const rendering of renderingArrays) {
      const {requiredModifiers} = rendering;
      if (requiredModifiers != null) {
        let foundRendering = true;
        for (const key in requiredModifiers) {
          if (allSelectedModifiers[key] == null) {
            foundRendering = false;
            break;
          }
        }

        if (foundRendering) {
          let lowestIndex = 100000;
          const modifiers = [];
          for (const key in requiredModifiers) {
            let modifier = mutableSelectedModifiers[key];
            if (modifier == null) {
              modifier = allSelectedModifiers[key];
            } else {
              lowestIndex = Math.min(modifier.index, lowestIndex);
              mutableSelectedModifiers[key] = null;
            }
            modifiers.push(modifier);
          }
          renderingsToDisplay.unshift(
            Object.assign(
              {
                index: lowestIndex,
                modifiers: modifiers,
                configurationPageId: configurationPage.id,
              },
              rendering
            )
          );
        }
      }
    }
  }

  return {
    renderings: renderingsToDisplay.sort((a, b) => a.index - b.index),
    leftOverModifiers: Object.keys(mutableSelectedModifiers).filter(
      (key) => mutableSelectedModifiers[key] != null
    ),
  };
}

export function getImagesFromRenderings(renderings) {
  const overlayRenderingMap = {};
  const newRenderingArray = [];
  renderings.forEach((rendering) => {
    let caption = "";
    const sortedModifiers = rendering.modifiers.sort(
      (a, b) => a.index - b.index
    );
    for (const modifier of sortedModifiers) {
      if (caption.length > 0) {
        caption = caption + " + ";
      }
      caption = caption + modifier.title;
    }
    rendering.caption = caption;
    rendering.captionElements = sortedModifiers;

    if (rendering.overlayGroupId != null) {
      let overlayRendering = overlayRenderingMap[rendering.overlayGroupId];
      if (overlayRendering == null) {
        overlayRendering = Object.assign({}, rendering);
        overlayRendering.overlayRenderings = [rendering];
        newRenderingArray.push(overlayRendering);
        overlayRenderingMap[rendering.overlayGroupId] = overlayRendering;
      } else {
        overlayRendering.overlayRenderings.push(rendering);
      }
    } else {
      newRenderingArray.push(rendering);
    }
  });

  const images = newRenderingArray.map((rendering) => {
    let overlayImages = null;
    let caption = null;
    let orientation = null;
    if (rendering.overlayRenderings != null) {
      overlayImages = rendering.overlayRenderings
        .sort((a, b) => a.zIndex - b.zIndex)
        .map((rendering) => {
          if (orientation == null) {
            orientation = rendering.orientation;
          }
          rendering.captionElements.forEach((modifier) => {
            const appendedText = modifier.title;
            if (isBlankString(caption) || caption.indexOf(appendedText) < 0) {
              if (!isBlankString(caption)) {
                caption = caption + " + " + appendedText;
              } else {
                caption = appendedText;
              }
            }
          });

          return {imageId: rendering.imageId, zIndex: rendering.zIndex};
        });
    } else {
      caption = rendering.caption;
    }

    let objectFit = isBlankString(rendering.objectFit)
      ? "cover"
      : rendering.objectFit;
    if (
      caption != null &&
      (caption.toLowerCase().indexOf("covered porch") >= 0 ||
        caption.toLowerCase().indexOf("orientation") >= 0)
    ) {
      objectFit = "contain";
    }

    return {
      imageId: rendering.imageId,
      configurationPageId: rendering.configurationPageId,
      caption: caption,
      overlayImages: overlayImages,
      orientation: orientation,
      objectFit: objectFit,
      overlayGroupId: rendering.overlayGroupId,
    };
  });
  return images;
}

// TODO can be deleted, but might be useful to keep
export function getImagesFromRenderingSnapshots(renderings) {
  const overlayRenderingMap = {};
  const newRenderingArray = [];
  renderings.forEach((renderingFull) => {
    const rendering = Object.assign({}, renderingFull);
    let caption = "";
    const sortedModifiers = [];
    Object.values(rendering.requiredModifiers).forEach((title) => {
      if (caption.length > 0) {
        caption = caption + " + ";
      }
      caption = caption + title;
      sortedModifiers.push(title);
    });
    rendering.captionElements = sortedModifiers;
    rendering.caption = caption;

    if (rendering.overlayGroupId != null) {
      let overlayRendering = overlayRenderingMap[rendering.overlayGroupId];
      if (overlayRendering == null) {
        overlayRendering = Object.assign({}, rendering);
        overlayRendering.overlayRenderings = [rendering];
        newRenderingArray.push(overlayRendering);
        overlayRenderingMap[rendering.overlayGroupId] = overlayRendering;
      } else {
        overlayRendering.overlayRenderings.push(rendering);
      }
    } else {
      newRenderingArray.push(rendering);
    }
  });

  const images = newRenderingArray.map((rendering) => {
    let overlayImages = null;
    let caption = null;
    let orientation = null;
    if (rendering.overlayRenderings != null) {
      overlayImages = rendering.overlayRenderings
        .sort((a, b) => a.zIndex - b.zIndex)
        .map((rendering) => {
          if (orientation == null) {
            orientation = rendering.orientation;
          }
          rendering.captionElements.forEach((title) => {
            const appendedText = title;
            if (isBlankString(caption) || caption.indexOf(appendedText) < 0) {
              if (!isBlankString(caption)) {
                caption = caption + " + " + appendedText;
              } else {
                caption = appendedText;
              }
            }
          });

          return {imageId: rendering.imageId, zIndex: rendering.zIndex};
        });
    } else {
      caption = rendering.caption;
    }

    let objectFit = isBlankString(rendering.objectFit)
      ? "cover"
      : rendering.objectFit;
    if (
      caption != null &&
      caption.toLowerCase().indexOf("covered porch") >= 0
    ) {
      objectFit = "contain";
    }

    return {
      imageId: rendering.imageId,
      configurationPageId: rendering.configurationPageId,
      caption: caption,
      overlayImages: overlayImages,
      orientation: orientation,
      objectFit: objectFit,
      overlayGroupId: rendering.overlayGroupId,
    };
  });
  return images;
}
