import useMountEffect from "../hooks/useMountEffect";
import {updateURL} from "../slices/GlobalSlice";
import {useEffect} from "react";
import {auth} from "../../../database/firebase";
import {useDispatch, useSelector} from "react-redux";
import {setProductAndGroupId, setProductId} from "../slices/CatalogSlice";
import {
  getProductGroupFromURL,
  getUnitFromURL,
} from "../../design-tool/logic/ProductUtils";
import {loadConfiguration} from "../../design-tool/backend/ConfigurationAPI";
import {fetchWebAppConfig} from "../../design-tool/backend/WebAppConfig";
import {updateProductGroupAndProduct} from "../catalog/ProductCatalogHelper";
import history from "../navigation/history";
import {useUrl} from "../hooks/useUrl";
import {usePageManager} from "../hooks/usePage";
import {useProductCatalog} from "../hooks/useProductCatalog";
import {HistoryChangeType} from "../navigation/_helpers";

let previousUID = null;

const fetchDataForUser = () => {
  return loadConfiguration();
};

export const DataManager = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(
    (state) => state.configuration.fetchedConfiguration
  );
  const user = useSelector((state) => state.global.user);
  const productId = useSelector((state) => state.catalog.productId);
  const productGroupId = useSelector((state) => state.catalog.productGroupId);

  const {availableProductGroups} = useProductCatalog();

  const {getURL} = useUrl();
  // Do not remove!
  usePageManager();
  useMountEffect(() => {
    history.listen(() => {
      const productId = getUnitFromURL();
      const productGroupId = getProductGroupFromURL();
      dispatch(setProductAndGroupId({productGroupId, productId}));
      dispatch(updateURL(getURL()));
    });

    fetchWebAppConfig();
  });

  useEffect(() => {
    const firebaseUser = auth.currentUser;
    const newUID = firebaseUser != null ? firebaseUser.uid : null;
    if (previousUID !== newUID) {
      previousUID = newUID;
      fetchDataForUser();
    }
  }, [user]);

  useEffect(() => {
    if (
      configuration != null &&
      configuration.product != null &&
      productId !== configuration.product.id
    ) {
      dispatch(setProductId(configuration.product.id));
    }
  }, [configuration]);

  const determineReplaceOrPush = (productId) => {
    // If there is only 1 product group, and we are not looking at a product - replace
    // otherwise push
    if (
      (availableProductGroups && availableProductGroups.length > 1) ||
      productId
    ) {
      return HistoryChangeType.PUSH;
    } else {
      return HistoryChangeType.REPLACE;
    }
  };

  useEffect(() => {
    if (
      getProductGroupFromURL() !== productGroupId ||
      getUnitFromURL() !== productId
    ) {
      updateProductGroupAndProduct(
        productGroupId,
        productId,
        determineReplaceOrPush(productId)
      );
    }
  }, [productId, productGroupId]);

  return null;
};
