export function downloadImageToDesktop(url, filename) {
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
			const blobURL = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = blobURL;
			a.style.display = "none";
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		})
		.catch((error) => {
			console.error(error);
		});
}
