import styled from "styled-components";
import {calculateBorder, INatStyle} from "../_shared";
import {DROPDOWN_Z_INDEX, NatStyle} from "../../styles";
import {WHITE, DROPDOWN_ACCENT_COLOR, BLACK} from "../../colors";
import {isMobile} from "../../navigation";

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  text-align: center;
  margin-right: 0.5rem;
  color: ${BLACK};
  margin-bottom: 0.25rem;
`;

export const DropdownLabel = styled.button<INatStyle>`
  ${NatStyle};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: ${(props) => calculateBorder(props.initialBorderColor)};
  border-radius: 3rem;
  background-color: ${(props) => !props.enabled && DROPDOWN_ACCENT_COLOR};
  &:hover {
    border: ${(props) => calculateBorder(props.hoverBorderColor)};
  }
`;
export const DropdownOptionsContainer = styled.div<{show: boolean}>`
  display: ${(props) => (props.show ? "flex" : "none")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: ${isMobile() ? "fixed" : "absolute"};
  left: ${isMobile() && "0.5rem"};
  right: ${isMobile() && "0.5rem"};
  top: ${(props) => props.show && !isMobile() && "125%"};
  bottom: ${(props) => props.show && isMobile() && "0.5rem"};
  background-color: ${WHITE};
  border: 2px solid ${DROPDOWN_ACCENT_COLOR};
  border-radius: 0.5rem;
  z-index: ${DROPDOWN_Z_INDEX};
  transition: all 2000ms ease-in-out;
`;

export const DropdownOption = styled.button`
  text-align: ${isMobile() ? "center" : "left"};
  background-color: white;
  color: inherit;
  border: none;
  border-bottom: 1px solid ${DROPDOWN_ACCENT_COLOR};
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.5rem;
  width: 100%;
  white-space: nowrap;
  &:disabled {
    border: none !important;
    border-bottom: 1px solid ${DROPDOWN_ACCENT_COLOR} !important;
  }
  &:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
    border: 0;
  }
  &:only-child {
    border-radius: 0.5rem;
  }
  &:hover {
    filter: brightness(96%);
  }
`;
