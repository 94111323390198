import styled from "styled-components";
import {Button, Row} from "antd";
import {BREAKPOINT_DESKTOP_SMALL, colors} from "../../theme/constants";

export const ADUListContainer = styled.div`
	padding: ${(props) => (props.isListExpanded ? "69px 24px 8px" : "8px 24px")};
	flex: 2;
	overflow: hidden;
	background: #fff;
	@media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
		display: flex;
		justify-content: flex-end;
		height: 100vh;
		flex: 1;
		min-width: 400px;
		max-width: 520px;
		padding: 24px 48px;
		#geocoder {
			margin-bottom: 40px;
		}
	}
`;

export const ImgSliderContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
	height: 200px;
	:hover {
		.slider-arrow {
			opacity: 1;
		}
	}
	.slick-dots {
		bottom: 12px;
		z-index: 2;
		height: 2px;
		.slick__dots--custom {
			height: 2px;
			background: rgba(255, 255, 255, 0.4);
		}
		.slick-active .slick__dots--custom {
			background: rgba(255, 255, 255, 1);
		}
	}
`;

export const ImgSliderArrows = styled.div`
	position: absolute;
	width: 100%;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	.slider-arrow {
		opacity: 0;
		transition: opacity 0.3s;
		:hover {
			cursor: pointer;
		}
		:last-child {
			float: right;
		}
	}
`;

export const ADUListColumn = styled.div`
	width: 100%;
	flex: 1;
	z-index: 1;
	background-color: #fff;
	.btn--expand-icon {
		padding: 16px;
		right: -16px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.btn-transparent {
		border: none;
		box-shadow: none;
		outline: none;
		:focus,
		:active {
			fill: inherit;
			border: none;
			box-shadow: none;
		}
	}
	.btn--expand-icon {
		transition: transform 0.3s ease;
		transform: rotate(${(props) => (props.isListExpanded ? "0" : "180deg")});
	}
	.ant-tabs-nav-list {
		width: 100%;
		position: relative;
		display: flex;
		transition: transform 0.3s;
		flex-wrap: wrap;

		.ant-tabs-tab {
			width: 50%;
			line-height: 18px;
			padding-top: 12px;
			padding-bottom: 12px;
			cursor: pointer;
		}
		.ant-tabs-ink-bar {
			background: ${colors.aduRed};
			height: 4px;
			position: relative;
		}
		.ant-tabs-tab .ant-tabs-tab-btn {
			color: ${colors.textGrey};
			font-size: 18px;
			line-height: 18px;
			opacity: 1;
		}
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-weight: bold;
		}
	}
	.ant-tabs-content-holder {
		overflow-x: hidden;
		overflow-y: auto;
		transition: height 0.3s ease;
		height: ${(props) =>
			props.isListExpanded ? "calc(100vh - 200px)" : "calc(100vh - 410px)"};
	}
	@media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
		max-width: 400px;
		.ant-tabs-content-holder {
			height: calc(100vh - 243px);
		}
	}
`;

export const ProjectDesignContainer = styled.div`
	padding-bottom: 128px;
	overflow-y: auto;
	overflow-x: hidden;
	height: ${(props) =>
		props.isListExpanded ? "calc(100vh - 190px)" : "calc(100vh - 400px)"};
	h2 {
		font-weight: 500;
		font-size: 26px;
		line-height: 26px;
		color: ${colors.textGrey};
	}
	@media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
		height: calc(100vh - 190px);
		width: 416px;
		padding-right: 16px;
	}
`;

export const ProjectDesignFooterContainer = styled.div`
	position: fixed;
	background-color: #fff;
	width: 100%;
	padding: 0;
	bottom: 0;
	left: 0;
	z-index: 3;
	.ant-space {
		width: 100%;
		margin: 0 auto;
		padding: 16px 24px;
	}
	.ant-btn {
		box-shadow: none;
		border: none;
		outline: none;
		height: 46px;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		border-radius: 8px;
		background-color: #fff;
		color: ${colors.textGrey};
		&.ant-btn-primary {
			color: #fff;
			background-color: #000;
			:focus,
			:hover {
				background-color: #333;
				border: none;
				outline: none;
			}
		}
		:focus,
		:hover {
			background-color: #efefef;
			border: none;
			outline: none;
		}
	}
	@media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
		max-width: 520px;
		.ant-space {
			width: 100%;
			margin-right: 49px;
			margin-left: auto;
			float: right;
			max-width: 400px;
			padding: 24px 0;
		}
	}
`;

export const ListHeaderContainer = styled.div`
	margin: 0 auto 24px;
	.ant-btn {
		svg {
			fill: inherit;
		}
	}
	h3 {
		font-weight: bold;
		font-size: 26px;
		line-height: 26px;
		color: ${colors.textGrey};
	}
`;
export const StyledTextButton = styled(Button)`
	color: ${colors.aduRed};
	position: relative;
	font-weight: bold;
`;

export const StyledRoundButton = styled(Button)`
	height: 43px;
	background: #ffffff;
	border: 1.5px solid rgba(62, 80, 84, 0.4);
	border-radius: 100px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: ${colors.textGrey};
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		margin-right: 8px;
	}
	:hover,
	:focus {
		border: 1.5px solid black;
		color: black;
	}
`;

export const ListItem = styled.div`
	color: ${colors.textGrey};
	cursor: pointer;
	margin-top: 32px;
	padding-bottom: 32px;
	border-bottom: 1px solid #e1e1e1;
	:last-child {
		border-bottom: none;
	}
	:first-child {
		margin-top: 8px;
	}
	img {
		object-fit: cover;
		height: 182px;
		width: 100%;
	}
`;

export const ListItemTitle = styled(Row)`
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 8px;
	line-height: 18px;
	display: flex;
	align-content: space-between;
	width: 100%;
`;
export const ListItemDataRow = styled(Row)`
	font-weight: 500;
	font-size: 15px;
	line-height: 15px;
`;
