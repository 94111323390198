export const DesignDashboard = () => {
	return null;
	// const [feedback, setFeedback] = useState({});
	// const [viewOpportunityData, setViewOpportunityData] = useState(false);
	// useEffect(async () => {
	// 	const feedbackMap = await getFeedback();
	// 	const feedbackWithComments = [];
	// 	const ratings = {};
	// 	const rawData = [];
	//
	// 	Object.values(feedbackMap).forEach((value) => {
	// 		if (viewOpportunityData !== true || !isBlankString(value.opportunityId)) {
	// 			const key = "" + value.rating;
	// 			if (ratings[key] == null) {
	// 				ratings[key] = 0;
	// 			}
	// 			ratings[key] = ratings[key] + 1;
	// 			rawData.push(value.rating);
	// 			if (!isBlankString(value.comment)) {
	// 				feedbackWithComments.push(value);
	// 			}
	// 		}
	// 	});
	//
	// 	let totalRatingScore = 0;
	// 	let totalCount = 0;
	// 	Object.keys(ratings).forEach((key) => {
	// 		const value = parseInt(key);
	// 		const count = ratings[key];
	// 		totalRatingScore += value * count;
	// 		totalCount += count;
	// 	});
	//
	// 	const results = {
	// 		ratings: ratings,
	// 		totalCount: totalCount,
	// 		totalRatingScore: totalRatingScore,
	// 		average: Math.round((totalRatingScore / totalCount) * 100) / 100,
	// 		rawData: rawData,
	// 		feedbackWithComments: feedbackWithComments,
	// 	};
	//
	// 	setFeedback(results);
	// }, [viewOpportunityData]);
	//
	// console.log(feedback.rawData);
	//
	// if (Object.keys(feedback).length < 1 || feedback.rawData.length < 1) {
	// 	return (
	// 		<div
	// 			className={"clickable"}
	// 			onClick={() => {
	// 				setViewOpportunityData(!viewOpportunityData);
	// 			}}
	// 		>
	// 			{"Toggle Opportunity Data: " + viewOpportunityData}
	// 		</div>
	// 	);
	// }
	//
	// return (
	// 	<div style={{width: "300px"}}>
	// 		<OneLine leftText={"Avg:"} rightText={feedback.average} />
	// 		<OneLine
	// 			leftText={"Total Submissions:"}
	// 			rightText={feedback.totalCount}
	// 		/>
	// 		<div style={{width: "500px", height: "500px"}}>
	// 			<ResponsiveHistogram
	// 				ariaLabel="Feedback Scores"
	// 				orientation="vertical"
	// 				binValues={[0.5, 1.5, 2.5, 3.5, 4.5, 5.5]}
	// 				valueAccessor={(datum) => datum}
	// 				binType="numeric"
	// 				renderTooltip={({event, datum, data, color}) => (
	// 					<div>
	// 						<strong style={{color}}>
	// 							{Math.floor(datum.bin1) + " Stars"}
	// 						</strong>
	// 						<div>
	// 							<strong>Total </strong>
	// 							{datum.count}
	// 						</div>
	// 						<div>
	// 							<strong>Percent </strong>
	// 							{Math.round(datum.density * 100) + "%"}
	// 						</div>
	// 					</div>
	// 				)}
	// 			>
	// 				<BarSeries
	// 					animated
	// 					rawData={feedback.rawData /* or binnedData={...} */}
	// 				/>
	// 				<XAxis />
	// 				<YAxis />
	// 			</ResponsiveHistogram>
	// 		</div>
	// 		<div
	// 			className={"clickable"}
	// 			onClick={() => {
	// 				setViewOpportunityData(!viewOpportunityData);
	// 			}}
	// 		>
	// 			{"Toggle Opportunity Data: " + viewOpportunityData}
	// 		</div>
	// 		{feedback.feedbackWithComments.map((feedback) => {
	// 			return (
	// 				<div style={{whiteSpace: "pre"}}>
	// 					<Underline className="option-underline"></Underline>
	// 					{feedback.rating + " stars: '" + feedback.comment + "'\n"}
	// 					<br />
	// 					{"Configuration ID: " + feedback.id}
	// 					<br />
	// 					{"Opportunity ID: " + feedback.opportunityId}
	// 					<br />
	// 				</div>
	// 			);
	// 		})}
	// 	</div>
	// );
};
