import {
  ARCHIVE_FILL,
  ARCHIVE_FILL_HOVER,
  BLACK,
  BUTTON_FILL_COLOR_DARK,
  BUTTON_FILL_COLOR_GRAY_1,
  BUTTON_FILL_COLOR_LIGHT,
  DEVELOPER_FILL,
  DEVELOPER_FILL_HOVER,
  TOGGLE_BORDER_COLOR,
  TOGGLE_BORDER_ENABLED_COLOR,
  TOGGLE_ENABLED_FILL_COLOR,
  WHITE,
} from "../../colors";
import React from "react";

enum StyleOption {
  Developer = 1,
  Archive,
  ColorWillDarken,
  ColorWillLighten,
  BlackWillColorLight,
  BlackWillColorDark,
  NoneWillBoldAndUnderline,
  WhiteWillFillBlack,
  PRIMARY,
  PRIMARY_COLOR,
  SECONDARY,
  LIGHT_TOGGLE,
}
interface INatStyle {
  initialBackgroundColor: string | null;
  initialTextColor: string | null;
  initialTextDecoration: any | null;
  initialBorderColor: string | null;
  hoverBackgroundColor: string | null;
  hoverTextColor: string | null;
  hoverTextDecoration: any | null;
  hoverBorderColor: any | null;
  textSize: any | null;
  enabledBackgroundColor: string | null;
  enabledBorderColor: string | null;
  enabled?: boolean;
  width?: string;
  fontFamily?: string;
}

interface ITextStyle {
  fontSize: TextSize;
  hasLabel?: boolean;
  iconPosition?: IconPosition;
}

enum TextSize {
  XSMALL = "0.65rem",
  SMALL = "0.85rem",
  NORMAL = "1rem",
  LARGE = "1.15rem",
}
export enum IconPosition {
  LEFT,
  RIGHT,
  TOP,
}
export interface IconProps {
  icon: any;
  iconPosition: IconPosition;
}
export const getTextContent = (icon: any, label: string) => {
  if (icon?.iconPosition === IconPosition.TOP) {
    return (
      <>
        {icon?.icon}
        <br />
        <br />
        {label}
      </>
    );
  }
  return (
    <>
      {icon?.iconPosition === IconPosition.LEFT && icon?.icon}
      {label}
      {icon?.iconPosition === IconPosition.RIGHT && icon?.icon}
    </>
  );
};

const StyleOptions: {[key: number]: INatStyle} = {
  // -1 will never be taken, and can act as a default
  [-1]: {
    initialBackgroundColor: BUTTON_FILL_COLOR_LIGHT,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: null,
    hoverBackgroundColor: BUTTON_FILL_COLOR_DARK,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: WHITE,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.Developer]: {
    initialBackgroundColor: DEVELOPER_FILL,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: WHITE,
    hoverBackgroundColor: DEVELOPER_FILL_HOVER,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: WHITE,
    textSize: TextSize.SMALL,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.Archive]: {
    initialBackgroundColor: ARCHIVE_FILL,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: WHITE,
    hoverBackgroundColor: ARCHIVE_FILL_HOVER,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: WHITE,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.ColorWillDarken]: {
    initialBackgroundColor: BUTTON_FILL_COLOR_LIGHT,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: BUTTON_FILL_COLOR_LIGHT,
    hoverBackgroundColor: BUTTON_FILL_COLOR_DARK,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: BLACK,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.ColorWillLighten]: {
    initialBackgroundColor: BUTTON_FILL_COLOR_DARK,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: null,
    hoverBackgroundColor: BUTTON_FILL_COLOR_LIGHT,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: WHITE,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.BlackWillColorDark]: {
    initialBackgroundColor: BLACK,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: null,
    hoverBackgroundColor: BUTTON_FILL_COLOR_DARK,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: WHITE,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.BlackWillColorLight]: {
    initialBackgroundColor: BLACK,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: null,
    hoverBackgroundColor: BUTTON_FILL_COLOR_LIGHT,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: WHITE,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.NoneWillBoldAndUnderline]: {
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: null,
    hoverBackgroundColor: WHITE,
    hoverTextColor: BLACK,
    hoverTextDecoration: "underline",
    hoverBorderColor: WHITE,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.WhiteWillFillBlack]: {
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: BLACK,
    hoverBackgroundColor: BLACK,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: BLACK,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.PRIMARY]: {
    initialBackgroundColor: BLACK,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: BLACK,
    hoverBackgroundColor: BUTTON_FILL_COLOR_DARK,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: BUTTON_FILL_COLOR_DARK,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.PRIMARY_COLOR]: {
    initialBackgroundColor: BUTTON_FILL_COLOR_DARK,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: BUTTON_FILL_COLOR_DARK,
    hoverBackgroundColor: BLACK,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: BLACK,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.SECONDARY]: {
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: BLACK,
    hoverBackgroundColor: BUTTON_FILL_COLOR_GRAY_1,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: BLACK,
    textSize: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.LIGHT_TOGGLE]: {
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: TOGGLE_BORDER_COLOR,
    hoverBackgroundColor: WHITE,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: TOGGLE_BORDER_ENABLED_COLOR,
    textSize: null,
    enabledBackgroundColor: TOGGLE_ENABLED_FILL_COLOR,
    enabledBorderColor: TOGGLE_BORDER_ENABLED_COLOR,
  },
};

const getInitialBackgroundColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialBackgroundColor;
};
const getInitialTextColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialTextColor;
};
const getInitialTextDecoration = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialTextDecoration;
};
const getInitialBorderColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialBorderColor;
};
const getHoverBackgroundColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverBackgroundColor;
};
const getHoverTextColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverTextColor;
};
const getHoverTextDecoration = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverTextDecoration;
};
const getHoverBorderColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverBorderColor;
};
const getEnabledBorderColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].enabledBorderColor;
};
const getEnabledBackgroundColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].enabledBackgroundColor;
};

const getTextSize = (size?: TextSize) => {
  if (size) return size;
  else return TextSize.NORMAL;
};

export {
  StyleOption,
  TextSize,
  getTextSize,
  getInitialBackgroundColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getInitialBorderColor,
  getHoverBackgroundColor,
  getHoverTextColor,
  getHoverTextDecoration,
  getHoverBorderColor,
  getEnabledBorderColor,
  getEnabledBackgroundColor,
};
export type {INatStyle, ITextStyle};
