import styled from "styled-components";
import {MODAL_Z_INDEX} from "../../styles";

interface INatomasModal {
  width: string;
  height: string;
  opacity: number;
}

export const NatomasModal = styled.div<INatomasModal>`
  opacity: ${(props) => props.opacity};
  pointer-events: ${(props) => (props.opacity ? "auto" : "none")};
  background-color: white;
  border: 2px black solid;
  position: fixed;
  border-radius: 1rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: calc((100vw - (${(props) => props.width})) / 2);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  z-index: ${MODAL_Z_INDEX};
  transition: opacity 250ms ease-in-out;
`;

export const NatomasModalContainer = styled.div<{
  customPadding?: string;
}>`
  height: 100%;
  width: 100%;
  padding: ${(props) => (props.customPadding ? props.customPadding : "2rem")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NatomasModalContentContainer = styled.div<{centered: boolean}>`
  flex-grow: 1;
  text-align: ${(props) => props.centered && "center"};
`;

export const NatomasModalHeader = styled.h1<{isMobile: boolean}>`
  font-size: ${(props) => (props.isMobile ? 1.25 : 1.5)}rem;
  text-align: center;
  border-bottom: 1px solid black;
  margin: 0;
  padding-bottom: 1rem;
  font-family: Maison-Bold, sans-serif;
`;

export const NatomasModalClose = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: calc(${MODAL_Z_INDEX} + 100);
  svg {
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    background-color: white;
    &:hover {
      background-color: black;
      color: white;
      cursor: pointer;
    }
  }
`;

export const NatomasModalFooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
`;

export const NatomasModalBackdrop = styled.div<{opacity: number}>`
  position: fixed;
  pointer-events: ${(props) => (props.opacity ? "auto" : "none")};
  background-color: rgba(0, 0, 0, 0.6);
  opacity: ${(props) => props.opacity};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: calc(${MODAL_Z_INDEX} - 100);
  transition: opacity 200ms ease-in-out;
`;
