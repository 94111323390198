import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {ConfigurationPageStructure} from "../../../design-tool/models/ConfigurationPageModel";
import {
  deleteCategory,
  saveCategory,
} from "../../../design-tool/backend/catalogApi";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../_shared/application";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {DNDEditor} from "./DNDEditor";
import {getBlankItemSection} from "../views/ProductsTab/EditProduct";
import {AdminForm, MainContent, MainView} from "../styles/AdminStyles";
import {AdminInput} from "./AdminComponentFactory";
import {editCategory} from "../../../_shared/slices/CatalogSlice";
import {EditModifierGroupOverrides} from "../../../design-tool/components/EditModifierGroupOverrides";
import {RenderingsTab} from "./RenderingsTab";
import {EditorHeader} from "./EditorHeader";
import {isDeepEqual} from "../logic/AdminEditorHelper";
import {IStore} from "../../../_shared/slices/types/Store";

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "structure", title: "Category Structure"},
  {id: "renderings", title: "Renderings"},
];

const getInitialValues = (obj: any) => {
  const initialValues = getFormInitialValues(ConfigurationPageStructure);
  if (obj != null) {
    for (const key in ConfigurationPageStructure) {
      let initialValue = null;
      if (key === "sections") {
        const value = obj[key];
        const initialListOfModifierGroups =
          obj["modifierGroups"] != null ? obj["modifierGroups"] : [];
        let newSections = value != null ? Object.assign({}, value) : {};

        if (Object.keys(newSections).length < 1) {
          newSections = getBlankItemSection(initialListOfModifierGroups);
        } else {
          Object.keys(newSections).forEach((key, index) => {
            const section = newSections[key];
            if (section.items == null) {
              if (index === 0) {
                newSections[key] = Object.assign(
                  {items: initialListOfModifierGroups},
                  newSections[key]
                );
              } else {
                newSections[key] = Object.assign({items: []}, newSections[key]);
              }
            }
          });
        }

        initialValue = newSections;
      } else {
        initialValue = obj[key];
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditCategory = () => {
  const editingCategory = useSelector(
    (state: IStore) => state.catalog.editingCategory
  );
  const {productGroupCategories} = useProductCatalog();
  const category =
    productGroupCategories != null &&
    productGroupCategories[editingCategory?.id] != null
      ? productGroupCategories[editingCategory?.id]
      : editingCategory;

  const [lastCategoryId, setLastCategoryId] = useState("");
  const [tab, setTab] = useState(tabs[0].id);
  const {currentGroupModifierGroups} = useProductCatalog();
  const dispatch = useDispatch();
  const close = () => {
    dispatch(editCategory(null));
  };

  const deleteAction = () => {
    close();
    return deleteCategory(category);
  };

  const initialValues = getInitialValues(category);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const infoToSave = Object.assign({}, values);
      return saveCategory(category, infoToSave);
    },
  });

  useEffect(() => {
    if (category != null && lastCategoryId !== category.id) {
      formik.resetForm();
      setLastCategoryId(category.id);
      formik.setValues(initialValues);
    }
  }, [category]);

  if (category == null) {
    return null;
  }

  const libraryIds = Object.keys(currentGroupModifierGroups);
  const configurationPageStructureForForm = getFormStructure(
    ConfigurationPageStructure
  );

  let mainContent;
  if (tab === "details") {
    mainContent = (
      <AdminForm onSubmit={formik.handleSubmit}>
        {configurationPageStructureForForm.map((keyInfo) => {
          const {key} = keyInfo;
          // @ts-ignore
          const value = formik.values[key];
          if (keyInfo.hiddenInput === true) {
            return null;
          }

          // @ts-ignore
          let initial = initialValues[key];

          return (
            <AdminInput
              key={key}
              value={value}
              initialValue={initial}
              fieldInfo={keyInfo}
              onChange={(value: any) => {
                formik.setFieldValue(key, value);
              }}
              handleChange={formik.handleChange}
            />
          );
        })}
      </AdminForm>
    );
  } else if (tab === "renderings") {
    mainContent = (
      <RenderingsTab
        category={category}
        renderings={category.renderings}
        modifierGroupIds={category.modifierGroups}
      />
    );
  } else {
    const key = "modifierGroups";
    // @ts-ignore
    const sections = formik.values["sections"];
    // @ts-ignore
    const value = formik.values[key];

    mainContent = (
      <div>
        <DNDEditor
          sections={sections}
          fieldKey={key}
          formik={formik}
          savedIds={value}
          libraryIds={libraryIds}
          type={"modifierGroup"}
          callback={(newSavedIds: any, section: any) => {
            if (sections) {
              formik.setFieldValue("sections", sections);
            }
            formik.setFieldValue(key, newSavedIds);
          }}
        />
      </div>
    );
  }

  const upToDate = isDeepEqual(formik.values, initialValues);
  return (
    <MainView>
      <EditorHeader
        tabs={tabs}
        setTab={setTab}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        isUpToDate={upToDate}
        deleteAction={category?.id != null ? deleteAction : null}
        saveAction={formik.handleSubmit}
      />
      <MainContent>{mainContent}</MainContent>
      <EditModifierGroupOverrides
        callback={(valuesChanged: any) => {
          Object.keys(valuesChanged).forEach((key) => {
            formik.setFieldValue(key, valuesChanged[key]);
          });
        }}
      />
    </MainView>
  );
};
