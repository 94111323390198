import calendar from "../../../../assets/icons/pngs/calendarIcon16.png";
import clock from "../../../../assets/icons/pngs/clockIcon16.png";
import {PopUpModal} from "../../../_shared/generics/PopUpModal";
import React, {useState} from "react";
import {
  MainContainer,
  MobileParentBoxContainer,
  MobileTimelineContainer,
  MobileTimeLineHeaderETAComponent,
  MobileTitle,
  ParentBoxContainer,
  SubTitle,
  TimelineContainer,
  TimeLineFooter,
  TimeLineHeader,
  TimeLineHeaderETA,
  TimeLineHeaderETAComponent,
  TimeLineHeaderTitle,
  TimelineLeftColumn,
  TimeLineParent,
  TimelineRightColumn,
  TimelineRowTop,
  TimelineTable,
  Title,
} from "./styles";
import {
  MobileTimeline,
  NextStepsTimelineRow,
  TimelineStageInfo,
} from "./components/NextStepsTimeline";
import {
  ALL_MONTHS,
  NEXT_STEPS_POP_UP_TABS,
  getNextSteps,
  getProductionTimelineInfo,
} from "./constants/NextSteps";
import {StepBox} from "./components/NextStepsTodos";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {getProposalCompletionDate} from "../../_shared/utilities";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {usePage} from "../../../_shared/hooks/usePage";

export const NextStepsView = (props) => {
  const {active} = props;

  const {mergedProjectInfos, configuration} = useCurrentProject();
  const {productGroup, productGroups} = useProductCatalog();
  let currentProductGroup = productGroup;
  if (productGroups && configuration?.product?.productGroupId) {
    currentProductGroup =
      productGroups[configuration?.product?.productGroupId] ?? productGroup;
  }

  const startDate = getProposalCompletionDate(mergedProjectInfos) ?? new Date();
  const {monthBreakdown, totalMonths} =
    getProductionTimelineInfo(currentProductGroup);
  const estimatedMonths = totalMonths - 1; // Subtract 1 since proposal stage is technically in the past

  const targetDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + estimatedMonths,
    1
  );
  const targetMonth = ALL_MONTHS[targetDate.getMonth()];
  const targetYear = targetDate.getFullYear();

  const [show, setShow] = useState(false);
  const [step, setStep] = useState("price");
  const handleShow = (value) => {
    setStep(value);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const nextStepsInfo = getNextSteps(monthBreakdown);
  const {isNatMobile} = usePage();

  if (isNatMobile()) {
    return (
      <div style={{overflow: "hidden"}}>
        <PageContainer overridePadding={true}>
          <PageElement size={3} height={"5rem"}>
            <Title>Your next steps</Title>
            <div>We will email you these documents shortly, stay tuned!</div>
          </PageElement>
          <PageElement size={4} height={"22rem"}>
            <MobileParentBoxContainer>
              <StepBox handleShow={handleShow} step={1} />
              <StepBox handleShow={handleShow} step={2} />
            </MobileParentBoxContainer>
          </PageElement>
          <PageElement size={3} height={"7rem"}>
            <div style={{paddingBottom: "1rem"}}>
              <MobileTitle>Project Schedule Overview</MobileTitle>
              <MobileTimeLineHeaderETAComponent>
                <img alt="Clock" src={clock} width={18} />
                <div style={{color: "rgba(0, 0, 0, 0.6)"}}>
                  Estimated timeline:
                </div>
                <div className={"bold"}>{`${estimatedMonths} months`}</div>
              </MobileTimeLineHeaderETAComponent>
              <MobileTimeLineHeaderETAComponent>
                <img alt="Calendar" src={calendar} width={18} />
                <div style={{color: "rgba(0, 0, 0, 0.6)"}}>
                  Target Completion:
                </div>
                <div className={"bold"}>{targetMonth + " " + targetYear}</div>
              </MobileTimeLineHeaderETAComponent>
            </div>
          </PageElement>
          <PageElement size={3} height={"auto"}>
            <MobileTimelineContainer>
              <MobileTimeline
                info={nextStepsInfo}
                totalNumberOfMonths={totalMonths + 1}
              />
            </MobileTimelineContainer>
          </PageElement>
        </PageContainer>
        <PopUpModal
          show={show}
          handleClose={handleClose}
          initialStep={step}
          navItems={NEXT_STEPS_POP_UP_TABS}
        />
      </div>
    );
  }

  return (
    <MainContainer active={active}>
      <Title>Your Next Steps</Title>
      <SubTitle>
        We will email you these documents shortly, stay tuned!
      </SubTitle>
      <ParentBoxContainer>
        <StepBox handleShow={handleShow} step={1} />
        <StepBox handleShow={handleShow} step={2} />
      </ParentBoxContainer>
      <TimeLineParent>
        <TimeLineHeader>
          <TimeLineHeaderTitle>
            Your Estimated Project Schedule
          </TimeLineHeaderTitle>
          <TimeLineHeaderETA>
            <TimeLineHeaderETAComponent>
              <img alt="Clock" src={clock} width={18} />
              <div style={{color: "rgba(0, 0, 0, 0.6)"}}>
                Estimated timeline:
              </div>
              <div className={"bold"}>{`${estimatedMonths} months`}</div>
            </TimeLineHeaderETAComponent>
            <span style={{marginRight: "10px"}}> | </span>
            <TimeLineHeaderETAComponent>
              <img alt="Calendar" src={calendar} width={18} />
              <div style={{color: "rgba(0, 0, 0, 0.6)"}}>
                Target Completion:
              </div>
              <div className={"bold"}>{targetMonth + " " + targetYear}</div>
            </TimeLineHeaderETAComponent>
          </TimeLineHeaderETA>
        </TimeLineHeader>
        <TimelineContainer>
          <TimelineLeftColumn>
            <TimelineRowTop />
            {nextStepsInfo.map((info) => (
              <TimelineStageInfo key={info.id} info={info} />
            ))}
          </TimelineLeftColumn>
          <TimelineRightColumn>
            <TimelineTable>
              <NextStepsTimelineRow totalNumberOfMonths={totalMonths + 1} />
              {nextStepsInfo.map((info) => (
                <NextStepsTimelineRow
                  key={info.id}
                  info={info}
                  totalNumberOfMonths={totalMonths + 1}
                />
              ))}
            </TimelineTable>
          </TimelineRightColumn>
        </TimelineContainer>
        <TimeLineFooter>Jurisdiction timelines may vary*</TimeLineFooter>
      </TimeLineParent>
      <PopUpModal
        show={show}
        handleClose={handleClose}
        initialStep={step}
        navItems={NEXT_STEPS_POP_UP_TABS}
      />
    </MainContainer>
  );
};
