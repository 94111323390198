import React from "react";
import {AccountDetailsForm} from "./components/AccountDetailsForm/AccountDetailsForm";
import {ProjectDetailsForm} from "./components/ProjectDetailsForm/ProjectDetailsForm";
//Shared
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
//Styles
import {FormsContainer} from "./styles";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {isAdmin} from "../../../_shared/user/UserUtils";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {AdminBlurb} from "./components/AdminBlurb/AdminBlurb";

export const AccountView = () => {
  const {IS_SSD_LIVE} = useGlobalConfiguration();
  const {loggedInUser} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const flexDirection = useDynamicValue({
    forFour: "column",
    forEight: "row",
    forTwelve: "row",
  });
  const isAdminWithoutCustomerInformation = () => {
    return isAdmin(loggedInUser) && !customer;
  };

  const getAccountInformation = () => {
    if (isAdminWithoutCustomerInformation()) {
      return <AdminBlurb email={loggedInUser.email} />;
    }
    return (
      <>
        {IS_SSD_LIVE && <ProjectDetailsForm />}
        <AccountDetailsForm />
      </>
    );
  };

  return (
    <PageContainer>
      <FormsContainer direction={flexDirection}>
        {getAccountInformation()}
      </FormsContainer>
    </PageContainer>
  );
};
