import styled from "styled-components";
import {BREAKPOINT_TABLET, colors} from "../../theme/constants";
import homepageMobile from "../../../../../assets/images/backgrounds/homepage-mobile.png";
import homepageDesktop from "../../../../../assets/images/backgrounds/homepage-desktop.png";

export const SearchContainer = styled.div`
	padding: 24px;
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	background-image: url(${homepageMobile});
	background-size: cover;
	background-position: center;
	@media screen and (min-width: ${BREAKPOINT_TABLET}px) {
		background-image: url(${homepageDesktop});
		justify-content: center;
		align-items: center;
		background-position: center;
	}
`;

export const GeoCoderContainer = styled.div`
	min-height: 53px;
	position: ${(props) => (props.isOnMap ? "absolute" : "relative")};
	box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	height: 53px;
	font-weight: 500;
	font-size: 17px;
	line-height: 133%;
	width: 100%;
	z-index: 1;
	max-width: 400px;
	${(props) => {
		if (props.isOnMap) {
			return `width: calc(100vw - 48px);
      left: 50%;
      transform: translateX(-50%);
      top: 11px;`;
		}
	}}
	margin: 0 auto;
	.mapboxgl-ctrl-geocoder--icon {
		top: 15px;
	}
	.mapboxgl-ctrl-geocoder--icon-search {
		left: 13px;
	}
	.mapboxgl-ctrl-geocoder--icon-close {
		margin-top: 0;
	}
	.mapbox-gl-geocoder--no-results {
		padding: 16px;
	}
	.mapboxgl-ctrl-geocoder .suggestions {
		padding-top: 13px;
		border-radius: 0px;
		top: 100%;
		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
	}
	.mapboxgl-ctrl-geocoder .suggestions > li > a {
		padding-left: 48px;
		font-weight: 500;
		font-size: 17px;
		color: #415053;
	}
	.mapboxgl-ctrl-geocoder .suggestions > .active > a,
	.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
		color: #415053;
		background-color: #eceeee;
		text-decoration: none;
		cursor: pointer;
		font-weight: 500;
		font-size: 15px;
		@media screen and (min-width: ${BREAKPOINT_TABLET}px) {
			font-size: 17px;
		}
	}
	.mapboxgl-ctrl-geocoder--suggestion {
		display: flex;
		align-items: center;
		height: 48px;
		font-weight: 500;
		font-family: Maison-Book;
		flex-wrap: wrap;
	}
	.mapboxgl-ctrl-geocoder--suggestion-title {
		//display: none;
	}
	.mapboxgl-ctrl-geocoder--suggestion-address {
		font-weight: normal;
	}
	.mapboxgl-ctrl-geocoder--input:focus {
		outline: 0;
		box-shadow: none;
		outline: none;
	}
	.mapboxgl-ctrl-geocoder {
		height: 100%;
		width: 100%;
		max-width: 100%;
	}
	.mapboxgl-ctrl-geocoder--input {
		height: 53px;
		padding: 6px 35px 6px 48px;
		::-webkit-input-placeholder {
			/* Edge */
			font-weight: 500;
			font-size: 17px;
			line-height: 133%;
			/* or 23px */

			color: rgba(0, 0, 0, 0.4);
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			font-weight: 500;
			font-size: 17px;
			line-height: 133%;
			/* or 23px */

			color: rgba(0, 0, 0, 0.4);
		}

		::placeholder {
			font-weight: 500;
			font-size: 17px;
			line-height: 133%;
			/* or 23px */

			color: rgba(0, 0, 0, 0.4);
		}
	}
	.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
		z-index: 2;
		position: absolute;
		right: 8px;
		top: 16px;
		display: none;
	}
	.ant-spin {
		top: 66px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	@media screen and (min-width: ${BREAKPOINT_TABLET}px) {
		.mapboxgl-ctrl-geocoder--input:focus {
			color: #000;
			outline: 0;
			box-shadow: none;
			outline: none;
		}
	}
`;

export const IntroHeader = styled.div`
	margin: 48px auto;
	max-width: 600px;
	h1 {
		font-family: Maison Neue, Helvetica, Arial, Sans-Serif;
		font-style: normal;
		font-weight: 500;
		font-size: 41px;
		line-height: 110%;
		text-align: center;
		color: ${colors.textGrey};
	}
	h6 {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		text-align: center;
		color: ${colors.textGrey};
		margin-bottom: 0;
	}
`;
