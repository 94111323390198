import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {TextButton} from "../components/buttons/textButton";
import "./styles.scss";
import {Customer} from "@natomas/core";
import {Underline} from "../components/underline";
import {Button} from "../components/buttons/button";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {setProductGroupId} from "../../_shared/slices/CatalogSlice";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";

const ProductGroupDescription = (props) => {
  const {productGroup} = props;
  if (productGroup == null) {
    return null;
  }
  return (
    <div>
      <Underline className="option-underline" />
      <br />
      <h4 style={{marginBottom: "14px"}}>{productGroup.title}</h4>
      <div style={{marginBottom: "14px"}}>
        {productGroup.displayDescription}
      </div>
    </div>
  );
};

export const ProductGroupPicker = (props) => {
  const dispatch = useDispatch();
  const {selectedProductGroup, setSelectedProductGroup, filteredGroups} = props;
  const nodeRef = React.useRef(null);
  const {customer} = useCurrentCustomer();

  const onClickProduct = (product) => {
    setSelectedProductGroup(product);
  };
  const config = useGlobalConfiguration();

  //Array of objects to create the step bar and fill it's values

  useEffect(() => {
    if (selectedProductGroup == null && filteredGroups.length > 0) {
      setSelectedProductGroup(filteredGroups[0]);
      if (filteredGroups.length === 1) {
        dispatch(setProductGroupId(filteredGroups[0].id));
      }
    }
  }, [filteredGroups]);

  const onClickContinue = () => {
    if (selectedProductGroup) {
      dispatch(setProductGroupId(selectedProductGroup.id));
    }
  };

  let titleText = "Choose your Villa";
  if (customer != null) {
    titleText = "Welcome " + Customer.getFirstName(customer) + "!";
  }

  return (
    <div className="bath-form-wrapper">
      <h2 className="step-title">{titleText}</h2>
      <div
        className="floorplan-buttons button-layout"
        ref={nodeRef}
        style={{marginBottom: "20px"}}
      >
        {filteredGroups.map((group) => {
          return (
            <TextButton
              key={group.id}
              buttonId={group.id}
              onClick={() => {
                onClickProduct(group);
              }}
              price={""}
              buttonText={group.title}
              isSelected={selectedProductGroup?.id === group.id}
            />
          );
        })}
      </div>

      <ProductGroupDescription productGroup={selectedProductGroup} />
      <div
        className="next-button-wrapper"
        style={{
          marginLeft: "0px",
          marginTop: "30px",
          marginBottom: "30px",
          display: "block",
        }}
      >
        <Button
          componentClass="next-button red-button-next"
          id="next"
          onClick={onClickContinue}
        >
          View Homes
        </Button>
      </div>
    </div>
  );
};
