import styled from "styled-components";
import {spacing} from "./globals";

export const Container = styled.div`
  height: calc(100% - 85px);
  width: 100%;
  padding-left: 40px;
  overflow-y: auto;
  position: relative;
`;
export const Title = styled.div`
  font-size: 20px;
  margin-top: 14px;
  font-family: Maison-Medium, sans-serif;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 10px;
`;

export const ProgressContentDate = styled.div`
  width: 170px;
  margin-top: -54px;
  margin-left: -115px;
  font-size: 14px;
  align-items: center;
  text-align: center;
  position: absolute;
  font-style: italic;
  color: black;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ProgressCardContainer = styled.div`
  width: 100%;
  height: 275px;
  max-width: 90%;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 44px;
  background: white;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  align-items: center;
  color: black;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
`;

export const ProgressCardTitle = styled.div`
  font-size: 20px;
  font-family: Maison-Medium, sans-serif;
`;

export const ProgressCardProgressBar = styled.div`
  font-family: Maison-Bold, sans-serif;
  min-width: 720px;
  margin: 38px 0 20px 10px;
  width: 75%;
`;
export const ProgressContentContainer = styled.div`
  display: flex;
  min-width: 960px;
  margin-left: -10px;
  margin-right: 10px;
`;

export const ProgressContent = styled.div`
  width: 23%;
  margin-right: 2%;
  display: flex;
`;
export const ProgressContentImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

export const ProgressContentWrapper = styled.div``;

export const ProgressContentTitle = styled.div`
  flex-grow: 1;
  margin-top: 6px;
  font-family: Maison-Medium, sans-serif;
  font-size: 16px;
`;

export const ProgressContentSubtitle = styled.div`
  margin-top: 6px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
`;

export const ToDoHeader = styled.div`
  margin-top: 60px;
  font-size: 20px;
  font-family: Maison-Bold, sans-serif;
`;

export const ToDoListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export const ToDoListCardContainer = styled.div<{shortenMargin: boolean}>(
  (props) =>
    `
	margin-top: 30px;
	width: 284px;
	min-height: 186px;
	margin-right: ${props.shortenMargin === true ? "30px" : "75px"};
`
);

export const ToDoListTag = styled.div`
  background: rgba(110, 188, 233, 0.3);
  border-radius: 5px;
  padding: 5px 14px;
  position: absolute;
  width: fit-content;
  color: rgba(0, 0, 0, 0.8);
  font-family: Maison-Bold, sans-serif;
  margin-left: 10px;
  margin-top: 10px;
`;

export const ToDoListCard = styled.div`
  width: 284px;
  min-height: 186px;
  background: #ffffff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

export const ToDoText = styled.div<{imageSrc: String}>`
  padding: 20px;
  padding-top: ${(props) => (props.imageSrc ? "10px" : "50px")};
`;

export const ToDoTitle = styled.div`
  font-size: 18px;
  font-family: Maison-Medium, sans-serif;
`;
export const ToDoImage = styled.div<{imageSrc: String}>`
  width: 100%;
  height: ${(props) => (props.imageSrc ? "140px" : "0px")};
  ${(props) =>
    props.imageSrc
      ? "background-image: url(" + props.imageSrc + ");"
      : "background-image:none;"});
  padding: -20px;
  border: none;
  background-size: cover;
`;
export const ToDoSubtitle = styled.div`
  font-size: 16px;
`;
export const ToDoButton = styled.button`
  font-size: 18px;
  margin-top: 18px;
  min-width: 70%;
  border: none;
  padding: 9px 1px;
  border-radius: 100px;
  color: #fff;
  transition: all 0.25s;
  outline: none;
  font-family: Maison-Bold, sans-serif;
  background-color: ${(props) => (props.disabled ? "grey" : "black")};
`;

export const HelpContainer = styled.div`
  margin: 30px 0 20px 0;
  font-size: 18px;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
`;
export const HelpTitle = styled.div`
  margin: 50px 0 0 ${spacing.paddingLeft}px;
  font-size: 20px;
  font-family: Maison-Medium, sans-serif;
`;
export const HelpSubtitle = styled.div`
  font-size: 16px;
  margin: 0 0 20px ${spacing.paddingLeft}px;
`;
