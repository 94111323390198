// TODO What is this?
export const readDeepValue = (obj, deepPath) => {
	let s = deepPath.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
	s = s.replace(/^\./, ""); // strip a leading dot
	var a = s.split(".");
	for (var i = 0, n = a.length; i < n; ++i) {
		var k = a[i];
		if (k in obj) {
			obj = obj[k];
		} else {
			return;
		}
	}
	return obj;
};

// TODO What is this?
export const setDeepValue = (obj, deepPath, value) => {
	var pList = deepPath.split(".");
	var len = pList.length;
	for (var i = 0; i < len - 1; i++) {
		var elem = pList[i];
		if (!obj[elem]) obj[elem] = {};
		obj = obj[elem];
	}

	obj[pList[len - 1]] = value;
};

// TODO Where should this go?
export const exportToJson = (objectData) => {
	let filename = "export.json";
	let contentType = "application/json;charset=utf-8;";
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		var blob = new Blob(
			[decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))],
			{type: contentType}
		);
		navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		var a = document.createElement("a");
		a.download = filename;
		a.href =
			"data:" +
			contentType +
			"," +
			encodeURIComponent(JSON.stringify(objectData, null, 2));
		a.target = "_blank";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
};
