import React, {useEffect, useState} from "react";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {
  applyReset,
  deleteFilter,
  FilterOption,
  FilterType,
} from "../../../../_shared/catalog/filter";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {IoIosClose} from "react-icons/io";
import {IconPosition} from "../../../../_shared/generics/_shared";
//Styles
import {FilterToggleDescription, FilterTogglesContainer} from "./styles";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../../_shared/generics/page/components/PageContainer";

export const FilterToggles = (props: {offset: string}) => {
  const {filters, filteredProducts, filteredListedProducts} =
    useProductCatalog();
  const [activeFilters, setActiveFilters] = useState<FilterOption[]>([]);
  const {productGroupId} = useProductCatalog();
  useEffect(() => {
    setActiveFilters(
      filters.filter(
        (f: FilterOption) =>
          f.type !== FilterType.Address && f.type !== FilterType.ProductLine
      )
    );
  }, [filters]);

  const removeFilters = () => {
    if (productGroupId) {
      filters.forEach((f: FilterOption) => {
        if (
          f.type !== FilterType.Address &&
          f.type !== FilterType.ProductLine
        ) {
          deleteFilter(f);
        }
      });
    } else {
      applyReset();
    }
  };

  const selectFilteredProducts = () => {
    return productGroupId
      ? filteredProducts ?? []
      : filteredListedProducts ?? [];
  };

  const getFilterDescription = (): JSX.Element => {
    const p = selectFilteredProducts();
    if (activeFilters.length > 0 && p) {
      return (
        <FilterToggleDescription>
          {p?.length} total available ADUs
        </FilterToggleDescription>
      );
    } else return <></>;
  };

  return (
    <>
      <PageContainer>{getFilterDescription()}</PageContainer>
      <PageContainer
        backgroundColor={"rgba(255,255,255, 0.8)"}
        stickyOptions={{
          mode: PageContainerStickyMode.MODAL,
          offset: props.offset,
        }}
      >
        <FilterTogglesContainer removePadding={activeFilters?.length === 0}>
          {activeFilters?.length > 1 && (
            <NatButton
              fontFamily={"Maison-Book, sans-serif"}
              key={"filter-toggle-reset-all"}
              label={"Clear all filters"}
              trackingId={"clear-all-filters"}
              option={StyleOption.LIGHT_TOGGLE}
              size={TextSize.SMALL}
              clickEvent={removeFilters}
              type={"button"}
            />
          )}
          {activeFilters?.map((f: FilterOption) => {
            return (
              <NatButton
                fontFamily={"Maison-Book, sans-serif"}
                key={"filter-toggle-" + f.type + "-" + f.label}
                label={f.label}
                trackingId={"remove-filter"}
                option={StyleOption.LIGHT_TOGGLE}
                size={TextSize.SMALL}
                clickEvent={deleteFilter}
                clickEventParam={f}
                icon={{icon: <IoIosClose />, iconPosition: IconPosition.RIGHT}}
                type={"button"}
              />
            );
          })}
        </FilterTogglesContainer>
      </PageContainer>
    </>
  );
};
