import {markUserAction} from "../../../../../database/firebase/user";

export const saveVideoEvent = (
  user_id: string,
  status: string,
  videoUrl: string,
  actionId: string,
  videoRegion: string
) => {
  const payload = {
    type: "video",
    details: {
      video_url: videoUrl,
      region: videoRegion,
    },
    status: status,
    status_date: new Date().getTime(),
  };

  try {
    markUserAction(user_id, actionId, payload);
  } catch (e) {
    console.log("Video Event Save Error", e);
  }
};
