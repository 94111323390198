import styled from "styled-components";

export const AdminForm = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const InputImageContainer = styled.div`
  max-width: 100%;
  width: 530px;
  position: relative;
`;

export const InputImageWrapper = styled.div`
  height: 300px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
`;

export const InputContainer = styled.div<{fullWidth?: boolean}>`
  margin-bottom: 2rem;
  margin-right: 2rem;
  min-width: 220px;
  width: ${(props) => (props.fullWidth === true ? "100%" : "calc(33% - 40px)")};
  position: relative;
`;

export const InputTextAreaContainer = styled(InputContainer)`
  min-width: fit-content;
`;

export const InputTitle = styled.div<{bold?: boolean}>`
  color: rgba(0, 0, 0, 0.7);
  font-size: ${(props) => (props.bold ? "1.25rem" : "1rem")};
  font-family: ${(props) => (props.bold ? "Maison-Bold" : "Maison-Book")},
    sans-serif;
`;

export const InputTextField = styled.input`
  border: none;
  outline: none;
  padding: 5px 5px 8px 0;
  width: 100%;
  font-size: 18px;
  border-bottom: solid 1px #efefef;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputTextArea = styled.textarea`
  width: 500px;
  border: none;
  outline: none;
  font-size: 18px;
  border-bottom: solid 1px #efefef;
`;

export const MainView = styled.div`
  width: 100%;
  height: 100vh;
`;

export const MainContent = styled.div`
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  position: relative;
`;
export const Header = styled.div`
  height: 4rem;
  margin-bottom: 10px;
  position: relative;
`;

export const ViewOptions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    &:first-child {
      margin-bottom: 2px !important;
    }
  }
`;

export const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;

export const FirstColumn = styled.div<{width: string}>`
  width: ${(props) => props.width};
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
export const MainColumn = styled.div`
  width: 10rem;
  flex-grow: 2;
  height: 100%;
  overflow-y: auto;
`;

export const FirstColumnRow = styled.div<{active?: boolean}>`
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  cursor: pointer;
  font-size: 0.85rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  font-family: ${(props) =>
    props.active === true ? "Maison-Bold" : "Maison-Medium"};
  background-color: ${(props) =>
    props.active === true ? "rgba(130, 194, 117, 0.4)" : "white"};
`;
export const HeaderRow = styled(FirstColumnRow)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 4rem;
  line-height: 4rem;
  font-size: 1rem;
  font-family: Maison-Bold, sans-serif;
  cursor: default;
  position: relative;
`;

export const HeaderTitle = styled.div`
  font-size: 1rem;
`;

export const HeaderAddButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 2rem;
  width: 1.5rem;
  cursor: pointer;
  svg {
    height: 1rem;
    width: 1rem;
  }
`;

export const FirstColumnContent = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
`;

export const MainContentHeader = styled(FirstColumnRow)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
`;

export const SideBarContainer = styled.div`
  width: 12rem;
  height: 100%;
  background: #2a3639;
  color: white;
  display: flex;
  flex-flow: column;
  transition: width 400ms ease;

  .link-text {
    display: block;
  }
`;

export const SideBarHeader = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
`;

export const SideBarMenuItemContainer = styled.div<{
  enabled?: boolean;
  selected?: boolean;
}>`
  width: 100%;
  height: 3rem;
  position: relative;
  cursor: ${(props) => (props.enabled === true ? "pointer" : "default")};
  background-color: ${(props) =>
    props.selected === true ? "rgba(255, 255, 255, 0.1)" : "#2a3639"};
  border-right: ${(props) =>
    props.selected === true ? "3px solid white" : "3px solid #2a3639"};
  font-family: ${(props) =>
    props.selected === true ? "Maison-Bold" : "Maison-Book"};
  opacity: ${(props) => (props.enabled === true ? 1 : 0.5)};
`;

export const BottomMenuArea = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const BottomMenuItem = styled.div`
  width: 100%;
  height: 3rem;
  padding-left: 1rem;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const MenuItemContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const ExpandMenuIconFloating = styled.div<{isExpanded?: boolean}>`
  transform: ${(props) => (props.isExpanded ? "rotate(-180deg)" : "")};
  transition: 400ms ease;
  width: 24px;
  position: absolute;
  right: 20px;
  bottom: 8px;
  font-size: 26px;
  cursor: pointer;
`;

export const MenuItemTitle = styled.div`
  width: 100%;
  padding-left: 0.5rem;
  font-size: 1rem;
  position: relative;
  white-space: nowrap;
`;
