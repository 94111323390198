import React from "react";

export const OneLine = (props) => {
	const {leftText, rightText, ...otherProps} = props;

	return (
		<div className="one-line">
			<p {...otherProps}>{leftText}</p>
			<p>{rightText}</p>
		</div>
	);
};
