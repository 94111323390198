import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CurrentImage} from "../logic/currentImage";
import {Col, Container, Row} from "react-bootstrap";
import {Button} from "../components/button";
import "./styles.scss";
import {useFormik} from "formik";
import {getFormInitialValuesFromMap} from "../../_shared/application";
import {
  EMAIL_KEY,
  MULTI_FAMILY_INTAKE_FORM,
} from "../../_shared/forms/FormModels";
import {HiddenInput} from "../components/hiddenInput";
import {
  constructValidation,
  constructValidationWithStructure,
} from "../../_shared/forms/FormHelper";
import {intakeFormBackAction} from "../logic/navigation";
import {useMultiFamilyIntakeFormValues} from "../logic/MultiFamilyIntakeForm";
import {MFConfirmation} from "./MultiFamilyConfirmation";
import {ContactForm} from "../components/contactForm";
import * as Yup from "yup";

const MULTI_FAMILY_INTAKE_FORM_PARDOT_URL =
  "https://go.villahomes.com/l/909732/2021-10-25/56w5q";

const MULTI_FAMILY_VALIDATION_STRUCTURE = {
  [EMAIL_KEY]: Yup.string().email("Invalid email").required("Required"),
};

const MultiFamilyStep = (props) => {
  const step = useSelector((state) => state.intakeForm.step);

  switch (step) {
    case "step-contact":
      return <ContactForm {...props} />;
    case "step-confirmation":
      return <MFConfirmation {...props} />;
    default:
      return <ContactForm {...props} />;
  }
};

export const MultiFamilyIntakeForm = () => {
  const step = useSelector((state) => state.intakeForm.step);
  const form = MULTI_FAMILY_INTAKE_FORM;
  const formik = useFormik({
    initialValues: getFormInitialValuesFromMap(form),
    validationSchema: constructValidationWithStructure(
      form,
      MULTI_FAMILY_VALIDATION_STRUCTURE
    ),
    onSubmit: (values) => {},
  });

  const {information, finalSubmit, isFormValid} =
    useMultiFamilyIntakeFormValues(formik);

  return (
    <Container fluid>
      <Row className="full-height">
        <Col
          lg={6}
          className={`current-image`}
          style={{paddingRight: 0, height: "100%"}}
        >
          <div className="image-wrapper">
            <div className="top-bar mobile-only">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FVilla_Logo_Marks_CMYK_Secondary%20Mark.svg?alt=media&token=4b6666f3-6f9c-44bd-a51d-db1dc12e5f22"
                width="85px"
                alt="villa"
              />

              <Button
                buttonClasses="close-button"
                onClick={() => {
                  intakeFormBackAction(step);
                }}
              />
            </div>
            <CurrentImage />
          </div>
        </Col>

        <Col lg={6} className={`current-step ${step}`}>
          <div className="top-bar">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FVilla_Logo_Marks_CMYK_Secondary%20Mark.svg?alt=media&token=4b6666f3-6f9c-44bd-a51d-db1dc12e5f22"
              width="85px"
              alt="villa"
            />
            <Button
              buttonClasses="close-button"
              onClick={() => {
                intakeFormBackAction(step);
              }}
            />
          </div>
          <div
            className={
              step === "step-zero" || step === "step-confirmation"
                ? "step-wrapper-full-height"
                : "step-wrapper"
            }
          >
            <MultiFamilyStep
              formik={formik}
              finalSubmit={finalSubmit}
              form={form}
              isFormValid={isFormValid}
            />
          </div>

          <form
            className="hidden"
            action={MULTI_FAMILY_INTAKE_FORM_PARDOT_URL}
            method="POST"
            target={"hiddenFrame"}
          >
            {information.map((item) => (
              <HiddenInput
                inputName={item.inputName}
                inputValue={item.inputValue != null ? item.inputValue : "null"}
                inputType={item.inputType}
                key={item.inputName}
              />
            ))}

            <button
              className="hidden"
              type="submit"
              id="hidden-submit-button"
            />
          </form>
          <iframe
            name="hiddenFrame"
            width="0"
            height="0"
            style={{display: "none"}}
          />
        </Col>
      </Row>
    </Container>
  );
};
