import styled from "styled-components";
import React from "react";
import {AddressInput} from "../../../../_shared/generics/address-input/AddressInput";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {ERROR_COLOR} from "../../../../_shared/colors";

const RowContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowIcon = styled.img`
  margin-right: 1rem;
`;

const AddressContainer = styled.div`
  position: relative;
  height: 100%;
`;

const DetailInput = styled.input`
  width: 100%;
  height: 3rem;
  border: ${(props) => (props.error ? "2px solid" : "1px solid")};
  border-radius: 2px;
  border-color: ${(props) => (props.error ? ERROR_COLOR : "default")};
`;

export const PersonalInfoRow = (props) => {
  const {logo, type, title, value, editInfo, formik, setAddressObject} = props;
  const width = useDynamicValue(fullColumnWidths);
  let rowContent = null;
  switch (type) {
    case "name":
      if (editInfo) {
        rowContent = (
          <>
            <PageElement size={width / 2} height={"auto"}>
              <DetailInput
                spellCheck={false}
                placeholder={"First Name"}
                name={"firstName"}
                type="text"
                onChange={formik.handleChange}
                value={formik.values["firstName"]}
                error={formik.errors["firstName"]}
              />
            </PageElement>
            <PageElement size={width / 2} height={"auto"}>
              <DetailInput
                spellCheck={false}
                placeholder={"Last Name"}
                name={"lastName"}
                type="text"
                onChange={formik.handleChange}
                value={formik.values["lastName"]}
                error={formik.errors["lastName"]}
              />
            </PageElement>
          </>
        );
      }
      break;
    case "phone":
      //value = formatPhoneNumber(phoneNumber);
      if (editInfo) {
        rowContent = (
          <PageElement size={width} height={"auto"}>
            <DetailInput
              spellCheck={false}
              placeholder={"Phone Number"}
              name={"phoneNumber"}
              type="tel"
              onChange={formik.handleChange}
              value={formik.values["phoneNumber"]}
              error={formik.errors["phoneNumber"]}
            />
          </PageElement>
        );
      }
      break;
    case "address":
      if (editInfo) {
        rowContent = (
          <PageElement size={width} height={"3rem"}>
            <AddressContainer>
              <AddressInput
                placeholder={"Project Address"}
                setAddress={formik.handleChange}
                setAddressObject={setAddressObject}
                value={formik.values["address"]}
              />
            </AddressContainer>
          </PageElement>
        );
      }
  }

  if (rowContent != null) {
    return (
      <RowContainer editing={editInfo}>
        <RowIcon alt={"icon"} src={logo} height={"48px"} />
        {rowContent}
      </RowContainer>
    );
  }

  return (
    <RowContainer editing={editInfo}>
      <RowIcon alt={"icon"} src={logo} height={"48px"} />
      <RowContent>
        <div style={{color: "rgba(0,0,0,0.6)"}}>{title}</div>
        <div>{value}</div>
      </RowContent>
    </RowContainer>
  );
};
