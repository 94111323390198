import React, {useState} from "react";
import {useFormik} from "formik";
import {ConditionalRendering} from "../../../../_shared/components/ConditionalRendering";
import {getFormInitialValues} from "../../../../../_shared/application";
import {
  ADDRESS_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  PHONE_NUMBER_KEY,
  PROJECT_DETAILS_FORM,
} from "../../../../../_shared/forms/FormModels";
import {PersonalInfoRow} from "../PersonalInfoRow";
import profile from "../../../../../../assets/icons/pngs/profile_circle.png";
import phone from "../../../../../../assets/icons/pngs/phone_circle.png";
import pin from "../../../../../../assets/icons/pngs/pin_circle.png";
import {useGlobalConfiguration} from "../../../../../_shared/hooks/useGlobalConfiguration";
import {
  Address,
  Customer,
  formatPhoneNumber,
  IAddressDetails,
  ICustomerInfo,
  IProjectInfo,
  PhoneNumber,
  PhoneNumberFormatType,
  Project,
} from "@natomas/core";
import {constructValidation} from "../../../../../_shared/forms/FormHelper";
import {useDeepEffect} from "../../../../../_shared/hooks/useDeepEffect";
import {saveProjectInfo} from "../../../../../../database/firebase/project";
import {useCurrentProject} from "../../../../../_shared/hooks/useCurrentProject";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {ButtonContainer, SectionHeader} from "../../styles";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {
  HOME_PAGE_ID,
  toPage,
} from "../../../../_shared/navigation/PageNavigation";
import {
  JourneyStepName,
  useTracking,
} from "../../../../../_shared/hooks/useTracking";
import {
  changeFilter,
  getAddressFilter,
} from "../../../../../_shared/catalog/filter";

const lineItems = [
  {title: "Contact's Name", logo: profile, type: "name"},
  {title: "Contact's Phone", logo: phone, type: "phone"},
  {title: "Project Address", logo: pin, type: "address"},
];

const getNewProjectInfo = (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  address: IAddressDetails,
  currentConfigurationId: string,
  projectSummary?: IProjectInfo | null
) => {
  const cleanedPhoneNumber =
    formatPhoneNumber(phoneNumber, PhoneNumberFormatType.RAW) ?? "";
  const phoneDetails = PhoneNumber.create("primary", cleanedPhoneNumber);
  if (projectSummary) {
    let newProject;
    newProject = Project.setClientFirstName(projectSummary, firstName);
    newProject = Project.setClientLastName(newProject, lastName);
    newProject = Project.setClientPhone(newProject, phoneDetails);
    newProject = Project.setAddress(newProject, address);
    return newProject;
  } else {
    return Project.dataToProject(
      currentConfigurationId,
      firstName,
      lastName,
      phoneDetails,
      address,
      undefined
    );
  }
};

const getValue = (
  title: string,
  customer: ICustomerInfo,
  projectSummary?: IProjectInfo | null
) => {
  if (!projectSummary && !customer) return null;
  if (!projectSummary && customer) {
    if (title === "Contact's Name") {
      return Customer.getFullName(customer);
    } else if (title === "Contact's Phone") {
      return formatPhoneNumber(
        Customer.getPrimaryContactPhoneNumber(customer),
        PhoneNumberFormatType.PARENTHESIS_AND_DASHES
      );
    } else if (title === "Project Address") {
      return Address.getFullAddress(Customer.getContactAddress(customer));
    }
  } else if (projectSummary) {
    if (title === "Contact's Name") {
      return Project.getClientFullName(projectSummary);
    } else if (title === "Contact's Phone") {
      return formatPhoneNumber(
        PhoneNumber.getNumber(Project.getClientPhone(projectSummary)),
        PhoneNumberFormatType.PARENTHESIS_AND_DASHES
      );
    } else if (title === "Project Address") {
      return projectSummary?.address
        ? Address.getFullAddress(Project.getAddress(projectSummary))
        : null;
    }
  } else return null;
};

export const ProjectDetailsForm = () => {
  const {IS_SSD_LIVE} = useGlobalConfiguration();
  const {customer} = useCurrentCustomer();
  const {markJourneyEvent} = useTracking();
  const {currentConfigurationId, configuration, projectSummary} =
    useCurrentProject();
  const [editInfo, setEditInfo] = useState(false);
  const [addressObject, setAddressObject] = useState<IAddressDetails | null>(
    null
  );
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });

  const setFormikValues = () => {
    if (!projectSummary && !customer) return;
    else if (!projectSummary && customer) {
      formik.setFieldValue(
        FIRST_NAME_KEY,
        Customer.getFirstName(customer) ?? ""
      );
      formik.setFieldValue(LAST_NAME_KEY, Customer.getLastName(customer) ?? "");
      formik.setFieldValue(
        ADDRESS_KEY,
        Customer.getContactAddress(customer) ?? ""
      );
      formik.setFieldValue(
        PHONE_NUMBER_KEY,
        Customer.getPrimaryContactPhoneNumber(customer) ?? ""
      );
    } else if (projectSummary) {
      formik.setFieldValue(
        FIRST_NAME_KEY,
        Project.getClientFirstName(projectSummary) ?? ""
      );
      formik.setFieldValue(
        LAST_NAME_KEY,
        Project.getClientLastName(projectSummary) ?? ""
      );
      formik.setFieldValue(
        ADDRESS_KEY,
        Project.getAddress(projectSummary) ?? ""
      );
      formik.setFieldValue(
        PHONE_NUMBER_KEY,
        PhoneNumber.getNumber(Project.getClientPhone(projectSummary)) ?? ""
      );
    }
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(PROJECT_DETAILS_FORM),
    validationSchema: constructValidation(PROJECT_DETAILS_FORM),
    onSubmit: (values) => {
      // @ts-ignore
      const {firstName, lastName, phoneNumber} = values;
      setEditInfo(false);
      const info = getNewProjectInfo(
        firstName,
        lastName,
        phoneNumber,
        addressObject ??
          projectSummary?.address ??
          Customer.getContactAddress(customer),
        currentConfigurationId,
        projectSummary
      );
      saveProjectInfo(currentConfigurationId, info).then(() => {
        markJourneyEvent(JourneyStepName.INFO_SUBMITTED);
      });
      changeFilter(getAddressFilter(info.address.county, info.address.state));
    },
  });

  useDeepEffect(() => {
    // Everytime user updates - update form
    setFormikValues();
    if (projectSummary) setAddressObject(Project.getAddress(projectSummary));
    else if (customer) setAddressObject(Customer.getContactAddress(customer));
  }, [IS_SSD_LIVE, projectSummary, customer]);

  useDeepEffect(() => {
    if (editInfo) {
      setFormikValues();
    }
  }, [editInfo]);

  return (
    <>
      {currentConfigurationId && (
        <PageElement size={size} height={"auto"}>
          <SectionHeader>Project Information</SectionHeader>
          {lineItems.map((lineItem, index) => (
            <PersonalInfoRow
              {...lineItem}
              key={"personal-info-" + index}
              value={getValue(lineItem.title, customer, projectSummary)}
              editInfo={editInfo}
              formik={formik}
              setAddressObject={setAddressObject}
            />
          ))}
          <ButtonContainer>
            <ConditionalRendering
              mode={"owner"}
              approvedRender={
                <NatButton
                  type={"button"}
                  trackingId={
                    "project-details-" + editInfo
                      ? "discard-changes"
                      : "edit-info"
                  }
                  label={editInfo ? "Discard Changes" : "Edit Info"}
                  clickEvent={() => {
                    setEditInfo(!editInfo);
                  }}
                  option={StyleOption.SECONDARY}
                />
              }
              deniedRender={null}
            />
            <ConditionalRendering
              mode={"owner"}
              show={editInfo}
              approvedRender={
                <NatButton
                  clickEvent={() => {
                    return formik.submitForm();
                  }}
                  disabled={
                    Object.keys(formik.errors).length !== 0 || !addressObject
                  }
                  trackingId={"project-details-save-changes"}
                  label={"Save Changes"}
                  type={"button"}
                  option={StyleOption.PRIMARY}
                  spinnerEnabled={true}
                />
              }
            />
            <ConditionalRendering
              mode={"owner"}
              show={!editInfo && !projectSummary}
              approvedRender={
                <NatButton
                  clickEvent={() => {
                    formik.submitForm();
                    toPage(HOME_PAGE_ID);
                  }}
                  trackingId={"project-details-confirm-and-continue"}
                  label={"Confirm and continue"}
                  type={"button"}
                  option={StyleOption.ColorWillDarken}
                  spinnerEnabled={true}
                />
              }
            />
            <ConditionalRendering
              mode={"owner"}
              show={
                !editInfo && projectSummary !== null && !configuration?.product
              }
              approvedRender={
                <NatButton
                  clickEvent={() => {
                    toPage(HOME_PAGE_ID);
                  }}
                  trackingId={"project-details-continue"}
                  label={"Continue"}
                  type={"button"}
                  option={StyleOption.ColorWillDarken}
                  spinnerEnabled={false}
                />
              }
            />
          </ButtonContainer>
        </PageElement>
      )}
    </>
  );
};
