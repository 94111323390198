import {IImageDetails, ImageCategory, ImageStatus} from "@natomas/core";

export const getImageDetailsByCategory = (
  product: any,
  category: ImageCategory
) => {
  const productImages = product?.images?.filter(
    (imageDetails: IImageDetails) => {
      if (
        imageDetails.status !== ImageStatus.UNLISTED &&
        imageDetails.categories?.includes(category)
      ) {
        return imageDetails;
      }
    }
  );

  return productImages ?? [];
};
