import {
  ProgressCardProgressBar,
  ProgressContentDate,
  ProgressCardTitle,
  ProgressContent,
  ProgressContentContainer,
  ProgressContentImage,
  ProgressContentSubtitle,
  ProgressContentTitle,
  ProgressContentWrapper,
  ProgressCardContainer,
} from "../styles";
// @ts-ignore
import {ProgressBar, Step} from "react-step-progress-bar";
import {CheckMark} from "../../../../_shared/generics/CheckMark";
import call from "../../../../../assets/icons/pngs/Call.png";
import ADU from "../../../../../assets/icons/pngs/ADU.png";
import lightBulb from "../../../../../assets/icons/pngs/LightBulb.png";
import documentImage from "../../../../../assets/icons/pngs/Document.png";
import React from "react";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
// @ts-ignore
import {getDateObject} from "../../../_shared/utilities.ts";
import {
  DESIGN_PAGE_ID,
  PROPOSAL_PAGE_ID,
  SITE_PAGE_ID,
  toPage,
} from "../../../_shared/navigation/PageNavigation";
import dateFormat from "dateformat";
import {CustomerProjectInfo} from "@natomas/core";

export const ProgressCard = () => {
  const {status, mergedProjectInfos} = useCurrentProject();
  const {designReady, proposalReady, sitePlanReady} = status;
  const project = mergedProjectInfos as CustomerProjectInfo;

  let percentage = 33;
  if (proposalReady) {
    percentage = 100;
  } else if (designReady && sitePlanReady) {
    percentage = 90;
  } else if (sitePlanReady) {
    percentage = 66;
  }

  let dateFormatString: string = "mmm dS";
  let kickOffDate = getDateObject(project, "kick-off");
  let projectProposal = getDateObject(project, "proposal");

  return (
    <ProgressCardContainer>
      <ProgressCardTitle>Your Proposal Progress</ProgressCardTitle>
      <ProgressCardProgressBar>
        <ProgressBar
          filledBackground={"#82c275"}
          height={"3px"}
          percent={percentage}
        >
          {[...Array(4)].map((step, index) => {
            return (
              <Step key={"step-" + index} transition="scale">
                {(element: any) => (
                  <CheckMark
                    size={20}
                    strokeWidth={2}
                    incomplete={!element.accomplished}
                  />
                )}
              </Step>
            );
          })}
        </ProgressBar>
      </ProgressCardProgressBar>
      <ProgressContentContainer>
        <ProgressContent>
          <ProgressContentImage src={call} />
          <ProgressContentWrapper>
            <ProgressContentDate>
              {kickOffDate
                ? dateFormat(new Date(kickOffDate), dateFormatString)
                : ""}
            </ProgressContentDate>
            <ProgressContentTitle>Kick-Off Video Call</ProgressContentTitle>
            <ProgressContentSubtitle>
              Kick-off call completed
            </ProgressContentSubtitle>
          </ProgressContentWrapper>
        </ProgressContent>
        <ProgressContent
          className={"clickable"}
          onClick={() => {
            toPage(SITE_PAGE_ID);
          }}
        >
          <ProgressContentImage src={ADU} />
          <ProgressContentWrapper>
            <ProgressContentTitle>Site Plan Uploaded</ProgressContentTitle>
            <ProgressContentSubtitle>
              A detailed site plan with your ADU location, setbacks, and
              utilities infrastructure
            </ProgressContentSubtitle>
          </ProgressContentWrapper>
        </ProgressContent>
        <ProgressContent
          className={"clickable"}
          onClick={() => {
            toPage(DESIGN_PAGE_ID);
          }}
        >
          <ProgressContentImage src={lightBulb} />
          <ProgressContentWrapper>
            <ProgressContentTitle>Your ADU Design</ProgressContentTitle>
            <ProgressContentSubtitle>
              Confirm floor plan layout and upgrades to your unit
            </ProgressContentSubtitle>
          </ProgressContentWrapper>
        </ProgressContent>
        <ProgressContent
          className={status.proposalReady ? "clickable" : ""}
          onClick={() => {
            if (status.proposalReady) {
              toPage(PROPOSAL_PAGE_ID);
            }
          }}
        >
          <ProgressContentImage src={documentImage} />
          <ProgressContentWrapper>
            <ProgressContentDate>
              {projectProposal
                ? dateFormat(new Date(projectProposal), dateFormatString)
                : ""}
            </ProgressContentDate>
            <ProgressContentTitle>Project Proposal</ProgressContentTitle>
            <ProgressContentSubtitle>
              Congratulations, your Villa project proposal is ready for review!
            </ProgressContentSubtitle>
          </ProgressContentWrapper>
        </ProgressContent>
      </ProgressContentContainer>
    </ProgressCardContainer>
  );
};
