import {getFormInitialValues} from "../../../_shared/application";
import {ResetPasswordFormModel} from "./models";
import {ResetPasswordFormValidation} from "./validation";
import {EMAIL_KEY} from "../../_shared/models";

export const getResetPasswordFormik = (email?: string) => {
  const initialValues: any = getFormInitialValues(ResetPasswordFormModel);

  if (email) {
    initialValues[EMAIL_KEY] = email;
  }

  return {
    initialValues: initialValues,
    validationSchema: ResetPasswordFormValidation,
    onSubmit: (values: {[x: string]: string}) => {},
  };
};
