import styled from "styled-components";

export const MatrixTableWrapper = styled.div`
  height: calc(100vh - 70px);
  width: 100%;
  overflow: auto;
`;

export const MatrixTableHeaderRow = styled.tr`
  top: 0;
  position: sticky;
  td {
    vertical-align: middle !important;
  }
`;

export const MatrixTableHeaderCol = styled.tr`
  td {
    vertical-align: middle !important;
  }
`;
