import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "react-step-progress-bar/styles.css";
import {Container, Subtitle, Title} from "./styles";
import {ToDoList} from "./components/ToDoList";
import {ProgressCard} from "./components/ProgressCard.tsx";
import {getGreeting, getTodoList} from "./logic";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {PreDepositHomeView} from "./PreDepositHomeView";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";

export const HomeView = () => {
  const {status} = useCurrentProject();
  const {customer} = useCurrentCustomer();
  const {IS_SSD_LIVE} = useGlobalConfiguration();

  if (IS_SSD_LIVE) {
    return <PreDepositHomeView />;
  }

  return (
    <Container>
      <Title>{getGreeting(customer)}</Title>
      <Subtitle>
        Welcome to the Villa Customer Portal! This is your home page, where you
        can check your proposal progress and any to-do items.
      </Subtitle>
      <ProgressCard />
      <ToDoList todoList={getTodoList(status)} />
    </Container>
  );
};
