import styled from "styled-components";
import {FOOTER_Z_INDEX} from "../../../../_shared/styles";

export const MobileStickyBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4rem;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px 14px 20px 14px;
  background-color: white;
  z-index: ${FOOTER_Z_INDEX};
  border-top: 1px solid black;
`;

export const MobileStickyDescriptionPrefixText = styled.div`
  padding-right: 0.25rem;
`;
export const MobileStickyDescriptionText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 133%;
  text-decoration-line: underline;
  color: #000000;
`;
export const MobileStickyCallToActionButtonContainer = styled.div`
  text-align: right;
  width: 100%;
`;
export const MobileStickyFooterBumper = styled.div`
  width: 100%;
  //height: 4rem;
`;
