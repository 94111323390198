import React, {useState} from "react";
import {BudgetSummaryView} from "./BudgetSummaryView";
import {NextStepsView} from "./NextStepsView";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {
  Container,
  ProposalContainer,
  Tab,
  TabContainer,
  TabRow,
} from "./styles";
import {getParameter} from "../../../_shared/cookies";
import {isBlankString} from "@natomas/core";
import {downloadImageToDesktop} from "../SitePlanView/logic";
import {SitePlanButton, SitePlanButtonContainer} from "../SitePlanView/styles";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {FiDownload, FiRefreshCw} from "react-icons/fi";
import {ConditionalRendering} from "../../_shared/components/ConditionalRendering";
import {GeneratePDFModal} from "./components/GeneratePDFModal";
import {storage} from "../../../../database/firebase";

const BUDGET_SUMMARY_TAB = "Budget Summary";
const NEXT_STEPS_TAB = "Next Steps & Timeline";

const tabComponent = (tab, setTab, name) => {
  return (
    <Tab
      onClick={() => {
        setTab(name);
      }}
      active={tab === name}
    >
      {name}
    </Tab>
  );
};

export const ProposalView = () => {
  const [tab, setTab] = useState(
    !isBlankString(getParameter("success", true))
      ? NEXT_STEPS_TAB
      : BUDGET_SUMMARY_TAB
  );

  const [showModal, setShowModal] = useState(false);
  const {currentConfigurationId, mergedProjectInfos} = useCurrentProject();

  const config = useGlobalConfiguration();

  return (
    <ProposalContainer>
      <GeneratePDFModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentConfigurationId={currentConfigurationId}
      />
      <TabRow>
        <TabContainer>
          {tabComponent(tab, setTab, BUDGET_SUMMARY_TAB)}
          {tabComponent(tab, setTab, NEXT_STEPS_TAB)}
        </TabContainer>
        <SitePlanButtonContainer
          hideElement={!config.show_proposal_pdf_download}
        >
          <ConditionalRendering
            mode={"admin-activated"}
            approvedRender={
              <SitePlanButton
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <FiRefreshCw />
                <div style={{marginLeft: "6px", marginTop: "2px"}}>
                  {"Generate PDF"}
                </div>
              </SitePlanButton>
            }
            deniedRender={null}
          />
          <SitePlanButton
            primary={true}
            hidden={!mergedProjectInfos?.proposalPDFStoragePath}
            onClick={() => {
              return storage
                .ref(mergedProjectInfos?.proposalPDFStoragePath)
                .getDownloadURL()
                .then((url) => {
                  return downloadImageToDesktop(url, "Project_Proposal.pdf");
                });
            }}
          >
            <FiDownload />
            <div style={{marginLeft: "6px", marginTop: "2px"}}>
              {"Download PDF"}
            </div>
          </SitePlanButton>
        </SitePlanButtonContainer>
      </TabRow>

      <Container>
        <BudgetSummaryView active={tab === BUDGET_SUMMARY_TAB} />
        <NextStepsView active={tab === NEXT_STEPS_TAB} />
      </Container>
    </ProposalContainer>
  );
};
