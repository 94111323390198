import {
  ColumnText,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
} from "../styles/SiteWorkTableStyles";
import React from "react";

export const TableRow = (props) => {
  const {items} = props;

  return (
    <>
      <FirstColumn>
        <ColumnText className={"bold"}>{items[0]}</ColumnText>
      </FirstColumn>
      <SecondColumn>
        <ColumnText className={"bold"}>{items[1]}</ColumnText>
      </SecondColumn>
      <ThirdColumn>
        <ColumnText>{items[2]}</ColumnText>
      </ThirdColumn>
    </>
  );
};
