import {setURLQueryKeyValue} from "../../../_shared/navigation/_helpers";
import {IoPeopleCircleOutline} from "react-icons/io5";
import {AiOutlineFundProjectionScreen} from "react-icons/ai";
import {FiClipboard} from "react-icons/fi";
import React from "react";
import {HiOutlineClipboardCheck} from "react-icons/hi";
import {HomeView} from "../../views/DashboardView";
import {AccountView} from "../../views/AccountView";
import {DesignStudioView} from "../../views/DesignStudioView";
import {SitePlanView} from "../../views/SitePlanView";
import {ProposalView} from "../../views/ProposalView";
import {TeamView} from "../../views/TeamView";
import {MappingView} from "../../views/MappingView";
import {YourUnitView} from "../../views/YourUnitView";
import {PaymentTab} from "../../views/PaymentsView/tabs/PaymentTab";
import {PreProposalTab} from "../../views/PaymentsView/tabs/PreProposalTab";
import {MdSpaceDashboard} from "react-icons/md";
import {GiCrane} from "react-icons/gi";
import {RiBuilding3Line} from "react-icons/ri";

export const HOME_PAGE_ID = "home";
export const SUBMIT_ASSESSMENT_PAGE_ID = "submit";
export const OVERVIEW_ASSESSMENT_PAGE_ID = "assessment";
export const TEAM_PAGE_ID = "team";
export const DESIGN_PAGE_ID = "design";
export const SITE_PAGE_ID = "site";
export const PROPOSAL_PAGE_ID = "proposal";
export const PROFILE_PAGE_ID = "profile";
export const MAPPING_PAGE_ID = "map";
export const UNIT_PAGE_ID = "unit";

const GET_A_PROPOSAL_TAB = "Overview";
const NEXT_STEPS_TAB = "Submit";

const PRE_DEPOSIT_PROPOSAL_TABS = [
  {tabPageId: OVERVIEW_ASSESSMENT_PAGE_ID, name: GET_A_PROPOSAL_TAB},
  {tabPageId: SUBMIT_ASSESSMENT_PAGE_ID, name: NEXT_STEPS_TAB},
];

export const TABS = {
  [HOME_PAGE_ID]: {
    title: "Dashboard",
    pageId: HOME_PAGE_ID,
    icon: <MdSpaceDashboard />,
    component: <HomeView />,
  },
  [SUBMIT_ASSESSMENT_PAGE_ID]: {
    title: "Project Proposal",
    pageId: SUBMIT_ASSESSMENT_PAGE_ID,
    icon: <HiOutlineClipboardCheck />,
    component: <PaymentTab />,
    // headerChildren: <PortalHeaderTabs tabs={PRE_DEPOSIT_PROPOSAL_TABS} />,
  },
  [OVERVIEW_ASSESSMENT_PAGE_ID]: {
    title: "Project Proposal",
    pageId: OVERVIEW_ASSESSMENT_PAGE_ID,
    icon: <HiOutlineClipboardCheck />,
    component: <PreProposalTab />,
    // headerChildren: <PortalHeaderTabs tabs={PRE_DEPOSIT_PROPOSAL_TABS} />,
  },
  [TEAM_PAGE_ID]: {
    title: "Team",
    pageId: TEAM_PAGE_ID,
    icon: <IoPeopleCircleOutline />,
    component: <TeamView />,
  },
  [MAPPING_PAGE_ID]: {
    title: "Site Map",
    pageId: MAPPING_PAGE_ID,
    icon: <FiClipboard />,
    component: <MappingView />,
  },
  [SITE_PAGE_ID]: {
    title: "Your Site",
    pageId: SITE_PAGE_ID,
    icon: <GiCrane />,
    component: <SitePlanView />,
    natPage: false,
  },
  [DESIGN_PAGE_ID]: {
    title: "Your Home",
    pageId: DESIGN_PAGE_ID,
    icon: <RiBuilding3Line />,
    component: <DesignStudioView />,
    natPage: false,
  },
  [PROPOSAL_PAGE_ID]: {
    title: "Project Proposal",
    pageId: PROPOSAL_PAGE_ID,
    icon: <AiOutlineFundProjectionScreen />,
    component: <ProposalView />,
    natPage: false,
  },
  [PROFILE_PAGE_ID]: {
    title: "Profile",
    pageId: PROFILE_PAGE_ID,
    component: <AccountView />,
  },
  [UNIT_PAGE_ID]: {
    title: "Your Home",
    pageId: UNIT_PAGE_ID,
    icon: <RiBuilding3Line />,
    component: <YourUnitView />,
    natPage: false,
  },
};

export const DEFAULT_MENU_ITEMS = () => {
  return [
    HOME_PAGE_ID,
    TEAM_PAGE_ID,
    UNIT_PAGE_ID,
    MAPPING_PAGE_ID,
    OVERVIEW_ASSESSMENT_PAGE_ID,
  ];
};

export const DEFAULT_MENU_ITEMS_NO_MAP = () => {
  return [
    HOME_PAGE_ID,
    TEAM_PAGE_ID,
    UNIT_PAGE_ID,
    OVERVIEW_ASSESSMENT_PAGE_ID,
  ];
};
export const PROJECT_MENU_ITEMS = () => {
  return [
    HOME_PAGE_ID,
    TEAM_PAGE_ID,
    DESIGN_PAGE_ID,
    SITE_PAGE_ID,
    PROPOSAL_PAGE_ID,
  ];
};

export const getMenuItems = (isCustomerPreDeposit, IS_MAPPING_ENABLED) => {
  if (isCustomerPreDeposit) {
    if (IS_MAPPING_ENABLED) {
      return DEFAULT_MENU_ITEMS();
    }
    return DEFAULT_MENU_ITEMS_NO_MAP();
  }
  return PROJECT_MENU_ITEMS();
};

export const getTitleForPageId = (pageId) => {
  if (pageId) return TABS[pageId]?.title;
  else return TABS[HOME_PAGE_ID].title;
};

export const getHeaderChildrenForPageId = (pageId) => {
  if (pageId) return TABS[pageId]?.headerChildren;
  else return <></>;
};

export const getPageComponent = (pageId) => {
  if (pageId) return TABS[pageId]?.component;
  else return TABS[HOME_PAGE_ID].component;
};

export const isNatPage = (pageId) => {
  if (pageId) return TABS[pageId]?.natPage ?? true;
  else return true;
};

export const toPage = (pageId) => {
  setURLQueryKeyValue("page", pageId);
};
