import styled from "styled-components";
import {WHITE} from "../../colors";
import {LOGIN_PANEL_Z_INDEX} from "../../styles";
import {isMobile} from "../../navigation";

const FlexContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${LOGIN_PANEL_Z_INDEX};
  display: flex;
  flex-direction: ${isMobile() ? "column-reverse" : "row"};
  justify-content: space-between;
  align-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: ${WHITE};
`;

const LeftHalfContainer = styled.div`
   {
    width: ${isMobile() ? "100%" : "50%"};
    height: ${isMobile() ? "auto" : "100%"};
    display: flex;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
  }
`;

const RightHalfContainer = styled.div`
   {
    width: ${isMobile() ? "100%" : "50%"};
    height: ${isMobile() ? "auto" : "100%"};
    display: flex;
    flex-direction: column;
  }
`;

// For RightHalfContainer
const HeaderContainer = styled.div`
   {
    width: 100%;
    height: 10%;
    margin-top: ${isMobile() ? "1rem" : "0"};
    display: flex;
    justify-content: center;
    align-content: center;
  }
`;

const ContentContainer = styled.div`
   {
    width: 100%;
    max-width: 35rem;
    height: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
  }
`;

export {
  FlexContainer,
  LeftHalfContainer,
  RightHalfContainer,
  HeaderContainer,
  ContentContainer,
};
