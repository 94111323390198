import React from "react";
import {Button} from "antd";
import {MapToolbarContainer} from "./styled";
import {
  DimensionsIcon,
  MapIcon,
  MeasureIcon,
  SatelliteIcon,
  TrashIcon,
} from "../../../../../assets/icons";

export const MapToolbar = ({
  handleTrashBtnClick,
  isMobile,
  isSatelliteActive,
  isDimensionActive,
  isMeasurementActive,
  setIsMeasurementActive,
  setIsDimensionActive,
  toggleSatelliteView,
}) => {
  return (
    <MapToolbarContainer>
      {!isMobile && handleTrashBtnClick && (
        <Button onClick={handleTrashBtnClick}>
          <TrashIcon />
        </Button>
      )}
      {!isMobile && (
        <Button
          onClick={setIsMeasurementActive}
          style={{
            backgroundColor: isMeasurementActive ? "black" : "white",
          }}
        >
          <MeasureIcon isActive={isMeasurementActive} />
        </Button>
      )}
      <Button
        onClick={toggleSatelliteView}
        style={{
          backgroundColor: isSatelliteActive ? "black" : "white",
        }}
      >
        {isSatelliteActive ? <MapIcon /> : <SatelliteIcon />}
      </Button>
      <Button
        onClick={setIsDimensionActive}
        style={{
          backgroundColor: isDimensionActive ? "black" : "white",
        }}
      >
        <DimensionsIcon isActive={isDimensionActive} />
      </Button>
    </MapToolbarContainer>
  );
};
