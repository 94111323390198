import styled from "styled-components";
import {LOADING_PANEL_Z_INDEX} from "../../styles";

export const LoadingPanelContainer = styled.div<{
  hide: boolean;
  opacity: number;
}>`
  z-index: ${LOADING_PANEL_Z_INDEX};
  background-color: lightgoldenrodyellow;
  position: fixed;
  height: 100%;
  width: 100%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  opacity: ${(props) => props.opacity};
  transition: opacity 250ms linear;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`;

export const ImageContainer = styled.div`
  height: 3rem;
  width: 100%;
  img {
    height: 3rem;
    width: 100%;
  }
  margin-bottom: 3rem;
`;

export const LoadingPanelSpinner = styled.div<{size: string}>`
  width: 100%;
  height: 100%;
  svg {
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
    animation: rotation 2s infinite linear;
  }
`;

export const SpinnerContainer = styled.div`
  height: 2rem;
  width: 2rem;
`;
