//React
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
//Core
import {Customer} from "@natomas/core";
//Assets
import {FaRegEdit, FaUserFriends} from "react-icons/fa";
import {HiOutlinePresentationChartLine} from "react-icons/hi";
import {AiOutlineDoubleRight} from "react-icons/ai";
import {BiLogOut} from "react-icons/bi";
import {IoMdSwap} from "react-icons/io";
import Logo from "../../../../assets/logos/villa-logo-white.svg";
import LogoWithoutText from "../../../../assets/logos/villa-logo-white-notext.svg";
//Globally Shared
import {Avatar} from "../../../_shared/generics/Avatar";
import {isAdmin} from "../../../_shared/user/UserUtils";
import {setShowLogin} from "../../../_shared/slices/AuthSlice";
import {isMobile} from "../../../_shared/navigation";
//Locally Shared
import {setAdminMode} from "../slices/ProjectSummarySlice";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {
  TABS,
  PROPOSAL_PAGE_ID,
  PROFILE_PAGE_ID,
  MAPPING_PAGE_ID,
  SUBMIT_ASSESSMENT_PAGE_ID,
  UNIT_PAGE_ID,
  toPage,
  OVERVIEW_ASSESSMENT_PAGE_ID,
  HOME_PAGE_ID,
} from "../navigation/PageNavigation";
//Styles
import {
  AvatarContainer,
  BottomMenuArea,
  BottomMenuItem,
  MenuItemContentWrapper,
  MenuItemIcon,
  MenuItemTitle,
  BottomMenuContainer,
  SideBarContainer,
  SideBarLogo,
  SideBarLogoImage,
  SideBarLogoImageNoText,
  SideBarMenuItemContainer,
  SideBarRow,
  SideBarNavigation,
  BottomMenuListContainer,
  BottomMenuListItem,
} from "./styles";
//Local
import {
  CUSTOMER_STATUS,
  useCurrentCustomer,
} from "../../../_shared/hooks/useCurrentCustomer";
import {ProjectSwitcherModal} from "./ProjectSwitcherModal";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {GiPadlock} from "react-icons/gi";
import {MobileMenu} from "./MobileMenu";

const SideBarMenuItem = (props) => {
  const {logo, icon, title, pageId, selectedTab, onClick, enabled} = props;
  let isSelectedTab = selectedTab;
  if (isSelectedTab === SUBMIT_ASSESSMENT_PAGE_ID) {
    isSelectedTab = OVERVIEW_ASSESSMENT_PAGE_ID;
  }
  return (
    <SideBarRow>
      <SideBarMenuItemContainer
        onClick={onClick}
        selected={pageId === isSelectedTab}
        className={"unselectable"}
        enabled={enabled}
      >
        <MenuItemContentWrapper>
          <MenuItemIcon>
            {enabled ? (
              icon ? (
                icon
              ) : (
                <img src={logo} alt={title} width={"16px"} />
              )
            ) : (
              <GiPadlock style={{color: "gray"}} />
            )}
          </MenuItemIcon>
          <MenuItemTitle enabled={enabled}>{title}</MenuItemTitle>
        </MenuItemContentWrapper>
      </SideBarMenuItemContainer>
    </SideBarRow>
  );
};

const SideBarHeader = (props) => {
  const {setIsMenuOpen} = props;
  return (
    <SideBarLogo
      onClick={() => {
        toPage(HOME_PAGE_ID);
        setIsMenuOpen(false);
      }}
    >
      <SideBarLogoImage src={Logo} alt="logo" className="villaLogo" />
      <SideBarLogoImageNoText src={LogoWithoutText} alt="logo" />
    </SideBarLogo>
  );
};

const SideBarProfileRow = (props) => {
  const {setIsMenuOpen, tab, user, title} = props;
  return (
    <SideBarRow hasBorder={true}>
      <SideBarMenuItemContainer
        height={"5rem"}
        onClick={() => {
          toPage(PROFILE_PAGE_ID);
          setIsMenuOpen(false);
        }}
        selected={tab === PROFILE_PAGE_ID}
        enabled={true}
      >
        <MenuItemContentWrapper>
          <AvatarContainer>
            <Avatar customer={user} />
          </AvatarContainer>
          <MenuItemTitle enabled={true}>{title}</MenuItemTitle>
        </MenuItemContentWrapper>
      </SideBarMenuItemContainer>
    </SideBarRow>
  );
};

export const SideBar = (props) => {
  const {
    selectedTab,
    availableTabs,
    isSidebarExpanded,
    setIsSidebarExpanded,
    toggleReferralRock,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {status, product} = useCurrentProject();
  const {customer, customerStatus} = useCurrentCustomer();
  const {IS_PROJECT_SWITCHING_LIVE, IS_REFERRAL_ENABLED} =
    useGlobalConfiguration();
  const currentUser = useSelector((state) => state.global.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSwitchingProjects, setIsSwitchingProjects] = useState(false);
  const personalDetailsTitle = Customer.getFullName(customer) ?? "Account";
  const adminModeToolTipTitle = adminMode
    ? "Enter presentation mode"
    : "Enter admin mode";
  const admin = isAdmin(currentUser);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const content = (
    <>
      <SideBarContainer isExpanded={isSidebarExpanded}>
        <SideBarNavigation isExpanded={isSidebarExpanded}>
          <SideBarHeader setIsMenuOpen={closeMenu} />
          <SideBarProfileRow
            history={history}
            setIsMenuOpen={closeMenu}
            tab={selectedTab}
            user={customer}
            title={personalDetailsTitle}
          />
          {availableTabs?.map((pageId) => {
            const item = TABS[pageId];
            let enabled = true;
            if (item.pageId === UNIT_PAGE_ID) {
              enabled =
                adminMode ||
                (customerStatus.productSelectedStatus !==
                  CUSTOMER_STATUS.DISABLED &&
                  !!product);
            }
            if (item.pageId === OVERVIEW_ASSESSMENT_PAGE_ID) {
              enabled =
                adminMode ||
                customerStatus.depositPaidStatus !== CUSTOMER_STATUS.DISABLED;
              enabled = true;
            }
            if (item.pageId === MAPPING_PAGE_ID) {
              enabled =
                adminMode ||
                customerStatus.mappingStatus !== CUSTOMER_STATUS.DISABLED;
            }
            if (item.pageId === PROPOSAL_PAGE_ID) {
              enabled = admin || status.proposalReady;
            }
            return (
              <SideBarMenuItem
                key={item.pageId}
                {...item}
                selectedTab={selectedTab}
                enabled={enabled}
                onClick={() => {
                  if (enabled) {
                    toPage(item.pageId);
                    closeMenu();
                  }
                }}
              />
            );
          })}
          <BottomMenuArea>
            <BottomMenuListContainer isExpanded={isSidebarExpanded}>
              <BottomMenuListItem className={"unclickable"} hidden={!admin}>
                <div
                  data-tip
                  data-for={"tooltip-admin-toggle"}
                  style={{width: "fit-content"}}
                >
                  <Toggle
                    icons={{
                      checked: <FaRegEdit />,
                      unchecked: <HiOutlinePresentationChartLine />,
                    }}
                    checked={adminMode}
                    onChange={() => {
                      dispatch(setAdminMode(!adminMode));
                    }}
                  />
                </div>
                <ReactTooltip id={"tooltip-admin-toggle"} effect="solid">
                  {adminModeToolTipTitle}
                </ReactTooltip>
              </BottomMenuListItem>
              <BottomMenuListItem
                onClick={() => {
                  // TODO show pop-up for referring friends
                  toggleReferralRock();
                  closeMenu();
                }}
                hidden={!IS_REFERRAL_ENABLED}
              >
                <FaUserFriends />
                {" Refer friends"}
              </BottomMenuListItem>
              <BottomMenuListItem
                hidden={
                  !(
                    customer?.project_ids?.length > 1 &&
                    IS_PROJECT_SWITCHING_LIVE
                  )
                }
                onClick={() => {
                  setIsSwitchingProjects(true);
                  closeMenu();
                }}
              >
                <IoMdSwap />
                {" Switch projects"}
              </BottomMenuListItem>
              <BottomMenuListItem
                onClick={() => {
                  dispatch(setShowLogin(true));
                  closeMenu();
                }}
              >
                <BiLogOut />
                {" Log out"}
              </BottomMenuListItem>
            </BottomMenuListContainer>
            <BottomMenuContainer isExpanded={isSidebarExpanded}>
              <BottomMenuItem
                hidden={isMobile()}
                willRotate={true}
                onClick={() => {
                  setIsSidebarExpanded(!isSidebarExpanded);
                }}
                isExpanded={isSidebarExpanded}
              >
                <AiOutlineDoubleRight />
              </BottomMenuItem>
            </BottomMenuContainer>
          </BottomMenuArea>
        </SideBarNavigation>
      </SideBarContainer>
      <ProjectSwitcherModal
        show={isSwitchingProjects}
        handleClose={() => {
          setIsSwitchingProjects(false);
        }}
      />
    </>
  );

  if (isMobile()) {
    return (
      <MobileMenu
        items={content}
        isOpen={isMenuOpen}
        onOpen={() => {
          setIsMenuOpen(true);
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
      />
    );
  } else return content;
};
