import React from "react";
//Shared
import {
  StyledFormContent,
  StyledFormDetails,
  StyledParagraph,
  StyledFormContentHeader,
} from "../../../_shared/styles";
import {ForgotPasswordForm} from "../../components/ForgotPasswordForm";

interface IForgotPasswordPage {
  toLogin: any;
  toCheckYourEmail: any;
  email?: string;
}

const ForgotPasswordPage = (props: IForgotPasswordPage) => {
  const {toLogin, toCheckYourEmail, email} = props;
  return (
    <StyledFormContent>
      <StyledFormDetails>
        <StyledFormContentHeader>Reset your password</StyledFormContentHeader>
        <StyledParagraph>
          Please enter the email address associated with your Villa account.
          We’ll send you an email with instructions on how to reset your
          password.
        </StyledParagraph>
      </StyledFormDetails>
      <ForgotPasswordForm
        email={email}
        toLogin={toLogin}
        toCheckYourEmail={toCheckYourEmail}
      />
    </StyledFormContent>
  );
};

export {ForgotPasswordPage};
