import {CustomerProjectInfo} from "@natomas/core";
import {IEventInfo} from "@natomas/core";

export const mergeProjectInfoAndProjectOpportunityInfo = (
  projectInfo?: CustomerProjectInfo,
  projectOpportunityInfo?: CustomerProjectInfo
): CustomerProjectInfo | null => {
  if (projectInfo == null && projectOpportunityInfo == null) {
    return null;
  }

  if (projectOpportunityInfo == null && projectInfo != null) {
    return projectInfo;
  } else if (projectInfo == null && projectOpportunityInfo != null) {
    return projectOpportunityInfo;
  }

  // Since we have events from salesforce and natomas, we need to merge events
  // Let's start with SF events because they are the source of truth for now
  // Then any events not captured in salesforce will be filled in by the natomas events
  const eventsMap: {[key: string]: IEventInfo} = {};
  projectOpportunityInfo!.events?.forEach((event) => {
    if (!eventsMap[event.event_id]) {
      eventsMap[event.event_id] = event;
    }
  });

  projectInfo!.events?.forEach((event) => {
    if (!eventsMap[event.event_id]) {
      eventsMap[event.event_id] = event;
    }
  });

  const projectOppInfoCopy: CustomerProjectInfo = Object.assign(
    {},
    projectOpportunityInfo
  );

  return Object.assign(projectOppInfoCopy, {
    ...projectInfo,
    events: Object.values(eventsMap),
  });
};
