import React from "react";
import Modal from "react-bootstrap/Modal";

export const TextInputModal = (props) => {
	const {show, setShow, modifierGroup, formik} = props;
	const handleClose = () => {
		setShow(false);
	};
	const handleRemove = () => {
		formik.setFieldValue("note-" + modifierGroup.id, null);
		setShow(false);
	};

	const saveChanges = () => {
		setShow(false);
	};

	if (formik == null) {
		return null;
	}

	return (
		<div>
			<Modal show={show} onHide={handleClose} centered size="md">
				<Modal.Header closeButton style={{position: "relative"}}>
					<h4>
						{modifierGroup != null
							? '"' + modifierGroup.title + '" note'
							: "Add a note"}
					</h4>
				</Modal.Header>

				<Modal.Body>
					<div class="form-group">
						<textarea
							class="form-control"
							id="message-text"
							placeholder="Ex. still deciding on skylights"
							value={formik.values["note-" + modifierGroup.id]}
							name={"note-" + modifierGroup.id}
							onChange={formik.handleChange}
							autoFocus
						></textarea>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "space-between",
						}}
					>
						<button
							className="button-secondary"
							onClick={() => {
								handleRemove();
							}}
							style={{width: "45%"}}
						>
							Remove Note
						</button>
						<button
							className="red-button-next"
							onClick={() => {
								saveChanges();
							}}
							style={{width: "45%"}}
						>
							Done
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
