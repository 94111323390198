import styled from "styled-components";
import {isMobile} from "../../../../_shared/navigation";

export const Container = styled.div`
  height: ${isMobile() ? "unset" : "calc(100% - 85px)"};
  padding: 20px 0 0 84px;
  overflow-y: ${isMobile() ? "hidden" : "auto"};
  position: relative;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-top: 14px;
  font-family: Maison-Medium, sans-serif;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 10px;
`;

export const InformationSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
