//Global
import React, {useState} from "react";
//Shared
import {StyledRow} from "../../../_shared/styles";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
//Local
import {getResetPasswordFormik} from "../../logic/constants";
import {isBlankString} from "@natomas/core";
import {EMAIL_KEY} from "../../../_shared/models";
import {useHistory} from "react-router-dom";
import useMountEffect from "../../../../_shared/hooks/useMountEffect";
import {Authentication} from "../../../../../database/firebase";
import {setURLQuery} from "../../../../_shared/navigation/_helpers";
import styled from "styled-components";
import {getParameter} from "../../../../_shared/cookies";
import {useFormik} from "formik";
import {isMobile} from "../../../../_shared/navigation";

interface IForgotPasswordForm {
  toLogin: any;
  toCheckYourEmail: any;
  email?: string;
  prc: any;
}

const SignInInput = styled.input`
  height: ${(props: any) => (props.hideInput === true ? "1px" : "52px")};
  margin-top: ${(props: any) => (props.hideInput === true ? "0px" : "18px")};
  width: 100%;
  border-width: 2px;
  border-color: black;
  opacity: ${(props: any) => (props.hideInput === true ? 0 : 1)};
`;

function getPasswordResetCode() {
  return getParameter("prc", true);
}

export const ResetPasswordForm = (props: IForgotPasswordForm) => {
  const {toLogin, email, prc} = props;

  const formik = useFormik(getResetPasswordFormik(email));
  const {values, errors} = formik;
  const [status, setStatus] = useState("start");
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const setShowResetView = (value: boolean) => {};

  useMountEffect(() => {
    if (prc != null) {
      const email = prc.email;
      formik.setFieldValue(EMAIL_KEY, email);
      setStatus("ready");
    }
  });

  return (
    <div style={{width: isMobile() ? "90%" : "75%", marginTop: "20px"}}>
      <form
        target="hiddenLogInFrame"
        onSubmit={() => {
          const {password} = values;
          const email = values[EMAIL_KEY];
          if (status === "resetting" || status === "start") {
            return;
          }

          if (
            !isBlankString(email) &&
            !isBlankString(password) &&
            status === "ready" &&
            email
          ) {
            if (password.length >= 6) {
              setStatus("resetting");
              Authentication.resetPassword(
                password,
                getPasswordResetCode()
              ).then((data) => {
                if (data != null) {
                  Authentication.signInWithEmail(email, password).then(() => {
                    setShowResetView(false);
                    window.location.reload();
                  });
                }
              });

              setURLQuery(history, "prc", null);
            } else {
              setError("Password needs to be at least 6 characters");
              setTimeout(() => {
                setError(null);
              }, 5000);
            }
          }
        }}
      >
        <SignInInput
          id={"email-input"}
          className={"formik-input"}
          placeholder={"Email"}
          name={EMAIL_KEY}
          type={"email"}
          value={values[EMAIL_KEY]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => {
            formik.setFieldTouched(EMAIL_KEY, false);
          }}
          disabled={true}
          autoComplete={"username"}
          style={{
            borderColor: "rgba(0,0,0,0.1)",
          }}
        />

        <SignInInput
          id={"password-input"}
          className={"formik-input"}
          placeholder={"New Password"}
          name={"password"}
          value={values["password"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type={"password"}
          autoFocus={true}
          autoComplete="new-password"
        />
        <div style={{fontSize: 14, marginTop: "6px", color: "red"}}>
          {error}
        </div>

        <StyledRow>
          <NatButton
            label={"Back to Login"}
            trackingId={"reset-password-back-to-login"}
            option={StyleOption.SECONDARY}
            size={TextSize.NORMAL}
            type={"button"}
            clickEvent={toLogin}
            spinnerEnabled={false}
          />

          <NatButton
            disabled={
              isBlankString(values[EMAIL_KEY]) || errors[EMAIL_KEY] != null
            }
            trackingId={"reset-password-set-password"}
            label={"Set Password"}
            option={StyleOption.PRIMARY}
            type={"submit"}
            size={TextSize.NORMAL}
            spinnerEnabled={true}
          />
        </StyledRow>

        <div
          className={"fullDiv"}
          style={{
            backgroundColor: "rgba(255,255,255,0.3)",
            display: status !== "resetting" ? "none" : "block",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      </form>

      <iframe
        name="hiddenLogInFrame"
        width="0"
        height="0"
        style={{display: "none"}}
      />
    </div>
  );
};
