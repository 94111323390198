import React from "react";
import {Col, Row} from "antd";
import {DisclaimerContainer, UserFeedbackContainer} from "./styled";
import {CheckMarkIcon, InfoIcon} from "../../../../../assets/icons";
import {RiInformationLine} from "react-icons/ri";
import {useIntercom} from "react-use-intercom";

export const Disclaimer = () => {
  let icon = <InfoIcon />;

  const {show} = useIntercom();

  return (
    <DisclaimerContainer>
      <Row wrap={false}>
        <Col flex="32px">{<RiInformationLine />}</Col>
        <Col flex="auto">
          {"If this map looks inaccurate, "}
          <a
            onClick={() => {
              show();
            }}
            href={"#"}
          >
            chat with our team
          </a>
          {" to verify feasibility"}
        </Col>
      </Row>
    </DisclaimerContainer>
  );
};
