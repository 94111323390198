import React from "react";
import {NatomasLink} from "./styles";
import {
  StyleOption,
  getHoverBackgroundColor,
  getHoverTextColor,
  getInitialBackgroundColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getHoverTextDecoration,
  TextSize,
  getInitialBorderColor,
  getHoverBorderColor,
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getTextSize,
} from "../_shared";

export interface ILinkButton {
  label: string;
  trackingId: string;
  link?: string;
  clickEvent?: any;
  clickEventParam?: any;
  icon?: any;
  option?: StyleOption;
  textSize?: TextSize;
  hidden?: boolean;
}

const NatLink = (props: ILinkButton): JSX.Element => {
  const {
    link,
    clickEvent,
    label,
    option,
    textSize,
    clickEventParam,
    icon,
    hidden,
    trackingId,
  } = props;
  const attributes: any = {};
  if (link && !clickEvent) {
    attributes.href = link;
  }
  const doAction = () => {
    if (clickEventParam) clickEvent(clickEventParam);
    else if (clickEvent) clickEvent();
  };
  return (
    <>
      <NatomasLink
        initialBackgroundColor={getInitialBackgroundColor(option)}
        initialTextColor={getInitialTextColor(option)}
        initialTextDecoration={getInitialTextDecoration(option)}
        hoverBackgroundColor={getHoverBackgroundColor(option)}
        hoverTextColor={getHoverTextColor(option)}
        hoverTextDecoration={getHoverTextDecoration(option)}
        initialBorderColor={getInitialBorderColor(option)}
        hoverBorderColor={getHoverBorderColor(option)}
        enabledBackgroundColor={getEnabledBackgroundColor(option)}
        enabledBorderColor={getEnabledBorderColor(option)}
        onClick={() => doAction()}
        textSize={getTextSize(textSize)}
        {...attributes}
        hidden={hidden}
        name={trackingId}
      >
        {label}
        {icon}
      </NatomasLink>
    </>
  );
};

export {NatLink};
