import styled from "styled-components";
import {isMobile} from "../../../../_shared/navigation";

const BannerContainer = styled.div<{height: number}>`
  color: white;
  height: ${(props) => props.height}rem;
`;

const BannerImage = styled.div`
   {
    height: 100%;
    position: relative;
  }
`;

const BannerContent = styled.div<{top: number}>`
  //Positioning the card on top of the background
  position: relative;

  // This is calculated by the static height
  // and the margin bottom on BannerImage
  top: ${(props) => props.top}rem;
  z-index: 10;

  //Background tint
  background: rgba(0, 0, 0, 0.25);
  //Scale card to match parent
  width: 100%;
  height: 100%;

  //Fit content
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BannerTitle = styled.h1`
  font-size: ${isMobile() ? "1.5" : "3"}rem;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.5) 0.08rem 0.08rem;
`;

const BannerDescription = styled.p`
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.5) 0.08rem 0.08rem;
`;

export {
  BannerImage,
  BannerContent,
  BannerTitle,
  BannerDescription,
  BannerContainer,
};
