import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/DashboardView/styles/globals";

export const DesignButton = styled.div<{active: boolean}>`
  background: ${(props) => (props.active ? "var(--accent-color-active)" : "")};
  border: ${(props) =>
    props.active
      ? "2px solid var(--accent-color)"
      : "2px solid rgba(0, 0, 0, 0.2)"};
  border-radius: 10px;
  font-family: ${(props) =>
    props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border: 2px solid var(--accent-color);
  }
`;

export const DesignSquareButton = styled.div<{active: boolean}>`
  cursor: pointer;
  border: ${(props) =>
    props.active ? "3px solid var(--accent-color)" : "3px solid #fff"};
  box-shadow: ${(props) => (props.active ? "0 0 0 2px #fff inset" : "")};
  border-radius: 10px;
`;

export const ImageContainer = styled.div<{width?: number}>`
  height: 100%;
  min-width: ${(props) => props.width ?? "54px"};
  position: relative;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  margin-top: 0;
  border: none;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }

  p:first-child {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;
