import styled from "styled-components";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";

export const PaymentPageContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;
`;

export const PaymentContainer = styled.div`
  padding-bottom: 30px;
`;

export const PaymentTable = styled.div`
  width: 60%;
  margin-bottom: 10px;
`;

export const PaymentHorizontalRule = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const SubmitDepositTitle = styled.div`
  font-size: 18px;
  font-family: Maison-Bold, sans-serif;
`;

export const SubmitDepositImage = styled.img`
  width: 1000px;
  max-width: 100%;
`;

export const SubmitDepositSubtitle = styled.div`
  margin-top: 6px;
`;
export const SubmitDepositContainer = styled.div`
  padding-top: 3.5rem;
  padding-bottom: 1.5rem;
`;

export const SectionInfoContainer = styled.div`
  margin-top: 1rem;
  font-family: Maison-Bold, sans-serif;
`;
export const SectionInfoText = styled.div``;

export const AddressInfoContainer = styled.div`
  vertical-align: top;
  font-family: Maison-Bold, sans-serif;
  padding-bottom: 15px;
`;
export const AddressInfoText = styled.div`
  vertical-align: top;
`;

export const ADUImage = styled.div`
  height: 14rem;
  width: 100%;
  position: relative;
  .fullDiv {
    border-radius: 1rem;
  }
`;

export const ADUBlurbContainer = styled.div<{removeRightMargins: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  ${StandardElement} {
    margin-right: ${(props) => (props.removeRightMargins ? "0" : "")};
    margin-bottom: 1rem;
  }
`;

export const ADUBlurbTable = styled.table`
  width: 60%;
`;

export const ADUBlurbTableLeft = styled.td`
  width: 160px;
`;

export const ADUBlurbTableRight = styled.td`
  padding-left: 20px;
  width: 100%;
  vertical-align: text-top;
`;

export const ADUBlurbTitle = styled.div`
  font-size: 1.25rem;
  font-family: Maison-Book, sans-serif;
  vertical-align: text-top;
`;

export const ADUBlurbText = styled.div`
  vertical-align: text-top;
  padding-top: 10px;
  font-size: 16px;
  line-height: 133%;
  color: rgba(0, 0, 0, 0.7);
`;

export const ADUBlurbDescription = styled.div`
  vertical-align: text-top;
  padding-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 133%;
  color: rgba(0, 0, 0, 0.5);
`;

export const ADUBlurbButton = styled.div`
  padding-top: 10px;
  font-family: Maison-Book, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 133%;
  color: #82c275;
`;

export const SummaryPaymentCard = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0 4px 17px rgba(97, 97, 97, 0.17);
  border-radius: 8px;
  padding: 20px 20px;
`;

export const SummaryPaymentCardTitle = styled.div`
  font-family: Maison-Bold, sans-serif;
  padding-bottom: 10px;
`;

export const SummaryPaymentCardTable = styled.table`
  width: 100%;
`;

export const SummaryPaymentCardTableRow = styled.tr``;

export const SummaryPaymentCardTableRowSum = styled.tr`
  vertical-align: middle;
  line-height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  font-family: Maison-Bold, sans-serif;
  color: black;
`;

export const SummaryPaymentCardTableLeftSum = styled.td`
  font-family: Maison-Bold, sans-serif;
  color: black;
`;

export const SummaryPaymentCardTableRightSum = styled.td`
  font-family: Maison-Bold, sans-serif;
  color: black;
  text-align: right;
`;

export const SummaryPaymentCardTableLeft = styled.td`
  width: 50%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 10px;
`;

export const SummaryPaymentCardTableRight = styled.td`
  width: 10%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  color: #82c275;
  padding-bottom: 10px;
`;

export const MakeAPaymentButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 25px;
  border: none;
  background: none;
  margin-top: 20px;
  font-size: 18px;
  width: 100%;
  background: #82c275;
  border-radius: 100px;
  font-family: Maison-Bold, sans-serif;
  color: black;
  &:hover {
    background: black;
    color: white;
  }
`;

export const HomeDetailsFlex = styled.div<{removeRightMargin: number}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  ${StandardElement} {
    :nth-child(${(props) => props.removeRightMargin}n) {
      margin-right: 0;
    }
  }
`;

export const HomeDetailsTable = styled.table`
  width: 100%;
`;

export const HomeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const HomeDetailsTitle = styled.div`
  font-family: Maison-Bold, sans-serif;
  font-size: 1rem;
  margin-bottom: 0.75rem;
`;

export const HomeDetailsTableRow = styled.tr`
  width: 50%;
  padding-right: 0;
  &:last-child {
    padding-right: 0;
    padding-left: 0;
    border-right: 0;
  }
`;

export const HomeDetailsTableData = styled.td`
  width: 50%;
  padding-right: 0;
  &:last-child {
    padding-right: 0;
    padding-left: 0;
    border-right: 0;
  }
`;

export const HomeDetailsTableLeft = styled.td`
  vertical-align: text-top;
  white-space: nowrap;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
`;

export const HomeDetailsTableRight = styled.td`
  vertical-align: text-top;
  width: 100%;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
`;

export const PaymentDetails = styled.div<{removeRightMargin?: number}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${StandardElement} {
    &:nth-child(${(props) =>
          props.removeRightMargin ? props.removeRightMargin : -1}n) {
      margin-right: 0;
    }
  }
`;

export const PaymentDetailsTable = styled.table`
  margin: 0.5rem 0 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const YourInfoFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  column-gap: 3rem;
  align-items: flex-start;
`;

const ROW_TEXT_HEIGHT = "1.2rem";
export const PaymentDetailsTableRow = styled.tr`
  overflow-wrap: break-word;
`;

export const PaymentDetailsTableData = styled.td`
  vertical-align: text-top;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  line-height: ${ROW_TEXT_HEIGHT};
`;

export const PaymentDetailsTableLeft = styled.td`
  vertical-align: text-top;
  white-space: nowrap;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  line-height: ${ROW_TEXT_HEIGHT};
`;

export const PaymentDetailsTableRight = styled.td`
  vertical-align: text-top;
  width: 100%;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  text-align: right;
  line-height: ${ROW_TEXT_HEIGHT};
`;

export const PaymentDetailsTableLeftLast = styled.td`
  vertical-align: text-top;
  white-space: nowrap;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  line-height: ${ROW_TEXT_HEIGHT};
`;

export const PaymentDetailsTableRightLast = styled.td`
  vertical-align: text-top;
  width: 100%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  text-align: right;
  line-height: ${ROW_TEXT_HEIGHT};
`;

export const PaymentDetailsTableTitle = styled.td`
  font-family: Maison-Medium, sans-serif;
  font-size: 16px;
  line-height: 133%;
  height: 2rem;
  vertical-align: text-top;
`;

export const PaymentTableTitlesTableData = styled.td`
  width: 50%;
  &:last-child {
    padding-right: 0;
    padding-left: 0.25rem;
    border-right: 0;
  }
`;
export const NatTabRow = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 32px;
  position: relative;
`;
export const NatTabContainer = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
`;

export const NatTab = styled.div<{active: boolean}>`
  height: 100%;
  margin-right: 40px;
  min-width: fit-content;
  flex-shrink: 0;
  border-bottom: ${(props) => (props.active ? "3px solid #82c275" : "none")};
  font-family: ${(props) => (props.active ? "Maison-Bold" : "Maison-Book")};
  position: relative;
  cursor: pointer;
`;
