import styled from "styled-components";
import {ErrorMessage, Form, Formik} from "formik";
//Shared
import {FormInput} from "../../_shared/generics/form/FormInput";
import {BLACK, ERROR_COLOR} from "../../_shared/colors";
import {isMobile} from "../../_shared/navigation";
import {FONT_FAMILY_MEDIUM} from "../../portal/views/DashboardView/styles/globals";

const StyledParagraph = styled.p`
   {
    font-size: 0.9rem;
    font-family: Maison Neue, sans-serif;
    margin: 0 ${isMobile() ? "0" : "1rem"} 0 0;
    text-align: ${isMobile() ? "center" : "left"};
    user-select: none;
  }
`;

const StyledFormDetails = styled.div`
   {
    width: ${isMobile() ? "90%" : "75%"};
    font-size: 1rem;
    margin-top: ${isMobile() ? "1rem" : "0"};
  }
`;

const StyledFormContentHeader = styled.h1`
   {
    display: ${isMobile() ? "none" : "default"};
    font-size: 1.6rem;
    font-family: ${FONT_FAMILY_MEDIUM};
    padding-bottom: 1rem;
  }
`;

const StyledFormContent = styled.div`
   {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledForm = styled(Form)`
   {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: left;
    width: ${isMobile() ? "90%" : "75%"};
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;

const StyledFormik = styled(Formik)`
   {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
`;

const StyledFormInput = styled(FormInput)`
   {
    font-size: 1rem;
  }
`;

const StyledRow = styled.div`
   {
    width: 100%;
    padding-top: ${isMobile() ? "1rem" : "2rem"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${isMobile() ? "1rem" : "0"};
  }
`;

const StyledInputRow = styled.div`
   {
    width: 100%;
    padding: ${isMobile() ? "1rem" : "2rem"} 0 1rem 0;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledWarning = styled.div`
   {
    display: flex;
    justify-content: left;
    text-align: left;
    color: ${ERROR_COLOR};
    margin-bottom: 0.625rem;
    margin-left: 9px;
    font-size: 0.9rem;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  padding-top: ${isMobile() ? "1rem" : "2rem"};
  display: flex;
  flex-direction: ${isMobile() ? "column" : "row"};
  justify-content: space-between;
  align-items: center;
`;

const NewAccountBlurb = styled.div`
  width: ${isMobile() ? "100%" : "65%"};
  display: flex;
  flex-direction: ${isMobile() ? "column" : "row"};
  margin-top: ${isMobile() ? "1rem" : "0"};
  margin-bottom: ${isMobile() ? "1rem" : "0"};
  font-size: 0.85rem;
  justify-content: flex-end;
  align-items: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
   {
    font-size: inherit;
  }
`;

const StyledInput = styled.input`
  width: ${(props: {showingError: boolean}) =>
    props.showingError ? "75%" : "100%"};
  height: 100%;
  padding: 0.375rem;
  border-width: 0 0 0.125rem 0;
  border-color: ${(props: {showingError: boolean}) =>
    props.showingError ? ERROR_COLOR : BLACK};
  &:focus-visible {
    outline-width: 0;
  }

  font-size: ${isMobile() ? "1rem" : "inherit"};
`;

export {
  StyledInputRow,
  StyledFormInput,
  StyledForm,
  StyledFormik,
  StyledRow,
  StyledFormDetails,
  StyledFormContentHeader,
  StyledParagraph,
  StyledWarning,
  StyledInput,
  StyledErrorMessage,
  BottomSection,
  NewAccountBlurb,
};

export {StyledFormContent};
