import {useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchUser} from "../../../database/firebase/user";

// This hook is for retrieving information about the current LOGGED IN user
export const useCurrentUser = () => {
  const loggedInUser = useSelector((state) => state.global.user);
  const users = useSelector((state) => state.global.users);
  const userActionsMap = useSelector((state) => state.user.userActions);

  useEffect(() => {
    if (loggedInUser && users[loggedInUser.uid] == null) {
      fetchUser(loggedInUser.uid, false);
    }
  }, [loggedInUser, users]);

  const userInfo = users[loggedInUser?.uid];
  const userActions = userActionsMap[loggedInUser?.uid] ?? {};

  return {
    uid: loggedInUser?.uid,
    user_id: loggedInUser?.uid,
    user: userInfo,
    actions: userActions,
    loggedInUser,
  };
};
