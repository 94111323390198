import React from "react";

export const Icon = (props) => {
	const {iconSrc, iconAlt} = props;

	return (
		<img
			src={iconSrc}
			height="70px"
			alt={iconAlt}
			className="current-step-icon"
		/>
	);
};
