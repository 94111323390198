import React, {useState} from "react";
import {UnitBudgetSummary} from "./components/UnitBudgetSummary";
import {SiteBudgetSummary} from "./components/SiteBudgetSummary";
import {
  BreakdownPageContainer,
  CostTabToggle,
  CostTabToggleContainer,
  MainContainer,
  TabTitle,
  ToggleContainer,
} from "./styles";
import {ProjectCostCard} from "./components/ProjectCostCard";
import {AdditionalSiteCostsSummary} from "./components/AdditionalSiteCostsSummary";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {useSelector} from "react-redux";
import {isAdmin} from "../../../_shared/user/UserUtils";
import {OverallBudgetSummary} from "./components/OverallBudgetSummary";
import {Phase1BudgetSummary} from "./components/Phase1BudgetSummary";
import {BASE_URL} from "../../../_shared/application";
const OVERALL_COST_TAB_NAME = "Overall Cost Breakdown";
const UNIT_COST_TAB_NAME = "Unit Cost Breakdown";
const SITE_WORK_COST_TAB_NAME = "Site Work Cost Breakdown";
const ADDITIONAL_SITE_WORK_COST_TAB_NAME =
  "Additional Site Work Cost Breakdown";
const PHASE_1_TAB_NAME = "Phase 1 Line Items";

const tabs_template = [
  {id: "overall", title: OVERALL_COST_TAB_NAME, comp: <OverallBudgetSummary />},
  {
    id: "phase1",
    title: PHASE_1_TAB_NAME,
    comp: <Phase1BudgetSummary showExtras={true} />,
  },
  {
    id: "siteWork",
    title: SITE_WORK_COST_TAB_NAME,
    comp: <SiteBudgetSummary showExtras={true} />,
  },
  {
    id: "unit",
    title: UNIT_COST_TAB_NAME,
    comp: <UnitBudgetSummary showExtras={true} />,
  },
];

export const BudgetSummaryView = (props) => {
  const {active} = props;
  const {configurationSite, configuration} = useCurrentProject();

  const currentUser = useSelector((state) => state.global.user);
  const adminMode = useSelector((state) => state.portal.adminMode);
  const shouldShowAdditionalLineItemsTab =
    (isAdmin(currentUser) && adminMode) ||
    (configurationSite?.additionalLineItemCostInMicros != null &&
      configurationSite?.additionalLineItemCostInMicros !== 0);
  const tabs = [...tabs_template];
  if (shouldShowAdditionalLineItemsTab) {
    tabs.push({
      id: "additionalSiteWork",
      title: ADDITIONAL_SITE_WORK_COST_TAB_NAME,
      comp: <AdditionalSiteCostsSummary />,
    });
  }

  const [tab, setTab] = useState(tabs[0]);
  let designSpreadsheet = "";
  if (configuration?.spreadsheetId != null) {
    designSpreadsheet =
      "https://docs.google.com/spreadsheets/d/" + configuration.spreadsheetId;
  }
  let customerPortalURL = "";
  if (configuration?.id != null) {
    customerPortalURL = BASE_URL + "/?cid=" + configuration.id;
  }

  return (
    <MainContainer active={active}>
      <ProjectCostCard />
      <div style={{display: "flex", gap: "30px", color: "black"}}>
        {isAdmin(currentUser) && adminMode && designSpreadsheet !== "" && (
          <a
            style={{color: "black"}}
            href={designSpreadsheet}
            target={"_blank"}
          >
            Design Selections Spreadsheet
          </a>
        )}
        {isAdmin(currentUser) && adminMode && customerPortalURL !== "" && (
          <a
            href={customerPortalURL}
            target={"_blank"}
            style={{color: "black"}}
          >
            Customer Portal URL
          </a>
        )}
      </div>
      <ToggleContainer>
        <CostTabToggleContainer>
          {tabs.map((tabInfo) => {
            return (
              <CostTabToggle
                onClick={() => {
                  setTab(tabInfo);
                }}
                active={tab.id === tabInfo.id}
                key={tabInfo.id}
              >
                {tabInfo.title}
              </CostTabToggle>
            );
          })}
        </CostTabToggleContainer>
      </ToggleContainer>
      <TabTitle className={"left-right"}>
        <div className={"bold"}>{tab.title}</div>
      </TabTitle>
      {tabs.map((tabInfo) => {
        return (
          <BreakdownPageContainer
            key={tabInfo.id}
            hidden={tab.id !== tabInfo.id}
          >
            {tabInfo.comp}
          </BreakdownPageContainer>
        );
      })}
    </MainContainer>
  );
};
