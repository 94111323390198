export interface FilterOption {
  type: FilterType;
  value: number | string | AddressFilter | NumberRangeFilter;
  label: string;
}

export interface AddressFilter {
  state: string;
  county: string;
}

export interface NumberRangeFilter {
  low: number;
  high: number;
}

export enum FilterType {
  Address = "address",
  SquareFootage = "square_footage",
  PriceRange = "price_range",
  Bedroom = "bedroom",
  ProductLine = "product_line",
  UnitPriceRange = "unit_price_range",
}

interface GenericOption {
  label: string;
}

export interface SquareFootageOption extends GenericOption {
  value: NumberRangeFilter;
}

export interface PriceRangeOption extends GenericOption {
  value: NumberRangeFilter;
}

export interface BedroomOption extends GenericOption {
  value: number;
}

export interface ProductLineOption extends GenericOption {
  value: string;
}
