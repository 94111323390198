import {createSlice} from "@reduxjs/toolkit";
import {getParameter} from "../../_shared/cookies";
import {SHOW_MULTI_SELECT_PROPERTY_KEY} from "../logic/Constants";
import {Configuration} from "../../../database/firebase";
import {IProjectInfo} from "@natomas/core";

interface IConfigurationSlice {
  currentConfigurationPage: any;
  visitedConfigurationPages: any;
  selectedModifiers: any;
  editingSelections: any;
  showEditingConfirmation: any;
  allowDisselect: any;
  showConfirmation: any;
  saveInProgress: any;
  firstEdit: any;
  firstTouchOnPage: any;
  fetchedProjectSummary: IProjectInfo | null | undefined;
  fetchedProjectOpportunity: any;
  fetchedConfiguration: any;
  fetchedConfigurationSnapshot: any;
  fetchedConfigurationMap: any;
  fetchedConfigurationSite: any;
  fetchedConfigurationOrder: any; // TODO this isn't used anymore,
  currentConfigurationId: any;
}

const getInitialState = (): IConfigurationSlice => {
  return {
    currentConfigurationPage: "",
    visitedConfigurationPages: {},
    selectedModifiers: {},
    editingSelections: true,
    showEditingConfirmation: false,
    allowDisselect: true,
    showConfirmation: false,
    saveInProgress: false,
    firstEdit: false,
    firstTouchOnPage: false,
    fetchedProjectSummary: undefined,
    fetchedProjectOpportunity: undefined,
    fetchedConfiguration: null,
    fetchedConfigurationSnapshot: null,
    fetchedConfigurationMap: null,
    fetchedConfigurationSite: null,
    fetchedConfigurationOrder: null, // TODO this isn't used anymore
    currentConfigurationId: getParameter(
      Configuration.Constants.CONFIGURATION_ID_URL_KEY,
      true
    ),
  };
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState: getInitialState(),
  reducers: {
    setSaveInProgress: (state, action) => {
      switch (action.payload) {
        case "saving":
          state.saveInProgress = true;
          state.showConfirmation = true;
          break;
        case "saved":
          state.saveInProgress = false;
          state.firstEdit = false;
          break;
      }
    },
    setFirstTouch: (state, action) => {
      state.firstTouchOnPage = action.payload;
    },
    setShowEditingConfirmation: (state, action) => {
      state.showEditingConfirmation = action.payload;
    },
    setEditingSelections: (state, action) => {
      state.editingSelections = action.payload;
    },
    setConfigurationId: (state, action) => {
      state.currentConfigurationId = action.payload;
    },
    setShowConfirmation: (state, action) => {
      state.showConfirmation = action.payload;
    },
    loadedConfiguration: (state, action) => {
      state.fetchedConfiguration = action.payload;
    },
    loadedProjectSummary: (state, action) => {
      state.fetchedProjectSummary = action.payload;
    },
    loadedProjectOpportunity: (state, action) => {
      state.fetchedProjectOpportunity = action.payload;
    },
    loadedConfigurationSnapshot: (state, action) => {
      state.fetchedConfigurationSnapshot = action.payload;
    },
    loadedConfigurationMap: (state, action) => {
      state.fetchedConfigurationMap = action.payload;
    },
    loadedConfigurationSite: (state, action) => {
      state.fetchedConfigurationSite = action.payload;
    },
    loadedConfigurationOrder: (state, action) => {
      state.fetchedConfigurationOrder = action.payload;
    },
    resetConfiguration: (state) => {
      state.fetchedProjectSummary = undefined;
      state.fetchedProjectOpportunity = undefined;
      state.fetchedConfiguration = null;
      state.fetchedConfigurationSnapshot = null;
      state.fetchedConfigurationMap = null;
      state.fetchedConfigurationSite = null;
      state.currentConfigurationId = null;
    },
    setSelectionsFromConfiguration: (state, action) => {
      const configuration = action.payload;
      const {selectedModifiers} = configuration;
      if (selectedModifiers != null) {
        const newSelectedModifiers = {};
        Object.keys(selectedModifiers).forEach((modifierId) => {
          // @ts-ignore
          newSelectedModifiers[modifierId] = true;
        });
        state.selectedModifiers = newSelectedModifiers;
      }
    },
    selectModifier: (state, action) => {
      const {modifier, modifierGroup, reset} = action.payload;
      if (state.editingSelections === false && reset !== true) {
        return;
      }
      if (reset !== true) {
        state.firstEdit = true;
        state.firstTouchOnPage = true;
      }
      const modifierId = modifier.id;
      const isMultiSelect =
        modifierGroup.properties != null &&
        modifierGroup.properties[SHOW_MULTI_SELECT_PROPERTY_KEY] === true;

      const otherModifiersInGroup = modifierGroup.modifiers;
      otherModifiersInGroup.forEach((modifierIdInGroup: string | number) => {
        if (modifierIdInGroup === modifierId) {
          if (
            isMultiSelect &&
            state.selectedModifiers[modifierIdInGroup] === true
          ) {
            state.selectedModifiers[modifierIdInGroup] = null;
          } else {
            state.selectedModifiers[modifierIdInGroup] = true;
          }
        } else if (!isMultiSelect) {
          state.selectedModifiers[modifierIdInGroup] = null;
        }
      });
    },
    setSelectedModifiers: (state, action) => {
      const {modifiers, merge, reset} = action.payload;

      if (reset !== true) {
        state.firstEdit = true;
        state.firstTouchOnPage = true;
      }

      if (merge) {
        Object.keys(modifiers).forEach((modifierId) => {
          state.selectedModifiers[modifierId] = modifiers[modifierId];
        });
      } else {
        state.selectedModifiers = modifiers;
      }
    },
  },
});

export const configurationReducer = configurationSlice.reducer;
export const {
  resetConfiguration,
  selectModifier,
  setConfigurationId,
  setSaveInProgress,
  setSelectionsFromConfiguration,
  setShowConfirmation,
  loadedConfiguration,
  setEditingSelections,
  setShowEditingConfirmation,
  loadedProjectSummary,
  loadedProjectOpportunity,
  loadedConfigurationSnapshot,
  loadedConfigurationMap,
  loadedConfigurationSite,
  setFirstTouch,
  setSelectedModifiers,
  loadedConfigurationOrder,
} = configurationSlice.actions;
