import React, {useMemo} from "react";
import {CardContainer, SectionContainer, Title} from "../styles/preDeposit";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {CallToActionCard} from "./CallToActionCard";
import {Customer} from "@natomas/core";
import {getVideoList} from "../util/config";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {
  getItemFromLocalCache,
  storeItemInLocalCache,
} from "../../../../_shared/cache";
import {WELCOME_VIDEO_WATCHED_CACHE} from "../../../../_shared/cache/constants";

export const HelpfulVideos = React.memo(
  (props: {launchVideo: any; fullWidth: number}) => {
    const {launchVideo, fullWidth} = props;
    const {customer, actions} = useCurrentCustomer();
    const config = useGlobalConfiguration();
    const watchVideoList = useMemo(() => {
      const customerAddressState = Customer.getContactState(customer);
      return getVideoList(customerAddressState, actions, config);
    }, [customer, actions, config]);

    const watchVideoSize = useDynamicValue({
      forFour: 4,
      forEight: 4,
      forTwelve: 6,
    });

    // TODO uncomment out eventually, just showing a welcome video on first launch
    // const hasWatchedWelcomeVideo = getItemFromLocalCache(
    //   WELCOME_VIDEO_WATCHED_CACHE
    // );
    // if (!hasWatchedWelcomeVideo) {
    //   const welcomeVideo = watchVideoList[0];
    //   launchVideo(welcomeVideo);
    //   storeItemInLocalCache(WELCOME_VIDEO_WATCHED_CACHE, true);
    // }

    return (
      <SectionContainer>
        <Title>Watch Videos</Title>
        <CardContainer height={"auto"} rowCount={fullWidth / watchVideoSize}>
          {watchVideoList.map((item, index) => (
            <PageElement
              size={watchVideoSize}
              height={"auto"}
              key={item.target + index}
            >
              <CallToActionCard
                link={item.videoUrl}
                key={item.target}
                type={"watch-video"}
                statusMsg={item.statusMsg}
                status={item.status}
                title={item.title}
                msg={item.msg}
                image={item.image}
                buttonText={item.buttonText}
                clickHandler={() => launchVideo(item)}
              />
            </PageElement>
          ))}
        </CardContainer>
      </SectionContainer>
    );
  }
);
