import {InputTextField} from "../../admin/products/styles/AdminStyles";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
`;

const DimensionContainer = styled.div`
	margin-right: 20px;
	width: fit-content;
	display: flex;
`;

export const FeetInchesInput = (props) => {
	let {feet, inches, setFeet, setInches, setOutput} = props;

	if (isNaN(feet)) {
		feet = 0;
	}
	if (isNaN(inches)) {
		inches = 0;
	}

	const markOutput = (feet, inches) => {
		setOutput({
			feet: feet,
			inches: inches,
		});
	};

	const handleFeet = (e) => {
		let {value, min, max} = e.target;
		value = parseInt(value);
		value = Math.max(Number(min), Math.min(Number(max), Number(value)));
		markOutput(value, inches);
	};

	const handleInches = (e) => {
		let {value, min, max} = e.target;
		value = parseInt(value);
		value = Math.max(Number(min), Math.min(Number(max), Number(value)));
		markOutput(feet, value);
	};

	return (
		<Container>
			<DimensionContainer>
				<InputTextField
					value={Number(feet).toString()}
					onChange={handleFeet}
					type="number"
					min="0"
					max="250"
					style={{textAlign: "right"}}
				/>
				<div>'</div>
			</DimensionContainer>
			<DimensionContainer>
				<InputTextField
					value={Number(inches).toString()}
					onChange={handleInches}
					type="number"
					min="0"
					max="11"
					style={{textAlign: "right"}}
				/>
				<div>"</div>
			</DimensionContainer>
		</Container>
	);
};
