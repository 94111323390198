import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

//This is the styling needed for the stepper bar that controls the way it draws the svg
const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    marginBottom: '20px'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

}));

//Add as many steps that are on the form, not counting the in-between steps. The string will be hidden via CSS
function getSteps() {
  return ['one', 'two', 'three', 'four'];
}

export const NavStepper = () => {

  //Grab the step state from the store because depending on which step were on, it'll update the stepper
  const step = useSelector(state => state.intakeForm.step)

  //grab the styling
  const classes = useStyles();
  //creating local state where we will update according to gobal state step
  const [activeStep, setActiveStep] = React.useState(0);
  //grabs the array of steps, this will control how many steps are in the stepper
  const steps = getSteps();

  const icon =
    <span className="custom-nav-icon-wrapper">
      <img className="next-step" src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fnav-bar-open-circle.svg?alt=media&token=ac3f73ed-30c3-4f42-8f52-8fba075f5eb8" alt="empty-circle"></img>
      <img className="current-step" src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fnav-bar-active-circle.svg?alt=media&token=6e6ac917-6a69-4bb5-aa07-8f19f339cc57" alt="active step"/>
      <img className="completed-step" src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fdone.svg?alt=media&token=5b1ff836-f8a8-4b2d-bd01-e03ff61079c3" alt="completed step"/>
    </span>;

  //UseEffect to update the state everytime the global state step changes, so we have it set in the dependency array at the end
  useEffect(() => {

  //Depending on the global state of step, it will choose what the local activeStep is. This will also control moving backwards as well
    switch (step) {
      case 'step-zero' : setActiveStep(0); break
      case 'step-one': setActiveStep(0); break
      case 'step-one-substep-one' : setActiveStep(0); break
      case 'step-one-substep-two' : setActiveStep(1); break
      case 'step-two' : setActiveStep(1); break
      case 'step-two-substep-one' : setActiveStep(2); break
      case 'step-three' : setActiveStep(2);  break
      case 'step-contact' : setActiveStep(3);  break
      case 'step-confirmation' : setActiveStep(3); break
      default: setActiveStep(0); break;
  }

  }, [step])

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel icon={icon}></StepLabel>
          </Step>
        ))}
      </Stepper>
      <span className="progress-bar"></span>
    </div>
  );
}
