import * as Yup from "yup";
import {formatPhoneNumber, PhoneNumberFormatType} from "@natomas/core";

export const FIRST_NAME_KEY = "firstName";
export const LAST_NAME_KEY = "lastName";
export const EMAIL_KEY = "email";
export const PASSWORD_KEY = "password";
export const PHONE_NUMBER_KEY = "phoneNumber";
export const COMPANY_KEY = "company";
export const ADDRESS_KEY = "address";
export const ADDRESSES_KEY = "addresses";

const FIRST_NAME_VALUE: [string, any] = [
  FIRST_NAME_KEY,
  {
    placeholder: "First Name",
    type: "text",
    index: 0,
  },
];

const LAST_NAME_VALUE: [string, any] = [
  LAST_NAME_KEY,
  {
    placeholder: "Last Name",
    type: "text",
    index: 1,
  },
];

const COMPANY_VALUE: [string, any] = [
  COMPANY_KEY,
  {
    placeholder: "Company",
    type: "text",
    index: 2,
  },
];

const ADDRESS_VALUE: [string, any] = [
  ADDRESS_KEY,
  {
    placeholder: "Street Address",
    type: "IAddressDetails",
    index: 4,
  },
];

const ADDRESSES_VALUE: [string, any] = [
  ADDRESSES_KEY,
  {
    placeholder: "Property Street Address",
    type: "list",
    subtype: "street-address",
    index: 2,
    defaultValue: [""],
  },
];

const EMAIL_VALUE: [string, any] = [
  EMAIL_KEY,
  {
    placeholder: "Email",
    autoComplete: "username",
    type: "email",
    index: 2,
  },
];

const EMAIL_REQUIRED_VALUE: [string, any] = [
  EMAIL_KEY,
  {
    placeholder: "Email*",
    type: "email",
    index: 2,
  },
];

const PHONE_NUMBER_VALUE: [string, any] = [
  PHONE_NUMBER_KEY,
  {
    placeholder: "Phone Number",
    type: "tel",
    index: 3,
  },
];

const PASSWORD_VALUE: [string, any] = [
  PASSWORD_KEY,
  {
    placeholder: "Password",
    type: "password",
    autoComplete: "new-password",
    reveal: true,
    index: 4,
  },
];

const GENERAL_INTAKE_FORM_ARRAY: [string, any][] = [
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  PHONE_NUMBER_VALUE,
  EMAIL_VALUE,
];

const CUSTOMER_DETAILS_FORM_ARRAY: [string, any][] = [
  ...GENERAL_INTAKE_FORM_ARRAY,
  ADDRESS_VALUE,
];

const PROJECT_DETAILS_FORM_ARRAY: [string, any][] = [
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  PHONE_NUMBER_VALUE,
  ADDRESS_VALUE,
];

export const PROJECT_DETAILS_FORM: Map<any, any> = new Map(
  PROJECT_DETAILS_FORM_ARRAY
);

export const CUSTOMER_DETAILS_FORM: Map<any, any> = new Map(
  CUSTOMER_DETAILS_FORM_ARRAY
);

export const GENERAL_INTAKE_FORM: Map<any, any> = new Map(
  GENERAL_INTAKE_FORM_ARRAY
);

export const GENERAL_INTAKE_FORM_WITH_PASSWORD = new Map([
  ...GENERAL_INTAKE_FORM_ARRAY,
  PASSWORD_VALUE,
]);

export const MULTI_FAMILY_INTAKE_FORM = new Map([
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  EMAIL_REQUIRED_VALUE,
  PHONE_NUMBER_VALUE,
  COMPANY_VALUE,
  ADDRESSES_VALUE,
]);

export const COMPLETE_VALIDATION_STRUCTURE: any = {
  [FIRST_NAME_KEY]: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  [LAST_NAME_KEY]: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  [EMAIL_KEY]: Yup.string().email("Invalid email").required("Required"),
  [PHONE_NUMBER_KEY]: Yup.string()
    .test("valid-number", "Phone number is not valid", (value: any) => {
      return formatPhoneNumber(value, PhoneNumberFormatType.RAW) != null;
    })
    .required("Required"),
  [PASSWORD_KEY]: Yup.string().min(6, "Too Short!").required("Required"),
};
