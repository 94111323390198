import {
  SummaryPaymentCard,
  SummaryPaymentCardTable,
  SummaryPaymentCardTableLeft,
  SummaryPaymentCardTableLeftSum,
  SummaryPaymentCardTableRight,
  SummaryPaymentCardTableRightSum,
  SummaryPaymentCardTableRow,
  SummaryPaymentCardTableRowSum,
  SummaryPaymentCardTitle,
} from "../styles";
import React from "react";
import {StripeCheckoutButton} from "../../../../_shared/generics/StripeCheckoutButton";
import {isInitialDepositPaid} from "../../../_shared/utilities";
import {ICustomerInfo} from "@natomas/core";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {MobileStickyBottom} from "../../../_shared/components/MobileStickyBottom";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {AiFillCheckCircle} from "react-icons/all";
import {isMobile} from "../../../../_shared/navigation";

interface PaymentProps {
  clientReferenceIdProp: string;
  customerEmailProp: string;
  user: ICustomerInfo;
}

const INCLUDED_PROPOSAL_LINE_ITEMS = [
  "Consultation",
  "Design session",
  "Custom site-plan",
  "Full budget quote",
];

export const PaymentCard = (props: PaymentProps) => {
  const {
    assessmentPriceSKU,
    IS_PRE_DEPOSIT_SINGLE_PAGE,
  } = useGlobalConfiguration();
  const {user, clientReferenceIdProp, customerEmailProp} = props;
  let paymentAction;
  const paymentCardWidth = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 4,
  });
  const paymentCardWidthForSinglePage = useDynamicValue({
    forFour: 4,
    forEight: 6,
    forTwelve: 6,
  });

  const stripeButton = (
    <StripeCheckoutButton
      email={customerEmailProp}
      clientReferenceId={clientReferenceIdProp}
      priceSKU={assessmentPriceSKU}
      option={StyleOption.ColorWillDarken}
      label={"Complete proposal fee"}
    />
  );

  if (isInitialDepositPaid(user)) {
    paymentAction = (
      <div
        style={{
          marginTop: "30px",
          textAlign: "center",
          cursor: "default",
        }}
      >
        Completed Payment ✅
      </div>
    );
  } else {
    paymentAction = (
      <div style={{marginTop: "30px", textAlign: "center"}}>{stripeButton}</div>
    );
  }
  if (isMobile()) {
    return (
      <MobileStickyBottom
        callToActionButton={stripeButton}
        descriptionText={"$500"}
        descriptionPrefixText={""}
      />
    );
  }
  return (
    <PageElement
      size={
        IS_PRE_DEPOSIT_SINGLE_PAGE
          ? paymentCardWidthForSinglePage
          : paymentCardWidth
      }
      height={"22rem"}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <SummaryPaymentCard>
          <SummaryPaymentCardTitle>Proposal summary</SummaryPaymentCardTitle>
          <SummaryPaymentCardTable>
            <tbody>
              {INCLUDED_PROPOSAL_LINE_ITEMS.map((item) => {
                return (
                  <SummaryPaymentCardTableRow key={item}>
                    <SummaryPaymentCardTableLeft>
                      {item}
                    </SummaryPaymentCardTableLeft>
                    <SummaryPaymentCardTableRight>
                      <AiFillCheckCircle />
                    </SummaryPaymentCardTableRight>
                  </SummaryPaymentCardTableRow>
                );
              })}
              <SummaryPaymentCardTableRowSum>
                <SummaryPaymentCardTableLeftSum>
                  Proposal fee
                </SummaryPaymentCardTableLeftSum>
                <SummaryPaymentCardTableRightSum>
                  <span>$500</span>
                  <span
                    style={{
                      textDecorationThickness: "2px",
                      textDecoration: "line-through",
                      marginLeft: "10px",
                      fontFamily: "Maison-Book",
                    }}
                  >
                    $1,000
                  </span>
                </SummaryPaymentCardTableRightSum>
              </SummaryPaymentCardTableRowSum>
            </tbody>
          </SummaryPaymentCardTable>
          {paymentAction}
        </SummaryPaymentCard>
      </div>
    </PageElement>
  );
};
