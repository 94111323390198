import React, {useEffect, useRef, useState} from "react";
import {Address} from "@natomas/core";
import {IAddressDetails} from "@natomas/core/application/entities/AddressDetails";
import {CheckMark} from "../CheckMark";
import {Description, ResolutionDescription, StyledAddressInput} from "./styles";

let autoComplete: {
  addListener: (arg0: string, arg1: () => void) => void;
  getPlace: () => any;
};

//handle when the script is loaded we will assign autoCompleteRef with google maps autocomplete
//were passing in setQuery as updateQuery, the ref of the input and passing in dispatch function, also passing setAddressError so if incomplete address is sent, we can display error message
//passing in nextStep to handle the next step when somone chooses a verified address
function handleScriptLoad(
  autoCompleteRef: React.MutableRefObject<null>,
  setAddress: any,
  setAddressObject: any,
  setAddressError: React.Dispatch<React.SetStateAction<boolean>>,
  setVerifiedAddress: React.Dispatch<React.SetStateAction<boolean>>
) {
  //assign autoComplete with google maps place one time
  // @ts-ignore
  autoComplete = new window.google.maps.places.Autocomplete(
    //set the type of data we want and the fields associated with that type
    autoCompleteRef.current,
    {
      types: ["address"],
      componentRestrictions: {country: "us"},
    }
  );

  //Everytime user add/remove a character, call handlePlaceSelect to update predictions
  autoComplete.addListener("place_changed", () => {
    //when looking for address, set to false
    /*  setVerifiedAddress(false) */

    //continues to pass on setQuery function and dispatch function and setAddressError to finally use them in the next function
    handlePlaceSelect(
      setAddress,
      setAddressObject,
      setAddressError,
      setVerifiedAddress
    );
  });
}

async function handlePlaceSelect(
  setAddress: any,
  setAddressObject: any,
  setAddressError: any,
  setVerifiedAddress: any
) {
  //when user selects a prediction, grab the data from that selection and save it
  const addressObject = autoComplete.getPlace();

  //save the full address using the formatted_address field in object
  const full_address = addressObject?.formatted_address;
  //if full_address comes back null/undefined, which means an incomplete address was sent, catch it and unhide error message by setting setAddressError(true)
  //and returning to prevent continuation of function
  if (!full_address) {
    setAddressObject(null);
    setAddressError(true);
    setVerifiedAddress(false);
    return;
  } else if (
    !addressObject.types ||
    (!addressObject.types.includes("street_address") &&
      !addressObject.types.includes("premise"))
  ) {
    setAddressObject(null);
    setAddressError(true);
    setVerifiedAddress(false);
    return;
  }

  let route;
  let street_number;
  let zipcode;
  let city;
  let state;
  let county;

  //grabs address_components array and filters through to grab pieces of address information
  addressObject.address_components.forEach((item: any) => {
    if (item.types.includes("street_number")) {
      street_number = item.long_name;
    } else if (item.types.includes("route")) {
      route = item.long_name;
    } else if (item.types.includes("locality")) {
      city = item.long_name;
    } else if (item.types.includes("administrative_area_level_2")) {
      county = item.long_name;
    } else if (item.types.includes("administrative_area_level_1")) {
      state = item.short_name;
    } else if (item.types.includes("postal_code")) {
      zipcode = item.long_name;
    }
  });
  setAddress(full_address);

  setAddressObject(
    Address.dataToAddress(
      full_address,
      street_number,
      route,
      city,
      state,
      zipcode,
      county,
      "US",
      addressObject.geometry.location.lat(),
      addressObject.geometry.location.lng()
    )
  );
  //set setAddressError to false in case it has been triggered before
  setAddressError(false);

  //set verified address to true
  setVerifiedAddress(true);
}

export interface AddressInputProps {
  setAddressObject: any;
  setAddress: any;
  placeholder: string;
  value: IAddressDetails;
}

export const AddressInput = (props: AddressInputProps) => {
  const [addressError, setAddressError] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState(true);
  const autoCompleteRef = useRef(null);

  const verifiedIcon = (
    <ResolutionDescription id={"verified-icon"} status={"valid"}>
      <CheckMark size={18} strokeWidth={2} />
      <p>Valid address.</p>
    </ResolutionDescription>
  );

  const errorIcon = (
    <ResolutionDescription status={"error"}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FAlert.svg?alt=media&token=0ccb15d9-7f9b-4d8d-9791-44e17df3af2f"
        alt="error icon"
      />
      <p>Invalid address.</p>
    </ResolutionDescription>
  );

  useEffect(() => {
    handleScriptLoad(
      autoCompleteRef,
      props.setAddress,
      props.setAddressObject,
      setAddressError,
      setVerifiedAddress
    );
    props.setAddress(Address.getFullAddress(props.value));
  }, []);

  return (
    <>
      <StyledAddressInput
        ref={autoCompleteRef}
        spellCheck={false}
        placeholder={props.placeholder}
        defaultValue={props.value ? Address.getFullAddress(props.value) : ""}
        id="address-input"
        autoFocus={true}
        error={addressError}
      />
      <Description show={verifiedAddress}>{verifiedIcon}</Description>
      <Description show={addressError}>{errorIcon}</Description>
    </>
  );
};
