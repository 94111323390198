import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import React from "react";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";
import {
  BannerImage,
  BannerContent,
  BannerContainer,
  BannerTitle,
  BannerDescription,
} from "./styles";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {isMobile} from "../../../../_shared/navigation";

interface BannerProps {
  imageId: string;
  title?: string;
  description?: string;
}

export const Banner = (props: BannerProps) => {
  const width = useDynamicValue(fullColumnWidths);

  /*
   * We use the SmartImageWithId, and then overlay the content.
   * The following fields allow us to calculate and achieve this behavior.
   * */
  const height = isMobile() ? 12 : 24;
  const topModifier = -height;
  return (
    <PageContainer overridePadding={true}>
      <PageElement size={width} height={"auto"}>
        <BannerContainer height={height}>
          <BannerImage>
            <SmartImageWithId imageId={props.imageId} />
          </BannerImage>
          <BannerContent top={topModifier}>
            <BannerTitle>{props.title}</BannerTitle>
            <BannerDescription>{props.description}</BannerDescription>
          </BannerContent>
        </BannerContainer>
      </PageElement>
    </PageContainer>
  );
};
