import React, {useState} from "react";
import {IAddressDetails, Project} from "@natomas/core";
//Shared
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
//Steps
import {ProductDiscoveryStep} from "./components/ProductDiscoveryStep";
//Styles
import {DiscoveryForm} from "./styles";
import {ProductRecommendations} from "./components/ProductRecommendations";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {
  getAddressFilter,
  tryFilterByType,
} from "../../../_shared/catalog/filter";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {Path} from "../../_shared/components/Path";
import {Customer} from "@natomas/core";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";

export const ProductDiscoveryView = (props: {
  completeDiscovery: () => void;
}) => {
  const {completeDiscovery} = props;
  const {customer} = useCurrentCustomer();
  const {projectSummary} = useCurrentProject();
  const {filters} = useProductCatalog();
  const [formComplete, setFormComplete] = useState<boolean>(false);
  let projectAddress: IAddressDetails | null = null;
  if (projectSummary) {
    projectAddress = Project.getAddress(projectSummary);
  } else if (customer) {
    projectAddress = Customer.getContactAddress(customer);
  }

  const viewRecommendations = () => {
    if (projectAddress) {
      tryFilterByType(
        getAddressFilter(projectAddress.county, projectAddress.state),
        filters
      );
    }
    setFormComplete(true);
  };

  return (
    <>
      {formComplete && (
        <Path recommendedProducts={true} allADUCallback={completeDiscovery} />
      )}
      <PageContainer>
        {projectAddress &&
          (!formComplete ? (
            <DiscoveryForm>
              <ProductDiscoveryStep
                seeRecommendations={viewRecommendations}
                completeDiscovery={completeDiscovery}
              />
            </DiscoveryForm>
          ) : (
            <ProductRecommendations completeDiscovery={completeDiscovery} />
          ))}
      </PageContainer>
    </>
  );
};
