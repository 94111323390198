import styled from "styled-components";
import {GRAY} from "../../../_shared/colors";
import {isMobile} from "../../../_shared/navigation";
import {StandardElement} from "../../../_shared/generics/page/components/PageElement/styles";

export const ProductLists = styled.div`
   {
  }
`;
export const ProductLineList = styled.div<{removeRightMargin: number}>`
   {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    ${StandardElement} {
      &:nth-child(${(props) => props.removeRightMargin}n) {
        margin-right: 0;
      }
      margin-bottom: 1rem;
    }
  }
`;

export const HelpText = styled.div`
   {
    font-size: 0.85rem;
    color: ${GRAY};
    margin: ${isMobile() ? 3 : 8}rem;
    text-align: center;
    user-select: none;
  }
`;

export const FilterList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const FormattingContainer = styled.div`
  padding: 1rem 0;
`;
