import "../styles.scss";

export const MultiSelectTextButton = (props) => {
	let priceText = props.price;
	if (priceText == null) {
		priceText = "Included";
	}
	const svgClass = props.isSelected === true ? "checkedSVG" : "";
	const checkboxClass =
		props.isSelected === true ? "buttonCheckBox checked " : "buttonCheckBox";

	return (
		<div className="multi-select-text-button-wrapper">
			<div className={props.buttonClassname} onClick={props.onClick}>
				<div className={checkboxClass}>
					<svg
						viewBox="0 0 20 20"
						width="16"
						height="16"
						className={svgClass}
						preserveAspectRatio="xMidYMin"
					>
						<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
					</svg>
				</div>
				<p className="button-text">{props.buttonText}</p>
				<p className="price">{priceText}</p>
			</div>
		</div>
	);
};
