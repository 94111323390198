import {
  Configuration,
  firestore,
  Sitework,
  Utilities,
} from "../../../../../database/firebase";
import {isBlankString} from "@natomas/core";
import {dollarsToMicros, getDollarPriceValueFromText} from "@natomas/core";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import styled from "styled-components";
import {
  priceFromText,
  priceTextFromMicros,
} from "../../../../design-tool/logic/ConfigurationPageHelper";
import React, {useEffect, useState} from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {isAdmin} from "../../../../_shared/user/UserUtils";
import {useSelector} from "react-redux";
import {useConfigurationLocation} from "../../../_shared/hooks/useConfigurationLocation";
import {SiteWorkWaitingGraphic} from "./SiteWorkWaitingGraphic";
import {
  HeaderRowContainer,
  TableContainer,
} from "../styles/SiteWorkTableStyles";

const MainContainer = styled.div`
  margin-bottom: 0px;
`;

const RowContainer = styled.div`
  height: 50px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 14px;
`;

const ContentRowContainer = styled(RowContainer)`
  min-height: 70px;
  font-size: 16px;
  height: fit-content;
`;

const FinalRowContainer = styled(RowContainer)`
  min-height: 70px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  font-size: 16px;
  background-color: rgba(130, 194, 117, 0.4);
`;

const ColumnText = styled.div`
  position: relative;
  margin: 20px;
  margin-left: 30px;
`;

const Column = styled.div`
  min-height: 100%;
  min-width: 160px;
  position: relative;
  display: flex;
  align-items: center;
`;

const FirstColumn = styled(Column)`
  width: 70%;
  height: fit-content;
`;

const SecondColumn = styled.div`
  width: 30%;
  border-left: solid rgba(0, 0, 0, 0.1) 1px;
`;

const TableRow = (props) => {
  const {items} = props;

  return (
    <>
      <FirstColumn>
        <ColumnText className={"bold"}>{items[0]}</ColumnText>
      </FirstColumn>
      <SecondColumn>
        <ColumnText>{items[1]}</ColumnText>
      </SecondColumn>
    </>
  );
};

const getTotalBreakdown = (data) => {
  let totalSiteWorkCostInMicros = 0;
  data.forEach((row, index) => {
    const [description, total] = row;
    if (description.readOnly !== true) {
      let totalValue = getDollarPriceValueFromText(total.value);
      totalSiteWorkCostInMicros += dollarsToMicros(totalValue);
    }
  });

  return {
    totalSiteWorkCostInMicros,
  };
};

const shouldShowRow = (row) => {
  if (isBlankString(row[0].value) && isBlankString(row[1].value)) {
    return false;
  }
  return row[0].readOnly !== true;
};

const getTable = (rowCount, columnCount, data) => {
  const table = [];
  table.push([
    {value: "Description", readOnly: true},
    {value: "Estimated Cost", readOnly: true},
  ]);
  for (let i = 0; i < 7; i++) {
    const row = [];
    if (data != null && data.length > i) {
      const dataRow = data[i];
      row.push({
        value: dataRow.description,
      });
      row.push({value: dataRow.totalPrice});
    } else {
      for (let col = 0; col < columnCount; col++) {
        row.push({value: ""});
      }
    }
    table.push(row);
  }

  const {totalSiteWorkCostInMicros} = getTotalBreakdown(table);
  table.push([
    {value: "Additional costs total", readOnly: true},
    {
      value: priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
      readOnly: true,
    },
  ]);
  return table;
};

const initialData = getTable(8, 2, null);

const saveSitePricingToConfiguration = (data, configuration) => {
  const {totalSiteWorkCostInMicros} = getTotalBreakdown(data);

  const rows = data
    .filter((row) => {
      return row[1].readOnly !== true;
    })
    .map((row) => {
      const [description, totalPrice] = row;
      return {
        description: description.value,
        totalPrice: totalPrice.value,
      };
    });

  const payload = {
    additionalLineItems: rows,
    additionalLineItemCostInMicros: totalSiteWorkCostInMicros,
  };

  const updateSiteWorkCosts = Utilities.saveOrUpdateDocument(
    firestore.collection(Sitework.getCostsKey()),
    configuration.id,
    payload
  );

  const updateConfigurationCosts = Utilities.saveOrUpdateDocument(
    firestore.collection(Configuration.Constants.CONFIGURATION_SITE_DB_KEY),
    configuration.id,
    payload
  );

  return Promise.all([updateConfigurationCosts, updateSiteWorkCosts]);
};

const LINE_ITEMS_KEY = "additionalLineItems";
const TOTAL_TITLE = "ADDITIONAL COST SUBTOTAL";

export const AdditionalSiteCostsSummary = () => {
  const {
    configuration,
    configurationSite,
    configurationSnapshot,
  } = useCurrentProject();
  const currentUser = useSelector((state) => state.global.user);
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {saveGeoLocation} = useConfigurationLocation();

  const [data, setData] = useState(initialData);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (
      configurationSite != null &&
      configurationSite[LINE_ITEMS_KEY] != null
    ) {
      const rows = configurationSite[LINE_ITEMS_KEY];
      setData(getTable(rows.length, Object.keys(rows[0]).length, rows));
    }
  }, [configurationSite]);
  if (configuration == null) {
    return null;
  }
  const {totalSiteWorkCostInMicros} = getTotalBreakdown(data);

  if (isAdmin(currentUser) && editing === true) {
    return (
      <MainContainer>
        <ReactDataSheet
          style={{width: "100%"}}
          data={data}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={(changes) => {
            const grid = data.map((row) => [...row]);
            changes.forEach(({cell, row, col, value}) => {
              grid[row][col] = {...grid[row][col], value};
            });
            const {totalSiteWorkCostInMicros} = getTotalBreakdown(grid);
            const lastRow = grid[grid.length - 1];
            lastRow[1].value = priceTextFromMicros(
              "" + totalSiteWorkCostInMicros,
              "min"
            );
            setData(grid);
          }}
        />

        <br />

        <button
          className={"button-secondary"}
          onClick={() => {
            setEditing(false);
            if (
              configurationSite != null &&
              configurationSite[LINE_ITEMS_KEY] != null
            ) {
              const rows = configurationSite[LINE_ITEMS_KEY];
              setData(getTable(rows.length, Object.keys(rows[0]).length, rows));
            } else {
              setData(initialData);
            }
          }}
          style={{marginRight: "20px"}}
        >
          Discard Edits
        </button>
        <button
          className={"button-primary bold "}
          onClick={() => {
            setEditing(false);
            return saveSitePricingToConfiguration(data, configuration).then(
              () => {
                saveGeoLocation();
              }
            );
          }}
        >
          Save Additional Costs
        </button>
      </MainContainer>
    );
  }

  if (
    (configurationSite == null ||
      configurationSite[LINE_ITEMS_KEY] == null ||
      configurationSite.visible !== true) &&
    !isAdmin(currentUser)
  ) {
    let table = null;
    if (configurationSnapshot != null) {
      const rows = data.filter(shouldShowRow).map((row, index) => {
        const items = row.map((entry, index) => {
          if (index > 0) {
            return "-";
          }
          return entry.value;
        });

        return (
          <ContentRowContainer key={"row-" + index}>
            <TableRow items={items} />
          </ContentRowContainer>
        );
      });
      table = (
        <div style={{opacity: 0.5}}>
          <HeaderRowContainer className={"bold"}>
            <TableRow items={["Description", "Estimated Cost"]} />
          </HeaderRowContainer>
          {rows}
          <FinalRowContainer className={"bold"}>
            <TableRow items={[TOTAL_TITLE, "-"]} />
          </FinalRowContainer>
        </div>
      );
    }
    return (
      <div>
        <SiteWorkWaitingGraphic
          status={configurationSnapshot != null ? "pending" : "new"}
        />
        {table}
      </div>
    );
  }

  const rows = data.filter(shouldShowRow).map((row, index) => {
    const items = row.map((entry, index) => {
      if (index >= 1 && index <= 3) {
        const intValue = getDollarPriceValueFromText(entry.value);
        if (intValue === 0) {
          return "-";
        }
        return priceFromText("" + intValue, "min");
      }
      return entry.value;
    });

    return (
      <ContentRowContainer key={"row-" + index}>
        <TableRow items={items} />
      </ContentRowContainer>
    );
  });

  return (
    <MainContainer>
      <TableContainer>
        <HeaderRowContainer
          className={"bold"}
          style={{backgroundColor: "rgba(73, 166, 43, 0.4)"}}
        >
          <TableRow items={["Description", "Estimated Cost"]} />
        </HeaderRowContainer>
        {rows}
        <FinalRowContainer className={"bold"}>
          <TableRow
            items={[
              TOTAL_TITLE,
              priceTextFromMicros("" + totalSiteWorkCostInMicros, "min"),
            ]}
          />
        </FinalRowContainer>
      </TableContainer>
      <br />
      {isAdmin(currentUser) && adminMode ? (
        <div style={{display: "flex"}}>
          <button
            className={"button-secondary"}
            onClick={() => {
              setEditing(true);
            }}
          >
            Edit Additional Costs
          </button>
        </div>
      ) : null}
    </MainContainer>
  );
};
