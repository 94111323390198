import React from "react";
import {CurrentIcon} from "../logic/currentIcon";
import {Button} from "./button";
import {getFormStructureFromMap} from "../../_shared/application";
import {FIRST_NAME_KEY, LAST_NAME_KEY} from "../../_shared/forms/FormModels";
import {MAX_CHARACTER_LENGTH} from "../../_shared/forms/FormConstants";
import {IntakeFormInput} from "../../_shared/forms/FormComponentFactory";
import styled from "styled-components";
import {useUrl} from "../../_shared/hooks/useUrl";
import {useGlobalConfiguration} from "../../_shared/hooks/useGlobalConfiguration";
import {NatButton} from "../../_shared/generics/button";
import {StyleOption, TextSize} from "../../_shared/generics/_shared";

const Disclaimer = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  margin-bottom: 10px;
`;

export const ContactForm = (props) => {
  const {getURLPath} = useUrl();
  const {submit_button_title, submit_page_title} = useGlobalConfiguration();

  let title = "One last step! How can we contact you?";
  let buttonTitle = "Submit";
  let requiredFieldsDisclaimer = null;
  const {formik, form, finalSubmit, isFormValid, shouldUseSSD} = props;
  if (getURLPath().includes("multi-family")) {
    title = "How can we contact you?";
    requiredFieldsDisclaimer = <Disclaimer>* Required Fields</Disclaimer>;
  } else if (shouldUseSSD) {
    title = submit_page_title;
    buttonTitle = submit_button_title;
  }

  const {touched, values, errors} = formik;
  const formStructure = getFormStructureFromMap(form);

  //Form is used for all questions that have buttons as input. Will go through an array of objects to render out all the inputs

  const firstNameError = errors[FIRST_NAME_KEY] && touched[FIRST_NAME_KEY];
  const lastNameError = errors[LAST_NAME_KEY] && touched[LAST_NAME_KEY];

  return (
    <div className="form-wrapper">
      <CurrentIcon />
      <form onSubmit={finalSubmit} style={{width: "350px", maxWidth: "95%"}}>
        <div className="question-wrapper" style={{fontFamily: "Maison-Medium"}}>
          <h3 className="question">{title}</h3>
        </div>
        <div>
          {requiredFieldsDisclaimer}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div style={{width: "45%"}}>
              <input
                id={FIRST_NAME_KEY}
                name={FIRST_NAME_KEY}
                type={"text"}
                placeholder={"First Name"}
                value={values[FIRST_NAME_KEY]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={"formik-input"}
                autoFocus={true}
                maxLength={MAX_CHARACTER_LENGTH}
                style={{border: firstNameError ? "1px solid red" : null}}
              />
              {firstNameError ? (
                <div style={{fontSize: 14}}>{errors[FIRST_NAME_KEY]}</div>
              ) : null}
            </div>
            <div style={{width: "45%"}}>
              <input
                id={LAST_NAME_KEY}
                name={LAST_NAME_KEY}
                type={"text"}
                placeholder={"Last Name"}
                value={values[LAST_NAME_KEY]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={"formik-input"}
                maxLength={MAX_CHARACTER_LENGTH}
                style={{border: lastNameError ? "1px solid red" : null}}
              />
              {lastNameError ? (
                <div style={{fontSize: 14}}>{errors[LAST_NAME_KEY]}</div>
              ) : null}
            </div>
          </div>
          {formStructure.map((inputType) => {
            const {key} = inputType;

            if (key.indexOf("Name") > 0) {
              return null;
            }

            return (
              <div key={key}>
                <IntakeFormInput formik={formik} inputType={inputType} />
              </div>
            );
          })}
        </div>

        {/*A disabled button prevents the "return" key from submitting form*/}
        <button disabled hidden={true}>
          Submit
        </button>
        <NatButton
          label={buttonTitle}
          size={TextSize.LARGE}
          option={
            isFormValid ? StyleOption.ColorWillDarken : StyleOption.SECONDARY
          }
          type={"submit"}
          id={isFormValid ? "submit-intake-form" : "invalid-submit-button"}
          disabled={!isFormValid}
          spinnerEnabled={isFormValid}
        />
      </form>
    </div>
  );
};
