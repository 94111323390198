//Global
import React, {useState} from "react";
//Shared
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {NatButton, NatLink} from "../../../../_shared/generics/button";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
//Local
import {LoginFormModel} from "../../logic/models";
import {
  StyledForm,
  StyledFormik,
  StyledWarning,
  BottomSection,
  NewAccountBlurb,
} from "../../../_shared/styles";
import {toIntakeForm} from "../../../../_shared/navigation";
import {LoginFormValidation} from "../../logic/validation";
import {EMAIL_KEY, PASSWORD_KEY} from "../../../_shared/models";
import {Authentication} from "../../../../../database/firebase";
import {IntakeFormInput} from "../../../../_shared/forms/FormComponentFactory";

interface ILoginForm {
  toForgotPassword: any;
}

export const LoginForm = (props: ILoginForm) => {
  const {toForgotPassword} = props;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const formStructure = getFormStructure(LoginFormModel);

  return (
    <StyledFormik
      initialValues={getFormInitialValues(LoginFormModel)}
      validationSchema={LoginFormValidation}
      onSubmit={(values: {[x: string]: string}, {resetForm}) =>
        Authentication.signInWithEmail(
          values[EMAIL_KEY],
          values[PASSWORD_KEY]
        ).catch(() => {
          setErrorMessage("Invalid email or password");
          resetForm({
            values: {
              [EMAIL_KEY]: values[EMAIL_KEY],
              [PASSWORD_KEY]: "",
            },
          });
        })
      }
    >
      {(props) => (
        <StyledForm onSubmit={props.handleSubmit}>
          {formStructure.map((field) => {
            return (
              <div key={field.key} style={{width: "100%"}}>
                <IntakeFormInput
                  formik={props}
                  inputType={field}
                  disableErrors={true}
                />
              </div>
            );
          })}
          <StyledWarning>{errorMessage}</StyledWarning>
          <div>
            <NatLink
              textSize={TextSize.SMALL}
              label={"Forgot your password?"}
              trackingId={"forgot-password"}
              option={StyleOption.NoneWillBoldAndUnderline}
              clickEvent={() => {
                toForgotPassword(props.values[EMAIL_KEY]);
              }}
            />
          </div>
          <BottomSection>
            <NatButton
              size={TextSize.NORMAL}
              trackingId={"login"}
              label={"Sign in"}
              option={StyleOption.PRIMARY}
              type={"submit"}
            />
            <NewAccountBlurb>
              New to Villa?
              <NatLink
                textSize={TextSize.SMALL}
                label={"Create an account"}
                trackingId={"create-an-account"}
                option={StyleOption.NoneWillBoldAndUnderline}
                clickEvent={toIntakeForm}
              />
            </NewAccountBlurb>
          </BottomSection>
        </StyledForm>
      )}
    </StyledFormik>
  );
};
