import React from "react";
import {MAX_CHARACTER_LENGTH} from "./FormConstants";
import {FieldArray, Field, FormikProvider} from "formik";
import {isBlankString} from "@natomas/core";
import {useGlobalConfiguration} from "../hooks/useGlobalConfiguration";

export const IntakeFormInput = (props: any) => {
  const {submit_password_reveal_enabled} = useGlobalConfiguration();
  const {inputType, formik, disableErrors} = props;
  const {key, type, subtype, placeholder, autoComplete, reveal} = inputType;
  const {touched, values, errors} = formik;
  const value: any = values[key];

  const style: any = {};
  const inputAttributes: any = {};
  if (autoComplete) {
    inputAttributes.autoComplete = autoComplete;
  }
  if (submit_password_reveal_enabled && reveal) {
    const isEverythingFilledOut = Object.keys(values).every((valueKey) => {
      return valueKey === key || !isBlankString(values[valueKey]);
    });
    const isTouched = touched[key];
    if (isTouched || isEverythingFilledOut) {
      style.opacity = 1;
    } else {
      style.opacity = 0;
    }
  }

  const isError = !disableErrors && errors[key] && touched[key];
  if (isError) {
    style.border = "1px solid red";
  }

  if (type === "list") {
    return (
      <FormikProvider value={formik}>
        <FieldArray
          name={key}
          render={(arrayHelpers) => (
            <div>
              {value.map((element: any, index: number) => (
                <div
                  key={index}
                  style={{position: "relative", marginBottom: "10px"}}
                >
                  <Field
                    id={`${key}-${index}`}
                    name={`${key}.${index}`}
                    className={"formik-input"}
                    type={subtype}
                    placeholder={`${placeholder} #${index + 1}`}
                    value={element}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={style}
                    maxLength={MAX_CHARACTER_LENGTH}
                  />
                  {index > 0 ? (
                    <div
                      className={"clickable"}
                      onClick={() => arrayHelpers.remove(index)} // remove an item from the list
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    >
                      ⛔️
                    </div>
                  ) : null}
                </div>
              ))}
              <div
                className={"clickable medium"}
                style={{
                  position: "relative",
                  color: "rgba(0,0,0,0.5)",
                  marginBottom: "30px",
                }}
                onClick={() => {
                  const count = value.length;
                  arrayHelpers.push("");

                  // Focus the new input after it's been rendered
                  setTimeout(() => {
                    const newInput = document.getElementById(`${key}-${count}`);
                    if (newInput != null) {
                      newInput.focus();
                    }
                  }, 10);
                }}
              >
                {`Add ${placeholder}`}
              </div>
            </div>
          )}
        />
        {isError ? <div style={{fontSize: 14}}>{errors[key]}</div> : null}
      </FormikProvider>
    );
  }

  return (
    <div style={{marginBottom: "10px"}}>
      <input
        id={key}
        name={key}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={formik.handleChange}
        className={"formik-input"}
        onBlur={formik.handleBlur}
        style={style}
        maxLength={MAX_CHARACTER_LENGTH}
        {...inputAttributes}
      />
      {isError ? <div style={{fontSize: 14}}>{errors[key]}</div> : null}
    </div>
  );
};
