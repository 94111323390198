import React from "react";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import "react-circular-progressbar/dist/styles.css";
import "react-step-progress-bar/styles.css";
import {Signature, Subtitle, Title} from "./styles";
import {getGreeting} from "./logic";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {TeamContacts} from "./components/TeamContacts";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";

export const TeamView = () => {
  const {mergedProjectInfos} = useCurrentProject();
  const {customer} = useCurrentCustomer();

  let signingMessage = `- Villa Team`;
  if (mergedProjectInfos?.team_contacts?.length) {
    signingMessage = `- ${mergedProjectInfos?.team_contacts[0].first_name} and the Villa Team`;
  }

  return (
    <PageContainer>
      <Title>{getGreeting(customer)}</Title>
      <Subtitle>
        {"Building a home in your own backyard should be easy. Hassles with architects, city agencies, and contractors, however, can make it anything but. As a full-service ADU builder, that’s where we come in.\n" +
          "\n" +
          "We believe that families shouldn’t be broken up because they can’t afford to live in the same city. We believe that safe and comfortable housing shouldn’t be just for the wealthy. And we believe that the housing shortage can be solved through a visionary plan and hard work.\n" +
          "\n" +
          "Our plan focuses on building high-quality prefab accessory dwelling units in the most efficient and cost-effective way possible, and on making the process easy for everyone. Every member of our team is dedicated to our mission of building housing that matters. And we always put our customers first.\n" +
          "\n" +
          "We love what we do, and hope that you will too."}
      </Subtitle>
      <Signature>{signingMessage}</Signature>
      <TeamContacts teamContacts={mergedProjectInfos?.team_contacts} />
    </PageContainer>
  );
};
