import React from "react";
import {
  Card,
  CardBackground,
  CardButtonContainer,
  CardContainer,
  CardDescription,
  CardDetailsContainer,
  CardPriceRange,
  CardTitle,
} from "./styles";
import {StyleOption, TextSize} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
import {SmartImageWithId} from "../../../../design-tool/components/SmartImage";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {updateProductGroup} from "../../../../_shared/catalog/ProductCatalogHelper";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";

export interface ProductLineCardProps {
  title: string;
  id: string;
  description: string;
  priceRange: string;
  imageId: string;
  columnWidth: number;
}

export const ProductLineCard = (props: ProductLineCardProps) => {
  const {title, id, description, priceRange, imageId, columnWidth} = props;
  const textSize = useDynamicValue({
    forFour: TextSize.SMALL,
    forEight: TextSize.NORMAL,
    forTwelve: TextSize.NORMAL,
  });
  return (
    <PageElement size={columnWidth} height={"16rem"}>
      <Card>
        <CardBackground>
          <SmartImageWithId imageId={imageId} />
        </CardBackground>
        <CardContainer onClick={() => updateProductGroup(id)}>
          <CardDetailsContainer>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
            <CardPriceRange>{priceRange}</CardPriceRange>
            <CardButtonContainer>
              <NatButton
                label={"Show homes"}
                type={"button"}
                trackingId={"show-homes-" + id}
                option={StyleOption.NoneWillBoldAndUnderline}
                clickEvent={() => updateProductGroup(id)}
                size={textSize}
              />
            </CardButtonContainer>
          </CardDetailsContainer>
        </CardContainer>
      </Card>
    </PageElement>
  );
};
