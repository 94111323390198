export const SetCookie = (
	name: string,
	value: string,
	expirationDays: number
) => {
	const date = new Date();
	date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

	const expires = "expires=" + date.toUTCString();

	document.cookie = name + "=" + value + ";" + expires + ";path=/";

	return GetCookie(name);
};

export const GetCookie = (name: string) => {
	const cookieName = name + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieArray = decodedCookie.split("; ");
	let cookieValue = null;

	cookieArray.forEach((item) => {
		if (item.indexOf(cookieName) === 0) {
			cookieValue = item.substring(cookieName.length, item.length);
		}
	});

	return cookieValue;
};

export const CheckCookie = (
	name: string,
	value: string,
	expirationDays: number
) => {
	const cookie = GetCookie(name);

	if (cookie !== null) {
		return cookie;
	}

	return SetCookie(name, value, expirationDays);
};
