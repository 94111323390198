import React, {Dispatch, SetStateAction} from "react";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {
  HOME_PAGE_ID,
  toPage,
} from "../../../../_shared/navigation/PageNavigation";
import {DashboardLink, WarningContainer} from "./styles";
import {toCatalog} from "../../../../../_shared/navigation";
import {
  NatModal,
  NatModalSize,
} from "../../../../../_shared/generics/modal/NatModal";

export const ChangeModelModal = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const closeChangeModalModal = () => {
    props.setShow(false);
  };

  const changeModel = () => {
    props.setShow(false);
    toCatalog();
  };

  return (
    <NatModal
      show={props.show}
      handleClose={closeChangeModalModal}
      content={
        <WarningContainer>
          <div>
            Please note that upon selecting a new unit, your design options will
            reset and you will need to design your home again.
          </div>
          <div>
            If you would like some assistance, or have any questions, please
            reach out to us through{" "}
            <DashboardLink onClick={() => toPage(HOME_PAGE_ID)}>
              your dashboard
            </DashboardLink>
            .
          </div>
        </WarningContainer>
      }
      size={NatModalSize.SMALL}
      header={"Not the right one?"}
      footerButtons={[
        {
          label: "Dismiss",
          trackingId: "your-unit-warning-dismiss",
          clickEvent: closeChangeModalModal,
          option: StyleOption.SECONDARY,
          type: "button",
        },
        {
          label: "Continue to Catalog",
          option: StyleOption.PRIMARY,
          clickEvent: changeModel,
          type: "button",
          trackingId: "your-unit-continue-to-catalog",
        },
      ]}
    />
  );
};
