import styled from "styled-components";
import {NatomasButton} from "../../../../_shared/generics/button/styles";
import {GRAY} from "../../../../_shared/colors";

export const FilterTogglesContainer = styled.div<{removePadding: boolean}>`
  width: 100%;
  padding: ${(props) => (props.removePadding ? 0 : "0.5rem 0 0 0")};
  ${NatomasButton} {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const FilterToggleDescription = styled.div`
  font-size: 0.85rem;
  font-family: Maison-Medium, sans-serif;
  color: ${GRAY};
`;
