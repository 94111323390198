import {
  BreakdownContainer,
  BreakdownTitle,
  BreakdownValue,
  ProjectCostBreakdownContainer,
  ProjectCostContainer,
  ProjectTotalCostContainer,
  ToDoListTag,
  UnitSiteBreakdownContainer,
} from "../styles";
import {useCurrentProject} from "../../../../_shared/hooks/useCurrentProject";
import {getPricingForUnit} from "../../../_shared/ConfigurationPricingHelper";
import {priceTextFromMicros} from "../../../../design-tool/logic/ConfigurationPageHelper";
import React from "react";
import {ILineItem} from "../../../../../../functions/core/application/entities/SiteWorkCosts";
const getTotalProposalPrice = (
  pricing: any,
  totalSiteWorkCostInMicros: any,
  configurationSite: any
) => {
  if (
    pricing.fullUnitTotalPriceInMicros === 0 ||
    totalSiteWorkCostInMicros == null
  ) {
    return "---,---";
  }

  return priceTextFromMicros(
    pricing.fullUnitTotalPriceInMicros +
      totalSiteWorkCostInMicros +
      +(configurationSite?.totalPhase1CostInMicros ?? 0),
    "min"
  );
};

const getUnitPrice = (pricing: any) => {
  if (pricing.fullUnitTotalPriceInMicros === 0) {
    return (
      <ToDoListTag style={{background: "#FFD7CE"}}>
        WAITING FOR YOUR DESIGN
      </ToDoListTag>
    );
  }

  return (
    <div>{priceTextFromMicros(pricing.fullUnitTotalPriceInMicros, "min")}</div>
  );
};

const getSiteWorkPrice = (totalSiteWorkCostInMicros: any) => {
  return (
    <div>{priceTextFromMicros(totalSiteWorkCostInMicros, "accurate")}</div>
  );
};

const getPhase1PriceInMicros = (lineItems: any) => {
  let totalCost = 0;
  if (lineItems) {
    lineItems.forEach((item: ILineItem) => {
      if (item.totalPriceInMicros && item.lineItemId === "phase1") {
        totalCost += item.totalPriceInMicros;
      }
    });
  }
  return totalCost;
};
const getPhase1PriceString = (lineItems: any) => {
  return (
    <div>
      {priceTextFromMicros(getPhase1PriceInMicros(lineItems), "accurate")}
    </div>
  );
};

export const ProjectCostCard = (props: any) => {
  const {configurationSite, configuration} = useCurrentProject();
  const totalSiteWorkCostInMicros =
    configurationSite?.totalSiteWorkCostInMicros ?? 0;
  console.log("configurationSite " + configurationSite);
  const lineItems = configurationSite?.lineItems ?? [];
  const pricing = getPricingForUnit(configuration);

  return (
    <ProjectCostContainer>
      <ProjectTotalCostContainer className={"bold"}>
        <div>Total Budget</div>
        <div style={{fontSize: 32}}>
          {getTotalProposalPrice(
            pricing,
            totalSiteWorkCostInMicros,
            configurationSite
          )}
        </div>
      </ProjectTotalCostContainer>
      <ProjectCostBreakdownContainer>
        <div className={"bold"}>Budget Breakdown</div>
        <UnitSiteBreakdownContainer>
          <BreakdownContainer>
            <BreakdownTitle className={"bold"}>Phase 1</BreakdownTitle>
            <BreakdownValue>{getPhase1PriceString(lineItems)}</BreakdownValue>
          </BreakdownContainer>
          <BreakdownContainer>
            <BreakdownTitle className={"bold"}>Site Work</BreakdownTitle>
            <BreakdownValue>
              {getSiteWorkPrice(totalSiteWorkCostInMicros)}
            </BreakdownValue>
          </BreakdownContainer>
          <BreakdownContainer>
            <BreakdownTitle className={"bold"}>Unit Cost</BreakdownTitle>
            <BreakdownValue>{getUnitPrice(pricing)}</BreakdownValue>
          </BreakdownContainer>
        </UnitSiteBreakdownContainer>
      </ProjectCostBreakdownContainer>
    </ProjectCostContainer>
  );
};
