import {convertMultipolygonToPolygon} from "./geometry";
import * as turf from "@turf/turf";

var L = window.L;

/** Gets structures such as the primary residence, garage
 * @returns Array of buildings that are not the ADU
 */
export function getNonAduStructures(mapLayers) {
	try {
		const result = [];
		let buildingLayers = mapLayers.buildings
			.getLayers()
			.filter((i) => i.options.className === "non-adu");
		buildingLayers.forEach((layer) => {
			const geoJson = convertMultipolygonToPolygon(
				L.polygon(layer.getLatLngs()).toGeoJSON(13)
			);
			result.push(geoJson);
		});
		result.sort((a, b) => turf.area(b) - turf.area(a));
		return result;
	} catch (e) {
		return false;
	}
}

/** Creates and places the ADU onto feasible area
 * @param Array aduSides (dimensions in inches)
 * @param geoJson feasibleArea feature
 * @returns final ADU polygon
 */
export const getAduPolygon = (
	aduSides,
	feasibleArea,
	fetchedConfigurationMap,
	parcelSearchData,
	currentUnit
) => {
	// We place the ADU at the center of the feasible area
	/**
	 * TODO - if we have multiple feasible areas, (like front yard vs back yard)
	 * we should find the largest one and place it there
	 *  */
	let feasibleAreaCenterPoint = turf.center(feasibleArea);
	let bearing = 90;
	let result = [];

	if (
		fetchedConfigurationMap != null &&
		fetchedConfigurationMap.unitPlacement != null &&
		(parcelSearchData == null ||
			parcelSearchData.addressId ===
				fetchedConfigurationMap.unitPlacement.addressId)
	) {
		const coords = fetchedConfigurationMap.unitPlacement.map((coord) => {
			return [coord.lng, coord.lat];
		});
		feasibleAreaCenterPoint = {
			type: "Feature",
			properties: {},
			geometry: {
				type: "Point",
				coordinates: coords[0],
			},
		};

		if (
			fetchedConfigurationMap.unit != null &&
			currentUnit.id === fetchedConfigurationMap.unit.id
		) {
			coords.forEach((coord) => {
				result.push({
					type: "Feature",
					properties: {},
					geometry: {
						type: "Point",
						coordinates: coord,
					},
				});
			});

			result.push(feasibleAreaCenterPoint);

			const finalShape = [result.map((point) => turf.coordAll(point)[0])];
			return turf.polygon(finalShape);
		}

		const angleDeg =
			(Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]) *
				180) /
			Math.PI;
		bearing = angleDeg;
	}

	result = [feasibleAreaCenterPoint];

	let counter = 0;
	for (let side of aduSides) {
		if (counter >= 3) {
			continue;
		}
		result.push(
			turf.rhumbDestination(result[result.length - 1], side, bearing, {
				units: "inches",
			})
		);
		bearing += 90;
		counter += 1;
	}
	result.push(feasibleAreaCenterPoint);

	const finalShape = [result.map((point) => turf.coordAll(point)[0])];
	return turf.polygon(finalShape);
};
