import {TeamMemberContactInfo} from "@natomas/core";

export const teamContact = (
  firstName: string,
  lastName: string,
  title: string,
  photoURL: string
): TeamMemberContactInfo => {
  return {
    first_name: firstName,
    last_name: lastName,
    photo_url: photoURL,
    title: title,
  };
};
