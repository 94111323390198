import React from "react";
//Styles
import {
  FlexContainer,
  LeftHalfContainer,
  RightHalfContainer,
  HeaderContainer,
  ContentContainer,
} from "./styles";
//Assets
import splash from "./../../../../assets/images/photos/exteriorFeatures4.jpg";

const FormLayout = (props: {children: React.ReactNode}) => {
  const villaLogo =
    "https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FVilla_Logo_Marks_CMYK_Secondary%20Mark.svg?alt=media&token=4b6666f3-6f9c-44bd-a51d-db1dc12e5f22";
  return (
    <FlexContainer>
      <LeftHalfContainer>
        <img
          style={{objectFit: "cover", width: "100%"}}
          alt="Villa ADU bird's eye view, rendering."
          src={splash}
        />
      </LeftHalfContainer>
      <RightHalfContainer>
        <HeaderContainer>
          <img
            alt="Villa company logo"
            src={villaLogo}
            style={{marginBottom: "20px", marginTop: "-20px"}}
            height={"100px"}
          />
        </HeaderContainer>
        <ContentContainer>{props.children}</ContentContainer>
      </RightHalfContainer>
    </FlexContainer>
  );
};

export {FormLayout};
