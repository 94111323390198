import React from "react";
import {sort} from "../../utilities";
import {ProductCard} from "../ProductCard";
//Shared
import {updateProductId} from "../../../../_shared/catalog/ProductCatalogHelper";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {RecommendedCardContainer} from "./styles";
import {getUnitPriceInMicros} from "../../../../design-tool/logic/ProductUtils";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {PricingMode} from "../../../../_shared/slices/GlobalSlice";
import {getImageDetailsByCategory} from "../../utilities/images";
import {ImageCategory} from "@natomas/core";

export const RecommendedProductCards = (props: {
  completeDiscovery: () => void;
  products: any[];
}) => {
  const {products, completeDiscovery} = props;
  const {PRICING_MODE} = useGlobalConfiguration();
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
  });

  const viewProduct = (productId: string) => {
    updateProductId(productId);
    completeDiscovery();
  };

  const getPricing = (product: any) => {
    if (PRICING_MODE === PricingMode.UNIT) {
      return getUnitPriceInMicros(product);
    } else return product.priceMicros;
  };

  return (
    <RecommendedCardContainer>
      {sort(products).map((product: any) => {
        const floorPlanImages = getImageDetailsByCategory(
          product,
          ImageCategory.FLOOR_PLAN
        );
        return (
          <ProductCard
            key={"product-card-" + product.id}
            id={product.id}
            hoverImageId={
              floorPlanImages.length > 0 ? floorPlanImages[0].imageId : null
            }
            imageId={product?.imageId}
            title={product?.title}
            bedroomCount={product.productDetails?.bedrooms}
            bathroomCount={product.productDetails?.bathrooms}
            squareFootage={product.productDetails?.squareFeet}
            priceMicros={getPricing(product)}
            clickEvent={viewProduct}
            columnWidth={size}
            tagline={product.tagline}
          />
        );
      })}
    </RecommendedCardContainer>
  );
};
