export interface IDatabase {
	apiKey: string | undefined;
	authDomain: string | undefined;
	databaseURL: string | undefined;
	projectId: string | undefined;
	storageBucket: string | undefined;
	messagingSenderId: string | undefined;
	appId: string | undefined;
	measurementId: string | undefined;
}

export enum Environment {
	PRODUCTION,
	DEVELOPMENT,
}
