import {
  PROJECT_COLLECTION_KEY,
  PROJECT_OPPORTUNITY_COLLECTION_KEY,
} from "./constants";
import {Utilities} from "..";
import {
  CustomerProjectInfo,
  IProjectInfo,
  Project,
  PhoneNumber,
  ICustomerInfo,
} from "@natomas/core";
import {addressDetailsToNatomasDefaultAddress} from "../adapters/address";
import {CONFIGURATION_DESIGN_DB_KEY} from "../configuration/configuration";
import {mergeProjectInfoAndProjectOpportunityInfo} from "../adapters/project";

export const saveProjectInfo = async (
  configurationId: string,
  projectInfo: IProjectInfo
) => {
  // The interface for the database structure is CustomerProjectInfo
  const address = Project.getAddress(projectInfo);
  let cpi: CustomerProjectInfo = {
    customer_contact: {
      first_name: Project.getClientFirstName(projectInfo),
      last_name: Project.getClientLastName(projectInfo),
      phone: PhoneNumber.getNumber(Project.getClientPhone(projectInfo)),
    },
    project_address: addressDetailsToNatomasDefaultAddress(address),
  };
  let doc = await Utilities.collection(PROJECT_COLLECTION_KEY).doc(
    configurationId
  );
  return Utilities.updateOrCreateDocumentInDB(doc, cpi);
};

export const fetchProjectsForCustomer = (customer: ICustomerInfo) => {
  return Promise.all(
    customer?.project_ids?.map((projectId: string) => {
      const project = Utilities.getDocData(
        Utilities.collection(PROJECT_COLLECTION_KEY).doc(projectId)
      );
      const project_opp = Utilities.getDocData(
        Utilities.collection(PROJECT_OPPORTUNITY_COLLECTION_KEY).doc(projectId)
      );
      const configuration = Utilities.getDocData(
        Utilities.collection(CONFIGURATION_DESIGN_DB_KEY).doc(projectId)
      );
      return Promise.all([project, project_opp, configuration, projectId]);
    })
  ).then((results: any[]) => {
    return results.map((result) => {
      const [project, project_opp, configuration, projectId] = result;
      const projectInfo = project as CustomerProjectInfo;
      const projectOppInfo = project_opp as CustomerProjectInfo;
      return {
        project: mergeProjectInfoAndProjectOpportunityInfo(
          projectInfo,
          projectOppInfo
        ),
        configuration: configuration,
        id: projectId,
      };
    });
  });
};
