import React, {useEffect} from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {GeoCoderContainer} from "./styled";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import useMountEffect from "../../../../_shared/hooks/useMountEffect";
import {getUserInfoFromCID} from "../../../../../database/firebase/user";
import {getParameter} from "../../../../_shared/cookies";
import {isBlankString} from "@natomas/core";

const MAP_BOX_TOKEN =
  "pk.eyJ1IjoiamFja2Nvbm5vbGx5NDE1IiwiYSI6ImNrbnVzZnFybjBkb3cycXJxcjJ2OXVkYTQifQ.HrxEp44-s_3xcQiztYeeBA";

const options = {
  accessToken: MAP_BOX_TOKEN,
  types: "address",
  countries: "US",
  // This limits the results to California's bounding box
  bbox: [-124.409591, 32.534156, -114.131211, 42.009518],
  filter: function (item) {
    // returns true if item contains California region
    if (item.address == null) {
      return false;
    }
    return item.context
      .map(function (i) {
        // id is in the form {index}.{id} per https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
        // attempts to find the `region` named `California`
        return i.id.split(".").shift() === "region" && i.text === "California";
      })
      .reduce(function (acc, cur) {
        return acc || cur;
      });
  },
};

const geocoder = new MapboxGeocoder(options);
const formatPlaceName = (placeName) => {
  return placeName.split(" California")[0] + " CA";
};

let initialLoad = true;

export const GeoCoder = ({onGeocodeSubmit, placeName, loading, isOnMap}) => {
  useMountEffect(() => {
    geocoder.addTo("#geocoder");
    geocoder.setPlaceholder("Enter street address");

    if (initialLoad) {
      initialLoad = false;
      getUserInfoFromCID(getParameter("cid")).then((data) => {
        if (
          data != null &&
          isBlankString(
            document.querySelectorAll(".mapboxgl-ctrl-geocoder--input")[0].value
          )
        ) {
          geocoder.setInput(data.address);
        }
      });
    }

    geocoder.on("result", onGeocodeSubmit);
  });

  useEffect(() => {
    // We use this to force the address in the geocoder input on map view
    for (const input of document.querySelectorAll(
      ".mapboxgl-ctrl-geocoder--input"
    )) {
      if (placeName) {
        input.value = formatPlaceName(placeName);
        input.blur();
      } else {
        input.focus();
      }
    }
  }, [placeName]);

  return (
    <GeoCoderContainer id="geocoder" isOnMap={isOnMap}>
      {/* TODO - bring back if we want to add a spinner to the input
      {loading && (
        <LoadingSpinner style={{ marginTop: 16 }} isCenter={true} />
      )} */}
    </GeoCoderContainer>
  );
};
