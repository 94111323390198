import React from "react";
import {
  PageContainerWrapper,
  PageContent,
  PageContainerBackgroundImage,
  PageContainerRoot,
} from "./styles";
import {usePage} from "../../../../hooks/usePage";
import {PageElement} from "../PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../hooks/useDynamicValue";

interface PageContainerProps {
  children: any;
  marginTop?: string;
  border?: boolean;
  backgroundColor?: string;
  backgroundImage?: PageContainerBackgroundImageOptions;
  stickyOptions?: PageContainerStickyOptions;
  overridePadding?: boolean;
  overrideAnimation?: boolean;
}

export enum PageContainerStickyMode {
  HEADER,
  FOOTER,
  MODAL,
}

export interface PageContainerStickyOptions {
  height?: string;
  mode?: PageContainerStickyMode;
  offset?: string;
}

interface PageContainerBackgroundImageOptions {
  height: string;
  image: string;
}

export const PageContainer = (props: PageContainerProps) => {
  const {
    children,
    backgroundColor,
    backgroundImage,
    border,
    stickyOptions,
    marginTop,
    overridePadding,
    overrideAnimation,
  } = props;
  const {containerWidth, containerPadding, pageWidth} = usePage();

  const fullWidth = useDynamicValue(fullColumnWidths);

  const calculateTop = () => {
    if (stickyOptions?.mode === PageContainerStickyMode.HEADER) {
      return "0";
    } else if (stickyOptions?.mode === PageContainerStickyMode.MODAL) {
      return stickyOptions.offset;
    }
  };

  if (
    children &&
    containerWidth &&
    containerPadding &&
    pageWidth &&
    fullWidth
  ) {
    return (
      <>
        <PageContainerRoot
          top={calculateTop()}
          sticky={stickyOptions}
          marginTop={marginTop}
        >
          {backgroundImage && (
            <PageContainerBackgroundImage
              image={backgroundImage.image}
              width={containerWidth}
              height={backgroundImage.height}
            />
          )}
          <PageContainerWrapper
            sticky={stickyOptions}
            padding={!overridePadding ? containerPadding : 0}
            backgroundColor={backgroundColor}
            border={border}
            width={containerWidth}
            overrideAnimation={overrideAnimation}
          >
            <PageContent
              enabled={pageWidth > 0}
              width={!overridePadding ? pageWidth + "px" : "100%"}
            >
              {children}
            </PageContent>
          </PageContainerWrapper>
        </PageContainerRoot>
        {stickyOptions && (
          <PageContainer>
            <PageElement
              size={fullWidth}
              height={stickyOptions.height ?? "auto"}
            >
              <div />
            </PageElement>
          </PageContainer>
        )}
      </>
    );
  } else return <></>;
};
