import React from "react";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {RecommendationTitle, RecommendationSubtitle} from "./styles";
import {RecommendedProductCards} from "../../../_shared/components/RecommendedProductCards";

export const ProductRecommendations = (props: {
  completeDiscovery: () => void;
}) => {
  const {completeDiscovery} = props;
  const {filteredListedProducts} = useProductCatalog();
  const size = useDynamicValue(fullColumnWidths);

  return (
    <>
      <PageElement size={size} height={"auto"}>
        <RecommendationTitle>Your recommended products</RecommendationTitle>
      </PageElement>
      <PageElement size={size} height={"auto"}>
        <RecommendationSubtitle>
          {filteredListedProducts?.length} homes available
        </RecommendationSubtitle>
      </PageElement>
      <RecommendedProductCards
        completeDiscovery={completeDiscovery}
        products={filteredListedProducts ?? []}
      />
    </>
  );
};
