import {
  InfoSubtitle,
  InfoTitle,
  MainRowCell,
  PhaseBox,
  PhaseTitle,
  RowBlock,
  RowCell,
  TimelineRow,
  TimelineRowInfoContainer,
  TimelineToolTip,
  TimelineToolTipContent,
  TimelineToolTipTitle,
  TimelineRowTop,
  MobileTimelineRow,
  MobileMonthNameRow,
  MobileRowBlock,
  MobileStageTitleDiv,
  MobileStageMonthCountDiv,
  MobilePhaseBox,
  MobilePhaseTitle,
  MobileStageMonthTooltipDiv,
} from "../styles";
import ReactTooltip from "react-tooltip";
import React from "react";
import {ALL_MONTHS, INextStepInfo} from "../constants/NextSteps";
import {makeNounPlural} from "@natomas/core";
import {GoInfo} from "react-icons/go";

export const NextStepsTimelineRow = (props: {
  info?: INextStepInfo;
  totalNumberOfMonths: number;
}) => {
  const {info, totalNumberOfMonths} = props;
  const months = ALL_MONTHS;
  const d = new Date();
  const startingMonth = (d.getMonth() - 1 + months.length) % months.length;
  const monthDivs = [];
  for (let i = 0; i < totalNumberOfMonths; i++) {
    const month = months[(startingMonth + i) % months.length];
    monthDivs.push(month);
  }

  if (info == null) {
    return (
      <TimelineRowTop>
        {monthDivs.map((month, index: number) => (
          <RowCell key={"dates" + index}>{month}</RowCell>
        ))}
      </TimelineRowTop>
    );
  }
  const cells = monthDivs.map((month, index) => {
    return <MainRowCell key={info.id + index} />;
  });

  const startDay = d.getDate();
  let dayOffset = startDay / 31;
  let {startMonth, endMonth, color, title, details} = info;

  startMonth += dayOffset;
  endMonth += dayOffset;

  const totalMonths = Math.round(endMonth - startMonth);
  const content = `${totalMonths} Month${makeNounPlural(totalMonths)}`;

  return (
    <TimelineRow>
      {cells}
      <ReactTooltip
        className={"opaque tool-tip"}
        backgroundColor={"white"}
        id={"timeline-row-tip-" + info.id}
        effect="solid"
        getContent={() => {
          return (
            <TimelineToolTip>
              <TimelineToolTipTitle>{title}</TimelineToolTipTitle>
              <TimelineToolTipContent>{details}</TimelineToolTipContent>
            </TimelineToolTip>
          );
        }}
      />
      <RowBlock
        className={"clickable"}
        data-tip
        data-for={"timeline-row-tip-" + info.id}
        bgColor={color}
        start={(startMonth / totalNumberOfMonths) * 100}
        width={((endMonth - startMonth) / totalNumberOfMonths) * 100}
      >
        <div style={{display: "flex"}}>{content}</div>
      </RowBlock>
      <TimelinePhase info={info} totalNumberOfMonths={totalNumberOfMonths} />
    </TimelineRow>
  );
};

export const MobileNextStepsTimelineRow = (props: {
  info?: INextStepInfo;
  totalNumberOfMonths: number;
}) => {
  return <></>;
};

export const MobileTimelinePhase = (props: {
  info: INextStepInfo;
  totalNumberOfMonths: number;
}) => {
  const {info} = props;
  const {phase} = info;
  if (phase == null) {
    return null;
  }

  const d = new Date();
  const startDay = d.getDate();
  let dayOffset = startDay / 31;
  let {startMonth, color} = info;
  let endMonth = phase.endMonth;
  const endLineColor = phase.endColor;

  startMonth += dayOffset;
  endMonth += dayOffset;

  return (
    <React.Fragment>
      <MobilePhaseBox
        show={true}
        startLineColor={color}
        endLineColor={endLineColor}
        start={startMonth}
        monthHeight={endMonth - startMonth}
      >
        <MobilePhaseTitle>{phase.title}</MobilePhaseTitle>
      </MobilePhaseBox>
    </React.Fragment>
  );
};

export const TimelinePhase = (props: {
  info: INextStepInfo;
  totalNumberOfMonths: number;
}) => {
  const {info, totalNumberOfMonths} = props;
  const {phase} = info;
  if (phase == null) {
    return null;
  }

  const d = new Date();
  const startDay = d.getDate();
  let dayOffset = startDay / 31;
  let {startMonth, color} = info;
  let endMonth = phase.endMonth;
  const endLineColor = phase.endColor;

  startMonth += dayOffset;
  endMonth += dayOffset;

  return (
    <React.Fragment>
      <PhaseBox
        show={true}
        startLineColor={color}
        endLineColor={endLineColor}
        start={(startMonth / totalNumberOfMonths) * 100}
        width={((endMonth - startMonth) / totalNumberOfMonths) * 100}
      >
        <PhaseTitle color={color}>{phase.title}</PhaseTitle>
      </PhaseBox>
    </React.Fragment>
  );
};

export const TimelineStageInfo = (props: {info: INextStepInfo}) => {
  const {info} = props;

  return (
    <TimelineRow>
      <TimelineRowInfoContainer>
        <InfoTitle>{info.title}</InfoTitle>
        <InfoSubtitle>{info.subtitle}</InfoSubtitle>
      </TimelineRowInfoContainer>
    </TimelineRow>
  );
};

export const MobileTimeline = (props: {
  info: INextStepInfo[];
  totalNumberOfMonths: number;
}) => {
  const {info, totalNumberOfMonths} = props;
  const months = ALL_MONTHS;
  const d = new Date();
  const startingMonth = (d.getMonth() - 1 + months.length) % months.length;
  const monthDivs = [];
  for (let i = 0; i < totalNumberOfMonths; i++) {
    const month = months[(startingMonth + i) % months.length];
    monthDivs.push(month);
  }

  if (info == null) {
    return (
      <>
        {monthDivs.map((month, index: number) => (
          <table style={{width: "100%"}}>
            <tr>
              <MobileMonthNameRow key={"dates" + index}>
                {month}
              </MobileMonthNameRow>
              <MobileTimelineRow />
            </tr>
          </table>
        ))}
      </>
    );
  }
  const cells = monthDivs.map((month, index) => {
    return (
      <table style={{width: "calc(100% - 32px)"}}>
        <tr>
          <MobileMonthNameRow key={"dates" + index}>{month}</MobileMonthNameRow>
          <MobileTimelineRow />
        </tr>
      </table>
    );
  });

  return (
    <>
      {cells}
      {info.map((rowInfo, index: number) => {
        const startDay = d.getDate();
        let dayOffset = startDay / 31;
        let {startMonth, endMonth, color, title, details} = rowInfo;

        startMonth += dayOffset;
        endMonth += dayOffset;

        const totalMonths = Math.round(endMonth - startMonth);
        const content = `${totalMonths} Month${makeNounPlural(totalMonths)}`;

        return (
          <div
            key={"next-steps-timeline-index-" + index}
            style={{width: "100%", overflow: "hidden"}}
          >
            <ReactTooltip
              className={"opaque tool-tip"}
              backgroundColor={"white"}
              id={"timeline-row-tip-" + rowInfo.id}
              place="left"
              effect="solid"
              getContent={() => {
                return (
                  <div style={{maxWidth: "200px"}}>
                    <TimelineToolTip>
                      <TimelineToolTipTitle>{title}</TimelineToolTipTitle>
                      <TimelineToolTipContent>{details}</TimelineToolTipContent>
                    </TimelineToolTip>
                  </div>
                );
              }}
            />
            <MobileRowBlock
              bgColor={color}
              start={startMonth}
              monthHeight={endMonth - startMonth}
            >
              <MobileStageTitleDiv>{title}</MobileStageTitleDiv>
              <MobileStageMonthCountDiv>{content}</MobileStageMonthCountDiv>
              <MobileStageMonthTooltipDiv>
                <GoInfo
                  size={20}
                  data-tip
                  data-for={"timeline-row-tip-" + rowInfo.id}
                />
              </MobileStageMonthTooltipDiv>
              {/*<MobileStagePhaseDescriptionDiv>*/}
              {/*  {details}*/}
              {/*</MobileStagePhaseDescriptionDiv>*/}
            </MobileRowBlock>
            <MobileTimelinePhase
              info={rowInfo}
              totalNumberOfMonths={totalNumberOfMonths}
            />
          </div>
        );
      })}
    </>
  );
};
