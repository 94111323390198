import {useEffect, useState} from "react";
import Select from "react-select";
import {
  deleteRenderingFromConfigurationPage,
  saveRenderingToConfigurationPage,
} from "../backend/catalogApi";
import {ImageUploader} from "./ImageUploader";
import {
  convertModifiersToSelectionOptions,
  getAllModifierGroupsForProduct,
} from "../logic/ConfigurationPageHelper";
import {useSelector} from "react-redux";
import {Underline} from "./underline";
import {useFormik} from "formik";
import {isBlankString} from "@natomas/core";

function getOptionsFromSelections(initialSelections, allModifiers) {
  if (initialSelections == null) {
    return [];
  }
  return Object.keys(initialSelections).map((key) => {
    return {
      value: key,
      label: allModifiers[key] != null ? allModifiers[key].title : key,
    };
  });
}

function selectionsFromOptions(options) {
  const selections = {};
  options.forEach((option) => {
    selections[option.value] = option.label;
  });
  return selections;
}

const ModifierSelection = (props) => {
  const {initialSelections, onSelectionChange, allModifiers} = props;
  const [selectedModifiers, setSelectedModifiers] = useState([]);

  useEffect(() => {
    const modifierOptions = getOptionsFromSelections(
      initialSelections,
      allModifiers
    );
    setSelectedModifiers(modifierOptions);
  }, [initialSelections]);

  return (
    <Select
      isMulti
      value={selectedModifiers}
      onChange={(value) => {
        onSelectionChange(value);
      }}
      placeholder={"Modifiers"}
      options={convertModifiersToSelectionOptions(allModifiers)}
    />
  );
};

const renderingProperties = {
  overlayGroupId: "",
  zIndex: "",
  orientation: "",
  objectFit: "",
};

const findModifierIDsForFileName = (fileName, allModifiers) => {
  const fileNameParts = fileName.toLowerCase().split("_");
  const modifierMap = {};
  Object.keys(allModifiers)
    .filter((modifierId) => {
      const modifier = allModifiers[modifierId];
      let modifierTitle = modifier.title;
      if (modifierTitle == null) {
        return false;
      }
      modifierTitle = modifierTitle.toLowerCase();

      if (modifierTitle.length > 8) {
        modifierTitle = modifierTitle.substr(0, 8);
      }
      for (const fileNamePart of fileNameParts) {
        if (
          modifierTitle.startsWith(fileNamePart) ||
          fileNamePart.startsWith(modifierTitle)
        ) {
          return true;
        }
      }
      return false;
    })
    .forEach((modifierId) => {
      modifierMap[modifierId] = true;
    });
  return modifierMap;
};

export const EditRendering = (props) => {
  const {
    rendering,
    renderingImageId,
    productId,
    configurationPage,
    leftOverModifiers,
  } = props;
  let remainingModifiers = null;
  if (leftOverModifiers != null) {
    remainingModifiers = {};
    leftOverModifiers.forEach((modifierId) => {
      remainingModifiers[modifierId] = true;
    });
  }
  const initialModifiers =
    remainingModifiers != null
      ? remainingModifiers
      : rendering.requiredModifiers;

  const [newSelections, setNewSelections] = useState(initialModifiers);
  const [imageId, setImageId] = useState(renderingImageId);
  const [showMore, setShowMore] = useState(false);
  const allModifiers = useSelector((state) =>
    getAllModifierGroupsForProduct(state, productId)
  );

  const initialValues = {};
  Object.keys(renderingProperties).forEach((key) => {
    initialValues[key] = "";
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const valuesToSave = {};
      valuesToSave.zIndex = isBlankString(values.zIndex)
        ? null
        : parseInt(values.zIndex);
      valuesToSave.overlayGroupId = isBlankString(values.overlayGroupId)
        ? null
        : values.overlayGroupId;
      valuesToSave.orientation = isBlankString(values.orientation)
        ? null
        : values.orientation;
      valuesToSave.objectFit = isBlankString(values.objectFit)
        ? null
        : values.objectFit;
      return saveRendering(valuesToSave);
    },
  });

  useEffect(() => {
    setNewSelections(
      remainingModifiers != null
        ? remainingModifiers
        : rendering.requiredModifiers
    );
    formik.resetForm();
    for (const key in renderingProperties) {
      if (key === "zIndex" && rendering[key] != null) {
        formik.setFieldValue(key, "" + rendering[key]);
      } else if (rendering[key] != null) {
        formik.setFieldValue(key, rendering[key]);
      }
    }
  }, [rendering, leftOverModifiers]);

  useEffect(() => {
    setImageId(renderingImageId);
  }, [renderingImageId]);

  const onSelectionChange = (value) => {
    setNewSelections(selectionsFromOptions(value));
  };

  const saveRendering = (valuesToSave) => {
    const updatedRendering = {
      ...valuesToSave,
      imageId: imageId,
      requiredModifiers: newSelections,
      id: rendering.id,
    };
    return saveRenderingToConfigurationPage(
      productId,
      configurationPage,
      updatedRendering
    );
  };

  const deleteRendering = () => {
    return deleteRenderingFromConfigurationPage(
      productId,
      configurationPage,
      rendering.id
    );
  };

  return (
    <div style={{minHeight: "400px"}}>
      <Underline className="option-underline" />
      <br></br>
      <div style={{maxWidth: "500px"}}>
        <ModifierSelection
          initialSelections={newSelections}
          productId={productId}
          onSelectionChange={onSelectionChange}
          allModifiers={allModifiers}
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        {showMore !== true
          ? null
          : Object.keys(renderingProperties).map((key) => {
              return (
                <div key={key}>
                  <label htmlFor={key} style={{marginRight: "20px"}}>
                    {key}:
                  </label>
                  <input
                    id={key}
                    name={key}
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values[key]}
                  />
                  <br />
                </div>
              );
            })}
        <div
          className="clickable"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {showMore ? "Show Less" : "Show More"}
        </div>
        <button type="submit">Submit</button>
        <span
          style={{marginLeft: "20px"}}
          className="clickale"
          onClick={() => {
            return deleteRendering();
          }}
        >
          Delete
        </span>
      </form>
      <ImageUploader
        key={imageId}
        imageIdValue={imageId}
        onComplete={(value) => {
          setImageId(value);
        }}
        fileNameCallback={(name) => {
          if (false) {
            console.log(name);
            setNewSelections(findModifierIDsForFileName(name, allModifiers));
          }
        }}
      />
    </div>
  );
};
