import React from "react";
import {useSelector} from "react-redux";
import {StepOne} from "../views/stepOne";
import {StepTwo} from "../views/stepTwo";
import {StepTwoSubstepOne} from "../views/stepTwoSubstepOne";
import {StepThree} from "../views/stepThree";
import {Confirmation} from "../views/confirmation";
import {NoContact} from "../views/noContact";
import {AddressPage} from "../views/AddressPage";
import {ContactForm} from "../components/contactForm";

export const CurrentStep = (props) => {
  const step = useSelector((state) => state.intakeForm.step);
  const street = useSelector((state) => state.intakeForm.street);

  if (street == null) {
    return <AddressPage />;
  }

  switch (step) {
    case "step-one":
      return <StepOne {...props} />;
    case "step-two":
      return <StepTwo {...props} />;
    case "step-two-substep-one":
      return <StepTwoSubstepOne {...props} />;
    case "step-three":
      return <StepThree {...props} />;
    case "step-contact":
      return <ContactForm {...props} />;
    case "step-confirmation":
      return <Confirmation {...props} />;
    case "step-no-contact":
      return <NoContact {...props} />;
    default:
      <StepOne {...props} />;
  }
};
