import React from "react";
import {
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
} from "../../_shared/forms/FormModels";
import {CheckMark} from "../../_shared/generics/CheckMark";

const MF_CALENDLY_LINK = "https://calendly.com/rick-415/30min?month=2021-11";

export const MFConfirmation = (props: any) => {
  const {formik} = props;
  const {values} = formik;
  const first_name = values[FIRST_NAME_KEY];
  const last_name = values[LAST_NAME_KEY];
  const email = values[EMAIL_KEY];

  const name = first_name + "%20" + last_name;
  const calendlyLink = MF_CALENDLY_LINK + `?name=${name}&email=${email}`;
  let checkBoxCircle = <CheckMark size={50} strokeWidth={4} />;

  return (
    <div className="form-wrapper">
      {checkBoxCircle}
      <div className="confirmation-title-wrapper">
        <h2 className="confirmation-title">We received your information</h2>
      </div>
      <p className="confirmation-text">
        We are excited to chat with you soon. You will be hearing back from our
        team shortly!
      </p>
      <div className="confirmation-buttons">
        <a href={calendlyLink} className="yellow-btn link">
          Schedule an intro call
        </a>
      </div>
    </div>
  );
};
