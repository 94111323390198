import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {geohashForLocation} from "geofire-common";
import {firestore, Utilities} from "../../../../database/firebase";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {Customer, Address, Project} from "@natomas/core";
import {CONFIGURATION_MAPS_DB_KEY} from "../../../../database/firebase/configuration/configuration";
import {saveProjectInfo} from "../../../../database/firebase/project";
import {JourneyStepName} from "../../../_shared/hooks/useTracking";

function httpGetAsync(theUrl, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
      callback(JSON.parse(xmlHttp.responseText));
  };
  xmlHttp.open("GET", theUrl, true); // true for asynchronous
  xmlHttp.send(null);
}

export const useConfigurationLocation = () => {
  const {currentConfigurationId, configurationSite, projectSummary} =
    useCurrentProject();

  let googleGeoCodingURL = "";
  let googleMapsURL = "";
  let fullAddress = "";
  let address = "";
  if (projectSummary) {
    const addressDetails = Project.getAddress(projectSummary);
    let {city, zip, state} = addressDetails;

    address = Address.getStreetNumberAndStreet(addressDetails);
    fullAddress = Address.getFullAddress(addressDetails);

    let fullAddressForGoogle = "";
    fullAddressForGoogle +=
      address != null ? address.split(" ").join("+") + "," : "";
    fullAddressForGoogle += city != null ? city.split(" ").join("+") + "," : "";
    fullAddressForGoogle +=
      state != null ? state.split(" ").join("+") + "," : "";
    fullAddressForGoogle += zip != null ? zip : "";

    googleGeoCodingURL =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      fullAddressForGoogle +
      "&key=AIzaSyCwZWAlda8aZcqdbNqTaU_AqZ2Dqj7-vMM";

    googleMapsURL =
      "https://maps.googleapis.com/maps/api/staticmap?center=" +
      fullAddressForGoogle +
      "&size=600x400&zoom=19&maptype=roadmap&scale=2&key=AIzaSyCgX3X7xM9p-_klJWM6g5vPKzL8icoOkH8&markers=size:small|color:0xff0000|label:3|" +
      fullAddressForGoogle;
  }

  const saveGeoLocation = () => {
    if (configurationSite == null || configurationSite.geohash == null) {
      httpGetAsync(googleGeoCodingURL, (data) => {
        if (data != null) {
          const {lat, lng} = data.results[0].geometry.location;
          const payload = {};
          payload.geohash = geohashForLocation([lat, lng]);
          payload.latitude = lat;
          payload.longitude = lng;
          const updateSiteWorkCosts = Utilities.saveOrUpdateDocument(
            firestore.collection("siteWorkCosts"),
            currentConfigurationId,
            payload
          );
          const updateConfigurationSite = Utilities.saveOrUpdateDocument(
            firestore.collection("configurationSites"),
            currentConfigurationId,
            payload
          );
          return Promise.all([updateSiteWorkCosts, updateConfigurationSite]);
        }
      });
    }
  };

  const fetchGeoLocationToMap = () => {
    const addressDetails = Project.getAddress(projectSummary);
    const coordinates = Address.getCoordinates(addressDetails);
    if (!coordinates) {
      httpGetAsync(googleGeoCodingURL, (data) => {
        if (data != null) {
          const {lat, lng} = data.results[0].geometry.location;
          const address = Address.setCoordinates(addressDetails, lat, lng);
          const newProjectInfo = Project.setAddress(projectSummary, address);
          return saveProjectInfo(currentConfigurationId, newProjectInfo);
        }
      });
    }
  };

  return {
    address,
    fullAddress,
    googleMapsURL,
    saveGeoLocation,
    fetchGeoLocationToMap,
  };
};
