import {Button} from "./button";
import {useSelector} from "react-redux";

export const BackButton = (props) => {
	const {clickBackAction, stepsTaken} = props;
	const step = useSelector((state) => state.intakeForm.step);
	const street = useSelector((state) => state.intakeForm.street);

	const shouldHideButton =
		street == null ||
		stepsTaken.length === 0 ||
		step === "step-no-contact" ||
		step === "step-confirmation";

	return (
		<Button
			buttonClasses={shouldHideButton ? "hidden" : "back-button"}
			onClick={clickBackAction}
		>
			<span>
				<svg
					width="8"
					height="12"
					viewBox="0 0 8 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.5 11L1.5 6L6.5 1"
						stroke="black"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</span>
			Back
		</Button>
	);
};
