import React from "react";
import {
  MobileStickyBottomContainer,
  MobileStickyCallToActionButtonContainer,
  MobileStickyDescriptionPrefixText,
  MobileStickyDescriptionText,
  MobileStickyFooterBumper,
} from "./styles/mobileStickyBottom";

interface StickyBottomProps {
  callToActionButton: any;
  descriptionText: string;
  descriptionPrefixText: string;
}

export const MobileStickyBottom = (props: StickyBottomProps) => {
  const {callToActionButton, descriptionText, descriptionPrefixText} = props;

  return (
    <>
      <MobileStickyFooterBumper />
      <MobileStickyBottomContainer>
        <MobileStickyDescriptionPrefixText>
          {descriptionPrefixText}
        </MobileStickyDescriptionPrefixText>
        <MobileStickyDescriptionText>
          {descriptionText}
        </MobileStickyDescriptionText>
        <MobileStickyCallToActionButtonContainer>
          {callToActionButton}
        </MobileStickyCallToActionButtonContainer>
      </MobileStickyBottomContainer>
    </>
  );
};
