import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setEditingSelections,
  setShowEditingConfirmation,
} from "../slices/configurationSlice";
import {GenericModal} from "../../_shared/generics/GenericModal";
import {IStore} from "../../_shared/slices/types/Store";
import {StyleOption} from "../../_shared/generics/_shared";

export const EditingConfirmationModal = () => {
  const dispatch = useDispatch();
  const show = useSelector(
    (state: IStore) => state.configuration.showEditingConfirmation
  );
  const handleClose = () => {
    dispatch(setShowEditingConfirmation(false));
  };

  const startEditing = () => {
    dispatch(setShowEditingConfirmation(false));
    dispatch(setEditingSelections(true));
  };

  return (
    <GenericModal
      header={"Want to explore some changes?"}
      show={show}
      handleClose={handleClose}
      content={`Feel free to explore our options and upgrades. If you want to save your changes, click "Save Your Design" on the summary page.`}
      footerButtons={[
        {label: "Close", trackingId: "cancel-edit-design"},
        {
          label: "Start Editing",
          action: startEditing,
          option: StyleOption.PRIMARY,
          trackingId: "start-editing-design",
        },
      ]}
    />
  );
};
