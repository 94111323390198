import MaterialTable from "material-table";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";
import {convertModifiersToSelectionOptions} from "../../logic/ConfigurationPageHelper";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";

function getDataFromEntries(prices) {
	return prices.map((price) => {
		return {
			price: price.priceMicros / 100,
			requiredModifiers: price.requiredModifiers,
			condition: price.condition,
		};
	});
}

function generateOutput(prices) {
	return prices.map((price) => {
		return {
			priceMicros: Math.round(price.price * 100),
			requiredModifiers: price.requiredModifiers,
			condition: price.condition,
		};
	});
}

function getOptionsFromSelections(initialSelections, allModifiers) {
	if (initialSelections == null) {
		return [];
	}
	return Object.keys(initialSelections).map((key) => {
		return {
			value: key,
			label: allModifiers[key] != null ? allModifiers[key].title : key,
		};
	});
}

const ModifierSelection = (props) => {
	const {initialSelections, onSelectionChange} = props;

	const [selectedModifiers, setSelectedModifiers] = useState([]);
	const {currentGroupModifiers} = useProductCatalog();

	useEffect(() => {
		const modifierOptions = getOptionsFromSelections(
			initialSelections,
			currentGroupModifiers
		);
		setSelectedModifiers(modifierOptions);
	}, [initialSelections]);

	return (
		<Select
			isMulti
			value={selectedModifiers}
			onChange={(value) => {
				onSelectionChange(value);
			}}
			placeholder={"Modifiers"}
			options={convertModifiersToSelectionOptions(currentGroupModifiers)}
		/>
	);
};

export const ProductPriceToModifiersTable = (props) => {
	const {prices, handleChange, name} = props;
	const productId = useSelector((state) => state.catalog.productId);

	const [data, setData] = useState(getDataFromEntries(prices));
	const [show, setShow] = useState(false);

	const updateRow = (index, newData) => {
		const dataUpdate = [...data];
		dataUpdate[index] = newData;
		const newDataToSave = [...dataUpdate];
		setData(newDataToSave);
		handleChange(generateOutput(newDataToSave));
	};

	const [columns, setColumns] = useState([
		{
			title: "Price",
			field: "price",
			type: "currency",
		},
		{
			title: "Dependent Modifiers",
			field: "requiredModifiers",
			render: (rowData) => {
				const requiredModifiers = rowData.requiredModifiers;

				return (
					<ModifierSelection
						initialSelections={requiredModifiers}
						onSelectionChange={(value) => {
							const dict = {};
							value.forEach((modifier) => {
								dict[modifier.value] = true;
							});
							const row = rowData.tableData.id;
							const newData = Object.assign({}, rowData);
							delete newData.tableData;
							newData.requiredModifiers = dict;
							updateRow(row, newData);
						}}
						productId={productId}
					/>
				);
			},
			editComponent: (props) => {
				const rowData = props.rowData;
				const requiredModifiers = rowData.requiredModifiers;

				return (
					<ModifierSelection
						initialSelections={requiredModifiers}
						onSelectionChange={(value) => {
							const dict = {};
							value.forEach((modifier) => {
								dict[modifier.value] = true;
							});
							props.onChange(dict);
						}}
						productId={productId}
					/>
				);
			},
		},
		{
			title: "Condition",
			field: "condition",
			initialEditValue: "all",
			lookup: {all: "All Modifiers Selected", any: "Any Modifiers Selected"},
		},
	]);

	useEffect(() => {
		setData(getDataFromEntries(prices));
	}, [prices]);

	if (!show) {
		return (
			<div>
				<div
					className="clickable"
					onClick={() => {
						setShow(true);
					}}
				>
					{"Show " + name}
				</div>
			</div>
		);
	}

	return (
		<MaterialTable
			title={name}
			columns={columns}
			data={data}
			options={{
				search: false,
			}}
			editable={{
				onRowAdd: (newData) =>
					new Promise((resolve, reject) => {
						const newDataToSave = [...data, newData];
						setData(newDataToSave);
						handleChange(generateOutput(newDataToSave));
						resolve();
					}),
				onRowUpdate: (newData, oldData) =>
					new Promise((resolve, reject) => {
						const index = oldData.tableData.id;
						updateRow(index, newData);
						resolve();
					}),
				onRowDelete: (oldData) =>
					new Promise((resolve, reject) => {
						const dataDelete = [...data];
						const index = oldData.tableData.id;
						dataDelete.splice(index, 1);
						const newDataToSave = [...dataDelete];
						setData(newDataToSave);
						handleChange(generateOutput(newDataToSave));
						resolve();
					}),
			}}
		/>
	);
};
