import {
  InputContainer,
  InputImageContainer,
  InputImageWrapper,
  InputTextArea,
  InputTextAreaContainer,
  InputTextField,
  InputTitle,
} from "../styles/AdminStyles";
import {FeetInchesInput} from "../../../design-tool/components/FeetInchesInput";
import React from "react";
import {ImageUploader} from "../../../design-tool/components/ImageUploader";
import styled from "styled-components";
import {ProductOptionKeyToValueTable} from "../../../design-tool/admin/components/ProductOptionKeyToValueTable";
import {isBlankString} from "@natomas/core";
import {ColorPickerComp} from "../../../design-tool/components/ColorPicker";
import {convertModifiersToSelectionOptions} from "../../../design-tool/logic/ConfigurationPageHelper";
import Select from "react-select";
import {useProductCatalog} from "../../../_shared/hooks/useProductCatalog";
import {ProductPriceToModifiersTable} from "../../../design-tool/admin/components/ProductPriceToModifiersTable";
import {isDeepEqual} from "../logic/AdminEditorHelper";
import {ModifierDependencyTable} from "../../../design-tool/admin/components/ModifierDependencyTable";
import {SelectionOptionsInputWithArrays} from "../../../design-tool/components/SelectionOptionsInput";
import {GenericTable} from "../../../_shared/generics/table/GenericTable";
import {ImageManager} from "./ImageManager";

const Break = styled.div`
  width: 100%;
`;
const ColorCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 10px;
  background-color: ${(props) => "" + props.bgColor};
`;

const ChangeHighlight = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  background-color: rgba(255, 165, 0, 0.15);
`;

function cleanUpValue(value, options) {
  return options[value] != null ? options[value] : {label: value, value: value};
}

function cleanUpImportedValue(valueDict, library) {
  return Object.keys(valueDict).map((key) => {
    return {
      value: key,
      label: library[key] != null ? library[key].title : key,
    };
  });
}

export const ModifierSelect = (props) => {
  const {value, onChange} = props;
  const {currentGroupModifiers} = useProductCatalog();
  return (
    <Select
      isMulti
      value={cleanUpImportedValue(value, currentGroupModifiers)}
      onChange={(values) => {
        const newSelectionValue = {};
        values.forEach((val) => {
          newSelectionValue[val.value] = true;
        });
        onChange(newSelectionValue);
      }}
      placeholder={"Modifiers"}
      options={convertModifiersToSelectionOptions(currentGroupModifiers)}
    />
  );
};

const DropdownSaveToArray = (props) => {
  // Takes in an array and saves to an array
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {name, options} = fieldInfo;
  return (
    <InputContainer>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <SelectionOptionsInputWithArrays
        options={options}
        handleChange={(value) => {
          onChange(value);
        }}
        value={value}
      />
    </InputContainer>
  );
};

export const DropdownSelect = (props) => {
  const {value, onChange, fieldInfo, changeHighlight} = props;
  const {name, options} = fieldInfo;

  return (
    <InputContainer style={{zIndex: 100}}>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <Select
        value={cleanUpValue(value, options)}
        onChange={(value) => {
          onChange(value.value);
        }}
        placeholder={name}
        options={Object.values(options)}
      />
    </InputContainer>
  );
};

export const TableInput = (props) => {
  const {value, onChange, fieldInfo, changeHighlight} = props;
  const {name, tableStructure} = fieldInfo;

  return (
    <>
      <Break />
      <InputContainer fullWidth={true}>
        {changeHighlight}
        <InputTitle>{name}</InputTitle>
        <GenericTable
          currentValue={value}
          tableStructure={tableStructure}
          onChange={onChange}
        />
      </InputContainer>
      <Break />
    </>
  );
};

const ModifierSelectionInput = (props) => {
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {name} = fieldInfo;
  return (
    <InputContainer>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <ModifierSelect value={value} onChange={onChange} />
    </InputContainer>
  );
};

const ColorInput = (props) => {
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <InputContainer>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          zIndex: 11,
        }}
      >
        <ColorPickerComp
          key={key}
          keyName={key}
          value={value}
          onChange={(value) => {
            onChange(value);
          }}
        />
        <ColorCircle bgColor={!isBlankString(value) ? value : "white"} />
      </div>
    </InputContainer>
  );
};

const PriceTable = (props) => {
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <InputContainer>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <ProductPriceToModifiersTable
        name={name}
        prices={value}
        handleChange={onChange}
      />
    </InputContainer>
  );
};

const ModifierDependencyTableInput = (props) => {
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {key, name, type, defaultValue, subType} = fieldInfo;
  return (
    <>
      <Break />
      <InputContainer fullWidth={true}>
        {changeHighlight}
        <InputTitle>{name}</InputTitle>
        <ModifierDependencyTable
          name={name}
          value={value}
          handleChange={onChange}
          type={subType}
        />
      </InputContainer>
      <Break />
    </>
  );
};

const DimensionInput = (props) => {
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <InputContainer style={{display: "flex"}}>
      {changeHighlight}
      <div style={{marginRight: "40px"}}>
        <InputTitle>Front/Long Length</InputTitle>
        <FeetInchesInput
          feet={value["frontLength"].feet}
          inches={value["frontLength"].inches}
          setOutput={(values) => {
            const newDimensions = {
              frontLength: values,
              sideLength: value.sideLength,
            };
            onChange(newDimensions);
          }}
        />
      </div>
      <div>
        <InputTitle>Side/Short Length</InputTitle>
        <FeetInchesInput
          feet={value["sideLength"].feet}
          inches={value["sideLength"].inches}
          setOutput={(values) => {
            const newDimensions = {
              frontLength: value.frontLength,
              sideLength: values,
            };
            onChange(newDimensions);
          }}
        />
      </div>
    </InputContainer>
  );
};

const ImageInput = (props) => {
  const {value, fieldInfo, onChange, changeHighlight} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <>
      <Break />
      <InputImageContainer key={key}>
        {changeHighlight}
        <InputTitle>{name}</InputTitle>
        <InputImageWrapper>
          <ImageUploader
            key={key}
            drop={true}
            imageIdValue={value}
            onComplete={onChange}
          />
        </InputImageWrapper>
      </InputImageContainer>
    </>
  );
};

const TextAreaInput = (props) => {
  const {value, fieldInfo, onChange, handleChange, changeHighlight} = props;
  const {key, name, type, subType, defaultValue} = fieldInfo;
  return (
    <InputTextAreaContainer key={key} fullWidth={subType === "full-width"}>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <InputTextArea
        id={key}
        placeholder={isBlankString(defaultValue) ? name : "" + defaultValue}
        name={key}
        onChange={handleChange}
        value={value}
      />
    </InputTextAreaContainer>
  );
};

const TextFieldInput = (props) => {
  const {value, fieldInfo, onChange, handleChange, id, changeHighlight} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <InputContainer key={key}>
      {changeHighlight}
      <InputTitle>{name}</InputTitle>
      <InputTextField
        id={id != null ? id : key}
        placeholder={isBlankString(defaultValue) ? name : "" + defaultValue}
        name={key}
        onChange={handleChange}
        value={value}
      />
    </InputContainer>
  );
};

const OptionKeyToValueInput = (props) => {
  const {value, onChange, changeHighlight} = props;
  return (
    <div style={{width: "90%"}}>
      {changeHighlight}
      <ProductOptionKeyToValueTable
        optionEntries={value}
        handleChange={(newData) => {
          const optionValueMap = {};
          newData.forEach((entry) => {
            optionValueMap[entry.key] = entry.value;
          });
          onChange(optionValueMap);
        }}
      />
    </div>
  );
};

export const AdminInput = (props) => {
  let input = null;
  let changeHighlight = null;
  if (
    props.initialValue != null &&
    !isDeepEqual(props.initialValue, props.value)
  ) {
    changeHighlight = <ChangeHighlight />;
  }
  const enhancedProps = {...props, changeHighlight};

  switch (props.fieldInfo.type) {
    case "color":
      input = <ColorInput {...enhancedProps} />;
      break;
    case "dropdown-array":
      input = <DropdownSaveToArray {...enhancedProps} />;
      break;
    case "priceTable":
      input = <PriceTable {...enhancedProps} />;
      break;
    case "optionKeyValues":
      input = <OptionKeyToValueInput {...enhancedProps} />;
      break;
    case "dimensions":
      input = <DimensionInput {...enhancedProps} />;
      break;
    case "image":
      input = <ImageInput {...enhancedProps} />;
      break;
    case "image-manager":
      input = <ImageManager {...enhancedProps} />;
      break;
    case "textarea":
      input = <TextAreaInput {...enhancedProps} />;
      break;
    case "modifier-select":
      input = <ModifierSelectionInput {...enhancedProps} />;
      break;
    case "modifier-dependency":
      input = <ModifierDependencyTableInput {...enhancedProps} />;
      break;
    case "dropdown":
      input = <DropdownSelect {...enhancedProps} />;
      break;
    case "table":
      input = <TableInput {...enhancedProps} />;
      break;
    default:
      input = <TextFieldInput {...enhancedProps} />;
      break;
  }

  return input;
};
