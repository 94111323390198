import styled from "styled-components";

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  img {
    border-radius: 0.5rem;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
