import React from "react";
import {IntroHeader, SearchContainer} from "./styled";
import {GeoCoder} from "./GeoCoder";
import {isMobile} from "../../../../_shared/navigation";

const Search = React.memo(({loading, onGeocodeSubmit}) => {
	return (
		<SearchContainer>
			<IntroHeader>
				<h1>Let's find out what Villa can build on your property</h1>
				{isMobile() && (
					<h6>
						For a better experience
						<br />
						please view this on desktop
					</h6>
				)}
			</IntroHeader>
			<GeoCoder onGeocodeSubmit={onGeocodeSubmit} loading={loading} />
		</SearchContainer>
	);
});

export default Search;
