import {executeRequest} from "../APIExecutor";
import {devLogger} from "../../../../.developerSettings";

export const syncUserInfoWithSalesForce = (user_id: string) => {
  return executeRequest("/user/v1/synchronize", {
    user_id,
  }).then((data) => {
    devLogger(data);
  });
};

export const updateUserProductSelection = (projectId: string, product: any) => {
  return executeRequest("/project/v1/updateProductSelection", {
    projectId,
    product,
  }).then((data) => {
    devLogger(data);
  });
};
