import styled from "styled-components";
import {LINK_COLOR, GRAY} from "../../../../_shared/colors";

export const BreadcrumbItem = styled.div<{clickable?: boolean}>`
  :hover {
    color: ${(props) => props.clickable && LINK_COLOR};
    cursor: ${(props) => props.clickable && "pointer"};
    text-decoration: ${(props) => props.clickable && "underline"};
    :after {
      color: ${GRAY};
    }
  }
`;
export const Separator = styled.div``;

export const Breadcrumb = styled.div<{isMobile: boolean; separator: string}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0.5rem 0;
  font-size: ${(props) => (props.isMobile ? 0.85 : 1)}rem;
  color: ${GRAY};
  cursor: default;
  user-select: none;
  ${BreadcrumbItem} {
    :not(:last-child) {
      :after {
        display: inline-block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        content: "${(props) => props.separator}";
      }
    }
  }
`;
