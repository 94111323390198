import {ICustomerInfo} from "@natomas/core/application/entities/CustomerInfo";
import {Customer} from "@natomas/core";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";

const getTodoStageOrder = (stage: string) => {
  switch (stage) {
    case "review":
      return 0;
    case "todo":
      return 1;
    case "waiting":
      return 2;
  }
  return -1;
};

const getTodoStage = (type: string, complete: boolean) => {
  let stage = "todo";
  switch (type) {
    case "design":
      if (complete) {
        stage = "review";
      }
      break;
    case "proposal":
      if (complete) {
        stage = "review";
      } else {
        stage = "waiting";
      }
      break;
    case "site-plan":
      if (complete) {
        stage = "review";
      } else {
        stage = "waiting";
      }
      break;
    case "assessment":
      if (complete) {
        stage = "review";
      } else {
        stage = "todo";
      }
      break;
  }
  return stage;
};

export function getGreeting(customer: ICustomerInfo) {
  if (customer == null) {
    return "Welcome!";
  }
  const firstName = Customer.getFirstName(customer);

  const currentTimeInHours = new Date().getHours();
  if (currentTimeInHours < 5 || currentTimeInHours >= 18) {
    return `Good evening, ${firstName} 🌙️`;
  } else if (currentTimeInHours < 12) {
    return `Good morning, ${firstName} ☀️️`;
  }

  return `Good afternoon, ${firstName} ☀️️️️`;
}

export function GetPreDepositTodoList(status: any) {
  const {customer} = useCurrentCustomer();

  const scheduledAssessment = Customer.isAssessmentScheduled(customer);
  return [
    {
      type: "assessment",
      complete: scheduledAssessment,
    },
  ]
    .map((entry) => {
      const {type, complete} = entry;
      const stage = getTodoStage(type, complete);
      return Object.assign(
        {
          stage: stage,
          order: getTodoStageOrder(stage),
        },
        entry
      );
    })
    .sort((a, b) => {
      return a.order - b.order;
    });
}

export function getTodoList(status: any) {
  const {designReady, sitePlanReady, proposalReady} = status;
  return [
    {
      type: "site-plan",
      complete: sitePlanReady,
    },
    {
      type: "design",
      complete: designReady,
    },
    {
      type: "proposal",
      complete: proposalReady,
    },
  ]
    .map((entry) => {
      const {type, complete} = entry;
      const stage = getTodoStage(type, complete);
      return Object.assign(
        {
          stage: stage,
          order: getTodoStageOrder(stage),
        },
        entry
      );
    })
    .sort((a, b) => {
      return a.order - b.order;
    });
}
