import styled from "styled-components";
import {GRAY} from "../../../../_shared/colors";

export const QuestionIcon = styled.div`
  font-size: 2rem;
`;

export const Question = styled.div`
  font-family: Maison-Bold, sans-serif;
  font-size: 1.5rem;
`;

export const QuestionHelper = styled.div`
  font-family: Maison-Book, sans-serif;
  font-size: 0.85rem;
  color: ${GRAY};
`;

export const ProductDiscoveryStepContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RecommendationTitle = styled.div`
  font-size: 1.5rem;
  font-family: Maison-Bold, sans-serif;
  margin-top: 1rem;
`;

export const RecommendationSubtitle = styled.div`
  font-size: 1rem;
  font-family: Maison-Book, sans-serif;
  margin: 1rem 0;
  color: ${GRAY};
`;
