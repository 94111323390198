import {store} from "../../../../store";
import {
  AddressFilter,
  FilterOption,
  FilterType,
  NumberRangeFilter,
} from "./types";
import {
  addFilter,
  overwriteFilter,
  removeFilter,
  resetFilters,
} from "../../slices/CatalogSlice";
import {ProductGroup} from "@natomas/core";
import {getUnitPriceInMicros} from "../../../design-tool/logic/ProductUtils";

export const applyReset = () => {
  store.dispatch(resetFilters());
};

export const applyFilter = (filter: FilterOption) => {
  store.dispatch(addFilter(filter));
};

export const changeFilter = (filter: FilterOption) => {
  store.dispatch(overwriteFilter(filter));
};

export const tryFilter = (filter: FilterOption, filters: FilterOption[]) => {
  if (!checkForExactFilter(filter.type, filter.label, filters)) {
    applyFilter(filter);
  }
};

export const tryFilterByType = (
  filter: FilterOption,
  filters: FilterOption[]
) => {
  if (!checkForFilter(filter.type, filters)) {
    applyFilter(filter);
  }
};

export const deleteFilter = (filter: FilterOption) => {
  store.dispatch(removeFilter(filter));
};

export const deleteFilterByType = (type: string) => {
  store.dispatch(removeFilter({type: type}));
};

export const getBedroomFilter = (value: number, label: string) => {
  return {
    type: FilterType.Bedroom,
    value: value,
    label: label,
  };
};

export const getSquareFootageFilter = (
  description: string,
  value: NumberRangeFilter
) => {
  // Low included, High excluded
  return {
    type: FilterType.SquareFootage,
    value: value,
    label: description,
  };
};

export const getPriceRangeFilter = (
  subType: FilterType,
  description: string,
  value: NumberRangeFilter
) => {
  // Low included, High excluded
  return {
    type: subType,
    value: value,
    label: description,
  };
};

export const getAddressFilter = (county: string, state: string) => {
  return {
    type: FilterType.Address,
    value: <AddressFilter>{
      state: state,
      county: county,
    },
    label: state,
  };
};

export const getProductLineFilter = (name: string, internalName: string) => {
  return {
    type: FilterType.ProductLine,
    value: internalName,
    label: name,
  };
};

const checkWithProductLineFilter = (product: any, filterValue: string) => {
  return product.productGroupId === filterValue;
};

const checkWithBedroomFilter = (product: any, filterValue: number) => {
  return product.productDetails?.bedrooms === filterValue;
};

const evaluateNumberRangeFilter = (value: number, range: NumberRangeFilter) => {
  return value >= range.low && value < range.high;
};

export const filterProducts = (
  products: any[],
  productGroups: any,
  filters: FilterOption[]
): any[] => {
  // Check if there is an address filter
  const addressFilter = <AddressFilter>(
    filters.find((f: FilterOption) => f.type === FilterType.Address)?.value
  );
  if (products) {
    // First filter out hidden products
    let availableProductsToCustomer = products.filter((p: any) => {
      const group = productGroups.find((pG: any) => pG.id === p.productGroupId);
      return group && !ProductGroup.isHidden(group);
    });
    // Second - apply an address filter if present
    if (addressFilter)
      availableProductsToCustomer = availableProductsToCustomer.filter(
        (p: any) =>
          ProductGroup.isAvailable(
            addressFilter.county,
            addressFilter.state,
            productGroups.find((pG: any) => {
              return pG.id === p.productGroupId;
            })
          )
      );
    // Lastly - apply all the other filters
    return availableProductsToCustomer.filter((p: any) => {
      // Make sure the product satisfies every type of filter
      let satisfiedCategories = {
        bedroom: !filters.find(
          (f: FilterOption) => f.type === FilterType.Bedroom
        ),
        price_range: !filters.find(
          (f: FilterOption) => f.type === FilterType.PriceRange
        ),
        square_footage: !filters.find(
          (f: FilterOption) => f.type === FilterType.SquareFootage
        ),
        product_line: !filters.find(
          (f: FilterOption) => f.type === FilterType.ProductLine
        ),
        unit_price_range: !filters.find(
          (f: FilterOption) => f.type === FilterType.UnitPriceRange
        ),
      };
      filters.forEach((f: FilterOption) => {
        switch (f.type) {
          case FilterType.Bedroom:
            if (checkWithBedroomFilter(p, <number>f.value))
              satisfiedCategories.bedroom = true;
            break;
          case FilterType.PriceRange:
            if (
              evaluateNumberRangeFilter(
                p.priceMicros,
                <NumberRangeFilter>f.value
              )
            )
              satisfiedCategories.price_range = true;
            break;
          case FilterType.UnitPriceRange:
            if (
              evaluateNumberRangeFilter(
                getUnitPriceInMicros(p),
                <NumberRangeFilter>f.value
              )
            )
              satisfiedCategories.unit_price_range = true;
            break;
          case FilterType.SquareFootage:
            if (
              evaluateNumberRangeFilter(
                p.productDetails?.squareFeet,
                <NumberRangeFilter>f.value
              )
            )
              satisfiedCategories.square_footage = true;
            break;
          case FilterType.ProductLine:
            if (checkWithProductLineFilter(p, <string>f.value))
              satisfiedCategories.product_line = true;
            break;
        }
      });
      return Object.values(satisfiedCategories).every((v: boolean) => v);
    });
  } else return [];
};

export const checkForFilter = (type: string, filters: FilterOption[]) => {
  return !!filters.find((f: FilterOption) => f.type === type);
};

export const checkForExactFilter = (
  type: string,
  label: string,
  filters: FilterOption[]
) => {
  return !!filters.find(
    (f: FilterOption) => f.type === type && f.label === label
  );
};
