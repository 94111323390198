import React from "react";
import {useSelector} from "react-redux";
import {ButtonForm} from "../components/buttonForm";

export const TIMELINE_KEY = "timeline";
export const TIMELINE_ASAP = "asap";
export const TIMELINE_MONTHS = "months";
export const TIMELINE_YEARS = "years";

export const StepThree = (props) => {
	const selectedValue = useSelector((state) => state.intakeForm.questionThree);
	const onClick = props.clickFormButton;
	const inputs = [
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "ASAP",
			nextStep: "step-contact",
			radioID: "first-option",
			radioValue: TIMELINE_ASAP,
			radioName: TIMELINE_KEY,
		},
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "Within months",
			nextStep: "step-contact",
			radioID: "second-option",
			radioValue: TIMELINE_MONTHS,
			radioName: TIMELINE_KEY,
		},
		{
			buttonClass: "form-button",
			onClick,
			buttonText: "Within years",
			nextStep: "step-contact",
			radioID: "third-option",
			radioValue: TIMELINE_YEARS,
			radioName: TIMELINE_KEY,
		},
	];

	return (
		<ButtonForm
			inputs={inputs}
			question="When would you like to start your ADU project?"
			selectedValue={selectedValue}
		/>
	);
};
