import {getFormInitialValues} from "../../../_shared/application";
import {ForgotPasswordFormModel} from "./models";
import {ForgotPasswordFormValidation} from "./validation";
import {
  emailPasswordResetLink,
  triggerPasswordResetLink,
} from "../../../../database/firebase/authentication";
import {EMAIL_KEY} from "../../_shared/models";

export const getForgotPasswordFormik = (callback: any, email?: string) => {
  const initialValues: any = getFormInitialValues(ForgotPasswordFormModel);

  if (email) {
    initialValues[EMAIL_KEY] = email;
  }

  return {
    initialValues: initialValues,
    validationSchema: ForgotPasswordFormValidation,
    onSubmit: (values: {[x: string]: string}) => {
      callback(null);
      triggerPasswordResetLink(values["email"]).then(() => {
        callback(values["email"]);
      });
    },
  };
};
