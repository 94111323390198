import styled from "styled-components";
import {FONT_FAMILY_MEDIUM, colors} from "./globals";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";
import {isMobile} from "../../../../_shared/navigation";

export const DepositSubmissionJourneyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1rem;
`;

export const CardContainer = styled.div<{
  height: string;
  rowCount?: number;
}>`
  height: ${(props) => props.height};
  width: 100%;
  ${StandardElement}:last-child {
    margin-bottom: 0;
  }
  ${StandardElement}:nth-child(n + ${(props) => props.rowCount}) {
    margin-right: 0;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SectionsWrapper = styled.div``;

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;
export const PreDepositPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  margin-top: 1rem;
  font-size: 1.25rem;
  width: 100%;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const Subtitle = styled.div`
  font-size: ${isMobile() ? "0.95rem" : "1rem"};
  margin-top: 0.5rem;
`;

export const ButtonContainer = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
`;
export const Button = styled.a`
  margin-top: 10px;
  background-color: ${colors.modalButtonBackground};
  color: ${colors.modalButtonText};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  border: 1px solid ${colors.modalBorder};
  border-radius: 20px;
  padding: 12px 25px;
  text-decoration: none;
  &:hover {
    background-color: ${colors.modalButtonHoverBackground};
    color: ${colors.modalButtonHoverText};
    text-decoration: none;
    cursor: pointer;
  }
`;
