import React, {useMemo, useState} from "react";
//Button
import {StyleOption, TextSize} from "../_shared/";
import {NatButton} from "../button";
//Styles
import {
  CollapsableContainer,
  CollapsableContainerHeader,
  DeveloperToolsSection,
  SectionDescription,
  SectionRow,
  SectionTitle,
} from "./styles";
//Shared
import {SectionToggle} from "./_shared/SectionToggle";
//Hooks
//Database
import {Customer, EventStatus, IEventInfo} from "@natomas/core";
import {Users} from "../../../../database/firebase";
import {useCurrentCustomer} from "../../hooks/useCurrentCustomer";
import {customerInfoToNatomasCustomer} from "../../../../database/firebase/adapters";

export const CatalogSection = () => {
  //Local
  const [show, setShow] = useState<boolean>(false);
  //Hooks
  const {customer} = useCurrentCustomer();

  const hasCompletedDiscoveryForm = useMemo(() => {
    if (customer) {
      const events = Customer.getEvents(customer);
      return !!events.find(
        (event: IEventInfo) =>
          event.event_id === "catalog_form" &&
          event.details.status === EventStatus.COMPLETED
      );
    } else return false;
  }, [customer]);

  //Functions
  const resetDiscoveryCompletion = () => {
    const newInfo = Customer.deleteEvent(customer, "catalog_form");
    const natUser = customerInfoToNatomasCustomer(newInfo);
    Users.setNatomasUser(natUser.user_id, natUser);
  };

  return (
    <DeveloperToolsSection>
      <CollapsableContainerHeader>
        <SectionTitle>Product Catalog</SectionTitle>
        <SectionToggle show={show} setShow={setShow} />
      </CollapsableContainerHeader>
      <CollapsableContainer show={show}>
        <SectionDescription>
          The Product Catalog has a discovery form for <b>first-time users</b>.
        </SectionDescription>
        <SectionRow>
          <NatButton
            label={"Reset Discovery Form State"}
            type={"button"}
            trackingId={"dev-tools-reset-catalog-form"}
            size={TextSize.SMALL}
            option={StyleOption.Developer}
            clickEvent={resetDiscoveryCompletion}
            disabled={!hasCompletedDiscoveryForm}
          />
        </SectionRow>
      </CollapsableContainer>
    </DeveloperToolsSection>
  );
};
