import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "react-step-progress-bar/styles.css";
import {useCurrentProject} from "../../../_shared/hooks/useCurrentProject";
import {MappingInfo} from "./components/MappingInfo";
import {MappingImage} from "./components/MappingImage";

import MappedImage from "./assets/mapping-mapped.png";
import NonMappedImage from "./assets/mapping-notmapped.png";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {InformationSection} from "./styles";

export const MappingView = () => {
  const {configurationMap} = useCurrentProject();
  const isMapped = !!configurationMap;
  const fullWidth = useDynamicValue(fullColumnWidths);

  return (
    <>
      <PageContainer>
        <InformationSection>
          <PageElement size={fullWidth * (1 / 3)} height={"36rem"}>
            <MappingInfo isMapped={isMapped} />
          </PageElement>
          <PageElement size={fullWidth * (2 / 3)} height={"36rem"}>
            <MappingImage src={isMapped ? MappedImage : NonMappedImage} />
          </PageElement>
        </InformationSection>
      </PageContainer>
    </>
  );
};
