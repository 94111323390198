import React, {useEffect, useState} from "react";
import {Underline} from "./underline";

export const SectionStepBar = (props) => {
  const {sectionId, sections, onClick} = props;
  const [visitedSections, setVisitedSections] = useState({});
  useEffect(() => {
    setVisitedSections({
      ...visitedSections,
      [sectionId]: true,
    });
  }, [sectionId]);

  if (sections == null) {
    return null;
  }

  return (
    <div className="step-bar custom-container">
      {sections.map((section) => {
        const isSelected = sectionId === section.id;
        const visited = visitedSections[section.id] === true;
        let svgClass = "";
        let checkboxClass = "circleCheck";
        if (isSelected) {
          checkboxClass = "circleCheck selected";
          svgClass = "";
        } else if (visited) {
          checkboxClass = "circleCheck visited";
          svgClass = "checkedSVG";
        }

        let checkBoxCircle = (
          <div className={checkboxClass}>
            <svg
              viewBox="0 0 20 20"
              width="16"
              height="16"
              className={svgClass}
              preserveAspectRatio="xMidYMin"
            >
              <polyline points="5 10.75 8.5 14.25 16 6" />
            </svg>
          </div>
        );
        return (
          <div
            key={section.id}
            className={isSelected ? "active item" : "item"}
            onClick={() => onClick(section.id)}
            style={{display: "inline-flex", alignItems: "center"}}
          >
            {checkBoxCircle}
            {section.title}
          </div>
        );
      })}
      <Underline className="base-underline" />
    </div>
  );
};
