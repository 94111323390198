import React from "react";
import {Col, Row} from "antd";
import {UserFeedbackContainer} from "./styled";
import {CheckMarkIcon, InfoIcon} from "../../../../../assets/icons";

export const UserFeedback = ({userFeedback, minimal}) => {
  if (!userFeedback.message) return null;

  let icon = null;
  if (userFeedback.icon) {
    switch (userFeedback.type) {
      case "success":
        icon = <CheckMarkIcon />;
        break;
      case "neutral":
        icon = <InfoIcon />;
        break;
      default:
        icon = <InfoIcon />;
        break;
    }
  }

  return (
    <UserFeedbackContainer minimal={minimal} type={userFeedback.type}>
      <Row wrap={false}>
        {userFeedback.icon && <Col flex="32px">{icon}</Col>}
        {!minimal && <Col flex="auto">{userFeedback.message}</Col>}
      </Row>
    </UserFeedbackContainer>
  );
};
